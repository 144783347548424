(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("disconnectedController", disconnectedController);

	function disconnectedController($rootScope) {

		var vm = this;

		activate();


		$rootScope.$on("destroy", function () {
			$(window).unbind("online");
		});

		function activate() {
			onlineCheck();
		}

		function onlineCheck()
		{
			if (window.location.hash !== "#/disconnected")
				return;

			if (navigator.onLine) {
				window.location.hash = "#/email";
			}
			setTimeout(onlineCheck, 2000);
		}
	}
})();