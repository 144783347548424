(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("sysAdminMigrationStatusController", sysAdminMigrationStatusController);

	function sysAdminMigrationStatusController($rootScope, $scope, $http, $filter, $mdDialog, $compile,
		coreData, coreDataSysAdminSettings, coreDataSysAdminCards, errorHandling, successHandling, NgTableParams, gridCheckboxes) {
        var vm = this;
        vm.items = [];
        vm.tableParams = new NgTableParams(
            {
                sorting: { destinationAddress: 'asc' },
                count: 25
            }, {
                getData: queryData,
				counts: $rootScope.commonTableCounts
            });

		vm.checkboxes = gridCheckboxes.init();
		vm.checkboxes.table = vm.tableParams;
		vm.checkboxes.specialKey = function (item) { return item.destinationAddress + "|" + item.id; }

        // Functions
        vm.searchItems = searchItems;
        vm.refresh = refresh;
		vm.endSession = endSession;
		vm.contextMenuGridItem = contextMenuGridItem;

        activate();

        //////////////////////////

		function activate() {
			$scope.$emit("troubleshooting:countersChanged");
		}

        function queryData(params) {
            var searchParams =
                {
                    startindex: (params.page() - 1) * params.count(),
                    count: params.count(),
                    sortType: null,
                    search: vm.searchField,
                    ascending: !vm.isDescending
                };

            for (var k in params.sorting()) {
                if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
                    continue;
                switch (k) {
                    case 'importItems': searchParams.sortType = 0; break;
                    case 'status': searchParams.sortType = 1; break;
                    case 'ID': searchParams.sortType = 2; break;
                    case 'destinationAddress': searchParams.sortType = 3; break;
                }
                searchParams.ascending = params.sorting()[k] !== 'desc';
                break;
            }

            return $http.post('~/api/v1/settings/sysadmin/post-current-migrations', JSON.stringify(searchParams))
                .then(function (result) {
					var migrationStatus = { 0: 'QUEUED', 1: 'STATUS_IN_PROGRESS', 2: 'COMPLETED' }
                    vm.items = result.data.migrations;
                    angular.forEach(vm.items, function (value) {
                        value.status = migrationStatus[value.status] || value.status;
                    });
                    vm.searchResultCount = result.data.count;
					params.total(vm.searchResultCount);
                    return vm.items;
				}, function () { });
        }

        function searchItems() {
            vm.tableParams.filter({ $: vm.searchText });
        }

        function refreshTable() {
            vm.checkboxes.reset();
            vm.tableParams.reload();
        }

        function endSession() {
            var checked = vm.checkboxes.getCheckedItems();
            var toKill = [];

            angular.forEach(checked, function (migration) {
                var splits = migration.split('|', 2);
                toKill.push({ email: splits[0], id: splits[1] });
			});

			if (toKill.length == 1) {
				doEndSession();
			} else {
				var confirm = $mdDialog.confirmDeletion()
					.textContent($filter('translate')('CONFIRMATIONS_MULTI_GENERIC', { count: toKill.length }))
					.ok($filter('translate')('OK'))
					.title($filter('translate')('CONFIRMATION_REQUIRED'));
				$mdDialog.show(confirm).then(function () { doEndSession(); });
			}

			function doEndSession() {
				var params = JSON.stringify({ sessions: toKill });
				return $http.post('~/api/v1/settings/sysadmin/kill-migrations', params)
					.then(function (success) {
						vm.checkboxes.reset();
						vm.tableParams.reload();
						$rootScope.$broadcast("troubleshooting:countersChanged");
					}, errorHandling.report);
			}
        };

        function refresh() {
            vm.checkboxes.reset();
            vm.tableParams
	            .reload()
	            .then(function() {}, function() {})
				.finally(function () { successHandling.report("ACTION_SUCCEEDED"); });
			$rootScope.$broadcast("troubleshooting:countersChanged");
		};

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === vm.checkboxes.specialKey(item); })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [vm.checkboxes.specialKey(item)];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
                { key: 'endSession', click: endSession, translateKey: 'CANCEL_MIGRATION' }
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $('#context-menu-area');
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}
    }
})();
