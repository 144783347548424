
(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("systemAcmeCertificatesListController", systemAcmeCertificatesListController);

    function systemAcmeCertificatesListController($scope, $mdDialog, $translate, $http, $filter, $compile, $rootScope, errorHandling, successHandling, NgTableParams, gridCheckboxes) {
        var vm = this;
        vm.initialized = false;
        vm.searchText = null;
        vm.items = [];
        vm.tableParams = new NgTableParams(
            {
                sorting: { StatusSort: 'asc' },
                count: 25
            }, {
            getData: queryData,
            counts: $rootScope.commonTableCounts
        });
        vm.checkboxes = gridCheckboxes.init("guid");
        vm.checkboxes.table = vm.tableParams;
        vm.renewSort = '';

        // Functions
        vm.searchItems = searchItems;
        vm.editItem = editItem;
        vm.disableItems = disableItems;
        vm.enableItems = enableItems;
        vm.revalidateItems = revalidateItems;
        vm.contextMenuGridItem = contextMenuGridItem;
        let debouncedRefreshItems = _.debounce(refreshTable, 2000, false);
        vm.disableDisabled = true;
        vm.enableDisabled = true;
        vm.resolveDisabled = true;
        vm.renewItems = renewItems;

        vm.refreshMenuActions = function() {
            var checkedGuids = vm.checkboxes.getCheckedItems();
            var checkedItems = vm.items.filter(x => checkedGuids.includes(x.guid));
            vm.disableDisabled = !checkedGuids || !checkedItems.some(item => item.combined_status !== 1);
            vm.enableDisabled = !checkedGuids || !checkedItems.some(item => item.combined_status === 1);
            vm.resolveDisabled = vm.disableDisabled || !checkedGuids ||
                checkedItems.every(item => item.combined_status === 1 || item.combined_status === 4);


        }
        activate();

        /////////////////////////

        function activate() {
            $scope.$on("signalR.mailHub.client.acmeCertsChanged", () => debouncedRefreshItems());
        }

        function searchItems() {
            refreshTable();
        }

        function refreshTable() {
            vm.checkboxes.reset();
            vm.tableParams.reload();
        }

        function queryData(params) {
            var skip = (params.page() - 1) * params.count();
            var take = Number(params.count());
            var search = vm.searchText ? vm.searchText.toLowerCase() : "";

            var sortField = null;
            var sortDescending = false;
            for (var k in params.sorting()) {
                if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
                    continue;
                sortField = k;
                sortDescending = params.sorting()[k] === "desc";
                break;
            }

            $rootScope.spinner.show();
            return $http
                .get("~/api/v1/settings/sysadmin/acme-certificates/" + sortField + '/' + sortDescending)
                .then(onDataQuery, errorHandling.report)
                .finally($rootScope.spinner.hide);

            function onDataQuery(result) {
                vm.items = result.data.certificates || [];
                vm.acme_enabled = result.data.acme_enabled || false;
                vm.initialized = true;

                let enableRenewSort = false;

                for (var i in vm.items) {
                    if (i.renewsUtc != null) {
                        enableRenewSort = true;
                    }
                }
                if (enableRenewSort) {
                    vm.renewSort = 'renews_utc';
                } else {
                    vm.renewSort = null;
                }
                

                vm.checkboxes.reset();
                vm.tableParams.total(vm.items.length);
                if (search) {
                    vm.items = _.filter(vm.items, function (value) {
                        if (value.hostname && value.hostname.toLowerCase().indexOf(search) !== -1)
                            return true;
                        return false;
                    });
                    vm.tableParams.total(vm.items.length);
                }
                return vm.items.slice(skip, skip + take);
            }
        }

        async function editItem(item, ev) {
            try {             
                let result = await $mdDialog.show({
                    locals: {
                        options: {
                            acmeCertificate: item,
                        },
                    },
                    controller: "systemSslAcmeModalController",
                    controllerAs: "ctrl",
                    templateUrl: "app/sysadmin/settings/ssl-certificates/modals/acme.dlg.html",
                    targetEvent: ev
                });
                if (result.delete) {
                    await itemDelete(item);
                }
            } catch (err) {
                // Ignore - means the dialog was closed
            } finally {
                refreshTable();
            }
        }

        async function itemDelete(sslCertificate) {
            try {
                const confirm = $mdDialog.confirmDeletion()
                    .title($translate.instant("CONFIRMATION_REQUIRED"))
                    .textContent($translate.instant("CONFIRMATIONS_DELETE_ITEMS", { items: 1 }));
                await $mdDialog.show(confirm);
            }
            catch (err) {
                // Error means dialog was closed. Just exit this function
                return;
            }

            //    try {
            //        $rootScope.spinner.show();
            //        await $http.post("~/api/v1/settings/domain/ssl-cert-remove", JSON.stringify({ certGuid: sslCertificate.cert_guid }));
            //    } catch (err) {
            //        errorHandling.report(err)
            //    } finally {
            //        $rootScope.spinner.hide();
            //    }
        }

        async function enableDisableItems(ev, shouldEnable) {
            var ids = vm.checkboxes.getCheckedItems();
            if (!angular.isArray(ids) || ids.length < 1) 
                return;

            try {
                $rootScope.spinner.show();
                await $http.post("~/api/v1/settings/sysadmin/acme-cert-disable", JSON.stringify({ acmeGuids: ids, shouldDisable: !shouldEnable }));
                debouncedRefreshItems()
                successHandling.report("ACTION_SUCCEEDED");
            } catch (err) {
                errorHandling.report(err);
            } finally {
                $rootScope.spinner.hide();
            }
        }


        async function renewItems(ev) {
            var ids = vm.checkboxes.getCheckedItems();
            if (!angular.isArray(ids) || ids.length < 1)
                return;

            try {
                $rootScope.spinner.show();
                await $http.post("~/api/v1/settings/sysadmin/renew-certificates-now", JSON.stringify({ acmeGuids: ids }));
                successHandling.report("ACTION_SUCCEEDED");
            } catch (err) {
                errorHandling.report(err);
            } finally {
                $rootScope.spinner.hide();
            }
        }

        async function disableItems(ev) {
             enableDisableItems(ev, false);
        }

         async function enableItems(ev) {
             enableDisableItems(ev, true);
        }

      async function revalidateItems(ev) {
            var ids = vm.checkboxes.getCheckedItems();
            if (!angular.isArray(ids) || ids.length < 1)
                return;

            try {
                $rootScope.spinner.show();
                await $http.post("~/api/v1/settings/sysadmin/acme-cert-revalidate", JSON.stringify({ acmeGuids: ids }));
                successHandling.report("ACME_RESOLVE_CONFLICTS");
            } catch (err) {
                errorHandling.report(err);
            } finally {
                $rootScope.spinner.hide();
            }
        }

        function contextMenuGridItem(item, ev) {
            if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
                return;
            }
            ev.stopPropagation();
            ev.preventDefault();

            var items = vm.checkboxes.getCheckedItems();

            //If we right clicked on a not selected item we want to use that item instead
            if ((items.length > 1 && !_.some(items, function (val) { return val === item.guid; })) || items.length <= 1) {
                vm.checkboxes.reset();
                vm.checkboxes.checkCheckbox(ev, item);
                items = [item.guid];
            }
            vm.refreshMenuActions();
            $scope.dropdownEvent = $.extend(true, {}, ev);
            $scope.dropdownOptions = [
                { key: 'disableItems', click: disableItems, params: $scope.dropdownEvent, translateKey: 'DISABLE', disabled: vm.disableDisabled},
                { key: 'enableItems', click: enableItems, params: $scope.dropdownEvent, translateKey: 'ENABLE', disabled: vm.enableDisabled },
                { key: 'resolveConflicts', click: revalidateItems, params: $scope.dropdownEvent, translateKey: 'RESOLVE_CONFLICTS', disabled: vm.disableDisabled },
                { key: 'renewNow', click: renewItems, params: $scope.dropdownEvent, translateKey: 'ACME_RENEW_CERTIFICATE_NOW', disabled: vm.disableDisabled },
            ];

            var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
            var element = $('#context-menu-area');
            if (element) {
                var elementCompiled = $compile(elementToCompile)($scope);
                element.append(elementCompiled);
            }
        }
    }

})();









