(function () {
	"use strict";

	angular
		.module("smartermail")
		.factory("AuthenticationService", AuthenticationService);

    function AuthenticationService($http, $location, $state, $injector, $window, $rootScope, $q, $timeout,
		authStorage, claimsService, coreDataSysAdminSettings, userDataService, modalService, apiBase, notifications,
		signalrHubManager, preferencesStorage, localStorageService, localeInfoService) {

		var loadLoginSettingsDefer = null;
		var refreshTokenDefer = null;
		var signalRCallback = null;
		var logoutUrl = null;

		$rootScope.$on("Interceptor:ServiceDown", interceptorReportingAnError);

		var service = {
			loginSettings: null,
			loadLoginSettings: loadLoginSettings,
			Login: Login,
			OnSuccessLogin: OnSuccessLogin,
			Logout: Logout,
			getCaptcha: getCaptcha,
			submitCaptcha: submitCaptcha,
			resetPassword: resetPassword,
			resetPasswordRequest: resetPasswordRequest,
			forceResetPassword: forceResetPassword,
			refreshToken: refreshToken,
			setSignalRCallback: setSignalRCallback,
			doLogout: doLogout,
			navigateOnLoginSuccess: claimsService.navigateOnLoginSuccess
		};
		return service;

		function interceptorReportingAnError(data) {
			// We may want to relax this a bit
			service.loginSettings = null;
		}

		// Implementation
		function refreshToken() {
			if (refreshTokenDefer)
				return refreshTokenDefer.promise;
			refreshTokenDefer = $q.defer();
			var promise = refreshTokenDefer.promise;

			var refreshToken = authStorage.getRefreshToken();
			if (!refreshToken) {
				console.debug("Blank refresh token in authentication.service");
			}

			var clientId = authStorage.getClientId();

			$http
				.post("~/api/v1/auth/refresh-token", { token: refreshToken, iswebmailrefresh: true, clientId: clientId })
				.then(
					function (success) {
						if (!success.data || !success.data.accessToken || !success.data.refreshToken) {
							refreshTokenDefer.reject();
						} else {
							authStorage.saveToken(success.data, false, "authentication.service.refreshToken() ");
							refreshTokenDefer.resolve(success.data);
						}
					},
					refreshTokenDefer.reject
				)
				.catch(refreshTokenDefer.reject)
				.finally(function () { refreshTokenDefer = null; });

			return promise;
		}

		function loadLoginSettings() {
			if (loadLoginSettingsDefer)
				return loadLoginSettingsDefer.promise;
			if (service.loginSettings)
				return $q.when(service.loginSettings);

			loadLoginSettingsDefer = $q.defer();
			$http
				.get("~/api/v1/auth/login-settings")
				.then(onSuccess, onFailure)
				.finally(function () {
					loadLoginSettingsDefer = null;
				});

			return loadLoginSettingsDefer.promise;

			function onSuccess(success) {
				if (success.data !== "loading") {
					service.loginSettings = success.data;
					loadLoginSettingsDefer.resolve(service.loginSettings);
				} else {
					loadLoginSettingsDefer.resolve(success.data);
				}
			}

			function onFailure(failure) {
				loadLoginSettingsDefer.reject(failure);
			}
		}

		function Login(username, password, twoFactorCode, twoFactorSetupGuid, autoLoginToken, callback) {
			var params;
			var clientIdParam = authStorage.getClientId();
			if (autoLoginToken) {
				params = { username: "null", password: "null", autoLoginToken: autoLoginToken, twoFactorCode: twoFactorCode, twoFactorSetupGuid: twoFactorSetupGuid, clientId: clientIdParam };
			} else {
				params = { username: username, password: password, twoFactorCode: twoFactorCode, twoFactorSetupGuid: twoFactorSetupGuid, clientId: clientIdParam };
			}
			$http
				.post("~/api/v1/auth/authenticate-user", params)
				.then(
					function (success) { OnSuccessLogin(success, callback); },
					function (failure) {
						if (failure != undefined && failure.data != undefined) {
							if (failure.data.changePasswordNeeded != undefined && failure.data.changePasswordNeeded) {
								if (failure.data.requirements) {
									authStorage.savePasswordRequirements(failure.data.requirements);
								}
								if (callback != undefined) {
									if (failure.data.passwordExpired !== undefined && failure.data.passwordExpired) {
										callback(false, "forcePasswordExpired", undefined);
									} else {
										callback(false, "forcePasswordReset", undefined, undefined, failure.data.isAdmin);
									}
								}
								return;
							} else if (failure.data.isLicensed != undefined && !failure.data.isLicensed) {
								if (callback != undefined)
									callback(false, "", "notLicensed");
								return;
							} else if (failure.data.autoLoginToken && failure.data.message.indexOf("TWO_FACTOR_REQUIRED") != -1) {
								if (callback != undefined)
									callback(false, "", failure.data.message, failure.data.autoLoginToken);
								return;
							}
						}

						// Failure to authenticate
						if (callback != undefined) {
							if (service.loginSettings === null || (failure != undefined && failure.statusText === "Internal Server Error"))
								callback(false, "", "serverDown");
							else
								callback(false, "", (failure != undefined && failure.data != undefined) ? failure.data.message : "");
						}
					});
		}

		function OnSuccessLogin(success, callback) {
			var response = success.data;
			// Login successful if there's a token in the response
			if (response.accessToken && response.refreshToken) {
				// Store username and token in local storage to keep user logged in between page refreshes
				authStorage.saveToken(response, false, "authentication.service.Login()");

				if (response.displayWelcomeWizard && response.isAdmin) {
					$window.location.href = stSiteRoot + "interface/setup";
				} else if (response.displayWelcomeWizard && !response.isAdmin) {
					var coreData = $injector.get("coreData");
					coreData
						.init()
						.then(function () {
							if (response.localeId)
								localeInfoService.setLocale(response.localeId);
							if (callback != undefined)
								callback(true, "userSetup");
						}, function () {});
				} else {
					// initiate core data and respond to the callback function
					var coreData = $injector.get("coreData");
					coreData
						.init()
						.then(
							function () {
								claimsService.navigateOnLoginSuccess(callback);
							},
							function () {
								if (callback != undefined)
									callback(false);
							});
				}

			} else {
				// Execute callback with false to indicate failed login
				if (callback != undefined)
					callback(false);
			}
		}

		function Logout(source) {
			console.debug("Logout Called from " + source);
			if (userDataService.user.isSysAdmin &&
				coreDataSysAdminSettings.settings.globalMailSettings &&
				coreDataSysAdminSettings.settings.globalMailSettings.sysAdminSettings &&
				coreDataSysAdminSettings.settings.globalMailSettings.sysAdminSettings.serverUrl)
				logoutUrl = coreDataSysAdminSettings.settings.globalMailSettings.sysAdminSettings.serverUrl;
            else
				logoutUrl = userDataService.user.settings.logoutUrl;

			$http
				.post("~/api/v1/auth/logout-user")
				.then(afterLogout, afterLogout);
        }

		function afterLogout() {
			authStorage.deleteTokens();
			var refreshBrowser = doLogout("auth (afterLogout)");
            stopSignalR();
            $timeout(function () {
                // This has to be done after we shutdown signalr otherwise if a chat window
                // is open it will not automatically close for the user at logout.
                preferencesStorage.setSortingFilteringParam("chat", "doLogout", undefined);
                preferencesStorage.setSessionSortingFilteringParam("email", "treeSelectedBranchData", {});
				preferencesStorage.setSessionSortingFilteringParam("email", "shortTreeSelectedBranchData", {});
				preferencesStorage.cleanForLogout();

                if (refreshBrowser)
                    reloadBrowser();
            }, 300);
        }

		function doLogout(source, error, failedUsername) {
			console.debug("DoLogout Called: Source: " + source + " Error: " + error + " FailedUsername: " + failedUsername);

			var refreshBrowser = false;
			var coreData = $injector.get("coreData");
			$rootScope.pageTitle = "";
			notifications.reset();
			coreData.reset();
            modalService.cancelModal();
			apiBase.invalidateAll();
			signalrHubManager.userLogout();
			if (claimsService.impersonating()) {
				// All of our impersonating variables should be in session storage.
				// So we don't have to call delete on them as they will be deleted
				// when the window.close() function is called and the tab closes.

				//authStorage.deleteSessionStorage();
				//claimsService.deleteClaims();
				window.close();
			} else {
				// We need to delete authStorage before claimsService
				// because deleteAll has a reference to the claimsService
				if (failedUsername != undefined)
					authStorage.deleteAllWithUser(failedUsername, "authentication.service doLogout");
				else
					if (authStorage.getPasswordResetId())
						authStorage.deleteAllExceptPasswordResetInfo("authentication.service doLogout");
					else
						authStorage.deleteAll("authentication.service doLogout");
				claimsService.deleteClaims();
				if ($rootScope.spinner)
					$rootScope.spinner.hide();
				if (logoutUrl && !error) {
                    window.location.href = logoutUrl;
					window.redirectingtoExternalSite = true;
				}
                else
					refreshBrowser = true;
			}
            
            return refreshBrowser;
		}

		function setSignalRCallback(callback) {
			signalRCallback = callback;
		}

		function stopSignalR() {
			if (signalRCallback) {
				signalRCallback();
				signalRCallback = null;
            }
        }

		function reloadBrowser() {
			window.location.href = stSiteRoot + "interface/root";
			$rootScope.$destroy();
        }

		function getCaptcha() {
			var defer = $q.defer();
			$http
				.get("~/api/v1/auth/captcha")
				.then(
					function (success) { defer.resolve(success); },
					function (failure) { defer.reject(failure); });

			return defer.promise;
		}

		function submitCaptcha(email, captchaText) {
			var defer = $q.defer();
			$http
				.post("~/api/v1/auth/captcha/", { EmailAddress: email, CaptchaText: captchaText })
				.then(
					function (success) { defer.resolve(success); },
					function (failure) { defer.reject(failure); });

			return defer.promise;
		}

		function resetPassword(username, id, newPassword, confirmPassword) {
			var defer = $q.defer();
			$http
				.post("~/api/v1/auth/reset-password", { Username: username, Id: id, NewPassword: newPassword, ConfirmPassword: confirmPassword })
				.then(
					function (success) { defer.resolve(success); },
					function (failure) { defer.reject(failure); });

			return defer.promise;
		}

		function resetPasswordRequest(id) {
			var defer = $q.defer();
			$http
				.post("~/api/v1/auth/reset-password-request", { id: id })
				.then(
					function (success) { defer.resolve(success); },
					function (failure) { defer.reject(failure); });

			return defer.promise;
		}

		function forceResetPassword(username, oldPassword, newPassword, confirmPassword, isSystemAdmin) {
			var defer = $q.defer();
			$http
				.post("~/api/v1/auth/force-reset-password", { Username: username, OldPassword: oldPassword, NewPassword: newPassword, ConfirmPassword: confirmPassword, HostName: window.location.hostname, isSysAdmin: isSystemAdmin })
				.then(
					function (success) { defer.resolve(success); },
					function (failure) { defer.reject(failure); });

			return defer.promise;
		}

	}
})();
