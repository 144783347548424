/*This editor was created using Froala editor. 
 * Check https://www.froala.com/wysiwyg-editor/docs/ 
 * for methods, plugins, events, options and more to extend functionality*/


(function () {
	angular.module("smartertools")
		.component("messageBubble", {
			controller: messageBubbleController,
			bindings: {
				messageContent: "="
			},
			template:  `<div class="messsage-wrapper">
	                        <div class="name-and-time">
	                            <div class="name-container">
	                                <span class="name">{{$ctrl.messageContent.name}}</span>
	                                <span class='time'>&ndash; {{$ctrl.messageContent.dt | date: 'shortTime'}}</span>
	                            </div>
	                        </div>
	                        <div class="text st-html-compiler" ng-bind-html="$ctrl.messageContent.text | markdownToHtml"><div></div></div>
						</div>`
		});
	function messageBubbleController($element) {
		const vm = this;
	}
})();
