(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sharedUploadSubscribersController", sharedUploadSubscribersController);

	function sharedUploadSubscribersController($scope, $mdDialog, $filter, $state, $translate, toaster, options, errorHandling) {
		var vm = this;

		vm.isDomain = $state.params.type === 'domain';
		vm.fileCount = 0;
		$scope.options = options;
		$scope.uploaderData = JSON.stringify($scope.options);
		$scope.isValid = false;
		$scope.isProcessing = false;

		// Functions
		$scope.uploaderInit = uploaderInit;
		$scope.save = save;
		$scope.cancel = cancel;
		$scope.importFile = importFile;
		$scope.uploadFailed = uploadFailed;
		$scope.addedFile = addedFile;
		$scope.removedFile = removedFile;

		activate();

		///////////////////////

		function activate() {
		}

		function uploaderInit(publicFunctions) {
		    $scope.uploaderFunctions = publicFunctions;
		}

		function save() {
			if (!($scope.isValid = vm.fileCount > 0))
				return;
			$scope.isProcessing = true;
			$scope.uploaderFunctions.startUpload();
		};

		function cancel() {
			$mdDialog.cancel();
		};

		function importFile(result, data) {
			if (data.someSubscribersNotImported)
				toaster.pop("warning", $translate.instant("SOME_SUBSCRIBERS_NOT_IMPORTED"));
			$scope.isProcessing = false;
			$mdDialog.hide();
		};

		function uploadFailed() {
			errorHandling.report("ERROR_UPLOADING");
			$scope.isProcessing = false;
		    $scope.uploaderFunctions.reset();
		}

		function addedFile() {
			$scope.isValid = true;
			$scope.$applyAsync();
			vm.fileCount++;
		};

		function removedFile() {
			vm.fileCount--;
			if (vm.fileCount < 0)
				vm.fileCount = 0;
		};
	}

})();
