(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("editDomainPathController", editDomainPathController);

	function editDomainPathController($scope, $mdDialog, oldDomainPath) {
		$scope.oldDomainPath = oldDomainPath;
		$scope.newDomainPath = oldDomainPath;

		$scope.close = function (form) {
			if (form.$pending) return;
			$mdDialog.hide({ newDomainPath: $scope.newDomainPath });
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};

	}

})();
