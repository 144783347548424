(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("taskViewController", taskViewController);

	function taskViewController($rootScope, $scope, $mdDialog, $timeout, $filter, $q, $stateParams, $http, $translate, $sce,
		coreData, coreDataTasks, coreDataSettings, userDataService, authStorage, apiTaskSources, apiCategories, userTimeService,
		errorHandling, errorMessageService, preferencesStorage, popupService, themesService, claimsService, emailNavigation) {

		var vm = this;
		$scope.isLoaded = false;
		var askBeforeLeave = true;

		// Data List
		$scope.isNew = $stateParams.taskId === undefined;
		$scope.sources = [];
		$scope.tasks = [];
		$scope.useDateTime = true;
		$scope.reminderSet = true;
		$scope.afContext = "user";
		$scope.editorScope = {};
		Object.defineProperty(vm, "userTimeZone", {
			get: function () { return userTimeService.userTimeZone; }
		});
		$scope.removedAttachedFiles = [];

		// Dropdowns
		$scope.percentCompleteOptions = [];//[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
		for (var index = 0; index <= 100; index++) {
			$scope.percentCompleteOptions.push(index);
		}
		$scope.priorityOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
		$scope.statusOptions = [
			{ id: 3, name: "STATUS_CANCELED" },
			{ id: 0, name: "STATUS_NOT_STARTED" },
			{ id: 1, name: "COMPLETED" },
			{ id: 2, name: "STATUS_IN_PROGRESS" }
		];
		$scope.reminderOptions = generateReminderOptions();
		$scope.recurrenceType = {
			ONCE: 0,
			DAILY: 4,
			WEEKLY: 5,
			MONTHLY: 6,
			YEARLY: 7
		};
		$scope.recurrenceOptions = [
			{ id: $scope.recurrenceType.ONCE, name: $translate.instant("CALENDAR_RECURRENCE_ONCE") },
			{ id: $scope.recurrenceType.DAILY, name: $translate.instant("DAILY") },
			{ id: $scope.recurrenceType.WEEKLY, name: $translate.instant("WEEKLY") },
			{ id: $scope.recurrenceType.MONTHLY, name: $translate.instant("MONTHLY") },
			{ id: $scope.recurrenceType.YEARLY, name: $translate.instant("CALENDAR_RECURRENCE_YEARLY") }
		];
		$scope.recurPeriods = [
			{ id: 0, name: $translate.instant("CALENDAR_FIRST") },
			{ id: 1, name: $translate.instant("CALENDAR_SECOND") },
			{ id: 2, name: $translate.instant("CALENDAR_THIRD") },
			{ id: 3, name: $translate.instant("CALENDAR_FOURTH") },
			{ id: 4, name: $translate.instant("CALENDAR_LAST") }
		];
		$scope.daysOfWeek = [
			{ id: 0, name: $translate.instant("SUNDAY") },
			{ id: 1, name: $translate.instant("MONDAY") },
			{ id: 2, name: $translate.instant("TUESDAY") },
			{ id: 3, name: $translate.instant("WEDNESDAY") },
			{ id: 4, name: $translate.instant("THURSDAY") },
			{ id: 5, name: $translate.instant("FRIDAY") },
			{ id: 6, name: $translate.instant("SATURDAY") },
			{ id: 7, name: $translate.instant("DAY") },
			{ id: 8, name: $translate.instant("CALENDAR_RECUR_WEEKDAY") },
			{ id: 9, name: $translate.instant("CALENDAR_RECUR_WEEKEND_DAY") }
		];
		$scope.months = [
			{ id: 0, name: $translate.instant("MONTH_NAMES_JANUARY") },
			{ id: 1, name: $translate.instant("MONTH_NAMES_FEBRUARY") },
			{ id: 2, name: $translate.instant("MONTH_NAMES_MARCH") },
			{ id: 3, name: $translate.instant("MONTH_NAMES_APRIL") },
			{ id: 4, name: $translate.instant("MONTH_NAMES_MAY") },
			{ id: 5, name: $translate.instant("MONTH_NAMES_JUNE") },
			{ id: 6, name: $translate.instant("MONTH_NAMES_JULY") },
			{ id: 7, name: $translate.instant("MONTH_NAMES_AUGUST") },
			{ id: 8, name: $translate.instant("MONTH_NAMES_SEPTEMBER") },
			{ id: 9, name: $translate.instant("MONTH_NAMES_OCTOBER") },
			{ id: 10, name: $translate.instant("MONTH_NAMES_NOVEMBER") },
			{ id: 11, name: $translate.instant("MONTH_NAMES_DECEMBER") }
		];

		// Functions
		$scope.save = save;
		$scope.deleteTasksWithConfirm = deleteTasksWithConfirm;
		$scope.deleteTasks = deleteTasks;
		$scope.openTaskEmail = openTaskEmail;
		$scope.cancel = cancel;
		$scope.setForm = setForm;
		$scope.setFormDirty = setFormDirty;
		$scope.statusChanged = statusChanged;
		$scope.percentChanged = percentChanged;
		$scope.isOwner = isOwner;
		$scope.isReadOnly = isReadOnly;
		$scope.removeAttachedFile = removeAttachedFile;
		$scope.calculateCanSave = calculateCanSave;
		$scope.isRecurrenceCompatible = isRecurrenceCompatible;
		$scope.isRecurring = isRecurring;
		$scope.occurrenceStart = occurrenceStart;
		vm.hasSelectedCategory = hasSelectedCategory;
		vm.onCategoryChanged = onCategoryChanged;
		vm.onClearAllCategories = onClearAllCategories;
		vm.openManageCategoriesModal = openManageCategoriesModal;
		vm.categoryColorByName = categoryColorByName;

		// Activate
		activate();
		$scope.$on('$destroy', destroy);

		function activate() {
			themesService.ensureActivated();
			coreData
				.init()
				.then(onCoreDataInit, errorHandling.report);

			function onCoreDataInit() {
				userTimeService
					.init()
					.then(activate2, errorHandling.report);
			}
		}

		function activate2() {
			$rootScope.spinner.showInstant();

			var promises = [
				apiTaskSources.getSources(true)
			];

			if (!$scope.isNew)
				promises.push(coreDataTasks.getTaskFromServer($stateParams.owner, $stateParams.sourceId, $stateParams.taskId));

			$q
				.all(promises)
				.then(function (results) {
					$scope.sources = results[0];
					$scope.defaultSource = $scope.sources.find(source => source.owner === userDataService.user.username && source.isPrimary);
					$scope.currentSource = $scope.defaultSource;


					if (!$scope.isNew) {
						$scope.currentSource = $scope.sources.find(source => source.id === $stateParams.sourceId);
						$scope.taskInfo = $.extend(true, results[1],
							{
								sourcePermission: $scope.currentSource ? $scope.currentSource.permission : $stateParams.owner === userDataService.user.username ? 8 : 4
							});
						if (!$scope.taskInfo.start || $scope.taskInfo.start === "0001-01-01T00:00:00" ||
							!$scope.taskInfo.due || $scope.taskInfo.due === "0001-01-01T00:00:00") {
							$scope.useDateTime = false;
							$scope.taskInfo.start = null;
							$scope.taskInfo.due = null;
						}
						if (!$scope.taskInfo.reminder || $scope.taskInfo.reminder === "0001-01-01T00:00:00") {
							$scope.reminderSet = false;
							$scope.taskInfo.reminder = null;
						}
					}
					init();
				}, errorHandling.reportAndHideSpinner);

			$scope.$watch("useDateTime", function (newvalue, oldvalue) {
				if (newvalue === oldvalue) return;
				if (newvalue === false) {
					$scope.taskInfo.start = null;
					$scope.taskInfo.due = null;
				}
			});

			$scope.$watch("reminderSet", function (newvalue, oldvalue) {
				if (newvalue === oldvalue) return;
				if (newvalue === false) {
					$scope.taskInfo.reminder = null;
				}
			});

			$scope.$watch('taskInfo.start', function (newvalue, oldvalue) {
				if (newvalue === oldvalue) return;
				if ($scope.taskInfo && $scope.taskInfo.start) {
					if ($scope.taskInfo.due && $scope.taskInfo.due < $scope.taskInfo.start) {
						const duration = oldvalue && oldvalue < $scope.taskInfo.due ? 
							moment.duration(moment($scope.taskInfo.due).diff(moment(oldvalue))) : 
							moment.duration(1, "hours");
						$scope.taskInfo.due = moment($scope.taskInfo.start).add(duration).toDate();
					}
				}
			});
			
			$scope.$watch('taskInfo.due', function (newvalue, oldvalue) {
				if (newvalue === oldvalue) return;
				if ($scope.taskInfo && $scope.taskInfo.due) {
					if ($scope.taskInfo.start && $scope.taskInfo.start > $scope.taskInfo.due) {
						const duration = oldvalue && oldvalue > $scope.taskInfo.start ? 
							moment.duration(moment($scope.taskInfo.start).diff(moment(oldvalue))) : 
							moment.duration(1, "hours");
						$scope.taskInfo.start = moment($scope.taskInfo.due).add(duration).toDate();
					}
				}
			});
			$scope.$watch("currentSource", function (newvalue, oldvalue) {
				if (newvalue === oldvalue) return;
				if (newvalue.isDomainResource)
					$scope.afContext = "domainshare";
				else
					$scope.afContext = "user";
			});
			userDataService.init();

			$scope.$on("categoriesUpdated", onCategoryListModified);
		}

		function destroy() {
			window.onbeforeunload = undefined;
		}

		// Initialization
		function init() {
			var popoutData = authStorage.getPopoutData();
			if (!$.isEmptyObject(popoutData)) {
				$scope.taskInfo = popoutData.taskInfo;
				if (!$scope.taskInfo) { errorHandling.report("INVALID_POPOUT"); return; }

				$scope.taskInfo.start = moment($scope.taskInfo.start).toDate();
				$scope.taskInfo.due = moment($scope.taskInfo.due).toDate();
				$scope.taskInfo.reminder = moment($scope.taskInfo.reminder).toDate();
				$scope.originalTaskDesc = $scope.taskInfo.description;

				if (!popoutData.currentSource) {
					$scope.currentSource = $scope.defaultSource;
				} else {
					for (var i = 0; i < $scope.sources.length; i++) {
						if ($scope.sources[i].id === popoutData.currentSource.id && $scope.sources[i].owner === popoutData.currentSource.owner) {
							$scope.currentSource = $scope.sources[i];
							break;
						}
					}
				}
				apiCategories.categoryOwner = $scope.currentSource.owner;
				$scope.isNew = popoutData.isNew;
				if (!$scope.isNew) populateRecurrence();
				$scope.isLoaded = true;
				$rootScope.spinner.hide();
				$scope.editingItem = true;
			} else if (!$scope.isNew) {
				setupEditTask();
			} else { // new
				$scope.editingItem = true;
				$scope.currentSource = $scope.defaultSource;
				// If a user starts typing before we get here then their text will be erased, so we check for that
				if (!$scope.taskInfo) $scope.taskInfo = {};
				$scope.taskInfo.sourcePermission = 8;

				var start = moment();
				var dt = start.toDate();
				start.minute(5 * Math.round(start.minute() / 5));
				start.second(0);

				$scope.taskInfo.start = start.toDate();
				$scope.taskInfo.due = moment(start).add(1, "h").toDate();
				$scope.taskInfo.reminder = moment(start).subtract(5, "m").toDate();

				$scope.taskInfo.priority = 5;
				$scope.taskInfo.status = $scope.statusOptions[1].id;
				$scope.taskInfo.percentComplete = 0;

				// if the user clicks on a date from the calendar using resource view
				if (localStorage.taskPopout) {
					var taskPopout = JSON.parse(localStorage.taskPopout);
					start = taskPopout.start || (taskPopout.data ? taskPopout.data.start : undefined);
					if (start) start = moment.tz(start, userTimeService.userTimeZone.location);
					if (taskPopout.data) {
						$scope.taskInfo.start = start ? start.toDate() : taskPopout.data.start;
						$scope.taskInfo.due = moment(start).add(1, "h").toDate();
						$scope.taskInfo.reminder = moment(start).subtract(5, "m").toDate();
					}
					if (taskPopout.calId) {
						var results = $scope.sources.filter(x => x.id === taskPopout.calId);
						if (results && results.length === 1)
							$scope.currentSource = results[0];
					}
					delete localStorage.taskPopout;
				}

			}
			vm.attachmentGuid = coreData.generateGuid();
			vm.attachedFiles =
				$scope.taskInfo.attachedFiles ? JSON.parse(JSON.stringify($scope.taskInfo.attachedFiles)) : [];

			apiCategories.categoryOwner = $scope.currentSource.owner;
			$scope.categories = apiCategories.categoryOwner ? apiCategories.getCategories() : []; // domain shared tasks do not use categories
			setupCategories($scope.categories, $scope.taskInfo.categories);

			if (!$scope.taskInfo.status) $scope.taskInfo.status = 0;
			if ($scope.taskInfo.attachedMessage) {
				userTimeService
					.convertLocalToUserTime($scope.taskInfo.attachedMessage.date)
					.then(function (success) {
						if (success.getSeconds() >= 30) {
							success.setMinutes(success.getMinutes() + 1);
						}
						success.setSeconds(0);
						$scope.taskInfo.attachedMessage.date = success;
						$scope.isLoaded = true;
						$rootScope.spinner.hide();
					}
						, function (failure) {
							errorHandling.report(failure);
							$scope.isLoaded = true;
							$rootScope.spinner.hide();
						});
			} else {
				$scope.isLoaded = true;
				$rootScope.spinner.hide();
			}
		}

		function hasSelectedCategory() {
			if (!$scope.categories)
				return false;
			for (var i = 0; i < $scope.categories.length; i++)
				if ($scope.categories[i].selected) return true;
			return false;
		}

		function onCategoryChanged(cat) {
			if (cat)
				cat.selected = !cat.selected;
			vm.userForm.$setDirty();
		}

		function onClearAllCategories() {
			for (var i = 0; i < $scope.categories.length; i++)
				$scope.categories[i].selected = false;
			vm.userForm.$setDirty();
		}

		function openManageCategoriesModal(ev) {
			$mdDialog.show({
				controller: "manageCategoriesDialogController",
				controllerAs: "manageCategoriesCtrl",
				templateUrl: "app/shared/modals/manage.categories.dlg.html",
				targetEvent: ev
			});
		}

		function categoryColorByName(catName) {
			const cat = getCategoryByName(catName);
			if (!cat || cat.colorIndex == -1)
				return null;
			const color = apiCategories.getCategoryColor(cat.colorIndex);
			if (!color || !color.rgb)
				return null;
			return color.rgb;

			function getCategoryByName(categoryName) {
				if (!categoryName) return null;
				var results = $.grep($scope.categories, (cat) => cat.name.toUpperCase() === categoryName.toUpperCase());
				return results.length > 0 ? results[0] : null;
			}
		}

		function isReadOnly() {
			return ($scope.currentSource && $scope.currentSource.permission < 8) ||
				($scope.taskInfo && $scope.taskInfo.isDelegatedByOwner);
		}

		function setupEditTask() {
			$scope.editingItem = true;

			$scope.originalTaskDesc = $scope.taskInfo.description;

			if ($scope.taskInfo.start)
				$scope.taskInfo.start = coreDataTasks.convertLocalToTargetTz($scope.taskInfo.start, $scope.taskInfo.userTimeZone);
			if ($scope.taskInfo.due)
				$scope.taskInfo.due = coreDataTasks.convertLocalToTargetTz($scope.taskInfo.due, $scope.taskInfo.userTimeZone);
			if ($scope.taskInfo.reminder)
				$scope.taskInfo.reminder = coreDataTasks.convertLocalToTargetTz($scope.taskInfo.reminder, $scope.taskInfo.userTimeZone);

			var temp = $.grep($scope.sources, function (source) {
				return $scope.taskInfo.sourceId === source.id && $scope.taskInfo.sourceOwner === source.owner;
			});
			$scope.currentSource = temp.length > 0 ? temp[0] : $scope.sources[0];
			temp = $.grep($scope.sources, function (source) { return source.permission >= 8; });
			if (temp.length > 0)
				$scope.sources = temp;

			populateRecurrence();

			if ($scope.taskInfo.sourcePermission < 8) {
				$scope.skipNavigateConfirm = true;
				askBeforeLeave = false;
			}

		}

		function populateRecurrence() {
			if (!$scope.taskInfo.recurrence)
				return;

			$scope.currentRecurrence = getRecurrenceType($scope.taskInfo.recurrence.type);

			vm.recurUntilOption = $scope.taskInfo.recurrence.untilCountEnabled ? 1 : $scope.taskInfo.recurrence.untilDateEnabled ? 2 : 0;
			vm.recurUntilCount = $scope.taskInfo.recurrence.untilCount;
			vm.recurUntilDate = moment($scope.taskInfo.recurrence.untilDate).toDate();

			vm.recurStart = $scope.taskInfo.recurrence.startDate;
			vm.recurRegenerative = $scope.taskInfo.recurrence.isRegenerative;

			vm.dailyFreq = $scope.taskInfo.recurrence.isWeekdaysOnly ? 1 : 0;
			vm.dailyFreqDays = $scope.taskInfo.recurrence.dailyInterval;

			vm.weeklyFreqWeeks = $scope.taskInfo.recurrence.weeklyInterval;
			vm.weeklyDays = $scope.taskInfo.recurrence.weeklyDays;
			vm.weeklyDayStr = getWeeklyDayString();

			vm.monthlyFreq = $scope.taskInfo.recurrence.monthlySpecificDay ? 0 : 1;
			vm.monthlyFreqMonths = $scope.taskInfo.recurrence.monthlyInterval;
			vm.monthlyFreqDay = $scope.taskInfo.recurrence.monthDay;
			if ($scope.taskInfo.recurrence.monthlyPeriodIndex === 2147483647)
				$scope.taskInfo.recurrence.monthlyPeriodIndex = 4;
			vm.monthlyFreqPeriod = $scope.recurPeriods[$scope.taskInfo.recurrence.monthlyPeriodIndex];
			vm.monthlyFreqPeriodDay = $scope.daysOfWeek[$scope.taskInfo.recurrence.monthlyPeriodDayIndex];

			vm.yearlyFreq = $scope.taskInfo.recurrence.yearlySpecificDate ? 0 : 1;
			vm.yearlyFreqYears = $scope.taskInfo.recurrence.yearlyInterval;
			vm.yearlyFreqMonth = $scope.taskInfo.recurrence.yearlyMonthIndex;
			vm.yearlyFreqMonthDate = $scope.taskInfo.recurrence.yearlyDay;
			vm.yearlyFreqPeriod = $scope.taskInfo.recurrence.yearlyPeriodIndex;
			vm.yearlyFreqPeriodDay = $scope.taskInfo.recurrence.yearlyPeriodDayIndex;
			vm.yearlyFreqPeriodMonth = $scope.taskInfo.recurrence.yearlyMonthIndex;

			function getWeeklyDayString() {
				const selectedDays = [];
				for (let i = 0; i < vm.weeklyDays.length; i++) {
					if (vm.weeklyDays[i])
						selectedDays.push($scope.daysOfWeek[i].name);
				}

				if (selectedDays.length === 1)
					return selectedDays[0];

				var retStr = "";
				for (let i = 0; i < selectedDays.length - 1; i++)
					retStr += selectedDays[i] + ", ";
				retStr += $filter("translate")("AND").toLowerCase() + " " + selectedDays[selectedDays.length - 1];

				return retStr;
			}
		}

		$scope.onSourceChanged = function (source) {
			$scope.currentSource = source;
            if ($scope.currentSource.owner !== apiCategories.categoryOwner) {
                apiCategories.categoryOwner = $scope.currentSource.owner;
                onCategoryListModified();
            }
			vm.userForm.$setDirty();
		};

		// Button Functions
		// Save
		function save() {
			$scope.taskInfo.description = $scope.editorScope.getHtml();

			$timeout(function () {
				$scope.editingItem = false;
				if ($scope.isNew) {
					$scope.taskInfo.sourceOwner = $scope.currentSource.owner;
					$scope.taskInfo.sourceName = $scope.currentSource.name;
					$scope.taskInfo.sourceId = $scope.currentSource.id;
				}

				var origSourceOwner = $scope.taskInfo.sourceOwner;
				var origSourceId = $scope.taskInfo.sourceId;
				var origTaskId = $scope.taskInfo.id;
				var moved = false;

				if (!$scope.isNew && origSourceId !== $scope.currentSource.id) {
					moved = true;
					$scope.taskInfo.id = undefined;
					$scope.taskInfo.sourceOwner = $scope.currentSource.owner;
					$scope.taskInfo.sourceName = $scope.currentSource.name;
					$scope.taskInfo.sourceId = $scope.currentSource.id;
				}
				if (vm.attachedFiles.some(x => x.isNew)) {
					$scope.taskInfo.attachmentGuid = vm.attachmentGuid;
				}
				var start = moment.tz(moment($scope.taskInfo.start).format("YYYY-MM-DDTHH:mm:ss"), vm.userTimeZone.location);
				$scope.taskInfo.start = start;

				var due = moment.tz(moment($scope.taskInfo.due).format("YYYY-MM-DDTHH:mm:ss"), vm.userTimeZone.location);
				$scope.taskInfo.due = due;

				var reminder = moment.tz(moment($scope.taskInfo.reminder).format("YYYY-MM-DDTHH:mm:ss"), vm.userTimeZone.location);
				$scope.taskInfo.reminder = reminder;

				$scope.taskInfo.categories = $.grep($scope.categories, function (cat) { return cat.selected; });
				if ($scope.taskInfo.description && $scope.taskInfo.description.indexOf("\"/attachedfile?data=") > -1) {
					$scope.taskInfo.description = $scope.taskInfo.description.split("\"/attachedfile?data=").join('\"attlinkedfileid:');
				}
				$rootScope.spinner.show();
				try {
					deleteRemovedAttachedFiles(origSourceId, origSourceOwner)
						.then(
							function () {
								coreDataTasks.saveTasks([$scope.taskInfo])
									.then(
										function (data) {
											$scope.tasks = coreDataTasks.getFilteredTasks();
											$rootScope.spinner.hide();
											if (moved) {
												var newItemId = data[0].itemId;

												var delTask = {
													sourceOwner: origSourceOwner,
													sourceId: origSourceId,
													id: origTaskId
												};
												moveAttachedFiles(origSourceId,
													$scope.taskInfo.sourceId,
													$scope.taskInfo.itemId,
													newItemId)
													.then(() => coreDataTasks.removeTasks([delTask]))
													.then(
														leavePage,
														function (failure) {
															$rootScope.spinner.hide();
															errorMessageService
																.showErrorMessageFromString(failure.data);
														});
											} else
												leavePage();
										},
										function (failure) {
											$rootScope.spinner.hide();
											if (failure.data && failure.data.message)
												errorMessageService.showErrorMessageFromString(failure.data.message);
											else
												errorMessageService.showErrorMessageFromString(failure.data);
										});
							},
							function (failure) {
								errorMessageService.showErrorMessage(failure);
								$rootScope.spinner.hide();
							});
				} catch (err) {
					errorHandling.report(err);
					$rootScope.spinner.hide();
				}
			});

			function deleteRemovedAttachedFiles(sourceId, ownerEmail) {
				if (!$scope.removedAttachedFiles || $scope.removedAttachedFiles.length === 0) {
					var defer = $q.defer();
					defer.resolve();
					return defer.promise;
				}
				var source = $scope.sources.filter(src => src.id === sourceId)[0];
				var removeParams = JSON.stringify({
					folderId: source.folderId,
					owner: ownerEmail,
					removeFileReferences: $scope.removedAttachedFiles
				});
				return $http.post("~/api/v1/filestorage/remove-attached-file", removeParams);
			}

			function moveAttachedFiles(oldSourceId, newSourceId, oldItemId, newItemId) {
				if (!$scope.taskInfo.attachedFiles || $scope.taskInfo.attachedFiles.length === 0) {
					var defer = $q.defer();
					defer.resolve();
					return defer.promise;
				}
				var oldSource = $scope.sources.filter(src => src.id === oldSourceId)[0];
				var newSource = $scope.sources.filter(src => src.id === newSourceId)[0];
				var moveParams = JSON.stringify({
					oldOwner: oldSource.owner,
					newOwner: newSource.owner,
					oldFolderId: oldSource.folderId,
					oldItemId: oldItemId,
					newFolderId: newSource.folderId,
					newItemId: newItemId
				});
				return $http.post("~/api/v1/filestorage/move-attached-files", moveParams);
			}
		}

		// Delete
		function deleteTasksWithConfirm(selectedCards, ev) {
			if (selectedCards.length > 0) {

				var confirm = $mdDialog.confirmDeletion()
					.textContent($filter('translate')('CONFIRMATIONS_DELETE_ITEMS', { items: selectedCards.length }))
					.targetEvent(ev);
				$mdDialog.show(confirm)
					.then(function () { $scope.deleteTasks(selectedCards, ev); }, null);
			} else {
				errorHandling.report("ERROR_NO_CARDS");
				$rootScope.spinner.hide();
			}
		}

		function deleteTasks(selectedCards, ev) {
			$rootScope.spinner.show();
			try {
				coreDataTasks.removeTasks(selectedCards)
					.then(function () {
						$rootScope.spinner.hide();
						leavePage();
					}, function (failure) {
						$rootScope.spinner.hide();
						errorMessageService.showErrorMessageFromString(failure.data);
					});
			}
			catch (err) {
				errorHandling.reportAndHideSpinner(err);
			}
		}

		// Cancel
		function cancel() {
			leavePage();
		}

		function openTaskEmail(message) {
			const packet = emailNavigation.makeEmailPacket(message.owner, message.folder, message.uid);
			const url = emailNavigation.getPopoutUrl(packet);
			window.open(url,
				"emailID" + message.uid,
				'resizable=1, ' + popupService.dimensions.email);
		}

		function leavePage() {
			$rootScope.$broadcast("calendarRefresh");
			$scope.skipNavigateConfirm = true;
			askBeforeLeave = false;
			$timeout(function () { window.close(); });
		}

		function getRecurrenceType(id) {
			var temp = $.grep($scope.recurrenceOptions, function (option) { return id === option.id; });
			if (temp.length > 0)
				return temp[0];

			return $scope.recurrenceOptions[0];
		}

		function isOwner() {
			return $scope.currentSource && $scope.currentSource.owner === userDataService.user.username;
		}

		function isRecurrenceCompatible() {
			if (!$scope.currentRecurrence) return false;
			if ($scope.currentRecurrence.id === 0) return true;
			const recurrence = $scope.taskInfo.recurrence;
			if (recurrence.type === 6 && recurrence.monthlyPeriodDayIndex < 0) return false;
			if (recurrence.type === 7 && recurrence.yearlyPeriodDayIndex < 0) return false;
			return true;
		}

		function isRecurring() {
			if ($scope.isNew) return false;
			if (!$scope.currentRecurrence) return false;
			if ($scope.currentRecurrence.id === $scope.recurrenceType.ONCE) return false;
			return true;
		}

		function occurrenceStart() {
			//if (vm.originalDetails.firstInstanceStart) {
			//	var firstInstance = moment.tz(vm.originalDetails.firstInstanceStart.dt, vm.originalDetails.firstInstanceStart.tz);
			//	return firstInstance.toDate();
			//}
			//return $scope.start;
		}

		// Category Functions
		function setupCategories(categories, selectedCategories) {
            categories.forEach(function (cat) { cat.selected = false; });

            if (selectedCategories) {
                categories = selectedCategories.reduce(function (res, cat) {
                    const masterCat = res.find(c => c.name === cat.name);
                    if (masterCat) {
                        masterCat.selected = cat.selected;
                    } else if (cat.selected) {
                        res.push({ name: cat.name, colorIndex: -1, master: false, translatedName: $filter("translate")(cat.name), selected: true });
                    }
                    return res;
                }, 
                categories);

            }

            return categories;
		}

		function onCategoryListModified() {
			var usercategories = apiCategories.getCategories();
			$scope.categories = setupCategories(usercategories, $scope.categories);
		}

		function setForm(form) {
			vm.userForm = form;

			$timeout(function () {
				vm.userForm.$setPristine();
				vm.userForm.$setUntouched();
			});
		}
		vm.fileUploadedCallback = function() {
			vm.userForm.$setDirty();
		}
		function setFormDirty(form) {
			form.$setDirty();
			$scope.$applyAsync();
		}

		function removeAttachedFile(attachment, ev) {
			if ($scope.isReadOnly() && !attachment) return;
			if (attachment.isNew && typeof attachment.remove === "function") {
				attachment.remove();
				return;
			}
			$scope.removedAttachedFiles.push(attachment.fileReference);
			vm.userForm.$setDirty();
		};

		function calculateCanSave() {
			return !$scope.isReadOnly();
		}

		function generateReminderOptions() {
			var reminderOptions = [], id;

			// None
			reminderOptions.push({ id: 0, name: $filter("translate")("NONE") });

			// Minutes
			id = 1;
			for (var numMinutes = 0; numMinutes <= 15; numMinutes += 5)
				reminderOptions.push({ id: id++, name: $filter("translate")("REMINDER_MINUTES", { numMinutes: numMinutes }) });
			reminderOptions.push({ id: 5, name: $filter("translate")("REMINDER_MINUTES", { numMinutes: 30 }) });

			// Hours
			id = 6;
			for (var numHours = 1; numHours <= 12; ++numHours)
				reminderOptions.push({ id: id++, name: $filter("translate")("REMINDER_HOURS", { numHours: numHours }) });
			reminderOptions.push({ id: 18, name: $filter("translate")("REMINDER_HOURS", { numHours: 24 }) });

			// Days
			id = 19;
			for (var numDays = 2; numDays <= 4; ++numDays)
				reminderOptions.push({ id: id++, name: $filter("translate")("REMINDER_DAYS", { numDays: numDays }) });

			// Weeks
			reminderOptions.push({ id: 22, name: $filter("translate")("REMINDER_WEEKS", { numWeeks: 1 }) });
			reminderOptions.push({ id: 23, name: $filter("translate")("REMINDER_WEEKS", { numWeeks: 2 }) });

			return reminderOptions;
		}

		function statusChanged(status) {
			if (status === 1)
				$scope.taskInfo.percentComplete = 100;
		}

		function percentChanged(percent) {
			if (percent === 100)
				$scope.taskInfo.status = 1;
			else if (percent > 0)
				$scope.taskInfo.status = 2;
		}
		$scope.trustDescriptionHtml = function (htmlText) {
			// Use this here as the content is sanitized by the server and ng-bind-html strips inline styling.
			return $sce.trustAsHtml(htmlText);
		};
		window.onbeforeunload = function () {
			if (vm.userForm.$dirty && askBeforeLeave) {
				askBeforeLeave = false;
				return "";
			}
		};
	}
})();
