(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("domainSettingsContentFiltersController", domainSettingsContentFilterController);

	function domainSettingsContentFilterController($rootScope, $scope, $http, $filter, $mdDialog, $state, $compile, 
		coreDataDomainSettings, errorHandling, successHandling, NgTableParams, gridCheckboxes, contentFilterData) {

		var vm = this;
		$scope.contentFiltering = undefined;
		$scope.settingCore = coreDataDomainSettings;
		$scope.filterTypes = contentFilterData.filterTypes;
		$scope.actionTypes = contentFilterData.actionTypes;
		vm.tableParams = new NgTableParams(
			{
				sorting: { order: "desc" },
				count: 25
			}, {
			getData: queryData,
			counts: $rootScope.commonTableCounts
		});
		vm.checkboxes = gridCheckboxes.init("title");
		vm.checkboxes.table = vm.tableParams;
		vm.initialized = false;

		// Functions
		vm.deleteFilters = deleteFilters;
		vm.editFilter = editFilter;
		vm.newFilter = newFilter;
		vm.getActionsString = getActionsString;
		vm.getRulesString = getRulesString;
		vm.contextMenuGridItem = contextMenuGridItem;
		vm.setLowerPriority = setLowerPriority;
		vm.setHigherPriority = setHigherPriority;

		activate();

		//////////////////////////////

		function activate() {
			reloadData();
		}

		function reloadData() {
			$http
				.get("~/api/v1/settings/domain/content-filter-groups")
				.then(onDataLoaded, errorHandling.report);
		}

		function onDataLoaded(success) {
			$scope.contentFiltering = success.data.contentFilterGroups || [];
			coreDataDomainSettings.cards = angular.copy($scope.contentFiltering);
			coreDataDomainSettings.updateFilteredCards();
			coreDataDomainSettings.editItem = vm.editFilter;
			coreDataDomainSettings.newItem = vm.newFilter;
			coreDataDomainSettings.deleteItems = vm.deleteFilters;
			vm.checkboxes.reset();
			vm.tableParams.reload();
			vm.initialized = true;
		}

		function editFilter(selectedCard, ev) {
			if (!coreDataDomainSettings.selectMode) {
				$state.go(".specific", { contentfilterid: selectedCard.id });
			} else {
				var index = coreDataDomainSettings.selectedCards.indexOf(selectedCard);
				if (index > -1) {
					coreDataDomainSettings.selectedCards.splice(index, 1);
				} else {
					coreDataDomainSettings.selectedCards.push(selectedCard);
				}
			}
		}

		function newFilter(ev) {
			$state.go(".specific", { contentfilterid: "new" });
		};

		function deleteFilters(ev) {
			var rows = [];
			for (var key in vm.checkboxes.items) {
				if (vm.checkboxes.items[key]) {
					rows = rows.concat($.grep(vm.listResults, function (r) { return r.title === key }));
				}
			}

			if (!rows || rows.length === 0) {
				errorHandling.warn("ERROR_NO_CARDS");
				return;
			}

			var confirm = $mdDialog.confirmDeletion()
				.textContent($filter("translate")("CONFIRMATIONS_DELETE_ITEMS", { items: rows.length }))
				.targetEvent(ev);

			$mdDialog.show(confirm).then(onDoDelete);

			function onDoDelete() {
				var idsToRemove = [];
				angular.forEach(rows, function (value, key) {
					idsToRemove.push(value.id);
				});

				var params = JSON.stringify({ toRemove: idsToRemove });
				$http
					.post("~/api/v1/settings/domain/content-filter-groups", params)
					.then(reloadData, errorHandling.report);
			}
		}

		function getActionsString(card) {

			if (!card.actions || card.actions.length === 0) {
				return $filter("translate")("NONE");
			} else if (card.actions.length === 1) {
				var actionType = $.grep($scope.actionTypes,
					function(at) { return at.id === card.actions[0].actionType; });
				return actionType.length > 0
					? actionType[0].translate
					: $filter("translate")($scope.actionTypes[card.actions[0].actionType].translate);
			} else {
				return $filter("translate")("MULTIPLE", { count: card.actions.length });
			}
		}

		function getRulesString(card) {
			if (!card.filters || card.filters.length === 0) {
				return $filter("translate")("NONE");
			} else if (card.filters.length === 1) {
				return $filter("translate")($scope.filterTypes[card.filters[0].fieldType].translate);
			} else {
				return $filter("translate")("MULTIPLE", { count: card.filters.length });
			}
		}

		function queryData(params) {
			var sortType = "";
			var ascending = "";
			var startIndex = (params.page() - 1) * params.count();
			var count = params.count();
			for (var k in params.sorting()) {
				if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
					continue;
				sortType = k;
				ascending = params.sorting()[k] !== "desc";
				break;
			}

			coreDataDomainSettings.cards = $filter("orderBy")(coreDataDomainSettings.cards, sortType, ascending);
			vm.listResults = angular.copy(coreDataDomainSettings.cards);
			vm.listResults = vm.listResults.splice(startIndex, count);

			params.total(coreDataDomainSettings.cards.length);
			return vm.listResults;
		}

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== "touchstart" && ev.type !== "touchend" && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === item.title })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item.title];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: "deleteFilters", click: deleteFilters, params: $scope.dropdownEvent, translateKey: "DELETE" },
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $("#context-menu-area");
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		function setLowerPriority(row) {
			if (row.order === vm.tableParams.total())
				return;

			$http
				.post("~/api/v1/settings/domain/content-filter-groups/order/" + row.id + "/false")
				.then(onSuccess, errorHandling.report);

			function onSuccess(success) {
				successHandling.report();
				onDataLoaded(success);
			}
		}

		function setHigherPriority(row) {
			if (row.order === 1)
				return;

			$http
				.post("~/api/v1/settings/domain/content-filter-groups/order/" + row.id + "/true")
				.then(onSuccess, errorHandling.report);

			function onSuccess(success) {
				successHandling.report();
				onDataLoaded(success);
			}
		}
	}
})();