(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sharedEditCustomFieldController", sharedEditCustomFieldController);

	function sharedEditCustomFieldController($scope, $mdDialog, name, defaultValue) {
		$scope.name = name;
		$scope.defaultValue = defaultValue;
		$scope.isValid = true;
		$scope.isNameValid = true;
	    $scope.editing = !!name;
		$scope.isNew = name === undefined;
		// Functions
		$scope.save = save;
		$scope.cancel = cancel;
		$scope.deleteItem = deleteItem;

		activate();

		/////////////////////

		function activate() {
			$scope.$watch("name", function (newValue, oldValue) {
				if (newValue === oldValue)
					return;
				$scope.isNameValid = $scope.name && $scope.name.length > 0;
				$scope.isValid = true;
			});
		}

		function save() {
			if (!($scope.isValid = validation()))
				return;

			$mdDialog.hide({
				name: $scope.name,
				defaultValue: $scope.defaultValue
			});
		};

		function cancel() {
			$mdDialog.cancel();
		};
		function deleteItem() {
			if ($scope.isNew) 
				return;
			$mdDialog.hide({
				delete: true
			});
		};
		function validation() {
			$scope.isNameValid = $scope.name && $scope.name.length > 0;
			return $scope.isNameValid;
		}
	}

})();
