(function () {
	angular.module("smartertools").filter('friendlyDate', function () {
		return function (date, long, toLocal) {

			var m = moment.utc(date, "YYYY-MM-DD HH:mm");
			if (!m.isValid())
				m = moment.utc(date);
			if (long == 'true' || long == true) {
				if (toLocal == 'true' || toLocal == true) {
					m = m.local();
				}
				return m.calendar(new Date().getDate(), {
					sameDay: '[Today] h:mma - M.D.YYYY',
					nextDay: '[Tomorrow] h:mma - M.D.YYYY',
					nextWeek: 'dddd h:mma - M.D.YYYY',
					lastDay: '[Yesterday] h:mma - M.D.YYYY',
					lastWeek: '[Last] dddd h:mma - M.D.YYYY',
					sameElse: 'dddd h:mma - M.D.YYYY'
				})
			}
			else
			{
				if (toLocal == 'true' || toLocal == true) {
					m = m.local();
				}
				return m.format("l LT");
			}
		}
	});
})();

(function () {
	angular.module("smartertools").filter('friendlyDateShort', function () {
		return function (date, toLocal) {

			var d = moment.utc(date, "YYYY-MM-DD HH:mm");
			if (toLocal == 'true' || toLocal == true)
				d = d.local();
			
			return d.calendar(new Date().getDate(), {
				sameDay: 'h:mma',
				nextDay: '[Tomorrow] h:mma',
				nextWeek: 'M.D.YYYY',
				lastDay: 'ddd h:mma',
				lastWeek: 'ddd h:mma',
				sameElse: 'M.D.YYYY'
			})
		}
	});
})();

(function () {
	angular.module("smartertools").filter('friendlyTimespan', function () {
		return function (startDate, endDate) {
			var start = moment(startDate, "YYYY-MM-DD HH:mm:ss");
			var end = endDate != undefined ? moment(startDate, "YYYY-MM-DD HH:mm:ss") : moment();
			var diff = start.diff(endDate);
			if (diff < 0) diff *= -1;
			var span = moment.duration(diff);

			if (span.years() > 0)
				return moment.duration(span).format("y[y] M[M]");
			else if (span.months() > 0)
				return moment.duration(span).format("M[M] d[d]");
			else if (span.days() > 0)
				return moment.duration(span).format("d[d] h[h]");
			else if (span.hours() > 0)
				return moment.duration(span).format("h[h] m[m]");
			else
				return moment.duration(span).format("m[m] s[s]");
		}
	});
})();

(function () {
	angular.module("smartertools").filter('friendlyDateNoTime', function () {
		return function (date, long, toLocal) {
			var m = moment.utc(date, "YYYY-MM-DD HH:mm");
			if (!m.isValid())
				m = moment.utc(date);
			if (toLocal == 'true' || toLocal == true) {
				m = m.local();
			}
			return m.format('l');
		}
	});
})();

(function () {
    angular.module("smartertools").filter('friendlyDateHourly', function () {
        return function (date, long, toLocal) {
			var m = moment.utc(date, "YYYY-MM-DD HH:00");
			if (!m.isValid())
				m = moment.utc(date);
            if (toLocal == 'true' || toLocal == true) {
                m = m.local() ;
            }
            return m.format('l') + m.format(' HH:00');
        }
    });
})();

(function () {
    angular.module("smartertools").filter('friendlyDateLocalized', function () {
        return function (date, toLocal) {
			var m = moment.utc(date, "YYYY-MM-DD HH:mm");
			if (!m.isValid())
				m = moment.utc(date);
            if (toLocal == 'true' || toLocal == true) {
                m = m.local();
            }
            return m.format("LL");
        }
    });
})();

(function () {
	angular.module("smartertools").filter('friendlyDateTimeLocalized', function () {
		return function (date) {
			var m = moment.utc(date, "YYYY-MM-DD HH:mm").local();
			return m.format("LLL");
		}
	});
})();