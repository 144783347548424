(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("uploadCropperController", uploadCropperController);

	function uploadCropperController($mdDialog, $timeout, $scope, imageData, dimensions) {
		var vm = this;

		vm.imageData = imageData;
		vm.imageCropper = undefined;

		vm.save = save;
		vm.cancel = cancel;

		activate();

		function activate() {
			$timeout(function () {
				vm.imageCropper = $('#imageCropper').croppie({
					enableExif: true,
					viewport: {
						width: dimensions.width,
						height: dimensions.height
					}
				});
				vm.imageCropper.croppie('bind', {
					url: vm.imageData
				}).then(function () { }, function () {});
			});
		};

		function save() {
			var result = vm.imageCropper.croppie('result', { type: 'blob' })
				.then(function blob(result) {
					$mdDialog.hide({ blob: result });
				}, function () {});
		}

		function cancel() {
			$mdDialog.cancel();
		}
	};
})();