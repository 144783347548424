(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("adminSettingsLogSettingsController", adminSettingsLogSettingsController);

	function adminSettingsLogSettingsController($scope, $http, $mdDialog, errorHandling, coreDataFileStorage) {
		var vm = this;

		// Functions
		this.save = save;
		this.doCancel = doCancel;

		this.refresh = refresh;
		this.showProtocolLogWarning = showProtocolLogWarning;


		activate();

		//////////////

		function activate() {
			refresh();
			$scope.$emit("troubleshooting:countersChanged");
		}

		function refresh() {

			$http
				.get("~/api/v1/settings/sysadmin/global-mail")
				.then(onGlobalMailSettingsLoaded, function () { });

			function onGlobalMailSettingsLoaded(result) {
				var settings = angular.copy(result.data.globalMailSettings);
				var logSettings = settings.logSettings;

				// Log files
				vm.compressLogFiles = logSettings.logCompressionInterval;
				vm.compressLogFilesEnabled = logSettings.logCompressionEnabled;
				vm.deleteLogFiles = logSettings.logCleaningInterval;
				vm.deleteLogFilesEnabled = logSettings.logCleaningEnabled;

				// Log detail levels
				vm.activeSync = logSettings.activeSyncLogLevel;
				vm.administrative = logSettings.webMailLogLevel;
				vm.autoCleanFolders = logSettings.autoCleanFoldersLogLevel;
				vm.calendars = logSettings.calendarsLogLevel;
				vm.certificates = logSettings.certificatesLogLevel;
				vm.contentfilters = logSettings.contentFilterLogLevel;
				vm.conversion = -1000;
				vm.delivery = logSettings.deliveryLogLevel;
				vm.events = logSettings.eventsLogLevel;
				vm.ews = logSettings.ewsLogLevel;
				vm.exchangeRetrieval = logSettings.exchangeRetrievalLogLevel;
				vm.imap = logSettings.imapLogLevel;
				vm.imapRetrieval = logSettings.imapRetrievalLogLevel;
				vm.indexing = logSettings.indexingLogLevel;
				vm.ldap = logSettings.ldap_LogLevel;
				vm.mapi = logSettings.mapiLogLevel;
				vm.mailingListDebug = logSettings.mailingListDebugLogLevel;
				vm.maintenance = logSettings.maintenanceLogLevel;
				vm.messageId = logSettings.messageIDLogLevel;
				vm.oab = logSettings.oabLogLevel;
				vm.pop = logSettings.popLogLevel;
				vm.popRetrieval = logSettings.popRetrievalLogLevel;
				vm.smtp = logSettings.smtpLogLevel;
				vm.webdav = logSettings.webDAVLogLevel;
				vm.sharePoint = logSettings.sharePointLogLevel;
				vm.xmpp = logSettings.xmppLogLevel;
				vm.spamChecks = logSettings.spamChecksLogLevel;
				vm.autodiscover = logSettings.autodiscoverLogLevel;
				vm.general = logSettings.generalErrorsLogLevel;
				vm.errorLogging = logSettings.errorLogLevel;	// IIS logging
				vm.licensingLogging = logSettings.licenseLogLevel;

				vm.debugLogIds = logSettings.debugLogIds ? logSettings.debugLogIds.filter(onlyUnique).join("\n") : "";

				// Indexing
				vm.maxThreads = settings.indexSettings.maxThreads;
				vm.itemsToIndexPerPass = settings.indexSettings.itemsToIndexPerPass;
				vm.secondsInQueueBeforeIndexing = settings.indexSettings.secondsInQueueBeforeIndex;
				vm.fileCacheSize = settings.indexSettings.fileCacheSize;
				vm.fileCacheTrackStatistics = settings.indexSettings.fileCacheTrackStatistics;
				vm.openTelemetryEndpoint = settings.indexSettings.openTelemetryEndpoint;
				
				vm.pageForm.$setSubmitted();
			}
		}

		function doCancel(ev, form) {
			if (!form.$dirty) return;
			$mdDialog
				.show($mdDialog.confirmRevertChanges().targetEvent(ev))
				.then(function () { refresh(); form.$setPristine(); }, function () { });
		}

		function save(ev, form) {
			if (form.$invalid) {
				errorHandling.report("CORRECT_INVALID_FIELDS");
				return;
			}

			var uniqueIds = vm.debugLogIds ? vm.debugLogIds.split("\n").filter(onlyUnique) : [];

			var params = {
				globalMailSettings: {
					indexSettings: {
						maxThreads: vm.maxThreads,
						itemsToIndexPerPass: vm.itemsToIndexPerPass,
						secondsInQueueBeforeIndex: vm.secondsInQueueBeforeIndexing,
						fileCacheSize: vm.fileCacheSize,
						fileCacheTrackStatistics: vm.fileCacheTrackStatistics,
						openTelemetryEndpoint: vm.openTelemetryEndpoint
					},
					logSettings: {
						enableMailingListDebugLog: vm.mailingListDebug < 1000,
						exchangeRetrievalLogLevel: vm.exchangeRetrieval,
						logCompressionInterval: vm.compressLogFiles,
						logCompressionEnabled: vm.compressLogFilesEnabled,
						logCleaningInterval: vm.deleteLogFiles,
						logCleaningEnabled: vm.deleteLogFilesEnabled,
						activeSyncLogLevel: vm.activeSync,
						webMailLogLevel: vm.administrative,
						calendarsLogLevel: vm.calendars,
						certificatesLogLevel: vm.certificates,
						contentFilterLogLevel: vm.contentfilters,
						autoCleanFoldersLogLevel: vm.autoCleanFolders,
						deliveryLogLevel: vm.delivery,
						eventsLogLevel: vm.events,
						ewsLogLevel: vm.ews,
						imapLogLevel: vm.imap,
						imapRetrievalLogLevel: vm.imapRetrieval,
						indexingLogLevel: vm.indexing,
						ldap_LogLevel: vm.ldap,
						mapiLogLevel: vm.mapi,
						mailingListDebugLogLevel: vm.mailingListDebug,
						maintenanceLogLevel: vm.maintenance,
						messageIDLogLevel: vm.messageId,
						oabLogLevel: vm.oab,
						popLogLevel: vm.pop,
						popRetrievalLogLevel: vm.popRetrieval,
						smtpLogLevel: vm.smtp,
						webDAVLogLevel: vm.webdav,
						sharePointLogLevel: vm.sharePoint,
						xmppLogLevel: vm.xmpp,
						spamChecksLogLevel: vm.spamChecks,
						autodiscoverLogLevel: vm.autodiscover,
						generalErrorsLogLevel: vm.general,
						errorLogLevel: vm.errorLogging,	// IIS logging
						licenseLogLevel: vm.licensingLogging,
						debugLogIds: uniqueIds
					},
					mapiSettings: {
						debugMonitorRequestsMax: vm.maxRequestBeforeStop,
						debugMonitorUser: (vm.monitorActive ? vm.monitorUser : "")
					}
				}
			};

			$http
				.post("~/api/v1/settings/sysadmin/global-mail/", params)
				.then(function () { onSaveSuccess(uniqueIds); }, errorHandling.report);

			function onSaveSuccess(uniqueIds) {
				vm.debugLogIds = uniqueIds.filter(onlyUnique).join("\n");
				form.$setPristine();
			}
		}

		function onlyUnique(value, index, self) {
			return self.indexOf(value) === index;
		}

		function showProtocolLogWarning() {
            return vm.activeSync < 100 || vm.mapi < 100 || vm.ews < 100;
        }
	}

})();
