(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("editOwnShareDialogController", editOwnShareDialogController);

    function editOwnShareDialogController($rootScope, $mdDialog, $http, $filter, $timeout, errorHandling, errorMessageService, coreDataSettings, coreData, folderId , folder) {
        var vm = this;
        var FOLDERTYPE_NOTES = 5;
        var FOLDERTYPE_CONTACTS = 1;
        var FOLDERTYPE_APPOINTMENTS = 2;
        var FOLDERTYPE_TASKS = 4;
        vm.folder = folder;
        vm.folderId = folderId;
        vm.ready = false;
        vm.edition = 2;
        vm.userPermissions = [];
        vm.groupPermissions = [];
        vm.userGroups = [];
        vm.selectedItem = [];
        vm.stopSharingVisible = false;
        vm.galEnabled = coreData.user.settings.features.enableGlobalAddressList;
        vm.canSetPermissions = true;

        // functions
        vm.cancel = cancel;
        vm.stopSharing = stopSharing;
        vm.save = save;
        vm.focusUserInput = focusUserInput;
        vm.removeUser = removeUser;
        vm.removeGroup = removeGroup;
        vm.queryUsers = queryUsers;
        vm.onEmailChanged = onEmailChanged;
        vm.onEmailBlurred = onEmailBlurred;
        vm.onGroupChanged = onGroupChanged;

        // Startup
        activate();

        ///////////////////////////////////////////////////

        function activate() {
            if (!vm.folder && !vm.folderId)
                cancel();

            if (vm.folder) {
	            vm.folder.folderName = $filter('folderTranslate')(vm.folder.translatableName) || 
		            vm.folder.displayPath || $filter('folderTranslate')(vm.folder.folderName);
	            vm.folderId = vm.folder.folderId;
	            onFolderLoaded();
            } else {
				$http.get("~/api/v1/folders/get-owned-virtual-folder/" + vm.folderId)
					.then(function (success) {
						if (!success.data.success) {
							cancel();
							return;
						}
						vm.folder = success.data.virtualFolder;
						vm.folder.folderName = $filter('folderTranslate')(vm.folder.translatableName) || vm.folder.displayPath || $filter('folderTranslate')(vm.folder.folderName);
						onFolderLoaded();
						vm.ready = true;
					}, errorHandling.report);
            }
        }

        function onFolderLoaded() {
	        vm.ready = true;
            vm.originalValues = {
                isPrimary: vm.folder.isPrimary
            };

            vm.stopSharingVisible = (vm.folder.accessUsers && vm.folder.accessUsers.length) || (vm.folder.accessGroups && vm.folder.accessGroups.length);
            vm.host = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');

            switch (vm.folder.folderType) {
                case FOLDERTYPE_NOTES: vm.typeName = "notes"; break;
                case FOLDERTYPE_CONTACTS: vm.typeName = "contacts"; break;
                case FOLDERTYPE_APPOINTMENTS: vm.typeName = "appointments"; break;
                case FOLDERTYPE_TASKS: vm.typeName = "tasks"; break;
                default: vm.typeName = "email"; break;
            }

            for (var j = 0; j < vm.folder.accessUsers.length; ++j) {
                vm.userPermissions.push(vm.folder.accessUsers[j]);
                vm.selectedItem[j] = vm.folder.accessUsers[j].username;
            }
            vm.userPermissions.push({ access: 4, user: { username: "" } });

            coreDataSettings.settingsData.userGroups
                .then(function (success) {
	                var i;
                    vm.userGroups.push({ groupName: $filter("translate")("SELECT_A_GROUP"), userGroupId: undefined, selectText: true });
                    vm.userGroups.push({ groupName: $filter("translate")("EVERYONE"), userGroupId: -1, isEveryone: true });
                    vm.userGroups.push({ groupName: $filter("translate")("SHARED_RESOURCES_ADMIN"), userGroupId: -2, isAdmins: true });
                    var grps = $.extend(true, [], success);
                    for (i = 0; i < grps.length; i++) {
                        vm.userGroups.push({ groupName: grps[i].name, userGroupId: grps[i].id });
                    }

                    for (i = 0; i < vm.folder.accessGroups.length; ++i) {
                        vm.groupPermissions.push(vm.folder.accessGroups[i]);
                    }

                    if (vm.groupPermissions.length > 0) {
                        angular.forEach(vm.groupPermissions, function (share) {
                            for (i = 0; i < vm.userGroups.length; i++) {
                                if (share.userGroupId == vm.userGroups[i].userGroupId) {
                                    share.group = vm.userGroups[i];
                                    break;
                                }
                            }
                        });
                    }
                    vm.groupPermissions.push({ access: 4, group: vm.userGroups[0] });
                });
        }

        function queryUsers(query) {
	        return coreDataSettings.queryUsers(query);
        }

        function onEmailChanged(last, index, form) {
            var email = form["email" + index].$viewValue;
            if (email && email.length > 0 && last) {
                vm.userPermissions.push({ access: 4, user: { username: "" } });
            }
        }

        function onEmailBlurred(last, index, form) {
            var email = form["email" + index].$viewValue;
            if ((!email || email.length === 0) && !last) {
                vm.selectedItem.splice(index, 1);
                vm.userPermissions.splice(index, 1);
            }
        }

        function onGroupChanged(last) {
            if (last) {
                vm.groupPermissions.push({ access: 4, group: vm.userGroups[0] });
            }
        }

        function removeUser(index, form) {
	        vm.selectedItem.splice(index, 1);
            vm.userPermissions.splice(index, 1);
            form.$setDirty();
        }

        function removeGroup(index, form) {
            vm.groupPermissions.splice(index, 1);
            form.$setDirty();
        }

        function focusUserInput() {
            $timeout(function () {
                $("md-autocomplete input").last().trigger("focus");
            });
        }

        function save(event, form) {
            if (form.$invalid)
                return;

            // Clean everyone and admins
            if (!vm.folder.folderId) {
                vm.folder.folderType = vm.folder.type.value;
            }
            vm.folder.admin_access = 0;
            vm.folder.everyone_access = 0;
            vm.folder.users = [];
            vm.folder.usergroups = [];
            for (var i = 0; i < vm.groupPermissions.length; i++) {
                var g = vm.groupPermissions[i];
                if (g.group.userGroupId)
                    vm.folder.usergroups.push({ groupName: g.group.name, userGroupId: g.group.userGroupId, access: g.access });
            }
            for (var i = 0; i < vm.userPermissions.length; i++) {
                var g = vm.userPermissions[i];
                if (!g.username)
                    continue;
                vm.folder.users.push({ username: g.username, access: g.access });
            }

            var params = JSON.stringify({
                folderId: vm.folderId,
                accessUsers: vm.folder.users,
                accessGroups: vm.folder.usergroups,
                includeSubfolders: vm.folder.includeSubfolders
            });
            $http.post("~/api/v1/folders/set-owned-virtual-folder-properties", params)
                .then(function () {
                    $mdDialog.hide({ resource: vm.folder });
                });
        }

        function cancel() {
            $mdDialog.cancel();
        }

        function stopSharing(event) {
            $rootScope.spinner.show();
            $http.post("~/api/v1/folders/stop-sharing-folders", JSON.stringify({ folderIds: [vm.folderId] }))
                .then(
	                function (success) {
	                    $mdDialog.hide({ action: "refresh" });
	                }, function (failure) {
	                    errorMessageService.showErrorMessage(failure);
	                }
				)
	            .finally(function() { $rootScope.spinner.hide(); });
        }
    }
})();