(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("fsMoveFilesController", fsMoveFilesController);

	function fsMoveFilesController($rootScope, $scope, $filter, $mdDialog, $http, $timeout, coreDataFileStorage, selectedCards, folder, folders) {
		$scope.controller = this;
		$scope.selectedNode = "";
		$scope.title = "MOVE_FILES";
		$scope.folders = $.grep(folders, function(f) {
			return f.path !== folder.data.path &&
				f.path.indexOf("86bc2a5f-89d8-4e76-a3e8-c53cbebe565d") === -1 &&
				f.path.indexOf("PUBLIC_CHAT_FILES") === -1;
		});

		$scope.selectedFolder = $scope.folders[0];
		for (var i = 0; i < $scope.folders.length; ++i) {
			$scope.folders[i].displayPath = $filter("translate")("MY_FILES") + $scope.folders[i].path;
			$scope.folders[i].displayPath = $scope.folders[i].displayPath.replace("PUBLIC_CHAT_FILES", $filter("translate")("PUBLIC_CHAT_FILES"));
		}

		$scope.done = function () {
			$rootScope.spinner.show();
			coreDataFileStorage.moveFiles(selectedCards, $scope.selectedFolder)
				.then(
					function () { $rootScope.spinner.hide(); $mdDialog.hide(); },
					function(failure) { $rootScope.spinner.hide(); $mdDialog.hide({ failure: failure });
				});
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}
})();