(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("coreDataRss", coreDataRss);

    function coreDataRss($http, $q, $filter, $translate, $rootScope, $log) {

        //// Variable Declarations / Initializations
        var vm = this;

        var isInitialized = false;
        var initDefer = null;
        var areFeedsLoaded = false;
        var recalculateAllCountersSuspended = false;

        var _feedHierarchy = [];
        var _feedTree = {};
        var _shortTree = {};
        var getShortTree = getShortTree;

        vm.data = {
            get feeds() { return getFeeds(); },
            get feedTree() { return getFeedTree(); },
            get shortTree() { return getShortTree(); }
        };

        // Functions
        vm.init = init;
        vm.reset = reset;
        vm.recalculateAllCounters = recalculateAllCounters;
        vm.fireRssContentsUpdated = fireRssContentsUpdated;

        //// Get Feeds
        vm.getRssFeed = getRssFeed;

        //// Feed Actions
        vm.addFeed = addFeed;
        vm.editFeed = editFeed;
        vm.deleteFeed = deleteFeed;
        vm.markFeedRead = markFeedRead;
        vm.markReadUnread = markReadUnread;
        vm.refreshRssFeed = refreshRssFeed;
        vm.updateFeeds = updateFeeds;
        vm.reloadFeedTree = reloadFeedTree;

        //// Folder Actions
        vm.addFolder = addFolder;
        vm.editFolder = editFolder;
        vm.deleteFolder = deleteFolder;

        function init() {
            if (initDefer)
                return initDefer.promise;

            if (isInitialized)
                return $q.when();

            initDefer = $q.defer();
            var promise = initDefer.promise;

            if (isInitialized)
                defer.resolve();
            else {
                loadFeedHierarchy()
                    .then(function () {
                        isInitialized = true;
                        initDefer.resolve();
                        initDefer = null;
                    }, function (failure) {
                        isInitialized = false;
                        initDefer.reject(failure);
                        initDefer = null;
                    });
            }

            return promise;
        };

        function fireRssContentsUpdated(data) {
            if (isInitialized) {
                $rootScope.$broadcast('coreDataRss.rssContentsUpdated', data);
            }
        }

        function reset() {
            _feedHierarchy = [];
            // Trying not to reset the reference to the _feedTree
            _feedTree.data = [];
            _feedTree.map = {};
            _feedTree.mapById = {};
            _feedTree.selectedBranchData = {};
            _shortTree.data = [];
            _shortTree.map = {};
            _shortTree.selectedBranchData = {};
            isInitialized = false;
            areFeedsLoaded = false;
        };

        function loadFeedHierarchy() {
            return $http.get('~/api/v1/rss/feed-hierarchy')
                .then(function (success) {
                    _feedHierarchy = success.data.folders;
                    areFeedsLoaded = true;
                }, function (failure) {
                    areFeedsLoaded = false;
                    _feedHierarchy = [];
                });
        };

        function getFeeds() {
            var defer = $q.defer();

            if (areFeedsLoaded)
                return $q.when($.extend(true, [], _feedHierarchy));

            loadFeedHierarchy()
                .then(function (success) {
                    defer.resolve($.extend(true, [], _feedHierarchy));
                }, function (failure) {
                    defer.reject("RSS_FEEDS_ERRORS_FAILED_LOAD_FEEDS");
                });
            return defer.promise;
        };

        function updateFeeds() {
            _feedHierarchy = [];
            areFeedsLoaded = false;
            return loadFeedHierarchy();
        };

        function findFeedById(folders, id) {
            if (!folders)
                return null;
            for (var i = 0; i < folders.length; i++) {
                var folder = folders[i];
                if (folder && folder.feeds) {
                    for (var j = 0; j < folder.feeds.length; j++) {
                        var feed = folder.feeds[j];
                        if (feed && feed.id == id)
                            return feed;
                    }
                }
                if (folder && folder.children) {
                    var result = findFeedById(folder.children, id);
                    if (result)
                        return result;
                }
            }
            return null;
        }

        // Feed Tree
        function getShortTree() {
            return _shortTree;
        }

        function getFeedTree(rebuild) {
            if (_feedTree.data.length != 0 && !rebuild)
                return _feedTree;

            var expandMap = {};
            if (_feedTree.mapById) {
                for (var key in _feedTree.mapById) {
                    if (_feedTree.mapById.hasOwnProperty(key)) {
                        expandMap[key] = { expanded: _feedTree.mapById[key].expanded };
                    }
                }
            }

            // Trying not to reset the reference to the _feedTree
            _shortTree.data = [];
            _shortTree.map = {};
            _shortTree.selectedBranchData = {};

            _feedTree.data = [];
            _feedTree.map = {};
            _feedTree.mapById = {};
            _feedTree.selectedBranchData = {};

            // Create tree hierarchy
            recalculateAllCountersSuspended = true;
            for (var i = 0; i < _feedHierarchy.length; i++) {
                buildFolderTreeRecursive(_feedHierarchy[i], null);
            }
            recalculateAllCountersSuspended = false;
            recalculateAllCounters();

            // Re-expand tree
            for (var key in expandMap) {
                if (expandMap.hasOwnProperty(key)) {
                    if (_feedTree.mapById[key] && _feedTree.mapById[key].children && _feedTree.mapById[key].children.length)
                        _feedTree.mapById[key].expanded = expandMap[key].expanded;
                }
            }

            return _feedTree;
        };

		function buildFolderTreeRecursive(folder, parentFolder) {
            var isRootFolder = parentFolder == null;
            var isFirstLevelFolder = parentFolder != null && parentFolder.data.parentFolderId == null;

            var folderPath = isRootFolder
                ? "/" + folder.folderId
                : (parentFolder.data.path + '/' + folder.folderId);

            var displayPath = isRootFolder ? "ROOT_FOLDER"
                : isFirstLevelFolder
                ? ("/" + folder.displayName)
                : (parentFolder.data.displayPath + '/' + folder.displayName);

            var folderItem = {
                label: isRootFolder ? $translate.instant("RSS_FEEDS_ALL_FEEDS") : folder.displayName,
                data: {
                    id: folder.folderId,
                    key: "folder"+folder.folderId,
                    count: 0,
                    path: folderPath,
                    displayPath: displayPath,
                    parentFolderId: parentFolder ? parentFolder.data.id : null,
                    parentPath: parentFolder ? parentFolder.data.path : null,
                    parentDisplayPath: parentFolder ? parentFolder.data.displayPath : null,
                    isFolder: true,
                    allFeed : isRootFolder
                },
                expanded: parentFolder == null,
                children: [],
            };

            _feedTree.map[folderPath] = folderItem;
            _feedTree.mapById[folder.folderId] = folderItem;

            if (!isRootFolder) // Don't insert root folder
            {
                var parentContainer = isFirstLevelFolder ? _feedTree.data : parentFolder.children;
                parentContainer.push(folderItem);
            } else {
                _shortTree.data.push(folderItem);
                _shortTree.map[folderItem.data.id] = folderItem;
                _shortTree.map["All Feeds"] = folderItem;
            }

            // render children
            if (folder.children) {
                for (var i = 0; i < folder.children.length; i++)
                    buildFolderTreeRecursive(folder.children[i], folderItem);
            }

            // render feeds
            if (folder.feeds)
                for (var m = 0; m < folder.feeds.length; m++) {
                    var feed = folder.feeds[m];

                    var feedItem = {
                        label: feed.displayName,
                        data: {
                            id: feed.id,
                            key: "feed"+feed.id,
                            count: 0, // Set in markReadUnread()
                            path: feed.feedUrl,
                            folder: feed.folderId,
                            parentPath: folderItem && folderItem.data.path,
                            data: feed
                        }
                    };

                    if (isRootFolder)
                        _feedTree.data.push(feedItem);
                    else
                        folderItem.children.push(feedItem);

                    _feedTree.map[feed.id] = feedItem;
                    markReadUnread(feedItem, false, feed.unreadItems);
                }

            if (!folder.feeds.length && !folder.children.length && !isRootFolder) {
				folderItem.children.push({ data: { hide: true } });
            }
        }
         
        function markReadUnread(feedItem, markRead, count) {
            count = !count && count != 0 ? 1 : count;
            feedItem.data.count += markRead ? -count : count;
            if (feedItem.data.count < 0)
                feedItem.data.count = 0;
            recalculateAllCounters();
        }

		function recalculateAllCounters() {
            if (recalculateAllCountersSuspended)
				return 0;
            var allCounter = 0;
            for (var i = 0; i < _feedTree.data.length; i++) {
                var treeItem = _feedTree.data[i];
                allCounter += recalculateAllCountersRecurse(treeItem);
            }
            var allFeed = _shortTree.map["All Feeds"];
            if (allFeed)
                allFeed.data.count = allCounter;
            return allCounter;
        }

        function recalculateAllCountersRecurse(treeItem) {
            if (!treeItem.data.isFolder)
                return treeItem.data.count;

            var counter = 0;
            for (var i = 0; i < treeItem.children.length; i++) {
                var subItem = treeItem.children[i];
                if (subItem.data.isFolder) {
                    counter += recalculateAllCountersRecurse(subItem);
                } else {
                    counter += subItem.data.count || 0;
                }
            }
            treeItem.data.count = counter;
            return counter;
        }

        // Feed data
        function getRssFeed(feedIds) {
            var defer = $q.defer();
            if (feedIds.length <= 0) {
                defer.reject({ failure: { data: { message: "RSS_FEEDS_NO_FEEDS_SELECTED" } } });
                return defer.promise;
            }

            $http.post("~/api/v1/rss/get-rss-data", JSON.stringify({ ids: feedIds }))
                .then(function (success) {

                    var formattedList = [];
                    for (var i = 0; i < success.data.data.length; ++i) {
                        var article = success.data.data[i];
                        formattedList.push(article);
                    }
                    formattedList = $filter('orderBy')(formattedList, 'pubDate', true);
                    defer.resolve({ data: { items: formattedList, singleFeed: success.data.rssFeed } });
                }, function (failure) {
                    defer.reject(failure);
                });

            return defer.promise;
        };

        function markFeedRead(feeds, markRead) {
            var defer = $q.defer();
            $http.post("~/api/v1/rss/mark-feed/" + markRead, JSON.stringify({  feedIds:feeds }))
                .then(function (success) {
                    for (var i = 0; i < feeds.length; ++i) {
                        var feedItem = _feedTree.map[feeds[i]];
                        feedItem.data.count = success.data.counts[i];
                        recalculateAllCounters();
                    }
                    defer.resolve();
                }, function (failure) {
                    defer.reject(failure);
                });
            return defer.promise;
        }

        // Feed folders

        function addFolder(parentFolderId, name) {
            var defer = $q.defer();
            $http.post("~/api/v1/rss/folder-put", { parentFolderId: parentFolderId, name: name })
                .then(function (addSuccess) {
                    updateFeeds().then(function () { defer.resolve(addSuccess) }, defer.reject);
                }, defer.reject);
            return defer.promise;
        };

        function editFolder(folderId, parentFolderId, name) {
            var defer = $q.defer();
            $http.post("~/api/v1/rss/folder-patch", { folderId: folderId, parentFolderId: parentFolderId, name: name })
                .then(function (editSuccess) {
                    updateFeeds().then(function () { defer.resolve(editSuccess); }, defer.reject);
                }, defer.reject);
            return defer.promise;
        };

        function deleteFolder(folderId) {
            var defer = $q.defer();
            $http.post("~/api/v1/rss/delete-folder", { folderId: folderId })
                .then(function (success) {
                    updateFeeds().then(function () { defer.resolve(success); }, defer.reject);
                }, function (failure) {
                    defer.reject(failure);
                });
            return defer.promise;
        };









        //// Feed Actions
        function addFeed(feed) {
            var defer = $q.defer();
            var params = JSON.stringify(feed);
            $http.post("~/api/v1/rss/feed-put", params)
                .then(function (addSuccess) {
                    updateFeeds().then(function () { defer.resolve(addSuccess) }, defer.reject);
                }, defer.reject);
            return defer.promise;
        };

        function editFeed(feed) {
            var defer = $q.defer();
            var params = JSON.stringify(feed);
            $http.post("~/api/v1/rss/feed-patch/" + feed.feedId, params)
                .then(function (editSuccess) {
                    updateFeeds().then(function () { defer.resolve(editSuccess); }, defer.reject);
                }, defer.reject);
            return defer.promise;
        };

        function deleteFeed(feed) {
            var defer = $q.defer();
            $http.post("~/api/v1/rss/feed-delete/" + feed.uid)
				.then(function (success) {
					updateFeeds().then(function () { defer.resolve(); }, defer.reject);
                }, function (failure) {
                    defer.reject(failure);
                });
            return defer.promise;
        };

        function refreshRssFeed(feeds) {
            var defer = $q.defer();
            $http.post("~/api/v1/rss/refresh-feed", JSON.stringify({ ids: feeds }))
                .then(function (success) {
					updateFeeds().then(function () { defer.resolve(); }, defer.reject);
                }, function (failure) {
                    defer.reject(failure);
                });
            return defer.promise;
        };

        function reloadFeedTree() {
            getFeedTree(true);
        };


    }
})();