(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("messageSnifferSpamCtrl", messageSnifferSpamCtrl);

	function messageSnifferSpamCtrl($rootScope, $scope, $mdDialog, coreDataLicensing, $http, errorHandling) {

		activate();

		/////////////////

		function activate() {
			$rootScope.spinner.show();
			$http
				.get("~/api/v1/settings/sysadmin/antispam/settings")
				.then(onLoad, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onLoad(result) {
				$scope.licensed = coreDataLicensing.Data.Licensing.services.messagesniffer.enabled;
				$scope.item = angular.copy(result.data.settings.messageSniffer);
			}
		}

		$scope.cancel = function () {
			$mdDialog.cancel();
		};

		$scope.save = function () {
			if (!$scope.spamForm.$valid)
				return;

			$rootScope.spinner.show();
			$http
				.post("~/api/v1/settings/sysadmin/antispam/settings", JSON.stringify({ messageSniffer: $scope.item }))
				.then($mdDialog.hide, errorHandling.report)
				.finally($rootScope.spinner.hide);
		};
	}
})();