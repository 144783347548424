(function () {
	"use strict";

	angular
		.module("smartertools")
		.directive("validateEmail", validateEmail);

	function validateEmail($q, emailValidationService) {
		return {
			require: "ngModel",
			link: function (scope, element, attrs, ctrl) {
				ctrl.$asyncValidators.validateEmail = async function (modelValue, viewValue) {
					if (scope.$eval(attrs.validateEmail) === false) return $q.resolve(true);
					if (!viewValue) return $q.resolve({ succss: true });

					var isValid = await emailValidationService.isValidEmail(viewValue);
					return isValid
						? $q.resolve({ success: true })
						: $q.reject({ success: false });
				};
			}
		};
	}
})();