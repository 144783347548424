(function () {
	"use strict";

	angular
		.module("smartermail")
		.directive("rootManageCard", rootManageCardDirective);

	function rootManageCardDirective() {
		return {
			restrict: "EA",
			templateUrl: "app/sysadmin/manage/cards/root-manage-card.html",
			controller: function () { }
		};
	}

})();
