(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("emailTracker", emailTracker);

    function emailTracker() {
        var vm = this;

        // Functions
        vm.replaceTracker = replaceTracker;

        ////////////////////////////////////////

        //DO NO MODIFY THIS CODE, IT'S AUTOGENERATED, look for 'TrackingPixelJsBuilder.GenerateTrackingPixelDetectionCode'
        function replaceTracker(html, trackerId) {
            function replaceTrackerSlim(html, rgx, id) {
                html = html.replaceAll(rgx, '$1 src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-trackerid="' + id + '" data-trackerurl=$2 $3');
                return html;
            }

            try {
                trackerId = trackerId.toLowerCase();
                if (trackerId == "") { }
                else if (trackerId == "mailchimp")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:\/track\/open\.php\?u=|list-manage\.com)[^"'\s]*?['"])([^>]*>)/gi, "mailchimp");
                else if (trackerId == "sendgrid")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:\/wf\/open\?upn=)[^"'\s]*?['"])([^>]*>)/gi, "sendgrid");
                else if (trackerId == "drip")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:dripemail2)[^"'\s]*?['"])([^>]*>)/gi, "drip");
                else if (trackerId == "mailgun")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:\/o\/)[^"'\s]*?['"])([^>]*>)/gi, "mailgun");
                else if (trackerId == "streak")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:mailfoogae\.appspot\.com)[^"'\s]*?['"])([^>]*>)/gi, "streak");
                else if (trackerId == "bananatag")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:bl-1\.com)[^"'\s]*?['"])([^>]*>)/gi, "bananatag");
                else if (trackerId == "yesware")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:t\.yesware\.com)[^"'\s]*?['"])([^>]*>)/gi, "yesware");
                else if (trackerId == "postmark")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:pstmrk\.it\/open)[^"'\s]*?['"])([^>]*>)/gi, "postmark");
                else if (trackerId == "sidekick")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:t\.signauxtrois\.com|sidekickopen)[^"'\s]*?['"])([^>]*>)/gi, "sidekick");
                else if (trackerId == "tinyletter")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:tinyletterapp\.com)[^"'\s]*?['"])([^>]*>)/gi, "tinyletter");
                else if (trackerId == "mixmax")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:(?:email|track)\.mixmax\.com|mixmax\.com\/api\/track\/|mixmax\.com\/e\/o)[^"'\s]*?['"])([^>]*>)/gi, "mixmax");
                else if (trackerId == "mailtrack")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:mltrk\.io\/pixel\/|mailtrack\.io\/trace\/mail\/)[^"'\s]*?['"])([^>]*>)/gi, "mailtrack");
                else if (trackerId == "toutapp")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:toutapp\.com)[^"'\s]*?['"])([^>]*>)/gi, "toutapp");
                else if (trackerId == "litmus")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:emltrk\.com)[^"'\s]*?['"])([^>]*>)/gi, "litmus");
                else if (trackerId == "boomerang")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:mailstat\.us\/tr)[^"'\s]*?['"])([^>]*>)/gi, "boomerang");
                else if (trackerId == "contactmonkey")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:contactmonkey\.com\/api\/v1\/tracker)[^"'\s]*?['"])([^>]*>)/gi, "contactmonkey");
                else if (trackerId == "cirrusinsight")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:tracking\.cirrusinsight\.com)[^"'\s]*?['"])([^>]*>)/gi, "cirrusinsight");
                else if (trackerId == "convertkit")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:convertkit-mail\.com\/o\/)[^"'\s]*?['"])([^>]*>)/gi, "convertkit");
                else if (trackerId == "polymail")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:polymail\.io(?:\/v2\/z\/)?|share\.polymail\.io)[^"'\s]*?['"])([^>]*>)/gi, "polymail");
                else if (trackerId == "yamm")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:yamm-track\.appspot)[^"'\s]*?['"])([^>]*>)/gi, "yamm");
                else if (trackerId == "getresponse")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:\/open\.html\?x=)[^"'\s]*?['"])([^>]*>)/gi, "getresponse");
                else if (trackerId == "phplist")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:\/ut\.php\?u=)[^"'\s]*?['"])([^>]*>)/gi, "phplist");
                else if (trackerId == "close.io")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:close\.io\/email_opened)[^"'\s]*?['"])([^>]*>)/gi, "close.io");
                else if (trackerId == "constantcontact")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:\.net\/on\.jsp\?)[^"'\s]*?['"])([^>]*>)/gi, "constantcontact");
                else if (trackerId == "marketo")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:\/trk\?t=)[^"'\s]*?['"])([^>]*>)/gi, "marketo");
                else if (trackerId == "returnpath")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:returnpath\.net\/pixel\.gif)[^"'\s]*?['"])([^>]*>)/gi, "returnpath");
                else if (trackerId == "outreach")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:(?:outrch|whosen|getoutreach)\.com\/api\/mailings\/opened)[^"'\s]*?['"])([^>]*>)/gi, "outreach");
                else if (trackerId == "intercom")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:\/\/(?:.*).intercom-\w+.com(?:\/via)?\/[a-z]\/)[^"'\s]*?['"])([^>]*>)/gi, "intercom");
                else if (trackerId == "mailjet")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:\/\/links\..*\/oo\/|\.mjt\.lu\/|\/oo\/.*\.gif)[^"'\s]*?['"])([^>]*>)/gi, "mailjet");
                else if (trackerId == "nethunt")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:nethunt\.com\/api\/v1\/track\/email\/)[^"'\s]*?['"])([^>]*>)/gi, "nethunt");
                else if (trackerId == "superhuman")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:r\.superhuman\.com)[^"'\s]*?['"])([^>]*>)/gi, "superhuman");
                else if (trackerId == "apple")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:apple\.com\/report\/2\/its_mail_sf)[^"'\s]*?['"])([^>]*>)/gi, "apple");
                else if (trackerId == "fastmail")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:list-manage.com\/track\/open\.php)[^"'\s]*?['"])([^>]*>)/gi, "fastmail");
                else if (trackerId == "freshmail")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:mail\.(?:great-news|mailnews)\.pl\/o\/.*)[^"'\s]*?['"])([^>]*>)/gi, "freshmail");
                else if (trackerId == "activecampaign")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:\/lt\.php(?:.*)\?l=open)[^"'\s]*?['"])([^>]*>)/gi, "activecampaign");
                else if (trackerId == "aweber")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:openrate\.aweber.com)[^"'\s]*?['"])([^>]*>)/gi, "aweber");
                else if (trackerId == "getnotify")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:email81\.com\/case)[^"'\s]*?['"])([^>]*>)/gi, "getnotify");
                else if (trackerId == "growthdot")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:growthdot\.com\/api\/mail-tracking)[^"'\s]*?['"])([^>]*>)/gi, "growthdot");
                else if (trackerId == "hubspot")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:t\.hubspotemail\.net|hubspotstarter\.net|t\.(?:hubspotemail|hubspotfree|signaux|senal|signale|sidekickopen|sigopn|hsmsdd)|t.strk\d{2}\.email|track\.getsidekick\.com|\/e2t\/(?:o|c|to)\/)[^"'\s]*?['"])([^>]*>)/gi, "hubspot");
                else if (trackerId == "mailspring")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:getmailspring\.com\/open)[^"'\s]*?['"])([^>]*>)/gi, "mailspring");
                else if (trackerId == "mandrill")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:mandrillapp\.com\/track)[^"'\s]*?['"])([^>]*>)/gi, "mandrill");
                else if (trackerId == "mixpanel")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:mixpanel\.com\/(?:trk|track))[^"'\s]*?['"])([^>]*>)/gi, "mixpanel");
                else if (trackerId == "sparkpost")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:go\.sparkpostmail(?:.{0}|2)\.com\/q\/)[^"'\s]*?['"])([^>]*>)/gi, "sparkpost");
                else if (trackerId == "salesforce")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:\/\/click\..*\/open\.aspx|salesforceiq\.com\/t.png|beacon\.krxd\.net)[^"'\s]*?['"])([^>]*>)/gi, "salesforce");
                else if (trackerId == "persistiq")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:infinite-stream-5194\.herokuapp\.com\/pixel\/)[^"'\s]*?['"])([^>]*>)/gi, "persistiq");
                else if (trackerId == "sailthru")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:sailthru\.com\/trk)[^"'\s]*?['"])([^>]*>)/gi, "sailthru");
                else if (trackerId == "trackapp")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:trackapp\.io\/static\/img\/track\.gif|trackapp\.io\/[a-z]\/)[^"'\s]*?['"])([^>]*>)/gi, "trackapp");
                else if (trackerId == "sendinblue")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:sendibw{2}\.com\/track\/)[^"'\s]*?['"])([^>]*>)/gi, "sendinblue");
                else if (trackerId == "segment")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:email\.segment\.com\/e\/o\/)[^"'\s]*?['"])([^>]*>)/gi, "segment");
                else if (trackerId == "salesloft")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:salesloft\.com\/email_trackers|salesloftlinks\.com\/t\/)[^"'\s]*?['"])([^>]*>)/gi, "salesloft");
                else if (trackerId == "saleshandy")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:saleshandy\.com\/web\/email\/countopened)[^"'\s]*?['"])([^>]*>)/gi, "saleshandy");
                else if (trackerId == "shopify")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:\/tools\/emails\/open\/)[^"'\s]*?['"])([^>]*>)/gi, "shopify");
                else if (trackerId == "amazonses")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:\/\w*I0\/\w{16}-\w{8}-\w{4})[^"'\s]*?['"])([^>]*>)/gi, "amazonses");
                else if (trackerId == "humblebundle")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:sptrack.mailer.humblebundle.com\/q\/)[^"'\s]*?['"])([^>]*>)/gi, "humblebundle");
                else if (trackerId == "milesmore")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:t.mailing.milesandmore.com\/r\/)[^"'\s]*?['"])([^>]*>)/gi, "milesmore");
                else if (trackerId == "targito")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:e.targito.com\/oe)[^"'\s]*?['"])([^>]*>)/gi, "targito");
                else if (trackerId == "mailbird")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:tracking.getmailbird.com\/OpenTrackingPixel\/)[^"'\s]*?['"])([^>]*>)/gi, "mailbird");
                else if (trackerId == "mailforce")
                    return replaceTrackerSlim(html, /(<img[^>]+)src\s*=\s*(['"][^"'\s]*?(?:link.mailforce.cz\/Open.ashx)[^"'\s]*?['"])([^>]*>)/gi, "mailforce");
            } catch (e) { console.error("Failed to remove tracker: ", trackerId, e); }
            return html;
        }
        // DO NO MODIFY THIS CODE, IT'S AUTOGENERATED

    }
})();
