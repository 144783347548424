(function () {
	"use strict";

	angular
		.module("smartertools")
		.directive("categoryColorPreview", ["coreDataCategories",categoryColorPreview]);
	// Example Usage: <div category-color-preview="{{category.colorIndex}}"></div>
	function categoryColorPreview(coreDataCategories, $mdPanel) {
		return {
			scope: {
				"categoryColorPreview": "@"
			},
			replace: true,
			restrict: "A",
			link: function (scope, element, attrs, ngModel) {
				scope.$watch("categoryColorPreview",
					function(val) {
						var colorIndex = parseInt(val);
						scope.color = coreDataCategories.getCategoryColor(colorIndex).rgb;

					});
			},
			template: `
				<div style="display: contents">
					<div class='category-tag'><st-category-flag flag-color="color"></st-category-flag></div>
				</div>
			`
		};
	}
})(); 