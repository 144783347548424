(function () {
	"use strict";

	/**
	 * Example(s):
	 * <st-color-picker st-model="exampleModel"></st-color-picker>
	 */

	// ReSharper disable once UndeclaredGlobalVariableUsing
	angular
		.module("smartertools")
		.directive("stColorPicker", stColorPicker);

	function stColorPicker($compile, $interval) {
		return {
			restrict: 'E',
			scope: {
				stModel: "=",
				onChange: "&",
				onBlur: "&",
				onOpen: "&",
				onClose: "&",
				onDestroy: "&"
			},
			link: function (scope, element, attr) {
				//$(element).uniqueId();

				////var firstRun = true;
				//var timer = null;
				//scope.onValueChanged = function () {
				//	if (angular.isDefined(timer))
				//		$interval.cancel(timer);

				//	//if (!firstRun) {
				//	timer = $interval(function () {
				//		if (angular.isDefined(scope.onChange))
				//			return scope.onChange();
				//	}, 2000, 1);
				//	//} else {
				//	//	firstRun = false;
				//	//}
				//}

				var updateOn = attr.updateOn ? attr.updateOn : "'default'";
				var swatchPos = attr.swatchPos ? attr.swatchPos : "right";
				var pickerPos = attr.pickerPos ? attr.pickerPos : "bottom left";
				var swatchOnly = attr.swatchOnly ? attr.swatchOnly : "false";
				var debounceString = '';
				if (attr.defaultTime && attr.blurTime) {
					debounceString = "'default': " + attr.defaultTime + ",'blur': " + attr.blurTime;
				} else if (attr.defaultTime) {
					debounceString = "'default': " + attr.defaultTime;
				} else if (attr.blurTime) {
					debounceString = "'blur': " + attr.blurTime;
				} else {
					debounceString = "'default': 0";
				}
				scope.options = {
					format: 'hexString',
					alpha: false,
					swatch: true,
					swatchPos: swatchPos,
					swatchOnly: swatchOnly,
					swatchBootstrap: true,
					pos: pickerPos,
					case: 'upper'
				};

				scope.eventApi = {
					onClose: scope.onClose,
					onChange: scope.onChange,
					onBlur: scope.onBlur,
					onOpen: scope.onOpen,
					onDestroy: scope.onDestroy
				}

				var elementToBeAdded = angular.element(
					'<color-picker ng-model="stModel" ng-model-options="{ updateOn: ' + updateOn + ', debounce:{' + debounceString + '} }" options="options" event-api="eventApi"></color-picker>'
				);

				var elementCompiled = $compile(elementToBeAdded)(scope);
				element.append(elementCompiled);
			}
		}
	}

})();