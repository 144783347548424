(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("domainUserPropagationController", domainUserPropagationController);

	function domainUserPropagationController($rootScope, $scope, $mdDialog, $filter, $q, $http, $translate,
		modUser, domain, coreDataSettings, coreDataDomainSettings, userSettingEnums, errorHandling, successHandling, claimsService) {
		var vm = this;

		$scope.modUser = modUser;
		$scope.users = [];
		$scope.updateSettings = true;
		$scope.updateDefaults = false;
		$scope.domain = domain;
		$scope.selectedOptions = {};
		$scope.allUsers = false;
		var canChangeGreylist;

		// Functions
		$scope.save = save;
		$scope.cancel = cancel;
		$scope.YesNo = YesNo;
		$scope.checked = checked;
		$scope.queryUsers = queryUsers;
		vm.updateUsersList = updateUsersList;
		activate();

		///////////////////////

		function activate() {

			$rootScope.$on('$translateChangeSuccess', retranslate);
            vm.isImpersonating = claimsService.impersonating() || claimsService.isSysAdmin();
            var promises = [
                coreDataSettings.init(),
				$http.get("~/api/v1/settings/domain/domain"),
                $http.get('~/api/v1/settings/domain/user-defaults')
            ];
			$q.all(promises).then(function (success) {
                canChangeGreylist = coreDataSettings.userPermissions.greyListEnabled;
				vm.domainSettings = success[1].data.domainSettings;
                
				$scope.allowedSizeChanges = vm.isImpersonating || (vm.domainSettings && vm.domainSettings.allowUserSizeChanging);

                var userInfo = {
                    isTemplate: true,
                    userData: success[2].data.userData,
					userMailSettings: success[2].data.userMailSettings,
					mailboxForwardList: success[2].data.mailboxForwardList,
                    userGroups: {}
                }
                $scope.defaults = angular.copy(userInfo);
                generateSettingOptions();
            }, function (failure) { });

			$scope.$watchCollection('users', function () {
				if ($scope.users !== undefined)
					vm.updateUsersList();
			});
		}

		function retranslate() {
			if ($scope.settingOptions)
				generateSettingOptions();
		}

		function generateSettingOptions() {
			var defaults = $scope.modUser;

			var timeZoneOptions = userSettingEnums.timeZoneOptions.slice();
			timeZoneOptions.push({
				translated: $translate.instant("USE_SERVER_TIME_ZONE"),
				index: -1
			});
			
			var localeText;
			for (var i = 0; i < angularLangNames.length; i++) {
				if (angularLangNames[i].v === defaults.localeId) {
					localeText = angularLangNames[i].n;
					break;
				}
			}

			$scope.settingOptions = [];

			if ($scope.allowedSizeChanges) {
				$scope.settingOptions.push({ cat: 'ACCOUNT', id: 'MailboxSize', translation: 'DOMAIN_USERS_MAILBOX_SIZE_LIMIT', value: NumberOrUnlimited(defaults.maxMailboxSize / (1024 * 1024)) });
            }

            $scope.settingOptions = $scope.settingOptions.concat([
                { cat: 'SERVICE_ACCESS', id: 'EnableWebmailAccess', translation: 'WEBMAIL', value: EnabledDisabled(defaults.services.webmail) },
				{ cat: 'SERVICE_ACCESS', id: 'EnablePopAccess', translation: 'POP', value: EnabledDisabled(defaults.services.pop) },
				{ cat: 'SERVICE_ACCESS', id: 'EnableImapAccess', translation: 'IMAP', value: EnabledDisabled(defaults.services.imap) },
				{ cat: 'SERVICE_ACCESS', id: 'EnableIncomingSmtpAccess', translation: 'INCOMING_SMTP', value: EnabledDisabled(defaults.services.incomingSmtp) },
				{ cat: 'SERVICE_ACCESS', id: 'EnableOutgoingSmtpAccess', translation: 'OUTGOING_SMTP', value: EnabledDisabled(defaults.services.outgoingSmtp) },


				{ cat: 'USER', id: 'LocaleID', translation: 'LANGUAGE', value: localeText },
				{ cat: 'USER', id: 'TimeZoneIndex', translation: 'TIME_ZONE', value: userSettingEnums.findOptionTextByIndexValue(timeZoneOptions, defaults.timeZoneIndex) },
				{ cat: 'USER', id: 'PlusAddressing', translation: 'PLUS_ADDRESS', value: userSettingEnums.findOptionTextByValue(userSettingEnums.plusAddressOptions, defaults.plusAddressingOption) },
				{ cat: 'USER', id: 'LockPassword', translation: 'DISABLE_PASSWORD_CHANGES', value: YesNo(defaults.lockPassword) },

				{ cat: 'WEBMAIL', id: 'DeleteOption', translation: 'DELETE_EMAIL_ACTION', value: userSettingEnums.findOptionTextByValue(userSettingEnums.deleteOptions, defaults.deleteMailAction) },
				{ cat: 'WEBMAIL', id: 'ComposeFont', translation: 'COMPOSE_FONT', value: userSettingEnums.findOptionTextByValue(userSettingEnums.fontOptions, defaults.composeFont) },
				{ cat: 'WEBMAIL', id: 'ComposeFontSize', translation: 'FONT_SIZE', value: userSettingEnums.findOptionTextByValue(userSettingEnums.fontSizeOptions, defaults.composeFontSize) },
				{ cat: 'WEBMAIL', id: 'PreviewPaneOption', translation: 'PREVIEW_PANE', value: userSettingEnums.findOptionTextByValue(userSettingEnums.previewPaneOptions, defaults.previewPaneOption) },
				{ cat: 'WEBMAIL', id: 'DefaultComposeDomain', translation: 'DEFAULT_FROM_ADDRESS', value: (defaults.defaultComposeDomain && defaults.defaultComposeDomain.length > 0) ? defaults.defaultComposeDomain : domain },
				{ cat: 'WEBMAIL', id: 'PreferredIndexer', translation: 'SEARCH_LANGUAGE_INDEXER', value: userSettingEnums.findOptionTextByValue(userSettingEnums.availableIndexers, defaults.preferredIndexer) },
				{ cat: 'WEBMAIL', id: 'ReplyFromToField', translation: 'REPLY_FROM_TO_FIELD', value: YesNo(defaults.replyFromToField) },
				{ cat: 'WEBMAIL', id: 'RequestReadReceipts', translation: 'ENABLE_READ_RECEIPTS', value: YesNo(defaults.requestReadReceipts) },
				{ cat: 'WEBMAIL', id: 'RequestDeliveryReceipts', translation: 'ENABLE_DELIVERY_READ_RECEIPTS', value: YesNo(defaults.requestDeliveryReceipts) },
				{ cat: 'WEBMAIL', id: 'MarkPOP3DownloadsAsRead', translation: 'MARK_MESSAGES_AS_DOWNLOADED_POP3', value: YesNo(defaults.markPopDownloadsAsRead) },
				{ cat: 'WEBMAIL', id: 'DraftAutoSave', translation: 'DRAFT_AUTO_SAVE', value: YesNo(defaults.draftAutoSave) },
				{ cat: 'WEBMAIL', id: 'HideMailAvatars', translation: 'HIDE_MAIL_AVATARS', value: YesNo(defaults.hideMailAvatars) },
				{ cat: 'WEBMAIL', id: 'AllowRemoteContent', translation: 'ALLOW_REMOTE_CONTENT', value: YesNo(defaults.allowRemoteContent) },

				{ cat: 'THROTTLING', id: 'ThrottleMessages', translation: 'DOMAIN_MANAGEMENT_OUTGOING_PER_HOUR', value: NumberOrUnlimited(defaults.throttling.messagesPerHour) },
				{ cat: 'THROTTLING', id: 'ThrottleMessagesAction', translation: 'DOMAIN_MANAGEMENT_MESSAGE_THROTTLING', value: userSettingEnums.findOptionTextByValue(userSettingEnums.throttleOptions, defaults.throttling.messagesAction) },
				{ cat: 'THROTTLING', id: 'ThrottleBandwidth', translation: 'DOMAIN_MANAGEMENT_OUTGOING_BANDWIDTH', value: NumberOrUnlimited(defaults.throttling.bandwidthPerHour) },
				{ cat: 'THROTTLING', id: 'ThrottleBandwidthAction', translation: 'DOMAIN_MANAGEMENT_BANDWIDTH_THROTTLING', value: userSettingEnums.findOptionTextByValue(userSettingEnums.throttleOptions, defaults.throttling.bandwidthAction) },
			]);

            if (vm.domainSettings.sharedGlobalAddressList) {
				$scope.settingOptions.push({ cat: 'USER', id: 'HideFromLdap', translation: 'SHOW_IN_GAL_FULL_NAME', value: YesNo(!defaults.hideFromGal) });
			}
			if (canChangeGreylist) {
				$scope.settingOptions.push({ cat: 'USER', id: 'DisableGreylisting', translation: 'ENABLE_GREYLISTING', value: YesNo(!defaults.disableGreylisting) });
			}
			if (vm.domainSettings.enableMailForwarding)
				$scope.settingOptions.push({ cat: 'USER', id: 'MailForwarding', translation: 'MAIL_FORWARDING_ENABLE', value: YesNo(defaults.mailForwarding) });

			if (vm.domainSettings.enableXmpp || vm.isImpersonating) {
				$scope.settingOptions.push({
					cat: 'SERVICE_ACCESS',
					id: 'EnableXmppAccess',
					translation: 'DOMAIN_MANAGEMENT_FEATURES_LIVE_CHAT',
					value: EnabledDisabled(defaults.services.xmpp)
				});
			}

			$scope.settingOptions.push({
				cat: 'SERVICE_ACCESS',
				id: 'EnableWebdavAccess',
				translation: 'WEBDAV',
				value: EnabledDisabled(defaults.services.webdav)
			});

			if (vm.domainSettings.enableActiveSyncAccountManagement || vm.isImpersonating) {
                $scope.settingOptions.push({
                    cat: 'SERVICE_ACCESS',
                    id: 'EnableEas',
                    translation: 'EAS_ENTERPRISE_ONLY',
                    value: EnabledDisabled(defaults.enableEas)
                });
			}
            if (vm.domainSettings.enableMapiEwsAccountManagement || vm.isImpersonating) {
                $scope.settingOptions.push({
                    cat: 'SERVICE_ACCESS',
                    id: 'EnableMapiEws',
                    translation: 'MAPI_EWS_ENTERPRISE',
                    value: EnabledDisabled(defaults.enableMapiEws)
                });
            }

            angular.forEach($scope.settingOptions, function(value) {
				switch(value.cat) {
					case "ACCOUNT": value.cat_order = 1; break;
					case "SERVICE_ACCESS": value.cat_order = 3; break;
					case "USER": value.cat_order = 2; break;
					case "WEBMAIL": value.cat_order = 4; break;
					case "THROTTLING": value.cat_order = 5; break;
				}

				 value.label = $translate.instant(value.translation);
			});
		}

		function applyPropagationItem(arr, item) {
			var defaults = $scope.modUser;
			switch (item) {
				case "LocaleID": arr.push({ userField: item, strValue: defaults.localeId }); break;
				case 'TimeZoneIndex': arr.push({ userField: item, intValue: defaults.timeZoneIndex }); break;
				case 'MailboxSize': arr.push({ userField: item, longValue: defaults.maxMailboxSize }); break;
				case 'LockPassword': arr.push({ userField: item, boolValue: defaults.lockPassword }); break;
				case 'HideFromLdap': arr.push({ userField: item, boolValue: defaults.hideFromGal }); break;
				case 'DisableGreylisting': arr.push({ userField: item, boolValue: defaults.disableGreylisting }); break;
				case 'DeleteOption': arr.push({ userField: item, intValue: defaults.deleteMailAction }); break;
				case 'PlusAddressing':
					arr.push({ userField: 'PlusAddressingEnable', boolValue: (defaults.plusAddressingOption === 'disabled' ? false : true) });
					arr.push({ userField: 'PlusAddressingFolder', strValue: (defaults.plusAddressingOption === 'disabled' ? '' : defaults.plusAddressingOption) });
					break;
				case 'ComposeFont': arr.push({ userField: item, strValue: defaults.composeFont }); break;
				case 'ComposeFontSize': arr.push({ userField: item, strValue: defaults.composeFontSize }); break;
				case 'ReplyFromToField': arr.push({ userField: item, boolValue: defaults.replyFromToField }); break;
				case 'DefaultComposeDomain': arr.push({ userField: item, strValue: defaults.defaultComposeDomain }); break;
				case 'RequestReadReceipts': arr.push({ userField: item, boolValue: defaults.requestReadReceipts }); break;
				case 'RequestDeliveryReceipts': arr.push({ userField: item, boolValue: defaults.requestDeliveryReceipts }); break;
				case 'MarkPOP3DownloadsAsRead': arr.push({ userField: item, boolValue: defaults.markPopDownloadsAsRead }); break;
				case 'AllowRemoteContent': arr.push({ userField: item, boolValue: defaults.allowRemoteContent }); break;
				case 'ThrottleMessages': arr.push({ userField: item, intValue: defaults.throttling.messagesPerHour }); break;
				case 'ThrottleMessagesAction': arr.push({ userField: item, intValue: defaults.throttling.messagesAction }); break;
				case 'ThrottleBandwidth': arr.push({ userField: item, intValue: defaults.throttling.bandwidthPerHour }); break;
				case 'ThrottleBandwidthAction': arr.push({ userField: item, intValue: defaults.throttling.bandwidthAction }); break;
				case 'EnableWebmailAccess': arr.push({ userField: item, boolValue: defaults.services.webmail }); break;
				case 'EnableWebdavAccess': arr.push({ userField: item, boolValue: defaults.services.webdav }); break;
				case 'EnablePopAccess': arr.push({ userField: item, boolValue: defaults.services.pop }); break;
				case 'EnableImapAccess': arr.push({ userField: item, boolValue: defaults.services.imap }); break;
				case 'EnableIncomingSmtpAccess': arr.push({ userField: item, boolValue: defaults.services.incomingSmtp }); break;
				case 'EnableOutgoingSmtpAccess': arr.push({ userField: item, boolValue: defaults.services.outgoingSmtp }); break;
				case 'EnableXmppAccess': arr.push({ userField: item, boolValue: defaults.services.xmpp }); break;
				case 'PreferredIndexer': arr.push({ userField: item, strValue: defaults.preferredIndexer }); break;
				case 'PreviewPaneOption': arr.push({ userField: "PreviewUsePreview", boolValue: defaults.previewPaneOption !== "none" }); break;
				case 'MailForwarding': arr.push({ userField: item, boolValue: defaults.mailForwarding }); break;
                case 'EnableEas': arr.push({ userField: item, boolValue: defaults.enableEas }); break;
				case 'EnableMapiEws': arr.push({ userField: item, boolValue: defaults.enableMapiEws }); break;
				case 'DraftAutoSave': arr.push({ userField: item, boolValue: defaults.draftAutoSave }); break;
				case 'HideMailAvatars': arr.push({ userField: item, boolValue: defaults.hideMailAvatars }); break;
			}
		}

		function save(ev, scope) {
			var globalUpdate = [];
			
			angular.forEach($scope.selectedOptions, function (value, key) {
				applyPropagationItem(globalUpdate, key);
			});
			
			if (globalUpdate.length === 0) {
				errorHandling.report("PROPAGATION_NOTHING_TO_PROPAGATE");
				return;
			}

			$http.post('~/api/v1/settings/domain/propagate-settings', JSON.stringify({ globalUpdate: globalUpdate, emails: $scope.allUsers ? ["*@" + $scope.domain] : $scope.users }))
				.then(function () {
					successHandling.report("PROPAGATION_PROPAGATION_INPROGRESS");
				}, errorHandling.report);

			$mdDialog.hide({});
		};

		function cancel() {
			$mdDialog.cancel();
		};

		function NumberOrUnlimited(num) {
			return !num ? $filter('translate')('UNLIMITED') : $filter('number')(num);
		}

		function YesNo(value) {
			return value ? $filter("translate")("YES") : $filter("translate")("NO");
		};

		function EnabledDisabled(value) {
			return value ? $filter("translate")("ENABLED") : $filter("translate")("DISABLED");
		};

		function updateUsersList() {
			if (!$scope.users)
				$scope.users = [];

			if ($scope.users.length === 0) {
				$scope.users.push('');
			} else {
				//remove empty address fields from the list.
				if ($scope.users.length > 0) {
					var emptyAdds = $.grep($scope.users, function (add) { return add === ''; });
					if (emptyAdds.length > 1) {
						$scope.users = $.grep($scope.users, function (add) { return add !== ''; });
						$scope.users.push('');
					} else if (emptyAdds.length === 0) {
						$scope.users.push('');
					}
				}
			}
		};

		var lastChecked;
		function checked(ev, item) {
			document.getSelection().removeAllRanges();

			// If a shift select is occurring
			if (lastChecked && item.id != lastChecked && event.shiftKey) {
				var setChecked = $scope.selectedOptions[item.id];
				var checking = false;
				for (var i = 0; i < $scope.settingOptions.length; i++) {
					var itemToCheck = $scope.settingOptions[i];
					if (checking) {
						if (itemToCheck.id !== item.id) $scope.selectedOptions[itemToCheck.id] = setChecked;
						// If we reached the end of the shift selection
						if (itemToCheck.id == lastChecked || itemToCheck.id == item.id) break;
					} else if (itemToCheck.id == lastChecked || itemToCheck.id == item.id) { // We reached the beginning of the shift selection
						if (itemToCheck.id !== item.id) $scope.selectedOptions[itemToCheck.id] = setChecked;

						checking = true;
					}
				}
			}

			lastChecked = item.id;
		}

		function queryUsers(query) {
			return coreDataSettings.queryUsers(query, false);
		}
	}

})();
