(function () {
	"use strict";

	angular
		.module("smartermail")
        .controller("sysAdminsEditPasswordDialogController", sysAdminsEditPasswordDialogController);

	function sysAdminsEditPasswordDialogController($scope, $mdDialog, $http, $filter, errorHandling, isMe, username) {
		const vm = this;
		vm.username = username;
		vm.isMe = isMe;
		vm.oldPassword = "";
		vm.newPassword = "";
		vm.confirmPassword = "";
        vm.save = function () {
			if (!vm.newPassword || !vm.confirmPassword || vm.newPassword !== vm.confirmPassword) {
                return;
            }
            $http
	            .post("~/api/v1/settings/sysadmin/sysadmins/change-password", 
		            JSON.stringify({ username: vm.username, oldPassword: vm.oldPassword, password: vm.newPassword }))
	            .then(onSuccess, onSaveFailure);
			function onSuccess(success) {
				$mdDialog.hide();
			}
			function onSaveFailure(result) {
				if (result.data.message.indexOf("PASSWORD_RESET_REQUIRE") > -1) {
					vm.fullChecks = true;
					$scope.$broadcast("passwordCheckCallback", result);
				} else {
					errorHandling.report(result);
				} 
			}
		};

		vm.cancel = function () {
			$mdDialog.cancel();
		};
	}

})();
