(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("systemSslCertificatesController", systemSslCertificatesController);

    function systemSslCertificatesController($scope, $mdDialog, $timeout, $http, $filter, $rootScope, errorHandling, unsavedChangesTrackingService, claimsService) {
        var vm = this;
        vm.ready = false;
        vm.gridSections = [
            { translate: "OPTIONS", length: -1 },
            { translate: "CERTIFICATES", length: 0},
            { translate: "AUTOMATIC_CERTIFICATES", length: 0},
        ];
        vm.selectedSection = "OPTIONS";
        vm.changeSection = function (section) {
            if (unsavedChangesTrackingService.doConfirmDialog) {
                unsavedChangesTrackingService
                    .doConfirmDialog()
                    .then(function () { unsavedChangesTrackingService.doConfirmDialog = undefined; vm.selectedSection = section; }, function () { });
            } else
                vm.selectedSection = section;
        };
        let debouncedRefreshItems = _.debounce(refreshCounts, 500, false);

        // Functions
        activate();

        /////////////////////////

        function activate() {
            refreshCounts();
            $scope.$on("signalR.mailHub.client.sslCertsChanged", () => debouncedRefreshItems());
            $scope.$on("signalR.mailHub.client.acmeCertsChanged", () => debouncedRefreshItems());
        }

        function refreshCounts() {
            $rootScope.spinner.show();
            $http.get("~/api/v1/settings/sysadmin/ssl-certificate-counts")
                .then(onDataLoaded, errorHandling.report)
                .finally($rootScope.spinner.hide);

            function onDataLoaded(result) {
                vm.counts = result.data;
                vm.gridSections[1].length = vm.counts.certificateCount;
                vm.gridSections[2].length = vm.counts.acmeCount;
                vm.ready = true;
            }
        }
    }

})();

