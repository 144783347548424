(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("loginController", loginController);

	function loginController($rootScope, $scope, authStorage, $sce, $http, AuthenticationService, $filter, $location, browserNotifications,
		localStorageService, $timeout, claimsService, $q, coreData, coreDataSettings, $state, localeInfoService,
		$translate, apiBase, errorHandling, successHandling, preferencesStorage, userDataService) {

		var vm = this;
		vm.rememberMe = rememberMeChecked();
		vm.hideCookieNotice = false;
		vm.showPasswordRetrieval = false;
		vm.settingsLoaded = false;
		vm.serverUnderMaintanance = false;
		vm.loading = false;
		vm.badPasswordResetId = false;
		vm.imageUrl = null;
		vm.imageContent = null;
		vm.state = "login"; // States: login, passwordRequest, passwordReset, aupAgreement
		vm.initializing = false;
		vm.displayName = null;
		vm.availableLocales = [];
		vm.imageFile = null;
		vm.customTitle = "";
		for (var i = 0; i < angularLangNames.length; i++) {
			vm.availableLocales.push(angularLangNames[i]);
		}
		vm.notificationsSupported = browserNotifications.supported && browserNotifications.permission === "default";
		vm.hasTranslations = false;
		vm.isInit = false;
		vm.isIOS = (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);
		vm.twoFactorCode = "";
		vm.needsTwoFactor = false;
		vm.appPasswordTypes = ["UNUSED", "APP_PASSWORD_PSI", "APP_PASSWORD_EAS", "APP_PASSWORD_EWS", "APP_PASSWORD_WEBDAV", "INTERNAL", "INTERNAL", "SERVICES", "WEBMAIL", "APP_PASSWORD_MAPI", "LDAP", "SHAREPOINT", "APP_PASSWORD_XMPP"];
		vm.aupHtml = "";
		vm.passwordExpiredMessage = undefined;

		// Functions
		vm.dismissCookieNotice = dismissCookieNotice;
		vm.login = login;
		vm.loginWithTwoFactor = loginWithTwoFactor;
		vm.twoFactorNextStep = twoFactorNextStep;
		vm.twoFactorBackStep = twoFactorBackStep;
		vm.checkTwoFactorCode = checkTwoFactorCode;
		vm.twoFactorCantAccess = twoFactorCantAccess;
		vm.recoverTwoFactorReset = recoverTwoFactorReset;
		vm.recoverSetupTwoFactor = recoverSetupTwoFactor;
		vm.cancelTwoFactorLogin = cancelTwoFactorLogin;
		vm.cancelTwoFactorSetup = cancelTwoFactorSetup;
		vm.cantScan = cantScan;
		vm.copySecretKey = copySecretKey;
		vm.forgotPasswordClicked = forgotPasswordClicked;
		vm.cancelForgotPassword = cancelForgotPassword;
		vm.cancelForcePasswordReset = cancelForcePasswordReset;
		vm.captchaCheck = captchaCheck;
		vm.resetPasswordCheck = resetPasswordCheck;
		vm.forceResetPasswordCheck = forceResetPasswordCheck;
		vm.cancelResetPassword = cancelResetPassword;
		vm.saveUserSetup = saveUserSetup;
		vm.setLocale = setLocale;
		vm.userSetupBack = userSetupBack;
		vm.digestCount = 0;
		vm.userAcceptedPolicy = userAcceptedPolicy;
		vm.userDeclinedPolicy = userDeclinedPolicy;

		sessionStorage.removeItem("locale.oneTime");

		// Startup
		checkTranslationInit();

		function userSetupBack() {
			if (vm.twoFactorSetupStep) {
				vm.oldState = "userSetup";
				vm.state = "twoFactorSetup";
			} else {
				vm.state = "login";
			}
		}

        vm.scaffoldMode = false;

		if (window.location.hash.indexOf("?scaffold") !== -1)
			vm.scaffoldMode = true;

		//$rootScope.$watch(function () { vm.digestCount++; $log.log("Digest " + vm.digestCount); });

		function setLocale(val) {
			localeInfoService.setLocale(val);
			localStorageService.set("login.language.changed", true);
		}

		function checkTranslationInit() {
			// Wait for a guaranteed translation before letting anything else load
			localeInfoService
				.onReady()
				.then(onSuccessOrFailure, onSuccessOrFailure);

			function onSuccessOrFailure() {
				vm.hasTranslations = true;
				init();
			}
		}

		function setSessionAutoLoginData(event) {
			// Some extensions (like grammerly) actually use message and it breaks the page because it keeps resetting the fields if we dont return.
			if (!event.data || event.data.wappalyzer ||
				(typeof (event.data) === "string" && event.data.toUpperCase().indexOf("SETIMMEDIATE") > -1) ||
				(event.data.source && event.data.source.toUpperCase().contains("REACT-DEVTOOLS"))) {
				return;
			}
			if (!event.data.username && !event.data.password && !event.data.autoLogin)
				return;

			if (window.parent === window) {
				vm.password = event.data.password;
				vm.username = event.data.username;
				if (event.data.autoLogin)
					login();
				else
					$scope.$applyAsync();
			} else {
				authStorage.deleteAll("login.controller setSessionAutoLoginData");
				delete localStorage.claims;

				sessionStorage.setItem("username", event.data.username);
				sessionStorage.setItem("password", event.data.password);
				sessionStorage.setItem("autoLoginFromSession", event.data.autoLogin);
				$timeout(function () { window.parent.postMessage({}, "*"); }, 100);
			}
		}

		function init() {
			vm.autologinToken = localStorage.getItem("smAutoLoginToken");
			window.addEventListener("message", setSessionAutoLoginData);
			$scope.$on("$destroy", function () { window.removeEventListener("message", setSessionAutoLoginData); });

			setImageFile();
			resetCaptchaData();
			vm.hideCookieNotice = !!localStorageService.get("login.cookie-notice-dismissed");

            AuthenticationService
				.loadLoginSettings()
				.then(onLoginSettingsLoaded, onLoginSettingsFailed);

			if (authStorage.getPasswordResetId()) {
				vm.state = "passwordReset";
				vm.resetPasswordSuccess = $filter("translate")("PASSWORD_RESET_RESET_PASSWORD_MESSAGE");
				resetPasswordInitialCheck();
				$timeout(function () { $("input[name=newPassword]").trigger("focus"); });
			}
			if ($location.search()["recover"]) {
				vm.tfRecover = $location.search()["recover"];
				vm.state = "twoFactorRecover";
			}

			if (vm.autologinToken) {
				localStorage.removeItem("smAutoLoginToken");
				login();
				if (!vm.error) {
					return;
				}
			}
			vm.isInit = true;
		}

		function setImageFile() {
			// Opera 8.0+
			var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;

			// Firefox 1.0+
			var isFirefox = typeof InstallTrigger !== "undefined";

			// Safari 3.0+ "[object HTMLElementConstructor]" 
			var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window["safari"] || safari.pushNotification);

			// Internet Explorer 6-11
			var isIE = /*@cc_on!@*/false || !!document.documentMode;

			// Edge 20+
			var isEdge = !isIE && !!window.StyleMedia;

			// Chrome 1+
			var isChrome = !!window.chrome && !!window.chrome.webstore;

			// Blink engine detection
			var isBlink = (isChrome || isOpera) && !!window.CSS;

			if (isOpera)
				vm.imageFile = null;// (stSiteRoot || '/') + "interface/img/browser-messages/browser-notifications-opera.jpg";
			else if (isFirefox)
				vm.imageFile = (stSiteRoot || "/") + "interface/img/browser-messages/browser-notifications-firefox.jpg";
			else if (isSafari)
				vm.imageFile = null;// (stSiteRoot || '/') + "interface/img/browser-messages/browser-notifications-safari.jpg";
			else if (isIE)
				vm.imageFile = null;// (stSiteRoot || '/') + "interface/img/browser-messages/browser-notifications-ie.jpg";
			else if (isEdge)
				vm.imageFile = (stSiteRoot || "/") + "interface/img/browser-messages/browser-notifications-edge.jpg";
			else if (isChrome || isBlink)
				vm.imageFile = (stSiteRoot || "/") + "interface/img/browser-messages/browser-notifications-chrome.jpg";
		}

		function onLoginSettingsFailed(data) {
			vm.settingsLoaded = false;
			vm.serverUnderMaintanance = true;
			vm.serverErrorCode = data ? data.message : "";
			if (data && data.data && data.data.message && data.data.message === "convert") {
				window.location.href = window.location.origin + "/interface/convert-notice";
			}
		}

		var retryNumber = 0;
		var retryTimes = [500, 500, 500, 1000, 1000, 2000, 2000, 5000];

		function onLoginSettingsLoaded(data) {
			if (data && data === 'loading') {
				vm.loading = true;

				let retryIndex = retryNumber >= retryTimes.length ? retryTimes.length - 1 : retryNumber;
				let retryTime = retryTimes[retryIndex];
				retryNumber++;

				console.log('waiting ' + retryTime);
				$timeout(function () {
					AuthenticationService
						.loadLoginSettings()
						.then(onLoginSettingsLoaded, onLoginSettingsFailed)
				}, retryTime);
			} else {
				vm.loading = false;
				setLocale(localeInfoService.getValidLocale(vm.availableLocales));

				vm.customTitle = data.customTitle;
				vm.showPasswordRetrieval = data.allowPasswordRecovery;
				vm.imageUrl = data.customLogo;
				vm.welcomeMessage = "";
				if (data.interfaceShowGettingStartedPrompt)
					localStorage.setItem('interface_show_getting_started_prompt', true);
				else
					localStorage.removeItem('interface_show_getting_started_prompt');

				$translate("WELCOME_TO_SMARTERMAIL").then(function () { }, function () { });
				vm.helpText = data.customHelpText;
				if (!vm.helpText) {
					vm.helpText = $translate.instant("OPEN_HELP");
				}
				vm.helpUrl = data.customHelpUrl;
				if (!vm.helpUrl) {
					vm.helpUrl = "https://help.smartertools.com/SmarterMail/current/?page=login&v=17";
				}
				vm.customHtmlBlock = $sce.trustAsHtml(data.customHtmlBlock);
				vm.backgroundColor = data.backgroundColor;

				if ($(window).width() < 737) {
					vm.backgroundColor = "#444";
					vm.backgroundImage = null;
				}
				else
					vm.backgroundImage = data.backgroundStillImage;

				$rootScope.$broadcast("pageTitleUpdated", data.customPageTitle);

				// Get System Favicon
				//$http
				//	.get('~/api/v1/companyinfo/favicon/')
				//	.then(function (success) {
				//		if (success.data != null) { // The server has the Favicon so use this image
				//			var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
				//			link.type = 'image/x-icon';
				//			link.rel = 'shortcut icon';
				//			link.href = stSiteRoot + 'api/v1/companyinfo/favicon/'
				//			document.getElementsByTagName('head')[0].appendChild(link);
				//		}
				//	}, function () {
				//		// Do nothing for now
				//	});

				// Already logged in?
				var refreshToken = authStorage.getRefreshToken();
				var autoLoginFromSession = sessionStorage.autoLoginFromSession;
				if ((refreshToken || autoLoginFromSession) && window.parent === window) {
					vm.initializing = true;
					localeInfoService.applyTextDirectionCss();
					if (autoLoginFromSession) {
						vm.username = sessionStorage.username;
						vm.password = sessionStorage.password;
						delete sessionStorage.autoLoginFromSession;
						delete sessionStorage.username;
						delete sessionStorage.password;
						if (autoLoginFromSession === "true") {
							login()
								.then(
									function (data) {
										if (data.displayWelcomeWizard) {
											vm.initializing = false;
											vm.settingsLoaded = true;
											$scope.$applyAsync();
										}
									}, setupLogin);
						} else {
							setupLogin();
						}
					} else {
						AuthenticationService
							.refreshToken()
							.then(
								function (response) {
									// We don't want to set the display name if we're impersonating
									if (!claimsService.impersonating())
										vm.displayName = claimsService.getDisplayName();
									preloadAndGotoInterface(response.displayWelcomeWizard, claimsService.isSysAdmin());

									// next function doesn't work unless remember me is checked
									//var cu = authStorage.getCurrentUser();
								}, setupLogin);
					}
				}
				else {
					vm.rememberMe = false;
					setupLogin();
				}
			}
		}

		function setupLogin() {
			vm.initializing = false;
			vm.settingsLoaded = true;
			$timeout(function () {
				localeInfoService.applyTextDirectionCss();

				if (!vm.loginForm)
					return;

				vm.manualEmailInput = document.getElementById("loginUsernameBox");
				vm.manualPasswordInput = document.getElementById("loginPasswordBox");

				$timeout(function () {
					vm.username = vm.manualEmailInput ? vm.manualEmailInput.value : null;
					vm.password = vm.manualPasswordInput ? vm.manualPasswordInput.value : null;

					if (!vm.username) $("#loginUsernameBox").trigger("focus");
					else $("#loginPasswordBox").trigger("focus");

					vm.loginForm.$setPristine();
					vm.loginForm.$setUntouched();
					$scope.$applyAsync();
				}, 200, false);
			}, 100);
		}

		function preloadAndGotoInterface(displayWelcomeWizard) {
			const isSysAdmin = claimsService.isSysAdmin();

			if (displayWelcomeWizard && isSysAdmin) {
				$window.location.href = stSiteRoot + "interface/setup";
			} else {
				coreData
					.init({ showSpinner: false })
					.then(onSuccess, onFailure);
			}

			function onSuccess() {
				claimsService.navigateOnLoginSuccess();

				vm.settingsLoaded = true;
			}

			function onFailure() {
				vm.initializing = false;
				vm.settingsLoaded = true;
			}
		}

		function dismissCookieNotice() {
			localStorageService.set("login.cookie-notice-dismissed", true);
			vm.hideCookieNotice = true;
		}

		function login(twoFactorCode) {
			var defer = $q.defer();
			//if (vm.rememberMe && authStorage.localStorageHasValue()) {
			//! TODO:: [SMAIL-2439] If it causes problems, delete remember me data for old user from localStorage
			//vm.error = $filter('translate')('ANOTHER_USER_ALREADY_LOGGED_IN');
			//return defer.reject();
			//}

			if (twoFactorCode == null)
				twoFactorCode = "";
			if (!twoFactorCode && vm.twoFactorCode) {
				twoFactorCode = vm.twoFactorCode;
			}

			if ((vm.username && vm.password && vm.username.length > 0 && vm.password.length > 0) || vm.autologinToken) {
				vm.loading = true;
				apiBase.invalidateAll();
				authStorage.saveRememberMe(vm.rememberMe);

				var oldInit = vm.initializing;
				var showLoggingIn = $timeout(function() {
					vm.initializing = true;
					showLoggingIn = undefined;
				}, 500);

				AuthenticationService
					.Login(vm.username, vm.password, twoFactorCode, vm.twoFactorSetupGuid, vm.autologinToken,
						function (result, changeState, reason, newLoginToken, isAdmin) {
							if (showLoggingIn) {
								$timeout.cancel(showLoggingIn);
								showLoggingIn = undefined;
							}

							if (newLoginToken) {
								vm.autologinToken = newLoginToken;
							} else {
								vm.autologinToken = "";
							}
							if (changeState) {
								switch (changeState) {
									case "forcePasswordReset":
										vm.password = "";
										vm.isSysAdmin = isAdmin;
										$timeout(function () { $("input[name=oldPassword]").trigger("focus"); });
										break;
									case "forcePasswordExpired":
										changeState = "forcePasswordReset";
										vm.password = "";
										vm.passwordExpiredMessage = $filter("translate")("PASSWORD_RESET_DESCRIPTION_EXPIRED");
										$timeout(function () { $("input[name=oldPassword]").trigger("focus"); });
										break;
									case "userSetup":
										loadTimeZones();
										vm.recoveryEmail = coreDataSettings.userSettings.backupEmailAddress;
										vm.showRecoveryEmail = coreDataSettings.userPermissions.passwordSettingsEnablePasswordRetrieval && !coreDataSettings.userSettings.backupEmailAddress;
										break;
									default:
										break;
								}

								vm.state = changeState;
								vm.loading = false;
								vm.initializing = oldInit;
								vm.isInit = true;
								vm.twoFactorCode = "";
								return defer.resolve({ displayWelcomeWizard: vm.state === "userSetup" });
							}
							// TODO: Remove redirect and preload logic from AuthService and move to here
							vm.displayName = claimsService.getDisplayName();
							if (vm.state !== "twoFactorLogin" && vm.state !== "aupAgreement") {
								resetCaptchaData();
							}
							if (result === false) {
								vm.initializing = oldInit;
								switch (reason) {
									case "notLicensed":
										vm.error = $filter("translate")("LOGIN_PAGE_NOT_LICENSED");
										vm.loading = false;
										$("#loginPasswordBox").select().trigger("focus");
										break;
									case "serverDown":
										vm.error = $filter("translate")("LOGIN_PAGE_MAINTENANCE_BODY");
										vm.loading = false;
										init();
										break;
									default:
										if (reason && reason.indexOf("POLICY_NOT_ACCEPTED") !== -1) {
											let split = reason.split("|");
											vm.aupHtml = $sce.trustAsHtml(split[1]);
											vm.state = "aupAgreement";
											vm.loading = false;
											break;
										}

										if (reason && reason.indexOf("TWO_FACTOR_REQUIRED") !== -1) {
											twoFactorRequiredChecks(reason);
											vm.loading = false;
											let split = reason.split("|");
											if (split.length >= 7)
												setLocale(split[6])
											break;
										}
										if (reason !== undefined && reason !== "") {
											if (reason.contains(" ")) {
												var splits = reason.split(" ");
												var transReason = "";
												for (var i = 0; i < splits.length; i++)
													transReason += " " + $filter("translate")(splits[i]);
												vm.error = transReason.trim();
											} else
												vm.error = $filter("translate")(reason);
										}
										else
											vm.error = $filter("translate")("USERNAME_OR_PASSWORD_INCORRECT");
										vm.loading = false;
										$("#loginPasswordBox").select().trigger("focus");
										break;
								}
								vm.isInit = true;

								// If we autologin or remember me and get an error it won't show until we do SetupLogin
								if (vm.initializing)
									setupLogin();
							}

							preferencesStorage.setSortingFilteringParam("chat", "doLogout", undefined);
							preferencesStorage.setSortingFilteringParam("system", "doLogout", undefined);

							return defer.resolve({});
						});
			}
			return defer.promise;
		}

		function userAcceptedPolicy() {
			$http
				.post("~/api/v1/settings/user-aup-accepted-version", JSON.stringify({ email: vm.username }))
				.then(function () { login(vm.twoFactorCode); }, function () { });
		}

		function userDeclinedPolicy() {
			vm.username = undefined;
			vm.password = undefined;
			vm.state = "login";
			$timeout(function () { $("input[name=username]").trigger("focus"); });
		}

		function twoFactorRequiredChecks(reason) {
			vm.error = "";
			if (reason.indexOf("DOMAIN_FORCED_NOT_SETUP") !== -1 || reason.indexOf("SYSADMIN_NOT_SETUP") !== -1)  {
				//Should be in this format "TWO_FACTOR_REQUIRED|DOMAIN_FORCED_NOT_SETUP|{typesAllowed}|{guid}|{fullEmail}|{recoveryEmail}"
				var split = reason.split("|");
				vm.tempTypes = split[2].split(",");
				vm.twoFactorSetupTypes = [];
				for (var i = 0; i < vm.tempTypes.length; ++i) {
					switch (vm.tempTypes[i]) {
						case "email":
						case 1:
							vm.twoFactorSetupTypes.push({ value: 1, translation: "RECOVERY_EMAIL" });
							break;
						case "rfc6238":
						case 2:
							vm.twoFactorSetupTypes.unshift({ value: 2, translation: "AUTHENTICATOR_APP" });
							break;
					}
				}
				vm.tfSetupType = vm.twoFactorSetupTypes[0].value;
				vm.tfSetupUserType = reason.indexOf("SYSADMIN_NOT_SETUP") !== -1 ? "sysadmin": "user";
				vm.twoFactorSetupGuid = split[3];
				vm.tfUserEmail = split[4];
				vm.tfRecoveryMail = split[5];
				vm.state = "twoFactorSetup";
				vm.twoFactorSetupStep = 0;
				if (vm.tfSetupUserType !== 'sysadmin') 
					$timeout(function () { $("input[name=tfrecoverymail]").trigger("focus"); });
			} else {
				//Should be in this format "TWO_FACTOR_REQUIRED|{type}|{fullEmail}"
				vm.tfUserEmail = reason.split("|")[2];
				vm.tfSetupType = reason.split("|")[1];
				vm.needsTwoFactor = true;
				switch (vm.tfSetupType) {
					case "email":
					case 1:
						vm.error = $translate.instant("TWO_FACTOR_SETUP_2_MAIL");
						break;
					case "rfc6238":
					case 2:
						vm.error = $translate.instant("TWO_FACTOR_SETUP_2_RFC");
						break;
				}
				$timeout(function () { $("input[name=twofactorcode]").trigger("focus"); });
			}
		}

		function twoFactorNextStep() {
			if (vm.twoFactorSetupStep === 0 && !vm.tfRecoveryMail && vm.tfSetupUserType !== 'sysadmin') {
				return;
			}

			if (vm.twoFactorSetupStep === 0) {
				var generateUserTwoFactor = function () {
						$http
							.post("~/api/v1/settings/user-generate-two-factor/" + (vm.tfSetupType == 1 ? "email" : "rfc6238") + "/" + vm.twoFactorSetupGuid, JSON.stringify({ email: vm.tfUserEmail }))
							.then(
								function (result) {
									vm.tfSecretKey = result.data.clientSecret;
									switch (vm.tfSetupType) {
										case 1:
											vm.twoFactorSetupStep = 2;
											$timeout(function () { $("input[name=checkFactorCodeMail]").trigger("focus"); });
											break;
										case 2:
											vm.twoFactorSetupStep = 1;
											vm.tfQrCode = result.data.qrString;
											setTimeout(function () {
												var element = document.getElementById("qrcode");
												if (element) {
													new QRCode(element, {
														text: vm.tfQrCode,
														width: 150,
														height: 150,
													});
												}
												$("input[name=checkFactorCode]").trigger("focus");
											}, 250);
											break;
									}
								}, function () { }
							);
					}
				if (vm.tfSetupUserType == 'sysadmin') {
					$http
						.post("~/api/v1/settings/admin-set-two-factor-recovery/" + vm.twoFactorSetupGuid, JSON.stringify({ email: vm.tfUserEmail }))
						.then(generateUserTwoFactor);

				} else {
					$http
						.post("~/api/v1/settings/user-set-two-factor-recovery/" + vm.tfRecoveryMail + "/" + vm.twoFactorSetupGuid, JSON.stringify({ email: vm.tfUserEmail }))
						.then(generateUserTwoFactor);
				}
				//promise
				//	.success(function () {
				//			$http
				//				.post("~/api/v1/settings/user-generate-two-factor/" + (vm.tfSetupType == 1 ? "email" : "rfc6238") + "/" + vm.twoFactorSetupGuid, JSON.stringify({ email: vm.tfUserEmail }))
				//				.then(
				//					function (result) {
				//						vm.tfSecretKey = result.data.clientSecret;
				//						switch (vm.tfSetupType) {
				//							case 1:
				//								vm.twoFactorSetupStep = 2;
				//								$timeout(function () { $("input[name=checkFactorCodeMail]").trigger("focus"); });
				//								break;
				//							case 2:
				//								vm.twoFactorSetupStep = 1;
				//								vm.tfQrCode = result.data.qrString;
				//								setTimeout(function () {
				//									var element = document.getElementById("qrcode");
				//									if (element) {
				//										new QRCode(element, {
				//											text: vm.tfQrCode,
				//											width: 100,
				//											height: 100,
				//										});
				//									}
				//									$("input[name=checkFactorCode]").trigger("focus");
				//								}, 250);
				//								break;
				//						}
				//					}, function () { }
				//				);
				//		})
				//	.error(function () { });
					////Set the users recovery email then generate their two factor. The recovery email has to be set first if email is being used as the two factor method.
					//$http
					//	.post("~/api/v1/settings/user-set-two-factor-recovery/" + vm.tfRecoveryMail + "/" + vm.twoFactorSetupGuid, JSON.stringify({ email: vm.tfUserEmail }))
					//	.then(function () {
					//		$http
					//			.post("~/api/v1/settings/user-generate-two-factor/" + (vm.tfSetupType == 1 ? "email" : "rfc6238") + "/" + vm.twoFactorSetupGuid, JSON.stringify({ email: vm.tfUserEmail }))
					//			.then(
					//				function (result) {
					//					vm.tfSecretKey = result.data.clientSecret;
					//					switch (vm.tfSetupType) {
					//						case 1:
					//							vm.twoFactorSetupStep = 2;
					//							$timeout(function () { $("input[name=checkFactorCodeMail]").trigger("focus"); });
					//							break;
					//						case 2:
					//							vm.twoFactorSetupStep = 1;
					//							vm.tfQrCode = result.data.qrString;
					//							setTimeout(function () {
					//								var element = document.getElementById("qrcode");
					//								if (element) {
					//									new QRCode(element, {
					//										text: vm.tfQrCode,
					//										width: 100,
					//										height: 100,
					//									});
					//								}
					//								$("input[name=checkFactorCode]").trigger("focus");
					//							}, 250);
					//							break;
					//					}
					//				}, function () { }
					//			);
					//	}, function () { });
			} else if (vm.twoFactorSetupStep === 1 && vm.tfSetupType !== 1 && !vm.hasVerifiedTwoFactor) {
				checkTwoFactorCode();
			} else if (vm.twoFactorSetupStep === 1 && vm.tfSetupType !== 1 && vm.hasVerifiedTwoFactor ) {

				getAppPasswords();
			} else if (vm.twoFactorSetupStep === 2 && vm.tfSetupType !== 1) {
				if (!vm.hasVerifiedTwoFactor) {
					return;
				}
				vm.twoFactorSetupStep = 3;
			} else if (vm.twoFactorSetupStep === 2 && vm.tfSetupType === 1) {
				getAppPasswords();
			} else if (vm.twoFactorSetupStep === 3) {
				cancelTwoFactorSetup();
			} else if (vm.twoFactorSetupStep === 4) {
				if (vm.oldState) {
					vm.state = vm.oldState;
				} else if (vm.password) {
					login();
				} else {
					cancelTwoFactorLogin();
				}
			}
		}

		function twoFactorBackStep() {
			vm.hasVerifiedTwoFactor = false;
			vm.tfCodeError = false;
			vm.twoFactorCode = "";
			if (vm.twoFactorSetupStep === 1) {
				if (vm.tfCantScan) {
					vm.tfCantScan = false;
					$timeout(function () { $("input[name=checkFactorCode]").trigger("focus"); });
				} else {
					vm.tfQrCode = "";
					vm.tfSecretKey = "";
					$("#qrcode").empty();
					vm.twoFactorSetupStep = 0;
				}
			} else if (vm.twoFactorSetupStep === 2 && vm.tfSetupType === 1) {
				vm.twoFactorSetupStep = 0;
			} else if (vm.twoFactorSetupStep === 2 && vm.tfSetupType !== 1) {
				vm.twoFactorSetupStep = 1;
			}
		}

		function checkTwoFactorCode() {
			if (!vm.twoFactorCode)
				return;

			var oType = (vm.tfSetupType === 1 ? "email" : "rfc6238");
			$http
				.post("~/api/v1/settings/user-check-two-factor-code/" + oType + "/" + vm.twoFactorCode + "/" + vm.tfSecretKey + "/" + vm.twoFactorSetupGuid, JSON.stringify({ email: vm.tfUserEmail }))
				.then(onSuccess, onFailure);

			function onSuccess(success) {
				if (success.data.result === true) {
					vm.tfCodeError = false;
					$http.post("~/api/v1/settings/user-set-two-factor-secret/" + vm.tfSecretKey + "/" + oType + "/" + vm.twoFactorSetupGuid, JSON.stringify({ email: vm.tfUserEmail }))
						.then(function () {
							vm.hasVerifiedTwoFactor = true;
							twoFactorNextStep();
						}, function () { });
				} else {
					vm.tfCodeError = true;
				}
			}

			function onFailure() {
				vm.tfCodeError = true;
			}
		}

		function getAppPasswords() {
			if (vm.tfSetupUserType === 'sysadmin') {
				vm.twoFactorSetupStep = 4;
				twoFactorNextStep();
				return;
			}
			$http
				.post("~/api/v1/settings/app-passwords/" + vm.twoFactorSetupGuid, JSON.stringify({ email: vm.tfUserEmail }))
				.then(function (success) {
					vm.tfAppPasswords = success.data.devices || [];
					vm.twoFactorSetupStep = 4;
				}, function () { });
		}

		function twoFactorCantAccess(ev, form) {
			const disableRecoveryLink = function(duration) {

				if (ev && ev.currentTarget) {
					ev.currentTarget.classList.add('disabled');
				}
			}
			vm.tfCodeError = false;
			vm.error = "";
			$http
				.post("~/api/v1/settings/user-recover-two-factor/" + vm.tfUserEmail)
				.then(function (success) {
					disableRecoveryLink();
					vm.recoveryCodeSent = true;
					vm.recoveryEmailAddressMasked = success.data.result;
					vm.captchaSuccess = $filter("translate")("TWO_FACTOR_SENT_RECOVERY", { email:vm.recoveryEmailAddressMasked });
					cancelTwoFactorLogin();
				}, function () { });
		}

		function recoverTwoFactorReset() {
			$http
				.post("~/api/v1/settings/user-reset-two-factor/", { input: vm.tfRecover })
				.then(onSuccess, onFailure);

			function onSuccess(success) {
				if (success.data.result.indexOf("|") === -1) {
					vm.error = $filter("translate")(success.data.result);
				} else {
					vm.tfRecover = "";
					vm.hasResetTwoFactor = true;
					//Format should be {required}|{types}|{guid}|{email}|{recoveryEmail}
					var split = success.data.result.split("|");
					vm.requireTwoFactorReset = split[0] === "REQUIRED" ? true : false;
					vm.tempTypes = split[1].split(",");
					vm.twoFactorSetupTypes = [];
					for (var i = 0; i < vm.tempTypes.length; ++i) {
						switch (vm.tempTypes[i]) {
						case "email":
						case 1:
							vm.twoFactorSetupTypes.push({ value: 1, translation: "RECOVERY_EMAIL" });
							break;
						case "rfc6238":
						case 2:
							vm.twoFactorSetupTypes.push({ value: 2, translation: "AUTHENTICATOR_APP" });
							break;
						}
					}
					vm.tfSetupType = vm.twoFactorSetupTypes[0].value;
					vm.twoFactorSetupGuid = split[2];
					vm.tfUserEmail = split[3];
					vm.tfRecoveryMail = split[4];
				}
			}

			function onFailure(failure) {
				vm.error = getErrorMessage(failure.data.message);
			}
		}

		function recoverSetupTwoFactor() {
			vm.state = "twoFactorSetup";
			vm.twoFactorSetupStep = 0;
			$timeout(function () { $("input[name=tfrecoverymail]").trigger("focus"); });
		}

		function cancelTwoFactorSetup() {
			vm.twoFactorSetupStep = 0;
			vm.twoFactorCode = "";
			vm.tfSecretKey = "";
			vm.tfRecoveryMail = "";
			vm.tfConfirmRecoveryMail = "";
			vm.tfCantScan = false;
			vm.tfUserEmail = "";
			vm.twoFactorSetupGuid = "";
			vm.error = "";
			vm.tfCodeError = false;
			vm.tfSetupType = "";
			vm.hasVerifiedTwoFactor = false;
			vm.state = "login";
			vm.tfRecover = "";
			vm.hasResetTwoFactor = false;
			vm.requireTwoFactorReset = false;
			vm.tfAppPasswords = [];
		}

		function loginWithTwoFactor() {
			login(vm.twoFactorCode);
		}

		function cancelTwoFactorLogin() {
			cancelTwoFactorSetup();
		}

		function cantScan() {
			vm.tfCantScan = true;
			$timeout(function () { $("input[name=checkFactorCode]").trigger("focus"); });
		}

		function copySecretKey() {
			var copyText = $("#copy-key")[0];
			var range = document.createRange();
			range.selectNode(copyText);
			window.getSelection().removeAllRanges();
			window.getSelection().addRange(range);
			document.execCommand("copy");
			window.getSelection().removeAllRanges();
			successHandling.report($translate.instant("COPIED_TO_CLIPBOARD"));
		}

		function rememberMeChecked() {
			return authStorage.isLocalStorageForCurrentUser();
		}

		function forgotPasswordClicked() {
			vm.state = "passwordRequest";
			AuthenticationService
				.getCaptcha()
				.then(onSuccess, onFailure);

			function onSuccess(success) {
				vm.imageContent = success.data.captchaImage;
				$("input[name=email]").trigger("focus");
			}

			function onFailure(failure) {
				vm.captchaError = getErrorMessage(failure.data.message);
			}
		}

		function cancelForgotPassword() {
			resetCaptchaData();
		}

		function captchaCheck() {
			if (vm.email && vm.captchaText && vm.email.length > 0 && vm.captchaText.length > 0) {
				AuthenticationService
					.submitCaptcha(vm.email, vm.captchaText)
					.then(onSuccess, onFailure);
			}

			function onSuccess(success) {
				resetCaptchaData();
				vm.captchaSuccess = $filter("translate")(success.data.message);
			}

			function onFailure(failure) {
				changeCaptchaImage();
				vm.captchaError = getErrorMessage(failure.data.message);
			}
		}

		function getErrorMessage(message) {
			if (message == null)
				return "";

			var result = "";
			var parts = message.split(" ");
			for (var idx = 0; idx < parts.length; ++idx) {
				if (idx > 0)
					result += " ";
				result += $filter("translate")(parts[idx]);
			}
			return result;
		}

		function resetCaptchaData() {
			vm.captchaSuccess = undefined;
			vm.email = undefined;
			vm.captchaText = undefined;
			vm.imageContent = undefined;

			vm.resetPasswordError = undefined;
			vm.resetPasswordSuccess = undefined;
			vm.newPassword = undefined;
			vm.confirmPassword = undefined;

			vm.state = "login";
			$timeout(function () { $("input[name=username]").trigger("focus"); });
		}

		function changeCaptchaImage() {
			vm.captchaText = undefined;
			vm.imageContent = undefined;
			AuthenticationService
				.getCaptcha()
				.then(onSuccess, onFailure);

			function onSuccess(success) {
				vm.imageContent = success.data.captchaImage;
			}

			function onFailure(failure) {
				vm.captchaError = getErrorMessage(failure.data.message);
			}
		}

		function cancelForcePasswordReset() {
			vm.state = "login";
			$timeout(function () { $("input[name=username]").trigger("focus"); });
		}

		function resetPasswordInitialCheck() {
			AuthenticationService
				.resetPasswordRequest(authStorage.getPasswordResetId())
				.then(onSuccess, onFailure);

			function onSuccess(success) {
				if (success.data.username) {
					vm.username = success.data.username;
					return success.data.username;
				}
				return "";
			}

			function onFailure(failure) {
				if (failure.data.message === "PASSWORD_RESET_RESET_ID_NOT_FOUND")
					vm.badPasswordResetId = true;
				vm.resetPasswordError = getErrorMessage(failure.data.message);
				return "";
			}
		}

		function resetPasswordCheck() {
			if (vm.newPassword && vm.confirmPassword && vm.newPassword.length > 0 && vm.confirmPassword.length > 0) {
				AuthenticationService
					.resetPassword(authStorage.getPasswordResetUsername(), authStorage.getPasswordResetId(), vm.newPassword, vm.confirmPassword)
					.then(onSuccess, onFailure);
			} else {
				onFailure("ERROR");
			}

			function onSuccess(success) {
				if (success.data.username && success.data.errorCode) {
					authStorage.savePasswordResetUsername(success.data.username);
					var content;
					if (success.data.errorCode === "PASSWORD_RESET_REQUIRE_LENGTH")
						content = $filter("translate")(success.data.errorCode, { num: success.data.errorData });
					else
						content = $filter("translate")(success.data.errorCode);
					var requireMsg = $filter("translate")("PASSWORD_RESET_REQUIRE", { msg: content });
					vm.resetPasswordError = requireMsg;
				} else {
					authStorage.savePasswordResetId(null);
					authStorage.savePasswordResetUsername(null);
					resetCaptchaData();
					$location.path("/login");
				}
			}

			function onFailure(failure) {
				vm.resetPasswordError = getErrorMessage(failure.data.message);
			}
		}

		function forceResetPasswordCheck() {
			AuthenticationService
				.forceResetPassword(vm.username, vm.password, vm.newPassword, vm.confirmPassword, vm.isSysAdmin)
				.then(onSuccess, onFailure);

			function onSuccess(success) {
				if (success.data.username && success.data.errorCode) {
					var content;
					if (success.data.errorCode === "PASSWORD_RESET_REQUIRE_LENGTH")
						content = $filter("translate")(success.data.errorCode, { num: success.data.errorData });
					else
						content = $filter("translate")(success.data.errorCode);

					var requireMsg = $filter("translate")("PASSWORD_RESET_REQUIRE", { msg: content });
					vm.resetPasswordError = requireMsg;
				} else {
					vm.password = vm.newPassword;
                    login();
                }
			}

			function onFailure(failure) {
				if (failure.data.message.indexOf("PASSWORD_RESET_REQUIRE") > -1) {
					$scope.$broadcast("passwordCheckCallback", failure);
				} 
			}
		}

		function cancelResetPassword() {
			window.close();
			authStorage.savePasswordResetId("");
			vm.state = "login";
			init();
		}

		vm.getImageSource = function (imageUrl) {
			return imageUrl || "img/email.svg";
		};

		// Time zones
		function loadTimeZones() {
			coreDataSettings.settingsData
				.availiableTimeZones
				.then(function (success) {
					vm.timeZoneSearch = "";
					vm.availableTimeZones = success;

					var currentDate = new Date();
					var currentOffset = currentDate.getTimezoneOffset();
					var currentYear = currentDate.getFullYear();
					var jan = new Date(currentYear, 0, 1);
					var jun = new Date(currentYear, 6, 1);
					var janOffset = jan.getTimezoneOffset();
					var junOffset = jun.getTimezoneOffset();
					var usesDst = true;
					if (janOffset === junOffset) {
						usesDst = false;
					}

					var timezone = null;
					var timezoneSet = false;
					if (coreDataSettings && coreDataSettings.userSettings) {
						var temp = $.grep(vm.availableTimeZones,
							function (tz) {
								return tz.index === coreDataSettings.userSettings.timeZoneIndex;
							});
						if (temp.length > 0) {
							timezone = temp[0];
							timezoneSet = true;
						}
					}
					for (var i = 0; i < vm.availableTimeZones.length; ++i) {
						// Translate the name of the timezone
						vm.availableTimeZones[i].translated = $filter("translate")(vm.availableTimeZones[i].displayName);

						if (!timezoneSet) {
							// Determine the users offset, DST, and default to the first timezone with the name US in the daylightName variable or the translated variable.
							if (timezone == null && vm.availableTimeZones[i].utcBias === currentOffset) {
								if (usesDst && vm.availableTimeZones[i].utcBiasDaylight !== 0) {
									timezone = vm.availableTimeZones[i];
								} else if (!usesDst && vm.availableTimeZones[i].utcBiasDaylight === 0) {
									timezone = vm.availableTimeZones[i];
								}
							} else if (vm.availableTimeZones[i].utcBias === currentOffset) {
								if (usesDst && vm.availableTimeZones[i].utcBiasDaylight !== 0) {
									if ((!timezone.translated.indexOf("US") > -1 ||
											!timezone.daylightName.indexOf("US") > -1) &&
										(vm.availableTimeZones[i].translated.indexOf("US") > -1 ||
											vm.availableTimeZones[i].daylightName.indexOf("US") > -1)) {
										timezone = vm.availableTimeZones[i];
									}
								} else if (!usesDst && vm.availableTimeZones[i].utcBiasDaylight === 0) {
									if ((!timezone.translated.indexOf("US") > -1 ||
											!timezone.daylightName.indexOf("US") > -1) &&
										(vm.availableTimeZones[i].translated.indexOf("US") > -1 ||
											vm.availableTimeZones[i].daylightName.indexOf("US") > -1)) {
										timezone = vm.availableTimeZones[i];
									}
								}
							}
						}
					}

					if (timezone != null) {
						vm.timeZone = timezone;
					} else {
						vm.timeZone = vm.availableTimeZones[0];
					}
				}, errorHandling.report);
		}
		
		vm.permissionsFetched = false;
		function saveUserSetup() {
			var params = {
				userMailSettings: {
					timeZoneIndex: vm.timeZone.index,
					backupEmailAddress: vm.recoveryEmail,
					welcomeWizardProgress: 3,
					localeId: localeInfoService.language
				}
			};

			$http
				.post("~/api/v1/settings/user-mail", params)
				.then(function () {
					coreDataSettings.userSettings.timeZoneIndex = vm.timeZone.index;
					coreDataSettings.userSettings.backupEmailAddress = vm.recoveryEmail;
					coreDataSettings.userSettings.welcomeWizardProgress = 3;
					userDataService.reset();
					userDataService.init()
						.then(function() {
								$location.path("/email");
							},
							function() {});
				}, function () { });
		}
	}
})();
