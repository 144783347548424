(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("aboutSmartermailController", aboutSmartermailController);

    function aboutSmartermailController($scope, $mdDialog, $http, coreLicensing) {
        recalculateLicense();
        coreLicensing.watchForChanges($scope, recalculateLicense);

        function recalculateLicense() {
            $scope.version = stProductBuild;
            $scope.edition = coreLicensing.edition;
            $scope.editionString = "";
            $scope.year = moment().year().toString();

            switch ($scope.edition) {
                case coreLicensing.EDITION_ENTERPRISE:
                    $scope.editionString = "LICENSE_PRODUCT_NAME_ENTERPRISE";
                    break;
                case coreLicensing.EDITION_PROFESSIONAL:
                    $scope.editionString = "LICENSE_PRODUCT_NAME_PROFESSIONAL";
                    break;
                default:
                    $scope.editionString = "LICENSE_PRODUCT_NAME_FREE";
                    break;
            };
        }

        $scope.cancel = function () {
            $mdDialog.cancel();
        };
    }
})();
