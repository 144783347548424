(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("adminUserActivityMainController", adminUserActivityMainController);

	function adminUserActivityMainController($scope, $filter, $q, $state, $http, errorHandling, $localStorage, coreData) {
		var vm = this;
		$state.current.data.pageTitle = $filter("translate")("USER_ACTIVITY");
		vm.selectedSection = $localStorage.userActivitySection ? $localStorage.userActivitySection : 'ONLINE_USERS';
		vm.gridSections = [
			{ translate: 'ONLINE_USERS', length: 0 },
			{ translate: 'INACTIVE_USERS', length: 0 }
		];
		vm.inactiveTimeRangeSelection = $localStorage.inactiveSelectedTimeRange ? $localStorage.inactiveSelectedTimeRange : 0;

		// Functions
		vm.getUserActivity = getUserActivity;
		vm.changeSection = changeSection;

		activate();

		/////////////////////

		function activate() {
			coreData
				.init()
				.then(onSuccess, errorHandling.report);

			function onSuccess() {
				var promises = [];
				promises.push(vm.getUserActivity());
				$q.all(promises)
					.then(function (success) {
						$scope.isInit = true;
					}, function (failure) {
						errorHandling.report();
					});
			};

			$scope.$on('reloadUserActivity', getUserActivity);
		};

		function getUserActivity() {
			vm.inactiveTimeRangeSelection = $localStorage.inactiveSelectedTimeRange ? $localStorage.inactiveSelectedTimeRange : 0;
			switch (vm.inactiveTimeRangeSelection) {
				case 0:
					vm.inactiveTimeRange = moment(new Date()).subtract(30, 'days').toDate();
					break;
				case 1:
					vm.inactiveTimeRange = moment(new Date()).subtract(90, 'days').toDate();
					break;
				case 2:
					vm.inactiveTimeRange = moment(new Date()).subtract(6, 'months').toDate();
					break;
				case 3:
					vm.inactiveTimeRange = moment(new Date()).subtract(12, 'months').toDate();
					break;
			}
			return $http.post('~/api/v1/settings/sysadmin/user-activity-counts', { dateTime: vm.inactiveTimeRange })
				.then(function (success) {
					vm.gridSections[0].length = success.data.onlineUsersCount;
					vm.gridSections[1].length = success.data.inactiveUserCounts;
				}, function (failure) {
					errorHandling.report(failure.data.message);
				});
		};

		function changeSection(section) {
			if (vm.selectedSection !== section) {
				vm.selectedSection = section;
				$localStorage.userActivitySection = section;
			}
		};
	}
})();
