(function () {
	"use strict";

	angular.module("smartertools")
		.directive("stInternationalName", stInternationalName);

	function stInternationalName($compile) {
		return {
			restrict: "E",
			scope: {
				asciiName: "<",
				idnName: "<"
			},
			link: function(scope, element) {
				var template = '<span class="st-international-name">{{(idnName || asciiName)}}<md-tooltip md-delay="100" ng-show="idnName && asciiName && idnName !== asciiName">{{asciiName}}</md-tooltip><span>';
				var compiled = $compile(angular.element(template))(scope);
				element.append(compiled);
			}
		}
	}
})();