(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sharedEditSystemMessageController", sharedEditSystemMessageController);

	function sharedEditSystemMessageController($scope, $mdDialog, $filter, $log, $translate, $timeout, userDataService, message, listId) {
		$scope.name = message.name;
		$scope.listId = listId;
		$scope.isDefault = !message.lastModified;
		$scope.editedOn = moment.utc(message.lastModified, "YYYY-MM-DD HH:mm").format("YYYY") < 100 ? $filter("translate")("NA") : $filter("friendlyDateTimeLocalized")(message.lastModified);
		$scope.body = message.body;
		$scope.needsSubject = message.needsSubject;
		$scope.subject = message.subject;
		$scope.translatedName = $filter('translate')('MAILING_LISTS_MESSAGE_TYPES_' + $scope.name.toUpperCase());
		$scope.variables = getVariables();
		$scope.customVariables = [{ key: 'var_dropdown', options: $scope.variables, icon: 'cog', title: $filter('translate')("SIGNATURES_CUSTOM_VARIABLES"), callback: variableCallback }];
		$scope.editorScope = {};
		$scope.afcontext = userDataService.user.isSysAdmin ? "system" : "domainshare";
		$scope.save = function () {
			const body = $scope.editorScope.getHtml();

			$timeout(function () {
				$mdDialog.hide({ name: $scope.name, subject: $scope.subject, body: body });
			});
		};

		$scope.reset = function () {
			   $mdDialog.hide({reset: true});
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};

		function getVariables() {
			const upperName = $scope.name.toUpperCase();
			const variables = {};

			if (upperName === "FOOTER" || upperName === "HEADER" || upperName === "DIGESTFOOTER" ||
				upperName === "DIGESTHEADER" || upperName === "DIGESTSEPARATOR")
			{
				variables["#UnsubscribeLink#"] = $translate.instant("MAILING_LISTS_VARIABLE_UNSUBSCRIBELINK");
				variables["#Recipient"] = $translate.instant("RECIPIENT");
			}
			else if (upperName === "FAILEDTOSETDIGESTMODE" || upperName === "SETDIGESTMODE" ||
				upperName === "FAILEDTOSETSTANDARDMODE" || upperName === "SETSTANDARDMODE" ||
				upperName === "SUBSCRIBE")
			{
				variables["#UnsubscribeLink#"] = $translate.instant("MAILING_LISTS_VARIABLE_UNSUBSCRIBELINK");
			}

			variables["#Sender#"] = $translate.instant("SENDER");

			variables["#DomainDateNow#"] = $translate.instant("MAILING_LISTS_VARIABLE_DOMAINDATENOW");
			variables["#DomainTimeNow#"] = $translate.instant("MAILING_LISTS_VARIABLE_DOMAINTIMENOW");
			variables["#DomainDateTimeNow#"] = $translate.instant("MAILING_LISTS_VARIABLE_DOMAINDATETIMENOW");
			variables["#DomainDayNow#"] = $translate.instant("MAILING_LISTS_VARIABLE_DOMAINDAYNOW");
			variables["#DomainMonthNow#"] = $translate.instant("MAILING_LISTS_VARIABLE_DOMAINMONTHNOW");
			variables["#DomainYearNow#"] = $translate.instant("MAILING_LISTS_VARIABLE_DOMAINYEARNOW");
			variables["#DomainDayNameNow#"] = $translate.instant("MAILING_LISTS_VARIABLE_DOMAINDAYNAMENOW");
			variables["#DomainMonthNameNow#"] = $translate.instant("MAILING_LISTS_VARIABLE_DOMAINMONTHNAMENOW");

			if (upperName !== "HELPMESSAGE" && upperName !== "DOUBLEOPTIN" && upperName !== "PRIVATELIST") {
				variables["#ModeratorDateNow#"] = $translate.instant("MAILING_LISTS_VARIABLE_MODERATORDATENOW");
				variables["#ModeratorTimeNow#"] = $translate.instant("MAILING_LISTS_VARIABLE_MODERATORTIMENOW");
				variables["#ModeratorDateTimeNow#"] = $translate.instant("MAILING_LISTS_VARIABLE_MODERATORDATETIMENOW");
				variables["#ModeratorDayNow#"] = $translate.instant("MAILING_LISTS_VARIABLE_MODERATORDAYNOW");
				variables["#ModeratorMonthNow#"] = $translate.instant("MAILING_LISTS_VARIABLE_MODERATORMONTHNOW");
				variables["#ModeratorYearNow#"] = $translate.instant("MAILING_LISTS_VARIABLE_MODERATORYEARNOW");
				variables["#ModeratorDayNameNow#"] = $translate.instant("MAILING_LISTS_VARIABLE_MODERATORDAYNAMENOW");
				variables["#ModeratorMonthNameNow#"] = $translate.instant("MAILING_LISTS_VARIABLE_MODERATORMONTHNAMENOW");
			}

			variables["#ServerDateNow#"] = $translate.instant("MAILING_LISTS_VARIABLE_SERVERDATENOW");
			variables["#ServerTimeNow#"] = $translate.instant("MAILING_LISTS_VARIABLE_SERVERTIMENOW");
			variables["#ServerDateTimeNow#"] = $translate.instant("MAILING_LISTS_VARIABLE_SERVERDATETIMENOW");
			variables["#ServerDayNow#"] = $translate.instant("MAILING_LISTS_VARIABLE_SERVERDAYNOW");
			variables["#ServerMonthNow#"] = $translate.instant("MAILING_LISTS_VARIABLE_SERVERMONTHNOW");
			variables["#ServerYearNow#"] = $translate.instant("MAILING_LISTS_VARIABLE_SERVERYEARNOW");
			variables["#ServerDayNameNow#"] = $translate.instant("MAILING_LISTS_VARIABLE_SERVERDAYNAMENOW");
			variables["#ServerMonthNameNow#"] = $translate.instant("MAILING_LISTS_VARIABLE_SERVERMONTHNAMENOW");

			return variables;
		}

		function variableCallback(cmd, val, scope) {
			$scope.form.$setDirty(); $scope.$applyAsync();
			if (scope.insertHtml) {
				scope.insertHtml(val);
			}
		}
	}
})();
