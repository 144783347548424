+(function () {
    "use strict";

    //TODO:

	angular
		.module("smartermail")
		.controller("chatHistoryTranscriptController", chatHistoryTranscriptController);

	function chatHistoryTranscriptController($rootScope, $http, $stateParams, coreData, errorHandling, themesService) {

		var vm = this;
		vm.chatTranscript = "";
		coreData
			.init()
			.then(function () {
				vm.hasLoaded = true;
				init();
			}, function () {});

		vm.isDifferentTime = isDifferentTime;

		var init = function () {
            themesService.ensureActivated();
			if ($stateParams.text === 'undefined') {
				$stateParams.text = '';
			}
			if ($stateParams.to === 'undefined') {
				$stateParams.to = '';
			}
			
			var params = {
                startDateUtc: new Date($stateParams.dtStart),
                endDateUtc: new Date($stateParams.dtStop)
			};

			$rootScope.spinner.show();
			$http
				.post('~/api/v1/chat/history-transcript/' + $stateParams.username + "/" + $stateParams.id, JSON.stringify(params))
				.then(function(success) {
						vm.chatTranscript = success.data.result;
						for (var i = 0; i < vm.chatTranscript.length; ++i) {
							var v = vm.chatTranscript[i];
							v.date = new Date(v.date);
							if (v.from === coreData.user.username ||
								v.from === coreData.user.displayName ||
								v.from === coreData.user.emailAddress) {
								v.isMe = true;
							}
						}
					},
					errorHandling.report)
				.finally($rootScope.spinner.hide);
		};

		function isDifferentTime(dt1, dt2) {
			if (!dt1 || !dt2)
				return true;
			if (dt1.toDateString() != dt2.toDateString())
				return true;
			if (dt1.getHours() != dt2.getHours())
				return true;
			if (dt1.getMinutes() != dt2.getMinutes())
				return true;
			return false;
		}
	};
})();