(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("themesService", themesService);

    function themesService($rootScope, $window) {
        var vm = this;
        var debouncedThemeChanged = _.debounce(onThemeChanged, 25);
        var debouncedEnableEffects = _.debounce(enableEffects, 250);

        // Functions ---------------------------------------------------------------
        vm.ensureEventsSetup = function () { }
        vm.getTheme = getTheme;
        vm.isThemeDark = isThemeDark;
        vm.setPrimaryTheme = setPrimaryTheme;
        vm.togglePrimaryTheme = togglePrimaryTheme;
        vm.refreshTheme = function () { debouncedThemeChanged(); }
        vm.ensureActivated = function () { }
        activate();

        // Initialization ----------------------------------------------------------

        function activate() {
            $rootScope.$on("themeService:changed", themeUpdated);
            window.addEventListener("storage", onStorageEvent);

            function onStorageEvent(e1, e2, e3) {
                if (e1 == null || e1.key !== "theme")
                    return;
                signalThemeChanged();
                debouncedThemeChanged();
            }

            function themeUpdated() {
                debouncedThemeChanged();
            }
        }

        // -------------------------------------------------------------------------

        function isDark(themeName) { return (themeName || "").toLowerCase().startsWith("dark"); }
        function isThemeDark() { return isDark(getTheme()); }
        function getTheme() { return isDark($window.localStorage && $window.localStorage.getItem("theme")) ? "dark" : "light"; }

        function setPrimaryTheme(theme) {
            var newTheme = isDark(theme) ? "dark" : "light";
            $window.localStorage && $window.localStorage.setItem("theme", newTheme);
            signalThemeChanged();
        }

        function signalThemeChanged() {
            $rootScope.$broadcast("themeService:changed", {});
        }

        function togglePrimaryTheme() {
            setPrimaryTheme(isThemeDark() ? "light" : "dark");
        }

        function applyThemeHtmlElements() {
            var isDarkMode = isThemeDark();
            $("html")
                .addClass('disable-effects')
                .toggleClass("theme-light", !isDarkMode)
                .toggleClass("theme-dark", isDarkMode);
            $("body")
                .toggleClass("theme-light", !isDarkMode)
                .toggleClass("theme-dark", isDarkMode);
            debouncedEnableEffects();
        }

        function enableEffects() {
            $("html").removeClass('disable-effects');
        }

        function onThemeChanged() {
            applyThemeHtmlElements();
        }
    }

})();