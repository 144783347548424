(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("coreDataNotes", coreDataNotes);

    function coreDataNotes($http, $q, $filter, $rootScope, $log, $translate, userDataService, apiCategories, coreDataSettings, preferencesStorage, apiNoteSources) {
        var isInitialized = false;
        var isSourcesLoaded = false;
        var isNotesLoaded = false;
        var isNoteShared = [];
        var sources = [];
        var notes = [];
        var initDefer;
        var previousTreeController;
        var _sourcesTree = {
            data: [],	// Used by tree directive
            map: [],	// Reference to treeData nodes, used to quickly find and change values
            selectedBranchData: {}
        };
        var _colorsTree = {
            data: [],	// Used by tree directive
            map: [],	// Reference to treeData nodes, used to quickly find and change values
            selectedBranchData: {}
        };

        var sourcesDefer;

        // public members
        var vm = this;
        vm.reloadOnEnter = false;
        vm.ignoreNoteModified = { requested: moment(), ignored: moment() };
        vm.ignoreNoteRemoved = { requested: moment(), ignored: moment() };
        vm.categories = function () {
            return apiCategories.getMasterCategories();
        };
        vm.resetSources = function () { isSourcesLoaded = false; apiNoteSources.invalidateSources(); };
        vm.resetNotes = function () { isNotesLoaded = false; return loadNotes(); };
        vm.areNotesLoaded = function () { return isNotesLoaded; };
        vm.parameters = {
            get sortField() {
                var value = preferencesStorage.getSessionSortingFilteringParam("notes", "sortField");
                if (value === undefined) {
                    value = "lastModifiedUTC";
                    preferencesStorage.setSessionSortingFilteringParam("notes", "sortField", value);
                }
                return value;
            },
            set sortField(value) { preferencesStorage.setSessionSortingFilteringParam("notes", "sortField", value); },

            get isDescending() {
                var value = preferencesStorage.getSessionSortingFilteringParam("notes", "isDescending");
                if (value === undefined) {
                    value = true;
                    preferencesStorage.setSessionSortingFilteringParam("notes", "isDescending", value);
                }
                return value;
            },
            set isDescending(value) { preferencesStorage.setSessionSortingFilteringParam("notes", "isDescending", value); },

            get sortReverse() {
                var value = preferencesStorage.getSessionSortingFilteringParam("notes", "sortReverse");
                if (value === undefined) {
                    value = false;
                    preferencesStorage.setSessionSortingFilteringParam("notes", "sortReverse", value);
                }
                return value;
            },
            set sortReverse(value) { preferencesStorage.setSessionSortingFilteringParam("notes", "sortReverse", value); },

            get categoryFilters() {
                const catFilter = apiCategories.getCategoryFilter("notes");
                return !catFilter ? [] : catFilter.categoryData;
            },
            //set categoryFilters(value) { preferencesStorage.setSessionSortingFilteringParam("notes", "categoryFilters", value); },

            get colorFilters() {
                var value = preferencesStorage.getSessionSortingFilteringParam("notes", "colorFilters");
                if (value === undefined) {
                    value = [
                        '#FFFFFF',	// white
                        '#FAD122',	// yellow
                        '#FDB2B3',	// pink
                        '#68BD45',	// green
                        '#006693',	// blue
                        'white',
                        'yellow',
                        'pink',
                        'green',
                        'blue'
                    ];
                    preferencesStorage.setSessionSortingFilteringParam("notes", "colorFilters", value);
                }
                return value;
            },

            set colorFilters(value) {
                preferencesStorage.setSessionSortingFilteringParam("notes", "colorFilters", value);
            },

            get currentView() {
                var value = preferencesStorage.getSortingFilteringParam("notes", "currentView");
                if (value === undefined) {
                    value = "CARD_VIEW"; preferencesStorage.setSortingFilteringParam("notes", "currentView", value);
                }
                return value;
            },
            set currentView(value) { preferencesStorage.setSortingFilteringParam("notes", "currentView", value); },
            get hasCategoryFilter() {
                const catFilter = apiCategories.getCategoryFilter("notes");
                return !catFilter || !catFilter.allSelected();
            },

            get filterType() {
                return preferencesStorage.getSessionSortingFilteringParam("notes", "filterType");
            },
            set filterType(value) {
                preferencesStorage.setSessionSortingFilteringParam("notes", "filterType", value);
            }
        };
        vm.parameters.searchText = "";
        vm.listDataCache = {};

        // Functions
        vm.init = init;
        vm.reset = reset;
        vm.addNote = addNote;
        vm.addNotes = addNotes;
        vm.deleteNotes = deleteNotes;
        vm.editNote = editNote;
        vm.ensureSourcesLoadedPromise = ensureSourcesLoadedPromise;
        vm.ensureNotesLoadedPromise = ensureNotesLoadedPromise;
        vm.getCardById = getCardById;
        vm.getColorsTree = function () { return _colorsTree; };
        vm.getFilteredNotes = getFilteredNotes;
        vm.getNote = getNote;
        vm.getNotes = function () { return notes; };
        vm.getSources = function () { return sources; };
        vm.getSourcesTree = function () { return _sourcesTree; };
        vm.listDataProvider = listDataProvider;
        vm.loadColorsTree = loadColorsTree;
        vm.loadSourcesTree = loadSourcesTree;
        vm.modifyNote = modifyNote;
        vm.removeSingleNote = removeSingleNote;
        vm.removeNotes = removeNotes;
        vm.updateSourceVisibility = updateSourceVisibility;

        ///////////////////////////////////////

        function init() {
            // For notes, init doesn't really do anything.  We just keep this stuff below to keep the same pattern as the other areas
            // technically it would just be replaced with "isInitialized = true; return $q.when();"

            if (initDefer) return initDefer.promise;
            if (isInitialized)
                return $q.when();
            else {
                initDefer = $q.defer();
                initDefer.promise.finally(function () { initDefer = undefined; });
                isInitialized = true;
                initDefer.resolve();
                return initDefer.promise;
            }
        };

        function reset() {
            isInitialized = false;
            isSourcesLoaded = false;
            isNotesLoaded = false;
            sources = [];
            notes = [];
            vm.listDataCache = {};
        };

        function ensureSourcesLoadedPromise() { return loadSources(); }
        function ensureNotesLoadedPromise() { return loadNotes(); }

        function listDataProvider(obj) {
            //TODO: Manage loading notes dynamically instead of pulling from a full set of data
            var subset = [];
            var j = 0;
            var n = vm.getFilteredNotes();
            for (var i = obj.start; i < (obj.start + obj.take); i++) {
                //Note: splicing will make a copy, I want a reference.
                if (n[i] == undefined) {
                    break;
                }
                subset[j++] = n[i];
            }
            obj.defer.resolve(subset);
            return obj.defer.promise;
        }

        function filterByCategoryCallback(note) {
            const categoryFilters = vm.parameters.categoryFilters;
            if (note.categoriesMetaData && note.categoriesMetaData.some(c => c.selected)) {
                return note.categoriesMetaData.some(cat => cat.selected &&
                    categoryFilters.some(catFilter => catFilter.selected && catFilter.name === cat.name));

            } else {
                return categoryFilters.some(cat => cat.noCategory && cat.selected);
            }

        }

        function applyCategoryFiltering() {
            if (!vm.parameters.hasCategoryFilter) return notes;
            return $.grep(notes, function (note) { return filterByCategoryCallback(note); });
        }

        function filterByColorCallback(note) {
            var temp = $.grep(vm.parameters.colorFilters || [], function (c) { return c.toLowerCase() === note.color.toLowerCase(); });
            if (temp.length > 0)
                return true;
            return false;
        }

        function applyColorsFiltering(filteredNotes) {
            return $.grep(filteredNotes || [], function (note) { return filterByColorCallback(note); });
        }

        function applyNonCategoryFiltering(filteredNotes) {
            if (vm.parameters.filterType === undefined)
                return filteredNotes;

            return $.grep(filteredNotes, function (contact) { return filterCallback(contact); });
        }

        function filterCallback(note) {
            const filter = vm.parameters.filterType;
            if (filter === undefined || filter.length === 0)
                return true;

            if (note.hasAttachments && filter === 1)
                return true;

            return false;
        }

        function getFilteredNotes() {
            var filteredNotes = applyCategoryFiltering();
            filteredNotes = applyColorsFiltering(filteredNotes);
            filteredNotes = applyNonCategoryFiltering(filteredNotes);
            filteredNotes = $filter("filter")(filteredNotes, searchPredicate);
            filteredNotes = $filter("orderBy")(filteredNotes, vm.parameters.sortField, vm.parameters.isDescending);
            return filteredNotes;
        };

        function loadSources() {
            var defer = $q.defer();
            if (isSourcesLoaded) {
                defer.resolve();
            } else {
                getSources()
                    .then(function () {
                        isNoteShared = [];
                        coreDataSettings.resetResources();
                        coreDataSettings.settingsData.resources
                            .then(function (success) {
                                for (var i = 0; i < success.length; i++) {
                                    if (success[i].shareType === 5) {
                                        isNoteShared[success[i].resourceName] = success[i].permissions > 0;
                                    }
                                }
                                defer.resolve();
                            }, function (failure) {
                                defer.resolve();
                            });

                        isSourcesLoaded = true;
                        isNotesLoaded = false;
                    }, function (failure) {
                        isSourcesLoaded = false;
                        isNotesLoaded = false;
                        defer.reject(failure);
                    });
            }
            return defer.promise;
        };

        function getSources() {
            return $http.get("~/api/v1/notes/sources").then(onGetSourcesSuccess, onGetSourcesFailure);
        }

        function onGetSourcesSuccess(result) {
            sources = result.data.sharedLists;

            if (sources[0].displayName.toLowerCase() === "my notes" || sources[0].displayName === "MY_NOTES")
                sources[0].untranslatedName = "MY_NOTES";

            preferencesStorage.cleanSourceVisibilities("notes", sources);
            sources.forEach(src => src.enabled = preferencesStorage.getSourceVisibility("notes", src, true));

            sources.sort(apiNoteSources.defaultNoteSourceComparer);
        }

        function onGetSourcesFailure(result) {
            return result;
        }

        function loadNotes() {
            var defer = $q.defer();
            if (isNotesLoaded) {
                defer.resolve();
            } else {
                notes.length = 0;
                var promises = [];
                angular.forEach(sources, function (source) {
                    if (source.enabled)
                        promises.push(getNotes(source));
                });

                $q.all(promises)
                    .then(function (success) {
                        isNotesLoaded = true;
                        defer.resolve();
                    }, function (failure) {
                        isNotesLoaded = false;
                        defer.reject(failure);
                    });
            }

            return defer.promise;
        };

        async function getNotes(source) {
            let ownerName = source.ownerUsername || "~";
            let success = await $http.get(`~/api/v1/notes/notes/${ownerName}/${source.itemID}/`);

            success.data.notes.forEach((note) => {
                if (!note.text)
                    note.text = "";
                if (!note.subject) {
                    var start = note.text.indexOf(">") + 1;
                    var end = note.text.indexOf("</");
                    end = end - start > 15 ? start + 15 : end;
                    note.subject = note.text.substring(start, end);
                }
                note.dateCreated = moment(note.dateCreated).format();

                processNote(note, source.ownerUsername);
                note.sourceOwner = source.ownerUsername;
                note.sourceId = source.itemID;
                note.sourceName = source.displayName;
                note.sourcePermission = source.access;
                note.isVisible = source.enabled;
                notes.push(note);
            });

            isNotesLoaded = true;
        }

        function getCardById(id) {
            return $.grep(notes, function (note) { return note.id === id });
        };

        function processNote(note, owner) {
            // convertedColor is used for the color bar that appears on cards
            note.convertedColor = (note.color || '').toLowerCase();
            if (note.convertedColor == 'white')
                note.convertedColor = 'var(--notes-color-white)';
            if (note.convertedColor == 'yellow')
                note.convertedColor = 'var(--notes-color-yellow)';
            if (note.convertedColor == 'pink')
                note.convertedColor = 'var(--notes-color-pink)';
            if (note.convertedColor == 'green')
                note.convertedColor = 'var(--notes-color-green)';
            if (note.convertedColor == 'blue')
                note.convertedColor = 'var(--notes-color-blue)';

            note.categories = note.categoriesMetaData || [];
            if (owner) {
                apiCategories.addRgbColorsToCategories(owner, note.categories);
            }
            note.categoriesString = apiCategories.generateNameString(note.categories);
        }

        async function modifyNote(owner, sourceId, id) {
            try {
                var parsedOwner = null;
                if (owner) {
                    var parsedOwner = owner.split('@');
                    parsedOwner = parsedOwner[0] != undefined ? parsedOwner[0] : '';
                }
                var noteToUpdate = $.grep(notes, function (note) { return note.id === id && note.sourceOwner === parsedOwner; });
                if (noteToUpdate.length > 0 && noteToUpdate[0].isVisible) {
                    //if the note already exists.
                    let success = await $http.get(`~/api/v1/notes/note/${id}/${sourceId}/${parsedOwner}/`);
                    var detail = success.data.note;
                    var tempDetail = {};
                    $.extend(tempDetail, detail, { sourceOwner: noteToUpdate[0].sourceOwner, sourceId: noteToUpdate[0].sourceId, sourceName: noteToUpdate[0].sourceName, sourcePermission: noteToUpdate[0].sourcePermission, isVisible: noteToUpdate[0].isVisible });
                    processNote(tempDetail, parsedOwner);
                    var index = notes.indexOf(noteToUpdate[0]);
                    notes[index] = tempDetail;
                } else {
                    //if we are adding a new note.
                    let success = await $http.get(`~/api/v1/notes/note/${id}/${sourceId}/${parsedOwner}/`);
                    var noteSource = $.grep(sources, function (source) { return source.ownerUsername === parsedOwner && source.itemID === sourceId; });
                    if (noteSource[0] != undefined && noteSource[0].enabled) {
                        var detail = success.data.note;
                        var tempDetail = {};
                        $.extend(tempDetail, detail, { sourceOwner: noteSource[0].ownerUsername, sourceId: noteSource[0].itemID, sourceName: noteSource[0].displayName, sourcePermission: noteSource[0].access, isVisible: noteSource[0].enabled });
                        processNote(tempDetail, parsedOwner);
                        notes = $.grep(notes, function (n) { return n.id === tempDetail.id && n.sourceOwner === parsedOwner }, true);
                        notes.push(tempDetail);
                    }
                }

                $rootScope.$broadcast("notesRefresh");
            } catch (err) {
                // Ignoring failures (because that's how the old code worked)
            }
        };

        function deleteNotes(owner, ids) {
            var parsedOwner = null;
            if (owner) {
                var parsedOwner = owner.split('@');
                parsedOwner = parsedOwner[0] != undefined ? parsedOwner[0] : '';
            }
            angular.forEach(ids, function (value, key) {
                notes = $.grep(notes, function (note) { return note.id === value && note.sourceOwner === parsedOwner; }, true);
            });
            $rootScope.$broadcast("notesRefresh");
        };

        async function getNote(id, sourceId, sourceOwner) {
            let success = await $http.get(`~/api/v1/notes/note/${id}/${sourceId}/${sourceOwner}/`);
            var note = success.data.note;
            if (!note.text)
                note.text = "";
            processNote(note, sourceOwner);
            return note;
        };

        async function addNote(note) {
            var sourceOwner = note.sourceOwner;
            note.text = setNoteDefaultFont(note.text);

            vm.ignoreNoteModified.requested = moment();

            var params = JSON.stringify(note);
            let successSave = await $http.post(`~/api/v1/notes/note-put/${note.sourceId}/${sourceOwner}/`, params);

            $.extend(successSave.data.note, successSave.data.note, {
                sourceOwner: sourceOwner,
                sourceId: note.source.itemID,
                sourceName: note.source.displayName,
                sourcePermission: note.source.access,
                isVisible: note.source.enabled
            });
            processNote(successSave.data.note, sourceOwner);

            if (note.source.enabled)
                notes.push(successSave.data.note);

            $rootScope.$broadcast("notesRefresh");
            return successSave.data.note;
        };

        async function addNotes(_notes, source) {
            var defer = $q.defer();
            $.each(_notes, function (index, note) {
                note.text = setNoteDefaultFont(note.text);
            })
            var params = JSON.stringify(_notes);

            vm.ignoreNoteModified.requested = moment();
            let result = await $http.post(`~/api/v1/notes/notes-put/${source.itemID}/${source.ownerUsername}`, params);

            for (var i = 0; i < result.data.notes.length; ++i) {
                $.extend(result.data.notes[i], result.data.notes[i], {
                    sourceOwner: _notes[i].sourceOwner,
                    sourceId: _notes[i].sourceId,
                    sourceName: _notes[i].sourceName,
                    sourcePermission: _notes[i].sourcePermission,
                    isVisible: _notes[i].isVisible
                });
                processNote(result.data.notes[i], _notes[i].sourceOwner);

                if (result.data.notes[i].isVisible)
                    notes.push(result.data.notes[i]);
            }

            $rootScope.$broadcast("notesRefresh");
        };

        async function editNote(note) {
            note.text = setNoteDefaultFont(note.text);
            var params = JSON.stringify(note);
            vm.ignoreNoteModified.requested = moment();

            await $http.post(`~/api/v1/notes/note-patch/${note.id}/${note.sourceId}/${note.sourceOwner}`, params);

            var temp = $.grep(notes, function (n) { return n.id === note.id && n.sourceOwner === note.sourceOwner });
            if (temp.length > 0) {
                var index = notes.indexOf(temp[0]);
                if (index > -1) {
                    note.sourceOwner = notes[index].sourceOwner;
                    note.sourceId = notes[index].sourceId;
                    note.sourceName = notes[index].sourceName;
                    note.sourcePermission = notes[index].sourcePermission;
                    note.isVisible = notes[index].isVisible;
                    processNote(note, note.sourceOwner);
                    notes[index] = note;
                }
            }

            $rootScope.$broadcast("notesRefresh");
        };

        function setNoteDefaultFont(html) {
            try {
                var jHtml = $(html);
                var size = userDataService.user.settings.userMailSettings.composeFontSize;
                var font = userDataService.user.settings.userMailSettings.composeFont;
                if (font == "lucida")
                    font = "lucida sans";
                if (jHtml.length !== 1) {
                    jHtml.wrapAll(`<div style='font-family: ${font}; font-size: ${size};'>`);
                    jHtml = jHtml.parent();
                } else {
                    if (!jHtml.css('font-family')) {
                        jHtml.css('font-family', font);
                    }
                    if (!jHtml.css('font-size')) {
                        jHtml.css('font-size', size);
                    }
                }

                var html = jHtml[0].outerHTML;
                return html;
            } catch (e) {
                return html;
            }
        }

        async function removeSingleNote(owner, folderId, noteId) {
            await $http.post(`~/api/v1/notes/note-delete/${noteId}/${folderId}/${owner}`);
            $rootScope.$broadcast("notesRefresh");
        }

        async function removeNotes(notesToRemove) {
            var defer = $q.defer();
            if (!angular.isArray(notesToRemove))
                throw $filter("translate")("ERROR");

            angular.forEach(notesToRemove, function (value, key) {
                if (!value.id) {
                    var card = vm.getCardById(value);
                    if (card.length > 0) {
                        notesToRemove[key] = card[0];
                    } else {
                        value = { sourceOwner: '', sourceId: '', id: '' };
                    }
                }
            });

            vm.ignoreNoteRemoved.requested = moment();
            var params = JSON.stringify($.map(notesToRemove, (note) => ({ sourceOwner: note.sourceOwner, id: note.id, sourceId: note.sourceId })));
            await $http.post("~/api/v1/notes/delete-bulk", params);

            angular.forEach(notesToRemove, function (note) {
                notes = $.grep(notes, (t) => t.id === note.id && t.sourceId === note.sourceId && t.sourceOwner === note.sourceOwner, true);
            });

            $rootScope.$broadcast("notesRefresh");
        }

        async function updateSourceVisibility(source, newValue) {
            if (typeof newValue !== 'undefined' && newValue !== null)
                source.enabled = newValue;

            preferencesStorage.setSourceVisibility("notes", source);
            sources.forEach(src => src.enabled = preferencesStorage.getSourceVisibility("notes", src, true));
            if (source.enabled)
                await getNotes(source);
            else
                notes = $.grep(notes, function (note) { return note.sourceId === source.itemID && note.sourceOwner === source.ownerUsername }, true);
        }

        function searchPredicate(note) {
            var search = vm.parameters.searchText || "";
            search = search.toLowerCase();
            return (note.subject.toLowerCase().indexOf(search) > -1 ||
                note.text.toLowerCase().indexOf(search) > -1);
        }

        function loadSourcesTree(treeController, forceReload) {
            if (sourcesDefer)
                return sourcesDefer.promise;
            sourcesDefer = $q.defer();

            if ((treeController == null || treeController == undefined) && previousTreeController)
                treeController = previousTreeController;

            if (forceReload) {
                vm.resetSources();
            }

            if (forceReload || _sourcesTree.data.length == 0) {
                loadSourcesTreeData(sourcesDefer, treeController);
                if (treeController)
                    previousTreeController = treeController;
            }
            else {
                var b = _sourcesTree.map[_sourcesTree.selectedBranchData.key];
                if (b != undefined) {
                    if (treeController) {
                        previousTreeController = treeController;
                    }
                    sourcesDefer.resolve(false);
                }
                sourcesDefer.resolve(true);
            }

            sourcesDefer.promise.finally(function () {
                sourcesDefer = null;
            });

            return sourcesDefer.promise;
        };

        function loadSourcesTreeData(defer, treeController) {
            loadSources().then(function () {
                let newData = [];
                let newMap = [];

                $.each(sources,
                    function (i, val) {
                        if (val.ownerUsername == userDataService.user.username)
                            val.isBeingShared = isNoteShared[val.itemID] || false;
                        newData.push(loadSourcesTreeBranch(val, newMap));
                    });

                _sourcesTree.data = newData;
                _sourcesTree.map = newMap;
                defer.resolve();
            });
        };

        function loadSourcesTreeBranch(val, newMap) {
            // Load data for this branch.
            const name = val.isSharedItem ? $filter("folderTranslate")(val.displayName, val.owner) :  $filter("folderTranslate")(val.displayName);
            var branch = {
                label: name,
                data: {
                    "id": val.displayName,
                    "isShared": val.ownerUsername != userDataService.user.username || val.isBeingShared,
                    "isSharedByOther": val.ownerUsername !== userDataService.user.username || val.isDomainResource,
                    "isDomainResource": val.isDomainResource,
                    "showEye": true,
                    "isVisible": val.enabled,
                    "source": val,
                    "isBeingShared": val.isBeingShared,
                    "isPrimary": val.isPrimary
                }
            };
            newMap[branch.data.key] = branch;
            return branch;
        };

        function loadColorsTree(treeController, forceReload) {
            var defer = $q.defer();
            if ((treeController == null || treeController == undefined) && previousTreeController)
                treeController = previousTreeController;

            if (forceReload || _colorsTree.data.length == 0) {
                loadColorsTreeData(defer, treeController);
                if (treeController)
                    previousTreeController = treeController;
            }
            else {
                var b = _colorsTree.map[_colorsTree.selectedBranchData.key];
                if (b != undefined) {
                    if (treeController) {
                        previousTreeController = treeController;
                    }
                    defer.resolve(false);
                }
                defer.resolve(true);
            }
            return defer.promise;
        };

        function loadColorsTreeData(defer, treeController) {
            var colors = [
                { name: 'WHITE', color: '#FFFFFF' },
                { name: 'YELLOW', color: '#FAD122' },
                { name: 'PINK', color: '#FDB2B3' },
                { name: 'GREEN', color: '#68BD45' },
                { name: 'BLUE', color: '#006693' },
            ];
            _colorsTree.data = [];
            _colorsTree.map = [];

            $.each(colors, (i, val) => { _colorsTree.data.push(loadColorsTreeBranch(val, treeController)); });

            defer.resolve();
        };

        function loadColorsTreeBranch(val, treeController) {
            // Load data for this branch.
            var name = $filter("translate")(val.name);

            //if (!vm.parameters.colorFilters) vm.parameters.colorFilters = [];

            var branch = {
                label: name,
                data: {
                    "id": val.name,
                    "isShared": false,
                    "showEye": true,
                    "isVisible": vm.parameters.colorFilters.indexOf(val.color) > -1,
                    "color": val.color, //"#F9DCF7"
                    "disableUnderline": true
                }
            };

            _colorsTree.map[branch.data.key] = branch;
            return branch;
        };


    }

})();