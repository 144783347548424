(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("documentStatusDlgController", documentStatusDlgController);

    function documentStatusDlgController($scope, $mdDialog, $http, fileid) {
        $scope.controller = this;
        $scope.selectedNode = "";
        $scope.title = "Document Status";
        $scope.fileid = fileid; // Ensure fileid is available in the scope

        // Function to close and force save the document
        $scope.done = function () {
            $mdDialog.hide({
                'success': true,
            });

            // Post the file ID to the API on success
            $http.post('~/wopi/ForceCloseDocument', JSON.stringify(fileid), { headers: { 'Content-Type': 'application/json' } })
                .then(function (response) {
                    console.log('File ID posted successfully:', response.data);
                })
                .catch(function (error) {
                    console.error('Error posting file ID:', error);
                });
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        };
        
    }
})();
