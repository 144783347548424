(function () {
	"use strict";

	// Processes Enter Key commands on input controls. 
	
	// Usage Example:
	// <input type="text" st-escape-key="someFunction();" />

	// ReSharper disable once UndeclaredGlobalVariableUsing
	angular
		.module("smartertools")
		.directive("stEscapeKey",stEscapeKeyDirective);

	function stEscapeKeyDirective() {
		return function(scope, element, attrs) {
			element.bind("keyup keypress keydown",
				function(event) { 
					var keyCode = event.which || event.keyCode;

					// If enter key is pressed
					if (keyCode === 27) {
						scope.$apply(function () {
							// Evaluate the expression
							scope.$eval(attrs.stEscapeKey);
						});
						event.preventDefault();
					}
				});
		};
	}

})();