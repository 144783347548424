(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("userCloudStorageConnectController", userCloudStorageConnectController);

	function userCloudStorageConnectController($scope, $mdDialog, $filter, $http, $state, $window, $timeout, modalService, popupService, coreData, errorHandling, showAllOptions) {
		var vm = this;
		$scope.currentType = undefined;
		$scope.returnCode = undefined;
		$scope.displayName = '';
		$scope.isValid = true;
		$scope.isNameValid = true;
		
		$scope.step = 1;

		$scope.accountTypes = [
			{
				translatable: "DROPBOX",
				id: 2,
				imgSrc: "img/product-icons/dropbox_icon.png",
				useOAuth: true,
				defaultDisplayKey: "MY_DROPBOX"
			},
			{
				translatable: "GOOGLE_DRIVE",
				id: 9,
				imgSrc: "img/product-icons/drive_icon.png",
				useOAuth: true,
				defaultDisplayKey: "MY_GOOGLE_DRIVE"
			},
			{
				translatable: "LEITZCLOUD",
				id: 7,
				imgSrc: "img/product-icons/leitzcloud_icon.png",
				useOAuth: true,
				defaultDisplayKey: "MY_LEITZCLOUD"
			},
			{
				translatable: "ONEDRIVE",
				id: 4,
				imgSrc: "img/product-icons/onedrive_icon.png",
				useOAuth: true,
				defaultDisplayKey: "MY_ONEDRIVE_ACCESS"
			},
			{
				translatable: "OWNCLOUD",
				id: 10,
				imgSrc: "img/product-icons/owncloud_icon.png",
				useOAuth: false,
				defaultDisplayKey: "MY_OWNCLOUD"
			},
			{
				translatable: "VBOXX",
				id: 8,
				imgSrc: "img/product-icons/vboxx_icon.png",
				useOAuth: true,
				defaultDisplayKey: "MY_VBOXX"
			}
		];

		// Functions
		vm.modalServiceCallback = modalServiceCallback;
		$scope.save = save;
		$scope.cancel = cancel;
		$scope.openService = openService;
		$scope.showAllOptions = showAllOptions;

		activate();

		///////////////////////

		function activate() {
			modalService.setModalCallback(vm.modalServiceCallback);
			$scope.$watch('displayName', function () {
				if ($scope.returnCode) {
					if ($scope.displayName === '') {
						$scope.isNameValid = false;
					} else {
						$scope.isNameValid = true;
						$scope.isValid = true;
					}
				}
			});
		}

		function modalServiceCallback() {
			$mdDialog.cancel({ reason: "cancel" });
		}

		function save() {
			if ($scope.displayName === '') {
				$scope.isNameValid = false;
				$scope.isValid = false;
				return;
			}

			if ($scope.currentType.useOAuth) {
				let returnArgs = {
					name: $scope.displayName,
					type: $scope.currentType,
					code: $scope.returnCode
				};

				$mdDialog.hide(returnArgs);
				modalService.setModalCallback(null);
			}
			else {
				let params = JSON.stringify({
					url: $scope.serverAddress,
					userName: $scope.userName,
					password: $scope.password
				});

				$http.post("~/api/v1/settings/owncloud-test", params)
					.then(
						function (result) {
							if (!result.data.success) {
								errorHandling.report(result.data.message);
								return;
							}

							let returnArgs = {
								name: $scope.displayName,
								type: $scope.currentType,
								url: $scope.serverAddress,
								userName: $scope.userName,
								password: $scope.password
							};
							
							$mdDialog.hide(returnArgs);
							modalService.setModalCallback(null);
						},
						errorHandling.report);
			}
		}

		function cancel() {
			$mdDialog.cancel({ reason: "cancel" });
			modalService.setModalCallback(null);
		}

		function openService(type) {
			if (type.useOAuth) {
				doOAuth(type);
			}
			else {
				$scope.currentType = type;
				$scope.step = 2
				$scope.displayName = $filter("translate")($scope.currentType.defaultDisplayKey);
			}
		}

		function doOAuth(type) {
			$scope.currentType = undefined;
			$scope.returnCode = undefined;
			var redirect = coreData.baseUrl + "/interface/connect-redirect";
			var params = JSON.stringify({ redirectUri: redirect });

			//The location is set after the post because otherwise some browsers see window.open in async calls as
			// an unwanted popup and block it.
			var newWindow = window.open('', '_blank', popupService.dimensions.connectivity);
			switch (type.id) {
				case 2: // DROPBOX
					$http.post('~/api/v1/settings/dropbox-redirect', params)
						.then(onRedirectApiSuccess, onRedirectApiFailure);
					break;
				case 4: // ONEDRIVEV2
					$http.post("~/api/v1/settings/microsoft-redirect/OneDrive", params)
						.then(onRedirectApiSuccess, onRedirectApiFailure);
					break;
				case 7: // Leitzcloud
					$http.post("~/api/v1/settings/leitzcloud-redirect", params)
						.then(onRedirectApiSuccess, onRedirectApiFailure);
					break;
				case 8: // vBoxx
					$http.post("~/api/v1/settings/vboxx-redirect", params)
						.then(onRedirectApiSuccess, onRedirectApiFailure);
					break;
				case 9: // Google Picker
					params = JSON.stringify({ redirectUri: redirect });
					$http.post("~/api/v1/settings/google-redirect", params)
						.then(onRedirectApiSuccess, onRedirectApiFailure);
					break;
				default:
					newWindow.close();
					break;
			}

			function handleWindowClose(result) {
				$window.handleConnectClose = undefined;
				if (result.code) {
					$scope.returnCode = result.code;
					$scope.step = 2
				}
				//function does not apply scope on its own.
				$scope.$applyAsync();
			}

			function onRedirectApiFailure(failure) {
				errorHandling.report(failure);
				newWindow.close();
			}

			function onRedirectApiSuccess(success) {
				$scope.currentType = type;
				$scope.displayName = $filter("translate")($scope.currentType.defaultDisplayKey);
				$window.handleConnectClose = handleWindowClose;
				newWindow.location = success.data.result;
			}
		}
	}
})();
