(function () {
    "use strict";

    angular
        .module("smartertools")
        .directive("validateFolderNameSlashes", validateFolderNameSlashes);

    function validateFolderNameSlashes() {
        return {
            require: "ngModel",
            link: function (scope, element, attrs, ctrl) {
                ctrl.$validators.invalidFolderCharactersSlashes = function (modelValue, viewValue) {
                    if (!viewValue) {
                        return true;
                    }

                    if (window.stOS == 'L')
                        return validateLinux(viewValue);
                    else
                        return validateWindows(viewValue);
                };
            }
        };

        function validateLinux(viewValue) {
              // Check for invalid characters
            var regex = /[\u0000]/;
            if (regex.test(viewValue))
                return false;

            // Check for invalid upfolder references
            regex = /\.\./g;
            if (regex.test(viewValue))
                return false;
            return true;
      }

        function validateWindows(viewValue) {
            // Disallow starting period
            if (viewValue.startsWith("."))
                return false;

            // Check for invalid characters
            var regex = /[:\*\?\<\>\|\"\u0000]/;
            if (regex.test(viewValue))
                return false;

            // Check for invalid upfolder references
            regex = /\.\./g;
            if (regex.test(viewValue))
                return false;
            return true;
        }
    }
})();