(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminEditMapiCapController", sysAdminEditMapiCapController);

	function sysAdminEditMapiCapController($scope, $mdDialog, $http, errorHandling, coreDataFileStorage) {
		var vm = this;
		$scope.removeMapiCapture = removeMapiCapture;
		$scope.downloadMapiCapture = downloadMapiCapture;
		$scope.monitorCaptures = [];
		$scope.saveInformation = saveInformation;
		$scope.updateInformation = updateInformation;
		$scope.maxRequestBeforeStop = 123;

		updateInformation();

		$scope.cancel = function () {
			$mdDialog.cancel();
		};


		function updateInformation() {
			var vm = $scope;
			$http
				.get("~/api/v1/settings/sysadmin/global-mail")
				.then(onGlobalMailSettingsLoaded, function () { });

			function onGlobalMailSettingsLoaded(result) {
				var settings = angular.copy(result.data.globalMailSettings);

				// Mapi debugging
				vm.maxRequestBeforeStop = settings.mapiSettings.debugMonitorRequestsMax;
				vm.monitorUser = settings.mapiSettings.debugMonitorUser;
				vm.monitorActive = vm.monitorUser.length > 0;
				vm.monitorCaptures = settings.mapiSettings.debugMonitorCaptures;

				//vm.pageForm.$setSubmitted();
			}
		}

		function saveInformation() {
			var vm = $scope;
			var params = {
				globalMailSettings: {
					mapiSettings: {
						debugMonitorRequestsMax: vm.maxRequestBeforeStop,
						debugMonitorUser: (vm.monitorActive ? vm.monitorUser : "")
					}
				}
			};

			$http
				.post("~/api/v1/settings/sysadmin/global-mail/", params)
				.then(function () { onSaveSuccess(); }, errorHandling.report);

			function onSaveSuccess() {

			}
		}

		function removeMapiCapture(ev, filename) {
			var vm = $scope;
			$http
				.get("~/api/v1/debug/delete-mapi-capture/" + filename)
				.then(onSaveSuccess, errorHandling.report);

			function onSaveSuccess() {
				vm.monitorCaptures = vm.monitorCaptures.filter(function (item) {
					return item !== filename;
				});
			}
		}

		function downloadMapiCapture(ev, filename) {
			var vm = $scope;
			var httpPath = "~/api/v1/debug/download-mapi-capture/" + filename;

			coreDataFileStorage.downloadFile(httpPath, filename)
				.then(function () { }, errorHandling.report);
		}




	}

})();
