(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminEditBlacklistingController", sysAdminEditBlacklistingController);

	function sysAdminEditBlacklistingController($scope, $mdDialog, $translate, $timeout, addresses, smtp, pop, imap, xmpp, ldap, web_mail, web_dav, active_sync, mapi_ews, description, fromGrid, changed, readOnly) {

		var vm = this;

		$scope.addresses = addresses;
		$scope.smtp = smtp;
		$scope.pop = pop;
		$scope.imap = imap;
		$scope.xmpp = xmpp;
		$scope.ldap = ldap;
		$scope.active_sync = active_sync;
		$scope.web_dav = web_dav;
		$scope.web_mail = web_mail;
		$scope.mapi_ews = mapi_ews;
		$scope.description = description;
		$scope.fromGrid = fromGrid;
		$scope.isValid = true;
		$scope.isSwitchSelected = true;
		$scope.readOnly = readOnly;
		$scope.description = $translate.instant($scope.description);
		$scope.changed = changed;
		$scope.changedText = $translate.instant("DATE_MODIFIED_WITH_DATE", { date: $scope.changed });

		$scope.hideChanged = hideChanged;

		$timeout(function () { vm.userForm.$setDirty(); }, 100);

		$scope.save = function (scope) {
			if (!($scope.isValid = scope.userForm.$valid && validateSwitches())) {
				return;
			}

			$mdDialog.hide({
				address: $scope.addresses,
				smtp: $scope.smtp,
				pop: $scope.pop,
				imap: $scope.imap,
				xmpp: $scope.xmpp,
				ldap: $scope.ldap,
				mapi_ews: $scope.mapi_ews,
				web_dav: $scope.web_dav,
				web_mail: $scope.web_mail,
				active_sync: $scope.active_sync,
				description: $scope.description
			});
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};

		function validateSwitches() {
			$scope.isSwitchSelected = $scope.smtp || $scope.pop || $scope.imap || $scope.xmpp || $scope.web_dav || $scope.web_mail || $scope.ldap || $scope.mapi_ews || $scope.active_sync;
			return $scope.isSwitchSelected;
		}


		function hideChanged() {
			if (!$scope.changed)
				return true;
			else
				return false;
		}
	}

})();
