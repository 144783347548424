(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("domainSettingsFilteringSpamController", domainSettingsFilteringSpamController);

	function domainSettingsFilteringSpamController($rootScope, $http, $q, $mdDialog,
		errorHandling, coreDataSettings, coreDataDomainSettings) {

		var vm = this;
		vm.overrideActive = false;
		vm.defaultSettings = {};
		vm.domainSettings = {};
		vm.activeSettings = vm.domainSettings;
		vm.trustedDomains = [];
		vm.trustedEmails = [];
		vm.domainSettings = {};
		vm.showSpamMenu = false;
		vm.spamCheckOverrideActive = false;

		// functions
		vm.reloadState = reloadState;
		vm.save = save;
		vm.switchOverride = switchOverride;
		vm.editItem = editItem;
		vm.openTrustedDomains = openTrustedDomains;
		vm.openTrustedEmails = openTrustedEmails;

		activate();

		////////////////////

		function activate() {
			coreDataDomainSettings
				.init()
				.then(onInit, errorHandling.report);

			function onInit() {
				$q
					.all([
						$http.get("~/api/v1/settings/domain/spam-settings"),
						$http.get("~/api/v1/settings/domain/domain")
					])
					.then(function (success) {
						vm.domainSettings = success[0].data.domainSettings;
						vm.defaultSettings = success[0].data.defaultSettings;
						vm.allowOverride = coreDataDomainSettings.domainPermissions.spamCheckAllowDomainOverride;
						switchOverride(false);
						onSettingsLoaded(success[1]);
					}, errorHandling.report);
			}

			function onSettingsLoaded(result) {
				var data = result.data.domainSettings;
				vm.showSpamMenu = data.showSpamMenu || false;
				vm.spamCheckOverrideActive = data.spamCheckOverrideActive || false;

				vm.trustedDomains = [];
				angular.forEach(data.whitelistDomains || [], function (value) {
					vm.trustedDomains.push(value.value);
				});

				vm.trustedEmails = [];
				angular.forEach(data.whitelistAddresses || [], function (value) {
					vm.trustedEmails.push(value.value);
				});
			}
		}

		function reloadState(form) {
			activate();
			form.$setPristine();
		}

		function switchOverride(copySystemActions) {
			if (copySystemActions) {
				if (vm.domainSettings.overrideActive === true) {
					vm.domainSettings = angular.copy(vm.defaultSettings);
				}
			}
			vm.activeSettings = vm.allowOverride && vm.domainSettings.overrideActive ? vm.domainSettings : vm.defaultSettings;
		}

		function save(event, form) {
			if (!form.$valid) {
				errorHandling.report("CORRECT_INVALID_FIELDS");
				return;
			}

			if (!vm.allowOverride) {
				continueSaving2();
				return;
			}

			var params = JSON.stringify({
				domainSettings: {
					spamCheckOverrideActive: vm.spamCheckOverrideActive,
					showSpamMenu: vm.showSpamMenu,
					spamLevelLowAction: vm.activeSettings.lowAction,
					spamLevelMedAction: vm.activeSettings.medAction,
					spamLevelHighAction: vm.activeSettings.highAction,
					spamLevelLowWeight: vm.activeSettings.lowWeight,
					spamLevelMedWeight: vm.activeSettings.medWeight,
					spamLevelHighWeight: vm.activeSettings.highWeight
				}
			});
			$http
				.post("~/api/v1/settings/domain/domain", params)
				.then(continueSaving1, errorHandling.report);

			function continueSaving1() {
				coreDataSettings.userPermissions.spamCheckOverrideActive = vm.allowOverride && vm.showSpamMenu;
				continueSaving2();
			}

			function continueSaving2() {
				var params = JSON.stringify({ domains: vm.trustedDomains, emails: vm.trustedEmails });
				$http
					.post("~/api/v1/settings/domain/whitelist", params)
					.then(onSaveSuccess, errorHandling.report);
			}

			function onSaveSuccess() {
				form.$setPristine();
				$rootScope.$broadcast("domain-settings:changed");
			}
		}

		function openTrustedDomains(ev, form) {
			openTrustedSenders(ev, form, false);
		}

		function openTrustedEmails(ev, form) {
			openTrustedSenders(ev, form, true);
		}

		function openTrustedSenders(ev, form, storeInEmails) {
			$mdDialog
				.show({
					locals: { trustedSenders: storeInEmails ? vm.trustedEmails : vm.trustedDomains, isAddresses: storeInEmails },
					controller: "trustedSenderDialogController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/shared/trusted-senders.dlg.html",
					targetEvent: ev
				})
				.then(onAdditionalInfoOk, function () { });

			function onAdditionalInfoOk(modalSuccess) {
				var filteredItems = $.grep(modalSuccess, function (value) {
					var trimmed = (value || "").trim();
					return trimmed;
				});
				if (storeInEmails)
					vm.trustedEmails = filteredItems;
				else
					vm.trustedDomains = filteredItems;
				form.$setDirty();
			}
		}

		function editItem(event, form, item) {
			if (!vm.spamCheckOverrideActive)
				return;

			var title;
			if (item === vm.activeSettings.lowAction)
				title = "SPAM_FILTERING_LOW_PROBABILITY";
			else if (item === vm.activeSettings.medAction)
				title = "SPAM_FILTERING_MED_PROBABILITY";
			else // item === vm.activeSettings.highAction
				title = "SPAM_FILTERING_HIGH_PROBABILITY";

			if (!item) item = {};

			$mdDialog.show({
				locals: {
					title: title,
					actionType: item.actionType,
					argument: item.argument,
					weight: null,
					hideMoveToJunk: false
				},
				controller: "editSpamFilterActionDialogController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/shared/spam-filter-action.dlg.html",
				targetEvent: event
			})
				.then(function (modalSuccess) {
					item.actionType = modalSuccess.actionType;
					item.argument = modalSuccess.argument;
					if (item === vm.activeSettings.lowAction && modalSuccess.weight)
						vm.activeSettings.lowWeight = modalSuccess.weight;
					else if (item === vm.activeSettings.medAction && modalSuccess.weight)
						vm.activeSettings.medWeight = modalSuccess.weight;
					else if (modalSuccess.weight)
						vm.activeSettings.highWeight = modalSuccess.weight;
					form.$setDirty();
				}, function () {
					// Do nothing
				});
		}
	}

})();
