(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sharedMailingListSettingsController", sharedMailingListSettingsController);

	function sharedMailingListSettingsController($state, $rootScope, $scope, $http, $timeout, $filter, $mdDialog, $q, $translate,
		errorHandling, successHandling, coreDataDomainSettings, coreDataSettings, claimsService, coreLicensing) {

		var vm = this;
		vm.canManageMailingLists = claimsService.isSysAdmin() || claimsService.isDomainAdmin();
		vm.isEnterprise = false;

		vm.domainSpamSettings = {}
		vm.data = {};
		vm.mailingListId = $state.params.mailingListId;
		vm.listFromOption = "LISTADDRESS";
		vm.listFromAddress = "";
		vm.listReplyToOption = "DONOTSET";
		vm.listReplyToAddress = "";
		vm.originalDoubleOpt = false;
		vm.allowAnyoneToPost = false;
		vm.allowSubscribersToPost = false;
		vm.spamSettings = null;

		// Functions
		vm.save = save;
		vm.openDescriptionModal = openDescriptionModal;
		vm.deleteThis = deleteThis;
		vm.sendDigestNow = sendDigestNow;
		vm.queryUsers = queryUsers;
		vm.editSpamItem = editSpamItem;

		activate();

		/////////////////////////////

		function activate() {
			recalculateLicense();
			coreLicensing.watchForChanges($scope, recalculateLicense);
			loadMailingList();
		}

		function recalculateLicense() {
			vm.isEnterprise = coreLicensing.hasEnterpriseFunctionality;
		}



		function loadMailingList() {
			const promises = [
				$http.get(`~/api/v1/settings/domain/mailing-lists/${vm.mailingListId}/settings`),
				$http.get("~/api/v1/settings/domain/spam-settings"),
				coreDataDomainSettings.init()
			];

			$q.all(promises)
				.then(onPromisesReady, onPromisesFailure);

			function onPromisesReady(success) {
				vm.data = success[0].data.item;
				vm.listFromOption = vm.data.listFromAddress;
				if (vm.listFromOption !== "LISTADDRESS" && vm.listFromOption !== "POSTERADDRESS") {
					vm.listFromAddress = vm.data.listFromAddress;
					vm.listFromOption = "SPECIFICADDRESS";
				}
				vm.listReplyToOption = vm.data.listReplyToAddress;
				if (vm.listReplyToOption !== "LISTADDRESS" &&
					vm.listReplyToOption !== "POSTERADDRESS" &&
					vm.listReplyToOption !== "DONOTSET") {
					vm.listReplyToAddress = vm.data.listReplyToAddress;
					vm.listReplyToOption = "SPECIFICADDRESS";
				}
				vm.allowAnyoneToPost = vm.data.postingPermissions === 0;
				vm.allowSubscribersToPost = vm.data.postingPermissions <= 1;

				const overridingSystem = coreDataDomainSettings.domainPermissions.spamCheckAllowDomainOverride && success[1].data.domainSettings.overrideActive;
				vm.domainSpamSettings = {
					highAction: overridingSystem ? success[1].data.domainSettings.highAction : success[1].data.defaultSettings.highAction,
					highWeight: success[1].data.defaultSettings.highWeight,
					lowAction: overridingSystem ? success[1].data.domainSettings.lowAction : success[1].data.defaultSettings.lowAction,
					lowWeight: success[1].data.defaultSettings.lowWeight,
					medAction: overridingSystem ? success[1].data.domainSettings.medAction : success[1].data.defaultSettings.medAction,
					medWeight: success[1].data.defaultSettings.medWeight,
				}
				
				// Convert Move To Junk actions to Delete actions, since mailing lists don't have a junk folder
				if (vm.domainSpamSettings.highAction.actionType === 4)
					vm.domainSpamSettings.highAction.actionType = 1;
				if (vm.domainSpamSettings.lowAction.actionType === 4)
					vm.domainSpamSettings.lowAction.actionType = 1;
				if (vm.domainSpamSettings.medAction.actionType === 4)
					vm.domainSpamSettings.medAction.actionType = 1;
				if (vm.data.enableSpamFiltering) {
					vm.spamSettings = {
						highAction: ConvertSpamAction(vm.data.spamHighAction) ?? vm.domainSpamSettings.highAction,
						lowAction: ConvertSpamAction(vm.data.spamLowAction) ?? vm.domainSpamSettings.lowAction,
						medAction: ConvertSpamAction(vm.data.spamMedAction) ?? vm.domainSpamSettings.medAction,
					};
				}
				else {
					vm.spamSettings = {
						highAction: vm.domainSpamSettings.highAction,
						lowAction: vm.domainSpamSettings.lowAction,
						medAction: vm.domainSpamSettings.medAction,
					};
				}

				vm.originalDoubleOpt = vm.data.doubleOptIn;
				vm.listAddress = vm.data.listAddress + "@" + ($rootScope.secondaryImpersonationDomain || coreDataDomainSettings.domain);
				vm.listCommandAddress = vm.data.listCommandAddress;

				if (vm.data.enableDigest && (!vm.data.digestSubject || vm.data.digestSubject.length === 0)) {
					vm.data.digestSubject = $translate.instant("DIGEST");
					vm.pageForm.$setDirty();
				}
				else
					$timeout(pristineForm, 200, false);

				$scope.$watch(function () { return vm.allowAnyoneToPost; }, function (newValue, oldValue) {
					if (newValue === oldValue) return;
					if (newValue === true)
						vm.allowSubscribersToPost = true;
				});

				function ConvertSpamAction(action) {
					if (!action)
						return action;
					return !action
						? null
						: {
							actionType: action.action_type,
							argument: action.argument,
							boolOption1: action.boolOption1,
							boolOption2: action.boolOption2,
							isDefaultAction: action.isDefaultAction,
						}
				}
			}

			function onPromisesFailure(failure) {
				if (!vm.canManageMailingLists) {
					const isListModerator = coreDataSettings.userPermissions.isListModerator;
					if (isListModerator)
						$state.go("index.settings.mailing-lists");
					else
						$state.go("index.settings.user-account-settings");
					return;
				}
				errorHandling.report(failure);
			}
		}

		function pristineForm() {
			if (!vm.pageForm) {
				$timeout(pristineForm, 20, false);
				return;
			}
			vm.pageForm.$setPristine();
		}

		function save(event, form) {
			if (!form.$valid)
				return;

			vm.data.listFromAddress = vm.listFromOption === "SPECIFICADDRESS"
				? vm.listFromAddress
				: vm.listFromOption;

			vm.data.listReplyToAddress = vm.listReplyToOption === "SPECIFICADDRESS"
				? vm.listReplyToAddress
				: vm.listReplyToOption;

			if (!vm.allowSubscribersToPost && !vm.allowAnyoneToPost)
				vm.data.postingPermissions = 2;
			else
				vm.data.postingPermissions = !vm.allowAnyoneToPost ? 1 : 0;

			const params = {
				listAddress: vm.data.listAddress,
				moderatorAddress: vm.data.moderatorAddress,
				password: vm.data.password,
				subject: vm.data.subject,
				listToAddress: vm.data.listToAddress,
				listFromAddress: vm.data.listFromAddress,
				listReplyToAddress: vm.data.listReplyToAddress,
				unsubscribeText: vm.data.unsubscribeText,
				disabled: vm.data.disabled,
				description: vm.data.description,
				enableDigest: vm.data.enableDigest,
				sendSubscribeEmail: vm.data.sendSubscribeEmail,
				sendUnsubscribeEmail: vm.data.sendUnsubscribeEmail,
				disableSubscriptions: vm.data.disableSubscriptions,
				doubleOptIn: vm.data.doubleOptIn,
				disableListErrorReplies: vm.data.disableListErrorReplies,
				digestSubject: vm.data.digestSubject,
				digestSendTriggerType: vm.data.digestSendTriggerType,
				digestSendTriggerSize: vm.data.digestSendTriggerSize,
				digestSendType: vm.data.digestSendType,
				digestStripNonTextAttachments: vm.data.digestStripNonTextAttachments,
				maxMessagesSentPerHour: vm.data.maxMessagesSentPerHour,
				messagesAction: vm.data.messagesAction,
				maxSmtpOutBandwidthPerHour: vm.data.maxSmtpOutBandwidthPerHour,
				bandwidthAction: vm.data.bandwidthAction,
				baseUrl: vm.data.baseUrl,
				showInGal: vm.data.showInGal,
				postingPermissions: vm.data.postingPermissions,
				allowAnyoneToPost: vm.data.allowAnyoneToPost,
				allowSubscribersToPost: vm.data.allowSubscribersToPost,
				enableSpamFiltering: vm.data.enableSpamFiltering,
				spamHighAction: ConvertSpamAction(vm.spamSettings.highAction),
				spamLowAction: ConvertSpamAction(vm.spamSettings.lowAction),
				spamMedAction: ConvertSpamAction(vm.spamSettings.medAction)
			};

			$http
				.post(`~/api/v1/settings/domain/mailing-lists/${vm.mailingListId}/settings`, JSON.stringify(params))
				.then(function () {
					form.$setPristine();
					$rootScope.$broadcast("domain-settings:changed");
					if (vm.originalDoubleOpt && !vm.data.doubleOptIn)
						$rootScope.$broadcast("mailing-list:counts-changed");
					vm.originalDoubleOpt = vm.data.doubleOptIn;
				}, errorHandling.report);

			function ConvertSpamAction(action) {
				return {
					action_type: action.actionType,
					argument: action.argument,
					boolOption1: action.boolOption1 ?? false,
					boolOption2: action.boolOption2 ?? false,
					isDefaultAction: action.isDefaultAction,
				};
			}
		}

		function openDescriptionModal(ev) {
			$mdDialog
				.show({
					locals: {
						name: "DESCRIPTION",
						text: vm.data.description
					},
					controller: "domainEditTextController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/shared/domain-settings-edit-text.dlg.html",
					targetEvent: ev
				})
				.then(function(modalSuccess) {
						vm.data.description = modalSuccess;
						vm.pageForm.$setDirty();
					}, function() {});
		}

		function deleteThis(event) {
			const confirm = $mdDialog.confirmDeletion()
				.textContent($filter("translate")("CONFIRMATIONS_DELETE_MAILING_LIST"))
				.targetEvent(event);
			$mdDialog.show(confirm).then(doDelete, function() {});

			function doDelete() {
				$http
					.post(`~/api/v1/settings/domain/mailing-lists/${vm.mailingListId}/delete`)
					.then(function () { vm.skipNavigateConfirm = true; $state.go("^"); }, errorHandling.report);
			}
		}

		function editSpamItem(event, form, item) {
			if (!vm.data.enableSpamFiltering)
				return;

			var title;
			if (item === vm.spamSettings.lowAction)
				title = "SPAM_FILTERING_LOW_PROBABILITY";
			else if (item === vm.spamSettings.medAction)
				title = "SPAM_FILTERING_MED_PROBABILITY";
			else // item === vm.spamSettings.highAction
				title = "SPAM_FILTERING_HIGH_PROBABILITY";

			if (!item) item = {};

			$mdDialog.show({
				locals: {
					title: title,
					actionType: item.actionType,
					argument: item.argument,
					weight: null,
					hideMoveToJunk: true,
				},
				controller: "editSpamFilterActionDialogController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/shared/spam-filter-action.dlg.html",
				targetEvent: event
			})
				.then(function (modalSuccess) {
					item.actionType = modalSuccess.actionType;
					item.argument = modalSuccess.argument;
					if (item === vm.spamSettings.lowAction && modalSuccess.weight)
						vm.spamSettings.lowWeight = modalSuccess.weight;
					else if (item === vm.spamSettings.medAction && modalSuccess.weight)
						vm.spamSettings.medWeight = modalSuccess.weight;
					else if (modalSuccess.weight)
						vm.spamSettings.highAction = modalSuccess.weight;
					form.$setDirty();
				}, function () {
					// Do nothing
				});
		}

		function sendDigestNow(event) {
			const confirm = $mdDialog.confirm()
				.title($filter("translate")("SEND_DIGEST"))
				.ok($filter("translate")("SEND"))
				.cancel($filter("translate")("CANCEL"))
				.textContent($filter("translate")("MAILING_LISTS_SEND_DIGEST_CONFIRMATION"))
				.targetEvent(event);
			$mdDialog.show(confirm).then(doSend, function() {});

			function doSend() {
				$http
					.post(`~/api/v1/settings/domain/mailing-lists/${vm.mailingListId}/send-digest`)
					.then(successHandling.report, errorHandling.report);
			}
		}

		function queryUsers(searchText, includeAliases) {
			if (searchText.length > 0) {
				return $http
					.get(`~/api/v1/settings/domain/list-usernames/?usersToReturn=50&includeDomain=true&includeDomainAliases=${includeAliases}&search=${searchText}`)
					.then(function (result) { return result.data.usernames; }, errorHandling.report)
					.catch(function () { return []; });
			}

			return $q.when([]);
		}
	}

})();
