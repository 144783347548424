(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("createAddressBookController", createAddressBookController);

    function createAddressBookController($scope, $mdDialog, $http, errorHandling) {

        var vm = this;
        vm.name = "";
        vm.id = null;

        // Functions
        vm.cancel = cancel;
        vm.save = save;

        // Setup
        activate();

        /////////////////////////////////

        function activate() {
        }

        function cancel() {
            $mdDialog.cancel();
        }

        async function save() {
            if (vm.name === "")
                return;

            try {
                var folder = { folder: vm.name };
                let success = await $http.post('~/api/v1/contacts/address-book/add', folder);
                vm.id = success.data.folderGuid;
                $mdDialog.hide();
            }
            catch (failure) {
                errorHandling.report(failure);
            }
        }
    }
})();
