(function () {
    'use strict'

    angular
        .module('smartertools')
        .directive('validatePathConversion', validatePathConversion);

    function validatePathConversion($http, $q) {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ctrl) {
                ctrl.$asyncValidators.validatePathConversion = function (modelValue, viewValue) {
                    
                    if (!viewValue || ctrl.$pristine) return $q.when(true);
                    var defer = $q.defer();
                    
                    var parentOrig = "";
                    var childPaths = [];
                    var system = ctrl.$$scope.$parent.cStatus.showGroupedSystemPaths();
                    var certs = ctrl.$$scope.$parent.cStatus.showGroupedBindingsPaths();
                    var domain = ctrl.$$scope.$parent.cStatus.showGroupedDomainPaths();

                    var params = {};
                    if (system) {
                        parentOrig = ctrl.$$scope.$parent.cStatus.groupedSystemSettingsPaths.parentPathOld;
                        ctrl.$$scope.$parent.cStatus.settingsPathsOrigNoDups.forEach(function (path) {
                            childPaths.push(path.origPath);
                        });
                        params = {
                            parentPathOrig: parentOrig,
                            parentPathUpdate: viewValue,
                            childPaths: childPaths,
                            isCertCheck: false,
                        };
                    }
                    else if (certs) {
                        parentOrig = ctrl.$$scope.$parent.cStatus.groupedBindingCertificatesPaths.parentPathOld;
                        ctrl.$$scope.$parent.cStatus.bindingsPathsOrigNoDups.forEach(function (path) {
                            childPaths.push(path.origPath);
                        });
                        
                        params = {
                            parentPathOrig: parentOrig,
                            parentPathUpdate: viewValue,
                            childPaths: childPaths,
                            isCertCheck: true,
                        };
                    }
                    else if (domain) {
                        parentOrig = ctrl.$$scope.$parent.cStatus.groupedDomainPaths.parentPathOld;
                        ctrl.$$scope.$parent.cStatus.domainPathsNoDups.forEach(function (path) {
                            childPaths.push(path.origPath);
                        });
                        params = {
                            parentPathOrig: parentOrig,
                            parentPathUpdate: viewValue,
                            childPaths: childPaths,
                            isCertCheck: false,
                        };
                    }

                    $http.post('~/api/v1/conversion/os-path-conversion-validation/' + ctrl.$$scope.$parent.cStatus.accessKey, JSON.stringify(params))
                        .then(function (res) {
                            if (res.data.success) {
                                if (system) {
                                    ctrl.$$scope.$parent.cStatus.settingsPathsOrigNoDups.forEach(function (pathData) {
                                        var itemFound = ctrl.$$scope.$parent.cStatus.searchValidationResult(res.data.pathData, pathData.origPath);
                                        if (itemFound !== undefined) {
                                            pathData.pathNotExist = itemFound.pathNotExist;
                                            pathData.pathNoPermissions = itemFound.pathNoPermissions;
                                        }
                                    });
                                } else if (certs) {
                                    ctrl.$$scope.$parent.cStatus.bindingsPathsOrigNoDups.forEach(function (pathData) {
                                        var itemFound = ctrl.$$scope.$parent.cStatus.searchValidationResult(res.data.pathData, pathData.origPath);
                                        if (itemFound !== undefined) {
                                            pathData.pathNotExist = itemFound.pathNotExist;
                                            pathData.pathNoPermissions = itemFound.pathNoPermissions;
                                        }
                                    });
                                } else if (domain) {
                                    ctrl.$$scope.$parent.cStatus.domainPathsNoDups.forEach(function (pathData) {
                                        var itemFound = ctrl.$$scope.$parent.cStatus.searchValidationResult(res.data.pathData, pathData.origPath);
                                        if (itemFound !== undefined) {
                                            pathData.pathNotExist = itemFound.pathNotExist;
                                            pathData.pathNoPermissions = itemFound.pathNoPermissions;
                                        }
                                    });
                                }

                                defer.resolve();
                            } else if (res.data.success === false) {
                                defer.reject(res.data.message);
                            }
                        }, function (failure) {
                            defer.reject(failure);
                        });

                    return defer.promise;
                };
            }
        };
    }
})();