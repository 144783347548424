(function () {
    "use strict";

    angular
        .module('smartermail')
        .component('messageRecipientAutocomplete', {
            templateUrl: "app/email/compose/recipient-autocomplete.component.html",
            controller: messageRecipientAutocomplete,
            bindings: {
                recipientList: '=',
                onRecipientListChanged: '&',
                autocompleteData: '=',
                autocompleteId: '<',
                autocompleteInputId: '<',
               // autocompleteQuery: '=',
                contacts: '=',
            }
        });

    /* @ngInject */
    function messageRecipientAutocomplete($rootScope, $scope, $http, $mdConstant, userDataService, errorHandling, emailValidationService) {
        // To explain the -59 for semicolon. Angular material by default for chips checks if the keycode matches one of these in the keyDown method. problem is on foreign keyboards the keycode for
        // semicolon can be a completly different character. So we modifed it to also check keyPress which gives a char code which is 59 for semicolon. ands we use the - and check only against
        // negatives in this keyPress function and let keyDown handle the normal keys.
        const specialSemicolon = -59;


        // Properties ------------------------------------------
        var vm = this;
        vm.addressSeparatorKeys = [$mdConstant.KEY_CODE.ENTER, $mdConstant.KEY_CODE.TAB, $mdConstant.KEY_CODE.COMMA, specialSemicolon];

        // Functions
        vm.doAutocompleteQuery = doAutocompleteQuery;
        vm.autocompleteTransformChip = autocompleteTransformChip;
        vm.autocompleteRemoveChip = autocompleteRemoveChip;
        vm.autocompleteOnChipRemoved = autocompleteOnChipRemoved;
        vm.autocompleteQueryAll = autocompleteQueryAll;
        vm.autocompleteQuerySearch = autocompleteQuerySearch;

        /////////////////////////////////////

        function doAutocompleteQuery() {
            if (vm.contacts.length > 0)
                return vm.autocompleteQueryAll(vm.autocompleteData.searchString, vm.recipientList);
            else
                return vm.autocompleteQuerySearch(vm.autocompleteData.searchString, vm.recipientList);
        }

        async function autocompleteQueryAll(query, recipientList) {
            // If there are multiple addresses dont search
            if (query.indexOf(';') > -1 || query.indexOf(',') > -1) {
                return null;
            }

            var results = query ? vm.contacts.filter(createFilterFor(query)) : vm.contacts;
            var subset = {};
            var regex = /^mailto:/gi;

            angular.forEach(results, function (value) {
                value.emailAddress = value.emailAddress.replace(regex, '');

                // Look for entries that are already in the chips
                for (var i = 0; i < recipientList.length; ++i) {
                    var v = recipientList[i];
                    if (v.formatted === value.formatted)
                        return;
                }

                var existing = subset[value.formatted];
                if (!existing) {
                    subset[value.formatted] = value;
                    return;
                }

                // If the contact is already in chips, see if this one should take it's place
                if (value.source === 'CONTACT' && existing.source !== 'CONTACT') {
                    // Contact list gets priority
                    subset[value.formatted] = value;
                } else if (value.source === 'GAL' && existing.source === "SENT_ITEM") {
                    // GAL gets next priority
                    subset[value.formatted] = value;
                } else {
                    // Sent items can only fill in display name if one isn't set
                    if (existing.emailAddress === existing.displayAs && value.displayAs && value.displayAs !== value.emailAddress) {
                        existing.displayAs = value.displayAs;
                        existing.formatted = `"${existing.displayAs}" <${existing.emailAddress}>`;
                    }
                }
            });

            var newResults = [];
            angular.forEach(subset, function (value) { newResults.push(value); });
            return newResults;

            function createFilterFor(query) {
                const lowercaseQuery = query.toLowerCase();
                return function filterFn(contact) {
                    const lowercaseContactEmail = contact.emailAddress.toLowerCase();
                    const lowercaseContactName = contact.displayAs.toLowerCase();
                    return (lowercaseContactEmail && lowercaseContactEmail.indexOf(lowercaseQuery) >= 0) ||
                        (lowercaseContactName && lowercaseContactName.indexOf(lowercaseQuery) >= 0);
                };
            }

        }

        async function autocompleteQuerySearch(query, recipientList) {
            // If there are multiple addresses dont search
            if (query.indexOf(';') > -1 || query.indexOf(',') > -1) {
                return null;
            }
            
            var params = {
                search: query,
                requestLimit: 200,
            };
            var newResults = [];
            await $http
                .post("~/api/v1/settings/auto-complete-list-search/", params)
                .then(function (success) {
                    var results = success.data.emailData;
                    var subset = {};
                    var regex = /^[mM][aA][iI][lL][tT][oO]:/g;
                    angular.forEach(results || [], function (value) {
                        value.emailAddress = value.emailAddress.replace(regex, "");
                        
                        // Look for entries that are already in the chips
                        for (var i = 0; i < recipientList.length; ++i) {
                            var v = recipientList[i];
                            if (v.formatted === value.formatted)
                                return;
                        }

                        var existing = subset[value.formatted];
                        if (!existing) {
                            subset[value.formatted] = value;
                            return;
                        }

                        if (value.source === 'CONTACT' && existing.source !== 'CONTACT') {
                            // Contact list gets priority
                            subset[value.formatted] = value;
                        } else if (value.source === 'GAL' && existing.source === "SENT_ITEM") {
                            // GAL gets next priority
                            subset[value.formatted] = value;
                        } else {
                            // Sent items can only fill in display name if one isn't set
                            if (existing.emailAddress === existing.displayAs && value.displayAs && value.displayAs !== value.emailAddress) {
                                existing.displayAs = value.displayAs;
                                existing.formatted = `"${existing.displayAs}" <${existing.emailAddress}>`;
                            }
                        }
                    });

                    //var newResults = [];
                    angular.forEach(subset, function (value) { newResults.push(value); });
                }, function () { });

            return newResults;
        }

        function autocompleteTransformChip(chip) {
            try {
                let list = vm.recipientList;

                if (!chip.source || chip.source.toUpperCase() !== "CONTACT_GROUP") {
                    if (!chip.emailAddress) {
                        var delims = new RegExp(',|;');
                        var addresses = chip.split(delims);
                        for (var i = addresses.length - 1; i > -1; --i) {
                            addresses[i] = addresses[i].trim();
                            var email = parseEmail(addresses[i]);
                            if (!emailValidationService.isValidEmail(email)) {
                                addresses.splice(i, 1);
                            } else if (email) {
                                var current = list;
                                var contains = false;
                                for (var j = 0; j < current.length; ++j) {
                                    if (current[j].emailAddress === email) {
                                        contains = true;
                                        break;
                                    }
                                }
                                if (!contains) {
                                    list.push({ emailAddress: email, formatted: email, type: 'new' });
                                }
                            }
                        }
                    }
                    else if (!emailValidationService.isValidEmail(chip.emailAddress))
                        return null;
                }

                // If it is an object, it's already a known chip
                return angular.isObject(chip) ? chip : null;
            } finally {
                vm.onRecipientListChanged();
            }
        }

        async function autocompleteRemoveChip(item, ev, scope) {
            ev.preventDefault();
            ev.stopPropagation();

            try {
                var params = JSON.stringify({
                    username: userDataService.user.username,
                    idsToDelete: [item.id]
                });

                await $http.post("~/api/v1/settings/auto-complete-delete", params);
                if (vm.contacts.length > 0)
                    vm.contacts = $.grep(vm.contacts, (c) => c.id !== item.id);

                // Autocomplete dropdown must be manually updated
                const autoCompleteCtrl = scope.$parent.$mdChipsCtrl.autocompleteCtrl.scope.$mdAutocompleteCtrl;
                const newMatchList = $.grep(autoCompleteCtrl.matches, (c) => c.id !== item.id);
                $scope.$applyAsync(function () {
                    autoCompleteCtrl.matches = newMatchList;
                });

            } catch (err) {
                errorHandling.report(err);
            }

            return false;
        }

        function autocompleteOnChipRemoved() {
            vm.onRecipientListChanged();
        }

        function parseEmail(email) {
            email = email.trim();
            if (!email.contains('<')) return email;

            return email.substring(email.indexOf("<") + 1, email.indexOf(">")).trim();
        }

    }

})();
