(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("userRemoteWipeConfirmController", userRemoteWipeConfirmController);

	function userRemoteWipeConfirmController($scope, $mdDialog, canAccountOnlyRemoteWipe) {
		$scope.isPassValid = true;
		$scope.isValid = true;
		$scope.password = "";
		$scope.accountOnlyRemoteWipe = true;
		$scope.canAccountOnlyRemoteWipe = canAccountOnlyRemoteWipe || false;

		// Functions
		$scope.save = save;
		$scope.cancel = cancel;

		activate();

		///////////////////////

		function activate() {
			$scope.$watch('password', function (newValue, oldValue) {
				if (newValue === oldValue)
					return;

				if (!$scope.password) {
					$scope.isPassValid = false;
				} else {
					$scope.isValid = true;
					$scope.isPassValid = true;
				}
			});
		}

		function save() {
			if (!$scope.password || !$scope.isPassValid) {
				$scope.isValid = false;
				$scope.isPassValid = false;
				return;
			}
			$mdDialog.hide({ password: $scope.password, accountOnlyRemoteWipe: $scope.accountOnlyRemoteWipe });
		};

		function cancel() {
			$mdDialog.cancel();
		};
	}

})();
