(function () {
	"use strict";
	angular
		.module("smartertools")
		.directive("stLoadingImage", loadingImageDirective);
	
	function loadingImageDirective($compile, $log, $timeout) {
		return {
			restrict: "C",
			link: function (scope, el) {
				el.on('load', onLoaded);
				el.on('error', onLoaded);
				function onLoaded() {
					el.addClass("image-loaded");
					el.removeClass("loading-image");
					el.off('load');
					el.off('error');
				}
			}
		};
	}

	angular
		.module("smartertools")
		.directive("stHtmlCompiler", htmlCompilerDirective);
	
	function htmlCompilerDirective($compile, $log, $timeout) {
		return {
			restrict: "C",
			transclude: true,
			scope: {
				compile: "<"
			},
			link: function (scope, el, attrs, ctrl, transclude) {
				transclude(scope, function (clone) {
					var compiled = $compile("<div>" + scope.compile + "</div>")(scope);
					el.append(compiled);
				});
			}
		};
	}
})();