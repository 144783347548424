(function () {

    angular
        .module('smartertools')
        .directive('stColorPill', stColorPill);

    /* @ngInject */
    function stColorPill() {
        return {
            restrict: 'EA',
            scope: {
                pillText: "@",
                pillColor: "@",
            },
            template: `<st-category-flag flag-color="pillColor"></st-category-flag><div class='pillText'>{{pillTextAdjusted}}</div><md-tooltip md-autohide ng-if="pillTextAdjusted != pillText">{{ pillText }}</md-tooltip>`,
            link: function (scope, element, attrs) {
                const el = element[0];

                let text = (scope.pillText || '').trim();

                // We use calculateTextWidth here instead of just counting characters to ensure it's not a label with a bunch of thin characters
                if (calculateTextWidth(text) > 130)
                    text = text.substr(0, 20) + "...";
                scope.pillTextAdjusted = text;
            }
        };
    }

    function calculateTextWidth(text) {
        var div = document.createElement('div');
        div.style.fontSize = '13px';
        div.style.position = 'absolute';
        div.style.visibility = 'hidden';
        div.style.whiteSpace = 'nowrap';
        div.innerHTML = text;
        document.body.appendChild(div);
        return div.clientWidth;
    }

})();