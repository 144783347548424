+(function () {
	"use strict";

	//TODO:

	angular
		.module("smartermail")
		.controller("chatHistorySearchController", chatHistorySearchController);

	function chatHistorySearchController($rootScope, $scope, $http, $q, $timeout, $filter, $translate, $compile, $sanitize, authStorage, $sce, $log, $mdDialog, $mdMedia,
		NgTableParams, userDataService, coreData, coreDataSettings, errorHandling, popupService) {
		var vm = this;
		vm.searchResults = [];
		$scope.startDate = moment().local().subtract(7, 'd').toDate();
        $scope.endDate = moment().local().toDate();
		$scope.aliasOptions = [];
		vm.percentProgress = 100;
		vm.showingResults = true;

		coreData.init()
			.then(function () {
				vm.hasLoaded = true;
			}, function () {});

		vm.tableParams = new NgTableParams(
			{
				sorting: { from: 'asc' }, // { fieldToSortBy: sortOrder }
				count: 25 // Default count
			},
			{
				getData: queryData, // Gets the data from a promise resolution
				counts: $rootScope.commonTableCounts
			}
		);

		// Functions
		vm.queryUsers = queryUsers;
		vm.editItem = editItem;
		vm.searchItems = searchItems;

		activate();

		/////////////////////

		function activate() {
			var promises = [
				$http.get('~/api/v1/settings/domain/aliases/' + userDataService.user.username + '/')
			];

			$q.all(promises)
            .then(function (successes) {
				$scope.aliasOptions = $.map(successes[0].data.gridInfo, function (alias) { return alias.name; });
            });
		};

        function editItem(selectedCard, ev) {
            var dateStart = moment(selectedCard.date).startOf('day').toISOString();
            var dateEnd = moment(selectedCard.date).endOf('day').toISOString();
			window.open(window.location.href.replace('/settings/domain/domain-chat-search',
                '/popout/chat-search/transcript/' + $scope.from + '/' + encodeURIComponent(selectedCard.chatId) + '/' + dateStart + '/' + dateEnd
            ),
				'', 'resizable=1, ' + popupService.dimensions.transcript);
		};

		function searchItems() {
			var params = {
				searchArgs: {
					from: $scope.from,
					startDateUtc: $scope.startDate,
					endDateUtc: $scope.endDate,
					text: $scope.text,
					to: $scope.to
				}
			};

			$http.post('~/api/v1/chat/history-search', JSON.stringify(params))
				.then(function (success) {
					vm.showingResults = false;
					vm.tableParams;
					vm.searchResults = [];
					vm.searchID = success.data.result;
					checkProgress();
					//vm.tableParams.reload();
				}, errorHandling.report);
		};

		function queryUsers(query) {
			var defer = $q.defer();

			$http
				.get("~/api/v1/settings/search-users/" + query)
				.then(function (success) {
					var results = success.data.userData;
					var names = results.map(function (v) { return { userName: v.userName, emailAddress: v.emailAddress, fullName: v.fullName } });
					names = names.concat($scope.aliasOptions.filter(function (x) {
						if (x.toLowerCase().indexOf(query.toLowerCase()) > -1) {
							return true;
						}
						return false;
					}).map(function (v) { return { userName: v, emailAddress: '', fullName: '' }}));
					defer.resolve(names);
				}, function (failure) {
					defer.resolve([]);
				});

			return defer.promise;
		}

		function queryData(params) {
			if (!vm.searchID) return;

			var searchParams = {
				searchID: vm.searchID,
				startIndex: (params.page() - 1) * params.count(),
				count: params.count()
			};

			return $http.get('~/api/v1/chat/history-search/' + searchParams.searchID + '/' + searchParams.startIndex + '/' + searchParams.count)
				.then(function (success) {
					vm.searchResults = success.data.searchResults.results;
					for (var i = 0; i < vm.searchResults.length; ++i) {
						var v = vm.searchResults[i];
						v.previewText = $($sanitize("<div>" + v.previewText + "</div>")).text();
					}
					vm.searchResultCount = success.data.searchResults.totalItems;
					params.total(vm.searchResultCount);

					return vm.searchResults;
				}, function (failure) {
					errorHandling.report($translate.instant(failure.data.message));
				});
		};

		function checkProgress() {
			if (!vm.searchID) return;

			var searchParams = {
				searchID: vm.searchID,
				startIndex: 0,
				count: 0
			};

			$http.get('~/api/v1/chat/history-search/' + searchParams.searchID + '/' + searchParams.startIndex + '/' + searchParams.count)
				.then(function (success) {
					vm.searchResultCount = success.data.searchResults.totalItems;
					vm.percentProgress = success.data.searchResults.percentProgress;

					if (vm.percentProgress < 100) {
						$timeout(function () { checkProgress() }, 1000);
					} else {
						if (vm.percentProgress >= 100 && vm.searchResultCount === 0) {
							vm.noResults = true;
						}
					}

					var startIndex = ((vm.tableParams.page() - 1) * vm.tableParams.count()) + 1;
					if (vm.percentProgress >= 100 || (vm.searchResultCount - startIndex > 25 && vm.searchResults.length < vm.tableParams.count())) {
						vm.tableParams.reload();
						vm.showingResults = true;
						vm.noResults = false;
					}

				}, function (failure) {
					errorHandling.report($translate.instant(failure.data.message));
				});
		};
	};
})();