(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("synchronizedDeviceInfo", synchronizedDeviceInfo);

    /* @ngInject */
	function synchronizedDeviceInfo($http, $mdDialog, coreDataSettings, errorHandling) {
        var vm = this;
       
        // Functions
        vm.deleteActiveSync = deleteActiveSync;
        vm.deleteStsSync = deleteStsSync;
        vm.deleteCardDavSync = deleteCardDavSync;
		vm.deleteEwsSync = deleteEwsSync;
        vm.deleteMapiSync = deleteMapiSync;
        vm.activeSyncRemoteWipe = activeSyncRemoteWipe;
        vm.loadCards = function () { };
        vm.connectedDevices = [];

        activate();

        ///////////////////////////

        function activate() { }

        function deleteActiveSync(card) {
            $http.post('~/api/v1/settings/active-sync-device-delete/' + card.deviceIdHash)
                .then(function () {
                    vm.connectedDevices = $.grep(vm.connectedDevices, function (device) { return device.deviceIdHash === card.deviceIdHash; }, true);
                    coreDataSettings.settingsData.userSyncDevices = $.extend(true, [], vm.connectedDevices);
                    vm.loadCards();
                }, errorHandling.report);
        }

        function deleteStsSync(card) {
            $http.post('~/api/v1/settings/sts-map-delete/' + card.deviceId)
                .then(function (success) {
                    vm.connectedDevices = $.grep(vm.connectedDevices, function (device) { return device.deviceId === card.deviceId; }, true);
                    coreDataSettings.settingsData.userSyncDevices = $.extend(true, [], vm.connectedDevices);
                    vm.loadCards();
                }, errorHandling.report);
        }

        function deleteCardDavSync(card) {
            $http.post('~/api/v1/settings/card-cal-dav-delete/' + card.syncType)
                .then(function () {
                    vm.connectedDevices = $.grep(vm.connectedDevices, function (device) { return device.syncType === card.syncType; }, true);
                    coreDataSettings.settingsData.userSyncDevices = $.extend(true, [], vm.connectedDevices);
                    vm.loadCards();
                }, errorHandling.report);
        }

        function deleteEwsSync(card) {
            $http.post('~/api/v1/settings/ews-sync-delete')
                .then(function (success) {
                    vm.connectedDevices = $.grep(vm.connectedDevices, function (device) { return device.syncType === card.syncType; }, true);
                    coreDataSettings.settingsData.userSyncDevices = $.extend(true, [], vm.connectedDevices);
                    vm.loadCards();
                }, errorHandling.report);
		}

		function deleteMapiSync(card) {
			$http.post('~/api/v1/settings/mapi-sync-delete/')
				.then(function (success) {
					vm.connectedDevices = $.grep(vm.connectedDevices, function (device) { return device.syncType === card.syncType; }, true);
					coreDataSettings.settingsData.userSyncDevices = $.extend(true, [], vm.connectedDevices);
					vm.loadCards();
				}, errorHandling.report);
        }

        function activeSyncRemoteWipe(card, ev) {
	        $mdDialog
		        .show({
			        locals: { canAccountOnlyRemoteWipe: card.protocolVersion === "v16_1" },
			        controller: "userRemoteWipeConfirmController",
			        controllerAs: "ctrl",
			        templateUrl: "app/settings/user-settings/external-accounts/confirm-remote-wipe.dlg.html",
			        targetEvent: ev
		        })
		        .then(function(modalSuccess) {
				        var params = JSON.stringify({
					        deviceName: card.deviceIdHash,
					        password: modalSuccess.password,
					        accountOnlyRemoteWipe: modalSuccess.accountOnlyRemoteWipe
				        });
				        $http
					        .post("~/api/v1/settings/active-sync-remote-wipe", params)
					        .then(function() {}, errorHandling.report);
			        },
			        function() {});
        }
    }

})();