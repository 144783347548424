(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("systemSenderPriorityOverrideModalController", systemSenderPriorityOverrideModalController);

	function systemSenderPriorityOverrideModalController($rootScope, $scope, $mdDialog, $filter, $http, info, errorHandling) {
		var orig = angular.copy(info);
		$scope.address = info.address;
		$scope.priority = info.priority;
		$scope.description = info.description;
        $scope.priorityOptions = [
            { value: 100, translation: "LOW" },
            { value: 101, translation: "NORMAL" },
            { value: 102, translation: "HIGH" }
        ];
		
	    // Functions
		$scope.save = save;
		$scope.cancel = cancel;
		$scope.deleteItem = deleteItem;

		activate();

	    /////////////////////

		function activate() {
		}

		function save() {
			if ($scope.pageForm.$invalid) {
				return;
			}

			var params = JSON.stringify({
				priorityOverride: {
					address: $scope.address,
					priority: $scope.priority,
					description: $scope.description
				}
			});

			if (orig.isNew)
				addOverride(params);
			else
				editOverride(params);
		};

		function cancel() {
			$mdDialog.cancel();
		};
		function deleteItem() {
			$mdDialog.hide({delete: true});
		}
		function addOverride(params) {
			$rootScope.spinner.show();
			$http
				.post("~/api/v1/settings/sysadmin/priority-override-put", params)
				.then(onSaveSuccess, errorHandling.report)
				.finally($rootScope.spinner.hide);
		}

		function editOverride(params) {
			$rootScope.spinner.show();
			$http
				.post("~/api/v1/settings/sysadmin/priority-override/" + orig.address, params)
				.then(onSaveSuccess, errorHandling.report)
				.finally($rootScope.spinner.hide);
		}

		function onSaveSuccess() {
			$mdDialog.hide({});
		}
	}

})();
