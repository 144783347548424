(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("spoolMoveConfirmController", spoolMoveConfirmController);

	function spoolMoveConfirmController($scope, $mdDialog, coreDataSpool, spoolPath, address, type, count, editAddress) {
		var vm = this;
		vm.address = address;
		$scope.editAddress = editAddress;
		$scope.addressRequired = false;
		$scope.type = type;
		$scope.count = count ? count : 0;
		$scope.folder = spoolPath;

		$scope.init = function() {
			switch ($scope.type) {
				case coreDataSpool.spoolStatTypes.OutboundSenders:
				case coreDataSpool.spoolStatTypes.InboundRecipients:
				case coreDataSpool.spoolStatTypes.InboundSenders:
					$scope.addressRequired = true;
					break;
			};
		}

		$scope.save = function () {
			if ($scope.spoolMoveForm.$valid) {
				$mdDialog.hide({location: $scope.folder, address: vm.address, type: $scope.type});
			} 
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}

})();
