// This filter is used in the abn-tree to determine
//		how/if it should translate the label 
(function () {
    angular
        .module("smartertools")
        .filter("folderLabel", folderLabel);

    function folderLabel(folderNameTranslator) {
        return function (folderData) {
            return folderNameTranslator.fromFolderData(folderData);
        };
    }

})();
