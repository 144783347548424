(function () {
    'use strict';

    angular.module('smartermail')
        .directive('attachedFile', [
            function () {
                return {
                    restrict: 'E',
                    replace: true,
                    scope: {
                        item: '=',
                        isCompose: '=',
                        removeAttachment: '&'
                    },
                    link: function (scope, element, attrs) {
                        scope.deleteClicked = function (item, ev) {
                            ev.stopPropagation();
                            ev.preventDefault();
                            if (scope.removeAttachment) {
                                scope.removeAttachment()(item, ev);
                            }
                        };
                    },
                    template: `
                    <a href="{{item.webLink}}" class="attached-file" download="{{item.filename}}" title="{{item.filename}}"> 
                        <div class="preview-image">
							<img  ng-if="item.hasImagePreview" ng-src="{{item.webLink}}" onerror="this.onerror=null; this.src='/interface/img/misc/file-image.svg'" />
                            <i ng-if="!item.hasImagePreview" class="toolsicon toolsicon-file{{(item.iconType ? ('-' + item.iconType) : '')}}"></i>
						</div> 
                        <div class="attached-file-info">
                            <div class="attached-filename">{{item.filename}}</div>
                            <div class="attached-filesize"><span ng-if="item.allAttachments">{{::"APPROXIMATELY" | translate}} </span>{{::item.size | bytes}}</div>
                        </div>
                        <div class="att-remove-button">
                            <div class="btn-container" ng-click="$parent.deleteClicked($parent.item, $event)" ng-if="isCompose && !item.isInline">
                                <i class="toolsicon toolsicon-close"></i>
                            </div>
                        </div>
                    </a>`
                };
            }]);
}());