(function () {
    'use strict';

    angular.module('smartermail')
        .directive('mailAttachmentNew', [
            function () {
                return {
                    restrict: 'E',
                    replace: true,
                    scope: {
                        item: '=',
                        isCompose: '=',
                        removeAttachment: '&'
                    },
                    link: function (scope, element, attrs) {
                        scope.deleteClicked = function (item, ev) {
                            ev.stopPropagation();
                            ev.preventDefault();
                            if (scope.removeAttachment) {
                                scope.removeAttachment()(item, ev);
                            }
                        };
                    },
                    template: `
                    <div class="emailAttachment" title="{{item.filename}}">
                        <i ng-if="!item.previewImage && !item.allAttachments" class="toolsicon toolsicon-file{{(item.type ? ('-' + item.type) : '')}}"></i>
                        <i ng-if="!item.previewImage && item.allAttachments" class="toolsicon toolsicon-download dl-all-icon"></i>
                        <div ng-if="item.previewImage" class="previewImage"><img ng-src="{{item.previewImage}}" on-error-src="~/interface/img/misc/file-image.svg"/></div>
                        <div class="emailAttachmentInfo">
                            <span class="emailAttachmentName">{{item.filename}}</span>
                            <span class="emailAttachmentSize"><span ng-if="item.allAttachments">{{::"APPROXIMATELY" | translate}} </span>{{::item.size | bytes}}</span>
                        </div>
                        <div class="att-remove-button" ng-if="isCompose">
                            <div class="btn-container" ng-click="$parent.deleteClicked($parent.item, $event)">
                                <i class="toolsicon toolsicon-close"></i>
                            </div>
                        </div>
                    </div>`
                };
            }]);

}());