(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminManageServicesController", sysAdminManageServicesController);

	function sysAdminManageServicesController($rootScope, $scope, $http, toaster, $translate, $mdDialog, $filter, $compile,
		coreData, coreDataSysAdminSettings, coreDataSysAdminCards, errorHandling, NgTableParams, gridCheckboxes) {
		var vm = this;
		vm.items = [];
		vm.tableParams = new NgTableParams(
			{
				sorting: { name: 'asc' },
				count: 25
			}, {
				dataset: [],
				counts: $rootScope.commonTableCounts
			});
		vm.checkboxes = gridCheckboxes.init("key");
		vm.checkboxes.table = vm.tableParams;

		// Functions
		vm.startServices = startServices;
		vm.stopServices = stopServices;
		vm.searchItems = searchItems;
		vm.contextMenuGridItem = contextMenuGridItem;
		vm.loadItems = loadItems;

		activate();

		//////////////////////////

		function activate() {
			loadItems();
			$scope.$emit("troubleshooting:countersChanged");
		}

		function loadItems() {
			vm.checkboxes.reset();
			$http
				.get('~/api/v1/settings/sysadmin/services')
				.then(function (result) {
					var services = result.data.services || [];
					vm.serviceInfo = {
						exchangeretrieval: { name: "EWS_RETRIEVAL", description: "LOGS_AND_SETTINGS_SERVICES_DESCRIPTIONS_EXCHANGE_RETRIEVAL" },
						imap: { name: 'IMAP', description: 'LOGS_AND_SETTINGS_SERVICES_DESCRIPTIONS_IMAP' },
						imapretrieval: { name: 'IMAP_RETRIEVAL', description: 'LOGS_AND_SETTINGS_SERVICES_DESCRIPTIONS_IMAP_RETRIEVAL' },
						indexing: { name: 'INDEXING', description: 'LOGS_AND_SETTINGS_SERVICES_DESCRIPTIONS_INDEXING' },
						ldap: { name: 'LDAP', description: 'LOGS_AND_SETTINGS_SERVICES_DESCRIPTIONS_LDAP' },
						pop: { name: 'POP', description: 'LOGS_AND_SETTINGS_SERVICES_DESCRIPTIONS_POP' },
						popretrieval: { name: 'POP_RETRIEVAL', description: 'LOGS_AND_SETTINGS_SERVICES_DESCRIPTIONS_POP_RETRIEVAL' },
						smtp: { name: 'SMTP', description: 'LOGS_AND_SETTINGS_SERVICES_DESCRIPTIONS_SMTP' },
						spool: { name: 'SPOOL', description: 'LOGS_AND_SETTINGS_SERVICES_DESCRIPTIONS_SPOOL' },
						xmpp: { name: 'XMPP', description: 'LOGS_AND_SETTINGS_SERVICES_DESCRIPTIONS_XMPP' }
					};

					vm.items = [];
					angular.forEach(services,
						function (value, key) {
							let si = vm.serviceInfo[key.toLowerCase()];
							var item =
								{
									key: key,
									status: value,
									sortableStatus: String(value),
									name: $translate.instant(si.name),
									description: $translate.instant(si.description)
								};
							vm.items.push(item);
						});
					vm.tableParams.settings({ dataset: vm.items });
					refreshTable();
				}, function () { });
		}

		function searchItems() {
			vm.tableParams.filter({ $: vm.searchText });
		}

		function refreshTable() {
			vm.tableParams.reload();
		}

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === item.key; })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item.key];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: 'startServices', click: startServices, translateKey: 'START' },
				{ key: 'stopServices', click: stopServices, translateKey: 'STOP' }
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $('#context-menu-area');
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		function startServices() {
			var checkedItems = vm.checkboxes.getCheckedItems();
			if (!checkedItems || !checkedItems.length)
				return;

			if (checkedItems.length == 1) {
				doStartServices();
			} else {
				var confirm = $mdDialog.confirmDeletion()
					.textContent($filter('translate')('CONFIRMATIONS_MULTI_GENERIC', { count: checkedItems.length }))
					.ok($filter('translate')('OK'))
					.title($filter('translate')('CONFIRMATION_REQUIRED'));
				$mdDialog.show(confirm).then(function () { doStartServices(); });
			}

			function doStartServices() {
				var params = JSON.stringify({ input: checkedItems });
				$rootScope.spinner.show();
				$http
					.post('~/api/v1/settings/sysadmin/start-services', params)
					.then(onStartSuccess, errorHandling.report)
					.finally($rootScope.spinner.hide);

				function onStartSuccess() {
					loadItems();
					$rootScope.$broadcast("troubleshooting:countersChanged");
					toaster.pop("success", "", $translate.instant("ACTION_SUCCEEDED"));
				}
			}
		};

		function stopServices() {
			var checkedItems = vm.checkboxes.getCheckedItems();
			if (!checkedItems || !checkedItems.length)
				return;

			if (checkedItems.length == 1) {
				doStopServices();
			} else {
				var confirm = $mdDialog.confirmDeletion()
					.textContent($filter('translate')('CONFIRMATIONS_MULTI_GENERIC', { count: checkedItems.length }))
					.ok($filter('translate')('OK'))
					.noWarn(true)
					.title($filter('translate')('CONFIRMATION_REQUIRED'));
				$mdDialog.show(confirm).then(function () { doStopServices(); });
			}

			function doStopServices() {
				var params = JSON.stringify({ input: checkedItems });
				$rootScope.spinner.show();
				$http
					.post('~/api/v1/settings/sysadmin/stop-services', params)
					.then(onStopSuccess, errorHandling.report)
					.finally($rootScope.spinner.hide);

				function onStopSuccess() {
					loadItems();
					$rootScope.$broadcast("troubleshooting:countersChanged");
					toaster.pop("success", "", $translate.instant("ACTION_SUCCEEDED"));
				}
			}
		};
	}

})();
