(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("loginPreviewController", loginPreviewController);

	function loginPreviewController($scope, $sce, $http, AuthenticationService, $filter, 
		$timeout, localeInfoService, $translate, preferencesStorage) {

		var vm = this;
		vm.settingsLoaded = false;
		vm.imageUrl = null;
		vm.imageContent = null;
		vm.state = "login"; // States: login, passwordRequest, passwordReset
		vm.initializing = false;
		vm.displayName = null;
		vm.selectedLocale = localeInfoService.language;
		vm.availableLocales = [];
		vm.imageFile = null;
		for (var i = 0; i < angularLangNames.length; i++) {
			vm.availableLocales.push(angularLangNames[i]);
		}
		vm.stage1NextText = vm.notificationsSupported ? "NEXT" : "OK";
		vm.hasTranslations = false;

		// Functions
		vm.setLocale = setLocale;
		vm.digestCount = 0;
		// Startup
		checkTranslationInit();

		//$rootScope.$watch(function () { vm.digestCount++; $log.log("Digest " + vm.digestCount); });

		window.addEventListener('storage', function (event) {
			updateInterface();
			$scope.$applyAsync();
		});

		function updateInterface() {
			var changes = preferencesStorage.getLocalParam("loginPreviewChanges");
			if (!changes) return;

			if (changes.isDomain)
			{
				vm.backgroundImage = vm.orignalBackgroundImage;
				vm.backgroundColor = vm.originalBackgroundColor;
			}

			if (changes.imageGuid !== undefined) {
				if (!changes.imageGuid)
					vm.imageUrl = "";
				else
					vm.imageUrl = stSiteRoot + 'api/v1/settings/sysadmin/temp-image/' + changes.imageGuid;
			}

			if (changes.logoAction !== undefined) {
				if (changes.logoAction === "revert")
					vm.imageUrl = vm.originalImageUrl;
				else if (changes.logoAction === "replace")
					vm.originalImageUrl = vm.imageUrl;
			}

			if (changes.customTitle !== undefined) {
				vm.welcomeMessage = "";
				$translate('WELCOME_TO_SMARTERMAIL').then(function (success) {
					vm.welcomeMessage = changes.customTitle || success;
				}, function () {
					vm.welcomeMessage = changes.customTitle || $filter('translate')('WELCOME_TO_SMARTERMAIL');
				});
			}

			if (changes.backgroundStillImage !== undefined) {
				if (!changes.backgroundStillImage)
					vm.backgroundImage = undefined;
				else
					vm.backgroundImage = changes.backgroundStillImage;
			}

			if (changes.backgroundColor !== undefined) {
				vm.backgroundColor = changes.backgroundColor;
			}

			if (changes.customHtmlBlock !== undefined) {
				vm.customHtmlBlock = $sce.trustAsHtml(changes.customHtmlBlock);
			}

            vm.helpUrl = 'https://help.smartertools.com/SmarterMail/current/?page=login&v=17';
			if (changes.customHelpUrl) {
				vm.helpUrl = changes.customHelpUrl;
			}

			vm.helpText = $translate.instant('OPEN_HELP');
			if (changes.customHelpText) {
				vm.helpText = changes.customHelpText;
			}

			preferencesStorage.setLocalParam("loginPreviewChanges", undefined);
		}

		function setLocale(val) {
			localeInfoService.setLocale(val);
			vm.selectedLocale = val;
		}

		function checkTranslationInit() {
			localeInfoService.onReady().then(function () { //Wait for a guaranteed translation before letting anything else load.
				vm.hasTranslations = true;
				init();
			}, function () { //If the translation id was not found
				vm.hasTranslations = true;
				init();
			});
		}

		function init() {
			setImageFile();
			AuthenticationService.loadLoginSettings()
				.then(onLoginSettingsLoaded, function() {});
		}

		function setImageFile() {
			// Opera 8.0+
			var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

			// Firefox 1.0+
			var isFirefox = typeof InstallTrigger !== 'undefined';

			// Safari 3.0+ "[object HTMLElementConstructor]" 
			var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || safari.pushNotification);

			// Internet Explorer 6-11
			var isIE = /*@cc_on!@*/false || !!document.documentMode;

			// Edge 20+
			var isEdge = !isIE && !!window.StyleMedia;

			// Chrome 1+
			var isChrome = !!window.chrome && !!window.chrome.webstore;

			// Blink engine detection
			var isBlink = (isChrome || isOpera) && !!window.CSS;

			if (isOpera)
				vm.imageFile = null;// (stSiteRoot || '/') + "interface/img/browser-messages/browser-notifications-opera.jpg";
			else if (isFirefox)
				vm.imageFile = (stSiteRoot || '/') + "interface/img/browser-messages/browser-notifications-firefox.jpg";
			else if (isSafari)
				vm.imageFile = null;// (stSiteRoot || '/') + "interface/img/browser-messages/browser-notifications-safari.jpg";
			else if (isIE)
				vm.imageFile = null;// (stSiteRoot || '/') + "interface/img/browser-messages/browser-notifications-ie.jpg";
			else if (isEdge)
				vm.imageFile = (stSiteRoot || '/') + "interface/img/browser-messages/browser-notifications-edge.jpg";
			else if (isChrome || isBlink)
				vm.imageFile = (stSiteRoot || '/') + "interface/img/browser-messages/browser-notifications-chrome.jpg";
		}

		function onLoginSettingsLoaded(data) {
			vm.imageUrl = data.customLogo;
			vm.originalImageUrl = data.customLogo;
			vm.orignalBackgroundImage = data.backgroundStillImage;
			vm.originalBackgroundColor = data.backgroundColor;
			updateInterface();

			vm.initializing = false;
			vm.settingsLoaded = true;
			$timeout(function () {
				if (!vm.username)
					$('#loginUsernameBox').trigger("focus");
				else
					$('#loginPasswordBox').trigger("focus");
			}, 100);
		}

		vm.getImageSource = function (imageUrl) {
			return imageUrl || "img/email.svg";
		};
	}
})();