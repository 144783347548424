(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminEditRspamdServerController", sysAdminEditRspamdServerController);

	function sysAdminEditRspamdServerController($scope, $mdDialog, $filter, toaster, cardData) {
		$scope.name = cardData && cardData.rspamdServerName;
		$scope.httpBaseAddress = cardData && cardData.httpBaseAddress;
		$scope.httpCheckAddress = (cardData && cardData.httpCheckAddress) || "/checkv2";
		$scope.httpSpamAddress = cardData && cardData.httpSpamAddress;
		$scope.httpHamAddress = cardData && cardData.httpHamAddress;

		$scope.isValid = true;
		$scope.editingItem = !!cardData;

		$scope.delete = function () {
			$mdDialog.hide({ doDelete: true });
		};

		$scope.save = function (form) {

			if (!$scope.httpCheckAddress || !$scope.httpCheckAddress.toLowerCase().startsWith('/checkv2')) {
				toaster.pop("error", $filter("translate")("RSPAMD_ENDPOINT_ERROR", { name: $filter("translate")("RSPAM_CHECKV2_ADDRESS"), endpoint: '/checkv2' }));
				return;
			}

			if ($scope.httpSpamAddress && !$scope.httpSpamAddress.toLowerCase().startsWith('/learnspam')) {
				toaster.pop("error", $filter("translate")("RSPAMD_ENDPOINT_ERROR", { name: $filter("translate")("RSPAM_LEARNSPAM_ADDRESS"), endpoint: '/learnspam' }));
				return;
			}

			if ($scope.httpHamAddress && !$scope.httpHamAddress.toLowerCase().startsWith('/learnham')) {
				toaster.pop("error", $filter("translate")("RSPAMD_ENDPOINT_ERROR", { name: $filter("translate")("RSPAM_LEARNHAM_ADDRESS"), endpoint: '/learnham' }));
				return;
			}

			form.$setSubmitted();
			if (!($scope.isValid = form.$valid)) {
				return;
			}

			$mdDialog.hide({
				rspamdServerName: $scope.name,
				httpBaseAddress: $scope.httpBaseAddress,
				httpCheckAddress: $scope.httpCheckAddress,
				httpSpamAddress: $scope.httpSpamAddress,
				httpHamAddress: $scope.httpHamAddress,
			});
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}

})();
