(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("userFailedToLoadDialogController", userFailedToLoadDialogController);

	function userFailedToLoadDialogController($scope, $mdDialog, $http, errorHandling, username, domainPath) {

		$scope.username = username;
		$scope.domainPath = domainPath + "/Users/" + username;

		$scope.cancel = function () {
			$mdDialog.cancel();
		};

		$scope.detachUser = function() {
			$http
				.post("~/api/v1/settings/domain/user-detach/" + $scope.username)
				.then(function () { $mdDialog.hide(true); }, errorHandling.report);
		};
	}

})();
