(function () {
    'use strict';

    angular
        .module('smartermail')
        .controller('getLdapCredentialsController', getLdapCredentialsController);

    function getLdapCredentialsController($scope, $mdDialog) {  
        /* jshint validthis:true */
        var vm = this;

        // Functions
        vm.cancel = cancel;
        vm.enterCredentials = enterCredentials;

        activate();
        
        ////////////////

        function activate() {
        }

        function cancel(ev) {
            $mdDialog.cancel();
        }

        function enterCredentials(ev) {
            $mdDialog.hide({ ldapUser: vm.ldapUser, ldapPassword: vm.ldapPass, rememberCredentials: vm.rememberMe });
        }
    }
})();
