(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("taskImportDialogController", taskImportDialogController);

    function taskImportDialogController($scope, $mdDialog, $q, $timeout, coreDataTasks, errorHandling) {
        var vm = this;

        $scope.isProcessing = false;
		$scope.sources = $.grep(coreDataTasks.getSources(), function (source) { return source; });
		vm.currentSource = $scope.sources[0];
		$scope.uploaderData = JSON.stringify({ source: vm.currentSource.owner });



		$scope.uploaderInit = function (publicFunctions) {
			$scope.uploaderFunctions = publicFunctions;
		}

		$scope.importFile = function (file, data) {
			$scope.isProcessing = false;
			$mdDialog.hide();
		};

		$scope.uploadFailed = function () {
			errorHandling.report("ERROR_UPLOADING");
			$scope.isProcessing = false;
			$scope.uploaderFunctions.reset();
		}

		$scope.done = function () {
			$scope.uploaderData = JSON.stringify({ source: vm.currentSource.owner, fileId: vm.currentSource.folderId });
			$scope.isProcessing = true;
			$scope.uploaderFunctions.startUpload();
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
    }
})();