(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("emailNavigation", emailNavigation);

	function emailNavigation(claimsService) {
		var vm = this;

		// Functions
		vm.parsePopoutPacket = parsePopoutPacket;
		vm.makeComposePacket = makeComposePacket;
		vm.makeEmailPacket = makeEmailPacket;
		vm.makeArchivePacket = makeArchivePacket;
		vm.makeSpoolPacket = makeSpoolPacket;
		vm.getPopoutUrl = getPopoutUrl;
		vm.getPopoutComposeUrl = getPopoutComposeUrl;

		////////////////////////////////////////

		function makeComposePacket(options) {
			let packet = {};

			if (options && options.sid)
				packet.sid = options.sid;
			if (options && options.owner)
				packet.owner = options.owner;

			if (options && options.folder)
				packet.folder = options.folder;
			if (options && options.id) {
				packet.uid = options.id;
				packet.id = options.id;
			}
			if (options && options.uid) {
				packet.uid = options.uid;
				packet.id = options.uid;
			}
			if (options && options.attachEmails)
				packet.attachEmails = options.attachEmails;
			if (options && options.to)
				packet.to = options.to;
			if (options && options.reply)
				packet.reply = options.reply;

			// Fixup missing items from packet
			if (!packet.owner)
				packet.owner = claimsService.getEmailAddress();

			return packet;
		}

		function makeEmailPacket(owner, folder, uid, mid, options) {
			let packet = {};
			if (uid > 0)
				packet = { owner, folder, uid, mid, id: uid };
			else if (mid > 0)
				packet = { owner, folder, uid, mid, id: mid };

			if (options && options.mode)
				packet.mode = options.mode;
			if (options && options.markRead)
				packet.markRead = options.markRead;
			if (options && options.softReload)
				packet.softReload = options.softReload;

			// Fixup missing items from packet
			if (!packet.owner)
				packet.owner = claimsService.getEmailAddress();

			return packet;
		}

		function makeArchivePacket(searchGuid, uid, options) {
			let packet = { type: 'archive', searchGuid, uid, id: uid };
			if (options && options.mode)
				packet.mode = options.mode;
			return packet;
		}

		function makeSpoolPacket(spoolName, fileName, quarantine, options) {
			let packet = { type: 'spool', spoolName, fileName, quarantine };
			if (options && options.mode)
				packet.mode = options.mode;
			return packet;
		}

		function encodePacketForUrl(packet) {
			return encodeURIComponent(utf8ToBase64(JSON.stringify(packet)))
				.replace(/%2f/gi, "%252f"); // Double encode forward slashes because something in angular is decoding them improperly, resulting in the URL getting broken into too many segments
		}

		function getPopoutUrl(packet) {
			const packetString = encodePacketForUrl(packet);
			if (packet.type === 'spool')
				return `#/popout/email/view-spool/${packetString}`;
			else if (packet.type === 'archive')
				return `#/popout/email/view-archive/${packetString}`;
			else
				return `#/popout/email/view/${packetString}`;
		}

		function getPopoutComposeUrl(packet) {
			const packetString = encodePacketForUrl(packet);
			return `#/popout/email/compose/${packetString}`;
		}

		function parsePopoutPacket(packet) {
			if (!packet)
				return {};
			const objJsonStr = base64ToUtf8(decodeURIComponent(packet.replace(/%252f/gi, "%2f")));
			return JSON.parse(objJsonStr);
		}

		////////////////////////////////////////////////

	}
})();
