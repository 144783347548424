(function () {
	"use strict";

	angular
		.module('smartermail')
		.service("diskSpaceSummaryService", diskSpaceSummaryService)
		.component('diskSpaceSummary', {
			templateUrl: "app/components/disk-space-summary.component.html",
			controller: diskSpaceSummaryController,
			transclude: false,
			bindings: {}
		});

	/* @ngInject */
	function diskSpaceSummaryService($rootScope, $http, $log) {
		var vm = this;
		vm.diskSpaceLoaded = false;
		vm.diskUsage = {};

		// Functions
		vm.getDiskUsage = getDiskUsage;

		activate();

		/////////////////

		function activate() {
			getDiskUsage();
			$rootScope.$on('diskSpaceUsage:changed', function () { getDiskUsage(); });
		}

		function getDiskUsage() {
			$http.get('~/api/v1/filestorage/disk-usage-summary')
				.then(function (success) {
					vm.diskUsage = success.data.diskUsage;
					vm.diskUsage.mailboxPercent = 0;
					vm.diskUsage.appointmentsPercent = 0;
					vm.diskUsage.contactsPercent = 0;
					vm.diskUsage.notesPercent = 0;
					vm.diskUsage.tasksPercent = 0;
					vm.diskUsage.fileStoragePercent = 0;
					vm.diskUsage.meetingWorkspacePercent = 0;
					vm.diskUsage.chatFilesPercent = 0;

					if (vm.diskUsage.allowed > 0) {
						vm.diskUsage.mailboxPercent = vm.diskUsage.mailboxUsed * 100 / vm.diskUsage.allowed;
						vm.diskUsage.appointmentsPercent = vm.diskUsage.appointmentsUsed * 100 / vm.diskUsage.allowed;
						vm.diskUsage.contactsPercent = vm.diskUsage.contactsUsed * 100 / vm.diskUsage.allowed;
						vm.diskUsage.notesPercent = vm.diskUsage.notesUsed * 100 / vm.diskUsage.allowed;
						vm.diskUsage.tasksPercent = vm.diskUsage.tasksUsed * 100 / vm.diskUsage.allowed;
						vm.diskUsage.fileStoragePercent = vm.diskUsage.fileStorageUsed * 100 / vm.diskUsage.allowed;
						vm.diskUsage.meetingWorkspacePercent = vm.diskUsage.meetingWorkspaceUsed * 100 / vm.diskUsage.allowed;
						vm.diskUsage.chatFilesPercent = vm.diskUsage.chatFilesUsed * 100 / vm.diskUsage.allowed;
					}
					vm.diskUsage.percentUsed = vm.diskUsage.allowed
						? Math.round(vm.diskUsage.used * 100 / vm.diskUsage.allowed)
						: null;
					vm.diskSpaceLoaded = true;
				}, function () { });
		}
	}

	/* @ngInject */
	function diskSpaceSummaryController($rootScope, $scope, $state, $log, $http, $filter, diskSpaceSummaryService) {
		var vm = this;
		vm.diskSpaceLoaded = diskSpaceSummaryService.diskSpaceLoaded;
		vm.diskUsage = diskSpaceSummaryService.diskUsage;
		vm.showDiskMenu = false;

		// Functions
		vm.goToDiskReport = goToDiskReport;
		vm.flipDiskFloater = flipDiskFloater;

		activate();

		/////////////////

		function activate() {
			$scope.$watch(function () { return diskSpaceSummaryService.diskSpaceLoaded; }, function () { vm.diskSpaceLoaded = diskSpaceSummaryService.diskSpaceLoaded; });
			$scope.$watch(function () { return diskSpaceSummaryService.diskUsage; }, function () { vm.diskUsage = diskSpaceSummaryService.diskUsage; });
		}

		function goToDiskReport() {
			$state.go('index.reports.reports-details', { type: 'user', report: 'disk_usage' });
		}

		function flipDiskFloater(newstate) {
			vm.showDiskMenu = newstate;
		}
	}

})();
