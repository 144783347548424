(function () {
    "use strict";

    angular
        .module("smartertools")
        .service("tableColumnSwitching", tableColumnSwitching);

    function tableColumnSwitching($rootScope, $timeout, ngTableEventsChannel) {
        var mvm = this;
        var _switcherList = [];

        // Functions
        mvm.requestSwitcher = requestSwitcher;
        mvm.getFirstSwitcher = getFirstSwitcher;
        mvm.reset = reset;

        function requestSwitcher() {
            var retSwitcher = new switcher();
            retSwitcher.id = String(Math.random()).split(".")[1];
            retSwitcher.init();
            _switcherList.push(retSwitcher);
            return retSwitcher;
        }

        function getFirstSwitcher() {
            // Some places in the interface actually reference the switcher, unfortunately.
            // Since those places only have one table we can just return the first switcher
            if (_switcherList.length > 0)
                return _switcherList[0];
            return null;
        }

        function reset() {
            for (var i = 0; i < _switcherList.length; i++) {
                _switcherList[i].reset();
            }
            _switcherList = [];
        }

        // Switcher "Class"
        function switcher() {
            var vm = this;
            var _columnCount = 0;
            var _columnWidths = [];
            var _tableWidth = 0;
            var _rtl = $("body[dir=rtl]").length > 0;
            var _cols = []; // This will contain all non-optional columns
            var _deRegisterTreeState;

            vm.id = undefined; // Used to identify the controller in the st-table directive
            vm.cols = null; // vm.cols is set in the stTableSecondDirective in st-table.directive.js
            vm.currentColumn = -1;
            vm.firstColumn = 0;
            vm.prev = _rtl ? next : prev;
            vm.next = _rtl ? prev : next;
            vm.showLeftChevron = false;
            vm.showRightChevron = false;
            vm.onSlidePanelSliding = onSlidePanelSliding;
            vm.allowCheckHide = true;
            vm.optionalColumns = { /* 4: function () { return shouldColShow; }*/ };

            // Functions
            vm.init = init;
            vm.reset = reset;
            vm.softReset = softReset;
            vm.setFirstColumn = setFirstColumn;

            //// Service management
            function init() {
                vm.allowCheckHide = true;
                window.addEventListener('resize', onResize);
                _deRegisterTreeState = $rootScope.$on("treeState:stateChange", onResize);
                return vm;
            }

            function getLastColumn() {
                return _columnCount <= 1 ? vm.currentColumn : vm.currentColumn + (_columnCount - 1);
            };

            function setLastColumn(value) {
                _columnCount = value - vm.firstColumn;
            }

            function setFirstColumn(c) {
                try {
                    vm.firstColumn = c || 0;
                } finally {
                    updateChevrons();
                }
            }

            function onResize() {
                checkHideColumns();
            }

            function reset() {
                vm.cols = null;
                vm.currentColumn = -1;
                vm.firstColumn = 0;
                setLastColumn(0);
                _columnWidths = [];
                window.removeEventListener('resize', onResize);
                _deRegisterTreeState();
            }

            function softReset() {
                _columnWidths = [];
                setLastColumn(0);
                vm.currentColumn = -1;
                vm.allowCheckHide = true;
                if (!vm.cols) return;
                for (var i = 0; i < vm.cols.length; i++) {
                    vm.cols[i].show(true);
                }
            }

            //// Show/Hide Chevrons - For RTL
            function updateChevrons() {
                var newShowLeft = _rtl ? showRightChevron() : showLeftChevron();
                var newShowRight = _rtl ? showLeftChevron() : showRightChevron();

                if (newShowLeft != vm.showLeftChevron || newShowRight != vm.showRightChevron) {
                    $rootScope.$applyAsync(function () {
                        vm.showLeftChevron = newShowLeft;
                        vm.showRightChevron = newShowRight;
                    });
                }

                function showLeftChevron() {
                    return vm.currentColumn > vm.firstColumn;
                }

                function showRightChevron() {
                    return vm.currentColumn > -1 && vm.currentColumn < _cols.length - 1 && getLastColumn() < _cols.length - 1;
                }
            }

            //// Actions
            function prev() {
                //if (_rtl) { next(); return; }
                if (vm.currentColumn - 1 < vm.firstColumn) return;
                // Please don't switch the order of these operations
                _cols[getLastColumn()].show(false);
                _cols[--vm.currentColumn].show(true);
                $timeout(calcVisibleColWidths, 25);
            }

            function next() {
                //if (_rtl) { prev(); return; }
                if (getLastColumn() >= _cols.length - 1) return;
                // Please don't switch the order of these operations
                _cols[vm.currentColumn++].show(false);
                _cols[getLastColumn()].show(true);
                $timeout(calcVisibleColWidths, 25);
            }

            function calcVisibleColWidths() {
                try {
                    var colWidths = [];
                    var tableWidth = 0;
                    const stTableSelector = '.st-table-' + vm.id;

                    $(`${stTableSelector} table colgroup`).remove();
                    $(`${stTableSelector} table`).css('table-layout', '');
                    $(`${stTableSelector} table .header`).each(function (_i, v) {
                        colWidths.push(v.offsetWidth);
                        tableWidth += v.offsetWidth;
                    });

                    var parent = $(stTableSelector).parent();
                    parent = parent[0]
                        ? parent[0].hasAttribute("not-parent")
                            ? parent.parent()
                            : parent
                        : parent;

                    var cardWidth = parent.outerWidth();
                    // This value is finicky, DO NOT TOUCH IT!!!!!!!!
                    cardWidth -= 18; // Offset for card padding and for the offset we cause on the card content when we create a left chevron

                    if (tableWidth <= cardWidth)
                        return;

                    var excess = tableWidth - cardWidth;
                    excess += 10;	// Offset to account for cell padding weirdness.
                    var adjustmentTargets = [];
                    var colGroup = $('<colgroup>');
                    for (var idx = 0; idx < colWidths.length; idx++) {
                        if (colWidths[idx] > (cardWidth * 0.5)) {
                            colGroup.append($('<col style="width:' + (colWidths[idx] - excess) + 'px">'));
                            excess = 0;
                            adjustmentTargets.push(idx);
                        } else {
                            colGroup.append($('<col style="width:' + colWidths[idx] + 'px">'));
                        }
                    }

                    if (adjustmentTargets.length > 0) {
                        $(`${stTableSelector} table`).css('table-layout', 'fixed');
                        if ($(`${stTableSelector} table colgroup`).length === 0)
                            $(`${stTableSelector} table`).prepend(colGroup);
                    }

                    for (var i = 0; i < adjustmentTargets.length; i++) {
                        if (vm.currentColumn >= 2) {
                            colGroup.children().eq(vm.currentColumn - 1).hide();
                        }
                        if (vm.currentColumn == 1) {
                            colGroup.children().eq(2).hide();
                        }
                    }

                } finally {
                    updateChevrons();
                }
            }

            // Showing Hiding
            function onSlidePanelSliding(expanding, offset) {
                if (!offset) offset = 300;
                offset = expanding ? -offset : offset;
                checkHideColumns(offset);
            }

            function checkHideColumns(offset, first) {
                try {
                    if (!vm.allowCheckHide) return;
                    // Offset deals with calculating future sizes when sliding panels
                    if (offset === undefined) offset = 0;
                    if (first) {
                        regenerateTrueCols();

                        // Need to make sure the containing card has loaded
                        var parent = $(".st-table-" + vm.id).parent();
                        parent = parent[0]
                            ? parent[0].hasAttribute("not-parent")
                                ? parent.parent()
                                : parent
                            : parent;
                        if (parent.outerWidth() < 200 || parent.outerWidth() >= window.innerWidth)
                            $timeout(function () { checkHideColumns(0, true); }, 25);
                        else
                            $timeout(doCheckHide);
                    }
                    else $timeout(doCheckHide);
                } finally {
                    updateChevrons();
                }

                function doCheckHide() {
                    try {
                        if (!vm.cols) return;
                        regenerateTrueCols();

                        const stTableSelector = '.st-table-' + vm.id;

                        // Remove settings that force specific widths to allow more accurate calculations.
                        $(`${stTableSelector} table colgroup`).remove();
                        $(`${stTableSelector} table`).css('table-layout', '');

                        // Only get the _tableWidth and _columnWidths if the full table is being displayed or if we haven't hid columns yet
                        if (vm.currentColumn === vm.firstColumn && getLastColumn() === _cols.length - 1 || vm.currentColumn === -1) {
                            _columnWidths = [];
                            _tableWidth = 0;
                            $(`${stTableSelector} table .header`).each(function (_i, v) {
                                _columnWidths.push(v.offsetWidth);
                                _tableWidth += v.offsetWidth;
                            });
                        }

                        var parent = $(stTableSelector).parent();
                        parent = parent[0]
                            ? parent[0].hasAttribute("not-parent")
                                ? parent.parent()
                                : parent
                            : parent;

                        var cardWidth = parent.outerWidth();
                        cardWidth += offset;
                        // This value is finicky, DO NOT TOUCH IT!!!!!!!!
                        cardWidth -= 18; // Offset for card padding and for the offset we cause on the card content when we create a left chevron

                        _columnCount = -1;
                        if (cardWidth >= _tableWidth) {
                            _columnCount = _cols.length - vm.firstColumn;
                            vm.currentColumn = vm.firstColumn;
                        } else {
                            // Check if any of the columns can be forced narrower:
                            var excess = _tableWidth - cardWidth;
                            excess += 10;	// Offset to account for cell padding weirdness.
                            var adjustmentTargets = [];
                            var colGroup = $('<colgroup>');
                            var isNotif = window.location.href.indexOf("popout/notifications") > -1;
                            for (var idx = 0; idx < _columnWidths.length; idx++) {
                                var notifColCheck = isNotif ? (_columnWidths[idx] * 0.7) : (_columnWidths[idx] * 0.6);
                                if (_columnWidths[idx] > (cardWidth * 0.5) && excess <= notifColCheck) {
                                    colGroup.append($('<col style="width:' + (_columnWidths[idx] - excess) + 'px">'));
                                    adjustmentTargets.push(idx);
                                } else {
                                    colGroup.append($('<col style="width:' + _columnWidths[idx] + 'px">'));
                                }
                            }
                            var notifExcessCheck = isNotif ? excess * .7 : excess;
                            if (notifExcessCheck < cardWidth && adjustmentTargets.length === 1) {
                                setLastColumn(_cols.length);
                                $(`${stTableSelector} table`).css('table-layout', 'fixed');
                                if ($(`${stTableSelector} table colgroup`).length === 0)
                                    $(`${stTableSelector} table`).prepend(colGroup);
                            }
                            else {
                                if (vm.currentColumn >= _cols.length)
                                    vm.currentColumn = vm.firstColumn;

                                // Find the initial amount of columns we'll show
                                var width = 0;
                                for (var i = 0; i < _columnWidths.length; i++) {
                                    width += _columnWidths[i];

                                    if (width > cardWidth) {
                                        // Since this column went over the width, the previous column was the last one to fit
                                        setLastColumn(i - 1);
                                        // If currentColumn is the last column, but now we can show three columns, then currentColumn should be three back
                                        if (getLastColumn() >= _cols.length) vm.currentColumn = _cols.length - _columnCount;
                                        break;
                                    }
                                }

                                if (_columnCount > vm.firstColumn + 1) {
                                    width = 0;
                                    var activeColumns = 0;
                                    var simCurrColumn = 0;
                                    // Simulate tabbing through the columns and make sure that at each point no columns are breaking out of the table
                                    for (var j = 0; j < _columnWidths.length; j++) {
                                        width += _columnWidths[j];
                                        if (++activeColumns < _columnCount) continue;

                                        // Some column is breaking out of the table still, we need to show one less column
                                        while (width > cardWidth) {
                                            _columnCount--;
                                            width -= _columnWidths[simCurrColumn++];
                                            if (_columnCount == 1) break;
                                        }

                                        width -= _columnWidths[simCurrColumn++];
                                    }
                                }
                            }
                        }

                        hideColumns();
                    } finally {
                        updateChevrons();
                    }

                }

                function hideColumns() {
                    if (vm.currentColumn < vm.firstColumn) vm.currentColumn = vm.firstColumn;

                    // Show everything from the currentColumn to the lastColumn, hide everything after that
                    for (var i = vm.firstColumn; i < _cols.length; i++) {
                        if (i >= vm.currentColumn && i <= getLastColumn() && getLastColumn() != -1)
                            _cols[i].show(true);
                        else
                            _cols[i].show(false);
                    }
                }
            }

            function regenerateTrueCols() {
                _cols = [];
                if (!vm.cols) return;
                for (var i = 0; i < vm.cols.length; i++) {
                    var opCol = vm.optionalColumns[i];
                    if (!opCol || (opCol && typeof opCol === "function" && opCol())) {
                        _cols.push(generateColSurrogate(i));
                        vm.cols[i].show(true);
                    }
                    else
                        vm.cols[i].show(false);
                }
            }

            function generateColSurrogate(col) {
                return {
                    show: function (value) {
                        if (vm.cols[col]) vm.cols[col].show(value);
                    }
                };
            }

            ngTableEventsChannel.onAfterReloadData(function () {
                checkHideColumns(0, true);
            });
        }
    }
})();