(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("contactNoteEditController", contactNoteEditController);

	function contactNoteEditController($scope, $mdDialog, name, text, afContext, afSourceType, afSourceId1, readOnly) {
		$scope.name = name;
		$scope.text = text;
		$scope.afContext = afContext;
		$scope.afSourceType = afSourceType;
		$scope.editorScope = [];
		if ($scope.afContext && $scope.afSourceType) {
			$scope.afSourceId1 = afSourceId1 || "123";
			$scope.afSourceId2 = "321";
		}
		$scope.readOnly = readOnly;

        $scope.setFormDirty = function (form) {
            form.$setDirty();
            $scope.$applyAsync();
        };

		$scope.save = function () {
			const text = $scope.editorScope.getHtml() || $scope.text;
			$mdDialog.hide(text);
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}

})();
