(function () {
    "use strict";

    // Processes touch commands on input controls. 
    
    // Usage Example:
    // <div st-touchstart="someFunction();" />

    // ReSharper disable once UndeclaredGlobalVariableUsing
    angular
		.module("smartertools")
		.directive("stTouchstart", stTouchStart)
		.directive("stTouchmove", stTouchmove)
		.directive("stTouchend", stTouchend)
		.directive("stTouchcancel", stTouchcancel);

    function stTouchStart() {
        return {
            restrict: 'A',
            controller: function ($scope, $element) {
                $element.bind("touchstart", onTouchStart);

                function onTouchStart(event) {
                    var method = $element.attr("st-touchstart");
                    $scope.$event = event;
                    $scope.$apply(method);
                }
            }
        };
    }

    function stTouchmove() {
        return {
            restrict: 'A',
            controller: function ($scope, $element) {
                $element.bind("touchstart", onTouchStart);

                function onTouchStart(event) {
                    event.preventDefault();
                    $element.bind("touchmove", onTouchMove);
                    $element.bind("touchend", onTouchEnd);
                }

                function onTouchMove(event) {
                    var method = $element.attr("st-touchmove");
                    $scope.$event = event;
                    $scope.$apply(method);
                }

                function onTouchEnd(event) {
                    event.preventDefault();
                    $element.unbind("touchmove", onTouchMove);
                    $element.unbind("touchend", onTouchEnd);
                }

            }
        };
    }

    function stTouchend() {
        return {
            restrict: 'A',
            controller: function ($scope, $element) {
                $element.bind("touchend", onTouchEnd);

                function onTouchEnd(event) {
                    var method = $element.attr("st-touchend");
                    $scope.$event = event;
                    $scope.$apply(method);
                }

            }
        };
    }

    function stTouchcancel() {
        return {
            restrict: 'A',
            controller: function ($scope, $element) {
                $element.bind("touchcancel", onTouchEnd);

                function onTouchEnd(event) {
                    var method = $element.attr("st-touchcancel");
                    $scope.$event = event;
                    $scope.$apply(method);
                }

            }
        };
	}

    // ReSharper disable once UndeclaredGlobalVariableUsing
    angular.module('smartertools')
		.directive('stLongTouch', ['$parse',
			function ($parse) {
				return {
					restrict: 'A',
					link: function ($scope, $elm, $attrs) {
						var handler = $parse($attrs['stLongTouch']);
						
						var touchTimer = null;
						var longTriggered = false;

						$elm.on('touchstart',
							function(e) {
								resetTimeout();
								if (e.originalEvent.touches.length > 1)
									return;
								touchTimer = setTimeout(function() {
										timeoutComplete(e);
									},
									750);
							});

						$elm.on('touchmove',
							resetTimeout);

						$elm.on('touchend',
							function(e) {
								if (longTriggered) {
									e.stopPropagation();
									e.preventDefault();
								}
								resetTimeout();
							});

						function resetTimeout() {
							if (touchTimer) {
								clearTimeout(touchTimer);
								touchTimer = null;
								longTriggered = false;
							}
						}

						function timeoutComplete(e) {
							longTriggered = true;
							touchTimer = null;
							$scope.$apply(function () {
								handler($scope, { $event: e });
							});
						}
					}
				};
			}
		]);
})();