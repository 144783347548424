(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("domainSharedResourcesController", domainSharedResourcesController);

	function domainSharedResourcesController($rootScope, $scope, $filter, $http, $mdDialog,
		coreDataDomainSettings, coreLicensing, coreDataCalendar, errorHandling) {
		var vm = this;

		vm.resources = [];
		vm.publicFolders = [];
		
		// User Groups properties
		vm.searchParamsUserGroups = { skip: 0, take: 0, search: null, sortField: null, sortDescending: false };
		vm.userList = [];

		// functions
		vm.editPublicFolder = editPublicFolder;
		vm.editResource = editResource;
		vm.newPublicFolder = newPublicFolder;
		vm.newResource = newResource;

		// User Groups functions
		vm.setClickedRow = setClickedRow;
		vm.newUserGroup = newUserGroup;
		vm.editUserGroup = editUserGroup;

		activate();
		
		/////////////////////

		function activate() {
			recalculateLicense();
			coreLicensing.watchForChanges($scope, recalculateLicense);
			
			loadUserGroups();
			loadResources();
			
			coreDataDomainSettings.settingsData
				.userList
				.then(function (results) { vm.userList = results || []; },
					function() {});
			
			$rootScope.$on("refreshUsersGrid", loadUserGroups);

			function recalculateLicense() {
			}
		}
		
		/*
		 * User Groups functions
		 */
		function editUserGroup(selectedCard, event) {
			$mdDialog
				.show({
					locals: {
						id: selectedCard.id,
						name: selectedCard.name,
						usernames: selectedCard.userNames
					},
					controller: "domainEditUserGroupController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/domain-settings/users/edit-user-group.dlg.html",
					targetEvent: event
				})
				.then(function (modalSuccess) {
					var params = JSON.stringify({
						userGroup: {
							name: modalSuccess.name,
							userNames: modalSuccess.grouped,
							id: selectedCard.id
						}
					});
					$http.post('~/api/v1/settings/domain/user-group', params).then(loadUserGroups, errorHandling.report);
				},
					function () { });
		}

		function loadUserGroups() {
			return $http.get("~/api/v1/settings/domain/all-user-groups")
				.then(
					function (results) {
						vm.userGroups = results.data.userGroupCollection.customUserGroups || [];
					},
					errorHandling.report);
		}
		
		function newUserGroup(event) {
			$mdDialog
				.show({
					locals: {
						id: undefined,
						name: undefined,
						usernames: []
					},
					controller: "domainEditUserGroupController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/domain-settings/users/edit-user-group.dlg.html",
					targetEvent: event
				})
				.then(
					function (modalSuccess) {
						var params = JSON.stringify({
							userGroup: {
								name: modalSuccess.name,
								userNames: modalSuccess.grouped,
								type: 2
							}
						});
						$http
							.post("~/api/v1/settings/domain/user-group-put", params)
							.then(loadUserGroups, errorHandling.report);
					},
					function () { });
		}

		function setClickedRow(event, row) {
			row._checked = !row._checked;
			if (row._checked)
				vm.selectedUsers.push(row);
			else {
				var index = vm.selectedUsers.indexOf(row);
				if (index !== -1)
					vm.selectedUsers.splice(index, 1);
			}
		}

		/*
		 * Sharing functions
		 */
		function deleteItem(item) {
			const confirm = $mdDialog.confirmDeletion()
				.textContent($filter("translate")("CONFIRMATIONS_DELETE_ITEMS", { items: 1 }))
				.targetEvent(event);
			$mdDialog.show(confirm).then(onDoRemove);

			function onDoRemove() {
				$http.post("~/api/v1/settings/domain/shared-resources/delete", JSON.stringify({ id: item.folderId }))
					.then(function() {
							loadResources();
							coreDataCalendar.reloadOnEnterCalendar = true;
							coreDataCalendar.resetSources();
						},
						errorHandling.report);
			}
		}

		function editPublicFolder(event, item) {
			$mdDialog
				.show({
					controller: "domainSharedResourceDialogController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/domain-settings/shared-resources/shared-resource.dlg.html",
					targetEvent: event,
					locals: { resource: item, isPublicFolder: true },
					userGroups: vm.userGroups
				})
				.then(
					function (modalSuccess) {
						if (modalSuccess.action === "delete") {
							deleteItem(item);
							return;
						}

						var editedResource = modalSuccess.resource;

						var params = JSON.stringify({
							folderId: item.folderId,
							folderName: editedResource.folderName
						});
						$http.post("~/api/v1/settings/domain/shared-resources/rename", params)
							.then(function () {

								params = JSON.stringify({
									folderId: item.folderId,
									accessUsers: editedResource.users,
									accessGroups: editedResource.userGroups
								});
								$http.post("~/api/v1/settings/domain/shared-resources/update-shares", params)
									.then(function () {
										loadResources();
									});
								coreDataCalendar.reloadOnEnterCalendar = true;
								coreDataCalendar.resetSources();
							}, errorHandling.report);
					},
					function () { }
				);
		}

		function editResource(event, item) {
			$mdDialog
				.show({
					controller: "domainSharedResourceDialogController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/domain-settings/shared-resources/shared-resource.dlg.html",
					targetEvent: event,
					locals: { resource: item, isPublicFolder: false },
					userGroups: vm.userGroups
				})
				.then(
					function (modalSuccess) {
						if (modalSuccess.action === "delete") {
							deleteItem(item);
							return;
						}

						var editedResource = modalSuccess.resource;

						var params = JSON.stringify({
							folderId: item.folderId,
							folderName: editedResource.folderName
						});
						$http.post("~/api/v1/settings/domain/shared-resources/rename", params)
							.then(function () {

								params = JSON.stringify({
									folderId: item.folderId,
									accessUsers: editedResource.users,
									accessGroups: editedResource.userGroups
								});
								$http.post("~/api/v1/settings/domain/shared-resources/update-shares", params)
									.then(function () {
										loadResources();
									});
								coreDataCalendar.reloadOnEnterCalendar = true;
								coreDataCalendar.resetSources();
							}, errorHandling.report);
					},
					function () { }
				);
		}

		function loadResources() {
			$http
				.get("~/api/v1/settings/domain/shared-resources/list")
				.then(function (success) {
					vm.resources = [];
					vm.publicFolders = [];

					const sharedResources = success.data.virtualFolders || [];
					for (let i = 0; i < sharedResources.length; i++) {
						const folder = sharedResources[i];

						if (folder.folderType !== 2 || folder.sharedResourceType === 1)
							vm.publicFolders.push(folder);
						else
							vm.resources.push(folder);
					}

				}, errorHandling.report);
		}
		
		function newPublicFolder(event) {
			$mdDialog
				.show({
					controller: "domainSharedResourceDialogController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/domain-settings/shared-resources/shared-resource.dlg.html",
					targetEvent: event,
					locals: { resource: { folderType: 2, sharedResourceType: 1 }, isPublicFolder: true },
					userGroups: vm.userGroups
				})
				.then(
					function (modalSuccess) {
						var newResource = modalSuccess.resource;
						var params = JSON.stringify({
							folderName: newResource.folderName,
							emailAlias: newResource.emailAlias,
							folderType: newResource.type.value,
							sharedResourceType: newResource.type.shared_resource_type,
							accessUsers: newResource.users,
							accessGroups: newResource.userGroups
						});
						$http
							.post("~/api/v1/settings/domain/shared-resources/create", params)
							.then(function () {
								coreDataCalendar.reloadOnEnterCalendar = true;
								coreDataCalendar.resetSources();
								loadResources();
							}, errorHandling.report);
					},
					function () { });
		}
		
		function newResource(event) {
			$mdDialog
				.show({
					controller: "domainSharedResourceDialogController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/domain-settings/shared-resources/shared-resource.dlg.html",
					targetEvent: event,
					locals: { resource: { folderType: 2, sharedResourceType: 2 }, isPublicFolder: false },
					userGroups: vm.userGroups
				})
				.then(
					function (modalSuccess) {
						var newResource = modalSuccess.resource;
						var params = JSON.stringify({
							folderName: newResource.folderName,
							emailAlias: newResource.emailAlias,
							folderType: newResource.type.value,
							sharedResourceType: newResource.type.shared_resource_type,
							accessUsers: newResource.users,
							accessGroups: newResource.userGroups
						});
						$http
							.post("~/api/v1/settings/domain/shared-resources/create", params)
							.then(function () {
								coreDataCalendar.reloadOnEnterCalendar = true;
								coreDataCalendar.resetSources();
								loadResources();
							}, errorHandling.report);
					},
					function () { });
		}
	}
})();