(function() {

    angular
        .module("smartertools")
        .filter('htmlToPlaintext', htmlToPlaintextFilter);

    function htmlToPlaintextFilter() {
        return function (text) {
            return text ? String(text).replace(/<br\/?>/gm, ' ').replace(/<p[^>]*>/gm, ' ').replace(/<[^>]+>/gm, '') : '';
        };
    }

})();