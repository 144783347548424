(function () {
	"use strict";

	angular
		.module("smartertools")
		.directive("validateAttachDomain", validateAttachDomain);

	function validateAttachDomain($http, $q, emailValidationService) {
		return {
			require: "ngModel",
			link: function (scope, element, attrs, ctrl) {
				ctrl.$asyncValidators.validateAttachDomain = function (modelValue, viewValue) {
					if (!viewValue) return $q.resolve({ success: true });

					var preCheck = window.stOS == 'L'
						? validateAttachDomainLinux(viewValue)
						: validateAttachDomainWindows(viewValue);
					if (!preCheck.isValid || !preCheck.domainName) {
						return $q.reject({ success: false });
					}

					var defer = $q.defer();
					$http
						.post("~/api/v1/settings/sysadmin/validate-domain-path", { input: viewValue })
						.then(
							async function (success) {
								if (!success.data.success) {
									defer.reject(success);
									return;
								}

								var result = await emailValidationService.isValidDomain(preCheck.domainName);
								if (result)
									defer.resolve({ success: true });
								else
									defer.reject({ success: false });
							},
							defer.reject);

					return defer.promise;
				};
			}
		};

		function validateAttachDomainLinux(viewValue) {
			// Ensure folder starts with / or ~/
			if (!/^(\/|~\/)/.test(viewValue))
				return { isValid: false };

			// Ensure folder does not only contain / or ~/
			if (/^(\/|~\/)$/.test(viewValue))
				return { isValid: false };

			var index = viewValue.lastIndexOf("/");
			if (index === -1 || index + 1 >= viewValue.length) {
				return { isValid: false };
			}

			var domainName = viewValue.substring(index + 1);
			return { isValid: true, domainName: domainName };
		}

		function validateAttachDomainWindows(viewValue) {
			// If the directory doesn't start with *:\ or \\
			if (!(/((^[a-zA-Z]:\\)|(^\\\\[^\\]))/).test(viewValue)) {
				//I dont know why $q.when(false) doesn't work.
				return { isValid: false };
			}
			//if the directory only contains *:\ or \\
			if ((/((^[a-zA-Z]:\\$)|(^\\\\[^\\]$))/).test(viewValue)) {
				return { isValid: false };
			}

			var index = viewValue.lastIndexOf("\\");
			if (index === -1 || index + 1 >= viewValue.length) {
				return { isValid: false };
			}

			var domainName = viewValue.substring(index + 1);
			return { isValid: true, domainName: domainName };
		}
	}

})();