(function () {
	"use strict";

	angular
		.module("smartertools")
		.directive("validateDomainName", validateDomainName);

	function validateDomainName($q, emailValidationService) {
		return {
			require: "ngModel",
			link: function (scope, element, attrs, ctrl) {
				ctrl.$asyncValidators.validateDomainName = async function (modelValue, viewValue) {
					var result = await emailValidationService.isValidDomain(viewValue);
					return result
						? $q.resolve({ success: true })
						: $q.reject({ success: false });
				};
			}
		};
	}
})();