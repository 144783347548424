(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("simpleHtmlViewController", simpleHtmlViewController);

	function simpleHtmlViewController($scope, $mdDialog, $timeout, headerText, htmlContent, context, readOnly, domainOverride) {
		$scope.editorScope = {};

		$scope.headerText = headerText;
		$scope.htmlContent = htmlContent;
		$scope.context = context;
		$scope.domainOverride = domainOverride;
		$scope.readOnly = readOnly;
		$scope.onFroalaInitialized = function () {
			$("st-html-editor textarea.froalaEditor")[0]["data-froala.editor"].events.focus();
		}

		$scope.save = function () {
			const htmlText = $scope.editorScope.getHtml();

			$timeout(function () {
				$mdDialog.hide(htmlText);
			});
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}

})();
