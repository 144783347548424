(function() {
	"use strict";

	angular
		.module("smartertools")
		.directive("validateAliasName", validateAliasName);

	function validateAliasName() {
		return {
			require: "ngModel",
			link: function (scope, element, attrs, ctrl) {
				// NOTE: If this validator changes also update validate-email-username.directive.js
                ctrl.$validators.validateAliasName = function (modelValue, viewValue) {
                    if (window.stOS == 'L') {
                       return !(/[@\*\<\>\:\/\|\"\?\'\\\s]/.test(viewValue));
                    }

                    var reservedWords = [
                        "WEB.CONFIG", "BIN", "APP_CODE", "APP_GLOBALRESOURCES", "APP_LOCALRESOURCES", "APP_WEBREFERENCES", "APP_DATA", "APP_BROWSERS",
                        "CON", "PRN", "AUX", "CLOCK$", "NUL",
                        "COM0", "COM1", "COM2", "COM3", "COM4", "COM5", "COM6", "COM7", "COM8", "COM9",
                        "LPT0", "LPT1", "LPT2", "LPT3", "LPT4", "LPT5", "LPT6", "LPT7", "LPT8", "LPT9"
                    ];
					if (!viewValue)
                        return true;
                    for (var i = 0; i < reservedWords.length; i++) {
                        var capValue = viewValue.toUpperCase();
                        if (reservedWords[i] === capValue)
                            return false;
                        if (capValue.indexOf(reservedWords[i] + ".") === 0)
                            return false;
                    }
					return !(/[@\*\<\>\:\/\|\"\?\'\\\s]/.test(viewValue));
				};
			}
		};
	}
})();
