(function () {
    "use strict";

    angular
        .module("smartermail")
        .directive("taskCard", taskCardDirective);

    function taskCardDirective() {
        return {
            restrict: "EA",
            templateUrl: "app/tasks/task-card.html",
            controller: function ($scope, $translate, $filter) {

                $scope.taskCard = {};
                $scope.taskCard.startString = $scope.task.start
                    ? ($filter("date")($scope.task.start, 'shortDate') + " " + $translate.instant("AT") + " " + $filter("date")($scope.task.start, 'shortTime'))
                    : $translate.instant("NONE");
                $scope.taskCard.dueString = $scope.task.due
                    ? ($filter("date")($scope.task.due, 'shortDate') + " " + $translate.instant("AT") + " " + $filter("date")($scope.task.due, 'shortTime'))
                    : $translate.instant("NONE");
                $scope.taskCard.percentCompletePercent = ($scope.task.percentComplete <= 100 ? $scope.task.percentComplete : 100) + '%';

                if ($scope.task.categories && $scope.task.categories.length) {
                    $scope.task.categories.forEach(cat => {
                        const translated = $translate.instant(cat.name);
                        cat.translatedName = $("<div>").html(translated).text(); // Translate HTML encodes the string, so we need to undo that
                    });
                }
            }
        };
    }

})();
