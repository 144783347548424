/*
 * USE EXAMPLE:
 * <!DOCTYPE html>
 * <html ng-app="bms.web" ng-cloak>
 * <head>
 *     <title page-title></title>
 *     <meta charset="utf-8" />
 *     <link rel="stylesheet" href="css/all.min.css" />
 *     <script src="js/output/all.js"></script>
 * </head>
 * <body layout="row" ng-controller="RootCtrl as rootCtrl">
 *     <st-side-menu-container template-url="app/menu/main-menu.html"></st-side-menu-container> <!-- stMenuContainer -->
 * 
 *     <div id="st-main-container" layout="column" class="relative" role="main" flex>
 *         <md-content>
 *             <ui-view></ui-view>
 *         </md-content>
 *         <div class="spinner-overlay" style="background-color: rgba(255, 255, 255, 0.5); z-index: 2000;" ng-show="spinner.isShown()">
 *             <span us-spinner="{color: '#000', radius: 42, corners: 1.0, width: 14, length: 28}" spinner-on="true"></span>
 *         </div>
 *     </div>
 * </body>
 * </html> 
 */

(function () {
	'use strict';

	angular
		.module('smartertools')
		.directive('stSideMenuContainer', stSideMenuContainer);

	function stSideMenuContainer() {
		return {
			restrict: 'E',
			transclude: true,
			scope: {
                templateUrl: '@',
                ngClass: '@'
			},
			template: '<div id="st-side-menu-container" ng-cloak ng-class="ngClass">' +
							'<div id="st-side-menu-content" ng-include="templateUrl"></div>' +
					   '</div>'
		};
	}
})();
