(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminSecurityEditCustomRuleController", sysAdminSecurityEditCustomRuleController);

	function sysAdminSecurityEditCustomRuleController($rootScope, $scope, $mdDialog, $http, errorHandling, ruleId) {

		activate();

		/////////////////////

		function activate() {
			$scope.rule = {
				name: '',
				source: 'Header',
				ruleArgument: '',
				matchType: 'Contains',
				weight: 0,
				matchMultiple: false,
				ruleItems: [],
				enableFiltering: false,
				enableOutgoingSmtpBlocking: false,
				new: true
			};
			$scope.ruleItemsString = "";

			if (ruleId) {
				$rootScope.spinner.show();
				$http
					.get("~/api/v1/settings/sysadmin/antispam/custom-rules/list")
					.then(onLoaded, errorHandling.report)
					.finally($rootScope.spinner.hide);
			}

			function onLoaded(result) {
				var items = result.data.items || [];
				var origRule = $.grep(items, function (i) { return i.id === ruleId; })[0];
				if (origRule) {
					$scope.rule = angular.copy(origRule);
					$scope.ruleItemsString = $scope.rule.ruleItems ? $scope.rule.ruleItems.join("\n") : "";
				}
			}
		}

		$scope.save = function (scope) {
			if (!($scope.isValid = scope.userForm.$valid)) {
				return;
			}

			var splitItems = $scope.ruleItemsString.split('\n');
			splitItems = $.grep(splitItems, function (item) {
				return item.length !== 0;
			});
			$scope.rule.ruleItems = splitItems;

			var params = { ItemsToUpdate: [$scope.rule] };
			$rootScope.spinner.show();
			$http
				.post('~/api/v1/settings/sysadmin/antispam/custom-rules/update', JSON.stringify(params))
				.then($mdDialog.hide, errorHandling.report)
				.finally($rootScope.spinner.hide);
		};

		$scope.delete = function () {
			var params = { ItemsToRemove: [$scope.rule.id] };
			$mdDialog.hide(params);
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};

		// Validation
		$scope.isValid = true;
	}

})();
