(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("adminSettingsProtocolSettingsController", adminSettingsProtocolSettingsController);

    function adminSettingsProtocolSettingsController($rootScope, $scope, $filter, $http, $state, $mdDialog, $q,
        coreDataLicensing, errorHandling, claimsService) {

        var vm = this;
        vm.isEnterprise = false;
        vm.isIpFailover = false;
        vm.secProtocols = "";
        vm.securitySystemDefaultsEnabled = true;
        vm.securitySsl3Enabled = false;
        vm.securityTls10Enabled = false;
        vm.securityTls11Enabled = false;
        vm.securityTls12Enabled = false;
        vm.securityTls13Enabled = false;

        // Functions
        vm.save = save;
        //vm.openIpList = openIpList;
        vm.ipListChanged = ipListChanged;
        vm.ipListRemove = ipListRemove;
        vm.doCancel = doCancel;
        vm.Licensing = {};

        activate();

        /////////////////

        function activate() {
            refresh();
            getLicenseType();
            $scope.$on("licensingChanged", getLicenseType);
        }

        function getLicenseType() {
            coreDataLicensing
                .init()
                .then(function () {
                    vm.Licensing = coreDataLicensing.Data.Licensing;
                    vm.isEnterprise = coreDataLicensing.Data.Licensing.enterpriseFunctionality;
                }, errorHandling.report);
        }

        function refresh() {

            $q.all([
                $http.get("~/api/v1/settings/sysadmin/global-mail"),
                $http.get("~/api/v1/settings/sysadmin/ips")
            ])
                .then(onGlobalMailSettingsLoaded);

            function onGlobalMailSettingsLoaded(results) {
                var result = results[0];
                var availableIps = results[1].data.ips;
                $scope.settings = angular.copy(result.data.globalMailSettings);
                $scope.localIPv4 = $scope.settings.localIPv4;
                $scope.localIPv6 = $scope.settings.localIPv6;

                vm.ipOptions = [
                    { address: 0, addressFamily: -1, translation: "USE_PRIMARY_IP_ON_NIC" },
                    { address: 2, addressFamily: -1, translation: "USE_THE_DOMAINS_IP" },
                    { address: 4, addressFamily: -1, translation: "ROTATE_IP_LIST" },
                    { address: 3, addressFamily: 23, translation: "DISABLE" }
                ];

                var failoverIp = $scope.settings.failoverIPAddress;
                if ($scope.settings.failoverEnabled && failoverIp && failoverIp !== "NONE") {
                    vm.isIpFailover = true;
                    vm.ipOptions = [{ address: 0, addressFamily: -1, translation: "HANDLED_VIA_FAILOVER" }];
                }

                vm.ipRotateOptions = [
                    { address: 0, addressFamily: -1, translation: "USE_PRIMARY_IP_ON_NIC" },
                    { address: 2, addressFamily: -1, translation: "USE_THE_DOMAINS_IP" }
                ];

                vm.ipOptions = vm.ipOptions.concat(availableIps);
                vm.ipRotateOptions = vm.ipRotateOptions.concat(availableIps);

                // POP
                vm.popBanner = $scope.settings.popSettings.popBanner;
                vm.popCommandTimeout = $scope.settings.popSettings.popCommandIdleTimeoutInMinutes;
                vm.popMaxBadCommands = $scope.settings.popSettings.popMaxBadCommands;
                vm.popMaxConnections = $scope.settings.popSettings.maxPopThreads;
                vm.popMaxRetrievalThreads = $scope.settings.messageRetrievalSettings.maxPopRetrievalThreads;
                vm.popRetrievalInterval = $scope.settings.messageRetrievalSettings.popRetrievalInterval;
                vm.popAuthLoginMethodForSmtpAuthenticationDisabled = $scope.settings.popSettings.popNoInsecureLogin;

                // IMAP
                vm.imapBanner = $scope.settings.imapSettings.imapBanner;
                vm.imapCommandTimeout = $scope.settings.imapSettings.imapCommandIdleTimeoutInMinutes;
                vm.imapMaxBadCommands = $scope.settings.imapSettings.imapMaxBadCommands;
                vm.imapMaxConnections = $scope.settings.imapSettings.imapConnections;
                vm.imapMaxRetrievalThreads = $scope.settings.messageRetrievalSettings.maxImapRetrievalThreads;
                vm.imapRetrievalInterval = $scope.settings.messageRetrievalSettings.imapRetrievalInterval;
                vm.imapIdleCommandEnabled = $scope.settings.imapSettings.imapIdle;
                vm.imapAuthLoginMethodForSmtpAuthenticationDisabled = $scope.settings.imapSettings.imapNoInsecureLogin;

                //EWS
                vm.exchangeMaxRetrievalThreads = $scope.settings.messageRetrievalSettings.exchangeMaxRetrievalThreads;
                vm.exchangeRetrievalInterval = $scope.settings.messageRetrievalSettings.exchangeRetrievalInterval;

                // LDAP
                vm.ldapSessionTimeout = $scope.settings.ldapSettings.ldap_SessionIdleTimeout;
                vm.ldapCommandTimeout = $scope.settings.ldapSettings.ldap_CommandIdleTimeout;

                // SMTP In
                vm.smtpInBanner = $scope.settings.smtpSettings.smtpBanner;
                vm.smtpInAllowRelay = $scope.settings.smtpSettings.relayOption;
                vm.smtpInSessionTimeout = $scope.settings.smtpSettings.smtpSessionTimeout;
                vm.smtpInSessionTimeoutEnabled = $scope.settings.smtpSettings.smtpSessionTimeoutEnabled;
                vm.smtpInCommandTimeout = $scope.settings.smtpSettings.smtpCommandIdleTimeout;
                vm.smtpInMaxBadCommands = $scope.settings.smtpSettings.smtpMaxBadCommands;
                vm.smtpInMaxConnections = $scope.settings.smtpSettings.maxSmtpThreads;
                vm.smtpInMaxHopCount = $scope.settings.smtpSettings.smtpMaxHopCount;
                vm.smtpInMaxMessageSize = parseInt($scope.settings.deliverySettings.maxMessageSizeInBytes / 1024, 10);
                vm.smtpInMaxMessageSizeCap = parseInt($scope.settings.deliverySettings.maxMessageSizeInBytesCap / 1024, 10);
                vm.smtpInMaxBadRecipients = $scope.settings.smtpSettings.maxBadConsecutiveRecipients;
                vm.smtpInAppendReceivedLine = $scope.settings.smtpSettings.recievedAppendOption;
                vm.smtpInRequireAuthMatch = getSmtpInRequireAuthMatch();
                vm.smtpInMaxMessagesPerSession = $scope.settings.smtpSettings.maxMessagesPerSession;
                vm.smtpInVrfyCommandEnabled = $scope.settings.smtpSettings.smtpEnableVrfy;
                vm.smtpInExpnCommandEnabled = $scope.settings.smtpSettings.smtpEnableExpn;
                vm.smtpInDsnEnabled = $scope.settings.smtpSettings.smtpEnableDsn;
                vm.smtpInAllowRelayForAuthenticatedUsersEnabled = $scope.settings.smtpSettings.smtpAuthBypassRelay;
                vm.smtpInDomainSmtpAuthSettingForLocalDeliveriesEnabled = $scope.settings.smtpSettings.enforceSMTPAuth;
                vm.smtpInAuthLoginMethodForSmtpAuthenticationDisabled = $scope.settings.smtpSettings.smtpNoAuthLogin;
                vm.smtpInAuthCram5MethodForSmtpAuthenticationDisabled = $scope.settings.smtpSettings.smtpNoCram5Login;
                vm.smtpInAcceptDisconnectedClients = $scope.settings.smtpSettings.smtpAcceptDisconnectedClients;

                // SMTP Out
                vm.smtpOutOutboundIpv4 = vm.isIpFailover ? 0 : getSmtpOutOutboundIp($scope.settings.deliverySettings.outboundDeliveryBinding, $scope.settings.deliverySettings.defaultOutboundDeliveryBinding);
                vm.outboundDeliveryIPList = createIpListFromSettings($scope.settings.deliverySettings.outboundDeliveryIPList);
                vm.smtpOutOutboundIpv6 = getSmtpOutOutboundIp($scope.settings.deliverySettings.outboundDeliveryBindingIPv6, $scope.settings.deliverySettings.defaultOutboundDeliveryBindingIPv6);
                vm.outboundDeliveryIPv6List = createIpListFromSettings($scope.settings.deliverySettings.outboundDeliveryIPv6List);
                vm.smtpOutPrimaryIpOnFailureEnabled = $scope.settings.deliverySettings.fallbackToPrimary;
                vm.outboundDeliveryFailureRatio = $scope.settings.deliverySettings.outboundDeliveryFailureRatio;
                vm.outboundDeliveryFailureThreshold = $scope.settings.deliverySettings.outboundDeliveryFailureThreshold;
                vm.smtpOutCommandTimeout = $scope.settings.deliverySettings.deliveryCommandTimeout;
                vm.smtpOutMaxDeliveryThreads = $scope.settings.deliverySettings.maxRelayThreads;
                vm.smtpOutMaxRecipientsPerSession = $scope.settings.deliverySettings.maxRecipientsPerSession;
                vm.smtpOutDnsCachingEnabled = $scope.settings.serverSettings.enableDnsCache;
                vm.smtpOutTlsIfSupportedByRemoteEnabled = $scope.settings.serverSettings.enableTlsIfAvailable;
                vm.smtpOutAppendAuthenticatedAsHeaderEnabled = $scope.settings.smtpSettings.authHeaderOnOut;
                vm.smptOutRemoteBouncesEnabled = $scope.settings.serverSettings.disableRemoteBounces;
                vm.smtpOutValidateAllCerts = $scope.settings.smtpSettings.validateAllCerts;
                vm.smtpOutRelaxedCertificateValidation = $scope.settings.smtpSettings.smtpOutRelaxedCertificateValidation;
                ipListChanged('ipv4');
                ipListChanged('ipv6');

                // XMPP
                vm.xmppMaxConnections = $scope.settings.xmppSettings.xmppConnections;

                // Security Protocols
                vm.securitySystemDefaultsEnabled = $scope.settings.securityProtocolsSettings.useSystemDefaults;
                vm.securitySsl3Enabled = $scope.settings.securityProtocolsSettings.useSsl3;
                vm.securityTls10Enabled = $scope.settings.securityProtocolsSettings.useTls10;
                vm.securityTls11Enabled = $scope.settings.securityProtocolsSettings.useTls11;
                vm.securityTls12Enabled = $scope.settings.securityProtocolsSettings.useTls12;
                vm.securityTls13Enabled = $scope.settings.securityProtocolsSettings.useTls13;

                // Mailbox Migrations
                vm.mailboxMigrationMaxThreads = $scope.settings.mailboxMigrationSettings.mailboxMigrationMaxThreads;

                vm.secProtocols =
                    vm.securitySystemDefaultsEnabled ||
                        vm.securitySsl3Enabled ||
                        vm.securityTls10Enabled ||
                        vm.securityTls11Enabled ||
                        vm.securityTls12Enabled ||
                        vm.securityTls13Enabled
                        ? "valid"
                        : "";
            }
        }

        function doCancel(ev, form) {
            if (!form.$dirty) return;
            $mdDialog
                .show($mdDialog.confirmRevertChanges().targetEvent(ev))
                .then(function () { refresh(); form.$setPristine(); }, function () { });
        }

        function getSmtpInRequireAuthMatch() {
            if ($scope.settings.serverSettings.requireSendAddressAuthMatch)
                return 1;
            else if ($scope.settings.serverSettings.requireSendDomainAuthMatch)
                return 2;
            else
                return 0;
        }

        function getSmtpOutOutboundIp(option, ip) {
            if (option === 1)
                return ip && ip.length > 0 ? ip : 0;
            else
                return option;
        }

        function createIpListFromSettings(list) {
            if (!list || list.length === 0) {
                list = [{
                    order: null,
                    ip: null
                }];
            } else {
                for (var i = 0; i < list.length; ++i) {
                    var item = list[i];
                    var temp = { order: item.order };
                    switch (item.type) {
                        case 3:
                        case 2:
                        case 0:
                            temp.ip = item.type;
                            break;
                        case 1:
                            temp.ip = item.ip;
                            break;
                        default:
                            temp.ip = 0;
                            break;
                    }
                    list[i] = temp;
                }
            }
            return list;
        }

        function ipListToSettings(list) {
            var newList = angular.copy(list);
            if (!newList) { return []; }
            for (var i = 0; i < newList.length; ++i) {
                var item = newList[i];
                var temp = { order: item.order };
                switch (item.ip) {
                    case 3:
                    case 2:
                    case 0:
                        temp.type = item.ip;
                        temp.ip = "";
                        break;
                    case 4:
                        temp.type = 0;
                        temp.ip = "";
                        break;
                    default:
                        temp.type = 1;
                        temp.ip = item.ip;
                        break;
                }
                newList[i] = temp;
            }
            return newList;
        }

        function getSelectedSmtpOutOutboundIpOption(option, getIP) {
            if (getIP) {
                switch (option) {
                    case 4:
                    case 3:
                    case 2:
                    case 0: return "";
                    default: return option;
                }
            }
            switch (option) {
                case 4:
                case 3:
                case 2:
                case 0: return option;
                default: return 1;
            }
        }

        function ipListChanged(type) {
            var listToUse = [];
            if (type === 'ipv4') {
                listToUse = vm.outboundDeliveryIPList;
            } else {
                listToUse = vm.outboundDeliveryIPv6List
            }
            if (listToUse.length === 0) {
                listToUse.push('');
            } else {
                if (listToUse.length > 0) {
                    var emptyAdds = $.grep(listToUse, function (add) { return (add == null || add.order == undefined || add.order == "" || add.ip == null); });
                    if (emptyAdds.length > 1) {
                        listToUse = $.grep(listToUse, function (add) { return (add != null && add.order != undefined && add.order != "" && add.ip != null); });
                        listToUse.push('');
                    } else if (emptyAdds.length === 0) {
                        listToUse.push('');
                    }
                }
            }
        }

        function ipListRemove(type, index, form) {
            var listToUse = [];
            if (type === 'ipv4') {
                listToUse = vm.outboundDeliveryIPList;
            } else {
                listToUse = vm.outboundDeliveryIPv6List;
            }
            listToUse.splice(index, 1);
            ipListChanged(type);
            form.$setDirty();
        }

        function save(ev, form) {
            if (form.$invalid) {
                errorHandling.report("CORRECT_INVALID_FIELDS");
                return;
            }

            vm.outboundDeliveryIPList = $.grep(vm.outboundDeliveryIPList, function (add) { return (add != null && add.order != undefined && add.order != "" && add.ip != null); });
            vm.outboundDeliveryIPv6List = $.grep(vm.outboundDeliveryIPv6List, function (add) { return (add != null && add.order != undefined && add.order != "" && add.ip != null); });
            if ((vm.smtpOutOutboundIpv4 === 4 && (!vm.outboundDeliveryIPList || vm.outboundDeliveryIPList.length === 0))
                || (vm.smtpOutOutboundIpv6 === 4) && (!vm.outboundDeliveryIPv6List || vm.outboundDeliveryIPv6List.length === 0)) {
                errorHandling.report("LIST_ONE_IP_REQUIRED");
                ipListChanged('ipv4');
                ipListChanged('ipv6');
                return;
            }

            var params = {
                globalMailSettings: {
                    popSettings: {
                        popBanner: vm.popBanner,
                        popCommandIdleTimeoutInMinutes: vm.popCommandTimeout,
                        popMaxBadCommands: vm.popMaxBadCommands,
                        maxPopThreads: vm.popMaxConnections,
                        popNoInsecureLogin: vm.popAuthLoginMethodForSmtpAuthenticationDisabled,
                    },
                    imapSettings: {
                        imapBanner: vm.imapBanner,
                        imapCommandIdleTimeoutInMinutes: vm.imapCommandTimeout,
                        imapMaxBadCommands: vm.imapMaxBadCommands,
                        imapConnections: vm.imapMaxConnections,
                        imapIdle: vm.imapIdleCommandEnabled,
                        imapNoInsecureLogin: vm.imapAuthLoginMethodForSmtpAuthenticationDisabled,
                    },
                    ldapSettings: {
                        ldap_SessionIdleTimeout: vm.ldapSessionTimeout,
                        ldap_CommandIdleTimeout: vm.ldapCommandTimeout
                    },
                    smtpSettings: {
                        smtpBanner: vm.smtpInBanner,
                        relayOption: vm.smtpInAllowRelay,
                        smtpSessionTimeout: vm.smtpInSessionTimeout,
                        smtpSessionTimeoutEnabled: vm.smtpInSessionTimeoutEnabled,
                        smtpCommandIdleTimeout: vm.smtpInCommandTimeout,
                        smtpMaxBadCommands: vm.smtpInMaxBadCommands,
                        maxSmtpThreads: vm.smtpInMaxConnections,
                        smtpMaxHopCount: vm.smtpInMaxHopCount,
                        maxBadConsecutiveRecipients: vm.smtpInMaxBadRecipients,
                        recievedAppendOption: vm.smtpInAppendReceivedLine,
                        maxMessagesPerSession: vm.smtpInMaxMessagesPerSession,
                        smtpEnableVrfy: vm.smtpInVrfyCommandEnabled,
                        smtpEnableExpn: vm.smtpInExpnCommandEnabled,
                        smtpEnableDsn: vm.smtpInDsnEnabled,
                        smtpAuthBypassRelay: vm.smtpInAllowRelayForAuthenticatedUsersEnabled,
                        enforceSMTPAuth: vm.smtpInDomainSmtpAuthSettingForLocalDeliveriesEnabled,
                        smtpNoAuthLogin: vm.smtpInAuthLoginMethodForSmtpAuthenticationDisabled,
                        smtpNoCram5Login: vm.smtpInAuthCram5MethodForSmtpAuthenticationDisabled,
                        smtpAcceptDisconnectedClients: vm.smtpInAcceptDisconnectedClients,
                        authHeaderOnOut: vm.smtpOutAppendAuthenticatedAsHeaderEnabled,
                        validateAllCerts: vm.smtpOutValidateAllCerts,
                        smtpOutRelaxedCertificateValidation: vm.smtpOutRelaxedCertificateValidation
                    },
                    mailboxMigrationSettings: {
                        mailboxMigrationMaxThreads: vm.mailboxMigrationMaxThreads
                    },
                    xmppSettings: {
                        xmppConnections: vm.xmppMaxConnections
                    },
                    securityProtocolsSettings: {
                        useSystemDefaults: vm.securitySystemDefaultsEnabled,
                        useSsl3: !vm.securitySystemDefaultsEnabled ? vm.securitySsl3Enabled : false,
                        useTls10: !vm.securitySystemDefaultsEnabled ? vm.securityTls10Enabled : false,
                        useTls11: !vm.securitySystemDefaultsEnabled ? vm.securityTls11Enabled : false,
                        useTls12: !vm.securitySystemDefaultsEnabled ? vm.securityTls12Enabled : false,
                        useTls13: !vm.securitySystemDefaultsEnabled ? vm.securityTls13Enabled : false
                    },
                    messageRetrievalSettings: {
                        exchangeMaxRetrievalThreads: vm.exchangeMaxRetrievalThreads,
                        exchangeRetrievalInterval: vm.exchangeRetrievalInterval,
                        maxPopRetrievalThreads: vm.popMaxRetrievalThreads,
                        popRetrievalInterval: vm.popRetrievalInterval,
                        maxImapRetrievalThreads: vm.imapMaxRetrievalThreads,
                        imapRetrievalInterval: vm.imapRetrievalInterval
                    },
                    serverSettings: {
                        requireSendAddressAuthMatch: vm.smtpInRequireAuthMatch === 1,
                        requireSendDomainAuthMatch: vm.smtpInRequireAuthMatch === 2,
                        enableDnsCache: vm.smtpOutDnsCachingEnabled,
                        enableTlsIfAvailable: vm.smtpOutTlsIfSupportedByRemoteEnabled,
                        disableRemoteBounces: vm.smptOutRemoteBouncesEnabled
                    },
                    deliverySettings: {
                        maxMessageSizeInBytes: vm.smtpInMaxMessageSize * 1024,
                        outboundDeliveryBinding: getSelectedSmtpOutOutboundIpOption(vm.smtpOutOutboundIpv4),
                        defaultOutboundDeliveryBinding: getSelectedSmtpOutOutboundIpOption(vm.smtpOutOutboundIpv4, true),
                        outboundDeliveryIPList: ipListToSettings(vm.outboundDeliveryIPList),
                        outboundDeliveryBindingIPv6: getSelectedSmtpOutOutboundIpOption(vm.smtpOutOutboundIpv6),
                        outboundDeliveryIPv6List: ipListToSettings(vm.outboundDeliveryIPv6List),
                        defaultOutboundDeliveryBindingIPv6: getSelectedSmtpOutOutboundIpOption(vm.smtpOutOutboundIpv6, true),
                        outboundDeliveryFailureRatio: vm.outboundDeliveryFailureRatio,
                        outboundDeliveryFailureThreshold: vm.outboundDeliveryFailureThreshold,
                        fallbackToPrimary: vm.smtpOutPrimaryIpOnFailureEnabled,
                        deliveryCommandTimeout: vm.smtpOutCommandTimeout,
                        maxRelayThreads: vm.smtpOutMaxDeliveryThreads,
                        maxRecipientsPerSession: vm.smtpOutMaxRecipientsPerSession
                    }
                }
            };

            $rootScope.spinner.show();
            $http
                .post("~/api/v1/settings/sysadmin/global-mail/", params)
                .then(onSaveSuccess, errorHandling.report)
                .finally($rootScope.spinner.hide);


            function onSaveSuccess() {
                ipListChanged('ipv4');
                ipListChanged('ipv6');
                form.$setPristine();
            }
        }

        $scope.$watchCollection(function () {
            return [
                vm.securitySystemDefaultsEnabled,
                vm.securitySsl3Enabled,
                vm.securityTls10Enabled,
                vm.securityTls11Enabled,
                vm.securityTls12Enabled,
                vm.securityTls13Enabled
            ];
        },
            function (newValue, oldValue) {
                if (newValue === oldValue) return;
                vm.secProtocols = vm.securitySystemDefaultsEnabled ||
                    vm.securitySsl3Enabled ||
                    vm.securityTls10Enabled ||
                    vm.securityTls11Enabled ||
                    vm.securityTls12Enabled ||
                    vm.securityTls13Enabled
                    ? "valid"
                    : "";
            });
    }

})();
