(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("systemIpAddressModalController", systemIpAddressModalController);

	function systemIpAddressModalController($scope, $mdDialog, $timeout, $http, $filter, info, errorHandling) {
		var bindingInfo = angular.copy(info);
		$scope.ipType = bindingInfo.ipType;
		$scope.ipAddress = bindingInfo.ipAddress;
		$scope.description = bindingInfo.description;
		$scope.portIDs = bindingInfo.portIDs || [];
        $scope.ports = bindingInfo.ports || [];
        $scope.hostName = bindingInfo.hostName;
		$scope.noRepeatedPorts = 0;
		$scope.selectAll = isAllSelected();
		$scope.canDelete = bindingInfo.canDelete;
	    // Functions
		$scope.save = save;
		$scope.cancel = cancel;
		$scope.checked = checked;
		$scope.deleteItem = deleteItem;
		activate();

	    /////////////////////

		function activate() {
			$scope.ports = $filter("orderBy")($scope.ports, "port");
		    onSelectionChanged();
		}

		function onSelectionChanged() {
			$scope.noRepeatedPorts = 0;
			var usedPorts = [];
			for (var i = 0; i < $scope.ports.length; ++i) {
				if ($scope.ports[i].isSelected) {
					var key = $scope.ports[i].port;
					if (usedPorts.indexOf(key) > -1) {
						$scope.noRepeatedPorts = -1;
						return;
					} else
						usedPorts.push(key);
				}
			}
		}

		function save() {
			var portIDs = [];
			for (var i = 0; i < $scope.ports.length; ++i) {
				if ($scope.ports[i].isSelected)
					portIDs.push($scope.ports[i].id);
			}

			var params = JSON.stringify({
				bindingInfo: [{
					ipType: info.ipType,
					ipAddress: info.ipAddress,
					description: $scope.description,
                    portIDs: portIDs,
                    hostName: $scope.hostName
				}]
			});
			
			$http
				.post("~/api/v1/settings/sysadmin/ip-binding-info", params)
				.then(onSaveSuccess, errorHandling.report);

			function onSaveSuccess() {
				$mdDialog.hide();
			}
		};
		function deleteItem() {
			if (!$scope.canDelete) return;
			$mdDialog.hide({delete: true});
		}
		function cancel() {
			$mdDialog.cancel();
		};

		function isAllSelected() {
			var count = 0;
			for (var i = 0; i < $scope.ports.length; ++i) {
				if ($scope.ports[i].isSelected)
					++count;
			}
			return count === $scope.ports.length;
		}

		var lastChecked;
		function checked(ev, port) {
			document.getSelection().removeAllRanges();

			// If a shift select is occurring
			if (lastChecked && port.id !== lastChecked && event.shiftKey) {
				var setChecked = port.isSelected;
				var checking = false;
				for (var i = 0; i < $scope.ports.length; i++) {
					var portToCheck = $scope.ports[i];
					if (checking) {
						if (portToCheck.id !== port.id) portToCheck.isSelected = setChecked;
						// If we reached the end of the shift selection
						if (portToCheck.id === lastChecked || portToCheck.id === port.id) break;
					} else if (portToCheck.id === lastChecked || portToCheck.id === port.id) { // We reached the beginning of the shift selection
						if (portToCheck.id !== port.id) portToCheck.isSelected = setChecked;

						checking = true;
					}
				}
			}

			lastChecked = port.id;
			onSelectionChanged();
		}
	}

})();
