(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("createCalendarFolderController", createCalendarFolderController);

    function createCalendarFolderController($scope, $mdDialog, $http, coreDataSettings, coreDataCalendar, coreData, errorHandling) {

        var vm = this;
        vm.name = "";
        vm.color = coreData.nextCalendarColor();

        // Functions
        vm.save = save;
        vm.cancel = cancel;

        // Setup
        activate();

        //////////////////////////////////////

        function activate() {
        }

        function cancel() {
            $mdDialog.cancel();
        }

        async function save(ev, userForm) {
            if (vm.name === "")
                return;
            if (!coreDataSettings.validateHexSixColor(vm.color))
                return;
            if (userForm.$invalid)
                return;

            try {
                var params = {
                    setting: {
                        friendlyName: vm.name,
                        calendarViewColor: vm.color
                    }
                };

                let success = await $http.post("~/api/v1/calendars/calendar-put", JSON.stringify(params));
                var newSource = {
                    color: vm.color,
                    id: success.data.result,
                    isCalendar: true,
                    isResource: false,
                    isSharedItem: false,
                    isTask: false,
                    isVisible: true,
                    name: vm.name,
                    owner: coreData.user.username,
                    permission: 8,
                    allowWebcalSubscription: vm.allowWebcalSubscription
                };

                await coreDataCalendar.addCalendarSource(newSource);
                $mdDialog.hide({ modified: true });
            }
            catch (failure) {
                errorHandling.report(failure);
            }
        }
    }
})();
