(function () {
    "use strict";

    angular
        .module("smartertools")
        .directive("validateFolderName", validateFolderName);

    function validateFolderName() {
        return {
            require: "ngModel",
            link: function (scope, element, attrs, ctrl) {
                ctrl.$validators.invalidFolderCharacters = function (modelValue, viewValue) {
                    if (!viewValue) {
                        return true;
                    }

                    return invalidFolderCharacters(viewValue);
                };
                ctrl.$validators.reservedFolderName = function (modelValue, viewValue) {
                    if (!viewValue) {
                        return true;
                    }

                    return reservedFolderName(viewValue);
                };
            }
        };

        function invalidFolderCharacters(viewValue) {
            // Just disallow all problematic characters, regardless of server OS.
            // This will eliminate problems should the server be migrated from one OS to another.

            // Disallow starting or ending dot
            if (viewValue.startsWith(".") || viewValue.endsWith("."))
                return false;
                
            // Check for invalid characters
            var regex = /[:/\\*?<>|"\u0000]/;
            if (regex.test(viewValue))
                return false;

            // Check for invalid upfolder references
            regex = /\.\./g;
            if (regex.test(viewValue))
                return false;

            return true;
        }

        function reservedFolderName(viewValue) {
            // Check for invalid folder names
            var regex = /(^|[\\/])(con|com\d?|prn|aux|nul|lpt\d)($|[\\/])/i;
            var splits = viewValue.split("/");
            for (var i = 0; i < splits.length; ++splits) {
                var s = splits[i];
                if (s.indexOf("." > -1))
                    s = splits[i].split(".")[0].replace(/^\s+|\s+$/g, "");
                if (regex.test(s))
                    return false;
            }
            return true;
        }
    }
})();