(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminSettingsEventsSectionController", sysAdminSettingsEventsSectionController);

	function sysAdminSettingsEventsSectionController($rootScope, $scope, $state, $filter, $stateParams, $http, $mdDialog, $compile,
		coreDataSysAdminCards, errorHandling, eventData, NgTableParams, gridCheckboxes, claimsService) {
		var vm = this;
		vm.eventData = eventData;
		vm.tableParams = new NgTableParams(
			{
				sorting: { name: "asc" },
				count: 25
			}, {
				getData: queryData,
				counts: $rootScope.commonTableCounts
			});
		vm.checkboxes = gridCheckboxes.init("id");
		vm.checkboxes.table = vm.tableParams;
		vm.isInitialized = false;
		$scope.page = $stateParams.page;
		$scope.cardService = coreDataSysAdminCards;

		// Functions
		vm.deleteEvents = deleteEvents;
		vm.editEvent = editEvent;
		vm.contextMenuGridItem = contextMenuGridItem;
		vm.newEvent = newEvent;
		vm.searchItems = function () { vm.tableParams.reload(); }
		vm.checkboxes.ignoreItemDelegate = function (item) { return item.eventGroup === "@DefaultEventGroupStatic"; }

		activate();

		///////////////////////

		function activate() {
			$scope.cardService.editItem = vm.editEvent;
			$scope.cardService.newItem = vm.newEvent;
			$scope.cardService.deleteItems = vm.deleteEvents;

			vm.tableParams.reload();
		}

		function queryData(params) {
			var sortDescending = false;
			var sortType = "";
			for (var k in params.sorting()) {
				if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
					continue;
				sortDescending = params.sorting()[k] === "desc";
				sortType = k;
				break;
			}

			// TODO:: Pass in sortField and sortDescending to api, api also needs to accomodate this
			var postParams = {
				"startIndex": (params.page() - 1) * params.count(),
				"count": params.count(),
				"name": vm.searchText,
				"sortDescending": sortDescending
			}

			return $http
				.post("~/api/v1/settings/sysadmin/event-hooks-by-owner", JSON.stringify(postParams))
				.then(function (result) {
					params.total(result.data.total);
                    result.data.eventHooks.forEach(function(x) {
                        x.whenType = vm.eventData.getEventWhenString(x);
                    });
                    result.data.eventHooks = $filter('orderBy')(result.data.eventHooks, sortType, sortDescending);
					vm.currentPageResults = result.data.eventHooks;
					
					return result.data.eventHooks;
				}, function () {});
		}

		function editEvent(selectedCard, ev) {
			$state.go("index.sysadmin-settings.events.specific", { id: selectedCard.id });
		}

		function newEvent(ev) {
			$state.go("index.sysadmin-settings.events.specific", { id: "new" });
		}

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== "touchstart" && ev.type !== "touchend" && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === item.id; })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item.id];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: "deleteEvents", click: deleteEvents, translateKey: "DELETE" }
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $("#context-menu-area");
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		function deleteEvents(selectedCards, ev) {
			var cards = vm.checkboxes.getCheckedItems();
			if (!cards || !cards.length)
				return;
			if (!angular.isArray(cards))
				return;

			var confirm = $mdDialog.confirmDeletion()
				.textContent($filter("translate")("CONFIRMATIONS_DELETE_ITEMS", { items: cards.length }))
				.targetEvent(ev);
			$mdDialog
				.show(confirm)
				.then(doDelete, function () { });

			function doDelete() {
				var params = JSON.stringify({ input: cards });
				$http.post("~/api/v1/settings/sysadmin/event-hook-delete", params)
					.then(function () {
						vm.checkboxes.reset();
						vm.tableParams.reload();
					}, errorHandling.report);
			}
		}
	}

})();
