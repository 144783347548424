(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("adminIdsBlocksController", adminIdsBlocksController);

	function adminIdsBlocksController($scope, $rootScope, $filter, $http, $localStorage, $mdDialog, $translate, $compile,
		errorHandling, successHandling, NgTableParams, gridCheckboxes) {
		var vm = this;

		vm.gridColumns = [
			{ sortField: 'ip', translate: 'IP_ADDRESS' },
			{ sortField: 'ipLocation', translate: 'COUNTRY' },
			{ sortField: 'protocol', translate: 'PROTOCOL' },
			{ sortField: 'blockType', translate: 'TYPE' },
			{ sortField: 'ruleDescription', translate: 'RULE' },
			{ sortField: "last30Days", translate: "IDS_BLOCKS_30_DAYS"}
		];
		vm.queryUrl = "~/api/v1/settings/sysadmin/blocked-ips";
		vm.searchText = '';
		vm.searchParams = { startindex: 0, count: 0, search: '', sortType: null, ascending: false };
		vm.tableParams = new NgTableParams(
			{
				sorting: { 1: 'asc' },
				count: 25
			},
			{
				getData: queryData,
				counts: $rootScope.commonTableCounts
			});
		vm.checkboxes = gridCheckboxes.init();
		vm.checkboxes.table = vm.tableParams;
		vm.checkboxes.specialKey = function (item) { return item.ip + '|' + item.protocol; };

		// Functions
		vm.contextMenuGridItem = contextMenuGridItem;
		vm.copyIp = copyToClipboard;
		vm.deleteIds = deleteIds;
		vm.refresh = refresh;
		vm.searchItems = searchItems;

		activate();

		var refreshInterval = setInterval(function () {
			vm.tableParams.data.forEach(function (item) {
				item.secondsLeftOnBlock -= 1;
				if (item.secondsLeftOnBlock < 0)
					item.secondsLeftOnBlock = 0;
			});
			$scope.$applyAsync();
		}, 1000);

		$scope.$on("$destroy", function () {
			clearInterval(refreshInterval);
		});

		//////////////////////////////////////////////////////////////

		function activate() {
			vm.queryUrl = "~/api/v1/settings/sysadmin/blocked-ips";
			vm.searchParams = { startindex: 0, count: 0, sortType: null, search: '', ascending: false };
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}
		
		function deleteIdsDropdown(params, ev) {
			deleteIds(ev, params.blacklist);
		}

		function deleteIds(ev, blacklist) {
			var blocks = [];
			if (vm.checkboxes.items) {
				for (var key in vm.checkboxes.items) {
					var split = key.split('|');
					var foundBlocks = $.grep(vm.searchResults, function (r) { return r.ip === split[0]; });
					blocks = blocks.concat(foundBlocks);
				}
			}

			var oldBlockCount = blocks.length;
			if (blacklist) {
				var ipValidRegex = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;
				blocks = blocks.filter(function (block) {
					return ipValidRegex.test(block.ip);
				});

				if (blocks.length === 0) {
					if (blocks.length !== oldBlockCount) {
						errorHandling.report("WARN_SOME_ITEMS_NOT_BLACKLISTED");
						return;
					}
					else
						errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED"); return;
				}
			} else {
				if (blocks.length === 0) {
					errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED");
					return;
				}
			}
			
			var confirm = $mdDialog.confirmDeletion()
				.textContent($filter('translate')((blacklist ? 'CONFIRMATIONS_BLACKLIST_ITEMS' : 'CONFIRMATIONS_DELETE_ITEMS'), { items: oldBlockCount }))
				.targetEvent(ev);

			if (blacklist) {
				confirm = $mdDialog.confirmWithOption()
					.title($filter("translate")("CONFIRMATION_REQUIRED"))
					.textContent($translate.instant("CONFIRMATIONS_BLACKLIST_ITEMS", { items: 1 }))
					.optionText($translate.instant("BLACKLIST_ENTIRE_CLASS_C_IP_RANGE"))
					.cancel($filter("translate")("CANCEL"))
					.ok($filter("translate")("BLOCK"));
			}

			$mdDialog
				.show(confirm)
				.then(function (option) {
					var params = [];
					if (blacklist)
						params = JSON.stringify({ ipBlocks: blocks, blockClassCRange: option });
					else
						params = JSON.stringify({ ipBlocks: blocks, });

					$http
						.post('~/api/v1/settings/sysadmin/unblock-ips' + (blacklist ? '/1' : ''), params)
						.then(function (result) {
							if (blacklist && blocks.length > 0 && blocks.length !== oldBlockCount) {
								errorHandling.warn("WARN_SOME_ITEMS_NOT_BLACKLISTED");
								refresh(true);
							}
							else {
								if (result.data && result.data.success && result.data.message)
									errorHandling.warn(result.data.message);
								refresh();
							}
						}, errorHandling.report);
				}, function () { });
		}

		function refresh(noSuccess) {
			vm.checkboxes.reset();
			vm.tableParams
				.reload()
				.then(function() {}, function() {})
				.finally(function () { if (!noSuccess) successHandling.report("ACTION_SUCCEEDED"); });
		}

		function searchItems() {
			vm.searchParams.search = vm.searchText;
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}

		function queryData(params) {
			vm.searchParams.startindex = (params.page() - 1) * params.count();
			vm.searchParams.count = params.count();

			vm.searchParams.sortType = null;
			for (var k in params.sorting()) {
				if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
					continue;
				vm.searchParams.sortType = k;
				vm.searchParams.ascending = params.sorting()[k] !== 'desc';
				break;
			}

			$rootScope.spinner.show();
			return $http
				.post(vm.queryUrl, JSON.stringify(vm.searchParams))
				.then(onSearchSuccess)
				.finally($rootScope.spinner.hide);

			function onSearchSuccess(result) {
				vm.searchResults = result.data.ipBlocks;
				vm.searchResultCount = result.data.count;
				angular.forEach(vm.searchResults, function (block) {
					switch (block.blockType) {
						default:
						case 0:
							block.blockTypeTranslate = "UNDEFINED";
							break;
						case 1:
							block.blockTypeTranslate = "IDS_BLOCKS_TYPES_HARVESTING";
							break;
						case 2:
							block.blockTypeTranslate = "ABUSE_DETECTION_DETECTION_TYPE_3";
							break;
						case 3:
							block.blockTypeTranslate = "IDS_BLOCKS_TYPES_DOS";
							break;
						case 4:
							block.blockTypeTranslate = "IDS_BLOCKS_BOUNCES_INDICATE_SPAMMER";
							break;
						case 5:
							block.blockTypeTranslate = "INTERNAL_SPAMMER";
							break;
						case 6:
							block.blockTypeTranslate = "ABUSE_DETECTION_DETECTION_TYPE_6";
							break;
						case 7:
							block.blockTypeTranslate = "ABUSE_DETECTION_DETECTION_TYPE_5";
							break;
					}

					switch(block.blockAction) {
						default:
						case 0:
							block.blockActionTranslate = "BLOCK";
							break;
						case 1:
							block.blockActionTranslate = "QUARANTINE";
							break;
					}

					block.ipLocationTranslate = !block.ipLocation ? "NA" : block.ipLocation;
				});

				params.total(vm.searchResultCount);
				return vm.searchResults;
			}
		}

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === vm.checkboxes.specialKey(item) })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [vm.checkboxes.specialKey(item)];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: 'unblock', click: deleteIdsDropdown, params: { ev: $scope.dropdownEvent, blacklist: false }, translateKey: 'UNBLOCK' },
				{ key: 'blacklist', click: deleteIdsDropdown, params: { ev: $scope.dropdownEvent, blacklist: true }, translateKey: 'BLACKLIST' },
				{ divider: true },
				{ key: 'copySource', click: copyToClipboard, params: { ev: $scope.dropdownEvent, item: item }, translateKey: "COPY_SOURCE" }
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $('#context-menu-area');
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		function copyToClipboard(params) {
			if (!params || !params.item || !params.item.ip)
				return;

			var textArea = document.createElement("textarea");
			textArea.value = params.item.ip;
			document.body.appendChild(textArea);
			textArea.focus();
			textArea.select();
			document.execCommand("copy");
			document.body.removeChild(textArea);
			successHandling.report("COPIED_TO_CLIPBOARD");
		}
	}
})();
