(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("editTaskFolderController", editTaskFolderController);

    function editTaskFolderController($scope, $mdDialog, $filter, $translate, $http,
        source, isCalendarView, coreData, coreDataSettings, userDataService, errorHandling) {

        var vm = this;
        vm.newItem = !source;
        vm.isCalendarView = isCalendarView || false;
        vm.owner = source ? (source.owner ? source.owner : userDataService.user.domain) : userDataService.user.username;
        vm.isOwner = vm.owner.toLowerCase() === userDataService.user.username.toLowerCase();
        vm.isShareable = vm.isOwner;
        vm.name = source
            ? (source.displayName ? $translate.instant(source.displayName) : source.name)
            : "";
        vm.originalName = vm.name;
        vm.isPrimary = source ? source.isPrimary : false;
        vm.origIsPrimary = vm.isPrimary;
        vm.permission = source ? source.access || source.permission : 8;
        vm.access = $filter("translate")("MAPPED_RESOURCES_ACCESS_" + vm.permission);
        vm.id = source ? (source.id || source.itemID) : null;
        vm.type = source ? source.shareType || "Tasks" : "Tasks";
        vm.galEnabled = coreData.user.settings.features.enableGlobalAddressList;
        vm.selectedItem = [];
        vm.color = source ? source.color : "#C6DE70";
        vm.ignoreNameWatch = true;
        vm.isNameValid = true;

        // Functions
        vm.queryUsers = coreDataSettings.queryUsers;
        vm.save = save;
        vm.delete = deleteTaskFolder;
        vm.detach = detach;
        vm.cancel = cancel;
        vm.onEmailChanged = onEmailChanged;
        vm.onEmailBlurred = onEmailBlurred;
        vm.removeUser = removeUser;
        vm.onGroupChanged = onGroupChanged;
        vm.removeGroup = removeGroup;
        vm.isValid = () => vm.isNameValid;

        activate();

        //////////////////

        function activate() {
            sharingInit();
            $scope.$watch("name", onNameChanged);
        }

        function cancel() {
            $mdDialog.cancel();
        }

        function sharingInit() {
            if (!vm.isShareable && vm.id !== 0)
                return;

            vm.userPermissions = [];
            vm.groupPermissions = [];
            vm.userGroups = [];
            vm.originalShareUsers = [];

            if (!source) {
                vm.userPermissions.push({ access: 4, user: { emailAddress: "" } });
                loadUserGroupList();
                return;
            }

            $http.post("~/api/v1/settings/share-users/", { sharingItem: vm.type, shareID: vm.id })
                .then(function (success) {
                    vm.originalShareUsers = $.extend(true, [], success.data.shareUsers);
                    angular.forEach(success.data.shareUsers, function (share) {
                        if (share.user)
                            vm.userPermissions.push(share);
                        else
                            vm.groupPermissions.push(share);
                    });
                    vm.userPermissions.push({ access: 4, user: { emailAddress: "" } });

                    loadUserGroupList();
                }, errorHandling.report);
        }

        function onNameChanged() {
            if (!vm.ignoreNameWatch) {
                if (vm.name === "") {
                    vm.isNameValid = false;
                } else {
                    vm.isNameValid = true;
                }
            } else {
                vm.ignoreNameWatch = false;
            }
        }

        async function deleteTaskFolder(ev) {
            if (vm.isPrimary)
                return;

            try {
                const confirm = $mdDialog.confirmDeletion()
                    .htmlContent($filter("translate")("CONFIRMATIONS_DELETE_FOLDER", { name: vm.name }))
                    .targetEvent(ev);
                await $mdDialog.show(confirm);
            }
            catch (err) { return; }

            try {
                const folder = {
                    folder: vm.name,
                    uid: vm.id
                };
                await $http.post("~/api/v1/tasks/sources/delete", folder);
                $mdDialog.hide();
            }
            catch (err) { errorHandling.report(err); }
        }

        async function detach() {
            try {
                var shareDetails = {
                    ownerUsername: source.owner,
                    shareType: vm.type,
                    itemID: vm.id
                };
                await $http.post("~/api/v1/settings/disconnect-from-share", JSON.stringify({ shareConnectorData: [shareDetails] }));
                $mdDialog.hide({ detached: true });
            }
            catch (err) {
                errorHandling.report(err);
            }
        }

        async function save(ev, taskFolderForm) {
            if (!vm.isValid())
                return;
            if (taskFolderForm.$invalid)
                return;

            try {
                const folder = {
                    folder: vm.name,
                    uid: vm.id,
                    color: vm.color
                };

                if (vm.isOwner) {
                    if (vm.newItem) {
                        const success = await $http.post("~/api/v1/tasks/sources/add", folder);
                        vm.id = success.data.id;
                    } else {
                        await $http.post("~/api/v1/tasks/sources/edit", folder);
                    }

                    if (vm.isShareable)
                        await savePermissions();
                }
                // Shares should use edit-shared-folder.dlg.html

                $mdDialog.hide();
            }
            catch (err) {
                errorHandling.report(err);
            }
        }

        async function savePermissions() {
            vm.userPermissions.pop();
            vm.groupPermissions = vm.groupPermissions.filter(share => share.group && !share.group.selectText);
            vm.groupPermissions = vm.groupPermissions.map(function (g) {
                if (g.group && g.group.isEveryone) {
                    g.group = undefined;
                    g.isEveryone = true;
                } else if (g.group && g.group.isAdmins) {
                    g.group = undefined;
                    g.isAdmins = true;
                } else {
                    g.isAdmins = false;
                    g.isEveryone = false;
                }
                return g;
            });

            var shareUsers = vm.userPermissions.concat(vm.groupPermissions);

            // Push to server
            var params = {
                shareUsers: shareUsers,
                sharingItem: vm.type,
                shareID: vm.id
            };
            var addedLength = shareUsers.length;
            await $http.post("~/api/v1/settings/share-item", params);
            coreDataSettings.addPermissions(vm.type, vm.id, addedLength);

            shareUsers = [];
            angular.forEach(vm.originalShareUsers, function (share) {
                var found = false;
                if (share.user) {
                    for (var i = 0; i < vm.userPermissions.length; i++) { // If we removed the user
                        if (vm.userPermissions[i].user.emailAddress === share.user.emailAddress) {
                            found = true;
                            break;
                        }
                    }
                } else {
                    for (var j = 0; j < vm.groupPermissions.length; j++) { // If we removed the userGroup
                        if (vm.groupPermissions[j].isEveryone && share.isEveryone) {
                            found = true;
                            break;
                        } else if (vm.groupPermissions[j].isAdmins && share.isAdmins) {
                            found = true;
                            break;
                        } else if (share.group && vm.groupPermissions[j].group && vm.groupPermissions[j].group.id === share.group.id) {
                            found = true;
                            break;
                        }
                    }
                }
                if (!found)
                    shareUsers.push(share);
            });

            params = {
                shareUsers: shareUsers,
                sharingItem: vm.type,
                shareID: vm.id
            };

            var removedLength = shareUsers.length;
            await $http.post("~/api/v1/settings/share-users-delete", JSON.stringify(params));
            coreDataSettings.removePermissions(vm.type, vm.id, removedLength);
        }

        function loadUserGroupList() {
            coreDataSettings.settingsData.userGroups
                .then(function (success) {
                    vm.userGroups.push({ name: $translate.instant("SELECT_A_GROUP"), id: undefined, selectText: true });
                    vm.userGroups.push({ name: $translate.instant("EVERYONE"), id: -1, isEveryone: true });
                    vm.userGroups.push({ name: $translate.instant("SHARED_RESOURCES_ADMIN"), id: -2, isAdmins: true });

                    var groups = $.extend(true, [], success).sort(function (a, b) {
                        var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
                        if (nameA < nameB)
                            return -1;
                        if (nameA > nameB)
                            return 1;
                        return 0;
                    });
                    vm.userGroups = vm.userGroups.concat(groups);

                    if (vm.groupPermissions.length > 0) {
                        angular.forEach(vm.groupPermissions, function (share) {
                            for (var i = 0; i < vm.userGroups.length; i++) {
                                if (vm.userGroups[i].isEveryone && share.isEveryone) {
                                    share.group = vm.userGroups[i];
                                    break;
                                } else if (vm.userGroups[i].isAdmins && share.isAdmins) {
                                    share.group = vm.userGroups[i];
                                    break;
                                } else if (share.group && vm.userGroups[i].id === share.group.id) {
                                    share.group = vm.userGroups[i];
                                    break;
                                }
                            }
                        });
                    }

                    vm.groupPermissions.push({ access: 4, group: vm.userGroups[0] });
                });
        }

        function onEmailChanged(last, index, form) {
            var email = form["email" + index].$viewValue;
            if (email && email.length > 0 && last)
                vm.userPermissions.push({ access: 4, user: { emailAddress: "" } });
        };

        function onEmailBlurred(last, index, form) {
            var email = form["email" + index].$viewValue;
            if ((!email || email.length === 0) && !last) {
                vm.selectedItem.splice(index, 1);
                vm.userPermissions.splice(index, 1);
            }
        };

        function removeUser(index, form) {
            vm.selectedItem.splice(index, 1);
            vm.userPermissions.splice(index, 1);
            form.$setDirty();
        };

        function onGroupChanged(last) {
            if (last)
                vm.groupPermissions.push({ access: 4, group: vm.userGroups[0] });
        };

        function removeGroup(index, form) {
            vm.groupPermissions.splice(index, 1);
            form.$setDirty();
        };

    }
})();
