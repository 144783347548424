(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("listFailedToLoadDialogController", listFailedToLoadDialogController);

	function listFailedToLoadDialogController($scope, $mdDialog, $http, errorHandling, listName) {

		$scope.listName = listName;

		$scope.cancel = function () {
			$mdDialog.cancel();
		};

		$scope.deleteList = function() {
			$http
				.post("~/api/v1/settings/domain/mailing-lists/delete", JSON.stringify($scope.listName))
				.then(function () { $mdDialog.hide(true); }, errorHandling.report);
		};
	}
})();
