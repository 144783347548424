(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("moveFolderController", moveFolderController);
    function moveFolderController($scope, $mdDialog, folders, folder) {
        $scope.controller = this;
        $scope.selectedNode = "";
        $scope.title = 'MOVE_FOLDER';
        $scope.folders = $.grep(folders, function (f) {
            return f.path != folder && f.access !== 2;
        });
        $scope.selectedFolder = $scope.folders[0];

        $scope.done = function () {
            $mdDialog.hide({ 'success': true, 'folder': $scope.selectedFolder.path, 'owner': $scope.selectedFolder.ownerEmailAddress });
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        };
    }
})();