(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("emailArchiveFunctions", emailArchiveFunctions);

	/*
	 * IMPORTANT NOTE:
	 * 
	 * Most of the functions defined in this service do not do error checking.It is your responsibility
	 * to handle exceptions and display them, usually using errorHandling.report(err).
	 * 
	 * Exceptions due to modal cancellations WILL be handled by this service, however. Exceptions should only
	 * be thrown for exceptional events or issues, not for user cancellations.
	 * 
	 */

	function emailArchiveFunctions($rootScope, $http, $mdDialog, $timeout, $translate, userDataService, coreDataFileStorage) {
		var vm = this;

		// Functions
		vm.loadMessage = loadMessage;
		vm.loadRawContent = loadRawContent;
		vm.downloadEmlFile = downloadEmlFile;
		vm.downloadAll = downloadAll;
		vm.copyToMailbox = copyToMailbox;
		vm.copyAllToMailbox = copyAllToMailbox;

		////////////////////////////////////////

		async function loadMessage(searchGuid, uid) {
			const params = JSON.stringify({
				OwnerEmailAddress: searchGuid,
				UID: uid
			});
			const url = '~/api/v1/mail/archive-message';
			const success = await $http.post(url, params);

			if (!success.data.messageData)
				return null;
			return success.data.messageData;
		}

		async function loadRawContent(searchGuid, uid) {
			const parameters = {
				'Folder': searchGuid,
				'UID': uid,
				'OwnerEmailAddress': searchGuid
			};
			const success = await $http.post('~/api/v1/mail/archive-message/raw', parameters);
			return success.data;
		}

		async function downloadEmlFile(archiveSearchGuid, uids) {
			if (uids === undefined || uids === null)
				return;
			if (!Array.isArray(uids))
				uids = [uids];

			const progressTimeout = $timeout(function () {
				const progress = $mdDialog.stProgressBar()
					.title($translate.instant("DOWNLOADING"))
					.barText("")
					.close($translate.instant("DOWNLOAD_IN_BACKGROUND"))
					.showCancel(false)
					.enableButtons(true)
					.autoClose(true)
					.percentProgress(-1)
					.guid("eml-download")
					.progressListener("eml-download.finished");
				$mdDialog.show(progress);
			}, 1000);

			try {
				var httpPath = "~/api/v1/settings/domain/download-message-archive";
				var displayFilename = $translate.instant("FILENAME_ARCHIVE_MESSAGES") + ".zip";
				var parameters = JSON.stringify({
					searchGuid: archiveSearchGuid,
					messageIds: uids
				});
				await coreDataFileStorage.downloadFile(httpPath, displayFilename, parameters);
			} finally {
				$timeout.cancel(progressTimeout);
				$rootScope.$broadcast("eml-download.finished", { guid: "eml-download", current: -1 });
			}
		}

		async function downloadAll(archiveSearchGuid) {
			const progressTimeout = $timeout(function () {
				const progress = $mdDialog.stProgressBar()
					.title($translate.instant("DOWNLOADING"))
					.barText("")
					.close($translate.instant("DOWNLOAD_IN_BACKGROUND"))
					.showCancel(false)
					.enableButtons(true)
					.autoClose(true)
					.percentProgress(-1)
					.guid("archive-download")
					.progressListener("archive-download.finished");
				$mdDialog.show(progress);
			}, 1000);

			try {
				var httpPath = "~/api/v1/settings/domain/download-message-archive-all";
				var displayFilename = $translate.instant("FILENAME_ARCHIVE_MESSAGES") + ".zip";
				var parameters = JSON.stringify({ searchGuid: archiveSearchGuid });
				await coreDataFileStorage.downloadFile(httpPath, displayFilename, parameters);
			} finally {
				$timeout.cancel(progressTimeout);
				$rootScope.$broadcast("archive-download.finished", { guid: "archive-download", current: -1 });
			}
		}

		/// Returns whether item was copied or not
		async function copyToMailbox(archiveSearchGuid, uids, $event) {
			if (uids === undefined || uids === null)
				return false;
			if (!Array.isArray(uids))
				uids = [uids];

			var modalResult;
			const userDataServicePromise = userDataService.init();

			try {
				modalResult = await $mdDialog.show({
					locals: {
						title: $translate.instant("COPY_TO_MAILBOX"),
						helplink: "sys-message-archive-dialog",
						ok: $translate.instant("COPY")
					},
					controller: "adminSelectUserMailFolderController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/shared/admin-select-user-mail-folder.dlg.html",
					targetEvent: $event
				});
			}
			catch (err) { return false; }

			// Make sure userDataService is ready
			await userDataServicePromise;

			const url = userDataService.user.isSysAdmin
				? `~/api/v1/settings/sysadmin/copy-message-archive`
				: `~/api/v1/settings/domain/copy-message-archive`;
			var params = {
				searchGuid: archiveSearchGuid,
				messageIds: uids,
				destEmail: modalResult.email,
				destFolder: modalResult.folder,
			};
			await $http.post(url, JSON.stringify(params));
			return true;
		}

		/// Returns whether item was copied or not
		async function copyAllToMailbox(archiveSearchGuid, $event) {
			var modalResult;
			const userDataServicePromise = userDataService.init();

			try {
				modalResult = await $mdDialog.show({
					locals: {
						title: $translate.instant("COPY_TO_MAILBOX"),
						helplink: "sys-message-archive-dialog",
						ok: $translate.instant("COPY")
					},
					controller: "adminSelectUserMailFolderController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/shared/admin-select-user-mail-folder.dlg.html",
					targetEvent: $event
				});
			}
			catch (err) { return false; }

			// Make sure userDataService is ready
			await userDataServicePromise;

			const url = userDataService.user.isSysAdmin
				? `~/api/v1/settings/sysadmin/copy-message-archive-all`
				: `~/api/v1/settings/domain/copy-message-archive-all`;
			var params = {
				searchGuid: archiveSearchGuid,
				destEmail: modalResult.email,
				destFolder: modalResult.folder,
			};
			await $http.post(url, JSON.stringify(params));
			return true;
		}

	}
})();
