(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("apiTaskSources", apiTaskSources);

	/* @ngInject */
	function apiTaskSources($rootScope, $translate, $http, $q, $sessionStorage) {
		var vm = this;

		// Functions
		vm.getSources = getSources;
		vm.invalidateSources = invalidateSources;
		vm.defaultTaskSourceComparer = defaultTaskSourceComparer;

		activate();

		///////////////////////////

		function activate() {
			// To load on service instantiation, call this
			// refreshSourcesFromHttp();

			// If item could contain translatable elements, add this
			// $rootScope.$on('$translateChangeSuccess', retranslate);
		}

		function refreshSourcesFromHttp() {
			var defer = $q.defer();

			$http
				.get("~/api/v1/tasks/sources")
				.then(onRefreshed, defer.reject);

			function onRefreshed(results) {
				var sources = results.data.sources || [];
				var expiresAt = new Date();

				if (sources[0].name.toLowerCase() === "my tasks" || sources[0].name === "MY_TASKS")
					sources[0].name = $translate.instant("MY_TASKS");

				expiresAt.setHours(expiresAt.getHours() + 1);
				$sessionStorage.userTaskSources = { expires: expiresAt, sources: sources };
				defer.resolve(sources);
			}

			return defer.promise;
		}

		function defaultTaskSourceComparer(a, b) {
            if (a == null) return -1;
            if (b == null) return 1;
            if (a.isSharedItem && !b.isSharedItem) return 1;
            if (b.isSharedItem && !a.isSharedItem) return -1;
            if (!a.isSharedItem && a.isPrimary && !b.isPrimary) return -1;
            if (!b.isSharedItem && b.isPrimary && !a.isPrimary) return 1;
            if (a.isDomainResource && !b.isDomainResource) return 1;
            if (b.isDomainResource && !a.isDomainResource) return -1;
            return caseInsensitiveStrCompare(a.name, b.name);

            function caseInsensitiveStrCompare(x, y) {
                x = (x === undefined || x === null) ? '' : x;
                y = (y === undefined || y === null) ? '' : y;
                return x.localeCompare(y, undefined, { sensitivity: 'base' });
            }
		}

		function getSources(forceReload) {
			var defer = $q.defer();
			if (forceReload)
				invalidateSources();

			var element = $sessionStorage.userTaskSources;
			if (!element || element.expires < new Date())
				refreshSourcesFromHttp().then(function (sources) { defer.resolve(sources); }, defer.reject);
			else
				defer.resolve(element.sources);

			return defer.promise;
		}

		function invalidateSources() {
			delete $sessionStorage.userTaskSources;
		}
	}
})();
