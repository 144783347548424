(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("adminSettingsSenderPriorityOverridesController", adminSettingsSenderPriorityOverridesController);

	function adminSettingsSenderPriorityOverridesController($rootScope, $scope, $mdDialog, $filter, $http, $compile,
		NgTableParams, gridCheckboxes, errorHandling) {
		var vm = this;
		vm.items = [];
		vm.tableParams = new NgTableParams(
			{
				sorting: { address: 'asc' },
				count: 25
			}, {
				dataset: [],
				counts: $rootScope.commonTableCounts
			});

		vm.checkboxes = gridCheckboxes.init("address");
		vm.checkboxes.table = vm.tableParams;
		vm.initialized = false;

		// Functions
		vm.newItem = newItem;
		vm.deleteItems = deleteItems;
		vm.searchItems = searchItems;
		vm.editItem = editItem;
		vm.contextMenuGridItem = contextMenuGridItem;

		activate();

		//////////////////////////

		function activate() {
			loadItems();
		}

		function loadItems() {
			$http
				.get("~/api/v1/settings/sysadmin/priority-overrides")
				.then(function (result) {
					vm.items = result.data.priorityOverrides || [];
					vm.tableParams.settings({ dataset: vm.items });
					
					refreshTable();
					vm.initialized = true;
				}, errorHandling.report);
		}

		function searchItems() {
			vm.tableParams.filter({ $: vm.searchText });
		}

		function newItem(ev) {
			var info = {
				isNew: true,
				address: "",
				priority: 101,
				description: ""
			};
			editItem(info, ev);
		}

		function editItem(card, ev) {
			if (!card) return;
			openEditModal(card, ev).then(loadItems);
		}

		function openEditModal(info, ev) {
			return $mdDialog.show({
				locals: { info: info },
				controller: "systemSenderPriorityOverrideModalController",
				controllerAs: "ctrl",
				templateUrl: "app/sysadmin/settings/delivery-limits/sender-priority-override.dlg.html",
				targetEvent: ev
			})
				.then(function (action) {
					if (action && action.delete) {
						deleteAddresses([info.address]);
					} else {
						loadItems();
						$rootScope.$broadcast("security:countersChanged");
					}
				}, function () { });
		}

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === item.address; })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item.address];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: 'deleteItems', click: deleteItems, params: $scope.dropdownEvent, translateKey: 'DELETE' }
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $('#context-menu-area');
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		function deleteItems(event) {
			var addresses = vm.checkboxes.getCheckedItems();
			deleteAddresses(addresses);
		}
		function deleteAddresses(addresses){ 
			if (!addresses || !addresses.length)
				return;

			var confirm = $mdDialog.confirmDeletion()
				.textContent($filter('translate')('CONFIRMATIONS_DELETE_ITEMS', { items: addresses.length }))
				.targetEvent(event);
			$mdDialog.show(confirm).then(doDelete, null);

			function doDelete() {
				var params = JSON.stringify({ addresses: addresses });
				$http
					.post("~/api/v1/settings/sysadmin/priority-overrides/delete", params)
					.then(onDeleteSuccess, errorHandling.report)
					.finally($rootScope.spinner.hide);

				function onDeleteSuccess() {
					loadItems();
					$rootScope.$broadcast("security:countersChanged");
				}
			}
		}

		function refreshTable() {
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}
	}

})();
