(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("modifyChecksSpamCtrl", modifyChecksSpamCtrl);

	function modifyChecksSpamCtrl($scope, $mdDialog, coreDataSysAdminSettings, type) {
		this.isInvalid = false;
		$scope.type = type;
		$scope.filteringEnabled = false;
		$scope.outFilteringEnabled = false;
		$scope.inFilteringEnabled = false;

		$scope.cancel = function () {
			$mdDialog.cancel();
		};

		$scope.save = function () {
		    $mdDialog.hide({
		        filteringEnabled: $scope.filteringEnabled,
		        outFilteringEnabled: $scope.outFilteringEnabled,
				inFilteringEnabled: $scope.inFilteringEnabled,
		    });
		};
	}
})();