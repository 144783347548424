(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("disposableAddressController", disposableAddressController);

    function disposableAddressController($rootScope, $scope, $mdDialog, $timeout, $http, $translate,
        coreDataMail, currentFolder, errorHandling, successHandling, alias) {

        // Variables
        var refreshTimer;
        $scope.alias = alias;
        $scope.expireTime = undefined;
        $scope.expireTranslate = { days: 0, hours: 0, minutes: 0, seconds: 0 };
        $scope.time = 4;
        $scope.loaded = false;

        // Functions
        $scope.cancel = cancel;
        $scope.copy = copy;
        $scope.extend = extend;
        $scope.generate = generate;
        $scope.refreshTime = refreshTime;
        $scope.revoke = revoke;

        activate();

        /////////////////////

        async function activate() {
            try {

                $rootScope.spinner.show();
                const success = await $http.get("~/api/v1/settings/list-folders/false");
                $scope.folders = angular.copy(success.data.folders);
                $scope.folders = $.grep($scope.folders, function (f) { return f.name.toUpperCase() !== "DRAFTS" && f.name.toUpperCase() !== "SENT ITEMS"; });
                $scope.selectedFolder = $scope.folders[0];
                selectFolder(currentFolder);

                if (!$scope.alias) {
                    try {
                        const success2 = await coreDataMail.Data.disposableAddress;
                        $scope.alias = success2;
                        if ($scope.alias) {
                            $scope.expireTime = moment.duration($scope.alias.expireTime - moment());
                            selectFolder($scope.alias.folder);
                            buildExpireTime();
                        }
                    } catch (err) { }
                }

                $scope.loaded = true;
                refreshTime();
            }
            catch (err) { errorHandling.report(err); }
            finally { $rootScope.spinner.hide(); }
        }

        function selectFolder(name) {
            for (var i = 0; i < $scope.folders.length; ++i) {
                var folder = $scope.folders[i];
                folder.name = folder.name.toLowerCase();
                folder.translatedPath = folder.translatedPath.replace(new RegExp(folder.name, "gi"), folder.friendlyName);
                if (folder.name === name || folder.translatedPath.toLowerCase() === name) {
                    $scope.selectedFolder = folder;
                }
            }
        }

        async function generate() {
            if (!$scope.addressForm.$valid)
                return;
            try {
                const params = JSON.stringify({ folderName: $scope.selectedFolder.name, hours: $scope.time });
                const success = await $http.post("~/api/v1/mail/disposable-address", params);
                $scope.alias = { address: success.data.address, expireTime: moment(success.data.expireTime), folder: $scope.selectedFolder.name };
                $scope.expireTime = moment.duration($scope.alias.expireTime - moment());
                buildExpireTime();
            } catch (err) {
                errorHandling.report(err);
            }
        }

        async function revoke() {

            try {
                var confirm =
                    $mdDialog.confirmDeletion({
                        textContent: $translate.instant("CONFIRMATIONS_DELETE_DISPOSABLE_ADDRESS"),
                        ok: $translate.instant('REVOKE'),
                    });
                await $mdDialog.show(confirm);
            } catch (err) {
                // Cancelling dialog just returns
                return;
            }

            try {
                await $http.post("~/api/v1/mail/disposable-address-delete");
                coreDataMail.Data.disposableAddress = undefined;
                stopRefreshTimer();
                $mdDialog.hide("");
            } catch (err) {
                errorHandling.report(err);
            }
        }

        async function extend() {
            try {
                const success = await $http.post("~/api/v1/mail/disposable-address/4");
                $scope.alias.expireTime = moment(success.data.date);
                $scope.expireTime = moment.duration($scope.alias.expireTime - moment());
                buildExpireTime();
            } catch (err) {
                errorHandling.report(err);
            }
        }

        function buildExpireTime() {
            $scope.expireTranslate.days = Math.floor($scope.expireTime.asDays());
            $scope.expireTranslate.hours = Math.floor($scope.expireTime.asHours() - ($scope.expireTranslate.days * 24));
            $scope.expireTranslate.minutes = Math.floor($scope.expireTime.asMinutes() - (($scope.expireTranslate.hours + $scope.expireTranslate.days * 24) * 60));
            $scope.expireTranslate.seconds = Math.floor($scope.expireTime.asSeconds() - ($scope.expireTranslate.minutes * 60 + ($scope.expireTranslate.hours * 60 * 60) + ($scope.expireTranslate.days * 24 * 60 * 60)));
        }

        function refreshTime() {
            if (!$scope.expireTime || !$scope.alias) {
                refreshTimer = $timeout($scope.refreshTime, 1000);
                return;
            }
            if ($scope.alias.expireTime < moment()) {
                $scope.alias = undefined;
                $scope.expireTime = undefined;
                coreDataMail.Data.disposableAddress = undefined;
                refreshTimer = $timeout($scope.refreshTime, 1000);
                return;
            }
            if ($scope.expireTime) {
                $scope.expireTime = $scope.expireTime.subtract(1000, "ms");
                buildExpireTime();
            }
            refreshTimer = $timeout($scope.refreshTime, 1000);
        }

        function stopRefreshTimer() {
            $timeout.cancel(refreshTimer);
        }

        function cancel() {
            stopRefreshTimer();
            $mdDialog.hide($scope.alias ? $scope.alias.folder : "");
        }

        function copy() {
            var copyText = $("#address")[0];
            var range = document.createRange();
            range.selectNodeContents(copyText);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
            document.execCommand("copy");
            window.getSelection().removeAllRanges();

            successHandling.report("COPIED_TO_CLIPBOARD");
        }
    }
})();
