(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("userSettingsGeneralCalendarController", userSettingsGeneralCalendarController);

	function userSettingsGeneralCalendarController($rootScope, $scope, $http, errorHandling, $q, coreDataDomainSettings) {

		var vm = this;

		vm.daysOfWeek = [
			{ name: "Sunday", translate: "SUNDAY" },
			{ name: "Monday", translate: "MONDAY" },
			{ name: "Tuesday", translate: "TUESDAY" },
			{ name: "Wednesday", translate: "WEDNESDAY" },
			{ name: "Thursday", translate: "THURSDAY" },
			{ name: "Friday", translate: "FRIDAY" },
			{ name: "Saturday", translate: "SATURDAY" }
		];
		vm.dateStarts = [];
		vm.dateEnds = [];
		vm.defaultDuration = 60;	// 1 hour
		vm.defaultReminder = 2;		// 5 minutes
		vm.firstDayOfWeek = 0;	// Sunday

		// Functions
		vm.reloadState = reloadState;
		vm.changeDate = changeDate;
		vm.save = save;

		activate();

		/////////////////////
		$scope.$watch("dateStarts[0]", function () { vm.changeDate("Sunday") });
		$scope.$watch("dateStarts[1]", function () { vm.changeDate("Monday") });
		$scope.$watch("dateStarts[2]", function () { vm.changeDate("Tuesday") });
		$scope.$watch("dateStarts[3]", function () { vm.changeDate("Wednesday") });
		$scope.$watch("dateStarts[4]", function () { vm.changeDate("Thursday") });
		$scope.$watch("dateStarts[5]", function () { vm.changeDate("Friday") });
		$scope.$watch("dateStarts[6]", function () { vm.changeDate("Saturday") });
		$scope.$watch("dateEnds[0]", function () { if (!vm.ignoreEndChange) { vm.changeDate("Sunday") } else { vm.ignoreEndChange = false } });
		$scope.$watch("dateEnds[1]", function () { if (!vm.ignoreEndChange) { vm.changeDate("Monday") } else { vm.ignoreEndChange = false } });
		$scope.$watch("dateEnds[2]", function () { if (!vm.ignoreEndChange) { vm.changeDate("Tuesday") } else { vm.ignoreEndChange = false } });
		$scope.$watch("dateEnds[3]", function () { if (!vm.ignoreEndChange) { vm.changeDate("Wednesday") } else { vm.ignoreEndChange = false } });
		$scope.$watch("dateEnds[4]", function () { if (!vm.ignoreEndChange) { vm.changeDate("Thursday") } else { vm.ignoreEndChange = false } });
		$scope.$watch("dateEnds[5]", function () { if (!vm.ignoreEndChange) { vm.changeDate("Friday") } else { vm.ignoreEndChange = false } });
		$scope.$watch("dateEnds[6]", function () { if (!vm.ignoreEndChange) { vm.changeDate("Saturday") } else { vm.ignoreEndChange = false } });

		function activate(reset) {
			var promises = [
				$http.get("~/api/v1/settings/user-mail").then(onLoadCompleted),
				$http.get("~/api/v1/settings/auto-clean-calendar").then(onAutoCleanComplete)
			];
			$rootScope.spinner.show();
			$q
				.all(promises)
				.then(function () {}, errorHandling.report)
				.finally($rootScope.spinner.hide);
			function onAutoCleanComplete(success) {
				vm.autoCleanSettings = success.data;
				vm.autoCleanSettings.source = vm.autoCleanSettings.allowDomainOverride && vm.autoCleanSettings.domainOverrideActive ? "domain" : "system";
				vm.parentAutoclean = vm.autoCleanSettings.allowDomainOverride && vm.autoCleanSettings.domainOverrideActive ? 
					vm.autoCleanSettings.domainCalendarAutoCleanSetting : vm.autoCleanSettings.sysCalendarAutoCleanSetting
			}

			function onLoadCompleted(success) {
				vm.dateStarts = [];
				vm.dateEnds = [];
				vm.dateEnableds = [];
				var calSettings = success.data.userMailSettings.calendarSettings;
				vm.displayWeekendsWeek = calSettings.showWeekendsWeekly;
				vm.displayTaskStart = calSettings.showTaskStarts;
				vm.displayTaskEnd = calSettings.showTaskDues;
				vm.hideCompletedTasks = calSettings.hideCompleteTasks;

				vm.dateStarts.push(tzMomentTimeSpan(calSettings.sunday.start));
				vm.dateStarts.push(tzMomentTimeSpan(calSettings.monday.start));
				vm.dateStarts.push(tzMomentTimeSpan(calSettings.tuesday.start));
				vm.dateStarts.push(tzMomentTimeSpan(calSettings.wednesday.start));
				vm.dateStarts.push(tzMomentTimeSpan(calSettings.thursday.start));
				vm.dateStarts.push(tzMomentTimeSpan(calSettings.friday.start));
				vm.dateStarts.push(tzMomentTimeSpan(calSettings.saturday.start));

				vm.dateEnds.push(tzMomentTimeSpan(calSettings.sunday.end));
				vm.dateEnds.push(tzMomentTimeSpan(calSettings.monday.end));
				vm.dateEnds.push(tzMomentTimeSpan(calSettings.tuesday.end));
				vm.dateEnds.push(tzMomentTimeSpan(calSettings.wednesday.end));
				vm.dateEnds.push(tzMomentTimeSpan(calSettings.thursday.end));
				vm.dateEnds.push(tzMomentTimeSpan(calSettings.friday.end));
				vm.dateEnds.push(tzMomentTimeSpan(calSettings.saturday.end));

				vm.dateEnableds.push(calSettings.sunday.enabled);
				vm.dateEnableds.push(calSettings.monday.enabled);
				vm.dateEnableds.push(calSettings.tuesday.enabled);
				vm.dateEnableds.push(calSettings.wednesday.enabled);
				vm.dateEnableds.push(calSettings.thursday.enabled);
				vm.dateEnableds.push(calSettings.friday.enabled);
				vm.dateEnableds.push(calSettings.saturday.enabled);

				vm.defaultDuration = calSettings.defaultDuration;
				vm.defaultReminder = calSettings.defaultReminder;
				vm.firstDayOfWeek = calSettings.firstDayOfWeek;

				if (reset) {
					setTimeout(function () {
						$rootScope.$broadcast("DateModelReset");
					}, 350);
				}
			}
		}

		function reloadState(form) {
			activate(true);
			form.$setPristine();
		}

		function tzMomentTimeSpan(dt) {
			var split = dt.split(":");
			return moment().hours(split[0]).minutes(split[1]).seconds(split[2]).toDate();
		}

		function save(event, form) {
			if (!form.$valid)
				return;

			var params = {
				userMailSettings: {
					calendarSettings: {
						showWeekendsWeekly: vm.displayWeekendsWeek,
						showWeekendsMonthly: vm.displayWeekendsWeek,
						showTaskStarts: vm.displayTaskStart,
						showTaskDues: vm.displayTaskEnd,
						hideCompleteTasks: vm.hideCompletedTasks,
						sunday: { start: moment(vm.dateStarts[0]).format("HH:mm:ss"), end: moment(vm.dateEnds[0]).format("HH:mm:ss"), enabled: vm.dateEnableds[0] },
						monday: { start: moment(vm.dateStarts[1]).format("HH:mm:ss"), end: moment(vm.dateEnds[1]).format("HH:mm:ss"), enabled: vm.dateEnableds[1]  },
						tuesday: { start: moment(vm.dateStarts[2]).format("HH:mm:ss"), end: moment(vm.dateEnds[2]).format("HH:mm:ss"), enabled: vm.dateEnableds[2]  },
						wednesday: { start: moment(vm.dateStarts[3]).format("HH:mm:ss"), end: moment(vm.dateEnds[3]).format("HH:mm:ss"), enabled: vm.dateEnableds[3]  },
						thursday: { start: moment(vm.dateStarts[4]).format("HH:mm:ss"), end: moment(vm.dateEnds[4]).format("HH:mm:ss"), enabled: vm.dateEnableds[4]  },
						friday: { start: moment(vm.dateStarts[5]).format("HH:mm:ss"), end: moment(vm.dateEnds[5]).format("HH:mm:ss"), enabled: vm.dateEnableds[5]  },
						saturday: { start: moment(vm.dateStarts[6]).format("HH:mm:ss"), end: moment(vm.dateEnds[6]).format("HH:mm:ss"), enabled: vm.dateEnableds[6]  },

						defaultDuration: vm.defaultDuration,
						defaultReminder: vm.defaultReminder,
						firstDayOfWeek: vm.firstDayOfWeek
					}
				}
			};
			if (vm.autoCleanSettings.allowDomainOverride && vm.autoCleanSettings.allowUserOverride) {
				params.userMailSettings.calendarAutoCleanMonths = vm.autoCleanSettings.userOverrideActive ? vm.autoCleanSettings.userCalendarAutoCleanSetting : -2
			}
			$http
				.post("~/api/v1/settings/user-mail", JSON.stringify(params))
				.then(onSuccess, errorHandling.report);

			function onSuccess() {
				form.$setPristine();
				$rootScope.$broadcast("user-settings:changed");
			}
		}

		function changeDate(day) {
			var index;
			switch (day) {
				case "Sunday": index = 0; break;
				case "Monday": index = 1; break;
				case "Tuesday": index = 2; break;
				case "Wednesday": index = 3; break;
				case "Thursday": index = 4; break;
				case "Friday": index = 5; break;
				case "Saturday": index = 6; break;
				default: return;
			}

			var startDate = moment(vm.dateStarts[index]);
			var endDate = moment(vm.dateEnds[index]);
			if (startDate.hour() >= endDate.hour()) {
				vm.dateEnds[index] = moment(vm.dateEnds[index]).hour(moment(vm.dateStarts[index]).hour()).toDate();
				vm.ignoreEndChange = true;
			}
			if (startDate.hour() === endDate.hour() && startDate.minute() >= endDate.minute()) {
				vm.dateEnds[index] = (endDate.minute(startDate.minute())).toDate();
				vm.ignoreEndChange = true;
			}
		}
	}

})();
