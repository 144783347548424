(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("domainAliasesData", domainAliasesData);

	/* @ngInject */
	function domainAliasesData($rootScope, $http, $q, coreDataDomainSettings) {

		var vm = this;
		vm.domainAliases = [];

		// Functions
		vm.loadAliases = loadAliases;
		vm.addAlias = addAlias;
		vm.updateAlias = updateAlias;
		vm.deleteAlias = deleteAlias;
		vm.verifyMx = verifyMx;

		activate();

		///////////////////////////

		function activate() {
		}

		function loadAliases() {
			var defer = $q.defer();

			$http.get("~/api/v1/settings/domain/domain-aliases")
				.then(function (success) {
					vm.domainAliases = success.data.domainAliasData || [];
					sortAliases();
					defer.resolve(vm.domainAliases);
				}, defer.reject);

			return defer.promise;
		}

		function verifyMx(aliasName) {
			var defer = $q.defer();

			$http.post("~/api/v1/settings/domain/domain-alias-verify/" + aliasName + "/")
				.then(function (result) {
					defer.resolve(result.data.success);
				}, defer.reject);

			return defer.promise;
		}

		function addAlias(aliasName, shouldVerifyMx) {
			var defer = $q.defer();

			$http
				.post("~/api/v1/settings/domain/domain-alias-put/" + aliasName + "/" + shouldVerifyMx + "/")
				.then(function (success) {
					vm.domainAliases.push({ name: success.data.name, aliasIdn: success.data.idnName, domainName: $rootScope.secondaryImpersonationDomain || coreDataDomainSettings.domain });
					sortAliases();
					defer.resolve(success);
				}, defer.reject);

			return defer.promise;
		}

		function updateAlias(oldName, newName, shouldVerifyMx) {
			var defer = $q.defer();

			$http
				.post("~/api/v1/settings/domain/domain-alias/" + oldName + "/" + newName + "/" + shouldVerifyMx + "/")
				.then(function () {
					loadAliases().then(defer.resolve, defer.reject);
				}, defer.reject);

			return defer.promise;
		}

		function deleteAlias(aliasName) {
			var defer = $q.defer();

			$http.post("~/api/v1/settings/domain/domain-aliases-delete", JSON.stringify({ input:[ aliasName ]}))
				.then(function () {
					loadAliases().then(defer.resolve);
				}, defer.reject);

			return defer.promise;
		}

		function sortAliases() {
			vm.domainAliases.sort(function (a, b) {
				if (a.aliasIdn) {
					return b.aliasIdn
						? a.aliasIdn.localeCompare(b.aliasIdn)
						: a.aliasIdn.localeCompare(b.name);
				}
				else {
					return b.aliasIdn
						? a.name.localeCompare(b.aliasIdn)
						: a.name.localeCompare(b.name);
				}
			});
		}
	}

})();