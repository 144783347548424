(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("userEditAutoCleanController", userEditAutoCleanController);

	function userEditAutoCleanController($scope, $mdDialog, $filter, folderName, folders, processGoal, processThreshold, thresholdDays, type, enabled, newItem) {

		$scope.folderName = folderName ? folderName.toLowerCase() : folderName;
		$scope.folders = folders;
		$scope.processGoal = processGoal;
		$scope.processThreshold = processThreshold;
		$scope.thresholdDays = thresholdDays;
		$scope.type = type;
		$scope.newItem = newItem;
		
		// Functions
		$scope.save = save;
		$scope.trySave = trySave;
		$scope.delete = deleteThis;
		$scope.cancel = cancel;

		activate();

		///////////////////////

		function activate() {
			$scope.selectedFolder = $scope.folders.find(f => f.path.toLowerCase() === $scope.folderName);
		}

		function save() {
			var rule = {
				folderName: $scope.selectedFolder.name,
				processGoalMb: $scope.processGoal,
				processThresholdMb: $scope.processThreshold,
				thresholdDays: $scope.thresholdDays,
				type: $scope.type,
				translatedPath: $scope.selectedFolder.translatedPath || $scope.selectedFolder.displayPath || $scope.selectedFolder.name

		}
			$mdDialog.hide({ rule: rule, deleteItem: false });
		};

		function trySave(invalid) {
			if (!invalid) {
				save();
			}
		}

		function deleteThis() {
			$mdDialog.hide({ deleteItem: true });
		};

		function cancel() {
			$mdDialog.cancel();
		};
	}

})();
