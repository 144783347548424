(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("calendarIcsDialogController", calendarIcsDialogController);

	function calendarIcsDialogController($scope, $mdDialog, calendars, errorHandling) {
		$scope.isProcessing = false;
		$scope.calendars = calendars;
		$scope.currentCalendar = calendars[0];
		$scope.uploaderData = JSON.stringify({ calId: $scope.currentCalendar.id, owner: $scope.currentCalendar.owner });

		$scope.uploaderInit = function (publicFunctions) {
			$scope.uploaderFunctions = publicFunctions;
		}

		$scope.importFile = function (file, data) {
			$scope.isProcessing = false;
			$mdDialog.hide();
		};

		$scope.uploadFailed = function () {
			errorHandling.report("ERROR_UPLOADING");
			$scope.isProcessing = false;
			$scope.uploaderFunctions.reset();
		}

		$scope.done = function () {
			$scope.uploaderData = JSON.stringify({ calId: $scope.currentCalendar.id, owner: $scope.currentCalendar.owner });
			$scope.isProcessing = true;
			$scope.uploaderFunctions.startUpload();
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	};
})();