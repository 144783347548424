(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("sysAdminEventConditionEditController", sysAdminEventConditionEditController);

    function sysAdminEventConditionEditController($scope, $mdDialog, $filter, $timeout, newCondition, conditionKey, eventId, operator, availableEvents, usedConditions, value1, value2, parseTimeSpan) {
        $scope.eventId = eventId;
        $scope.newCondition = newCondition;
        $scope.availableEvents = $.extend(true, [], availableEvents);
        $scope.isValid = true;
        $scope.currentKey = conditionKey || "";
        $scope.availableConditions = [];
        $scope.availableConditions = $scope.availableEvents[eventId] ? $scope.availableEvents[eventId].conditions : [];
        $scope.availableConditions = $.grep($scope.availableConditions, function (cond) { return cond.prefillKey != 'alldomainadmins' && cond.prefillKey != 'primarydomainadmin' && cond.key != 'checkname' });
        $scope.availableConditions.unshift({ key: 'eventoccured', value1: new Date(), value2: new Date(), prefillKey: '' });
        $scope.conditionOptions = {
            eventoccured: ["Between", "Outside"],
            daysofweek: ["Equals", "DoesNotEqual", "Between"],
            basic: ["Equals", "DoesNotEqual", "Contains", "StartsWith", "EndsWith"],
            number: ["Equals", "DoesNotEqual", "Between", "LessThan", "GreaterThan"],
            equals: ["Equals", "DoesNotEqual"],
        };
        $scope.currentOperator = operator;
        $scope.dayOptions = [
            { value: 0, translation: "SUNDAY" },
            { value: 1, translation: "MONDAY" },
            { value: 2, translation: "TUESDAY" },
            { value: 3, translation: "WEDNESDAY" },
            { value: 4, translation: "THURSDAY" },
            { value: 5, translation: "FRIDAY" },
            { value: 6, translation: "SATURDAY" },
        ];
        $scope.boolOptions = [
            { value: true, translation: "TRUE" },
            { value: false, translation: "FALSE" },
        ];
        $scope.spamLevelOptions = ['none', 'low', 'medium', 'high'];
        $scope.statusOptions = ['NeedsAction', 'InProcess', 'Completed', 'Cancelled'];
        $scope.subscribeMethodOptions = ['manual', 'emailcommand'];
        $scope.ruleTypeOptions = ["DenialOfService", "BruteForceByIp", "BruteForceByEmail", "PasswordRetrievalBruteForce", "EmailHarvesting", "InternalSpammer", "BouncesIndicateSpammer"];
        var timeFormat = 'LT';

        // Functions
        $scope.getSetCondition = getSetCondition;
        $scope.getFriendlyKey = getFriendlyKey;
        $scope.checkValueRange = checkValueRange;
        $scope.save = save;
        $scope.delete = deleteThis;
        $scope.cancel = cancel;

        activate();

        ///////////////////////

        function activate() {
            if (usedConditions) {
                angular.forEach(Object.keys(usedConditions), function (value) {
                    $scope.availableConditions = $.grep($scope.availableConditions, function (cond) { return cond.key === value }, true)
                });
            }

            if (!newCondition) {
                $scope.availableConditions.push({ key: $scope.currentKey, value1: '', value2: '', prefillKey: '' });
            }
            if ($scope.availableConditions.length === 0) {
                $scope.availableConditions.push({ key: "NO_CONDITIONS" });
            }

            if ($scope.currentKey === 'eventoccured') {
                // Singapore timezone in 1970 was +730, now it's +800
                // So we start at UTC today plus the timezone difference to get a modern day epoch time, then we add the eventOccurred values
                var momentBase = moment().hour(moment().hours() - moment().utc().hours()).minutes(moment().minutes() - moment().utc().minutes()).second(0);
                $scope.value1 = value1 ? momentBase.add(value1 / 10000).format(timeFormat) : moment().format(timeFormat);
                momentBase.add(-(value1 / 10000));
                $scope.value2 = value2 ? momentBase.add(value2 / 10000).format(timeFormat) : moment().format(timeFormat);
            } else {
                $scope.value1 = value1 ? value1 : value1 === 0 ? 0 : '';
                $scope.value2 = value2 ? value2 : value2 === 0 ? 0 : '';
            }
        }

        function getSetCondition(value) {
            if (arguments.length) { // Set
                if ($scope.currentKey != value && value != 'NO_CONDITIONS') {
                    $scope.currentKey = value;
                    $scope.value1 = '';
                    $scope.value2 = '';
                    $scope.currentOperator = $scope.conditionOptions[$scope.getFriendlyKey(value)][0];

                    var availCond = $.grep($scope.availableConditions, function (cond) { return cond.key === value; });
                    availCond = availCond[0] ? availCond[0] : undefined;
                    if (availCond) {
                        switch (availCond.key) {
                            case 'daysofweek':
                                $scope.value1 = 0;
                                $scope.value2 = 0;
                                break;
                            case 'hardreject':
                            case 'intradomain':
                                $scope.value1 = true;
                                break;
                            case 'spamlevel':
                                $scope.value1 = 'none';
                                break;
                            case 'subscribemethod':
                            case 'unsubscribemethod':
                                $scope.value1 = 'manual';
                                break;
                            case 'ruletype':
                                $scope.value1 = 'DenialOfService'
                                break;
                            case 'service':
                                $scope.value1 = 'SMTP'
                                break;
                            case 'status':
                                $scope.value1 = 'NeedsAction';
                                break;
                            case 'eventoccured':
                                $scope.value1 = moment().format(timeFormat);
                                $scope.value2 = moment().format(timeFormat);
                                break;
                            default:
                                if (!$scope.value1)
                                    $scope.value1 = availCond.value1;
                                if (!$scope.value2)
                                    $scope.value2 = availCond.value2;
                                break;
                        }
                    }
                }
            } else { // Get
                return $scope.currentKey;
            }
        };

        function getFriendlyKey(key) {
            switch (key) {
                case "fromdomain":
                case "fromaddress":
                case "subject":
                case "todomain":
                case "toaddress":
                case "location":
                case "description":
                case "aliasname":
                case "aliasaddresses":
                case "emailaddress":
                case "listname":
                case "mailinglistaddress":
                case "username":
                case "fullname":
                case "forwardingaddresses":

                case "domain":
                case "clamip":
                case "filename":
                case "ipaddress":
                case "rulename":
                case "spamassassinip":
                case "spamassassinname":
                case "virusname":
                case "licensefor":
                case "diskdrive":
                case "gatewayip":
                case "version":
                case "password":
                case "path":
                case "hostname":
                    return "basic";

                case "sizekb":
                case "mailboxusagepercent":
                case "mailboxusagemb":
                case "priority":
                case "percentcomplete":
                case "amtinhour":

                case "domaincount":
                case "domainpercent":
                case "domainusagepercent":
                case "domainusagemb":
                case "clamport":
                case "consecutivefailures":
                case "filesize":
                case "weight":
                case "spamassassinport":
                case "daysleft":
                case "diskspacegbused":
                case "diskspacepercent":
                case "diskspacefree":
                case "diskspacefreepercent":
                case "memorymbused":
                case "memorypercent":
                case "spoolcount":
                case "uptimedays":
                case "threads":
                case "daysuntilexpires":
                    return "number";

                case "spamlevel":
                case "intradomain":
                case "status":
                case "hardreject":
                case "unsubscribemethod":
                case "subscribemethod":

                case "ruletype":
                case "service":
                case "automatedrenewal":
                    return "equals";

                default:
                    return key;
            }
        };

        function checkValueRange() {
            switch ($scope.currentKey) {
                case "mailboxusagemb":
                case 'sizekb':
                case 'priority':
                    if ($scope.value2 < $scope.value1) {
                        $scope.value2 = $scope.value1;
                    }
                    break;
                case "percentcomplete":
                case "mailboxusagepercent":
                    if ($scope.value1 > 100) {
                        $scope.value1 = 100;
                    }
                    if ($scope.value2 > 100) {
                        $scope.value2 = 100;
                    }
                    if ($scope.value1 < 0) {
                        $scope.value1 = 0;
                    }
                    if ($scope.value2 < 0) {
                        $scope.value2 = 0;
                    }
                    if ($scope.value1 > $scope.value2)
                        $scope.value2 = $scope.value1;
                    break;
                case 'eventoccured':
                    var start = parseTimeSpan($scope.value1);
                    var end = parseTimeSpan($scope.value2);
                    if (start === "") {
                        $scope.value1 = '';
                    }
                    if (end === "") {
                        $scope.value2 = '';
                    }
                    if (start > end) {
                        end = start;
                    }
                    $scope.value1 = start.format(timeFormat);
                    $scope.value2 = end.format(timeFormat);
                    break;
            }
        }

        function save(invalid) {
            if (invalid) return;

            if ($scope.currentOperator !== "Between" && $scope.currentOperator !== "Outside")
                $scope.value2 = null;

            var templateCondition;
            if ($scope.currentKey === 'eventoccured') {
                var start = parseTimeSpan($scope.value1);
                var end = parseTimeSpan($scope.value2);
                templateCondition = {
                    key: $scope.currentKey, condition: $scope.currentOperator, value1: start * 10000, value2: end * 10000, prefillKey: "",
                    descriptionResourceID: "@EventCondition_TimeOfDay", type: "SmarterMail.Common.Events.EventCondition.EventConditionTime"
                };
                $mdDialog.hide({ condition: templateCondition, newCond: $scope.newCondition, deleteCond: false });
                return;
            }
            templateCondition = $.grep(availableEvents[eventId].conditions, function (cond) { return cond.key === $scope.currentKey });
            if (templateCondition[0]) { templateCondition = templateCondition[0] }
            if (templateCondition) {
                templateCondition.condition = $scope.currentOperator;
                templateCondition.value1 = $scope.value1;
                templateCondition.value2 = $scope.value2;
                $mdDialog.hide({ condition: templateCondition, newCond: $scope.newCondition, deleteCond: false });
            } else {
                $mdDialog.cancel();
            }
        };

        function deleteThis() {
            $mdDialog.hide({ condition: { key: $scope.currentKey, condition: $scope.currentOperator, value1: $scope.value1, value2: $scope.value2 }, newCond: $scope.newCondition, deleteCond: true });
        };

        function cancel() {
            $mdDialog.cancel();
        };
    }

})();
