(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminAbuseDetectionController", sysAdminAbuseDetectionController);

	function sysAdminAbuseDetectionController($rootScope, $scope, $http) {
		var vm = this;
		vm.gridSections = [
			{ translate: "IDS_RULES", length: '0' },
			{ translate: "BLACKLIST", length: 0 },
			{ translate: "WHITELIST", length: 0 },
			{ translate: "SMTP_BLOCKS", length: '0' },
		];
		vm.selectedSection = "IDS_RULES";
		vm.changeSection = function (section) { vm.selectedSection = section; };

		// Functions

		activate();

		////////////////////////////////

		function activate() {
			refreshCounts();
			$scope.$on("security:countersChanged", refreshCounts);
		}

		function refreshCounts() {
			$http
				.get('~/api/v1/settings/sysadmin/temp-ip-blocks')
				.then(function (result) {
					vm.gridSections[0].length = (result.data.tempIPBlocks || []).length;
				}, function () {});
			$http
				.get('~/api/v1/settings/sysadmin/smtp-block-rules')
				.then(function (result) {
					vm.gridSections[3].length = (result.data.blockRules || []).length;
				}, function () {});

			$http
				.get("~/api/v1/settings/sysadmin/listing-counts")
				.then(function (result) {
					vm.gridSections[1].length = result.data.blacklistCount;
					vm.gridSections[2].length = result.data.whitelistCount;
				}, function () {});
		}
	}

})();
