(function () {
	"use strict";

	angular
		.module("smartertools")
		.directive("resUploader", resUploader);

	function resUploader($compile, $http, $q, $log, $timeout, $filter, $translate, fileInfoProvider, errorHandling) {
		return {
			restrict: "EA",
			scope: {
				context: "@",
				contextData: "=?",
				resetOnContextDataChange: "=?",
				canRemoveFile: "=?",
				tempDisabled: "=?",
				hideWaitingFiles: "=?",
				removeFileAfterUpload: "=?",
				removeFileOnError: "=?",
				toastOnError: "=?",
				fileTypes: "@",
				displayMode: "@",
				fileHoverClass: "@",
				waitToUpload: "=?",
				newFileReplacesOld: "=?",
				maxFiles: "=?",
                maxNestedFolders: "=?",
                uploaderInit: "&",
				imagePreview: "&",
				allFilesFinished: "&",
				fileUploaded: "&",
				fileAdded: "&",
				fileRemoved: "&",
				fileFailed: "&",
				fileCanceled: "&",
				hideInterface: '@',
				browseControlSelector: '@',
				browseFolderControlSelector: '@',
				dropControlSelector: '@',
				cacheResumable: '=?', //to keep an external reference to the resumable object so it can be recovered.
				hasFiles: '=?'
			},// ADD FILETYPES AND GO TO ALL AREAS AND CHECK
			template: '\
            <div class="res-uploader" ng-hide="hideInterface">\
                <div class="res-upload-area" ng-if="(displayMode == \'droparea\')">\
                    <div ng-hide="hasFiles" class="initarea">\
                        {{::"UPLOADER_DROP_MESSAGE" | translate}}</a>\
                    </div>\
                    <div ng-show="hasFiles" class="files file-storage-card-container" id="droparea">\
                        <div ng-hide="hideWaitingFiles" ng-repeat="file in resumable.files" class="select-card-margin" style="margin:10px;margin-top:0px;">\
                            <div class="file" id="{{file.uniqueIdentifier}}">\
                                <div class="icon">\
                                    <img ng-src="{{getImageSource(file.previewImage, file.type)}}" />\
                                </div>\
                                <div class="info">\
                                    {{file.fileName}}<br>{{file.size | bytes:1}}\
                                </div>\
                                <div class="btn-container remove-btn" ng-click="$event.stopPropagation();removeFile(file);" role="button" tabindex="0">\
                                    <i class="toolsicon toolsicon-close"></i>\
                                </div>\
                            </div>\
                        </div>\
                    </div>\
                </div>\
                <div class="res-window" ng-if="((displayMode == \'page\' || displayMode == \'page-overlay\') && resumable.files.length > 0)">\
			        <div class="head">\
				        <span class="title">{{\'FILE_STORAGE_UPLOADING_ITEMS\' | translate:{\'items\':resumable.files.length} }}</span>\
				        <span class="minimize" ng-click="$event.stopPropagation();stopAllFiles();">X</span>\
				        <div style="clear: both;"></div>\
			        </div>\
\
                    <div class="file-list">\
                        <div ng-show="hasFiles" class="files" id="droparea">\
                            <div ng-repeat="file in resumable.files" class="file-row">\
					            <div class="file-progress" style="width:{{file.progress() * 100}}%;"></div>\
                                <div class="info">\
					                <span class="name">{{file.fileName}}</span>\
					                <span class="cancel" ng-click="$event.stopPropagation();stopFile(file);"><i class=\'toolsicon toolsicon-close\'></i></span>\
                                </div>\
                            </div>\
                        </div>\
                    </div>\
                </div>\
            </div>',
			link: async function (scope, element, attrs, ctrl) {
				scope.defer = $q.defer();
				var authStorage = null;
				var mdDialog = null
				var uploaderElem = $('.res-uploader');
				if (!uploaderElem || !uploaderElem.injector()) {
					uploaderElem = $('body');
				}
				if (uploaderElem.injector().has('authStorage')) {
					authStorage = uploaderElem.injector().get('authStorage');
				}
				else {
					authStorage = {
						getToken: function () { return "0"; }
					};
				}

                if (uploaderElem.injector().has('$mdDialog')) {
                    mdDialog = uploaderElem.injector().get('$mdDialog');
				}

                var toaster = null;
				if (uploaderElem.injector().has('toaster')) {
					toaster = uploaderElem.injector().get('toaster');
				}

				var siteRoot = "";
				if (typeof stSiteRoot != 'undefined') {
					siteRoot = stSiteRoot;
				}

				scope.resetOnContextDataChange = angular.isDefined(scope.resetOnContextDataChange) ? scope.resetOnContextDataChange : true;
				scope.hideWaitingFiles = angular.isDefined(scope.hideWaitingFiles) ? scope.hideWaitingFiles : false;
				scope.tempDisabled = angular.isDefined(scope.tempDisabled) ? scope.tempDisabled : false;
				scope.waitToUpload = angular.isDefined(scope.waitToUpload) ? scope.waitToUpload : false;
				scope.canRemoveFile = angular.isDefined(scope.canRemoveFile) ? scope.canRemoveFile : false;
				scope.newFileReplacesOld = angular.isDefined(scope.newFileReplacesOld) ? scope.newFileReplacesOld : false;
				scope.removeFileAfterUpload = angular.isDefined(scope.removeFileAfterUpload) ? scope.removeFileAfterUpload : true;
				scope.removeFileOnError = angular.isDefined(scope.removeFileOnError) ? scope.removeFileOnError : true;
				scope.toastOnError = angular.isDefined(scope.toastOnError) ? scope.toastOnError : true;
				scope.maxFiles = angular.isDefined(scope.maxFiles) ? scope.maxFiles : 10000;
				scope.maxNestedFolders = angular.isDefined(scope.maxNestedFolders) ? scope.maxNestedFolders : 0;
				scope.fileTypes = angular.isDefined(scope.fileTypes) ? scope.fileTypes : "";
				scope.displayMode = angular.isDefined(scope.displayMode) ? scope.displayMode : "droparea";
				scope.browseControlSelector = angular.isDefined(scope.browseControlSelector) ? scope.browseControlSelector : "default";
				scope.browseFolderControlSelector = angular.isDefined(scope.browseFolderControlSelector) ? scope.browseFolderControlSelector : "none";
                scope.dropControlSelector = angular.isDefined(scope.dropControlSelector) ? scope.dropControlSelector : "default";
				scope.fileHoverClass = angular.isDefined(scope.fileHoverClass) ? scope.fileHoverClass : "drop-file-hover";

                if (scope.newFileReplacesOld)
					scope.maxFiles = 1;

				scope.waitingToUpload = true;
				scope.waitingChunks = [];
				scope.preProcessChunk = function (chunk) {
					if (scope.waitToUpload && scope.waitingToUpload) {//Invoke wait functionality
						scope.waitingChunks.push(chunk);
					}
					else {
						chunk.preprocessFinished();
					}
				}
				scope.GetAllowedFileTypes = function () {
					var types = [];

					scope.fileTypes.split(",").forEach(function (type) {
						if (type == "image") {
							types.push(".jpg");
							types.push(".jpeg");
							types.push(".png");
							types.push(".webp");
						}
						else
							types.push(type);
					});
					return types;
				}
				if (scope.hasFiles == undefined) {
					scope.hasFiles = false;
				}
				if (!scope.cacheResumable) {
					scope.resumable = new Resumable({
						target: siteRoot + 'api/upload',
						headers: {
							"Authorization": "Bearer " + authStorage.getToken(),
							"X-SmarterMailDomain": window.stSecondaryImpersonationDomain
						},
						query: { context: scope.context, contextData: scope.contextData },
						chunkSize: 2 * 1024 * 1024,
						prioritizeFirstAndLastChunk: true,
						maxChunkRetries: 600,
						chunkRetryInterval: 1000,
						simultaneousUploads: 2,
						maxFiles: scope.maxFiles,
						preprocess: scope.preProcessChunk,
						fileType: scope.GetAllowedFileTypes(),
						maxNestedFolders: scope.maxNestedFolders,
						maxFilesErrorCallback: function (files, errorCount) {
							alert($translate.instant("UPLOAD_X_FILES_AT_A_TIME", { maxFiles: getOpt('maxFiles') }));
						},
						foldersTooDeepCallback: function (dirname, errorCount) {
							errorHandling.report($translate.instant('TOO_MANY_NESTED_FOLDERS', { folder: dirname, folderCount: getOpt('maxNestedFolders') }));
                        },
						minFileSizeErrorCallback: function (file, errorCount) {
							alert($translate.instant("FILE_TOO_SMALL_UPLOAD_LARGER_THAN_X", { minSize: $filter('bytes')(getOpt('minFileSize'))}));
						},
						maxFileSizeErrorCallback: function (file, errorCount) {
							alert($translate.instant("MAX_SIZE_EXCEEDED", { size: getOpt('maxFileSize')}));
						},
						fileTypeErrorCallback: function (file, errorCount) {
							alert($translate.instant("FILE_TYPE_NOT_ALLOWED_UPLOAD_TYPES_X", { formats: getOpt('fileType') }));
						}
					});
					function getOpt(option) { return scope.resumable.opts[option]; }
				} else {
					scope.resumable = scope.cacheResumable;
					scope.resumable.optsquery = { context: scope.context, contextData: scope.contextData };
					scope.resumable.opts.preprocess = scope.preProcessChunk;
				}

				if (scope.browseControlSelector != "none") {
					if (scope.browseControlSelector == "default") {
						var ctrl = element.find('.res-uploader')[0];
						if (ctrl)
							scope.resumable.assignBrowse(ctrl);
					} else {
						var ctrl = $(scope.browseControlSelector)[0];
						if (ctrl)
							scope.resumable.assignBrowse(ctrl);
					}
				}

				if (scope.browseFolderControlSelector != "none") {
					var ctrl = $(scope.browseFolderControlSelector)[0];
                    if (ctrl)
                        scope.resumable.assignBrowse(ctrl, true);
				}
				
				if (scope.dropControlSelector != "none") {
					if (scope.dropControlSelector == "default") {
						var ctrl = element.find('.res-uploader')[0];
						if (ctrl)
							scope.resumable.assignDrop(ctrl);
					} else {
						var ctrl = $(scope.dropControlSelector)[0];
						if (ctrl)
							scope.resumable.assignDrop(ctrl);
					}
					
					if (scope.fileHoverClass != "none") {
						if (scope.displayMode == "page") {
							$(scope.dropControlSelector).on("dragenter", function (e) {
								if (froalaPopupActive()) return true;
								if (scope.tempDisabled) return true;
								var dt = e.originalEvent.dataTransfer;
								if (dt.types.length > 0 && dt.types[0].contains("text/")) return;

								if (dt.types && (dt.types.indexOf ? dt.types.indexOf('Files') != -1 : dt.types.contains('Files'))) {
									if (dt.items && dt.items.length > scope.maxFiles)
										return;

									var overlay = createOverlay();
									overlay.addClass("no-pointer");
									scope.showDrag = true;
								}
							});
							$(scope.dropControlSelector).off("dragover");
							$(scope.dropControlSelector).on("dragover", function (ev) {
								if (froalaPopupActive()) return true;
								if (scope.tempDisabled) return true;
								var target = $(ev.target);
								if (!$(scope.dropControlSelector).has(target).length) {
									var overlay = $(scope.dropControlSelector).parent().find('#st-file-upload-overlay');
									if (overlay) { overlay.remove(); }
								}
								scope.showDrag = true;
							});
							$(scope.dropControlSelector).off("dragleave");
							$(scope.dropControlSelector).on('dragleave', function (ev) {
								scope.showDrag = false;
								$timeout(function () {
									$timeout(function () {
										if (!scope.showDrag) {
											var overlay = $(scope.dropControlSelector).parent().find('#st-file-upload-overlay');
											if (overlay) { overlay.remove(); }
										}
									}, 100)
								});
							});
							$(scope.dropControlSelector).on("drop", function (ev) {
								if (scope.tempDisabled) {
									ev.preventDefault();
									return true;
								}

								if (ev.originalEvent.dataTransfer.types.length > 0 && ev.originalEvent.dataTransfer.types[0].contains("text/")) return;
								ev.preventDefault();
								var overlay = $(scope.dropControlSelector).parent().find('#st-file-upload-overlay');
								if (overlay) { overlay.remove(); }
							});
						}
						else if (scope.displayMode == "page-overlay") {
							var dropControl = $(scope.dropControlSelector);
							var dropOverlay = createOverlay();
							dropOverlay.css("display", "none");

							scope.resumable.assignDrop(dropOverlay);

							dropControl.on("dragenter", function(e) {
								if (froalaPopupActive()) return true;
								if (scope.tempDisabled) return true;

								var dt = e.originalEvent.dataTransfer;
								if (dt.types.length > 0 && dt.types[0].contains("text/")) return;
								
								if (dt.types && (dt.types.indexOf ? dt.types.indexOf('Files') != -1 : dt.types.contains('Files'))) {
									if (dt.items && dt.items.length > scope.maxFiles)
										return;

									dropOverlay.css("display", "block");
									resizeOverlay();
									scope.showDrag = true;
								}
							})

							dropOverlay.off("dragover");
							dropOverlay.on("dragover", function (e) {
								if (froalaPopupActive()) return true;
								if (scope.tempDisabled) return true;
								var target = $(e.target);
								if (dropOverlay[0] !== e.target) {
									dropOverlay.css("display", "none");
								}
								scope.showDrag = true;
							});

							dropOverlay.off("dragleave");
							dropOverlay.on("dragleave", function (e) {
								scope.showDrag = false;
								$timeout(function () {
									$timeout(function () {
										if(!scope.showDrag) {
											dropOverlay.css("display", "none");
										}
									}, 100);
								});

							});

							dropOverlay.on("drop", function (e) {
								if (scope.tempDisabled) {
									e.preventDefault();
									return true;
								}
								
								if (e.originalEvent.dataTransfer.types.length > 0 && e.originalEvent.dataTransfer.types[0].contains("text/")) return;
								e.preventDefault();
								dropOverlay.css("display", "none");
							})
						}
					}

				}

				scope.publicFunctions = {

					startUpload: function () {
						if (scope.waitingChunks.length == 0)
							return $q.when();

						scope.resumable.opts.query = { context: scope.context, contextData: scope.contextData };

						scope.waitingToUpload = false;
						scope.waitingChunks.forEach(function (chunk) {
							chunk.preprocessFinished();
						});
						scope.waitingChunks = [];

						return scope.defer.promise;
					},
					uploadFile: function (file) {
						scope.waitToUpload = false;
						scope.waitingToUpload = false;
						scope.resumable.addFile(file);
					},
					reset: function () {
						//may need to reset the waitingtoupload state here
						scope.waitingChunks = [];
						scope.resumable.files.forEach(function (file) {
							scope.resumable.removeFile(file);
						});
						scope.hasFiles = false;
					},
					removeFile: function (file) {
						scope.removeFile(file);
					},
					setContextData: function (data) {
						scope.contextData = data;
					},
					bindBrowseControl: function (control) {
						if (scope.browseControlSelector != "none") {
							if (scope.browseControlSelector == "default") {
								var ctrl = element.find(control)[0];
								if (ctrl) {
									scope.resumable.assignBrowse(ctrl);
									return true;
								}
							} else {
								var ctrl = $(control)[0];
								if (ctrl) {
									scope.resumable.assignBrowse(ctrl);
									return true;
								}
							}
						}
						return false;
					}
				};

				if (typeof scope.uploaderInit() === "function")
					scope.uploaderInit()(scope.publicFunctions);

				scope.$watch("contextData", function (newValue, oldValue) {
					scope.resumable.opts.query = { context: scope.context, contextData: newValue };
					if (scope.resetOnContextDataChange) {
						//may need to reset the waitingtoupload state here
						scope.waitingChunks = [];
						scope.resumable.files.forEach(function (file) {
							scope.resumable.removeFile(file);
						});
						scope.hasFiles = false;
					}
				});

				scope.roundProgress = function (progress) { return Math.round(progress); };

				scope.CheckImageAndFire = function (file) {
					var fileType = file["type"];
					var ValidImageTypes = ["image/gif", "image/jpeg", "image/png", "image/webp"];
					if ($.inArray(fileType, ValidImageTypes) > 0) {
						var FR = new FileReader();
						FR.onload = function (e) {
							//These look weird, function calling a function but this is VALID.
							if (typeof scope.imagePreview() === "function")
								scope.imagePreview()(e.target.result, file);
						};
						FR.readAsDataURL(file);
					}
				};

				scope.stopFile = function (file) {
					file.cancel();
					scope.resumable.removeFile(file);
					//These look weird, function calling a function but this is VALID.
					if (typeof scope.fileCanceled() === "function")
						scope.fileCanceled()(file.file);
					if (scope.resumable.files.length == 0)
						scope.hasFiles = false;
				};

				scope.stopAllFiles = function () {

					if (mdDialog) {
                        var confirm = mdDialog.confirmDeletion({
                            textContent: $translate.instant('UPLOAD_CANCEL_DOWNLOADS'),
							ok: $translate.instant('CANCEL_UPLOADS'),
							cancel: $translate.instant('CONTINUE_UPLOADS'),
                            noWarn: true
                        });
                        mdDialog.show(confirm).then(function() {
                                scope.resumable.files.forEach(function(file) {
                                    file.cancel();
                                });
                                scope.resumable.files.length = 0;
                            },
                            function() {});
                    } else {
                        scope.resumable.files.forEach(function (file) {
                            file.cancel();
                        });
                        scope.resumable.files.length = 0;
                    }
                };
				scope.removeFile = function (file) {
					if (file.isComplete()) {
						$http({
							url: siteRoot + "api/upload/remove",
							method: "POST",
							data: JSON.stringify({ context: scope.context, contextData: scope.contextData, fileInfo: JSON.stringify(file.fileInfo) }),
							headers: {
								'Content-Type': 'application/json',
								'Authorization': 'Bearer ' + authStorage.getToken(),
								'X-SmarterMailDomain': window.stSecondaryImpersonationDomain
							}
						}).then(function (success) {
							//These look weird, function calling a function but this is VALID.
							if (typeof scope.fileRemoved() === "function")
								scope.fileRemoved()(file.file);
							if (file.cancel) {
								file.cancel();
							}
							scope.resumable.removeFile(file);
							if (scope.resumable.files.length == 0)
								scope.hasFiles = false;

						}, function (failure) {
						});
					} else {
						//These look weird, function calling a function but this is VALID.
						if (typeof scope.fileRemoved() === "function")
							scope.fileRemoved()(file.file);
						if (file.cancel) {
							file.cancel();
						}
						scope.resumable.removeFile(file);
						if (scope.resumable.files.length == 0)
							scope.hasFiles = false;
					}
				};
				scope.getImageSource = function (previewUrl, type) {
					if (previewUrl)
						return previewUrl;
					var icon = fileInfoProvider.iconFromExtension(type);
					return stSiteRoot + "fonts/tools-icons/img/" + icon + ".svg";
				};

				var setResumableFunctions = function () {
					scope.resumable.events = [];
					scope.resumable.on('fileAdded', function (file, event) {
						if (scope.tempDisabled) {
							file.cancel();
							scope.resumable.removeFile(file);
							if (scope.resumable.files.length == 0)
								scope.hasFiles = false;
							
							event.stopPropagation();
							return false;
						}

						if (scope.newFileReplacesOld) {
							scope.waitingChunks = [];
						}

						scope.resumable.headers = { Authorization: "Bearer " + authStorage.getToken() };
						scope.CheckImageAndFire(file.file);
						scope.hasFiles = true;
						scope.waitingToUpload = true;
						scope.resumable.upload();
						scope.$apply();
						if (typeof scope.fileAdded() === "function") {
							scope.fileAdded()(file.file, file, scope.resumable);
						}

						//prevent click through on files
						$('.file').click(function (event) {
							event.stopPropagation();
							return false;
						});
					});

					scope.resumable.on('fileProgress', function (file) {
						//var fileElem = $("#" + file.uniqueIdentifier);
						//fileElem.find("md-progress-linear").attr("value", file.progress()*100);
						$timeout(function () { scope.$apply(); });
						//console.log(file.progress() * 100);
					});

					scope.resumable.on('fileSuccess', function (file, message) {
						var fileElem = $("#" + file.uniqueIdentifier);
						fileElem.removeClass("uploading").addClass("success");
						var returnData = null;
						if (message)
							returnData = JSON.parse(message);


						if (typeof scope.allFilesFinished() === "function") {
							var completedFiles = 0;
							scope.resumable.files.forEach(function (file) {
								if (file.progress() == 1)
									completedFiles++;
							});
							if (completedFiles == scope.resumable.files) {
								scope.waitingToUpload = true;
								scope.allFilesFinished()(completedFiles);
							}
						}

						if (scope.removeFileAfterUpload) {
							scope.resumable.removeFile(file);
							if (scope.resumable.files.length == 0)
								scope.hasFiles = false;
						}
						file.fileInfo = returnData;

						if (typeof scope.fileUploaded() === "function")
							scope.fileUploaded()(file.file, returnData, file);

						scope.defer.resolve(returnData);

						scope.$apply();
					});

					scope.resumable.on('fileError', function (file, message) {
						var fileElem = $("#" + file.uniqueIdentifier);
						var exception = JSON.parse(message);

						if (exception && exception.message) {
							file.error = exception.message;
							fileElem.find(".errormessage").text(file.error = exception.message);
						}
						fileElem.removeClass("uploading").addClass("failed");
						scope.defer.resolve(null);

						if (scope.toastOnError) {
							if (toaster != null) {

								if (exception.message.indexOf('MAX_SIZE_EXCEEDED|') > -1) {
                                    exception.message = exception.message.replace('MAX_SIZE_EXCEEDED|', '');
                                    toaster.pop({
                                        type: 'error',
                                        title: null,
										body: $translate.instant("MAX_SIZE_EXCEEDED", { size: exception.message }),
                                        timeout: 10000,
                                        bodyOutputType: 'trustedHtml'
                                    });
								} else if (exception.message.indexOf('MALWARE_FOUND|') > -1) {
									exception.message = exception.message.replace('MALWARE_FOUND|', '');
                                    toaster.pop({
                                        type: 'error',
                                        title: null,
										body: $translate.instant("MALWARE_FOUND", { filename: exception.message }),
                                        timeout: 10000,
                                        bodyOutputType: 'trustedHtml'
                                    });
                                } else {
                                    errorHandling.report(exception.message);
                                }
                                
                            }
								
						}

						if (scope.removeFileOnError) {
							scope.resumable.removeFile(file);
							if (scope.resumable.files.length == 0)
								scope.hasFiles = false;
						}

						scope.$apply();
						if (typeof scope.fileFailed() === "function")
							scope.fileFailed()(file.file, exception);
					});
				}
				setResumableFunctions();

				function createOverlay() {					
					var drop = $(scope.dropControlSelector);
					$('#st-file-upload-overlay').remove();
					drop.parent().append('<div id="st-file-upload-overlay">');
					var overlay = drop.parent().find('#st-file-upload-overlay');

					resizeOverlay();

					return overlay;
				}


				function froalaPopupActive() {
					var froalaParent = $('st-html-editor  textarea.froalaEditor');
					if (froalaParent.length > 0) {
						// popups.areVisible returns false if none are visible, otherwise it returns a bootstrap object.
						if (froalaParent[0]["data-froala.editor"].popups.areVisible() !== false)
							return true;
					}
					return false;
				}
				
				function resizeOverlay() {
					var drop = $(scope.dropControlSelector);
					var overlay = drop.parent().find("#st-file-upload-overlay");
					if (!overlay)
						return;
					var overWidth = drop.width();
					var pLeft = parseInt(drop.css('paddingLeft'));
					var pRight = parseInt(drop.css('paddingRight'));
					overWidth += pLeft ? pLeft : 0;
					overWidth += pRight ? pRight : 0;
					var overHeight = drop.height();
					var pTop = parseInt(drop.css('paddingTop'));
					var pBot = parseInt(drop.css('paddingBottom'));
					overHeight += pTop ? pTop : 0;
					overHeight += pBot ? pBot : 0;
					overlay.width(overWidth);
					overlay.height(overHeight);

					var overlayBorder = $('#st-file-overlay-border');
					if (!overlayBorder || overlayBorder.length === 0) {
						overlay.append('<div id="st-file-overlay-border">');
						overlayBorder = $('#st-file-overlay-border');
						overlayBorder.append('\
					<div id="st-file-overlay-icon">\
						<i class="toolsicon toolsicon-4x toolsicon-upload"></i>\
						<span>' + $translate.instant("DROP_TO_UPLOAD") + '</span>\
					</div>');
					}
					overlayBorder.width(overlay.width() - 33);
					overlayBorder.height(overlay.height() - 33);

					var br = drop[0].getBoundingClientRect();
					var offset = {top: br.top ? br.top : br.y, left: br.left ? br.left : br.x };
					overlay.offset(offset);

				}
			}
		};
	}
})();