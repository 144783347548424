(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("calendarActions", calendarActions);

    function calendarActions($rootScope, $http, $mdDialog, $translate, $filter, errorHandling, preferencesStorage, coreDataCalendar) {
        var vm = this;

        // Functions
        vm.showCreateFolderModal = showCreateFolderModal;
        vm.showRenameFolderModal = showRenameFolderModal;
        vm.showDeleteFolderModal = showDeleteFolderModal;
        vm.showShareFolderModal = showShareFolderModal;
        vm.showSharedFolderPropertiesModal = showSharedFolderPropertiesModal;
        vm.showDeleteSchedulingFolderModal = showDeleteSchedulingFolderModal;
        vm.showSubscribedCalendarModal = showSubscribedCalendarModal;

        ///////////////////////////

        async function showCreateFolderModal(event) {
            try {
                await $mdDialog.show({
                    locals: {},
                    controller: "createCalendarFolderController",
                    controllerAs: "ctrl",
                    templateUrl: "app/calendars/modals/create-folder.dlg.html",
                    targetEvent: event,
                    clickOutsideToClose: false
                });
                return true;
            } catch (err) {
                // ignore error - they closed the modal
            }
        }

        async function showRenameFolderModal(event, source) {
            if (!source)
                return;

            try {
                let success = undefined;

                if (source.isSharedItem && source.itemID !== "gal") {
                    success = await $mdDialog.show({
                        controller: "editSharedFolderDialogController",
                        controllerAs: "ctrl",
                        templateUrl: "app/shared/modals/edit-shared-folder.dlg.html",
                        targetEvent: event,
                        locals: { folderId: source.folderId, webcalLink: null, folder: null }
                    });
                }
                else {
                    success = await $mdDialog.show({
                        locals: { source: source },
                        controller: "renameCalendarFolderController",
                        controllerAs: "ctrl",
                        templateUrl: "app/calendars/modals/rename-folder.dlg.html",
                        targetEvent: event,
                        clickOutsideToClose: false
                    });
                }

                if (success && success.detached) {
                    preferencesStorage.removeSourceVisibility("calendars", source);
                }

                return true;
            }
            catch (err) {
                // exception just means modal was closed
            }
        }

        async function showDeleteSchedulingFolderModal(event, source) {
            if (!source || source.isPrimary || source.isResource)
                return;

            const name = source.displayName ? $translate.instant(source.displayName) : source.name;
            const confirm = $mdDialog.confirmDeletion()
                .htmlContent($filter("translate")("SCHEDULING_CALENDAR_DELETE", { name: name }))
                .targetEvent(event);

            try {
                await $mdDialog.show(confirm);
            } catch (err) {
                return;
            }

            try {
                await $http.post("~/api/v1/calendars/calendar-delete/" + source.id)
                preferencesStorage.removeSourceVisibility("calendars", source);
                await coreDataCalendar.removeCalendarSource(source.id);
                return true;
            } catch (err) {
                errorHandling.report(err);
            }
        }

        async function showDeleteFolderModal(event, source) {
            if (!source || source.isPrimary || source.isResource)
                return;

            const name = source.displayName ? $translate.instant(source.displayName) : source.name;
            const confirm = $mdDialog.confirmDeletion()
                .htmlContent($filter("translate")("CONFIRMATIONS_DELETE_FOLDER", { name: name }))
                .targetEvent(event);

            try {
                await $mdDialog.show(confirm);
            } catch (err) {
                return;
            }

            try {
                await $http.post("~/api/v1/calendars/calendar-delete/" + source.id)
                preferencesStorage.removeSourceVisibility("calendars", source);
                await coreDataCalendar.removeCalendarSource(source.id);
                return true;
            } catch (err) {
                errorHandling.report(err);
            }
        }

        async function showShareFolderModal(event, source) {
            try {
                await $mdDialog.show({
                    locals: { source: source },
                    controller: "shareCalendarFolderController",
                    controllerAs: "ctrl",
                    templateUrl: "app/calendars/modals/share-folder.dlg.html",
                    targetEvent: event,
                    clickOutsideToClose: false
                });
                return true;
            } catch (err) {
                // ignore if the user closed the dialog
            }
        }

        async function showSharedFolderPropertiesModal(event, source) {
            try {
                let success = await $mdDialog.show({
                    controller: "editSharedFolderDialogController",
                    controllerAs: "ctrl",
                    templateUrl: "app/shared/modals/edit-shared-folder.dlg.html",
                    targetEvent: event,
                    locals: { folderId: source.folderId, webcalLink: null, folder: null }
                });

                if (success && success.detached) {
                    preferencesStorage.removeSourceVisibility("calendars", source);
                }
                return true;
            } catch (err) {
                // ignore if the user closed the dialog
            }
        }

        async function showSubscribedCalendarModal(event, source) {
            const params = {
                locals: { source: source },
                controller: "subscribeToCalendarController",
                controllerAs: "ctrl",
                templateUrl: "app/calendars/modals/subscribe-to-calendar.dlg.html",
                targetEvent: event,
                clickOutsideToClose: false
            };

            try {
                let success = await $mdDialog.show(params);
                return success;
            } catch (err) {
                // failure just means they closed the dialog
            }
        }
    }
})(); 