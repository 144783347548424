(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("domainSettingsUsersController", domainSettingsUsersController);

    function domainSettingsUsersController($state, $rootScope, $scope, $http, $filter, $mdDialog, $compile, $translate,
        coreLicensing, NgTableParams, gridCheckboxes, errorHandling, successHandling, coreDataDomainSettings, impersonationService,
        coreDataFileStorage, userDataService, coreData, claimsService) {

        var vm = this;
        vm.domain = $state.params.id || userDataService.user.domain;
        vm.domainSettings = {};
        vm.edition = 1;
        vm.searchText = "";
        vm.searchParams = { skip: 0, take: 0, search: null, sortField: null, sortDescending: false };
        vm.settingCore = coreDataDomainSettings;
        vm.canManageLicensing = claimsService.isSysAdmin() || claimsService.impersonating();
        vm.minDate = (new Date("2002"));
        vm.aupEnabled = false;
        vm.myUsername = coreData.user.username;
        vm.columnLabels = {
            size: $filter("translate")("SIZE"),
            spaceUsed: $filter("translate")("DISK_USAGE")
        };
        vm.maxResyncRequestsAllowed = 25;
        vm.primaryDomainAdminSelected = false;
        // Functions
        vm.newUser = newUser;
        vm.deleteItems = deleteItems;
        vm.editUser = editUser;
        vm.enableUsers = enableUsers;
        vm.disableUsers = disableUsers;
        vm.reindexItems = reindexItems;
        vm.resyncUsers = resyncUsers;
        vm.impersonateUser = impersonateUser;
        vm.doImpersonate = doImpersonate;
        vm.recalculateItems = recalculateItems;
        vm.expirePasswords = expirePasswords;
        vm.importUsers = importUsers;
        vm.importLdap = importLdap;
        vm.exportUsers = exportUsers;
        vm.searchItems = searchItems;
        vm.contextMenuGridItem = contextMenuGridItem;
        vm.restore = restore;
        vm.checkIfPrimary = checkIfPrimary;

        $scope.aupColumnEnabled = function () { return vm.aupEnabled; };
        activate();

        //////////////////////////////////

        function activate() {
            recalculateLicense();
            coreLicensing.watchForChanges($scope, recalculateLicense);

            $http.get("~/api/v1/settings/domain/domain")
                .then(onPromisesLoaded, function (failure) { });

            function onPromisesLoaded(result) {
                vm.domainSettings = result.data.domainSettings;
                $rootScope.$on("refreshUsersGrid", refresh);
                $rootScope.$on("refresh-users-counts", refresh);
                vm.allowImpersonation = claimsService.canImpersonate() || claimsService.impersonating();
                vm.isSysAdmin = claimsService.isSysAdmin();

                vm.tableParams = new NgTableParams(
                    {
                        sorting: { userName: "asc" },
                    }, {
                    getData: queryData,
                    counts: $rootScope.commonTableCounts
                });
                vm.checkboxes = gridCheckboxes.init("userName");
                vm.checkboxes.table = vm.tableParams;
            }

            function recalculateLicense() {
                vm.edition = coreLicensing.edition;
                vm.activeSyncLicensed = coreLicensing.hasEas;
                vm.mapiEwsLicensed = coreLicensing.hasMapiEws;
            }
        }

        function newUser(ev) {
            $mdDialog
                .show({
                    locals: { isDomainAdmin: false },
                    controller: "domainNewUserController",
                    controllerAs: "NUCtrl",
                    templateUrl: "app/settings/domain-settings/users/new-user.dlg.html",
                    targetEvent: ev
                })
                .then(onCreateUser, function () { });

            function onCreateUser(modalSuccess) {
                vm.newUsername = modalSuccess.username;
                var splitUsername = modalSuccess.username.split("@")[0];
                $state.go(".specific", { selectedUserId: encodeURIComponent(splitUsername + "@" + vm.domain) });
            }
        }

        function deleteItems(event) {
            var toDelete = getCheckedItems();

            var confirm = $mdDialog.confirmDeletion()
                .textContent($filter("translate")("CONFIRMATIONS_DELETE_USERS", { items: toDelete.length }))
                .targetEvent(event);
            $mdDialog
                .show(confirm)
                .then(doDelete, function () { });

            function doDelete() {
                $rootScope.spinner.show();
                $http
                    .post("~/api/v1/settings/domain/account-list-delete", JSON.stringify({ input: toDelete }))
                    .then(onDeleted, errorHandling.report)
                    .finally($rootScope.spinner.hide);
            }

            function onDeleted(success) {
                if (success.data.result) {
                    var confirm = $mdDialog.confirmDeletion()
                        .title()
                        .textContent($filter("translate")("CONFIRMATIONS_UNABLE_TO_DELETE_ACCOUNTS"))
                        .ok($filter("translate")("OK"))
                        .noWarn(true)
                        .hideCancel(true);
                    $mdDialog.show(confirm);
                }
                refreshAndNotify();
            }
        }

        function editUser(selectedCard, event) {
            if (selectedCard.status === 4) {
                $mdDialog
                    .show({
                        locals: { username: selectedCard.userName, domainPath: vm.domainSettings.domainPath },
                        controller: "userFailedToLoadDialogController",
                        controllerAs: "ctrl",
                        templateUrl: "app/settings/domain-settings/users/user-failed-to-load.dlg.html",
                        targetEvent: event
                    })
                    .then(function (result) {
                        if (result) refresh();
                    }, function () { });
                return;
            }
            if (selectedCard.accountType === 5) {
                // mailing list
                $state.go("^.domain-mailing-lists", { id: encodeURIComponent(selectedCard.emailAddress) });
            } else {
                $state.go(".specific", { selectedUserId: encodeURIComponent(selectedCard.emailAddress) });
            }
        }
        function disableUsers(selectedItems, event) {
            var users = [];
            angular.forEach(selectedItems, function (card, key) {
                users.push(key + "@" + vm.domain);
            });

            if (users.length === 0) {
                errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED");
                return;
            }
            var confirm = $mdDialog.confirmWithOption()
                .title($filter("translate")("CONFIRMATION_REQUIRED"))
                .textContent($translate.instant("CONFIRMATIONS_DISABLE_USERS", { items: users.length }))
                .optionText($translate.instant("ALLOW_INCOMING_MESSAGES"))
                .cancel($filter("translate")("CANCEL"))
                .ok($filter("translate")("DISABLE"));

            $mdDialog.show(confirm).then(function (option) { doDisableUsers(option); });

            function doDisableUsers(allowMail) {
                var apiEndpoint = `~/api/v1/settings/domain/users-disable/true/${allowMail}`;

                $http.post(apiEndpoint, JSON.stringify({ input: users }))
                    .then(function (success) {
                        vm.checkboxes.reset();
                        vm.tableParams.reload();
                        successHandling.report();
                    }, errorHandling.report);
            }
        }

        function enableUsers(selectedItems, event) {
            var users = [];
            angular.forEach(selectedItems, function (card, key) {
                users.push(key + "@" + vm.domain);
            });
            if (users.length === 0) {
                errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED");
                return;
            } else if (users.length === 1)
                doEnableUsers();
            else {
                var confirm = $mdDialog.confirm()
                    .title($filter("translate")("CONFIRMATION_REQUIRED"))
                    .textContent($translate.instant("CONFIRMATIONS_ENABLE_USERS", { items: users.length }))
                    .cancel($filter("translate")("CANCEL"))
                    .ok($filter("translate")("ENABLE"));
                $mdDialog.show(confirm).then(function () { doEnableUsers(); });

            }
            function doEnableUsers() {

                $http.post('~/api/v1/settings/domain/users-disable/false', JSON.stringify({ input: users }))
                    .then(function (success) {
                        vm.checkboxes.reset();
                        vm.tableParams.reload();
                        successHandling.report();
                    }, errorHandling.report);
            }
        }

        function reindexItems(selectedCards) {
            var confirm = $mdDialog.confirmDeletion()
                .title($translate.instant("CONFIRMATION_REQUIRED"))
                .textContent($translate.instant("CONFIRMATIONS_REINDEX"))
                .ok($translate.instant("REINDEX"))
                .noWarn(true);
            $mdDialog
                .show(confirm)
                .then(startReindex, function () { });

            function startReindex(modalSuccess) {
                var users = [];
                angular.forEach(selectedCards, function (card, key) {
                    users.push(key);
                });

                $http
                    .post("~/api/v1/settings/domain/reindex-users", JSON.stringify({ input: users }))
                    .then(
                        successHandling.report,
                        errorHandling.report
                    );
            }
        }

        function resyncUsers(selectedCards, event) {
            if (selectedCards.length > vm.maxResyncRequestsAllowed) {
                errorHandling.report($translate("RESYNC_USERS_MAX_EXCEEDED"));
            }
            var users = [];
            angular.forEach(selectedCards, function (card, key) {
                users.push(key);
            });

            var confirm = $mdDialog.confirm()
                .title($filter("translate")("CONFIRMATION_REQUIRED"))
                .textContent($translate.instant("CONFIRMATIONS_RESYNC_USERS", { items: users.length }))
                .cancel($filter("translate")("CANCEL"))
                .ok($filter("translate")("CONTINUE"));

            $mdDialog.show(confirm).then(function (option) { doResyncUsers(); });

            function doResyncUsers() {
                var apiEndpoint = `~/api/v1/settings/domain/resync-users/${vm.domain}`;

                $http.post(apiEndpoint, JSON.stringify({ input: users }))
                    .then(function (success) {
                        vm.checkboxes.reset();
                        vm.tableParams.reload();
                        successHandling.report($translate.instant("RESYNC_ACTION_MESSAGE", { count: users.length }));
                    }, errorHandling.report);
            }
        }

        function recalculateItems(selectedCards) {
            var users = [];
            angular.forEach(selectedCards, function (card, key) {
                users.push(key);
            });

            $http
                .post("~/api/v1/settings/domain/recalculate-users", JSON.stringify({ input: users }))
                .then(successHandling.report, errorHandling.report);
        }

        function expirePasswords(selectedCards, ev) {
            var users = [];
            angular.forEach(selectedCards, function (card, key) {
                users.push(key + "@" + vm.domain);
            });

            var confirm = $mdDialog.confirmDeletion()
                .title($translate.instant("CONFIRMATION_REQUIRED"))
                .textContent($filter("translate")("EXPIRE_PASSWORD_MESSAGE", { count: users.length }))
                .ok($translate.instant("EXPIRE_PASSWORD"))
                .noWarn(true);
            $mdDialog
                .show(confirm)
                .then(function () {
                    var params = JSON.stringify({ emailAddresses: users });
				$rootScope.spinner.show();
                    $http
                        .post("~/api/v1/settings/expire-passwords", params)
                        .then(function (success) {
                            if (success.data.activeDirectoryCount > 0)
                                errorHandling.warn("EXPIRE_PASSWORD_ACTIVEDIRECTORY");
                            else
                                successHandling.report();
                        }, errorHandling.report)
                        .finally($rootScope.spinner.hide);
                },
                    function () { });
        }

        function importUsers(ev) {
            $mdDialog
                .show({
                    controller: "domainImportUsersController",
                    controllerAs: "IUCtrl",
                    templateUrl: "app/settings/domain-settings/users/import-users.dlg.html",
                    targetEvent: ev
                })
                .then(refreshAndNotify, function () { });
        }

        function importLdap(ev) {
            $mdDialog
                .show({
                    controller: "importLdapUsersController",
                    controllerAs: "ctrl",
                    templateUrl: "app/settings/domain-settings/users/import-ldap.dlg.html",
                    targetEvent: ev
                })
                .then(refreshAndNotify, function () { });
        }

        function exportUsers() {
            $rootScope.spinner.show();
            coreDataFileStorage
                .downloadFile("~/api/v1/settings/domain/export-users", $filter("translate")("USERS") + ".csv", {})
                .then(function () { }, errorHandling.report)
                .finally($rootScope.spinner.hide);
        }

        function searchItems() {
            vm.searchParams.search = vm.searchText;
            refresh();
        }

        function restore(ev, type) {
            let items = vm.checkboxes.getCheckedItems();
            let options = { type: type };
            if (items && items.length == 1)
                options.emailAddress = items[0] + "@" + vm.domain;

            $mdDialog
                .show({
                    controller: "editDomainRestoreController",
                    controllerAs: "ctrl",
                    locals: { options: options },
                    templateUrl: "app/sysadmin/manage/domains/modals/restore.dlg.html",
                    targetEvent: ev,
                    clickOutsideToClose: false
                })
                .then(function () {
                    if (type === 0 || type === 3)
                        refresh();
                }, function () { });
        }

        function contextMenuGridItem(item, ev) {
            if (!ev || (ev.type !== "touchstart" && ev.type !== "touchend" && ev.which !== 3) || item.status === 4) {
                return;
            }
            ev.stopPropagation();
            ev.preventDefault();

            var items = vm.checkboxes.getCheckedItems();
            //If we right clicked on a not selected item we want to use that item instead
            if ((items.length > 1 && !_.some(items, function (val) { return val === item.userName })) || items.length <= 1) {
                vm.checkboxes.reset();
                vm.checkboxes.checkCheckbox(ev, item);
                items = [item.userName];
            }

            $scope.dropdownEvent = $.extend(true, {}, ev);
            $scope.dropdownOptions = [
                { key: "deleteItems", click: deleteItems, translateKey: "DELETE" }
            ];

            $scope.dropdownOptions.unshift({ divider: true });

            var selected = {};
            for (var i = 0; i < items.length; ++i) {
                selected[items[i]] = true;
            }
            if (items.length > 0 && (vm.activeSyncLicensed || vm.mapiEwsLicensed)) {
                $scope.dropdownOptions.unshift(
                    { key: "resyncusers", click: resyncUsers, params: selected, translateKey: "RESYNC_DEVICES", disabled: items.length > vm.maxResyncRequestsAllowed }
                );
            }

            $scope.dropdownOptions.unshift(
                { key: "recalculateItems", click: recalculateItems, params: selected, translateKey: "REFRESH_DISK_USAGE" }
            );
            $scope.dropdownOptions.unshift(
                { key: "reindexItems", click: reindexItems, params: selected, translateKey: "REINDEX" }
            );
            $scope.dropdownOptions.unshift(
                { key: "expirePasswords", click: expirePasswords, params: selected, translateKey: "EXPIRE_PASSWORD" }
            );

            $scope.dropdownOptions.unshift({ divider: true });
            $scope.dropdownOptions.unshift(
                { key: "disableUsers", click: disableUsers, params: selected, translateKey: "DISABLE" }
            );
            $scope.dropdownOptions.unshift(
                { key: "enableUsers", click: enableUsers, params: selected, translateKey: "ENABLE" }
            );

            if (items.length == 1 && (vm.allowImpersonation)) {
                $scope.dropdownOptions.unshift({ divider: true });
                $scope.dropdownOptions.unshift(
                    { key: "impersonate", click: impersonateUser, params: selected, translateKey: "IMPERSONATE_USER" }
                );
            }

            var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
            var element = $("#context-menu-area");
            if (element) {
                var elementCompiled = $compile(elementToCompile)($scope);
                element.append(elementCompiled);
            }
        }

        function doImpersonate(ev) {
            var items = vm.checkboxes.getCheckedItems();
            var selected = {};
            for (var i = 0; i < items.length; ++i) {
                selected[items[i]] = true;
            }
            impersonateUser(selected);
        }

        function impersonateUser(selectedCards) {
            var email = "";
            angular.forEach(selectedCards, function (card, key) {
                email = key + "@" + vm.domain
            });

            if (claimsService.impersonating())
                impersonationService.impersonateUserReplaceCurrent(email);
            else
                impersonationService.impersonateUser(email);
        }

        function queryData(params) {
            var accountTypes = {
                1: "USER",
                2: "DOMAIN_ADMINISTRATOR",
                3: "PRIMARY_ADMINISTRATOR",
                4: "USER_ALIAS",
                5: "MAILINGLIST"
            };

            var authTypes = {
                1: "DOMAIN_MANAGEMENT_LOCAL",
                2: "ACTIVE_DIRECTORY",
                3: "TWO_FACTOR_SHORT",
                4: "LDAP"
            };

            vm.searchParams.skip = (params.page() - 1) * params.count();
            vm.searchParams.take = params.count();

            vm.searchParams.sortField = null;
            vm.searchParams.searchFlags = ["users"];

            for (var k in params.sorting()) {
                if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
                    continue;
                vm.searchParams.sortField = k;
                vm.searchParams.sortDescending = params.sorting()[k] === "desc";
                break;
            }

            return $http
                .post("~/api/v1/settings/domain/account-list-search", JSON.stringify(vm.searchParams))
                .then(function (result) {
                    vm.aupEnabled = vm.domainSettings.acceptableUsePolicySettings.enabled;
                    vm.searchResults = result.data.results;
                    angular.forEach(vm.searchResults, function (value) {
                        value.accountTypeString = accountTypes[value.accountType];
                        value.authTypeString = authTypes[value.authType];
                        value.emailAddress = value.userName + "@" + vm.domain;
                        value.accountTypeClass =
                            value.aliasIsCatchAll ? "red-text" : null;
                        value.percent = value.bytesAllowed ? ((value.bytesUsed || 0) * 100.0 / value.bytesAllowed) : null;
                        value.lastLoginTime = new Date(value.lastLoginTime);
                        value.easLastLogin = new Date(value.easLastLogin);
                        value.mapiLastLogin = new Date(value.mapiLastLogin);
                        value.ewsLastLogin = new Date(value.ewsLastLogin);
                        value.popLastLogin = new Date(value.popLastLogin);
                        value.imapLastLogin = new Date(value.imapLastLogin);
                        value.lastLogin = new Date(value.lastLogin);
                    });
                    vm.searchResultCount = result.data.totalCount;
                    params.total(vm.searchResultCount);
                    return vm.searchResults;
                }, function (result) { })
                .catch(function (e) { });
        }

        function getCheckedItems() {
            var retVal = [];
            for (var k in vm.checkboxes.items) {
                if (vm.checkboxes.items.hasOwnProperty(k) && vm.checkboxes.items[k])
                    retVal.push(k);
            }
            return retVal;
        }

        function refresh() {
            vm.checkboxes.reset();
            vm.tableParams.reload();
        }

        function refreshAndNotify() {
            refresh();
            $rootScope.$broadcast("refresh-users-counts");
        }
        
        function checkIfPrimary(accountType, userName) {
            if (accountType == "PRIMARY_ADMINISTRATOR") {
                if (vm.checkboxes.items[userName]) {
                    vm.primaryDomainAdminSelected = true;
                } else {
                    vm.primaryDomainAdminSelected = false;
                }
            }
        }
    }

})();
