(function () {
	"use strict";

	// Processes Enter Key commands on input controls. 
	
	// Usage Example:
	// <input type="text" st-enter-key="someFunction();" />

	// ReSharper disable once UndeclaredGlobalVariableUsing
	angular
		.module("smartertools")
		.directive("stEnterKey",stEnterKeyDirective);

	function stEnterKeyDirective() {
		return function (scope, element, attrs) {
			element.bind("keydown keypress",
				function(event) {
					var keyCode = event.which || event.keyCode;

					// Not Enter key
					if (keyCode !== 13)
						return;

					// Shift+Enter is enabled and pressed
					if (attrs.hasOwnProperty("stShiftEnter") && event.shiftKey)
						return;
					
					scope.$apply(function () {
						// Evaluate the expression
						scope.$eval(attrs.stEnterKey);
					});

					event.preventDefault();
				});
		};
	}
})();