(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("contactAlreadyExistsController", contactAlreadyExistsController);

    function contactAlreadyExistsController($scope, $mdDialog, $translate) {
        $scope.delete = function () {
            $mdDialog.hide({ notify: $scope.notify });
            // Don't forget to execute the api to delete
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        }
    };
})();