(function () {
	'use strict';

	angular
		.module('smartertools')
		.service('unsavedChangesTrackingService', unsavedChangesTrackingService)
		.directive('confirmOnUnsavedChanges', confirmOnUnsavedChanges);

	function confirmOnUnsavedChanges() {
		return {
			restrict: 'A',
			controller: confirmOnUnsavedChangesController, 
			link: function (scope, element, attr) {
			}
		};
	}

	function unsavedChangesTrackingService() {
		var vm = this;
		vm.skipNextCheck = false;
		vm.doConfirmDialog = undefined;
	}

	function confirmOnUnsavedChangesController($rootScope, $scope, $state, $filter, $mdDialog, $translate, $attrs, unsavedChangesTrackingService, $transitions, $q) {
		var vm = this;
		vm.isIE = false;

		activate();

		function activate() {
			if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) 
				vm.isIE = true;

			unsavedChangesTrackingService.skipNextCheck = false;
			$transitions.onStart({}, onStateChangeStart);
			unsavedChangesTrackingService.doConfirmDialog = doConfirmDialog;
		}

		function onStateChangeStart(event) {
			var toState = event.$to();
			if (unsavedChangesTrackingService.skipNextCheck) {
				unsavedChangesTrackingService.skipNextCheck = false;
				return;
			}

			var hasChanges = $scope.$eval($attrs.confirmOnUnsavedChanges);
			if (!hasChanges || vm.isIE)
				return;

			if (vm.stateChangeTriggeredByDialog) {
				vm.stateChangeTriggeredByDialog = false;
				return;
			}

			var alert = $mdDialog.confirm({
				title: $translate.instant('UNSAVED_CHANGES'),
				textContent: $translate.instant('CONFIRMATIONS_UNSAVED_CHANGES_TEXT'),
				ok: $translate.instant('LEAVE_PAGE'),
				cancel: $translate.instant('CANCEL')
			});

			$mdDialog
				.show(alert)
				.then(function () {
					vm.stateChangeTriggeredByDialog = true;
					$state.go(toState, event.params(), { inherit: true });
				}, function () {});

			return false; //cancel transition
		}

		function doConfirmDialog() {
			var defer = $q.defer();
			if (unsavedChangesTrackingService.skipNextCheck) {
				unsavedChangesTrackingService.skipNextCheck = false;
				defer.resolve();
				return defer.promise;
			}

			var hasChanges = $scope.$eval($attrs.confirmOnUnsavedChanges);
			if (!hasChanges || vm.isIE)
			{ defer.resolve(); return defer.promise; }

			var alert = $mdDialog.confirm({
				title: $translate.instant('UNSAVED_CHANGES'),
				textContent: $translate.instant('CONFIRMATIONS_UNSAVED_CHANGES_TEXT'),
				ok: $translate.instant('LEAVE_PAGE'),
				cancel: $translate.instant('CANCEL')
			});

			$mdDialog.show(alert).then(function () {
				defer.resolve();
			}, function () {
				defer.reject();
			});

			return defer.promise;
		}

	}
})();


