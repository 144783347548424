(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("tokenRefreshService", tokenRefreshService);

    function tokenRefreshService($injector, authStorage) {
        var service = {};
        service.tokenExpired = tokenExpired;
        service.refreshToken = refreshToken;
        return service;

        function tokenExpired() {
            var token = authStorage.getToken();
            if (token != undefined && token != "") {

                var now = new Date().getTime();
                var expireConverted = new Date(authStorage.getAccessTokenExpiration());
                var accessTokenExpirationBuffer = 60000;
                var expires = expireConverted.getTime() - accessTokenExpirationBuffer;
                if (now > expires) {
                    // We need to refresh our token.
                    // Send back undefined so the user knows to get their new tokens
                    return undefined;
                } else {
                    // Our token is still good.  No need to refresh at this time.
                    // Return the token so the user can make their API call.
                    return token;
                }
            }
            return undefined;
        }

		function refreshToken() {
			var refreshToken = authStorage.getRefreshToken();
            var http = $injector.get("$http");
            var clientId = authStorage.getClientId();
            var defer = http.post(stSiteRoot + "api/v1/auth/refresh-token", { token: refreshToken, iswebmailrefresh: true, clientId: clientId })
            return defer;
        }
    }
})();