(function () {
	"use strict";

	angular
		.module("smartertools")
		.directive("validateRblHost", validateRblHost);

	function validateRblHost($q, emailValidationService) {
		return {
			require: "ngModel",
			link: function (scope, element, attrs, ctrl) {
				ctrl.$validators.validateRblHost = function (modelValue, viewValue) {
					return emailValidationService.isValidRbl(viewValue);
				};
			}
		};
	}
})();