(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("remoteContentExceptionsDialogController", remoteContentExceptionsDialogController);

	function remoteContentExceptionsDialogController($rootScope, $mdDialog, exceptions, header) {
		var vm = this;
		vm.textValue = "";
		vm.header = header;
		vm.exceptions = exceptions || [];
		vm.exceptions.sort();
		vm.textValue = vm.exceptions.join("\r\n");
		
		vm.save = function () {
			var arr = vm.textValue.split(/\r?\n/);
			if (vm.textValue == "") {
				arr = [];
			}
			$mdDialog.hide({ exceptions:arr});
		};

		vm.cancel = function () {
			$mdDialog.cancel();
		};
	}

})();
