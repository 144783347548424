(function () {
    "use strict";

    angular
        .module("smartertools")
        .directive("validateFullyQualifiedDomainPath", validateFullyQualifiedDomainPath);

    function validateFullyQualifiedDomainPath($http, $q) {
        return {
            require: "ngModel",
            link: function (scope, element, attrs, ctrl) {
                ctrl.$asyncValidators.validateFullyQualifiedDomainPath = function (modelValue, viewValue) {
                    if (!viewValue) return $q.when(true);
                    var defer = $q.defer();
                    
                    var preCheck = window.stOS == 'L'
                        ? validateFullyQualifiedDomainPathLinux(viewValue)
                        : validateFullyQualifiedDomainPathWindows(viewValue);
                    if (!preCheck) {
                        defer.reject();
                        return defer.promise;
                    }
                    
                    $http
                        .post('~/api/v1/settings/sysadmin/validate-domain-path', { input: viewValue })
                        .then(function (success) {
                            defer.resolve();
                        }, function (failure) {
                            defer.reject(failure);
                        });

                    return defer.promise;
                };
            }
        };

        function validateFullyQualifiedDomainPathLinux(viewValue) {
            // Ensure folder starts with / or ~/
            if (!/^(\/|~\/)/.test(viewValue))
                return false;

            // Ensure folder does not only contain / or ~/
            if (/^(\/|~\/)$/.test(viewValue))
                return false;

            // Check each part to make sure it doesn't have invalid characters
            let parts = viewValue.split('/');
            for (var i = 0; i < parts.length; ++i) {
                let part = parts[i];
                if (i == 0 && (part == '~' || part == ''))
                    continue;

                if (part.length == 0 && i != parts.length - 1)
                    return false;
                if (part.startsWith("."))
                    return false;
                let regexAllowedChars = /^[a-zA-Z0-9._-]*$/;
                if (!regexAllowedChars.test(parts[i]))
                    return false;
            }
            return true;
        }

        function validateFullyQualifiedDomainPathWindows(viewValue) {
            //if the directory doesnt start with *:\ or \\
            if (!(/((^[a-zA-Z]:\\)|(^\\\\[^\\]))/).test(viewValue)) {
                return false;
            }
            //if the directory only contains *:\ or \\
            if ((/((^[a-zA-Z]:\\$)|(^\\\\[^\\]$))/).test(viewValue)) {
                return false;
            }
            return true;
        }
    }
})();
