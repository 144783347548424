(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("eventData", eventData);

	/* @ngInject */
	function eventData($rootScope, $timeout, $log, $filter, $translate, $http, $q, userDataService) {
		var vm = this;
		vm.isSysAdmin = userDataService.user.isSysAdmin;
		vm.availableUserEvents = [];
		vm.availableDomainEvents = [];
		vm.eventTypeOptions = [];
		vm.eventIDGetterObject = [];
		vm.eventCategoryOptions = [];
		vm.eventIsLoading = true;

		// Functions
		vm.getEventWhenString = getEventWhenString;
		vm.getEventWithString = getEventWithString;
		vm.getEventThenString = getEventThenString;
		vm.getActionTranslation = getActionTranslation;
		vm.getGenericConditionTranslation = getGenericConditionTranslation;
		vm.getConditionTranslation = getConditionTranslation;
		vm.getConditionTypeString = getConditionTypeString;
		vm.getActionMiddleString = getActionMiddleString;
		vm.getActionBottomString = getActionBottomString;

		activate();

		///////////////////////////

		function activate() {
			refreshItems();
			$rootScope.$on('$translateChangeSuccess', refreshItems);
		}

		function refreshItems() {
			vm.eventIsLoading = true;
			var promises;
			if (vm.isSysAdmin) {
				promises = [$http.get('~/api/v1/settings/sysadmin/sysadmin-events')];
			} else {
				promises = [
					$http.post('~/api/v1/settings/user-events'),
					$http.get('~/api/v1/settings/domain/domain-events')
				];
			}
			$q
				.all(promises)
				.then(function (results) {

					if (vm.isSysAdmin)
						vm.availableUserEvents = results[0].data.events;
					else {
						vm.availableUserEvents = results[0].data.events;
						vm.availableDomainEvents = results[1].data.events;
					}
					fillOptions();
					vm.eventIsLoading = false;
				}, function () {
						vm.eventIsLoading = false;
				});
		}

		function fillOptions() {
			var eventIDTracker = {};
			vm.eventTypeOptions = [];
			vm.eventIDGetterObject = [];
			vm.eventCategoryOptions = [];
			for (var key in vm.availableUserEvents) {
				var event = vm.availableUserEvents[key];
				var nKey = Number(key);

				// vm.eventCategoryOptions
				if (eventIDTracker[event.categoryResourceId] === undefined) {
					vm.eventCategoryOptions.push({ value: nKey, translation: event.categoryResourceId.replace("@", "").toUpperCase() });
					eventIDTracker[event.categoryResourceId] = nKey;
				}

				// vm.eventIDGetterObject
				vm.eventIDGetterObject[nKey] = eventIDTracker[event.categoryResourceId];

				// vm.eventTypeOptions
				if (vm.eventTypeOptions[eventIDTracker[event.categoryResourceId]] === undefined)
					vm.eventTypeOptions[eventIDTracker[event.categoryResourceId]] = [];
				vm.eventTypeOptions[eventIDTracker[event.categoryResourceId]].push({ value: nKey, translation: "EVENT_TYPES_" + event.id.toUpperCase() });
			}
			for (var key in vm.availableDomainEvents) {
				var event = vm.availableDomainEvents[key];
				var nKey = Number(key);

				// vm.eventCategoryOptions
				if (eventIDTracker[event.categoryResourceId] === undefined) {
					vm.eventCategoryOptions.push({ value: nKey, translation: event.categoryResourceId.replace("@", "").toUpperCase() });
					eventIDTracker[event.categoryResourceId] = nKey;
				}

				// vm.eventIDGetterObject
				vm.eventIDGetterObject[nKey] = eventIDTracker[event.categoryResourceId];

				// vm.eventTypeOptions
				if (vm.eventTypeOptions[eventIDTracker[event.categoryResourceId]] === undefined)
					vm.eventTypeOptions[eventIDTracker[event.categoryResourceId]] = [];
				vm.eventTypeOptions[eventIDTracker[event.categoryResourceId]].push({ value: nKey, translation: "EVENT_TYPES_" + event.id.toUpperCase() });
			}
			vm.eventCategoryOptions = $filter("orderBy")(vm.eventCategoryOptions, "translation")
			$rootScope.$applyAsync();
		};

		function getEventWhenString(event) {
			var options = vm.eventTypeOptions[vm.eventIDGetterObject[event.eventID]];
			if (!options)
				return null;
			for (var i = 0; i < options.length; i++) {
				if (options[i].value == event.eventID)
					return $filter("translate")(options[i].translation.replace("EVENT_TYPES_", "EVENT_TYPES_WHEN_"));	// EVENT_TYPES_EXAMPLE >> EVENT_TYPES_WHEN_EXAMPLE
			}
		};

		function getEventWithString(event, isUserEvent) {
			// Events may have one or two default conditions not set by the user
			var defaultConditionCount = isUserEvent ? 2 : (isUserEvent === false ? 1 : 0);
			//if (event.eventID === 20013) // User changes password event
			//	defaultConditionCount = 2;

			var conditionCount = event.conditions ? event.conditions.length : 0;
			if (conditionCount > 0 && conditionCount === defaultConditionCount) {
				//if (defaultConditionCount == 1)
					return $filter("translate")("EVENT_CONDITIONS_" + event.conditions[0].key.toUpperCase()) + ' ' + event.conditions[0].value1;
				//else
					//return $filter("translate")("EVENT_CONDITIONS_" + event.conditions[0].key.toUpperCase()) + ' ' + event.conditions[0].value1 + ' ' + $filter("translate")("AND_PASSWORD");
			} else if (conditionCount === defaultConditionCount + 1) {
				var condition = $.grep(event.conditions, function (cond) { return cond.key !== 'pasword'; });
				if (condition.length > 0)
					condition = condition[0];
				else
					condition = undefined;

				if (condition)
					return getGenericConditionTranslation(condition);
				else
					return $filter("translate")('UNDEFINED');

			} else if (conditionCount > defaultConditionCount + 1) {
				return $filter("translate")('MULTIPLE', { count: conditionCount - defaultConditionCount });
			} else {
				return $filter("translate")('NO_CONDITIONS');
			}
		};

		function getEventThenString(event) {
			var actionCount = event.actions ? event.actions.length : 0;
			if (actionCount === 1) {
				var action = event.actions[0];
				if (action)
					return getActionTranslation(action.key);
				else
					return $filter("translate")('UNDEFINED');
			} else if (actionCount > 1)
				return $filter("translate")('MULTIPLE', { count: actionCount });
			else
				return $filter("translate")('NO_ACTIONS');
		};

		function getActionTranslation(key) {
			return $filter("translate")("EVENT_ACTIONS_" + key.toUpperCase());
		};

		function getGenericConditionTranslation(condition, minicard) {
			var value1;
			var value2;
			var type = getConditionTypeString(condition.condition);
			var conditionKey = getConditionTranslation(condition.key);
			var useValue2 = Boolean(condition.value2);
			var percent = false;

			switch (condition.key) {
				case "eventoccured":
					// Singapore timezone in 1970 was +730, now it's +800
					// So we start at UTC today plus the timezone difference to get a modern day epoch time, then we add the eventOccurred values
					var momentBase = moment().hour(moment().hours() - moment().utc().hours()).minutes(moment().minutes() - moment().utc().minutes()).second(0);
					value1 = momentBase.add(condition.value1 / 10000).format("hh:mm A");
					momentBase.add(-(condition.value1 / 10000));
					value2 = useValue2 ? momentBase.add(condition.value2 / 10000).format("hh:mm A") : undefined;
					break;
				case "daysofweek":
					value1 = getConditionDayString(condition.value1);
					value2 = useValue2 ? getConditionDayString(condition.value2) : undefined;
					break;
				case "sizekb":
					value1 = $filter('bytes')(condition.value1 * 1024, 1);
					value2 = useValue2 ? $filter('bytes')(condition.value2 * 1024, 1) : undefined;
					break;
				case "mailboxusagemb":
					conditionKey = conditionKey.replace("(MB)", "");
					value1 = $filter('bytes')(condition.value1 * 1024 * 1024, 1);
					value2 = useValue2 ? $filter('bytes')(condition.value2 * 1024 * 1024, 1) : undefined;
					break;
				case "intradomain":
					value1 = $filter("translate")(condition.value1.toString().toUpperCase());
					break;
				case "spamlevel":
					value1 = $filter("translate")(condition.value1.toString().toUpperCase());
					break;
				case "status":
					value1 = $filter("translate")("EVENTS_" + condition.value1.toString().toUpperCase());
					break;
				case "mailboxusagepercent":
					conditionKey = conditionKey.replace("(%)", "");
				case "percentcomplete":
					percent = true;
				default:
					value1 = condition.value1;
					value2 = useValue2 ? condition.value2 : undefined;
					break;
			}

			if (minicard) {
				switch (condition.condition.toLowerCase()) {
					case "lessthan":
					case "greaterthan":
						if (value1 == value2)
							value2 = undefined;
				}
				return $filter("translate")("EVENT_CONDITIONS_MINICARD_GENERIC_CONDITION", { value1: value1, value2: value2, percent: percent });
			} else {
				
				switch (condition.condition.toLowerCase()) {
					case "lessthan":
					case "greaterthan":
						return $filter("translate")('EVENT_CONDITIONS_CARD_GENERIC_CONDITION', { condition: conditionKey, is: true, type: type, value1: value1, percent: percent });
					default: // Equals, Between, Outside, DoesNotEqual, Contains, Starts With, Ends With
						return $filter("translate")('EVENT_CONDITIONS_CARD_GENERIC_CONDITION', { condition: conditionKey, is: useValue2, type: type, value1: value1, value2: value2, percent: percent });
				}
			}
		}

		function getConditionTranslation(key) {
			return $filter("translate")("EVENT_CONDITIONS_" + key.toUpperCase());
		};

		function getConditionTypeString(condition) {
			return $filter("translate")(condition.toUpperCase());
		};

		function getActionMiddleString(action, level) {
			var value;
			var label = $filter("translate")("TO");

			switch (action.key) {
				case "AlertUserAction":
				case "AlertAction":
				case "EmailAction":
					var recipientType = getActionInputValueByKey(action.inputs, 'recipienttype', "SpecifiedUser");
					
					if (recipientType === "SpecifiedUser") {
						value = getActionInputValueByKey(action.inputs, 'emailto');
					}
					else if (recipientType !== "") {
						value = $filter('translate')(`EVENT_ACTIONS_RECIPIENT_TYPE_${recipientType.toUpperCase()}`, {level: level});
					} else {
						value = $filter('translate')("UNDEFINED");
					}
					label = $filter("translate")("TO");
					break;
				case "TrustedSender":
				case "AddRecipientAction":
					return '';
				case "CommandLineAction":
					value = getActionInputValueByKey(action.inputs, 'process', "");
					label = $filter("translate")("EVENTS_PROCESS");
					break;
				default :
					break;
			}

			if (value)
				return `${label}: ${value}`;
			else
				return $filter('translate')("UNDEFINED");
		};
		function getActionInputValueByKey(inputs, key, defaultVal = "") {
			var results = $.grep(inputs, function(x) { return x.key === key; });
			return (results && results[0]) ? results[0].value : defaultVal;

		}
		function getActionBottomString(action) {
			var action;

			switch (action.key) {
				case "AlertUserAction":
				case "AlertAction":
					var value = $.grep(action.inputs, function (input) { return input.key === 'alertsubject'; });
					action = $filter("translate")("SUBJECT");
					break;
				case "EmailAction":
					var value = $.grep(action.inputs, function (input) { return input.key === 'emailsubject'; });
					action = $filter("translate")("SUBJECT");
					break;
				case "TrustedSender":
					var value = $.grep(action.inputs, function (input) { return input.key === 'addaddress'; });
					action = $filter("translate")("ADD_ADDRESS");
					break;
				case "AddRecipientAction":
					var value = $.grep(action.inputs, function (input) { return input.key === 'addrecipient'; });
					action = $filter("translate")("RECIPIENT");
					break;
				case "CommandLineAction":
					var value = $.grep(action.inputs, function (input) { return input.key === 'arguments'; });
					action = $filter("translate")("EVENTS_ARGUMENTS");
					break;
			}

			if (value && value[0])
				return $filter('translate')("EVENT_ACTIONS_GENERIC_MINICARD_ACTION", { action: action, value: value[0].value });
			else
				return $filter('translate')("UNDEFINED");
		};

		function getConditionDayString(condition) {
			switch (condition) {
				case 0:
					return $filter("translate")('SUNDAY');
				case 1:
					return $filter("translate")('MONDAY');
				case 2:
					return $filter("translate")('TUESDAY');
				case 3:
					return $filter("translate")('WEDNESDAY');
				case 4:
					return $filter("translate")('THURSDAY');
				case 5:
					return $filter("translate")('FRIDAY');
				case 6:
					return $filter("translate")('SATURDAY');
				default:
					return $filter("translate")('UNDEFINED');
			}
		};
	}

})();