(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("adminSpoolManageMainController", adminSpoolManageMainController);

	function adminSpoolManageMainController($scope, $filter, $q, $http, $state, $timeout, errorHandling, $localStorage,
		coreData, coreDataSpool, authStorage, signalrHubManager) {

		var vm = this;
		$state.current.data.pageTitle = $filter("translate")("SPOOL_MANAGEMENT");
		switch ($localStorage.spoolSection) {
			case "OVERVIEW":
			case "SPOOL":
			case "WAITING_TO_DELIVER":
			case "SPOOL_SECTIONS_SPAM_QUARANTINE":
			case "VIRUS_QUARANTINE":
			case "SPOOL_SECTIONS_TUSERS":
			case "SPOOL_SECTIONS_TDOMAINS":
				vm.selectedSection = $localStorage.spoolSection;
				break;
			default:
				vm.selectedSection = "OVERVIEW";
				break;
		}
		vm.gridSections = [
			{ translate: "OVERVIEW", length: -1 },
			{ translate: "SPOOL", length: 0 },
			{ translate: "WAITING_TO_DELIVER", length: 0 },
			{ translate: "SPOOL_SECTIONS_SPAM_QUARANTINE", length: 0 },
			{ translate: "VIRUS_QUARANTINE", length: 0 },
			{ translate: "SPOOL_SECTIONS_TUSERS", length: 0 },
			{ translate: "SPOOL_SECTIONS_TDOMAINS", length: 0 }
		];

		// Functions
		vm.changeSection = changeSection;
		$scope.selectCard = selectCard;
		vm.getSpoolMessages = getSpoolMessages;
		//vm.hasHitQuarantineLimit = hasHitQuarantineLimit;
		//vm.hasHitSpoolLimit = hasHitSpoolLimit;

		activate();

		/////////////////////

		function activate() {
			coreData
				.init()
				.then(onSuccess, errorHandling.report);

			signalrHubManager
				.init()
				.then(onSignalSuccess);

			function onSuccess() {
				var promises = [];
				changeSection(vm.selectedSection);
				promises.push(vm.getSpoolMessages());
				$q.all(promises)
					.then(function () {
						$scope.isInit = true;
					}, function () {
						errorHandling.report();
					});
			};

			function onSignalSuccess(connection) {
				//connection.mailHub.invoke("connectWithStartData", authStorage.getToken(), ["spoolCount", "onlineUsers", "currentConnections", "idsBlocks", "selfBlacklist"], ["spoolCount", "onlineUsers", "currentConnections", "idsBlocks", "selfBlacklist"])
				//	.done(function () { });
				$scope.$on("signalR.client.dashboardUpdate", onDashboardUpdate);
			}

			$scope.$on("reloadSpool", getSpoolMessages);
		}

		function getSpoolMessages() {
			return $http.get("~/api/v1/settings/sysadmin/spool-message-counts")
                .then(function (success) {
					vm.gridSections[1].length = success.data.counts.default || 0;
					vm.gridSections[2].length = success.data.counts.waiting || 0;
					vm.gridSections[3].length = success.data.counts.spam || 0;
					vm.gridSections[4].length = success.data.counts.virus || 0;
					vm.gridSections[5].length = (success.data.counts.throttledUsers || 0) + (success.data.counts.throttledMailingLists || 0);
					vm.gridSections[6].length = success.data.counts.throttledDomains || 0;

					vm.quarantineDisplayLimit = success.data.counts.quarantine_limit || 5000;
                    vm.spoolDisplayLimit = success.data.counts.spool_limit || 50000;

                    vm.hasHitQuarantineLimit = vm.gridSections[3].length + vm.gridSections[4].length >= vm.quarantineDisplayLimit;
                    vm.hasHitSpoolLimit = vm.gridSections[1].length + vm.gridSections[2].length >= vm.spoolDisplayLimit;
				}, function (failure) {
					errorHandling.report(failure.data.message);
				});
		}

		function onDashboardUpdate(ev, data) {
			$scope.$evalAsync(function () {
				//vm.gridSections[1].length = data.spoolCount;
                getSpoolMessages();
			});
		}

		function changeSection(section) {
			if (vm.selectedSection !== section) {
				vm.selectedSection = section;
				$localStorage.spoolSection = section;
				coreDataSpool.changeSection(section);
			}
		}

		function selectCard(selectedCard, ev) {
			coreDataSpool.selectCard(selectedCard, ev);
		}
	}
})();
