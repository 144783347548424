(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("manageCategoriesDialogController", manageCategoriesDialogController)
        .directive("validateCategoryName", ["$filter", "coreDataCategories", validateCategoryName]);

    function manageCategoriesDialogController($rootScope, $scope, $mdDialog, $filter, preferencesStorage, coreDataCategories, errorHandling, $timeout) {
        var vm = this;
        vm.categories = [];
        $scope.deletedCatIds = [];
        $scope.saving = false;

        // Functions
        $scope.delete = onDelete;
        $scope.cancel = onCancel;
        $scope.onNewCategoryBlur = onNewCategoryBlur;
        $scope.onNewCategoryChanged = onNewCategoryChanged;
        $scope.save = onSave;
        init();

        //////////////////////

        function init() {
            coreDataCategories.init()
                .then(function () {
                    vm.categories = coreDataCategories.getUsersCategories();
                    vm.categories.push({ colorIndex: -1 });
                }, $mdDialog.cancel);
        }

        function onNewCategoryBlur(last, index, form) {
            var newCat = form["catName_" + index].$viewValue;
            if ((!newCat || newCat.length === 0) && !last) {
                vm.categories.splice(index, 1);
                $scope.userForm.$setDirty();
            }
        }

        function onNewCategoryChanged(last, index, form) {
            var newCat = form["catName_" + index].$viewValue;
            if (newCat && newCat.length > 0 && last) {
                vm.categories.push({ colorIndex: -1 });
                $scope.userForm.$setDirty();
                $timeout(function () {
                    var field = document.getElementsByName("catName_" + index);
                    if (field && field.length) field[0].focus();
                });
            }
        }

        async function onSave(event, form) {
            if (form.$invalid)
                return;

            $scope.saving = true;

            var categoriesToSave = vm.categories.filter(x => !x.pendingRemoval);
            categoriesToSave.splice(categoriesToSave.length - 1);

            categoriesToSave.forEach(cat => {
                cat.name = cat.translatedName;
                cat.translatedName = null;
            });

            try {
                await coreDataCategories.setCategories(categoriesToSave);
                // nothing to do here as SignalR should trigger a rebuild of the category filters
            }
            catch (e) {
                errorHandling.report(e);
            }
            finally {
                $scope.saving = false;
                $mdDialog.hide();
            }
        };

        function onDelete(category) {
            if (category.guid) {
                // If it's an existing category, mark it for removal
                category.pendingRemoval = true;
                $scope.userForm.$setDirty();
            } else {
                // If it's a new one, just splice it out
                for (let i = 0; i < vm.categories.length; i++) {
                    if (vm.categories[i] === category) {
                        vm.categories.splice(i, 1);
                        break;
                    }
                }
            }
        };

        function onCancel() {
            $mdDialog.cancel();
        };
    }

    function validateCategoryName($filter, coreDataCategories) {

        return {
            restrict: "A",
            require: "ngModel",
            link: function (scope, element, attrs, ctrl) {
                scope.$watch(() => ctrl.$viewValue,
                    (val) => {
                        if (!scope.origName) scope.origName = val;
                    });
                ctrl.$validators.validateCategoryName = function (modelValue, viewValue) {
                    return !viewValue || (viewValue.indexOf(';') === -1 &&
                        viewValue.indexOf(',') === -1 &&
                        viewValue.indexOf('\u061B') === -1 &&
                        viewValue.indexOf('\uFE54') === -1 &&
                        viewValue.indexOf('\uFF1B') === -1);
                }
                ctrl.$validators.duplicateCategoryName = function (modelValue, viewValue) {
                    let categoryNames = coreDataCategories.getUsersCategories().map(x => $filter("translate")(x.name).toLowerCase());
                    if (scope.origName && viewValue && scope.origName !== viewValue) {
                        return categoryNames.indexOf(viewValue.toLowerCase()) === -1;
                    }
                    return true;
                }
            }
        }

    }
})();
