(function () {

	"use strict";

	angular
		.module("smartermail")
		.service("coreData", coreData);

	function coreData($rootScope, $http, $q, authStorage, userDataService,
		coreDataCalendar, coreDataContacts, coreDataNotes, coreDataMail, coreDataTasks, coreDataFileStorage, coreDataRss,
		coreDataSettings, coreDataDomainSettings, coreDataListener, coreDataSysAdminSettings,
		coreDataLicensing, coreDataSpool, coreDataCategories, $transitions, apiCategories) {
		var vm = this;

		var _isInitialized = false;
		this.isInitialized = function () {
			return _isInitialized;
		};
		vm.generateGuid = function () {
			return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
		}
		vm.customHelpInfo = { text: '', url: '' };

		var initDefer;

		this.init = function (args) {
			args = args || {};

			if (initDefer) {
				return initDefer.promise;
			}

			if (_isInitialized) {
				return $q.when();
			}

			initDefer = $q.defer();
			var promise = initDefer.promise;

			$rootScope.isPopout = (window.location.href.indexOf('#/popout/') != -1);
			if (!$rootScope.isPopout) authStorage.getPopoutData();

			if (args.showSpinner) {
				$rootScope.spinner.reset();
				$rootScope.spinner.show();
			}

			vm.reset();

			var promises = [];
			promises.push(loadCustomHelpInfo()); //This needs to be here so it happens before the menu nav loads.
			promises.push(userDataService.init());
			$q.all(promises)
				.then(function () {
					promises.length = 0;
					if (!userDataService.user.isSysAdmin) {
						if (window.location.href.indexOf('/popout/note') > 0) {
							promises.push(coreDataNotes.init());
							promises.push(coreDataCategories.init());
						} else if (window.location.href.indexOf('/popout/task') > 0) {
							promises.push(coreDataTasks.init());
							promises.push(coreDataCategories.init());
						} else if (window.location.href.indexOf('/popout/appointment') > 0) {
							promises.push(coreDataCalendar.init());
							promises.push(coreDataCategories.init());
						} else if (window.location.href.indexOf('/popout/settings/connect') > 0) {
						} else if (window.location.href.indexOf('/popout/chat/transcript') > 0) {
						} else {
							promises.push(coreDataSettings.init());
							if (userDataService.user.isDomainAdmin) {
								promises.push(coreDataDomainSettings.init());
							}
							$q.all(promises)
								.then(function () {
									promises.length = 0;
									promises.push(coreDataCalendar.init());
									promises.push(coreDataContacts.init());
									promises.push(coreDataNotes.init());
									promises.push(coreDataMail.init());
									promises.push(coreDataTasks.init());
									promises.push(coreDataFileStorage.init());
									promises.push(coreDataRss.init());
									promises.push(apiCategories.init());
								}, function (failure) {
									if (args.showSpinner) $rootScope.spinner.hide();
									initDefer.reject(failure);
									initDefer = null;
								});
						}

						$q.all(promises)
							.then(function () {
								if (args.showSpinner) $rootScope.spinner.hide();
								coreDataListener.start();
								_isInitialized = true;
								initDefer.resolve();
								initDefer = null;
								//$state.go("index.today");
							}, function (failure) {
								if (args.showSpinner) $rootScope.spinner.hide();
								initDefer.reject(failure);
								initDefer = null;
							});
					} else {
						promises.push(coreDataSysAdminSettings.init());
						promises.push(coreDataLicensing.init());
						promises.push(coreDataSpool.init());
						$q.all(promises)
							.then(function () {
								if (args.showSpinner) $rootScope.spinner.hide();
								coreDataListener.start();
								_isInitialized = true;
								initDefer.resolve();
								initDefer = null;
							}, function (failure) {
								if (args.showSpinner) $rootScope.spinner.hide();
								initDefer.reject(failure);
								initDefer = null;
							});
					}
				}, function (failure) {
					initDefer.reject(failure);
					initDefer = null;
				});
			setHooks();
			return promise;
		};

		this.reset = function () {
			userDataService.reset();
			coreDataSettings.reset();
			coreDataDomainSettings.reset();
			coreDataCalendar.reset();
			coreDataContacts.reset();
			coreDataNotes.reset();
			coreDataMail.reset();
			coreDataTasks.reset();
			coreDataFileStorage.reset();
			coreDataSysAdminSettings.reset();
			coreDataLicensing.reset();
			coreDataSpool.reset();
			coreDataRss.reset();
			coreDataCategories.reset();
			$rootScope.unreadEmailCount = -1;
			_isInitialized = false;
		};

		this.user = userDataService.user;

		this.baseUrl = window.location.href.substring(0, window.location.href.indexOf("/interface/root#"));

		this.defaultColors =
			[
				"#C6DE70",  // Yellow-Green
				"#F6ED6E",  // Yellow
				"#7FC56F",  // Green
				"#FDC66C",  // Yellow-Orange
				"#8AD1CB",  // Aqua
				"#F59668",  // Orange
				"#559CD4",  // Blue
				"#F4818E",  // Pink
				"#A9A6D3",  // Purple
				"#E695C0",  // Violet-Pink
			];
		var colorIndex = Math.floor(Math.random() * this.defaultColors.length);
		this.nextCalendarColor = function () {
			colorIndex = (colorIndex + 1) % vm.defaultColors.length;
			return vm.defaultColors[colorIndex];
		};

		this.getToken = function () {
			return authStorage.getToken();
		}

		$transitions.onStart({}, function () {
			//purge old dz hidden fields
			$(".dz-hidden-input").remove();
		});

		function loadCustomHelpInfo() {
			return $http.get('~/api/v1/settings/company-help')
				.then(function (success) {
					vm.customHelpInfo = { text: success.data.results[0], url: success.data.results[1] };
					$rootScope.$broadcast("loadCustomHelp")
				}, function (failure) {
					vm.customHelpInfo = { text: '', url: '' };
				});
		};

		function setHooks() {
			$rootScope.$on("customHelpUpdated", customHelpUpdated)
		}

		function customHelpUpdated(ev) {
			loadCustomHelpInfo();
		}
		this.getPendingDomainOffers = function() {
			const offers = this.user.isSysAdmin ? 
				coreDataSysAdminSettings.getPendingDomainOffers(this.user.username).map(x => x.type) :
				this.user.isDomainAdmin ? 
					coreDataDomainSettings.domainData.offersStatus.filter(x => !x.configured &&
						!x.dismissedUsers.includes(this.user.username)).map(x => x.type) 
				: [];
			const offersLoaded = sessionStorage["offersLoaded"] ? JSON.parse(sessionStorage["offersLoaded"]) : {};

			return offers.filter(x => !(offersLoaded[x]));
		}
	}
})();