(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("editAutodiscoverXml", editAutodiscoverXml);

	function editAutodiscoverXml($scope, $mdDialog, $http, domain, isMobile, text, settings) {
		$scope.name = isMobile ? "ADVANCED_MOBILE" : "ADVANCED_DESKTOP";
		$scope.text = text;
		var vm = this;
		vm.desktopProtocolNeedsToBeSet = false;
		vm.mobileProtocolNeedsToBeSet = false;

		activate();

		function activate() {
			if (!isMobile) {
				vm.desktopProtocolNeedsToBeSet = (settings["ews"].enabled === false && settings["imap"].enabled === false && settings["mapi"].enabled === false &&
					settings["pop"].enabled === false && settings['smtp'].enabled === false && settings['dav'].enabled === false);
			} else {
				vm.mobileProtocolNeedsToBeSet = (settings["eas"].enabled === false);
			}
		}

		$scope.generate = function () {
			$scope.form.$setDirty();
			if (!isMobile) {
				if (settings["ews"].enabled === false && settings["imap"].enabled === false && settings["mapi"].enabled === false &&
					settings["pop"].enabled === false && settings['smtp'].enabled === false && settings['dav'].enabled === false) {
					$scope.desktopProtocolNeedsToBeSet = true;
				} else {
					$http
						.get("~/api/v1/settings/sysadmin/ad-xml/desktop" + (domain ? "/" + domain : ""))
						.then(function (result) { $scope.text = result.data.xml; },
							function (error) { });
				}
			} else if (isMobile) {
				if (settings["eas"].enabled === false) {
					$scope.mobileProtocolNeedsToBeSet = true;
				} else {
					$http
						.get("~/api/v1/settings/sysadmin/ad-xml/mobile" + (domain ? "/" + domain : ""))
						.then(function (result) { $scope.text = result.data.xml; },
							function (error) { });
				}
			}
		};

		$scope.save = function () {
			$mdDialog.hide($scope.text);
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}

})();
