(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminSecurityUserPassSettingsController", sysAdminSecurityUserPassSettingsController);

	function sysAdminSecurityUserPassSettingsController($rootScope, $scope, $http, $filter, $state, $mdDialog, errorHandling, claimsService) {

		// Functions
		this.save = save;
		this.doCancel = doCancel;

		activate();

		//////////////////////

		function activate() {
			refresh();
		}

		function refresh() {
			$rootScope.spinner.show();
			$http
				.get("~/api/v1/settings/sysadmin/global-mail")
				.then(onGlobalMailSettingsLoaded, function () { })
				.finally($rootScope.spinner.hide);

			function onGlobalMailSettingsLoaded(result) {
				var passwordSettings = result.data.globalMailSettings.passwordSettings;
				$scope.passLength = passwordSettings.passLengthEnabled ? passwordSettings.passLength : 3;
				$scope.passExpirationInMonths = passwordSettings.passExpirationInMonths;
				$scope.passExpirationEnabled = passwordSettings.passExpirationEnabled;
				$scope.autoblockGracePeriod = passwordSettings.autoblockGracePeriod;
				$scope.notificationTimings = $filter("orderBy")(passwordSettings.autoBlockNotificationTimings, null, true).join(", ");
				$scope.passNumber = passwordSettings.passNumber;
				$scope.passCapital = passwordSettings.passCapital;
				$scope.passLower = passwordSettings.passLower;
				$scope.passSymbol = passwordSettings.passSymbol;
				$scope.passNotUsername = passwordSettings.passNotUsername;
				$scope.disablePassStrength = passwordSettings.disablePassStrength;
				$scope.enablePasswordRetrieval = passwordSettings.enablePasswordRetrieval;
				$scope.preventCommonPasswords = passwordSettings.preventCommonPasswords;
				$scope.preventPreviousPasswords = passwordSettings.preventPreviousPasswords;
				$scope.preventPreviousPasswordsCount = passwordSettings.preventPreviousPasswordsCount;
				$scope.enableAutoblockOutgoingSmtp = passwordSettings.enableAutoblockOutgoingSmtp;
			}
		}

		function doCancel(ev, form) {
			if (!form.$dirty) return;
			$mdDialog
				.show($mdDialog.confirmRevertChanges().targetEvent(ev))
				.then(function () { refresh(); form.$setPristine(); }, function () { });
		}

		function save(ev, form) {
			if (form.$invalid) {
				errorHandling.report("CORRECT_INVALID_FIELDS");
				return;
			}

			var timingArray = $scope.notificationTimings.split(",");
			var parsedNotificationTimings = [];
			for (var i = 0; i < timingArray.length; i++) {
				parsedNotificationTimings.push(Number(timingArray[i].trim()));
			}
			var params = JSON.stringify({
				globalMailSettings: {
					passwordSettings: {
						passLength: $scope.passLength,
						passLengthEnabled: true,
						passExpirationInMonths: $scope.passExpirationInMonths,
						passExpirationEnabled: $scope.passExpirationEnabled,
						autoblockGracePeriod: $scope.autoblockGracePeriod,
						autoBlockNotificationTimings: $filter("orderBy")(parsedNotificationTimings, null, true),

						passNumber: $scope.passNumber,
						passCapital: $scope.passCapital,
						passLower: $scope.passLower,
						passSymbol: $scope.passSymbol,
						passNotUsername: $scope.passNotUsername,
						disablePassStrength: $scope.disablePassStrength,
						enablePasswordRetrieval: $scope.enablePasswordRetrieval,
						preventCommonPasswords: $scope.preventCommonPasswords,
						preventPreviousPasswords: $scope.preventPreviousPasswords,
						preventPreviousPasswordsCount: $scope.preventPreviousPasswordsCount,
						enableAutoblockOutgoingSmtp: $scope.enableAutoblockOutgoingSmtp
					}
				}
			});

			$rootScope.spinner.show();
			$http
				.post('~/api/v1/settings/sysadmin/global-mail', params)
				.then(onSaveSuccess, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onSaveSuccess() {
				$scope.$emit('password-update-count');
				form.$setPristine();
			}
		}
	}

})();
