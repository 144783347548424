(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("userEditPasswordAdminController", userEditPasswordAdminController);

	function userEditPasswordAdminController($scope, $mdDialog, $timeout, username) {
		$scope.newPassword = "";
		$scope.confirmPassword = "";
		$scope.username = username;
		$timeout(function () { $("input[name=newpass]").trigger("focus"); });

		$scope.save = function () {
			if ($scope.userForm.$invalid || !$scope.meetsReq)
				return;
			$mdDialog.hide({ newPassword: $scope.newPassword, confirmPassword: $scope.confirmPassword });
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}

})();
