(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("domainSettingsMarketplaceController", domainSettingsMarketplaceController);

    function domainSettingsMarketplaceController($scope, $http, errorHandling, $rootScope, themesService, $translate, $mdDialog, userDataService) {
        var vm = this;

        $scope.treeExpanded = true;
        vm.isInitialized = false;

        vm.marketplaceItems = [
            {
                name: $translate.instant('ONLYOFFICE'),
                description: $translate.instant('ONLYOFFICE_DESCRIPTION'),
                imageUrl: "img/MarketPlace/icon_onlyoffice.webp",
                enabled: false,
                id: "onlyOffice"
            },
            {
                name: $translate.instant('COLLABORAONLINE'),
                description: $translate.instant('COLLABORAONLINE_DESCRIPTION'),
                imageUrl: "img/MarketPlace/icon_collabora.webp",
                enabled: false,
                id: "collabora"
            }
        ];

        // Side menu items
        vm.marketplaceSideMenuItems = [
            { title: $translate.instant('OVERVIEW'), sref: 'index.domain-marketplace.overview' }
        ];

        vm.bannerImage = "";
        vm.loadMarketplaceSettings = loadMarketplaceSettings;
        vm.sortItems = sortItems;
        vm.enableItem = enableItem;
        vm.openSettingsModal = openSettingsModal;
        vm.isOnlyOfficeEnabled = isOnlyOfficeEnabled;

        activate();

        function activate() {
            setBannerImage();
            $rootScope.$on("themeService:changed", setBannerImage);
            vm.isOnlyOfficeEnabled().then(function (response) {
                vm.enableOnlyOffice = response;
            })
            isCollaboraOnlineEnabled().then(function (response) {
                vm.enablewopi = response;
               
            })
            loadMarketplaceSettings().then(function () {
                sortItems();
                vm.isInitialized = true;
            });
        }

        function setBannerImage() {
            vm.bannerImage = themesService.isThemeDark()
                ? "img/MarketPlace/marketplaceBanner_dark_1570x200.webp"
                : "img/MarketPlace/marketplaceBanner_light_1570x200.webp";
        }
        async function isOnlyOfficeEnabled(){
            var apiUrl = "/api/v1/marketplace/IsOnlyOfficeEnabled";
            return $http.get(apiUrl)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    console.error(`Failed to fetch settings for addon: ${vm.addonName}`, error);
                    return false;
                });
        }

        async function isCollaboraOnlineEnabled(){
            var apiUrl = "/api/v1/marketplace/IsCollaboraOnlineEnabled";
            return  $http.get(apiUrl)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    console.error(`Failed to fetch settings for addon: ${vm.addonName}`, error);
                    return false;
                });
        }

        // Load marketplace settings from the backend
        function loadMarketplaceSettings() {
            $rootScope.spinner = $rootScope.spinner || { show: angular.noop, hide: angular.noop };
            $rootScope.spinner.show();
            return $http.get("/api/v1/marketplace/getmarketplace")
                .then(function (response) {
                    var marketplaceSettings = response.data || {};
                    vm.marketplaceItems.forEach(function (item) {
                        if (item.id === "onlyOffice") {
                            item.allowed = marketplaceSettings.onlyOfficeEnabled || false;
                            item.enabled = vm.enableOnlyOffice;
                        } else if (item.id === "collabora") {
                            item.allowed = marketplaceSettings.collaboraEnabled || false;
                            item.enabled =  vm.enablewopi;
                        }
                    });
                })
                .catch(errorHandling.report)
                .finally($rootScope.spinner.hide);
        }

        // Sort marketplace items alphabetically
        function sortItems() {
            vm.marketplaceItems.sort((a, b) => a.name.localeCompare(b.name));
        }

        // Enable/disable an item by sending a POST request
        function enableItem(item) {
            $rootScope.spinner.show();
            

            // For now, we only have a setOnlyOfficeSettings endpoint.
            // If you have a similar endpoint for Collabora, you can handle it similarly.
            if (item.id === "onlyOffice") {
                // Construct minimal settings to toggle enabled
                var updatedSettings = {
                    enabled: !item.enabled
                };

                $http.post("/api/v1/marketplace/setOnlyOfficeSettings", updatedSettings)
                    .then(function (response) {
                        // If successful, update the item's enabled status based on what we sent
                        item.enabled = updatedSettings.enabled;
                    })
                    .catch(errorHandling.report)
                    .finally($rootScope.spinner.hide);
            } else if (item.id === "collabora") {
                // For Collabora, if a similar API endpoint exists, handle it here.
                var collaboraSettings = {
                    enabled: !item.enabled
                };

                // Example: /api/v1/marketplace/setCollaboraSettings
                $http.post("/api/v1/marketplace/setCollaboraSettings", collaboraSettings)
                    .then(function (response) {
                        item.enabled = collaboraSettings.enabled;
                    })
                    .catch(errorHandling.report)
                    .finally(function(){
                    $rootScope.spinner.hide();
                    location.reload();
                    });ß
            } else {
                // If no endpoint is available, just toggle locally (not recommended)
                item.enabled = !item.enabled;
                $rootScope.spinner.hide();
            }
        }

        // Open the settings modal for the selected addon
        function openSettingsModal(item) {
            $mdDialog.show({
                controller: "modularConfigurationModalController",
                controllerAs: "settingsCtrl",
                templateUrl: "app/marketplace/modals/modular-configuration.modal.html",
                clickOutsideToClose: true,
                locals: {
                    addonName: item.id
                }
            }).then(function (updatedSettings) {
                console.log("Settings saved for:", item.name, updatedSettings);

                // If the modal returns updated settings, we also send them to the server.
                // Notice that enabled state is included in updatedSettings, so it remains in sync.
                if (item.id === "onlyOffice" && updatedSettings.onlyOffice) {
                    $rootScope.spinner.show();
                    $http.post("/api/v1/marketplace/setOnlyOfficeSettings", updatedSettings.onlyOffice)
                        .then(function (response) {
                            console.log(response.data);
                            // Update the item.enabled with whatever is returned
                            // Assuming the API returns the new enabled state
                            if (typeof updatedSettings.onlyOffice.enabled !== "undefined") {
                                item.enabled = updatedSettings.onlyOffice.enabled;
                            }
                        })
                        .catch(errorHandling.report)
                        .finally($rootScope.spinner.hide);
                }

                if (item.id === "collabora" && updatedSettings.wopi) {
                    // Similar call for Collabora if there's an endpoint
                    $rootScope.spinner.show();
                    $http.post("/api/v1/marketplace/setCollaboraSettings", updatedSettings.wopi)
                        .then(function (response) {
                            console.log(response.data);
                            if (typeof updatedSettings.wopi.enabled !== "undefined") {
                                item.enabled = updatedSettings.wopi.enabled;
                            }
                        })
                        .catch(errorHandling.report)
                        .finally($rootScope.spinner.hide);
                }
            }).catch(function () {
                console.log("Modal dismissed for:", item.name);
            });
        }
    }
})();