(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("rssFeedsController", rssFeedsController);

    function rssFeedsController($rootScope, $scope, $http, $timeout, $filter, $translate, $compile, $mdDialog,
        $mdMedia, coreData, coreDataRss, coreDataSettings, claimsService, preferencesStorage, errorHandling, treeState) {
        var vm = this;
        var COUNT_PER_PAGE = 50;
        vm.feeds = undefined;
        vm.tree = { data: [] };
        vm.shortTree = { data: [] };
        vm.treeController = {};
        vm.shortTreeController = {};
        vm.currentPageFeeds = [];
        vm.currentPage = 0;
        vm.pageCount = 0;
        vm.isFolderFeed = false;
        vm.noFeedsToShow = false;
        vm.displayingFeeds = false;
        vm.treeExpanded = treeState.isExpanded;

        //// Feed Functions
        vm.addFeed = addFeed;
        vm.deleteFeed = deleteFeed;
        vm.dropdownMarkFeed = dropdownMarkFeed;
        vm.editFeed = editFeed;
        vm.refreshFeed = refreshFeed;

        //// Folder Functions
        vm.addFolder = addFolder;
        vm.deleteFolder = deleteFolder;
        vm.editFolder = editFolder;

        //// Tree Functions
        vm.onBranchChanged = onBranchChanged;
        vm.onPreBranchSelect = onPreBranchSelect;
        vm.branchMouseUp = branchMouseUp;
        vm.dropdownEditFeed = dropdownEditFeed;
        vm.dropdownAddFeed = dropdownAddFeed;
        vm.dropdownEditFeed = dropdownEditFeed;
        vm.dropdownDeleteFeed = dropdownDeleteFeed;
        vm.dropdownAddFolder = dropdownAddFolder;
        vm.dropdownEditFolder = dropdownEditFolder;
        vm.dropdownDeleteFolder = dropdownDeleteFolder;

        //// Paging Functions
        vm.nextPage = nextPage;
        vm.previousPage = previousPage;

        //// Card Functions
        vm.dropdownMarkFeedItem = dropdownMarkFeedItem;
        vm.onMouseUp = onMouseUp;
        vm.queueMiddleClick = queueMiddleClick;
        vm.viewItem = viewItem;

        activate();

        /////////////////////////

        function activate() {
            $scope.$on('coreDataRss.rssContentsUpdated', function (event, data) {
                $rootScope.$applyAsync(function () {
                    var feedId = data.feedId;
                    var feed = vm.tree.map[feedId];
                    if (feed)
                        feed.data.count = data.unreadItems;
                    else
                        coreDataRss.reloadFeedTree();
                    coreDataRss.recalculateAllCounters();
                });
            });

            $scope.$on("treeState:stateChange", function (event, data) {
                vm.treeExpanded = data.expanded;
                $timeout(function () { $(window).trigger('resize'); }, 250);
            });

            $rootScope.spinner.show();

            if (!claimsService.impersonating()) {
                var keyExist = ("newsFeeds" in coreDataSettings.userSettings.seenWhatsNew);
                if (keyExist) {
                    var versionOverride = localStorage.getItem("FeatureVersionOverride");
                    var shouldShow = versionOverride === null ? stProductVersion.split('.')[2] > coreDataSettings.userSettings.seenWhatsNew["newsFeeds"] : true;
                    if (shouldShow) {
                        var route = `~/api/v1/settings/new-features/NewsFeeds${versionOverride === null ? "" : "/" + versionOverride}`;
                        $http.get(route).then(onFeaturesLoaded, function () { });
                    }
                } else {
                    $http.get('~/api/v1/settings/new-features/NewsFeeds').then(onFeaturesLoaded, function () { });
                }
            }

            coreData.init()
                .then(function () {
                    coreDataRss.updateFeeds()
                        .then(function () {
                            vm.tree = coreDataRss.data.feedTree;
                            vm.shortTree = coreDataRss.data.shortTree;
                            $rootScope.spinner.hide();
                            vm.isLoaded = true;
                            checkContentsOnPage();
                            $timeout(function () {
                                vm.treeController.deselect_all_branches();
                                vm.shortTreeController.select_first_branch();
                            });
                        }, errorHandling.reportAndHideSpinner);
                }, errorHandling.reportAndHideSpinner);
        }

        function onFeaturesLoaded(result) {
            var newItems = result.data.newFeatures;
            if (newItems.length > 0) {
                $rootScope.$broadcast("user-settings:changed");
                if (newItems.length > 4 && window.innerWidth > 736) {
                    $mdDialog.show({
                        locals: { items: newItems },
                        controller: "whatsNewDialogController",
                        controllerAs: "ctrl",
                        templateUrl: "~/interface/app/shared/modals/whats-new-double.dlg.html",
                        clickOutsideToClose: false
                    }).then(function () { }, function () { });
                }
                else {
                    $mdDialog.show({
                        locals: { items: newItems },
                        controller: "whatsNewDialogController",
                        controllerAs: "ctrl",
                        templateUrl: "~/interface/app/shared/modals/whats-new-narrow.dlg.html",
                        clickOutsideToClose: false
                    }).then(function () { }, function () { });
                }
            }
        }

        function onPreBranchSelect(branch, treeType) {
            if (treeType === "rss")
                vm.shortTreeController.deselect_all_branches();
            else
                vm.treeController.deselect_all_branches();
        }

        function branchMouseUp(branch, event) {
            // return if branch is All Feeds

            event.stopPropagation();
            event.preventDefault();
            event.cancelbubble = true;
            $scope.dropdownOptions = [];
            if (event && event.which === 3) {
                $scope.dropdownEvent = $.extend(true, {}, event);
                if (branch.data.allFeed) {
                    $scope.dropdownOptions = [
                        { key: 'markRead', click: vm.dropdownMarkFeed, params: { branch: branch, markRead: true, event: $scope.dropdownEvent }, translateKey: 'MARK_READ' },
                        { key: 'markUnread', click: vm.dropdownMarkFeed, params: { branch: branch, markRead: false, event: $scope.dropdownEvent }, translateKey: 'MARK_UNREAD' },
                        { divider: true },
                        { key: 'addItem', click: vm.dropdownAddFeed, params: { branch: branch, event: $scope.dropdownEvent }, translateKey: 'RSS_FEEDS_ADD_FEED' },
                        { divider: true },
                        { key: 'addFolder', click: vm.dropdownAddFolder, params: { branch: branch, event: $scope.dropdownEvent }, translateKey: 'NEW_FOLDER' },
                    ];
                }
                else if (branch.data.isFolder) {
                    $scope.dropdownOptions = [
                        { key: 'markRead', click: vm.dropdownMarkFeed, params: { branch: branch, markRead: true, event: $scope.dropdownEvent }, translateKey: 'MARK_READ' },
                        { key: 'markUnread', click: vm.dropdownMarkFeed, params: { branch: branch, markRead: false, event: $scope.dropdownEvent }, translateKey: 'MARK_UNREAD' },
                        { divider: true },
                        { key: 'addItem', click: vm.dropdownAddFeed, params: { branch: branch, event: $scope.dropdownEvent }, translateKey: 'RSS_FEEDS_ADD_FEED' },
                        { divider: true },
                        { key: 'addFolder', click: vm.dropdownAddFolder, params: { branch: branch, event: $scope.dropdownEvent }, translateKey: 'NEW_FOLDER' },
                        { key: 'edit', click: vm.dropdownEditFolder, params: { branch: branch, event: $scope.dropdownEvent }, translateKey: 'RSS_FEEDS_EDIT_FOLDER' },
                        { key: 'delete', click: vm.dropdownDeleteFolder, params: { branch: branch, event: $scope.dropdownEvent }, translateKey: 'DELETE_FOLDER' },
                    ];
                } else {
                    $scope.dropdownOptions = [
                        { key: 'markRead', click: vm.dropdownMarkFeed, params: { branch: branch, markRead: true, event: $scope.dropdownEvent }, translateKey: 'MARK_READ' },
                        { key: 'markUnread', click: vm.dropdownMarkFeed, params: { branch: branch, markRead: false, event: $scope.dropdownEvent }, translateKey: 'MARK_UNREAD' },
                        { divider: true },
                        { key: 'edit', click: vm.dropdownEditFeed, params: { branch: branch, event: $scope.dropdownEvent }, translateKey: 'RSS_FEEDS_EDIT_FEED' },
                        { key: 'delete', click: vm.dropdownDeleteFeed, params: { branch: branch, event: $scope.dropdownEvent }, translateKey: 'RSS_FEEDS_DELETE_FEED' }
                    ];
                }

                if ($scope.dropdownOptions.length === 0) return;

                var elementToCompile = '<st-context-menu options="::dropdownOptions" event="::dropdownEvent" classes="::[\'dropdown-no-scroll\']" menu-like="::true" menu-class="::\'abn-tree-row\'"></st-context-menu>';
                var element = $("#context-menu-area");
                if (element) {
                    var elementCompiled = $compile(elementToCompile)($scope);
                    element.append(elementCompiled);
                }
            }
        }

        function onBranchChanged(branch, treeType) {
            vm.feeds = undefined;
            vm.currentPageFeeds = [];
            vm.currentFeed = {};
            $('#feed-list-area').empty();
            $('.article-view').empty();
            vm.displayingFeeds = false;
            vm.currentFeed.id = branch.data.id;
            vm.currentFeed.data = branch.data;
            if (branch.data && branch.data.lastUpdatedUtc) {
                vm.currentFeed.lastUpdated = moment.utc(branch.data.lastUpdatedUtc).toDate();
            }
            vm.isFolderFeed = !!branch.data.isFolder;

            var folderFeedIds = getFolderFeedIds(branch);
            getRssFeed(folderFeedIds);
            if ($rootScope.windowWidth <= 737) {
                vm.treeExpanded = false;
                treeState.isExpanded = vm.treeExpanded;
            }
        }

        function getFolderFeedIds(folderItem) {
            var feedsMetaData = [];

            if (folderItem.data.allFeed) {
                for (var i = 0; i < vm.tree.data.length; i++)
                    feedsMetaData = feedsMetaData.concat(getFolderFeedIds(vm.tree.data[i]));
            }
            else if (folderItem.children) {
                for (var i = 0; i < folderItem.children.length; i++)
                    feedsMetaData = feedsMetaData.concat(getFolderFeedIds(folderItem.children[i]));
            }

            if (!folderItem.data.isFolder && !folderItem.data.isPlaceholder)
                if (folderItem.data && folderItem.data.id)
                    feedsMetaData.push(folderItem.data.id);

            return feedsMetaData;
        }

        function getRssFeed(feeds) {
            if (feeds && feeds.length > 0) {
                $rootScope.spinner.show();
                coreDataRss.getRssFeed(feeds)
                    .then(function (success) {
                        vm.feeds = success.data.items;
                        vm.rssFeed = success.data.singleFeed;
                        setRssDates();
                        setPageCounts();

                        var slicedFeeds = vm.feeds.slice(0, COUNT_PER_PAGE);
                        convertToStLink(slicedFeeds);
                        vm.currentPageFeeds = slicedFeeds;
                        vm.noFeedsToShow = vm.currentPageFeeds.length < 1;

                        checkContentsOnPage();
                        $timeout(replaceATargetList);
                        $rootScope.spinner.hide();
                    }, function (failure) {
                        errorHandling.report(failure);
                        $rootScope.spinner.hide();
                    });
            } else {
                vm.noFeedsToShow = true;
            }
        }

        function setRssDates() {
            for (var i = 0; i < vm.feeds.length; ++i) {
                var v = vm.feeds[i];
                v.pubDate = new Date(v.pubDate);
                if (v.pubDate < new Date('100')) {
                    v.pubDate = undefined;
                }
            }
        }

        function setPageCounts() {
            vm.pageCount = Math.ceil(vm.feeds.length / COUNT_PER_PAGE);
            vm.currentPage = 1;
        }

        //// Card Functions
        function dropdownMarkFeedItem(params) {
            var article = params.article;
            var markRead = params.markRead;

            if (article.read == markRead) return;

            $http.post("~/api/v1/rss/mark-feed-item/" + markRead, { feedId: article.feedId, crc: article.crc })
                .then(function (success) {
                    article.read = markRead;
                    coreDataRss.markReadUnread(vm.tree.map[article.feedId], markRead);
                }, errorHandling.report);
        }

        function onMouseUp(article, index, ev) {
            if (ev.which == 3) {
                ev.stopPropagation();
                ev.preventDefault();
                $scope.dropdownEvent = $.extend(true, {}, ev);
                $scope.dropdownOptions = [];
                $scope.dropdownOptions = $scope.dropdownOptions.concat([
                    { key: "markRead", click: vm.dropdownMarkFeedItem, params: { article: article, markRead: true, event: $scope.dropdownEvent }, translateKey: "MARK_READ" },
                    { key: "markUnread", click: vm.dropdownMarkFeedItem, params: { article: article, markRead: false, event: $scope.dropdownEvent }, translateKey: "MARK_UNREAD" }
                ]);

                var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent"></st-context-menu>';
                var element = $("#context-menu-area");
                if (element) {
                    var elementCompiled = $compile(elementToCompile)($scope);
                    element.append(elementCompiled);
                }
            }

            if (ev.which == 2 && vm.queuedMiddleClick == index) {
                viewItem(article, index, true);
                vm.queuedMiddleClick = undefined;
            }
        }

        //// Folder functions

        function expandAllParents(branch) {
            if (branch.children && branch.children.length)
                vm.treeController.expand_branch(branch);

            var parent = vm.tree.mapById[branch.data.parentFolderId];
            while (parent) {
                vm.treeController.expand_branch(parent);
                parent = vm.tree.mapById[parent.data.parentFolderId];
            }
        }

        function addFolder(ev, branch) {
            var currentBranch = vm.treeController.get_selected_branch() || vm.shortTreeController.get_selected_branch();
            if (!currentBranch.data.isFolder)
                currentBranch = vm.shortTreeController.get_first_branch();
            branch = branch || currentBranch;

            $mdDialog
                .show({
                    locals: {
                        name: "",
                        currentFolder: null,
                        parentFolder: branch,
                        newFolder: true
                    },
                    controller: "editRssFolderController",
                    controllerAs: "ctrl",
                    templateUrl: "app/rss-feeds/modals/edit-rss-folder.dlg.html"
                })
                .then(function (modalSuccess) {
                    $rootScope.spinner.show();
                    var name = modalSuccess.name;
                    var parentFolderId = modalSuccess.parentFolderId;

                    coreDataRss
                        .addFolder(parentFolderId, name)
                        .then(function (success) {
                            var id = success.data.id;
                            coreDataRss.reloadFeedTree();
                            expandAllParents(vm.tree.mapById[modalSuccess.parentFolderId]);
                            vm.treeController.select_branch(vm.tree.mapById[id]);
                        }, errorHandling.report)
                        .finally($rootScope.spinner.hide);
                }, function () { });
        }

        function deleteFolder(ev, branch) {
            var currentBranch = vm.treeController.get_selected_branch() || vm.shortTreeController.get_selected_branch();
            branch = branch || currentBranch;
            var confirm = $mdDialog.confirmDeletion()
                .textContent($filter('translate')('CONFIRMATIONS_DELETE_FOLDER', { name: branch.label }))
                .targetEvent(ev);

            $mdDialog
                .show(confirm)
                .then(function () {
                    if (!branch.data.isFolder || branch.data.allFeed) return;

                    coreDataRss
                        .deleteFolder(branch.data.id)
                        .then(function () {
                            var parentId = branch.data.parentFolderId;
                            coreDataRss.reloadFeedTree();
                            vm.treeController.select_branch(vm.tree.mapById[parentId]);
                        }, errorHandling.report);
                }, function () { });
        }

        function editFolder(ev, branch) {
            var currentBranch = vm.treeController.get_selected_branch() || vm.shortTreeController.get_selected_branch();
            branch = branch || currentBranch;
            if (!branch.data.isFolder || branch.data.allFeed) return;

            var parent = vm.tree.mapById[branch.data.parentFolderId];

            $mdDialog
                .show({
                    locals: {
                        name: branch.label,
                        currentFolder: branch,
                        parentFolder: parent,
                        newFolder: false
                    },
                    controller: "editRssFolderController",
                    controllerAs: "ctrl",
                    templateUrl: "app/rss-feeds/modals/edit-rss-folder.dlg.html"
                })
                .then(function (modalSuccess) {
                    $rootScope.spinner.show();
                    var name = modalSuccess.name;
                    var parentFolderId = modalSuccess.parentFolderId;
                    coreDataRss
                        .editFolder(branch.data.id, parentFolderId, name)
                        .then(function () {
                            coreDataRss.reloadFeedTree();
                            var newBranch = vm.tree.mapById[branch.data.id];
                            expandAllParents(newBranch);
                            vm.treeController.select_branch(newBranch);
                        },
                            errorHandling.report)
                        .finally($rootScope.spinner.hide);
                }, function () { });

        }

        //// Feed Functions
        function addFeed(ev, branch) {
            var currentBranch = vm.treeController.get_selected_branch() || vm.shortTreeController.get_selected_branch();
            branch = branch || currentBranch;

            $mdDialog.show({
                locals: {
                    name: "",
                    data: "",
                    folder: branch.data.isFolder ? branch.data.id : branch.data.folder,
                    newFeed: true
                },
                controller: "editRssController",
                controllerAs: "ctrl",
                templateUrl: "app/rss-feeds/modals/edit-rss.dlg.html"
            })
                .then(function (modalFeed) {
                    $rootScope.spinner.show();
                    // Create the new feeds
                    coreDataRss.addFeed(modalFeed)
                        .then(function (addSuccess) {
                            // Update the rss feed list and tree data.
                            coreDataRss.reloadFeedTree();
                            vm.tree = coreDataRss.data.feedTree;

                            vm.treeController.select_branch(vm.tree.map[addSuccess.data.id]);
                            var feedItem = vm.tree.map[addSuccess.data.id];

                            refreshFeed(feedItem, false);

                            $rootScope.spinner.hide();
                        }, errorHandling.reportAndHideSpinner);
                });
        }

        function deleteFeed(ev, branch) {
            var currentBranch = vm.treeController.get_selected_branch() || vm.shortTreeController.get_selected_branch();
            var branch = branch || currentBranch;
            var autoSelect = branch.data.id == currentBranch.data.id; // We're on the branch we're deleting

            if (branch && !branch.data.isFolder) {
                var confirm = $mdDialog.confirmDeletion()
                    .textContent($filter('translate')('RSS_FEEDS_DELETE_FEED_CONFIRMATION', { feed: branch.label }))
                    .targetEvent(ev);

                $mdDialog
                    .show(confirm)
                    .then(function () {
                        var feed = { uid: branch.data.id };
                        coreDataRss
                            .deleteFeed(feed)
                            .then(function () {
                                coreDataRss.reloadFeedTree();
                                vm.tree = coreDataRss.data.feedTree;
                                var folderItem = vm.tree.map[branch.data.parentPath];

                                if (autoSelect)
                                    vm.treeController.select_branch(folderItem);

                                if (autoSelect || feedIsChild(branch, currentBranch)) {
                                    vm.feeds = undefined;
                                    vm.isFolderFeed = true;

                                    resetPage();
                                    getRssFeed(getFolderFeedIds(folderItem));
                                }

                                $rootScope.spinner.hide();
                            }, function (failure) {
                                errorHandling.report(failure);
                                $rootScope.spinner.hide();
                            });
                    }, function () { });
            } else {
                errorHandling.warn("RSS_FEEDS_NO_FEEDS_SELECTED");
            }
        }

        function dropdownMarkFeed(params) {
            var branch = params.branch;
            var markRead = params.markRead;

            var markFeeds = getFolderFeedIds(branch);
            coreDataRss
                .markFeedRead(markFeeds, markRead)
                .then(function (success) {
                    // Mark feeds on the page as read
                    var compareUid = {};
                    for (var i = 0; i < markFeeds.length; i++) {
                        compareUid[markFeeds[i].uid] = true;
                    }
                    for (var i = 0; i < vm.currentPageFeeds.length; i++) {
                        if (compareUid[vm.currentPageFeeds[i].sourceUid])
                            vm.currentPageFeeds[i].read = markRead;
                    }

                }, function () { });
        }

        function editFeed(ev, branch) {
            var currentBranch = vm.treeController.get_selected_branch() || vm.shortTreeController.get_selected_branch();
            branch = branch || currentBranch;
            if (branch && !branch.data.isFolder) {
                var oldPath = branch.data.path;
                $mdDialog.show({
                    locals: {
                        name: branch.label,
                        data: branch.data.path,
                        folder: branch.data.folder,
                        newFeed: false
                    },
                    controller: "editRssController",
                    controllerAs: "ctrl",
                    templateUrl: "app/rss-feeds/modals/edit-rss.dlg.html"
                })
                    .then(function (modalFeed) {
                        $rootScope.spinner.show();
                        var feed = { feedId: branch.data.id, name: modalFeed.name, url: modalFeed.url, folderId: modalFeed.folderId };
                        coreDataRss.editFeed(feed)
                            .then(function (success) {
                                coreDataRss.reloadFeedTree(feed);
                                //If the path changed
                                if (oldPath != modalFeed.data)
                                    vm.refreshFeed();

                                vm.treeController.select_branch(vm.tree.map[currentBranch.data.path]);
                                expandAllParents(branch);

                                $rootScope.spinner.hide();
                            }, errorHandling.reportAndHideSpinner);
                    });
            } else
                errorHandling.warn("RSS_FEEDS_NO_FEEDS_SELECTED");
        }

        function refreshFeed(branch, goToFeed) {
            var currentBranch = vm.treeController.get_selected_branch() || vm.shortTreeController.get_selected_branch();
            branch = branch || currentBranch;
            if (goToFeed === undefined) goToFeed = true;

            if (branch) {
                var feeds = branch.data.isFolder ? getFolderFeedIds(vm.tree.map[branch.data.path]) : [branch.data.id];

                $rootScope.spinner.show();
                if (goToFeed) {
                    vm.feeds = undefined;
                    resetPage();
                }
                //refresh the rss feed
                coreDataRss.refreshRssFeed(feeds)
                    .then(function (success) {
                        // Update the rss feed list and tree data
                        coreDataRss.reloadFeedTree();
                        vm.tree = coreDataRss.data.feedTree;
                        vm.treeController.select_branch(vm.tree.map[branch.data.isFolder ? branch.data.path : currentBranch.data.id]);

                        vm.feeds = undefined;
                        vm.isFolderFeed = branch.data.isFolder;

                        resetPage();
                        $rootScope.spinner.hide();

                        // Get the new data for the rss feed
                        //coreDataRss.getRssFeed(feeds)
                        //	.then(function (success) {
                        //		if (goToFeed) {
                        //			vm.feeds = success.data.items;
                        //			setRssDates();
                        //			setPageCounts();
                        //			var slicedFeeds = vm.feeds.slice(0, COUNT_PER_PAGE);
                        //			convertToStLink(slicedFeeds);
                        //			vm.currentPageFeeds = slicedFeeds;
                        //		}
                        //		$rootScope.spinner.hide();
                        //	}, errorHandling.reportAndHideSpinner);
                    }, errorHandling.reportAndHideSpinner);
            } else
                errorHandling.reportAndHideSpinner($translate.instant("ERROR"));
        }


        //// Tree Functions

        function checkContentsOnPage() {
            if (vm.currentPageFeeds.length > 0 && $(".rss-card .rss-description-group").length == vm.currentPageFeeds.length && ($("md-card").length * 130) > ($("#feed-list-area").outerHeight() / 2))
                $timeout(function () { vm.displayingFeeds = true; }, 25);
            else
                $timeout(checkContentsOnPage, 100);
        }

        function dropdownAddFeed(params) {
            vm.addFeed(params.event, params.branch);
        }

        function dropdownEditFeed(params) {
            vm.editFeed(params.event, params.branch);
        }

        function dropdownDeleteFeed(params) {
            vm.deleteFeed(params.event, params.branch);
        }

        function dropdownAddFolder(params) {
            vm.addFolder(params.event, params.branch);
        }

        function dropdownEditFolder(params) {
            vm.editFolder(params.event, params.branch);
        }

        function dropdownDeleteFolder(params) {
            vm.deleteFolder(params.event, params.branch);
        }

        //// Paging Functions
        function nextPage() {
            if (vm.currentPage < vm.pageCount) {
                vm.displayingFeeds = false;
                vm.currentPage++;
                var newSlice = vm.currentPage * COUNT_PER_PAGE;
                var slicedFeeds = vm.feeds.slice(newSlice - COUNT_PER_PAGE, newSlice);
                convertToStLink(slicedFeeds);
                vm.currentPageFeeds = slicedFeeds;
                checkContentsOnPage();
                $timeout(replaceATargetList);
            }
        }

        function previousPage() {
            if (vm.currentPage - 1 > 0) {
                vm.displayingFeeds = false;
                vm.currentPage--;
                var newSlice = vm.currentPage * COUNT_PER_PAGE;
                var slicedFeeds = vm.feeds.slice(newSlice - COUNT_PER_PAGE, newSlice);
                convertToStLink(slicedFeeds);
                vm.currentPageFeeds = slicedFeeds;
                checkContentsOnPage();
                $timeout(replaceATargetList);
            }
        }

        // This happens on mousedown, and it queues the click for the mouseup event, basically faking a normal click event
        function queueMiddleClick(index, ev) {
            if (ev.which == 2) vm.queuedMiddleClick = index;
            ev.preventDefault();
        }

        function viewItem(article, index, noFocus) {
            window.open(article.link);

            if (noFocus) {
                window.focus();
            }

            if (article.read) return;

            $http.post("~/api/v1/rss/mark-feed-item/true", { feedId: article.feedId, crc: article.crc })
                .then(function () {
                    article.read = true;
                    coreDataRss.markReadUnread(vm.tree.map[article.feedId], true);
                }, errorHandling.report);
        }

        //// Helper Functions
        function convertToStLink(feeds) {
            angular.forEach(feeds, function (item) {
                item.plainTextDescription = item.plainTextDescription.replace(/<a/g, '<st-link')
                item.plainTextDescription = item.plainTextDescription.replace(/<\/a/g, '</st-link')
            });
        }

        function feedIsChild(feedItem, folderItem) {
            var parent = vm.tree.map[feedItem.data.folder];

            while (!parent.data.allFeed) {
                if (parent.data.id == folderItem.data.id)
                    return true;
                var parent = vm.tree.map[parent.data.folder];
            }

            if (parent.data.id == folderItem.data.id) // All Feed
                return true;

            return false;
        }

        function replaceATargetList() { $('.feed-list a').attr("target", "_blank"); };

        function resetPage() {
            vm.currentPageFeeds = [];
            vm.currentPage = 1;
            vm.pageCount = 0;
        };
    };
})();