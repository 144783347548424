(function () {

	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminManageRootController", sysAdminManageRootController);

	function sysAdminManageRootController($rootScope, $scope, $translate, $http, $q, $state, $mdDialog, $log, $filter, $timeout, treeState,
		successHandling, NgTableParams, gridCheckboxes, coreDataSysAdminSettings, coreData, signalrHubManager, errorHandling, authStorage, coreDataFileStorage,
		claimsService, coreLicensing, toaster) {

		// Variables
		var vm = this;
		vm.domainCount = 0;
		vm.spoolCount = 0;
		vm.userActivity = 0;
		vm.usersConnections = 0;
		vm.currentConnections = 0;
		vm.idsBlocks = 0;
		vm.selfBlacklist = 0;		// Server blacklist check
		vm.domains = [];
		vm.canImpersonate = claimsService.canImpersonate();
		vm.isEnterprise = coreLicensing.edition != coreLicensing.EDITION_PROFESSIONAL;
		vm.isInitialized = false;
		vm.showArchiveSearch = false;
		
		// Functions
        vm.onImpersonate = onImpersonate;
		activate();

		$scope.treeExpanded = treeState.isExpanded;
		$scope.$on("treeState:stateChange", function (event, data) {
			$scope.treeExpanded = data.expanded;
			$timeout(function () { $(window).trigger('resize'); }, 300);
		});

		$rootScope.$on("signalR.mailHub.client.certificateValidityUpdate", onCertificateValidityUpdate);

		//////////////////

		function activate() {
			coreData
				.init()
				.then(onInitSuccess, errorHandling.report);

            function onInitSuccess() {
                vm.isInitialized = true;
				connectToSignalR();
				if (coreDataSysAdminSettings.settings.domainArchiveRules) {
					var sysLevelArchiveRules = coreDataSysAdminSettings.settings.domainArchiveRules.find(rule => rule.isDefault);
					vm.showArchiveSearch = !sysLevelArchiveRules.disabled;
				} else {
					vm.showArchiveSearch = false;
				}
				$scope.$applyAsync();

				$http.get("~/api/v1/settings/sysadmin/dashboard-stats")
                    .then(onDashboardStatsSuccess, errorHandling.report);
			}

            function onDashboardStatsSuccess(result) {
                onDashboardUpdate(undefined, result.data);
			}
		}

		function onImpersonate(ev) {
            $mdDialog
                .show({
					locals: { domain: null, email: null },
                    controller: "sysAdminImpersonateUserController",
                    controllerAs: "ctrl",
                    templateUrl: "app/root/modals/impersonate-user.dlg.html",
                    targetEvent: ev
                });
		}

		function connectToSignalR() {
			signalrHubManager
				.init()
				.then(onSuccess);

			function onSuccess(connections) {
				connections.mailHub.invoke("manageConnectWithStartData",
					authStorage.getToken(), ["domaincount", "spoolCount", "userActivity", "usersConnections", "currentConnections", "idsBlocks", "selfBlacklist"], ["domainCount", "spoolCount", "userActivity", "currentConnections", "selfBlacklist"])
					.then(connectedtoSignalR);
				$scope.$on("signalR.client.dashboardUpdate", onDashboardUpdate);
			}

			function connectedtoSignalR() { }
		}

		function onDashboardUpdate(event, data) {
            $scope.$evalAsync(function () {
                vm.domainCount = data.domaincount || 0;
                vm.spoolCount = data.spoolCount || 0;
				vm.userActivity = Math.max(0, data.userActivity || 0);
				vm.usersConnections = Math.max(0, data.usersConnections || 0);
                vm.currentConnections = data.currentConnections || 0;
                vm.idsBlocks = data.idsBlocks || 0;
                vm.selfBlacklist = data.selfBlacklist || 0;
            });
		}

		function onCertificateValidityUpdate(event, data) {
			if (data.valid && window.certificateErrorToast) {
				toaster.clear(window.certificateErrorToast);
				window.certificateErrorToast = null;
			}
		}
	}
})();