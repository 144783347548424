(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("calendarOutlookDialogController", calendarOutlookDialogController);

	function calendarOutlookDialogController($scope, $mdDialog, $filter, calendars) {
		$scope.calendars = calendars;
		$scope.currentCalendar = calendars[0];


		$scope.outlookVersionOptions = [
			{ version: "3.0", name: $filter("translate")("OUTLOOK_2007_OR_LATER") },
			{ version: "1.0", name: $filter("translate")("OUTLOOK_2003") }
		];

		$scope.currentVersion = $scope.outlookVersionOptions[0];

		function getMetaData() {
			var metaData = {
				"outlookVersion": $scope.currentVersion.version,
				"calendar": $scope.currentCalendar,
				"displayName": $scope.displayName
			};
			return metaData;
		}

		$scope.done = function () {
			var result = getMetaData();
			$mdDialog.hide(result);
		}.bind(this);

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	};
})();