//(function () {
//	"use strict";

//	angular
//		.module("smartermail")
//		.controller("domainSettingsMainController", domainSettingsMainController);

//	function domainSettingsMainController($rootScope, $scope, $filter, $q, $stateParams, coreData, coreDataDomainSettings, claimsService, errorHandling) {

//		// Variable declarations
//		var vm = this;
//		vm.isInitialized = false;
//        vm.pageTitle = "";
//        vm.editItemTitle = "";
//		$scope.page = $stateParams.page;
//		$scope.settingCore = undefined;
//		$scope.userCount = 0;

//		// Functions
//		activate();

//		// Initialization
//		function activate() {
//            $rootScope.spinner.show();

//            var promises = [
//                coreData.init(),
//                $http.get("~/api/v1/settings/domain/domain")
//            ];

//            $q.all(promises).then(function (success) {
//                vm.domainSettings = success[1].data.domainSettings;
//                init();
//                $rootScope.spinner.hide();
//            }, function (failure) {
//                errorHandling.report(failure);
//                $rootScope.spinner.hide();
//            });

//			this.canImpersonate = claimsService.isDomainAdmin() && claimsService.impersonating();
//            $scope.mainSetCtrl.impersonateUserDelegate = undefined;
//            $scope.impersonateUser = function () {
//                if ($scope.mainSetCtrl.impersonateUserDelegate)
//                    $scope.mainSetCtrl.impersonateUserDelegate();
//            };
//		}

//		function init() {
//			$scope.settingCore = coreDataDomainSettings;
//			coreDataDomainSettings.settingsData.domainData
//			.then(function (success) {
//				$scope.userCount = $filter('translate')("USERS") + " (" + success.userCount + ")";
//				$scope.aliasCount = $filter('translate')("ALIASES") + " (" + success.aliasCount + ")";
//			}, function () {});
//			coreDataDomainSettings.changePageReset();
//			$scope.enableActiveSyncAccountManagement = vm.domainSettings.enableActiveSyncAccountManagement;
//			vm.isDomainAdmin = coreData.user.isDomainAdmin;
//            vm.isInitialized = true;
//		}
//	}
//})();
