(function () {
	"use strict";

	angular
		.module("smartertools")
		.directive("validateNotCurrentDomain", validateNotCurrentDomain);

	function validateNotCurrentDomain($rootScope, $q, coreDataDomainSettings) {
		return {
			require: "ngModel",
			link: function (scope, element, attrs, ctrl) {
				ctrl.$asyncValidators.validateNotCurrentDomain = async function (modelValue, viewValue) {
					let result = await coreDataDomainSettings.init();

					let currentDomain = $rootScope.secondaryImpersonationDomain || coreDataDomainSettings.domain;

					return currentDomain == viewValue
						? $q.reject({ success: false })
						: $q.resolve({ success: true });
				};
			}
		};
	}
})();