(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("adminSelectUserMailFolderController", adminSelectUserMailFolderController);

	function adminSelectUserMailFolderController($mdDialog, $state, $translate, $filter, errorHandling,  coreDataUserFolder, locals) {

		var vm = this;
		vm.title = locals.title;
		vm.helplink = locals.helplink;
		vm.ok = locals.ok ? locals.ok : $translate.instant("OK");
		vm.cancel = locals.cancel ? locals.cancel : $translate.instant("CANCEL");
		vm.isSysAdmin = false;
		vm.domains = [];
		vm.selectedDomain = null;
		vm.selectedFolderText = $translate.instant("FOLDER");
		resetData();

		function resetData() {
			vm.folderList = [];
			vm.targetEmail = null;
			vm.targetUsername = null;
			vm.selectedFolder = null;
			vm.selectedFolderPath = null;
			vm.folderDisplayPath = null;
			vm.newfolder = null;
		}
		vm.init = function() {
			coreDataUserFolder.init()
				.then(function() {
					vm.isSysAdmin = coreDataUserFolder.isSysAdmin;
					vm.domains = coreDataUserFolder.domains;
					vm.selectedDomain = coreDataUserFolder.selectedDomain;
				}, errorHandling.report);
		};

		vm.getUserFolderList = function(username) {
			if (!username) {
				resetData();
				return;
			}
			vm.targetEmail =vm.selectedDomain.idnName
				? `${username}@${vm.selectedDomain.idnName}`
				: `${username}@${vm.selectedDomain.asciiName}`;
			coreDataUserFolder.getUserMailFolderList(username)
				.then(function(success) {
						const sortedFolders = success.sort(function (a, b) {
							return a.path.toLowerCase().localeCompare(b.path.toLowerCase());
						});

						for (let folder in sortedFolders) {
							let indentCount = sortedFolders[folder].displayPath.split('/').length - 1;
							sortedFolders[folder].name = sortedFolders[folder].displayPath.split('/')[sortedFolders[folder].displayPath.split('/').length - 1];
							for (let i = 0; i < indentCount; i++) {
								sortedFolders[folder].name = "\u00A0\u00A0\u00A0\u00A0" + sortedFolders[folder].name;
								if (sortedFolders[folder].translatedName != undefined)
									sortedFolders[folder].translatedName = "\u00A0\u00A0\u00A0\u00A0" + sortedFolders[folder].translatedName;
							}
						}
						vm.folderList = sortedFolders;
					},
					errorHandling.report);
		}
		
		vm.onSelectFolderPath = function(form) {
			if (form && form.$valid && vm.selectedFolderPath)
			{
				$mdDialog.hide({
					email: vm.targetEmail,
					folder: vm.selectedFolderPath
				});
			}
		}

		vm.emailChanged = function (form) {
			if (form && form.email.$valid) {
				var split = vm.targetEmail.toLowerCase().split("@");
				vm.targetUserName = split[0];
				var domainMatches = $.grep(vm.domains, (item, index) => (item.asciiName && item.asciiName.toLowerCase() == split[1]) || (item.idnName && item.idnName.toLowerCase() == split[1]));
				coreDataUserFolder.selectedDomain = domainMatches.length == 1
					? domainMatches[0]
					: null;
				coreDataUserFolder.getUserMailFolderList(vm.targetUserName)
					.then(function(success) {
							vm.folderList = success;
						},
						errorHandling.report);
			}
		};

		vm.queryUsers = function (searchText) {
			return coreDataUserFolder.queryUsers(searchText);
		}

		vm.onCancel = function () {
			$mdDialog.cancel();
		};

		vm.changeDomain = function() {
			resetData();
			coreDataUserFolder.selectedDomain = vm.selectedDomain;
		}
		
		
		vm.onFolderSelected = function(form) {
			if (form) {
				var baseDisplayname = vm.selectedFolder.value.translatedPath != undefined
					? $translate.instant(vm.selectedFolder.value.translatedPath)
					: vm.selectedFolder.value.translatedPath;
				vm.selectedFolderPath =
					form.newfolder.$valid && vm.newfolder ? `${vm.selectedFolder.value.path}/${vm.newfolder}` : vm.selectedFolder.value.path;
				vm.folderDisplayPath =
					form.newfolder.$valid && vm.newfolder ? `${baseDisplayname}/${vm.newfolder}` : baseDisplayname;
				vm.selectedFolderText = vm.selectedFolder.value.translatedName ? $filter("folderTranslate")(vm.selectedFolder.value.translatedName, vm.selectedFolder.value.ownerEmailAddress) : vm.selectedFolder.value.name.replaceAll("\u00A0", '');
			}
		}

		vm.onNewFolderChanged = function(form) {
			if (form && form.newfolder.$valid) {
				const baseDisplayname = vm.selectedFolder.value.translatedPath != undefined
					? $translate.instant(vm.selectedFolder.value.translatedPath)
					: vm.selectedFolder.value.translatedPath;
				vm.selectedFolderPath =
					vm.selectedFolder ? vm.newfolder ? `${vm.selectedFolder.value.path}/${vm.newfolder}` : vm.selectedFolder.value.path : vm.newfolder;
				vm.folderDisplayPath =
					vm.selectedFolder ? vm.newfolder ? `${baseDisplayname}/${vm.newfolder}` : baseDisplayname : vm.newfolder;
			}
		}
	}

})();