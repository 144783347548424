(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("domainEditLoginHtmlController", domainEditLoginHtmlController);

	function domainEditLoginHtmlController($scope, $mdDialog, $timeout, body) {
		$scope.editorScope = {};
		$scope.body = body;
		$scope.onFroalaInitialized = function () {
			$("st-html-editor textarea.froalaEditor")[0]["data-froala.editor"].events.focus();
		}

		$scope.save = function () {
			const htmlText = $scope.editorScope.getHtml();

			$timeout(function () {
				$mdDialog.hide(htmlText);
			});
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}

})();
