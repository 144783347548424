(function () {
	"use strict";

	angular
		.module("smartermail")
        .controller("sysAdminDeliveryLimitsController", sysAdminDeliveryLimitsController);

    function sysAdminDeliveryLimitsController($rootScope, $scope, $http, claimsService) {
		var vm = this;
        vm.gridSections = [
            { translate: "DO_NOT_FORWARD", length: '0' },
            { translate: "SENDER_PRIORITY", length: '0' },
            { translate: "RESERVED_DOMAINS", length: '0' }
        ];
        vm.selectedSection = "DO_NOT_FORWARD";
        vm.changeSection = function (section) { vm.selectedSection = section; };

		// Functions

		activate();

		////////////////////////////////

        function activate() {
            refreshCounts();
            $scope.$on("security:countersChanged", refreshCounts);
        }

        function refreshCounts() {
            $http
                .get("~/api/v1/settings/sysadmin/forward-blacklist")
                .then(function (result) {
                    vm.gridSections[0].length = (result.data.forwardBlacklists || []).length;
				}, function () {});

            $http
                .get("~/api/v1/settings/sysadmin/priority-overrides")
                .then(function (result) {
                    vm.gridSections[1].length= (result.data.priorityOverrides || []).length;
				}, function () {});

            $http
                .get("~/api/v1/settings/sysadmin/global-mail")
                .then(function (result) {
                    vm.gridSections[2].length = (result.data.globalMailSettings.serverSettings.reservedDomainNames || []).length;
				}, function () {});
        }
	}

})();
