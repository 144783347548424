(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("filterRunCurrentFolder", filterRunCurrentFolder);
    function filterRunCurrentFolder($scope, $http, $mdDialog, selectedFolder, errorHandling, folderLabel) {
        $scope.controller = this;
        $scope.selectedFolder = selectedFolder;
        $scope.folderLabel = folderLabel;

        activate();

        function activate() {
            init();
        }

        async function init() {
            try {
                // Load available filters
                const success = await $http.get('~/api/v1/settings/content-filter-groups');
                $scope.filters = success.data.contentFilterGroups || [];
                $scope.selectedFilter = $scope.filters[0];
            } catch (err) {
                errorHandling.report(err);
            }
        }

        $scope.done = function () {
            $mdDialog.hide({
                    'success': true,
                    'folder': $scope.selectedFolder.path,
                    'filter': $scope.selectedFilter
                }
            );
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        };
    }
})();