(function () {
    'use strict'
    //validate-sysadmin-does-not-exists.directive
    angular
        .module('smartertools')
        .directive('validateSysadminDoesNotExists', validateSysadminDoesNotExists);

    function validateSysadminDoesNotExists($http, $q) {
        return {
            require: "ngModel",
            link: function (scope, element, attrs, ctrl) {
                ctrl.$asyncValidators.validateSysadminDoesNotExists = function (modelValue, viewValue) {
                    if (!viewValue || ctrl.$pristine) return $q.when(true);
                    var defer = $q.defer();
                    $http.get('~/api/v1/settings/sysadmin/sysadmin-does-not-exist/' + viewValue + '/')
                        .then(function (success) {
                            defer.resolve();
                        }, function (failure) {
                            defer.reject(failure);
                        });

                    return defer.promise;
                };
            }
        };
    }

})();