(function () {
	"use strict";

	angular
		.module("smartermail")
		.directive("noteCard", noteCardDirective);

	function noteCardDirective() {
		return {
			restrict: "EA",
			templateUrl: "app/notes/note-card.html",
			controller: function ($scope, $translate) {
				if ($scope.note.categories && $scope.note.categories.length) {
					$scope.note.categories.forEach(cat => {
						const translated = $translate.instant(cat.name);
						cat.translatedName = $("<div>").html(translated).text(); // Translate HTML encodes the string, so we need to undo that
					});
				}
			}
		};
	}

})();
