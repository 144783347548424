(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("userFilterRuleEditController", userFilterRuleEditController);

	function userFilterRuleEditController($scope, $mdDialog, $filter, $timeout, filterId, newRule, ruleTypes, ruleCategories, oldRule, usedRules, wildcard, internalType) {
		$scope.filterId = filterId;
		$scope.newRule = newRule;
		$scope.isCategorySelected = !newRule;
		$scope.isRuleSelected = !newRule;
		$scope.ruleCategories = ruleCategories;
		$scope.ruleTypes = ruleTypes;
		$scope.rule = oldRule;
		$scope.wildcard = wildcard;
		$scope.internalType = internalType;
		$scope.currentCategory = undefined;
		$scope.currentRule = undefined;
		$scope.rulesForCategory = [];
		$scope.logicInput = false;
		$scope.textInput = '';
		$scope.KBInput = '';
		$scope.bytesInput = '';
		$scope.dateTimeDisplays = { start: '', end: '' };
		$scope.timePickers = { start: undefined, end: undefined };
		$scope.boolOption = true;
		$scope.spamOption = 'low';
		$scope.isValid = true;

		$scope.monthOptions = [
			{ translation: "MONTH_NAMES_JANUARY",	value: 1,  days: 31 },
			{ translation: "MONTH_NAMES_FEBRUARY",	value: 2,  days: 28, daysAlt: 29 },
			{ translation: "MONTH_NAMES_MARCH",		value: 3,  days: 31 },
			{ translation: "MONTH_NAMES_APRIL",		value: 4,  days: 30 },
			{ translation: "MONTH_NAMES_MAY",		value: 5,  days: 31 },
			{ translation: "MONTH_NAMES_JUNE",		value: 6,  days: 30 },
			{ translation: "MONTH_NAMES_JULY",		value: 7,  days: 31 },
			{ translation: "MONTH_NAMES_AUGUST",	value: 8,  days: 31 },
			{ translation: "MONTH_NAMES_SEPTEMBER",	value: 9,  days: 30 },
			{ translation: "MONTH_NAMES_OCTOBER",	value: 10, days: 31 },
			{ translation: "MONTH_NAMES_NOVEMBER",	value: 11, days: 30 },
			{ translation: "MONTH_NAMES_DECEMBER",	value: 12, days: 31 },
		];
		generateYears();
		generateDays();
		$scope.dates = {
			start: { year: $scope.yearOptions[0], month: 1, day: 1 },
			end: { year: $scope.yearOptions[0], month: 1, day: 2 }
		};
		$scope.yearLimit = 0; $scope.monthLimit = 0; $scope.dayLimit = 0;
		updateDateLimits();

		function generateYears() {
			$scope.yearOptions = [];
			for (var i = Number(moment().format("Y")); i >= 1970; i--) {
				$scope.yearOptions.push(i);
			}
		}

		function generateDays() {
			$scope.dayOptions = [];
			for (var i = 1; i <= 31; i++) {
				$scope.dayOptions.push(i);
			}
		}

		function getMonthsDayLimit(year, monthValue) {
			var month = $.grep($scope.monthOptions, function (month) { return month.value == monthValue });
			return year % 4 == 0 && month[0].daysAlt ? month[0].daysAlt : month[0].days;
		}

		function updateDateLimits() {
			// Year Limit
			$scope.yearLimit = $scope.dates.start.year;
			$scope.startMonthDayLimit = getMonthsDayLimit($scope.dates.start.year, $scope.dates.start.month)

			if ($scope.dates.end.year < $scope.yearLimit)
				$scope.dates.end.year = $scope.yearLimit;

			// Month Limit
			if ($scope.dates.end.year === $scope.dates.start.year)
				$scope.monthLimit = $scope.dates.start.month > 12 ? 12 : $scope.dates.start.month;
			else
				$scope.monthLimit = 0;

			if ($scope.dates.end.month < $scope.monthLimit)
				$scope.dates.end.month = $scope.monthLimit;

			// Day Limit
			$scope.endMonthDayLimit = getMonthsDayLimit($scope.dates.end.year, $scope.dates.end.month);
			if ($scope.dates.end.month === $scope.dates.start.month && $scope.dates.end.year === $scope.dates.start.year)
				$scope.dayLimit = $scope.dates.start.day + 1 > $scope.endMonthDayLimit ? $scope.endMonthDayLimit : $scope.dates.start.day + 1;
			else
				$scope.dayLimit = 0;

			if ($scope.dates.end.day < $scope.dayLimit)				$scope.dates.end.day = $scope.dayLimit;
			if ($scope.dates.end.day > $scope.endMonthDayLimit)		$scope.dates.end.day = $scope.endMonthDayLimit;
			if ($scope.dates.start.day > $scope.startMonthDayLimit) $scope.dates.start.day = $scope.startMonthDayLimit;
		}

		// Functions
		$scope.updateDateLimits = updateDateLimits;
		$scope.getCurrentRule = getCurrentRule;
		$scope.getRulesForCategory = getRulesForCategory;
		$scope.loadArguments = loadArguments;
		$scope.getSpamTranslation = getSpamTranslation;
		$scope.buildSaveRule = buildSaveRule;
		$scope.save = save;
		$scope.delete = deleteThis;
		$scope.cancel = cancel;

		activate();

		/////////////////////////////

		function activate() {
			if (!$scope.newRule) {
				angular.forEach(usedRules, function (value) {
					$scope.ruleTypes = $.grep($scope.ruleTypes, function (rule) {
						if (rule.id === value.fieldType) {
							return (rule.id === $scope.rule.fieldType);
						}
						return true;
					});
				});
			} else {
				angular.forEach(usedRules, function (value) {
					$scope.ruleTypes = $.grep($scope.ruleTypes, function (rule) { return rule.id !== value.fieldType; });
				});
			}

			$scope.ruleCategories = $.grep($scope.ruleCategories, function (category) {
				for (var i = 0; i < $scope.ruleTypes.length; ++i) {
					if ($scope.ruleTypes[i].category === category.id) {
						return true;
					}
				}
				return false;
			});

			if (!$scope.newRule) {
				$scope.getCurrentRule();
				$scope.getRulesForCategory();
			}

			$scope.$watch('currentCategory', onCurrentCategoryChanged);
			$scope.$watch('currentRule', onCurrentRuleChanged);
			$scope.loadArguments();
		}

		function getCurrentRule() {
			var findRule = $.grep($scope.ruleTypes, function (rule) { return rule.id === $scope.rule.fieldType });
			if (findRule.length > 0) {
				$scope.currentRule = findRule[0];
			}
			var findCat = $.grep($scope.ruleCategories, function (cat) { return cat.id === $scope.currentRule.category });
			if (findCat.length > 0) {
				$scope.currentCategory = findCat[0];
			}
		};

		function getRulesForCategory() {
			$scope.rulesForCategory = $.grep($scope.ruleTypes, function (rule) { return rule.category === $scope.currentCategory.id});
		};

		var previousCategoryId = -1;
		var currentCategoryId = -1;
		function onCurrentCategoryChanged() {
			previousCategoryId = currentCategoryId;
			currentCategoryId = $scope.currentCategory ? $scope.currentCategory.id : -1;
			if ($scope.currentCategory) {
				$scope.isCategorySelected = true;
				$scope.getRulesForCategory();
				if ($scope.newRule || $scope.resetRule) {
					$scope.currentRule = $scope.rulesForCategory[0];
				}
				$scope.isRuleSelected = true;
				if ($scope.currentRule.id === $scope.rule.fieldType && (!$scope.newRule && !$scope.resetRule)) {
					$scope.loadArguments();
				} else {
					if ((previousCategoryId === 0 || currentCategoryId === 0) && (previousCategoryId === 2 || currentCategoryId === 2))
						return;
					$scope.textInput = '';
					$scope.KBInput = '';
					$scope.bytesInput = '';
				}
				//This is kinda weird but we really dont have much of an indicator besides this whether we should reset
				// the values on a change so after the initial load where this will be false reset it so it treats it
				// like a new condition.
				$scope.resetRule = true;
			}
		}

		function onCurrentRuleChanged() {
			if ($scope.currentRule) {
				if ($scope.currentRule.id === $scope.rule.fieldType) {
					$scope.loadArguments();
				} else if ($scope.newRule || $scope.resetRule) {
					if ((previousCategoryId === 0 || currentCategoryId === 0) && (previousCategoryId === 2 || currentCategoryId === 2))
					{ previousCategoryId = -1; return; }

					$scope.textInput = '';
					$scope.KBInput = '';
					$scope.bytesInput = '';
				}
			}
		}

		function loadArguments() {
			if (!$scope.newRule && !$scope.resetRule) {
				$scope.logicInput = $scope.rule.applyLogicalNot;
				switch ($scope.currentRule.inputType) {
					default:
					case 0:
						break;
					case 1:
						$scope.textInput = $scope.rule.searchArguments ? $scope.rule.searchArguments.join('\n') : '';
						break;
					case 2:
						$scope.KBInput = $scope.rule.searchArguments ? $scope.rule.searchArguments[0] / 1024 : '';
						break;
					case 3:
						$scope.bytesInput = $scope.rule.searchArguments ? Number($scope.rule.searchArguments[0]) : '';
						break;
					case 4:
						if ($scope.rule.searchArguments) {
							var startSplit = $scope.rule.searchArguments[0].split("/");
							var endSplit = $scope.rule.searchArguments[1].split("/");
							$scope.dates.start = { year: Number(startSplit[2]), month: Number(startSplit[0]), day: Number(startSplit[1]) };
							$scope.dates.end = { year: Number(endSplit[2]), month: Number(endSplit[0]), day: Number(endSplit[1]) };
							updateDateLimits();
						}
						break;
					case 5:
						$scope.spamOption = $scope.rule.searchArguments ? $scope.rule.searchArguments[0] : 'low';
						break;
					case 6:
						if ($scope.rule.searchArguments) {
							if ($scope.rule.searchArguments[0] == '0') {
								$scope.boolOption = true;
							} else {
								$scope.boolOption = false;
							}
						} else {
							$scope.boolOption = false;
						}
						break;
				}
			}
		};

		function getSpamTranslation(spam) {
			switch(spam) {
				case 'low':
					return $filter('translate')('CONTENT_FILTERING_SPAM_LOW');
				case 'normal':
					return $filter('translate')('CONTENT_FILTERING_SPAM_MED');
				case 'high':
					return $filter('translate')('HIGH');
			}
		};

		function buildSaveRule() {
			switch($scope.currentRule.inputType) {
				default:
				case 0:
					return { applyLogicalNot: $scope.logicInput, fieldType: $scope.currentRule.id, filterType: 2, isWildcard: $scope.wildcard, searchArguments: [] };
				case 1:
					var splitArguments = $scope.textInput ? $scope.textInput.split('\n') : [];
					return { applyLogicalNot: $scope.logicInput, fieldType: $scope.currentRule.id, filterType: 2, isWildcard: $scope.wildcard, searchArguments: splitArguments };
				case 2:
					return { applyLogicalNot: $scope.logicInput, fieldType: $scope.currentRule.id, filterType: 2, isWildcard: $scope.wildcard, searchArguments: [($scope.KBInput * 1024).toString()] };
				case 3:
					return { applyLogicalNot: $scope.logicInput, fieldType: $scope.currentRule.id, filterType: 2, isWildcard: $scope.wildcard, searchArguments: [($scope.bytesInput).toString()] };
				case 4:
					var start = moment($scope.dates.start.year + "-" + $scope.dates.start.month + "-" + $scope.dates.start.day, "YYYY-MM-DD").format('MM/DD/YYYY');
					var end = moment($scope.dates.end.year + "-" + $scope.dates.end.month + "-" + $scope.dates.end.day, "YYYY-MM-DD").format('MM/DD/YYYY');
					return { applyLogicalNot: $scope.logicInput, fieldType: $scope.currentRule.id, filterType: 2, isWildcard: $scope.wildcard, searchArguments: [start, end] };
				case 5:
					return { applyLogicalNot: $scope.logicInput, fieldType: $scope.currentRule.id, filterType: 2, isWildcard: $scope.wildcard, searchArguments: [$scope.spamOption] };
				case 6:
					return { applyLogicalNot: $scope.logicInput, fieldType: $scope.currentRule.id, filterType: 2, isWildcard: $scope.wildcard, searchArguments: [$scope.boolOption ? '0' : '1'] };
			}
		};

		function save() {
			var saveRule = $scope.buildSaveRule();
			$mdDialog.hide({ rule: saveRule, oldType: $scope.rule.fieldType, deleteRule: false });
		};

		function deleteThis() {
			$mdDialog.hide({ fieldType: $scope.currentRule.id, deleteRule: true });
		};

		function cancel() {
			$mdDialog.cancel();
		};
	}

})();
