(function () {

    angular
        .module("smartertools")
		.filter('unicodeNumber', unicodeNumberFilter);

	function unicodeNumberFilter($filter) {
        return function (number, fractionSize) {
			return $filter('number')(number, fractionSize).replace(/[\u00A0\u1680​\u180e\u2000-\u2009\u200a​\u200b​\u202f\u205f​\u3000]/g, ' ');
        };
    }

})();