(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("mailSigningFieldsDialogController", mailSigningFieldsDialogController);

	function mailSigningFieldsDialogController($mdDialog, fields) {
		var vm = this;
		
		vm.fieldsAsText = fields.join("\n");

		vm.cancel = function () {
			$mdDialog.cancel();
		}
		vm.save = function () {
			var fields = vm.fieldsAsText
				? vm.fieldsAsText.trim().split("\n")
				: [];

			for (let i = 0; i < fields.length; i++) {
				fields[i] = fields[i].trim();
			}

			$mdDialog.hide({fields: fields});
		}
	}
})();