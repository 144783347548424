(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("domainEditTextController", domainEditTextController);

	function domainEditTextController($scope, $mdDialog, name, text) {
		$scope.name = name;
        $scope.text = text;
        
		$scope.save = function () {
			$mdDialog.hide($scope.text);
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}

})();
