(function () {
	'use strict';

	angular
		.module('smartertools')
		.directive('webRtcSrc', webRtcSrc);

	function webRtcSrc() {
		return {
			restrict: 'A',
			scope: {
				webrtcSrc: '='
			},
			link: function(scope, element) {
				const tagName = element[0].tagName.toLowerCase();
				if (tagName !== 'video' && tagName !== 'audio') {
					throw new Error('webrtcSrc directive can only be used on video or audio elements');
				}
				if (!('srcObject' in element[0])) {
					// Handle the case where srcObject is not supported
					throw new Error('Browser does not support srcObject.');
				}
				scope.$watch('webRtcSrc', function(newStream) {
					if (newStream) {
						element[0].srcObject = newStream;
					} else {
						element[0].srcObject = null;
					}
				});
			}
		};
	}

})();


