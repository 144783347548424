(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminSettingsGeneralEventItemController", sysAdminSettingsGeneralEventItemController);

	function sysAdminSettingsGeneralEventItemController($scope, $http, $filter, $q, $mdDialog, $state, $timeout,
		coreData, coreDataSysAdminCards, eventData, errorHandling) {

		var vm = this;

		vm.eventHookId = $state.params.id;
		vm.eventHook = null;
		vm.availableEvents = [];
		vm.lastCategory = null;
		vm.lastEvent = null;
		vm.canDelete = false;

		// Functions
		vm.save = save;
		vm.deleteThis = deleteThis;
		vm.addCondition = addCondition;
		vm.editCondition = editCondition;
		vm.addAction = addAction;
		vm.editAction = editAction;
		vm.getActionTranslation = eventData.getActionTranslation;
		vm.getGenericConditionTranslation = eventData.getGenericConditionTranslation;
		vm.getConditionTranslation = eventData.getConditionTranslation;
		vm.getConditionTypeString = eventData.getConditionTypeString;
		vm.getActionMiddleString = eventData.getActionMiddleString;
		vm.getActionBottomString = eventData.getActionBottomString;
		vm.verifyEventTypeChange = verifyEventTypeChange;
		vm.verifyEventCategoryChange = verifyEventCategoryChange;
		vm.isConditionEditable = isConditionEditable;

		activate();

		////////////////////

		function activate() {
			$q
				.all([
					$http.get("~/api/v1/settings/sysadmin/sysadmin-events"),
					$http.post("~/api/v1/settings/sysadmin/event-hooks-by-owner"),
					$http.get("~/api/v1/settings/sysadmin/event-variables")
				])
				.then(onDataLoaded, errorHandling.report);

			function onDataLoaded(result) {
				vm.availableEvents = result[0].data.events || [];
				parseAvailableEventOptions();

				var hooks = result[1].data.eventHooks || [];
				angular.forEach(hooks, function (value) { if (value.id === vm.eventHookId) vm.eventHook = value; });
				if (!vm.eventHook) {
					vm.eventHook = {
						actions: [], actionsByKey: {}, conditions: [], conditionsByKey: {}, enabled: true,
						eventID: null, groupID: coreData.user.domain, isNew: true, name: "", owner: ""
					};
				}
				vm.eventHook.conditions = vm.eventHook.conditions || [];
				vm.eventHook.actions = vm.eventHook.actions || [];
				vm.canDelete = vm.eventHookId !== "new" && vm.eventHook.eventGroup !== "@DefaultEventGroupStatic";
				vm.readOnly = vm.eventHook.eventGroup === "@DefaultEventGroupStatic";

				// pre-fill selectedCategory and selectedEventType
				angular.forEach(vm.eventCategories,
					function(cat) {
						angular.forEach(cat.events,
							function(event) {
								if (!vm.eventHook.eventID)
									vm.eventHook.eventID = event.eventId;
								if (event.eventId === vm.eventHook.eventID) {
									vm.selectedCategory = cat;
									vm.selectedEventType = event;
									return;
								}
							});
					});

				vm.eventVariables = result[2].data.variables;

				$scope.$watch(function () { return vm.selectedCategory; }, verifyEventCategoryChange);
				$scope.$watch(function () { return vm.selectedEventType; }, verifyEventTypeChange);

				$timeout(function () {
					$("#eventName").trigger("focus");
				}, 100);
			}
		}

		function parseAvailableEventOptions() {
			var retVal = [];
			angular.forEach(vm.availableEvents, function (value) {
				value.categoryResourceId = value.categoryResourceId.replace("@", "").toUpperCase()
				value.translation = "EVENT_TYPES_" + value.id.toUpperCase();

				var cat = null;
				angular.forEach(retVal, function (catValue) {
					if (catValue.categoryResourceId == value.categoryResourceId)
						cat = catValue;
				});
				if (!cat) {
					cat = { categoryResourceId: value.categoryResourceId, events: [], internalId: retVal.length };
					retVal.push(cat);
				}
				cat.events.push(value);
			});
			vm.eventCategories = retVal;
		}

		function categoryFromEvent(eventToLookup) {
			var found = null;
			angular.forEach(vm.eventCategories, function (cat) {
				angular.forEach(cat.events, function (event) {
					if (eventToLookup && eventToLookup.eventId == event.eventId)
						found= cat;
				});
			})
			return found;
		}

		function verifyEventCategoryChange(newValue, oldValue) {
			if (vm.lastCategory === newValue || !vm.lastCategory)
			{
				vm.lastCategory = newValue;
				return;
			}
		}

		function verifyEventTypeChange(newValue, oldValue) {
			if (vm.lastEvent === newValue || !vm.lastEvent) {
				applyChange();
				return;
			}

			var conditionsToRemove = [];
			angular.forEach(vm.eventHook.conditions, function (condition) {
				var found = false;
				angular.forEach(newValue.conditions, function (eventCondition) {
					if (eventCondition.key === condition.key &&
						eventCondition.inputType === condition.inputType &&
						eventCondition.descriptionResourceID === condition.descriptionResourceID)
						found = true;
				});
				if (!found)
					conditionsToRemove.push(condition);
			});

			var actionsToRemove = [];
			angular.forEach(vm.eventHook.actions, function (action) {
				var found = false;
				angular.forEach(newValue.possibleActions, function (eventAction) {
					if (eventAction.key === action.key &&
						eventAction.inputType === action.inputType &&
						eventAction.descriptionResourceID === action.descriptionResourceID)
						found = true;
				});
				if (!found)
					actionsToRemove.push(action);
			});

			if (!conditionsToRemove.length && !actionsToRemove.length) {
				applyChange();
				return;
			}

			var confirm = $mdDialog.confirm()
				.title($filter("translate")("EVENTS_NOTICE_CONDITIONS_AND_ACTIONS_CHANGED_TITLE"))
				.textContent($filter("translate")("EVENTS_NOTICE_CONDITIONS_AND_ACTIONS_CHANGED"))
				.ariaLabel($filter("translate")("EVENTS_NOTICE_CONDITIONS_AND_ACTIONS_CHANGED"))
				.ok($filter("translate")("CONTINUE"))
				.cancel($filter("translate")("CANCEL"));
			$mdDialog
				.show(confirm)
				.then(function () { applyChange(conditionsToRemove, actionsToRemove); }, revertChange);

			function revertChange() {
				vm.selectedCategory = categoryFromEvent(vm.lastEvent);
				vm.selectedEventType = vm.lastEvent;
				vm.eventHook.eventID = vm.lastEvent.eventId;
			}

			function applyChange(conditionsToRemove, actionsToRemove) {
				if (!newValue)
					return;
				if (conditionsToRemove) {
					angular.forEach(conditionsToRemove,
						function(c) {
							var index = vm.eventHook.conditions.indexOf(c);
							if (index !== -1)
								vm.eventHook.conditions.splice(index, 1);
							delete vm.eventHook.conditionsByKey[c.key];
						});
				}
				if (actionsToRemove) {
					angular.forEach(actionsToRemove,
						function(c) {
							var index = vm.eventHook.actions.indexOf(c);
							if (index !== -1)
								vm.eventHook.actions.splice(index, 1);
						});
				}

				vm.lastCategory = categoryFromEvent(newValue);
				vm.lastEvent = newValue;
				vm.eventHook.eventID = newValue.eventId;
			}
		}

		function save(event, form) {
			if (!form.$valid)
				return;

			var params = vm.eventHook;
			$http
				.post("~/api/v1/settings/sysadmin/event-hook", params)
				.then(onSaveSuccess, errorHandling.report);

			function onSaveSuccess(result) {
				if (!result.data.success) {
					errorHandling.report(result);
					return;
				}
				form.$setPristine();
				$state.go("^");
			}
		}

		function deleteThis(event, form) {
			if (vm.eventHookId === "new") {
				$state.go("^");
				return;
			}

			var confirm = $mdDialog.confirmDeletion()
				.textContent($filter("translate")("CONFIRMATIONS_DELETE_ITEMS", { items: 1 }))
				.targetEvent(event);

			$mdDialog
				.show(confirm)
				.then(onDoDelete, function () { });

			function onDoDelete() {
				vm.skipNavigateConfirm = true;
				var params = JSON.stringify({ input: [vm.eventHookId] });
				$http
					.post("~/api/v1/settings/sysadmin/event-hook-delete", params)
					.then(onSuccess, errorHandling.report);

				function onSuccess() {
					$state.go("^");
				}
			}
		}

		function isConditionEditable(eventId, condition) {
			return true;
		}

		function addCondition(ev, form) {
			$mdDialog
				.show({
					locals: {
						newCondition: true,
						conditionKey: "",
						eventId: vm.eventHook.eventID,
						operator: "Equals",
						availableEvents: vm.availableEvents,
						usedConditions: vm.eventHook.conditionsByKey,
						value1: undefined,
						value2: undefined,
						parseTimeSpan: coreDataSysAdminCards.parseUserTimeSpan,
						level: "user"
					},
					controller: "sysAdminEventConditionEditController",
					controllerAs: "EvMoCtrl",
					templateUrl: "app/sysadmin/settings/events/modals/event-condition.dlg.html",
					targetEvent: ev
				})
				.then(onAddComplete, function() {});

			function onAddComplete(result) {
				vm.eventHook.conditions.push(result.condition);
				vm.eventHook.conditionsByKey = vm.eventHook.conditionsByKey || [];
				vm.eventHook.conditionsByKey[result.condition.key] = result.condition;
				form.$setDirty();
			}
		}

		function editCondition(ev, condition, form) {
			$mdDialog
				.show({
					locals: {
						newCondition: false,
						conditionKey: condition.key,
						eventId: vm.eventHook.eventID,
						operator: condition.condition,
						availableEvents: vm.availableEvents,
						usedConditions: vm.eventHook.conditionsByKey,
						getConditionTranslation: eventData.getConditionTranslation,
						value1: condition.value1,
						value2: condition.value2,
						parseTimeSpan: coreDataSysAdminCards.parseUserTimeSpan,
						level: "user"
					},
					controller: "sysAdminEventConditionEditController",
					controllerAs: "EvMoCtrl",
					templateUrl: "app/sysadmin/settings/events/modals/event-condition.dlg.html",
					targetEvent: ev
				})
				.then(onEditComplete, function () {});

			function onEditComplete(result) {

				if (result.deleteCond) {
					var confirm = $mdDialog.confirmDeletion()
						.textContent($filter("translate")("CONFIRMATIONS_DELETE_ITEMS", { items: 1 }))
						.targetEvent(event);
					$mdDialog
						.show(confirm)
						.then(function() {
								var index = vm.eventHook.conditions.indexOf(condition);
								if (index !== -1) {
									vm.eventHook.conditions.splice(index, 1);
									delete vm.eventHook.conditionsByKey[result.condition.key];
									form.$setDirty();
								}
							},
							function() {});

					return;
				}

				var index = vm.eventHook.conditions.indexOf(condition);
				if (index === -1)
					vm.eventHook.conditions.push(result.condition);
				else
					vm.eventHook.conditions[index] = result.condition;

				form.$setDirty();
			}
		}

		function addAction(ev, form) {
			var baseEvent = vm.availableEvents[vm.eventHook.eventID];
			var defaultAction = $.grep(baseEvent.possibleActions, function (action) { return action.key === "AlertAction"; });
			if (!defaultAction[0]) {
				errorHandling.report("ERROR_GETTING_ACTION");
				return;
			}

			$mdDialog
				.show({
					locals: {
						newAction: true,
						eventGuid: vm.eventHookId,
						eventId: vm.eventHook.eventID,
						availableEvents: vm.availableEvents,
						availableVariables: vm.eventVariables,
						action: defaultAction[0],
						defaultEmail: coreData.user.emailAddress,
						level: "user"
					},
					controller: "sysAdminEventActionEditController",
					controllerAs: "EvMoCtrl",
					templateUrl: "app/sysadmin/settings/events/modals/event-action.dlg.html",
					targetEvent: ev
				})
				.then(onAddComplete, function () {});

			function onAddComplete(result) {
				vm.eventHook.actions.push(result.action);
				form.$setDirty();
			}
		}

		function editAction(ev, action, form) {
			$mdDialog
				.show({
					locals: {
						newAction: false,
						eventGuid: vm.eventHookId,
						eventId: vm.eventHook.eventID,
						availableEvents: vm.availableEvents,
						availableVariables: vm.eventVariables,
						action: angular.copy(action),
						defaultEmail: coreData.user.emailAddress,
						level: "user"
					},
					controller: "sysAdminEventActionEditController",
					controllerAs: "EvMoCtrl",
					templateUrl: "app/sysadmin/settings/events/modals/event-action.dlg.html",
					targetEvent: ev
				})
				.then(onEditComplete, function () {});

			function onEditComplete(result) {
				var index;
				if (result.deleteAction) {
					index = vm.eventHook.actions.indexOf(action);
					if (index !== -1) {
						vm.eventHook.actions.splice(index, 1);
						form.$setDirty();
					}

					return;
				}
				index = vm.eventHook.actions.indexOf(action);
				if (index === -1)
					vm.eventHook.actions.push(result.action);
				else
					vm.eventHook.actions[index] = result.action;
				form.$setDirty();
			}
		}
	}

})();
