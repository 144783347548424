(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("emailInviteFunctions", emailInviteFunctions);

    /*
     * IMPORTANT NOTE:
     * 
     * Most of the functions defined in this service do not do error checking.It is your responsibility
     * to handle exceptions and display them, usually using errorHandling.report(err).
     * 
     * Exceptions due to modal cancellations WILL be handled by this service, however. Exceptions should only
     * be thrown for exceptional events or issues, not for user cancellations.
     * 
     */

    function emailInviteFunctions($http, $mdDialog) {
        var vm = this;

        // Functions
        vm.acceptInvite = acceptInvite;
        vm.declineInvite = declineInvite;
        vm.deleteInvite = deleteInvite;
        vm.showAvailabilityModal = showAvailabilityModal;
        vm.tentativeAcceptInvite = tentativeAcceptInvite;
        vm.openProposeChangesModal = openProposeChangesModal;

        ////////////////////////////////////////

        async function acceptInvite(owner, calendarId, eventId, rawMeetingInfo, replyComment) {
            const url = `~/api/v1/calendars/meeting-accept/${owner}/${calendarId}/${eventId}`;
            const parameters = JSON.stringify({
                meetingInfoRaw: rawMeetingInfo,
                replyingComment: replyComment
            });
            await $http.post(url, parameters);
        }

        async function tentativeAcceptInvite(owner, calendarId, eventId, rawMeetingInfo, replyComment) {
            const url = `~/api/v1/calendars/meeting-tentatively-accept/${owner}/${calendarId}/${eventId}`;
            const parameters = JSON.stringify({
                meetingInfoRaw: rawMeetingInfo,
                replyingComment: replyComment
            });
            await $http.post(url, parameters);
        }

        async function openProposeChangesModal(eventInfo, start, eventId, $event, replyingComment) {
            let browserOffset = moment(start.dt).utcOffset();
            let timezoneOffset = moment.tz(moment(), start.tz).utcOffset();
            const offset = timezoneOffset - browserOffset;
            let newStart = moment.tz(start.dt, start.tz).add(offset, "m").utc().toDate();
            let params = {
                locals: { eventInfo: eventInfo, date: newStart, id: eventId, replyingComment: replyingComment, fromEmail: true},
                controller: "calendarEventProposeController",
                controllerAs: "calendarEventProposeCtrl",
                templateUrl: "app/calendars/modals/calendar-event-propose.dlg.html",
                targetEvent: $event
            };
            await $mdDialog.show(params);
        }

        async function declineInvite(owner, calendarId, eventId, rawMeetingInfo, replyComment) {
            const url = `~/api/v1/calendars/meeting-decline/${owner}/${calendarId}/${eventId}`;
            const parameters = JSON.stringify({
                meetingInfoRaw: rawMeetingInfo,
                replyingComment: replyComment
            });
            await $http.post(url, parameters);
        }

        async function deleteInvite(owner, calendarId, eventId, recurrenceId) {
            const url = `~/api/v1/calendars/meeting-delete/${owner}/${calendarId}/${eventId}`;
            var parameters = recurrenceId ? { instanceStart: recurrenceId } : {};
            await $http.post(url, parameters);
        }

        function showAvailabilityModal(start, attendees, $event) {
            var info = {
                start: start,
                attendees: attendees
            };
            $mdDialog.show({
                locals: {
                    eventInfo: { info: info },
                    date: new Date(start.dt)
                },
                controller: "calAvailabilityController",
                controllerAs: "ctrl",
                templateUrl: "app/calendars/modals/calendar-availability.dlg.html",
                targetEvent: $event,
                clickOutsideToClose: false
            });
        }
    }
})();
