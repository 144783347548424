(function () {
    angular
        .module('smartermail')
        .config(config)
        .config(templateCacheBust)
        .run([
            "$rootScope", "$state", function ($rootScope, $state) {
                $rootScope.$state = $state;


                // To debug UI-ROUTER, use the items below NOTE this is NO LONGER VALID as 
                /*
                //	NOTE: State change events are deprecated, DISABLED and replaced by Transition Hooks as of version 1.0 (https://ui-router.github.io/guide/ng1/migrate-to-1_0#state-change-events)
                $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
                    debugger;
                    console.log('$stateChangeStart from ' + fromState.name + '- fired when the transition begins. fromState,fromParams : \n', fromState, fromParams);
                    console.log('$stateChangeStart to ' + toState.name + '- fired when the transition begins. toState,toParams : \n', toState, toParams);
                });

                $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams) {
                    console.log('$stateChangeError - fired when an error occurs during transition.');
                    console.log(arguments);
                });

                $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                    console.log('$stateChangeSuccess from ' + toState.name + '- fired once the state transition is complete.');
                    console.log('$stateChangeSuccess to ' + toState.name + '- fired once the state transition is complete.');
                });

                $rootScope.$on('$viewContentLoaded', function (event) {
                    console.log('$viewContentLoaded - fired after dom rendered', event);
                    console.log(event.targetScope && event.targetScope.$resolve && event.targetScope.$resolve.$state$);
                    console.log(event.targetScope && event.targetScope.$resolve && event.targetScope.$resolve.$stateParams);
                });

                $rootScope.$on('$stateNotFound', function (event, unfoundState, fromState, fromParams) {
                    console.log('$stateNotFound ' + unfoundState.to + '  - fired when a state cannot be found by its name.');
                    console.log(unfoundState, fromState, fromParams);
                });
                */
            }
        ]);


    function templateCacheBust($provide) {
        return $provide.decorator("$http",
            [
                "$delegate", "$log", function ($delegate, $log) {
                    var get = $delegate.get;
                    $delegate.get = function (url, config) {
                        var appindex = url.indexOf("app/");
                        var interfaceappindex = url.indexOf("interface/app/");
                        if ((appindex == 0 || appindex == 1 || interfaceappindex == 0 || interfaceappindex == 1) &&
                            url.indexOf(".html") != -1) {
                            url += (url.indexOf("?") === -1 ? "?" : "&");
                            url += htmlCacheBustQs;
                        }
                        return get(url, config);
                    };
                    return $delegate;
                }
            ]);
    }

    /* @ngInject */
    function config($stateProvider, $urlServiceProvider, $httpProvider) {
        const mailUserAuth = {
            hasDomain: true,
            roles: [],
            claims: ["nameid"]
        };
        const domainAdminAuth = {
            roles: ["DomainAdmin"],
            claims: []
        };
        const sysAdminAuth = {
            roles: ["SysAdmin"],
            claims: []
        };


        try {
            var locale = window.navigator.userLanguage || window.navigator.language || 'en_US';
            moment.locale(normalizeLocale(locale));
        } catch (ex) { }
        $httpProvider.interceptors.push("siteRootPathInterceptor");
        $httpProvider.interceptors.push("authInterceptor");
        $urlServiceProvider.rules.otherwise("/login");

        configureUserSettingsStates($stateProvider);
        configureDomainSettingsStates($stateProvider);

        $stateProvider.state('login',
            {
                url: "/login",
                templateUrl: "app/authentication/login.html",
                data: { pageTitle: "LOGIN" }
            });
        $stateProvider.state('disconnected',
            {
                url: "/disconnected",
                template: "<div ng-controller='disconnectedController as vm'><div class='login-wrapper full-screen-bg-image'><div class='login-backdrop'><div class='login-modal'><div class='maintainance'><div class='container'><i class='toolsicon toolsicon-warning_fill'></i></div></div><p>{{::'LOGIN_PAGE_MAINTENANCE_BODY'|translate}}</p></div></div></div></div>"
            });
        $stateProvider.state('popout',
            {
                abstract: true,
                url: "",
                templateUrl: "app/root/popout.html"
            });
        $stateProvider.state('popout.emailCompose',
            {
                url: "/popout/email/compose/{packet}",
                templateUrl: "app/email/compose/popout.html",
                data: {
                    pageTitle: 'EMAIL',
                    auth: mailUserAuth
                }
            });
        $stateProvider.state('popout.emailView',
            {
                url:
                    "/popout/email/view/{packet}",
                templateUrl: "app/email/email-popout.html",
                data: {
                    pageTitle: 'EMAIL',
                    auth: mailUserAuth
                }
            });
        $stateProvider.state('popout.emailSpoolView',
            {
                url: "/popout/email/view-spool/{packet}",
                templateUrl: "app/email/spool/popout.html",
                data: {
                    pageTitle: 'EMAIL',
                    auth: sysAdminAuth
                }
            });
        $stateProvider.state('popout.emailArchiveView',
            {
                url: "/popout/email/view-archive/{packet}",
                templateUrl: "app/email/archive/popout.html",
                data: {
                    pageTitle: 'EMAIL',
                    auth: domainAdminAuth
                }
            });
        $stateProvider.state('popout.noteNew',
            {
                url:
                    "/popout/note/new",
                templateUrl: "app/notes/note-view.html",
                data: {
                    pageTitle: 'NOTE',
                    auth: mailUserAuth
                }
            });
        $stateProvider.state('popout.note',
            {
                url:
                    "/popout/note/{id}/{sourceId}/{sourceOwner}",
                templateUrl: "app/notes/note-view.html",
                data: {
                    pageTitle: 'NOTE',
                    auth: mailUserAuth
                }
            });

        $stateProvider.state('popout.newOnlyOfficedoc',
            {

                url: "/popout/filestorage/newOnlyOfficeDoc/",
                templateUrl: "app/file-storage/newOnlyOfficeDoc.html",
                data: {
                    pageTitle: 'New Document',
                    auth: mailUserAuth
                }

            });




        $stateProvider.state('popout.taskNew',
            {
                url: "/popout/task/new",
                templateUrl: "app/tasks/task-view.html",
                data: {
                    pageTitle: 'TASK',
                    auth: mailUserAuth
                }
            });
        $stateProvider.state('popout.task',
            {
                url: "/popout/task/{taskId}/{sourceId}/{owner}",
                templateUrl: "app/tasks/task-view.html",
                data: {
                    pageTitle: 'TASK',
                    auth: mailUserAuth
                }
            });
        $stateProvider.state('popout.chat',
            {
                url: "/popout/chat",
                templateUrl: "app/chats/chat-view.html",
                data: {
                    pageTitle: 'CHAT',
                    auth: mailUserAuth
                }
            });
        $stateProvider.state('popout.viewNew',
            {
                url: "/popout/appointment/new",
                params: { owner: null, calId: null, id: null, data: { start: null } },
                templateUrl: "app/calendars/event-view.html",
                data: {
                    pageTitle: 'CALENDAR',
                    auth: mailUserAuth
                }
            });
        $stateProvider.state('popout.calEvent',
            {
                url: "/popout/appointment/{owner}/{calId}/{id}",
                params: { data: { start: null } },
                templateUrl: "app/calendars/event-view.html",
                data: {
                    pageTitle: 'APPOINTMENT',
                    auth: mailUserAuth
                }
            });
        $stateProvider.state('popout.scheduleAppoint',
            {
                url: "/popout/schedule/appointment",
                params: { data: { start: null } },
                templateUrl: "app/sections/schedule-appointment/modals/schedule-appointment.dlg.html",
                data: { pageTitle: 'SCHEDULE' }
            });
        $stateProvider.state('popout.contact',
            {
                url: "/popout/contact/{id}/{sourceOwner}/{sourceId}",
                templateUrl: "app/contacts/contact-view.html",
                data: {
                    pageTitle: 'CONTACT',
                    auth: mailUserAuth
                }
            });
        $stateProvider.state('popout.contactNew',
            {
                url: "/popout/contact/{id}",
                templateUrl: "app/contacts/contact-view.html",
                data: {
                    pageTitle: 'CONTACT',
                    auth: mailUserAuth
                }
            });
        $stateProvider.state('popout.contactGroup',
            {
                url: "/popout/contactGroup/{id}/{sourceOwner}/{sourceId}",
                templateUrl: "app/contacts/contact-group-view.html",
                data: {
                    pageTitle: 'CONTACT',
                    auth: mailUserAuth
                }
            });
        $stateProvider.state('popout.contactGroupNew',
            {
                url: "/popout/contactGroup/{id}",
                templateUrl: "app/contacts/contact-group-view.html",
                data: {
                    pageTitle: 'CONTACT',
                    auth: mailUserAuth
                }
            });
        $stateProvider.state('popout.chattranscript',
            {
                url: "/popout/chat-search/transcript/{username}/{id}/{dtStart}/{dtStop}",
                templateUrl: "app/settings/domain-settings/chat-search/chat-transcript.html",
                data: {
                    pageTitle: 'CHAT',
                    auth: mailUserAuth
                }
            });
        $stateProvider.state("popout.meetingNew",
            {
                url: "/popout/meeting/{id}",
                templateUrl: "app/meetings/meeting-view.html",
                data: {
                    pageTitle: "MEETING",
                    auth: mailUserAuth
                }
            });
        $stateProvider.state('popout.notifications',
            {
                url: '/popout/notifications',
                templateUrl: 'app/notifications/notifications.html',
                data: {
                    pageTitle: 'NOTIFICATIONS',
                    auth: {
                        roles: [],
                        claims: ["nameid"]
                    }
                }
            });
        $stateProvider.state('index.sysadmin-notifications',
            {
                url: '/notifications',
                data: {
                    pageTitle: 'NOTIFICATIONS',
                    auth: sysAdminAuth
                },
                views: {
                    "mainContentView@index": {
                        templateUrl: "app/notifications/notifications.html",
                        data: { pageTitle: 'NOTIFICATIONS' },
                    }
                }
            });
        $stateProvider.state('popout.loginPreview',
            {
                url:
                    "/popout/login-preview",
                templateUrl: "app/shared/login-preview.html",
                data: {
                    pageTitle: 'LOGIN_PREVIEW',
                    auth: domainAdminAuth
                }
            });
        $stateProvider.state('popout.emailSearch',
            {
                url: "/popout/email-search",
                templateUrl: "app/search/search-view.html",
                data: {
                    pageTitle: 'ADVANCED_SEARCH',
                    auth: mailUserAuth
                }
            });

        $stateProvider.state('index',
            {
                abstract: true,
                url: "",
                views: {
                    "@": {
                        templateUrl: "app/root/main.html"
                    }
                },
                data: {
                    auth: mailUserAuth
                }
            });
        $stateProvider.state('index.email',
            {
                url: "/email",
                views: {
                    "mainContentView@index": {
                        templateUrl: "app/email/email-section.html",
                        data: { pageTitle: 'EMAIL' },
                    }
                }
            });
        $stateProvider.state('index.chat',
            {
                url: "/chat",
                views: {
                    "mainContentView@index": {
                        templateUrl: "app/chats/chat-section.html",
                        data: { pageTitle: 'CHAT' }
                    }
                }
            });
        $stateProvider.state('index.meetings',
            {
                url: "/meetings",
                views: {
                    "mainContentView@index": {
                        templateUrl: "app/meetings/meetings-section.html",
                        data: { pageTitle: 'MEETINGS' }
                    }
                }
            });

        //$stateProvider.state('index.advanced-search',
        //    {
        //        url: "/advanced-search",
        //        views: {
        //            "mainContentView@index": {
        //                templateUrl: "app/search/search-view.html",
        //                data: { pageTitle: 'ADVANCED_SEARCH' }
        //            }
        //        }
        //    });

        $stateProvider.state('index.contacts',
            {
                url: "/contacts",
                views: {
                    "mainContentView@index": {
                        templateUrl: "app/contacts/contacts.html",
                        data: { pageTitle: 'CONTACTS', selectedCard: {} }
                    }
                },
            });
        $stateProvider.state('index.contacts.view',
            {
                url: "/{id}/{source}",
                views: {
                    "mainContentView@index": {
                        templateUrl: "app/contacts/contact-view.html",
                        data: { pageTitle: 'CONTACTS' }
                    }
                }
            });
        $stateProvider.state('index.calendar',
            {
                url: "/calendar",
                views: {
                    "mainContentView@index": {
                        templateUrl: "app/calendars/calendar.html",
                        data: { pageTitle: 'CALENDAR' }
                    }
                }
            });
        $stateProvider.state('index.calendar.view',
            {
                url: "/:owner/:calId/:id",
                params: { data: { start: null } },
                views: {
                    "mainContentView@index": {
                        templateUrl: "app/calendars/event-view.html",
                        data: { pageTitle: 'CALENDAR' }
                    }
                }
            });
        $stateProvider.state('index.calendar.viewNew',
            {
                url: "/new",
                params: { owner: null, calId: null, id: null, data: { start: null } },
                views: {
                    "mainContentView@index": {
                        templateUrl: "app/calendars/event-view.html",
                        data: { pageTitle: 'CALENDAR' }
                    }
                }
            });
        $stateProvider.state('index.calendar.viewTask',
            {
                url: "/task/{id}",
                views: {
                    "mainContentView@index": {
                        templateUrl: "app/tasks/task-view.html",
                        data: { pageTitle: 'TASKS' }
                    }
                }
            });
        $stateProvider.state('index.tasks',
            {
                url: "/tasks",
                views: {
                    "mainContentView@index": {
                        templateUrl: "app/tasks/tasks.html",
                        data: { pageTitle: 'TASKS' }
                    }
                }
            });
        $stateProvider.state('index.tasks.view',
            {
                url: "/{id}",
                views: {
                    "mainContentView@index": {
                        templateUrl: "app/tasks/task-view.html",
                        data: { pageTitle: 'TASKS' }
                    }
                }
            });
        $stateProvider.state('index.notes',
            {
                url: "/notes",
                views: {
                    "mainContentView@index": {
                        templateUrl: "app/notes/notes.html",
                        data: { pageTitle: 'NOTES' }
                    }
                }
            });
        $stateProvider.state('index.notes.view',
            {
                url: "/{id}",
                views: {
                    "mainContentView@index": {
                        templateUrl: "app/notes/note-view.html",
                        data: { pageTitle: 'NOTES' }
                    }
                }
            });
        $stateProvider.state('index.rss-feeds',
            {
                url: "/rss-feeds",
                views: {
                    "mainContentView@index": {
                        templateUrl: "app/rss-feeds/rss-feeds.html",
                        data: { pageTitle: 'RSS_FEEDS' }
                    }
                }
            });
        $stateProvider.state('index.file-storage',
            {
                url: "/file-storage",
                views: {
                    "mainContentView@index": {
                        templateUrl: "app/file-storage/file-storage.html",
                        data: { pageTitle: 'FILE_STORAGE' }
                    }
                }
            });
        $stateProvider.state('index.reports',
            {
                url: "/reports",
                data: {
                    pageTitle: 'REPORTS',
                    auth: {
                        roles: [],
                        claims: ["nameid"]
                    }
                },
                views: {
                    "mainContentView@index": { templateUrl: "app/reports/reports.html" }
                }
            });
        $stateProvider.state('index.reports.reports-summary',
            {
                url: "/{type}",
                data: { pageTitle: 'REPORTS' },
                views: {
                    "reportsContentView@index.reports": { templateUrl: "app/reports/reports-summary.html" }
                },
                params: {
                    type: null
                }
            });
        $stateProvider.state('index.reports.reports-details',
            {
                url: "/{type}/{report}?return={returnpath}",
                views: {
                    "reportsContentView@index.reports": {
                        templateUrl: "app/reports/report-details.html",
                        data: { pageTitle: 'REPORTS' }
                    }
                },
                params: { type: null, report: null }
            });
        $stateProvider.state('index.reports.reports-details-sources',
            {
                url: "/{type}/{report}/{source}",
                views: {
                    "reportsContentView@index.reports": {
                        templateUrl: "app/reports/report-details.html",
                        data: { pageTitle: 'REPORTS' }
                    }
                },
                params: { type: null, report: null }
            });
        $stateProvider.state('index.reports.domain-reports-summary',
            {
                url: "/domain/{type}",
                data: { pageTitle: 'REPORTS' },
                views: {
                    "reportsContentView@index.reports": { templateUrl: "app/reports/reports-summary.html" }
                },
                params: {
                    type: null
                }
            });
        $stateProvider.state('index.reports.domain-reports-details',
            {
                url: "/{type}/{report}?return={returnpath}",
                views: {
                    "reportsContentView@index.reports": {
                        templateUrl: "app/reports/report-details.html",
                        data: { pageTitle: 'REPORTS' }
                    }
                },
                params: { type: null, report: null }
            });
        $stateProvider.state('index.reports.dashboard-server',
            {
                url: "/dashboard/server",
                views: {
                    "reportsContentView@index.reports": {
                        templateUrl: "app/reports/dashboard.html",
                        data: {
                            pageTitle: 'SERVER_HEALTH'
                        }
                    }
                },
                params: {
                    type: null
                }
            });
    }

    function configureDomainSettingsStates($stateProvider) {
        $stateProvider.state('index.settings.domain-users-connections',
            {
                url: "/domain-users-connections",
                data: { pageTitle: 'USER_CONNECTIONS' },
                views: {
                    "settingsContentView": { templateUrl: "app/settings/domain-settings/users-connections/users-connections.html" }
                }
            });
        $stateProvider.state('index.settings.domain-users-statuses',
            {
                url: "/domain-users-statuses",
                data: { pageTitle: 'USER_STATUSES' },
                views: {
                    "settingsContentView": { templateUrl: "app/settings/domain-settings/users-statuses/users-statuses.html" }
                }
            });
        $stateProvider.state('index.settings.domain-general-settings',
            {
                url: "/domain-general-settings",
                data: { pageTitle: 'DOMAIN_SETTINGS' },
                views: {
                    "settingsContentView@index.settings": {
                        templateUrl: "app/settings/domain-settings/general-settings/general-settings.html"
                    }
                }
            });
        $stateProvider.state('index.settings.domain-email-customization',
            {
                url: "/domain-email-customization",
                data: { pageTitle: 'DOMAIN_SIGNATURES' },
                views: {
                    "settingsContentView": {
                        templateUrl: "app/settings/domain-settings/email-customization/email-customization.html"
                    }
                }
            });

        $stateProvider.state('index.settings.domain-spam-filtering',
            {
                url: "/domain-spam-filtering",
                data: { pageTitle: 'DOMAIN_SPAM_FILTERING' },
                views: {
                    "settingsContentView": { templateUrl: "app/settings/domain-settings/spam-filtering/spam-filtering.html" }
                }
            });
        $stateProvider.state('index.settings.domain-content-filtering',
            {
                url: "/domain-content-filtering",
                data: { pageTitle: 'DOMAIN_CONTENT_FILTERING' },
                views: {
                    "settingsContentView": { templateUrl: "app/settings/domain-settings/content-filters/content-filters.html" }
                }
            });
        $stateProvider.state('index.settings.domain-content-filtering.specific',
            {
                url: "/{contentfilterid}",
                data: { pageTitle: 'DOMAIN_CONTENT_FILTER' },
                views: {
                    "settingsContentView@index.settings": { templateUrl: "app/settings/domain-settings/content-filters/content-filter.html" }
                }
            });

        $stateProvider.state('index.settings.domain-activesync',
            {
                url: "/domain-activesync",
                data: { pageTitle: 'EAS_MAILBOXES' },
                views: {
                    "settingsContentView": { templateUrl: "app/settings/domain-settings/activesync/activesync.html" }
                }
            });
        $stateProvider.state('index.settings.domain-users',
            {
                url: "/domain-accounts",
                data: { pageTitle: 'ACCOUNTS' },
                views: {
                    "settingsContentView": { templateUrl: "app/settings/domain-settings/users/accounts.html" }
                }
            });
        $stateProvider.state('index.settings.user-defaults',
            {
                url: "/defaults",
                data: { pageTitle: 'USER_DEFAULTS' },
                views: {
                    "settingsContentView": { templateUrl: "app/settings/domain-settings/user-defaults/defaults.html" }
                }
            });
        $stateProvider.state('index.settings.domain-users.specific',
            {
                url: "/user/{selectedUserId}?isNew",
                data: { pageTitle: 'USER' },
                views: {
                    "settingsContentView@index.settings": { templateUrl: "app/settings/domain-settings/users/user.html" }
                }
            });
        $stateProvider.state('index.settings.domain-users.specific-list',
            {
                url: "/list/{mailingListId}",
                data: { pageTitle: 'MAILINGLIST' },
                views: { "settingsContentView@index.settings": { templateUrl: "app/settings/shared/mailing-lists/list.html" } }
            });
        $stateProvider.state('index.settings.domain-user-aliases',
            {
                url: "/domain-user-aliases",
                data: { pageTitle: 'ALIASES' },
                views: {
                    "settingsContentView": { templateUrl: "app/settings/domain-settings/user-aliases/aliases.html" }
                }
            });

        $stateProvider.state('index.settings.domain-message-archive',
            {
                url: '/message-archive-search',
                data: { pageTitle: 'MESSAGE_ARCHIVE_SEARCH' },
                views: {
                    "settingsContentView": {
                        templateUrl: 'app/settings/shared/message-archiving/message-archive-section.html'
                    }
                }
            });
        $stateProvider.state('index.settings.domain-message-archive.search',
            {
                url: '/message-archive-search/{searchGuid}',
                data: { pageTitle: 'MESSAGE_ARCHIVE_SEARCH_RESULTS' },
                views: {
                    "settingsContentView@index.settings": {
                        templateUrl: 'app/settings/shared/message-archiving/message-archive-search-view.html'
                    }
                }
            });
        $stateProvider.state('index.settings.domain-password-requirements',
            {
                url: '/password-requirements',
                data: { pageTitle: 'SYSADMIN_SECURITY_PASSWORD_REQUIREMENTS' },
                views: {
                    "settingsContentView": {
                        templateUrl: 'app/settings/shared/password-requirements/password-requirements.html'
                    }
                }
            });

        $stateProvider
            .state('index.domain-marketplace', {
                url: "/domain-marketplace",
                data: {
                    pageTitle: 'MARKET_PLACE'
                },
                views: {
                    "mainContentView@index": {
                        templateUrl: 'app/marketplace/marketplace-main.html',
                        controller: 'domainSettingsMarketplaceController',
                        controllerAs: 'mainSetCtrl'
                    }
                },
                params: {
                    sort: null
                }
            })
            .state('index.domain-marketplace.overview', {
                url: "/overview",
                data: {
                    pageTitle: 'MARKET_PLACE'
                },
                views: {
                    "marketplaceContentView@index.domain-marketplace": {
                        templateUrl: 'app/marketplace/marketplace.html'
                    }
                },
                params: {
                    sort: null
                }
            });
        $stateProvider.state('index.settings.domain-emclient-licenses',
            {
                url: "/emclient-licenses",
                data: {
                    pageTitle: 'EMCLIENT',
                    auth: {
                        roles: ["DomainAdmin"],
                        claims: ["eMClientAdmin"]
                    }
                },
                views: {
                    "settingsContentView": { templateUrl: "app/settings/domain-settings/emclient/emclient-licenses.html" }
                }
            });
        
        //$stateProvider.state('index.settings.domain-mailing-lists',
        //	{
        //		url: "/domain-mailing-lists",
        //		data: { pageTitle: 'MAILING_LISTS' },
        //		views: {
        //			"settingsContentView": { templateUrl: "app/settings/shared/mailing-lists/mailing-lists.html" }
        //		}
        //	});
        //$stateProvider.state('index.settings.domain-mailing-lists.specific-list',
        //	{
        //		url: "/list/{mailingListId}",
        //		data: { pageTitle: 'MAILINGLIST' },
        //		views: {
        //			"settingsContentView@index.settings": { templateUrl: "app/settings/shared/mailing-lists/list.html" }
        //		}
        //	});
        //$stateProvider.state('index.settings.domain-mailing-lists.specific.subscribers',
        //	{
        //		url: "/subscribers",
        //		data: { pageTitle: 'SUBSCRIBERS' },
        //		views: {
        //			"settingsContentView@index.settings": { templateUrl: "app/settings/shared/mailing-lists/list-subscribers.html" }
        //		}
        //	});
        //$stateProvider.state('index.settings.domain-mailing-lists.specific.digest-subscribers',
        //	{
        //		url: "/digest-subscribers",
        //		data: { pageTitle: 'DIGEST_SUBSCRIBERS' },
        //		views: {
        //			"settingsContentView@index.settings": { templateUrl: "app/settings/shared/mailing-lists/list-subscribers-digest.html" }
        //		}
        //	});
        //$stateProvider.state('index.settings.domain-mailing-lists.specific.posters',
        //	{
        //		url: "/posters",
        //		data: { pageTitle: 'POSTERS' },
        //		views: {
        //			"settingsContentView@index.settings": { templateUrl: "app/settings/shared/mailing-lists/list-posters.html" }
        //		}
        //	});
        //$stateProvider.state('index.settings.domain-mailing-lists.specific.banned-users',
        //	{
        //		url: "/banned-users",
        //		data: { pageTitle: 'BANNED_POSTERS' },
        //		views: {
        //			"settingsContentView@index.settings": { templateUrl: "app/settings/shared/mailing-lists/list-banned-users.html" }
        //		}
        //	});
        //$stateProvider.state('index.settings.domain-mailing-lists.specific.system-messages',
        //	{
        //		url: "/system-messages",
        //		data: { pageTitle: 'MESSAGES' },
        //		views: {
        //			"settingsContentView@index.settings": { templateUrl: "app/settings/shared/mailing-lists/list-messages.html" }
        //		}
        //	});

        //$stateProvider.state('index.settings.domain-mailing-list-subscribers',
        //	{
        //		url: "/domain-mailing-list-subscribers",
        //		data: { pageTitle: 'SUBSCRIBERS' },
        //		views: {
        //			"settingsContentView": { templateUrl: "app/settings/shared/mailing-lists/subscribers.html" }
        //		}
        //	});

        $stateProvider.state('index.settings.domain-events',
            {
                url: "/domain-events",
                data: { pageTitle: 'EVENTS_DOMAIN_EVENTS' },
                views: { "settingsContentView": { templateUrl: "app/settings/domain-settings/events/events.html" } }
            });
        $stateProvider.state('index.settings.domain-events.specific',
            {
                url: "/{domainEventId}",
                data: { pageTitle: 'EVENTS_DOMAIN_EVENT' },
                views: { "settingsContentView@index.settings": { templateUrl: "app/settings/domain-settings/events/event.html" } }
            });

        $stateProvider.state('index.settings.domain-sharing',
            {
                url: "/domain-sharing",
                data: { pageTitle: 'SHARING' },
                views: {
                    "settingsContentView": { templateUrl: "app/settings/domain-settings/shared-resources/shared-resources.html" }
                }
            });

        $stateProvider.state('index.settings.domain-chat-search',
            {
                url: "/domain-chat-search",
                views: {
                    "settingsContentView": {
                        templateUrl: "app/settings/domain-settings/chat-search/chat-search.html",
                        data: { pageTitle: 'CHAT' }
                    }
                }
            });
    }

    function normalizeLocale(key) {
        return key ? key.toLowerCase().replace('_', '-') : key;
    }

    function configureUserSettingsStates($stateProvider) {
        const domainOrUserSettingsAuth = function (claimsService, params) {
            if (!claimsService) return false;
            const type = params["type"];
            const auth =
            {
                hasDomain: true,
                roles: type === "domain" ? ["DomainAdmin"] : [],
                claims: ["nameid"]
            }
            return claimsService.checkAuthFromState(auth);
        };
        $stateProvider.state('index.settings',
            {
                url: "/settings/{type}",
                data: {
                    pageTitle: 'SETTINGS',
                    auth: domainOrUserSettingsAuth
                },
                views: {
                    "mainContentView@index": { templateUrl: "app/settings/settings-main.html" }
                },
                params: { type: null }
            });
        $stateProvider.state('index.settings.user-account-profile',
            {
                url: "/account-profile",
                data: { pageTitle: 'ACCOUNT_PROFILE' },
                views: {
                    "settingsContentView@index.settings": {
                        templateUrl: "app/settings/user-settings/account-profile/account-profile.html"
                    }
                }
            });
        $stateProvider.state('index.settings.user-account-settings',
            {
                url: "/account-settings",
                data: { pageTitle: 'ACCOUNT' },
                views: {
                    "settingsContentView": {
                        templateUrl: "app/settings/user-settings/account-settings/account-settings.html"
                    }
                }
            });
        $stateProvider.state('index.settings.user-sharing',
            {
                url: "/sharing",
                data: { pageTitle: 'SHARING' },
                views: {
                    "settingsContentView@index.settings": {
                        templateUrl: "app/settings/user-settings/sharing/sharing.html"
                    }
                }
            });
        $stateProvider.state('index.settings.signatures',
            {
                url: "/signatures",
                data: { pageTitle: 'SIGNATURES' },
                views: {
                    "settingsContentView": {
                        templateUrl: "app/settings/user-settings/signatures/signatures.html"
                    }
                }
            });
        $stateProvider.state('index.settings.user-auto-responder',
            {
                url: "/auto-responder",
                data: { pageTitle: 'AUTO_RESPONDER' },
                views: {
                    "settingsContentView": {
                        templateUrl: "app/settings/user-settings/auto-responder/auto-responder.html"
                    }
                }
            });
        $stateProvider.state('index.settings.user-trusted-senders',
            {
                url: "/trusted-senders",
                data: { pageTitle: 'TRUSTED_SENDERS' },
                views: {
                    "settingsContentView": {
                        templateUrl: "app/settings/user-settings/trusted-senders/trusted-senders.html"
                    }
                }
            });
        $stateProvider.state('index.settings.user-events',
            {
                url: "/events",
                data: { pageTitle: 'EVENTS' },
                views: { "settingsContentView": { templateUrl: "app/settings/user-settings/events/events.html" } }
            });
        $stateProvider.state('index.settings.user-events.specific',
            {
                url: "/{id}",
                data: { pageTitle: 'EVENT' },
                views: { "settingsContentView@index.settings": { templateUrl: "app/settings/user-settings/events/event.html" } }
            });
        $stateProvider.state('index.settings.user-calendar-settings',
            {
                url: "/calendar-settings",
                data: { pageTitle: 'CALENDAR_SETTINGS' },
                views: {
                    "settingsContentView": {
                        templateUrl: "app/settings/user-settings/calendar-settings/calendar-settings.html"
                    }
                }
            });
        $stateProvider.state('index.settings.user-scheduling-settings',
            {
                url: "/scheduling-settings",
                data: { pageTitle: 'SCHEDULING_SETTINGS' },
                views: {
                    "settingsContentView": {
                        templateUrl: "app/settings/user-settings/scheduling-settings/scheduling-settings.html"
                    }
                }
            });

        $stateProvider.state('index.settings.user-content-filters',
            {
                url: "/content-filters",
                data: { pageTitle: 'CONTENT_FILTERING' },
                views: {
                    "settingsContentView": {
                        templateUrl: "app/settings/user-settings/content-filters/content-filters.html"
                    }
                }
            });
        $stateProvider.state('index.settings.user-content-filters.specific',
            {
                url: "/{id}",
                data: { pageTitle: 'USER_CONTENT_FILTER' },
                views: {
                    "settingsContentView@index.settings": {
                        templateUrl: "app/settings/user-settings/content-filters/content-filter.html"
                    }
                }
            });
        $stateProvider.state('index.settings.user-spam-filters',
            {
                url: "/spam-filters",
                data: { pageTitle: 'SPAM_FILTERING' },
                views: {
                    "settingsContentView": {
                        templateUrl: "app/settings/user-settings/spam-filters/spam-filters.html"
                    }
                }
            });
        $stateProvider.state('index.settings.user-external-accounts',
            {
                url: "/connectivity",
                data: { pageTitle: 'CONNECTIVITY' },
                views: {
                    "settingsContentView": {
                        templateUrl: "app/settings/user-settings/external-accounts/external-accounts.html"
                    }
                }
            });

        /// Mailing Lists
        $stateProvider.state('index.settings.mailing-lists',
            {
                url: "/mailing-lists",
                data: { pageTitle: 'MAILING_LISTS' },
                views: {
                    "settingsContentView": { templateUrl: "app/settings/shared/mailing-lists/mailing-lists.html" }
                }
            });
        $stateProvider.state('index.settings.mailing-lists.specific-list',
            {
                url: "/list/{mailingListId}",
                data: { pageTitle: 'MAILINGLIST' },
                views: {
                    "settingsContentView@index.settings": { templateUrl: "app/settings/shared/mailing-lists/list.html" }
                }
            });
        $stateProvider.state('index.settings.mailing-lists.specific.subscribers',
            {
                url: "/subscribers",
                data: { pageTitle: 'SUBSCRIBERS' },
                views: {
                    "settingsContentView@index.settings": { templateUrl: "app/settings/shared/mailing-lists/list-subscribers.html" }
                }
            });
        $stateProvider.state('index.settings.mailing-lists.specific.digest-subscribers',
            {
                url: "/digest-subscribers",
                data: { pageTitle: 'DIGEST_SUBSCRIBERS' },
                views: {
                    "settingsContentView@index.settings": { templateUrl: "app/settings/shared/mailing-lists/list-subscribers-digest.html" }
                }
            });
        $stateProvider.state('index.settings.mailing-lists.specific.posters',
            {
                url: "/posters",
                data: { pageTitle: 'POSTERS' },
                views: {
                    "settingsContentView@index.settings": { templateUrl: "app/settings/shared/mailing-lists/list-posters.html" }
                }
            });
        $stateProvider.state('index.settings.mailing-lists.specific.banned-users',
            {
                url: "/banned-users",
                data: { pageTitle: 'BANNED_POSTERS' },
                views: {
                    "settingsContentView@index.settings": { templateUrl: "app/settings/shared/mailing-lists/list-banned-users.html" }
                }
            });
        $stateProvider.state('index.settings.mailing-lists.specific.system-messages',
            {
                url: "/system-messages",
                data: { pageTitle: 'MESSAGES' },
                views: {
                    "settingsContentView@index.settings": { templateUrl: "app/settings/shared/mailing-lists/list-messages.html" }
                }
            });
    }

})();
