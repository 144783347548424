(function () {

    // To enable the debug digest hub, change this variable to true
    var enableDigestHudInDebugMode = false;
    try {
        enableDigestHudInDebugMode = localStorage.getItem("digesthud") == 'true';
    } catch (e) { }

    angular.module("smartertools", []);
    angular.module("smartermail",
        [
            "ngAnimate", // Angular animations
            "ngMaterial", // Angular Material
            "ngSanitize", // Angular Sanitizer
            "ngStorage",
            "ui.router", // Routing
            "color.picker", // AngularJS - ColorPicker
            "angular-timeline", // Angular timeline
            "LocalStorageModule", // Angular local storage, session storage, cookie storage
            "pascalprecht.translate", // Angular translate
            "angularBootstrapNavTree", // Angular bootstrap nav-tree
            "toaster", // Angular toaster
            "smartertools",
            "froala", // HTML Editor
            "ngMessages",
            "tmh.dynamicLocale",
            "mdPickers", // Angular material Datetime pickers
            "ngTable",
            'angular.filter',
            'digestHud'
        ])
        .config(['$qProvider', function ($qProvider) {
            $qProvider.errorOnUnhandledRejections(true);
        }])
        .config(configTranslation)
        .config(['$locationProvider', function ($locationProvider) {
            $locationProvider.hashPrefix("");
        }])
        .config(['$mdAriaProvider', function ($mdAriaProvider) {
            $mdAriaProvider.disableWarnings();
        }])
        .config(['$mdInkRippleProvider', function ($mdInkRippleProvider) {
            // Disables ink ripple effect on buttons
            $mdInkRippleProvider.disableInkRipple();
        }])
        .config(['$compileProvider', function ($compileProvider) {
            if (debugMode) {
                console.log("Running in DEBUG mode. Hello o/");
                console.log("To enable the Angular Digest HUD, add a localstorage variable named 'digesthud' and set it to 'true', then refresh this window.");
                $compileProvider.debugInfoEnabled(true);
            }
            else
                $compileProvider.debugInfoEnabled(false);
        }])
        .config(['digestHudProvider', function (digestHudProvider) {
            if (enableDigestHudInDebugMode && debugMode) {
                digestHudProvider.enable();
                digestHudProvider.setHudPosition('bottom right');
                digestHudProvider.numTopWatches = 30;
                digestHudProvider.numDigestStats = 50;
            }
        }])
        .run(function ($rootScope, $location, spinnerFactory, modalService, $transitions, claimsService) {
            if (!$rootScope.spinner)
                $rootScope.spinner = new spinnerFactory();
            if (!$rootScope.determinateSpinner) {
                $rootScope.determinateSpinner = new spinnerFactory();
                $rootScope.determinateSpinnerValue = 0;
            }
            window.smRsHook = $rootScope;
            // #region
            // This code is here to help close modal windows when a user
            // navigates away from a page with the browser back/forward
            // buttons or with alt + arrow keys.
            $rootScope.$on('$locationChangeSuccess', function () {
                $rootScope.actualLocation = $location.path();
            });

            $transitions.onBefore({
                to: function (state) {
                    const stateAuth = checkStateForAuth(state);
                    if (stateAuth) {
                        
                        const userAuthorized = typeof stateAuth === "function"
                            ? stateAuth(claimsService, state.params)
                            : claimsService.checkAuthFromState(stateAuth);

                        if (!userAuthorized) 
                            console.log(`[stateChange Redirect] Authorization required for route:${state.name} user:${claimsService.getEmailAddress()}`);
                        return !userAuthorized;
                    }
                    return false;
                }
            }, function (trans) {
                const targetRoute = claimsService.getDefaultRouteForUser();
                console.log("[stateChange Redirect] redirecting to " + targetRoute);
                return trans.router.stateService.target(targetRoute);

            });

            $rootScope.$watch(function () {
                return $location.path();
            }, function (newLocation, oldLocation) {
                if ($rootScope.actualLocation === newLocation) {
                    modalService.cancelModal();
                }
            });
            function checkStateForAuth(state) {
                if (state.data && state.data.auth) {
                    return state.data.auth;
                } 
                if (state.parent) {
                    return checkStateForAuth(state.parent);
                }
                return null;
            }

            var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            var htmlelem = undefined;
            var bodyelem = undefined;
            if (iOS) {
                var windowInnerHeight = window.innerHeight;
                setInterval(function () {
                    if (windowInnerHeight != window.innerHeight) {
                        resize();
                    }
                }, 200);

                $(window).focus(function () {
                    if (windowInnerHeight != window.innerHeight) {
                        resize();
                    }
                });

                $(window).blur(function () {
                    if (windowInnerHeight != window.innerHeight) {
                        resize();
                    }
                });

                function resize() {
                    $("html").height("100vh");
                    $("html").height(window.innerHeight);
                    windowInnerHeight = window.innerHeight;
                }
            }

            // #endregion

            $('html').on('touchstart', onTouchAvailable);
            $('html').on('pointermove', onMouseAvailable);

            if (sessionStorage.mouseAvailable === "false")
                onTouchAvailable();
            else if (sessionStorage.mouseAvailable === "true")
                onMouseAvailable();

            function onTouchAvailable(e) {
                sessionStorage.mouseAvailable = "false";
                $('html').addClass('touch-only');
                $('html').off('touchstart', onTouchAvailable);
            }
            function onMouseAvailable(e) {
                sessionStorage.mouseAvailable = "true";
                $('html').removeClass('touch-only');
                $('html').off('pointermove', onMouseAvailable);
                $('html').off('touchstart', onTouchAvailable);
            }

            try {
                clearListCookies();
            } catch (e) {

            }

            function clearListCookies() {
                var cookies = document.cookie.split(";");
                for (var i = 0; i < cookies.length; i++) {
                    var spcook = cookies[i].split("=");
                    deleteCookie(spcook[0]);
                }
                function deleteCookie(cookiename) {
                    var d = new Date();
                    d.setDate(d.getDate() - 1);
                    var expires = ";expires=" + d;
                    var name = cookiename;
                    var value = "";
                    document.cookie = name + "=" + value + expires + "; path=/";
                }
            }

        })
        .run(function (customNgMessages) {
            customNgMessages.ensureTemplateCreated();
        })

    function configTranslation($translateProvider, $mdInkRippleProvider, tmhDynamicLocaleProvider) {
        //$mdInkRippleProvider.disableInkRipple();	
        $translateProvider.useStaticFilesLoader({
            prefix: stSiteRoot + "translations/",
            suffix: ".json?" + languageCacheBustQs
        });
        //$translateProvider.preferredLanguage("test");
        $translateProvider.registerAvailableLanguageKeys(window.angularLangList, window.angularLangMap);
        if (localStorage.noLanguageFallback !== "true")
            $translateProvider.fallbackLanguage("en");
        $translateProvider.determinePreferredLanguage();
        $translateProvider.useSanitizeValueStrategy("escape");
        tmhDynamicLocaleProvider.localeLocationPattern(stSiteRoot + 'interface/output/angular-i18n/angular-locale_{{locale}}.js');
    };

})();
