(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("apiSignatures", apiSignatures);

	/* @ngInject */
	function apiSignatures($rootScope, $http, $q, $sessionStorage) {
		var vm = this;

		// Functions
		vm.getSignatures = getSignatures;
		vm.invalidateSignatures = invalidateSignatures;

		activate();

		///////////////////////////

		function activate() {
			// To load on service instantiation, call this
			// refreshFromHttp();

			// If item could contain translatable elements, add this
			// $rootScope.$on('$translateChangeSuccess', retranslate);
		}

		function refreshFromHttp() {
			var defer = $q.defer();
			$http
				.get("~/api/v1/settings/user-signatures")
				.then(onRefreshed, defer.reject);

			function onRefreshed(results) {
				var signatures = results.data.signatureConfigs || [];
				var useDomainSignature = results.data.useDomainSignature;
				var expiresAt = new Date();
				expiresAt.setHours(expiresAt.getHours() + 1);
				$sessionStorage.userSignaturesCache = { expires: expiresAt, signatures: signatures, useDomainSignature: useDomainSignature };
				defer.resolve(signatures);
			}

			return defer.promise;
		}

		function getSignatures(forceReload) {
			var defer = $q.defer();
			if (forceReload)
				invalidateSignatures();

			var element = $sessionStorage.userSignaturesCache;
			if (!element || element.expires < new Date())
				refreshFromHttp().then(function (signatures) { defer.resolve(signatures); }, defer.reject);
			else
				defer.resolve(element.signatures);

			return defer.promise;
		}

		function invalidateSignatures() {
			delete $sessionStorage.userSignaturesCache;
		}
	}
})();
