'use strict';

angular.module('smartertools')
	.directive('stPager', function ($filter) {
	    return {
	        restrict: 'E',
	        replace: true,
	        scope: {
	            itemsPerPage: '=',
	            totalItems: '=',
	            currentPage: '='
	        },
	        link: function (scope, elements, attrs) {
	            scope.userInput = scope.currentPage;
	            scope.changePage = function () {
	                if (scope.totalPages == 0) {
	                    scope.userInput = 0;
	                    return;
	                }
	                if (scope.userInput > scope.totalPages)
	                    scope.userInput = scope.totalPages;
	                else if (scope.userInput < 1)
	                    scope.userInput = 1;
	                scope.currentPage = scope.userInput;
	            }

	            scope.totalPages = 1;
	            scope.setTotalPages = function () {
	                scope.totalPages = Math.ceil(scope.totalItems / scope.itemsPerPage);
	                if (scope.totalPages == 0)
	                    scope.userInput = 0;
	                else if (scope.userInput == 0)
	                    scope.userInput = 1;
	            }

	            scope.firstPage = function () {
	                scope.currentPage = 1;
	                scope.userInput = scope.currentPage;
	            };

	            scope.movePage = function (numToMove) {
	                scope.userInput = parseInt(scope.userInput, 10);
	                var newPage = scope.userInput + parseInt(numToMove, 10);
	                if (newPage < 0) {
	                    scope.userInput = 1;
	                    scope.currentPage = 1;
	                }
	                else if (newPage > scope.totalPages) {
	                    scope.userInput = scope.totalPages;
	                    scope.currentPage = scope.totalPages;
	                }
	                else {
	                    scope.userInput = newPage;
	                    scope.currentPage = newPage;
	                }
	            }

	            scope.lastPage = function () {
	                scope.currentPage = scope.totalPages; //$filter("number")((scope.totalItems / scope.itemsPerPage), 0);
	                //scope.userInput = scope.currentPage;
	            }

	            scope.keyup = function ($event) {
	                var charCode = ($event.which) ? $event.which : $event.keyCode;
	                if (charCode == 27) {
	                    scope.userInput = scope.currentPage;
	                    $($event.target).blur();
	                    $event.preventDefault();
	                    return false;
	                }
	            }

	            scope.keyPressed = function ($event) {
	                var charCode = ($event.which) ? $event.which : $event.keyCode;
	                if (charCode == 13 || charCode == 27 || charCode == 3) {
	                    $($event.target).blur();
	                    //$('#inputPager-' + scope.$id).blur();
	                    return true;
	                }
	                else if (charCode > 31
						&& (charCode < 48 || charCode > 57)) {
	                    $event.preventDefault();
	                    return false;
	                }

	                return true;
	            }
	            scope.noPaste = function ($event) {
	                $event.preventDefault();
	            }
	            scope.selectText = function ($event) {
	                $($event.target).select();
	            }
	            scope.resize = function () {
	                var goal = 10;
	                var width = 20;
	                if ($.isNumeric(scope.userInput))
	                    while (goal <= scope.userInput) {
	                        width += 10;
	                        goal *= 10;
	                    }
	                $('#inputPager-' + scope.$id).css('width', width + 'px');
	            }
	            scope.$watch(function (scope) { return scope.userInput }, scope.resize);
	            scope.$watch(function (scope) { return scope.currentPage }, function (oldValue, newValue) { scope.userInput = scope.currentPage; });
	            scope.$watch(function (scope) { return scope.totalItems }, function (oldValue, newValue) { scope.setTotalPages() });
	        },
	        template:
					'<div class="st-pager">' +
						'<div class="st-pager-buttons">' +
							'<button id="btnFirstPage-{{::$id}}" ng-click="firstPage()" type="button" ng-disabled="currentPage == 1 || totalPages == 0">' +
								'<i class="toolsicon toolsicon-first_page"></i>' +
							'</button>' +
							'<button id="btnPageBack-{{::$id}}" ng-click="movePage(-1)" type="button" ng-disabled="currentPage == 1 || totalPages == 0">' +
								'<i class="toolsicon toolsicon-keyboard_arrow_left"></i>' +
							'</button>' +
                            '<div class="st-pager-info">' +
							    '<input id="inputPager-{{::$id}}" ng-click="selectText($event)" ng-keyup="keyup($event)" ng-keypress="keyPressed($event)" ng-paste="noPaste($event)" ng-model="userInput" ng-blur="changePage()"/>' +
							    '<span>{{::"OF" | translate}}</span>' +
							    '<span>{{totalPages}}</span>' +
						    '</div>' +
							'<button id="btnPageForward-{{::$id}}" ng-click="movePage(1)" type="button" ng-disabled="currentPage == totalPages || totalPages == 0">' +
								'<i class="toolsicon toolsicon-keyboard_arrow_right"></i>' +
							'</button>' +
							'<button id="btnLastPage-{{::$id}}" ng-click="lastPage()" type="button" ng-disabled="currentPage == totalPages || totalPages == 0">' +
								'<i class="toolsicon toolsicon-last_page"></i>' +
							'</button>' +
						'</div>' +
					'</div>'
	    }
	});