(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("rocketChatPersonalAccessTokenDialog", rocketChatPersonalAccessTokenDialogController);

	function rocketChatPersonalAccessTokenDialogController($filter, $http, $mdDialog, title, serverUrl, username, personalAccessToken) {
		var vm = this;
		vm.title = title;
		vm.serverUrl = serverUrl;
		vm.personalAccessToken = personalAccessToken;
		vm.chatUsername = username;
		vm.isNew = !personalAccessToken;
		vm.totpRequiredAuth = false;
		vm.totpRequiredToken = false;
		vm.rocketChatAuthHeaders = null;
		vm.generateAccessToken = async function(form) {
			if (form.$invalid)
				return;
			const authenticate = async function() {
				const result = { success: false, data: null };
				try {
					const params = { username: vm.chatUsername, password: vm.chatPassword };
					if (vm.totpRequired) {
						params.code = vm.totpCode;
					}
					const success = await $http.post(`${vm.serverUrl}/api/v1/login`, JSON.stringify(params));
					result.success = true;
					result.data = success.data;
				} catch (err) {
					switch (err.code) {
					case 401:
						vm.totpRequiredAuth = err.error === "totp-required";
						if (vm.totpRequiredAuth) {
							vm.totpCodeDetails = err.details;
						} else {
							vm.serverError = $filter("translate")("USERNAME_OR_PASSWORD_INCORRECT");
						}
						break;
					default:
						vm.serverError = $filter("translate")("SERVER_NOT_FOUND");
					}
				}
				return result;
			};
			const createPersonalAccessToken = async function() {
				const result = { success: false, data: null };
				try {
					const params = { tokenName: vm.chatUsername, bypassTwoFactor: true };
					if (vm.totpRequiredToken) {
						params.code = vm.totpCode;
					}
					const success = await $http.post(`${vm.serverUrl}/api/v1/users.generatePersonalAccessToken`, JSON.stringify(params), vm.rocketChatAuthHeaders);
					result.success = true;
					result.data = success.data;
				} catch (err) {
					switch (err.code) {
					case 401:
						vm.totpRequiredToken = err.error === "totp-required";
						if (vm.totpRequiredToken) {
							vm.totpCodeDetails = err.details;
						} else {
							vm.totpCode = null;
						}
					default:
						if (err.errorType === "error-token-already-exists") {
							form.tokenName.$setValidity("token-already-exists", false);
						} else {

							vm.rocketChatAuthHeaders = null;
						}
						throw err;
					}
				}
				return result;
			};
			if (!vm.rocketChatAuthHeaders) {

				const authResults = await authenticate();
				if (!authResults.success) {
					return;
				}
				vm.totpCode = null;
				vm.rocketChatAuthHeaders = {
					"headers": {
						"X-Auth-Token": authResults.data.authToken,
						"X-User-Id": authResults.data.userId
					}
				}; 
			}
			const tokenResults = await createPersonalAccessToken();
			if (!tokenResults) {
				return;
			}
			vm.personalAccessToken = tokenResults.token;

		}
		vm.save = function (form) {
			if (form.$invalid || !vm.personalAccessToken) {
				return;
			}

			$mdDialog.hide({
				personalAccessToken: vm.personalAccessToken
			});
		};

		vm.cancel = function () {
			$mdDialog.cancel();
		};
	}
})();