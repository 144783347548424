(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("coreDataManageSettings", coreDataManageSettings);

	function coreDataManageSettings($http, $q, $filter, $rootScope, coreDataSysAdminCards, coreDataSysAdminSettings) {
	    var vm = this;
	    var _isInitialized = false;
		var _services = [];
		var servicesIntialized = false;
		vm.serviceInfo = {
			imap: { name: 'IMAP', description: 'LOGS_AND_SETTINGS_SERVICES_DESCRIPTIONS_IMAP' },
		    imapretrieval: { name: 'IMAP_RETRIEVAL', description: 'LOGS_AND_SETTINGS_SERVICES_DESCRIPTIONS_IMAP_RETRIEVAL' },
		    indexing: { name: 'INDEXING', description: 'LOGS_AND_SETTINGS_SERVICES_DESCRIPTIONS_INDEXING' },
		    ldap: { name: 'LDAP', description: 'LOGS_AND_SETTINGS_SERVICES_DESCRIPTIONS_LDAP' },
		    pop: { name: 'POP', description: 'LOGS_AND_SETTINGS_SERVICES_DESCRIPTIONS_POP' },
		    popretrieval: { name: 'POP_RETRIEVAL', description: 'LOGS_AND_SETTINGS_SERVICES_DESCRIPTIONS_POP_RETRIEVAL' },
		    smtp: { name: 'SMTP', description: 'LOGS_AND_SETTINGS_SERVICES_DESCRIPTIONS_SMTP' },
		    spool: { name: 'SPOOL', description: 'LOGS_AND_SETTINGS_SERVICES_DESCRIPTIONS_SPOOL' },
		    xmpp: { name: 'XMPP', description: 'LOGS_AND_SETTINGS_SERVICES_DESCRIPTIONS_XMPP' }
		};
		vm.passwordRequirment = {
		    common: 0x1,
		    length: 0x2,
		    capital: 0x4,
		    lowerCase: 0x8,
		    symbol: 0x10,
		    number: 0x20,
		    notUsername: 0x40,
		    blank: 0x80,
		    used: 0x0100
		};
		vm.Data = {
		    get services() { return getServices(); },
		    set services(value) { _services = value || []; },
		};
		vm.startService = undefined;
		vm.stopService = undefined;
		vm.searchLogs = undefined;
		vm.downloadLogs = undefined;
		vm.sendEmail = undefined;
		vm.exportCSV = undefined;
		vm.restore = undefined;
		vm.searchField = '';
		vm.sortField = '';
		vm.isDescending = false;

	    // Functions
		vm.init = init;
		vm.reset = reset;
		vm.Refresh = Refresh;
		vm.onSearchChange = onSearchChange;
		vm.onSortingChanged = onSortingChanged;

		activate();

	    /////////////////////

		function activate() {
		}

		function init() {
			var defer = $q.defer();
			if (_isInitialized)
				defer.resolve();
			else {
				var promises = [];
				$q.all(promises)
				.then(function () {
					_isInitialized = true;
					defer.resolve();
				}, function (failure) {
					defer.reject(failure);
				});
			}
			return defer.promise;
		};

		function reset() {
			_isInitialized = false;
			_services = [];
			servicesIntialized = false;
			vm.startService = undefined;
			vm.endService = undefined;
			_passwordPolicies = [];
			passwordPoliciesIntialized = false;
			vm.sendEmail = undefined;
			vm.exportCSV = undefined;
			vm.restore = undefined;
		}

		function loadServices() {
		    var _this = vm;
			return $http.get('~/api/v1/settings/sysadmin/services')
			.then(function (success) {
				_services = [];
				var keys = Object.keys(success.data.services);
				angular.forEach(keys, function (key) {
					_services.push({ key: key, status: success.data.services[key], nameTranslate: $filter('translate')(_this.serviceInfo[key].name) });
				});
				servicesIntialized = true;
			}, function (failure) {
				servicesIntialized = false;
			});
		}

		function getServices() {
			var defer = $q.defer();
			if (servicesIntialized) {
				return $q.when(_services);
			} else {
				loadServices()
				.then(function (success) {
					defer.resolve(_services);
				}, function (failure) {
					defer.reject('LOGS_AND_SETTINGS_ERRORS_FAILED_TO_LOAD_SERVICES');
				});
			}
			return defer.promise;
		};

		function getPasswordFlagCount(flag) {
			var count = 0;
			if ((flag & vm.passwordRequirment.blank) != 0)
				count++;
			if ((flag & vm.passwordRequirment.length) != 0)
				count++;
			if ((flag & vm.passwordRequirment.notUsername) != 0)
				count++;
			if ((flag & vm.passwordRequirment.capital) != 0)
				count++;
			if ((flag & vm.passwordRequirment.lowerCase) != 0)
				count++;
			if ((flag & vm.passwordRequirment.symbol) != 0)
				count++;
			if ((flag & vm.passwordRequirment.number) != 0)
				count++;
			if ((flag & vm.passwordRequirment.common) != 0)
				count++;
			if ((flag & vm.passwordRequirment.used) != 0)
				count++;
			return count;
		}

		function Refresh() {
		    var _this = vm;
			
		}

		function onSearchChange(search) {
		    vm.searchField = search;
		    vm.selectedCards.length = 0;
		    if (vm.listController.reset && vm.listController.updateDisplayList) {
				connections.length = 0;
				vm.listController.reset();
				vm.listController.updateDisplayList();
			}
		}

		function onSortingChanged(sortBy) {
		    if (vm.listController.updateDisplayList) {
				connections.length = 0;
		        //vm.listController.reset();
				vm.listController.updateDisplayList();
			}
		}
	}
})();