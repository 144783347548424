(function () {
	"use strict";

	angular
		.module("smartertools")
		.directive("validateEmailList", validateEmailList);
	
	function validateEmailList($q, emailValidationService) {
		return {
			require: "ngModel",
			link: function (scope, element, attrs, ctrl) {
				ctrl.$asyncValidators.validateEmailList = async function (modelValue, viewValue) {
					if (!viewValue) return $q.resolve({ succss: true });
					
					var items = viewValue.replace("\r", "").split("\n");
					for (let i = 0; i < items.length; i++) {
						var isValid = await emailValidationService.isValidEmail(items[i]);
						if (!isValid)
							return $q.reject({ success: false });
					}

					return $q.resolve({ success: true });
				};
			}
		};
	}
})();