(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminDomainDefaultsController", sysAdminDomainDefaultsController);

	function sysAdminDomainDefaultsController($state, $rootScope, $scope, $http, $mdDialog, $q, $translate,
		coreDataLicensing, claimsService, errorHandling) {

		var vm = this;
		vm.settings = undefined;
		vm.defaults = undefined;
		vm.Licensing = undefined;
		vm.isInitialized = false;
	    vm.diskSpaceInput = 0;
		vm.maxMessageSizeInput = 0;
		vm.maxAttachedFileSizeInput = 0;
		vm.mailingListMaxMessageSizeInput = 0;
        vm.enableGreylisting = true;
		vm.ldapDisallowOptOut = false;
		vm.catchAllEnabled = false;
		vm.twoFactorSettingOptions = [
			{ value: 1, translation: "ENABLED" },
			{ value: 2, translation: "FORCED" },
			{ value: 6, translation: "FORCE_LOCKED" }
		];
		vm.canChangeShowPassword = claimsService.canViewPasswords();

		// Functions
		vm.save = save;
		vm.cancel = cancel;
		vm.onClickAllowUserProfileEdits = onClickAllowUserProfileEdits;

		// Modals
		vm.openAdvancedXMLBody = openAdvancedXMLBody;
		vm.openAutoDiscoverModal = openAutoDiscoverModal;
		vm.openPropagateModal = propagate;
		vm.webRtcNewServer = webRtcNewServer;
		vm.webRtcEditServer = webRtcEditServer;

		$scope.$on("licensingChanged", onLicensingChanged);
		activate();

		////////////////////////////////////

		function activate() {

			$rootScope.spinner.show();
			$q.all([
				$http.get("~/api/v1/settings/sysadmin/global-mail").then(onGlobalMailSettingsLoaded, errorHandling.report),
				$http.get("~/api/v1/settings/sysadmin/gateways").then(onGatewaysLoaded),
			])
				.then(onSuccess, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onSuccess() {
				vm.isInitialized = true;
			}

			function onGlobalMailSettingsLoaded(success) {
				vm.settings = angular.copy(success.data.globalMailSettings);
				vm.defaults = vm.settings.domainDefaultSettings;
				vm.defaults.webRtcServers = vm.defaults.webRtcServers || [];
                vm.diskSpaceInput = parseInt(vm.defaults.maxDomainSize / (1024 * 1024), 10);
				vm.maxMessageSizeInput = parseInt(vm.defaults.maxMessageSize / 1024, 10);
				vm.hardcodedMessageSize = parseInt(vm.settings.deliverySettings.maxMessageSizeInBytesCap / 1024, 10);
				vm.hardcodedAttachmentSize = parseInt(vm.settings.deliverySettings.hardcodedAttachmentSize /1024, 10);
				vm.mailingListMaxMessageSizeInput = parseInt(vm.defaults.mailingListMaxMessageSize / 1024, 10);
				vm.maxAttachedFileSizeInput = parseInt(vm.defaults.maxAttachedFileSize / 1024, 10);
				vm.enableGreylisting = !vm.defaults.bypassGreyListing;
                vm.catchAllEnabled = vm.defaults.defaultCaRestrictionLevel !== 2;
				vm.ldapDisallowOptOut = !vm.settings.ldapSettings.ldap_DisallowOptOut;

				coreDataLicensing
					.init()
					.then(onLicensingLoaded, errorHandling.report);

				return $http
					.get("~/api/v1/settings/sysadmin/ips")
					.then(onIpsLoaded);
			}

			function onGatewaysLoaded(result) {
				vm.outgoingGateways = result.data.gatewayConfigs;
			}
			
			function onIpsLoaded(result) {
				vm.IPV4Options = [{ value: "", translation: "AUTOMATIC" }];
				vm.IPV6Options = [{ value: "", translation: "AUTOMATIC" }];

				var failoverIp = vm.settings.failoverIPAddress;
				if (failoverIp && failoverIp !== "NONE") {
					vm.isIpFailover = true;
					vm.disableIpv6 = true;
					vm.IPV4Options.push({ value: failoverIp, translation: failoverIp });
				}
				if (vm.settings.deliverySettings.defaultOutboundDeliveryBindingIPv6.toLowerCase() === "disable") {
					vm.disableIpv6 = true;
				}

				var ipv4 = $.grep(result.data.ips, function (ip) { return ip.addressFamily === 2 && ip.address !== "127.0.0.1" });
				ipv4 = $.map(ipv4, function (ip) { return { value: ip.address, translation: ip.address } });
				vm.IPV4Options = vm.IPV4Options.concat(ipv4);

				var ipv6 = $.grep(result.data.ips, function (ip) { return ip.addressFamily === 23 && ip.address !== "::1" });
				ipv6 = $.map(ipv6, function (ip) { return { value: ip.address, translation: ip.address } });
				vm.IPV6Options = vm.IPV6Options.concat(ipv6);

				if (vm.defaults.outgoingIP == null || !_.some(vm.IPV4Options, function (x) { return (x.value || "") === vm.defaults.outgoingIP; }))
					vm.defaults.outgoingIP = "";
				if (vm.defaults.outgoingIPv6 == null || !_.some(vm.IPV6Options, function (x) { return (x.value || "") === vm.defaults.outgoingIPv6; }))
					vm.defaults.outgoingIPv6 = "";
			}

			function onLicensingLoaded() {
				vm.Licensing = angular.copy(coreDataLicensing.Data.Licensing);
				if (!vm.Licensing.enterpriseFunctionality) {
					vm.defaults.enableRemoteWipe = false;
					vm.defaults.enableActiveSyncAccountManagement = false;
					vm.defaults.enableXmpp = false;
					vm.defaults.webConferencing = false;
					vm.ldapDisallowOptOut = false;
					vm.defaults.emClientEnabled = false;
				} else if (!vm.Licensing.isActivated) {
					vm.defaults.emClientEnabled = false;
				}
			}
		}

		function onLicensingChanged() {
			coreDataLicensing
				.init()
				.then(activate, errorHandling.report);
		}
		function onClickAllowUserProfileEdits(ev, form) {
			if (!vm.defaults.allowUserProfileEdits) {
				vm.ldapDisallowOptOut = false;
			}
		}
		function save(ev, form) {
			if (form.$invalid || form.$pending)
				return;
			
            vm.defaults.maxDomainSize = vm.diskSpaceInput * 1024 * 1024;
			vm.defaults.maxMessageSize = vm.maxMessageSizeInput * 1024;
			vm.defaults.mailingListMaxMessageSize = vm.mailingListMaxMessageSizeInput * 1024;
			vm.defaults.maxAttachedFileSize = vm.maxAttachedFileSizeInput * 1024;
			vm.defaults.bypassGreyListing = !vm.enableGreylisting;
			vm.defaults.defaultCaRestrictionLevel = vm.catchAllEnabled ? 1 : 2;
			vm.defaults.webRtcServers = vm.defaults.webConferencing ? vm.defaults.webRtcServers : [];
			vm.settings.ldapSettings.ldap_DisallowOptOut = !vm.ldapDisallowOptOut;

			var params = {
				globalMailSettings: {
					domainDefaultSettings: vm.defaults,
					ldapSettings: { ldap_DisallowOptOut: vm.settings.ldapSettings.ldap_DisallowOptOut },
					pathSettings: { rootMailPath: vm.settings.pathSettings.rootMailPath }
				}
			};

			if (!vm.canChangeShowPassword)
				params.globalMailSettings.domainDefaultSettings.enableShowPasswords = null;

			$rootScope.spinner.show();
			$http
				.post("~/api/v1/settings/sysadmin/global-mail", JSON.stringify(params))
				.then(onSaveSuccess, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onSaveSuccess() {
				form.$setPristine();
				form.$setUntouched();
			}
		}

		function cancel(event, form) {
			activate();
            form.$setPristine();
        }

        // Modal Functions
		function propagate(ev) {
			$mdDialog.show({
				locals: { },
				controller: "domainPropagateDomainController",
				controllerAs: "ctrl",
				templateUrl: "app/sysadmin/manage/domains/modals/domain-propagation.dlg.html",
				targetEvent: ev
			});
		}

		function openAdvancedXMLBody(ev, isMobile) {
			$mdDialog.show({
				locals: {
					domain: null,
					isMobile: isMobile,
					text: isMobile ? vm.defaults.autoDiscoverSettings.mobileXML : vm.defaults.autoDiscoverSettings.desktopXML,
					settings: vm.defaults.autoDiscoverSettings.services
				},
				controller: 'editAutodiscoverXml',
				controllerAs: 'ctrl',
				templateUrl: 'app/sysadmin/manage/domains/modals/edit-autodiscover-xml.dlg.html',
				targetEvent: ev,
				clickOutsideToClose: false
			})
				.then(function (modalSuccess) {
					vm.pageForm.$setDirty();
					if (isMobile)
						vm.defaults.autoDiscoverSettings.mobileXML = modalSuccess;
					else
						vm.defaults.autoDiscoverSettings.desktopXML = modalSuccess;
				}, function (modalCancel) {
					// Cancel
				});
		}

		function openAutoDiscoverModal(ev, type) {
			$mdDialog.show({
				locals: {
					type: type,
					domain: null,
					hostname: vm.defaults.hostname,
					settings: vm.defaults.autoDiscoverSettings.services[type]
				},
				controller: 'editAutoDiscoverSettingsController',
				controllerAs: 'ctrl',
				templateUrl: 'app/sysadmin/manage/domains/modals/edit-autodiscover-settings.dlg.html',
				targetEvent: ev,
				clickOutsideToClose: false
			})
				.then(function (modalSuccess) {
					vm.pageForm.$setDirty();
					vm.defaults.autoDiscoverSettings.services[type] = modalSuccess;
				}, function (modalCancel) {
					// Cancel
				});
		}

		function webRtcNewServer(ev, form) {
			$mdDialog
				.show({
					locals: {
						server: null,
						newItem: true
					},
					controller: "editStunServerController",
					controllerAs: "ctrl",
					templateUrl: "app/shared/modals/stun-server.dlg.html",
					targetEvent: ev
				})
				.then(onNewRuleOk, function () { });

			function onNewRuleOk(modalSuccess) {
				modalSuccess.server.uris = modalSuccess.server.urlstring && modalSuccess.server.urlstring.split(/[\r\n]+/);
				modalSuccess.server.uris.sort();
				modalSuccess.server.urlstring = null;
				vm.defaults.webRtcServers.push(modalSuccess.server);
				vm.defaults.webRtcServers.sort(function(a, b) { return (a.uris[0] || "").localeCompare(b.uris[0] || ""); });
				form.$setDirty();
			}
		}

		function webRtcEditServer(ev, item, form) {
			$mdDialog
				.show({
					locals: {
						server: {
							serverType: item.serverType,
							urlstring: item.uris.join('\r\n'),
							username: item.username,
							credential: item.credential
						},
						newItem: false
					},
					controller: "editStunServerController",
					controllerAs: "ctrl",
					templateUrl: "app/shared/modals/stun-server.dlg.html",
					targetEvent: ev
				})
				.then(onEditRuleOk, function () { });

			function onEditRuleOk(modalSuccess) {
				var index;
				if (modalSuccess.deleteItem) {
					index = vm.defaults.webRtcServers.indexOf(item);
					if (index > -1) {
						vm.defaults.webRtcServers.splice(index, 1);
					}
					form.$setDirty();
					return;
				}

				modalSuccess.server.uris = modalSuccess.server.urlstring && modalSuccess.server.urlstring.split(/[\r\n]+/);
				modalSuccess.server.uris.sort();
				modalSuccess.server.urlstring = null;

				index = vm.defaults.webRtcServers.indexOf(item);
				if (index > -1) {
					vm.defaults.webRtcServers.splice(index, 1, modalSuccess.server);
				}
				vm.defaults.webRtcServers.sort(function(a, b) { return (a.uris[0] || "").localeCompare(b.uris[0] || ""); });
				form.$setDirty();
			}
		}
	}

})();
