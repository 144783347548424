(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("domainEditDomainAliasController", domainEditDomainAliasController);

	function domainEditDomainAliasController($scope, $mdDialog, $q, $filter,  claimsService, name, idn, domain, domainIdn, domainAliasesData, errorHandling) {
		$scope.isSysAdmin = claimsService.isSysAdmin();
		$scope.isNewItem = !name;
		$scope.name = idn || name;
		$scope.originalName = name;
		$scope.domain = domain;
		$scope.domainIdn = domainIdn;
		$scope.verifyMX = true;
		$scope.isValid = true;

		// Functions
		$scope.doDelete = doDelete;
		$scope.save = save;
		$scope.cancel = function () { $mdDialog.cancel(); };

		function save(form) {
			if (!($scope.isValid = form.$valid))
				return;

			if ($scope.isNewItem)
				domainAliasesData.addAlias($scope.name, $scope.verifyMX).then($mdDialog.hide, errorHandling.report);
			else
				domainAliasesData.updateAlias($scope.originalName, $scope.name, $scope.verifyMX).then($mdDialog.hide, errorHandling.report);
		}

		function doDelete() {
			$mdDialog.hide({ deleteAlias: $scope.name });

		}
	}

})();
