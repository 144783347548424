(function () {
    "use strict";

    /*

    EXAMPLE USAGE:
  
    Setup viewOptions in code behind:
    // If not set, viewOptions will default to Card View and List View

        vm.viewOptions = [
            { id: 'dayGridMonth', name: 'MONTH' },
            { id: 'timeGridWeek', name: 'WEEK' },
            { id: 'timeGridDay', name: 'DAY' },
            { id: 'listMonth', name: 'CALENDAR_AGENDA' },
            { id: 'resourceTimeGridDay', name: 'SCHEDULE' },
            { id: 'resourceTimelineDay', name: 'TIMELINE' },
            { id: 'allview', name: 'GRID_VIEW' }
        ];

    Add control to HTML     

        <st-view-chooser-menu 
            current-view="ctrl.currentView"
            view-options="ctrl.viewOptions" 
            on-change="ctrl.setCurrentView(ctrl.currentView)">
        </st-view-chooser-menu >

    */

    angular
        .module('smartertools')
        .component('stViewChooserMenu', {
            template: template(),
            controller: stViewChooserMenu,
            transclude: true,
            bindings: {
                currentView: '=',
                viewOptions: '<',
                onChange: '&'
            }
        });

    function template() {
        return `
            <md-menu md-position-mode="target-left bottom" md-offset="-2 0">
                <md-button ng-click="$mdMenu.open($event)" class="md-secondary standard-icon with-text" aria-label="menu">
                    <i ng-class="$ctrl.currentViewIconClass"></i>
                    <span>{{ $ctrl.currentViewText }}</span>
                    <i class="toolsicon toolsicon-arrow_drop_down_emphasis"></i>
                </md-button>
                <md-menu-content class="standard icons">
                    <md-menu-item ng-repeat="r in $ctrl.viewOptions">
                        <md-button ng-click="$ctrl.fireViewChanged(r.id, $event)">
                            <i class="toolsicon toolsicon-check" ng-style="{'visibility': $ctrl.currentView === r.id ? '' : 'hidden'}"></i> 
                            {{ ::r.name | translate }}
                        </md-button>
                    </md-menu-item>
                </md-menu-content>
            </md-menu>
            `;
    }

    /* @ngInject */
    function stViewChooserMenu($scope, $timeout, $translate) {
        var vm = this;
        vm.fireViewChanged = fireViewChanged;
        vm.currentIcon = 'toolsicon-grid_view';
        vm.currentViewLast = null;
        vm.currentViewText = "";
        vm.currentViewIconClass = "";

        vm.$doCheck = function () {
            if (vm.currentViewLast !== vm.currentView) {
                vm.currentViewLast = vm.currentView;
                calculateView();
            }
        }

        vm.$onChanges = function () {
            vm.viewOptions = vm.viewOptions || [
                { id: 'CARD_VIEW', name: 'CARD_VIEW', icon: 'toolsicon-grid_view' },
                { id: 'GRID_VIEW', name: 'GRID_VIEW', icon: 'toolsicon-list_alt' },
            ];
            for (let i = 0; i < vm.viewOptions.length; i++)
                vm.viewOptions[i].icon = vm.viewOptions[i].icon || 'toolsicon-grid_view';
        }

        function calculateView() {
            vm.currentViewText = "";
            vm.currentViewIconClass = "";
            for (let i = 0; i < vm.viewOptions.length; i++) {
                let view = vm.viewOptions[i];
                if (vm.currentView != view.id) continue;
                vm.currentViewText = $translate.instant(view.name);
                vm.currentViewIconClass = "toolsicon " + view.icon;
            }
        }

        function fireViewChanged(id, event) {
            vm.currentView = id;
            var callback = vm.onChange;
            if (typeof callback() === 'function'){
                callback()(id, event);
            }
        }
    }

})();