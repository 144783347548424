(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminEditSmtpBlockingController", sysAdminEditSmtpBlockingController);

	function sysAdminEditSmtpBlockingController($scope, $mdDialog, blockType, blockedAddress, direction, description, isNew) {
		$scope.editingItem = blockedAddress;
		$scope.blockType = blockType;
		$scope.blockedAddress = blockedAddress;
		$scope.direction = direction;
		$scope.description = description;
		$scope.blockOptions = [ 0, 1 ];
		$scope.directionOptions = [ 1, 2, 3 ];
		$scope.isNew = isNew;
		$scope.isValid = true;
		$scope.onInit = function(form) {
			if ($scope.editingItem && $scope.isNew) {
				$scope.isValid = form.$valid;
				if (form.$valid) form.$setDirty(true);
			}
		}
		$scope.save = function (scope) {
			if (!($scope.isValid = scope.userForm.$valid)) {
				return;
			}

			$mdDialog.hide({
				blockType: $scope.blockType,
				blockedAddress: $scope.blockedAddress,
				direction: $scope.direction,
				description: $scope.description
			});
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}

})();
