(function () {
	"use strict";

	angular
		.module("smartertools")
		.directive("slidePanel", stResizablePanel);

	function stResizablePanel($rootScope, $timeout) {
		return {
			restrict: "C",
			transclude: true,
			link: function (scope, el, attrs, ctrl, transclude) {

				const minWidth = el.attr("min-width") || 220;
				const maxWidth = el.attr("max-width") || 600;

				if (el.attr("no-resize") !== undefined) {
					transclude(scope, function (clone) { el.append(clone); });
					return;
				}

				transclude(scope, function (clone) {
					el.append(clone);
					var wrapTarget = el.find(".tree-list-container");
					wrapTarget = wrapTarget.length > 0 ? wrapTarget : el.find(".admin-side-menu");

					wrapTarget.wrap('<div class="resizable-container"></div>');
					el.find(".resizable-container").append('<div class="tree-resize-handle"></div>');
				});

				var vm = {};
				var slidePanel = $(".slide-panel-wrapper .slide-panel");

				if (el.find(".resizable-container").length == 0) {
					// Page doesn't support a resizable side panel, set default widths and return
					slidePanel.css("min-width", 250 + "px");
					slidePanel.css("max-width", 250 + "px");
					return;
				}

				if (localStorage.slidePanelSize) {
					slidePanel.css("min-width", localStorage.slidePanelSize);
					slidePanel.css("max-width", localStorage.slidePanelSize);
				}

				el.find(".tree-resize-handle").on("mousedown", startResize);

				function startResize(event) {
					event.preventDefault();

					var rtl = $('body[dir=RTL]');
					vm.rtl = rtl && rtl.length > 0;

					$("body").append('<div class="resize-cover"></div>');

					vm.startX = event.clientX;
					vm.startWidth = Number(slidePanel.css("min-width").replace('px', ''));

					window.addEventListener("mousemove", duringResize);
					window.addEventListener("mouseup", stopResize);
				}

				function duringResize(event) {
					event.preventDefault();

					var newWidth;
					if (vm.rtl)
						newWidth = vm.startWidth + (vm.startX - event.clientX);
					else
						newWidth = vm.startWidth + (event.clientX - vm.startX);

					newWidth = newWidth < minWidth ? minWidth : (newWidth > maxWidth ? maxWidth : newWidth);
					if (newWidth == vm.prevWidth) return;

					vm.prevWidth = newWidth;
					slidePanel.css("min-width", newWidth + "px");
					slidePanel.css("max-width", newWidth + "px");

					if (!vm.triggerResizeTimeout) {
						vm.triggerResizeTimeout = $timeout(resizeTimeout, 20);
					}
				}

				function stopResize(event) {
					event.preventDefault();
					$(".resize-cover").remove();

					window.removeEventListener("mousemove", duringResize);
					window.removeEventListener("mouseup", stopResize);

					localStorage.slidePanelSize = slidePanel.css("min-width");

				}

				function resizeTimeout() {
					$(window).trigger("resize")
					$rootScope.$broadcast("masonry:contentsChanged");
					if (scope.correctTopBarWidth)
						scope.correctTopBarWidth();
					if (scope.listController)
						scope.listController.refresh();
					vm.triggerResizeTimeout = undefined;
				}

				scope.$on('$destroy', function () {
					$(".resize-cover").remove();
					window.removeEventListener("mousemove", duringResize);
					window.removeEventListener("mouseup", stopResize);
				});
			}
		}
	}
})();