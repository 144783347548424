(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("meetingWorkspaces", meetingWorkspaces);

	function meetingWorkspaces($rootScope, $http, $q, coreDataFileStorage, preferencesStorage, userDataService) {
		var vm = this;
		var userDataServiceLoaded = false;
		vm.meetings = null;
		vm.participatedMeetings = null;

		// Functions
		vm.getFilteredOwnedMeetings = getFilteredOwnedMeetings;
		vm.getFilteredParticipatedMeetings = getFilteredParticipatedMeetings;
		vm.getMeetings = getMeetings;
		vm.getMeetingDetails = getMeetingDetails;
		vm.deleteMeetings = deleteMeetings;
		vm.createNew = createNew;
		vm.unarchiveMeetings = unarchiveMeetings;
		vm.update = update;
		vm.userDataService = userDataService;
		vm.webConferencing = false;

		loadFeatures();

		// Implementation
		function loadFeatures() {
			userDataService
				.init()
				.then(function () {
					vm.webConferencing = userDataService.user.settings.features && userDataService.user.settings.features.enableWebConferencing;
					userDataServiceLoaded = true;
				}, function () {});
		}

		function getFilteredOwnedMeetings(archived) {
			if (!vm.meetings)
				return [];

			return archived
				? $.grep(vm.meetings, mtg => mtg.archived && !mtg.inProcess)
				: $.grep(vm.meetings, mtg => !mtg.archived && !mtg.inProcess);
		}

		function getFilteredParticipatedMeetings(archived) {
			if (!vm.participatedMeetings)
				return [];

			return archived
				? $.grep(vm.participatedMeetings, mtg => mtg.archived && !mtg.inProcess)
				: $.grep(vm.participatedMeetings, mtg => !mtg.archived && !mtg.inProcess);
		}

		function getMeetings(force) {
			var defer = $q.defer();
			if (!vm.webConferencing && !userDataServiceLoaded) {
				$q.when(userDataServiceLoaded)
				.then(function () {
					if (vm.meetings && !force) {
						defer.resolve();
					} else {
						var promises = [$http.get("~/api/v1/team-workspaces/my-workspaces"), $http.get("~/api/v1/team-workspaces/participated-workspaces")]
						$q.all(promises)
						.then(
							function (result) {
								vm.meetings = result[0].data.workspaces;
								vm.participatedMeetings = result[1].data.workspaces;
								getFolderSizes();
								defer.resolve();
							},
							function (failure) { defer.reject(failure); }
						);
					}
				}, function () { });
			} else {
				if (vm.meetings && !force) {
					defer.resolve();
				} else {
					var promises = [$http.get("~/api/v1/team-workspaces/my-workspaces"), $http.get("~/api/v1/team-workspaces/participated-workspaces")];
					$q.all(promises)
					.then(
						function (result) {
							vm.meetings = result[0].data.workspaces;
							vm.participatedMeetings = result[1].data.workspaces;
							getFolderSizes();
							defer.resolve();
						},
						function (failure) { defer.reject(failure); }
					);
				}
			}
			return defer.promise;
		};

		function getFolderSizes() {
			for (var i = 0; i < vm.meetings.length; ++i) {
				if (vm.meetings[i].meetingFolder) {
					vm.meetings[i].fileCount = vm.meetings[i].meetingFolder.count;
					vm.meetings[i].bytesUsed = vm.meetings[i].meetingFolder.size;
				} else {
					vm.meetings[i].fileCount = 0;
					vm.meetings[i].bytesUsed = 0;
				}
			}
		}

		function getMeetingDetails(id) {
			var defer = $q.defer();
			$http.get("~/api/v1/team-workspaces/workspace/" + id)
				.then(
					function (result) { 
						defer.resolve(result.data.workspace); 
					},
					function (failure) { defer.reject(failure); }
				);
			return defer.promise;
		}

		function deleteMeetings(ids, removeFromAppointment) {
			var defer = $q.defer();
			const url = removeFromAppointment
				? "~/api/v1/team-workspaces/remove-appt"
				: "~/api/v1/team-workspaces/delete";
			$http.post(url, { ids: ids })
				.then(
					function (result) {
						//coreDataFileStorage.removeMeetingFolders(publicIds);
						getMeetings(true).then(
							function () { defer.resolve(); },
							function (failure) { defer.reject(failure); });
					},
					function (failure) { defer.reject(failure); }
				);
			return defer.promise;
		}

		function unarchiveMeetings(ids) {
			var defer = $q.defer();
			$http.post("~/api/v1/team-workspaces/unarchive", { ids: ids })
				.then(
					function (result) {
						getMeetings(true).then(
							function () { defer.resolve(); },
							function (failure) { defer.reject(failure); });
					},
					function(failure) { defer.reject(failure) });
			return defer.promise;
		}

		$rootScope.$on("signalR.mailHub.client.meetingCreated", function(ev, meetingId) {
			getMeetings(true).then(function () {
				$rootScope.$broadcast('meetings.refresh');
			});
		});

		$rootScope.$on('signalR.mailHub.client.meetingDeleted', function (ev, meetingId) {
			vm.meetings = $.grep(vm.meetings, function (meeting) { return meeting.publicIdentifier === meetingId; }, true);
			$rootScope.$broadcast('meetings.refresh');
		});

		$rootScope.$on('signalR.mailHub.client.meetingModified', function (ev) {
			getMeetings(true).then(function () {
				$rootScope.$broadcast('meetings.refresh');
			});
		});
		
		$rootScope.$on('signalR.mailHub.client.filesModified', function (ev) {
			getMeetings(true).then(function () {
				$rootScope.$broadcast('meetings.refresh');
			});
		});

		function createNew(details) {
			var defer = $q.defer();

			const params = {
				title: details.title,
				description: details.description,
				password: details.password,
				allowGuestUsers: details.allowGuestUsers,
				attendeesCanStart: details.attendeesCanStart,
				uploadPermissionLevel: details.uploadPermissionLevel,
				inProcess: details.inProcess || false,
				securitySettings: details.securitySettings,
			};
			if (details.scheduleMeeting) {
				params.scheduledStart = details.scheduledStart;
				params.startTzId = details.timeZoneStart.location
					? details.timeZoneStart.location
					: details.timeZoneStart.id
						? details.timeZoneStart.id
						: details.timeZoneStart;
				params.scheduledEnd = details.scheduledEnd;
				params.endTzId = details.timeZoneEnd.location
					? details.timeZoneEnd.location
					: details.timeZoneEnd.id
						? details.timeZoneEnd.id
						: details.timeZoneEnd;
				params.endMeetingBehavior = details.endMeetingBehavior.id;
			}

			$http.post("~/api/v1/team-workspaces/create", params)
				.then(
					function (result) {
						getMeetings(true)
							.then(defer.resolve(result.data), defer.resolve(result.data));
					},
					defer.reject);

			return defer.promise;
		}

		function update(details) {
			var defer = $q.defer();

			const params = {
				id: details.id,
				title: details.title,
				description: details.description,
				password: details.password,
				allowGuestUsers: details.allowGuestUsers,
				attendeesCanStart: details.attendeesCanStart,
				uploadPermissionLevel: details.uploadPermissionLevel,
				inProcess: details.inProcess
			};
			if (details.scheduleMeeting) {
				params.scheduledStart = details.scheduledStart;
				params.startTzId = details.timeZoneStart.location
					? details.timeZoneStart.location
					: details.timeZoneStart.id
					? details.timeZoneStart.id
					: details.timeZoneStart;
				params.scheduledEnd = details.scheduledEnd;
				params.endTzId = details.timeZoneEnd.location
					? details.timeZoneEnd.location
					: details.timeZoneEnd.id
					? details.timeZoneEnd.id
					: details.timeZoneEnd;
				params.endMeetingBehavior = 
					(details.endMeetingBehavior.id === null || details.endMeetingBehavior.id === undefined) ? details.endMeetingBehavior : details.endMeetingBehavior.id;
			}
			
			$http.post("~/api/v1/team-workspaces/update", params)
				.then(
					function (result) {
						getMeetings(true).then(defer.resolve(result.data), defer.resolve(result.data));
					},
					function (failure) { defer.reject(failure); }
			);

			return defer.promise;
		}
	}
})();