(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("createNoteFolderController", createNoteFolderController);

    function createNoteFolderController($scope, $mdDialog, $http, errorHandling) {
        var vm = this;
        vm.name = '';

        // Functions
        vm.save = save;
        vm.cancel = cancel;

        activate();

        //////////////////

        function activate() {
        }

        function cancel() {
            $mdDialog.cancel();
        }

        async function save(ev, noteFolderForm) {
            if (vm.name === "")
                return;
            if (noteFolderForm.$invalid)
                return;

            try {
                const folder = { folder: vm.name };
                await $http.post('~/api/v1/notes/sources/add', folder);
                $mdDialog.hide();
            }
            catch (err) {
                errorHandling.report(err);
            }
        }
    }
})();
