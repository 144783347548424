(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminImpersonateUserControllerChange", sysAdminImpersonateUserControllerChange);

	function sysAdminImpersonateUserControllerChange($mdDialog, $http, $translate, $window, errorHandling, impersonationService, claimsService) {

		var vm = this;
		vm.email = "";
		vm.failedEmail = "";
		vm.selectedDomain = "";
		vm.userOptions = undefined;
		vm.claimsDomain = claimsService.getDomain();
        vm.stopImpersonating = stopImpersonating;
        vm.impersonatingEmail = getImpersonatingEmail();

		activate();

		function activate() {
			$http.get("~/api/v1/settings/sysadmin/internationalized-domain-names")
				.then(function (result) {
					vm.domainOptions = angular.copy(result.data.data);
					vm.domainOptions.unshift({ asciiName: $translate.instant("ALL") });
					vm.selectedDomain = vm.domainOptions[0];
				}, errorHandling.report);
		}

        function getImpersonatingEmail() {
            return claimsService.getEmailAddress();
        }

		function stopImpersonating(ev) {
            $window.close();
		}

		vm.impersonate = function (form) {
			var isAnyDomain = vm.selectedDomain === vm.domainOptions[0];
			if (isAnyDomain && (!form || !form.email || !form.email.$valid))
				return;

            var email = isAnyDomain ? vm.email : vm.email.item1;
			impersonationService.impersonateUserReplaceCurrent(email);
			$mdDialog.hide();
		};

		vm.emailChanged = function (form) {
			if (form) {
				if (vm.failedEmail !== "" && vm.failedEmail === vm.email.item1) {
					form.email.$setValidity("validateEmailExistence", false);
				}
				else {
					form.email.$setValidity("validateEmailExistence", true);
				}
			}
		};

		vm.queryUsers = function (searchText) {
			if (searchText.length > 0 && !searchText.endsWith(".")) {
				return $http.post("~/api/v1/settings/sysadmin/search-impersonation-users", { domain: vm.selectedDomain.asciiName, search: searchText })
					.then(function (result) { return result.data.data; }, errorHandling.report)
					.catch(function () {
						return [];
					});
			}

			return [];
		};

		vm.cancel = function () {
			$mdDialog.cancel();
		};

		vm.changeDomain = function changeDomain() {
			vm.email = "";
			vm.failedEmail = "";
			vm.userOptions = undefined;
		}
	}

})();