(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("fsNewFolderController", fsNewFolderController);

	function fsNewFolderController($scope, $mdDialog, $filter, folderInfo, folders) {
		$scope.treeData = [];
		$scope.treeController = {};
		$scope.treeData = $.extend(true, [], folderInfo.treeData);
		$scope.name = '';
		$scope.folders = folders;
		$scope.folders = $.grep($scope.folders, function (folder) {
			if (folder.path.indexOf("86bc2a5f-89d8-4e76-a3e8-c53cbebe565d") > -1) { return false; }
			if (folder.path.indexOf("PUBLIC_CHAT_FILES") > -1) { return false; }
		    return true;
		});
		for (var i = 0; i < $scope.folders.length; ++i) {
			$scope.folders[i].displayPath = $filter("translate")("MY_FILES") + $scope.folders[i].path;
		}

		$scope.parentFolder = folderInfo.parentFolder;
		$scope.parentFolderName = folderInfo.parentFolderName;

		if (folderInfo.parentFolder.indexOf("86bc2a5f-89d8-4e76-a3e8-c53cbebe565d") > -1 || folderInfo.parentFolder.indexOf("PUBLIC_CHAT_FILES") > -1) {
		    $scope.currentFolder = "/";
		    $scope.currentFolderName = $filter("translate")("MY_FILES");
		} else {
		    $scope.currentFolder = folderInfo.parentFolder;
		    $scope.currentFolderName = folderInfo.parentFolderName;
		}

		$scope.onBranchChanged = function (branch) {
			$scope.currentFolderName = branch.data.id;
			$scope.currentFolder = branch.data.path;
		}

		$scope.save = function () {
			$mdDialog.hide({
				folderInfo: {
					folder: $scope.name,
					parentFolder: $scope.currentFolder
				}
			});
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}

})();
