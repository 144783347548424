(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sharedNewMailingListController", sharedNewMailingListController);

	function sharedNewMailingListController($scope, $http, $mdDialog, claimsService, errorHandling) {

		var vm = this;

		$scope.listAddress = "";
		$scope.isValid = true;
		$scope.isListAddressValid = true;
		vm.currentModerator = "";

		// Functions
		$scope.save = save;
		$scope.cancel = cancel;
		vm.queryUsers = queryUsers;

		activate();

		////////////////////

		function activate() {
		}

		function save(form) {
			if (form.$invalid)
				return;

			$mdDialog.hide({
				listAddress: $scope.listAddress,
				moderator: vm.currentModerator
			});
		}

		function cancel() {
			$mdDialog.cancel();
		}

		function queryUsers(searchText) {
			if (searchText.length > 0) {
				return $http
					.get(`~/api/v1/settings/domain/list-usernames/?usersToReturn=50&includeDomain=true&search=${searchText}`)
					.then(function (result) { return result.data.usernames; }, errorHandling.report)
					.catch(function () { return []; });
			}

			return $q.when([]);
		}
	}

})();
