(function () {
    'use strict';

    angular
        .module('smartertools')
        .directive('onErrorSrc', onErrorSrc);

    function onErrorSrc() {
        return {
            link: function (scope, element, attrs) {
                element.bind('error', function () {
                    if (attrs.src != attrs.onErrorSrc) {
                        let dest = attrs.onErrorSrc;
                        if (dest && dest.indexOf("~/") == 0) {
                            dest = stSiteRoot + dest.substr(2);
                        }
                        attrs.$set('src', dest);
                    }
                });
            }
        }
    }

})();