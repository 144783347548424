/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "[iI]gnored" }]*/
(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("mainController", mainController);

	function mainController(localeInfoService, $rootScope, $scope, $window, $mdDialog, $filter, coreData, coreLicensing,
		$timeout, $state, $http, claimsService, authStorage, $translate, $log, toaster, browserNotifications) {
		var impersonatedTokens = authStorage.impersonationTokens();
		if (impersonatedTokens && authStorage.hasInitiatedImpersonation(impersonatedTokens.email) == false) {
			var decodedToken = claimsService.decodeToken(impersonatedTokens.accessToken);
			var nbf = moment.utc("1970").add("s", decodedToken.nbf);

			// We can't do this here because we're trying to compare a user's browser time to the servers time.
			// A user might not have their settings to automatically set their time so this check could fail
			// under normal circumstances.
			{
				// If the token was created with "Not Before Time" that's older than 6 seconds
				// from now then we're going to ignore the impersonation and delete the toktoken
				//if (now > nbf.add("s", 6)) {
				//	authStorage.deleteInitiatedImpersonation();
				//	authStorage.deleteImpersonateToken();
				//} else {
				authStorage.deleteInitiatedImpersonation();
				authStorage.saveToken(impersonatedTokens, true, "main.controller.mainController()");
				authStorage.deleteImpersonateToken();
				claimsService.setClaims(impersonatedTokens.accessToken, true);
				//}
			}
		}

		// Data
		var vm = this;
		vm.isSysAdmin = coreData.user.isSysAdmin;
		vm.impersonating = claimsService.impersonating();
		vm.currentLocation = $rootScope.actualLocation;
		vm.hideGettingStartedPrompt = hideGettingStartedPrompt;
		var noMouse = false;

		// Migration alert toast
		var migrationToast;

		// Initialization
		init();
		function init() {
			vm.showGettingStartedPrompt = !!localStorage.getItem("interface_show_getting_started_prompt");

			coreLicensing.ensureLoaded();
			localeInfoService.onReady().then(function () { //Wait for a guaranteed translation before letting anything else load.
				vm.isInit = true;
			}, function () { //If the translation id was not found
				vm.isInit = true;
			});

			if ($rootScope.browserCaps && $rootScope.browserCaps.mouseExists === false) {
				$timeout(function () {
					setNoMouse();
				}, 100);
			}

			if (!vm.impersonating) {
				coreData.init()
					.then(function() {
						},
						function() {});
			} 

			$rootScope.$on("migrationError", clearMigrationToast);
			$rootScope.$on("signalR.mailHub.client.mailMigrationUpdate", onMailMigrationUpdate);

			$rootScope.$on("signalR.mailHub.client.mailRetrievalStart", onMailRetrievalStart);

			$rootScope.commonTableCounts = [25, 50, 100, 200];
			checkEdgeVersion();
			checkFirefoxVersion();
			checkBrowserNotifications();
		}

		function checkBrowserNotifications() {
			const alreadyWarned = sessionStorage.getItem('browserNotifications.lastState') == browserNotifications.permission;

			let hideNotifications = window.localStorage.getItem("hideNotificationsWarning") == "True";
			if (!alreadyWarned && browserNotifications.permission != 'granted' && !hideNotifications) {
				$timeout(() => {
					window.browserNotificationDisabledWarningFunction = function () {
						event.stopPropagation();
						window.localStorage.setItem("hideNotificationsWarning", "True");
						toaster.clear(window.browserNotificationDisabledWarning);
						return false;
					}

					window.browserNotificationEnableFunction = function (event) {
						event.stopPropagation();
						browserNotifications.fetchPermission()
							.then(
								function () {
									toaster.clear(window.browserNotificationDisabledWarning);
								},
								function () {
									toaster.clear(window.browserNotificationDisabledWarning);
								}
							);
					}

					var warningText = $translate.instant("BROWSER_NOTIFICATIONS_DISABLED_WARNING");
					if (browserNotifications.permission == "default") {
						let prompt = $translate.instant("BROWSER_NOTIFICATIONS_ENABLE_PROMPT", { handler: "browserNotificationEnableFunction(event)" });
						prompt = $("<div>").html(prompt).text(); // Translate HTML encodes the string, so we need to undo that
						warningText += ` ${prompt}`;
					}
						
					window.browserNotificationDisabledWarning = toaster.pop({
						type: 'warning',
						body: `
                                <div>${warningText}</div>
                                <div style='margin-top: 5px;'>
                                    <button class='md-button md-primary' style='margin-left: 0;' type='button' onclick='event.stopPropagation(); window.open("https://help.smartertools.com/smartermail/current/?page=browser-notifications-disabled&v=${stProductVersion}", "help"); return false;'>${$translate.instant("MORE_INFORMATION")}</button>
                                    <button class='md-button md-primary' style='margin-left: 0;' type='button' onclick='browserNotificationDisabledWarningFunction()'>${$translate.instant("DISMISS")}</button>
                                </div>`,
						timeout: 0,
						bodyOutputType: 'trustedHtml',
						onHideCallback: () => { sessionStorage.setItem('browserNotifications.lastState', browserNotifications.permission); return true; },
						clickHandler: () => { sessionStorage.setItem('browserNotifications.lastState', browserNotifications.permission); return true; }
					});
				}, 500);
			}
		}

		function hideGettingStartedPrompt() {
			vm.showGettingStartedPrompt = false;
			localStorage.removeItem("interface_show_getting_started_prompt");
			$http.post("~/api/v1/settings/sysadmin/disable-getting-started-bar", {})
				.then(function (success) { }, function (ignored) { });
		}

		function setNoMouse() {
			if (window.location.hash.indexOf("login") == -1 || !$("body").hasClass("no-mouse")) {
				noMouse = true;
				$("body").addClass("no-mouse");
			} else {
				$timeout(function () {
					setNoMouse();
				}, 100);
			}
		}

		function clearMigrationToast() {
			if (migrationToast)
				toaster.clear(migrationToast);
			migrationToast = null;
		}

		var clearToastTimeout = null;
		function onMailMigrationUpdate(event, data) {
			if (clearToastTimeout) {
				$timeout.cancel(clearToastTimeout);
			}

			// The done notification may be part of a set of updates. Make sure to check all of them.
			for (let i = 0; i < data.length; i++) {
				if (data[i].done) {
					clearMigrationToast();
					return;
				}
			}

			if (!migrationToast) {
				migrationToast = toaster.pop({
					type: "info",
					title: null,
					body: $translate.instant("MAILBOXMIGRATION_RUNNING_TOAST", { source: data[0].source }),
					timeout: 0,
					bodyOutputType: "trustedHtml",
					tapToDismiss: false,
					showCloseButton: false
				});
			}

			clearToastTimeout = $timeout(clearMigrationToast, 20000);
		}

		function onMailRetrievalStart(event, data) {
			toaster.pop({
				type: "info",
				title: null,
				body: $translate.instant("RETRIEVING_MAIL_FROM_X", { source: data.source }),
				timeout: 10000,
				bodyOutputType: "trustedHtml"
			});
		}

		Object.defineProperty($rootScope, "windowWidth", {
			get: () => $window.innerWidth
		});
		Object.defineProperty($rootScope, "windowHeight", {
			get: () => $window.innerHeight
		});

		//Edge/16.16299
		function checkEdgeVersion() {
			var edgeVersion = -1;
			var indexOfEdge = navigator.userAgent.indexOf("Edge");
			if (indexOfEdge !== -1) {
				var tempString = navigator.userAgent.substring(indexOfEdge, indexOfEdge + 8);
				if (tempString.indexOf("Edge/") !== -1) {
					tempString = tempString.replace(".", "");
					var split = tempString.split("/");
					edgeVersion = Number(split[1]);
				}
			}
			if (!$rootScope.browserCaps) {
				$rootScope.browserCaps = {};
			}
			$rootScope.browserCaps.edgeVersion = edgeVersion;
		}

		//Firefox/40.0
		function checkFirefoxVersion() {
			var ffVersion = -1;
			var indexOfFF = navigator.userAgent.indexOf("Firefox");
			if (indexOfFF !== -1) {
				var tempString = navigator.userAgent.substring(indexOfFF, indexOfFF + 11);
				if (tempString.indexOf("Firefox/") !== -1) {
					tempString = tempString.replace(".", "");
					var split = tempString.split("/");
					ffVersion = Number(split[1]);
				}
			}
			if (!$rootScope.browserCaps) {
				$rootScope.browserCaps = {};
			}
			$rootScope.browserCaps.ffVersion = ffVersion;
		}
		$scope.$watch("actualLocation", function (newValue, oldValue) {
			vm.currentLocation = newValue;
			if (noMouse && !$("body").hasClass("no-mouse")) {
				$("body").addClass("no-mouse");
			}
		});

		$scope.$watch("browserCaps.mouseExists", function (newValue, oldValue) {
			if (newValue === false && noMouse === false) {
				setNoMouse();
			} else if (noMouse === true) {
				noMouse = false;
				$("body").removeClass("no-mouse");
			}
		});
	}

})();