(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("sysAdminSecurityGreylisting", sysAdminSecurityGreylisting);

    function sysAdminSecurityGreylisting($rootScope, $scope, $compile, $http, $mdDialog, $filter,
        errorHandling, NgTableParams, gridCheckboxes) {

        var vm = this;
        vm.items = [];
        vm.searchParams = { skip: 0, take: 0, search: null, sortField: null, sortDescending: false };
        vm.tableParams = new NgTableParams(
            {
                sorting: { 'ipAddress': 'asc' },
                count: 25
            }, {
            getData: queryData,
            counts: $rootScope.commonTableCounts
        });
        vm.checkboxes = gridCheckboxes.init("id");
        vm.checkboxes.ignoreItemDelegate = function (item) { return item.isInternal; }
        vm.checkboxes.table = vm.tableParams;
        vm.contextMenuGridItem = contextMenuGridItem;
        vm.initialized = false;

        // Functions
        vm.newItem = newItem;
        vm.editItem = editItem;
        vm.deleteItems = deleteItems;
        vm.searchItems = searchItems;

        activate();

        ////////////////////

        function activate() {
            $http
                .get("~/api/v1/settings/sysadmin/antispam/settings")
                .then(function (result) {
                    vm.greylistingDisabled = !result.data.settings.greylisting.enabled;
                }, function () { });
        }

        function signalCountChanged() {
            $rootScope.$broadcast("antispam:counts-changed");
        }

        function searchItems() {
            vm.searchParams.search = vm.searchText;
            refreshTable();
        }

        function queryData(params) {
            vm.searchParams.skip = (params.page() - 1) * params.count();
            vm.searchParams.take = Number(params.count());

            vm.searchParams.sortField = null;
            for (var k in params.sorting()) {
                if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
                    continue;
                vm.searchParams.sortField = k;
                vm.searchParams.sortDescending = params.sorting()[k] === "desc";
                break;
            }

            vm.initialized = false;
            $rootScope.spinner.show();
            return $http
                .post('~/api/v1/settings/sysadmin/greylist', JSON.stringify({ searchParams: vm.searchParams }))
                .then(onDataRetrieved, errorHandling.report)
                .finally($rootScope.spinner.hide);

            function onDataRetrieved(result) {
                vm.items = result.data.filters || [];

                params.total(result.data.totalCount);
                vm.initialized = true;

                return vm.items;
            }
        }

        function refreshTable() {
            signalCountChanged();
            vm.checkboxes.reset();
            vm.tableParams.reload();
        }

        function newItem(ev) {
            $mdDialog
                .show({
                    locals: {
                        filterType: 0,
                        countryCode: "",
                        ipAddress: "",
                        description: "",
                        isNew: true
                    },
                    controller: "sysAdminEditGreylistingController",
                    controllerAs: "ctrl",
                    templateUrl: "app/sysadmin/settings/antispam/modals/greylist-filter.dlg.html",
                    targetEvent: ev
                })
                .then(function (modalSuccess) {
                    var filter = {
                        countryCode: modalSuccess.filterType === 1 ? modalSuccess.countryCode : undefined,
                        ipAddress: modalSuccess.filterType === 0 ? modalSuccess.ipAddress : undefined,
                        description: modalSuccess.description,
                        filterType: modalSuccess.filterType
                    };

                    $http
                        .post("~/api/v1/settings/sysadmin/greylist-add", JSON.stringify({ filter: filter }))
                        .then(refreshTable, errorHandling.report);
                }, function () {
                    // Cancel
                });
        }

        async function editItem(row, ev) {
            if (row.isInternal)
                return;

            try {
                const modalProperties = {
                    locals: {
                        filterType: row.filterType,
                        countryCode: row.countryCode,
                        ipAddress: row.ipAddress,
                        description: row.description,
                        isNew: false
                    },
                    controller: "sysAdminEditGreylistingController",
                    controllerAs: "ctrl",
                    templateUrl: "app/sysadmin/settings/antispam/modals/greylist-filter.dlg.html",
                    targetEvent: ev
                };

                const modalSuccess = await $mdDialog.show(modalProperties);

                if (modalSuccess.deleteItem) {
                    deleteItems(ev, [row.id]);
                } else {
                    try {
                        row.ipAddress = modalSuccess.filterType === 0 ? modalSuccess.ipAddress : undefined;
                        row.countryCode = modalSuccess.filterType === 1 ? modalSuccess.countryCode : undefined;
                        row.description = modalSuccess.description;
                        row.filterType = modalSuccess.filterType;
                        await $http.post("~/api/v1/settings/sysadmin/greylist-edit", JSON.stringify({ filter: row }));
                        refreshTable();
                    } catch (err) {
                        errorHandling.report(err);
                    }
                }
            } catch (err) {
                // Modal closing will throw this exception
            }
        }

        function deleteItems(ev, items) {
            if (!items || !items.length)
                items = vm.checkboxes.getCheckedItems();
            if (!items || !items.length)
                return;

            var confirm = $mdDialog
                .confirmDeletion()
                .textContent($filter('translate')('CONFIRMATIONS_DELETE_ITEMS', { items: items.length }))
                .targetEvent(ev);

            $mdDialog
                .show(confirm)
                .then(doDelete, function () { /* Cancel */ });

            function doDelete() {
                $http
                    .post("~/api/v1/settings/sysadmin/greylist-delete", JSON.stringify({ ids: items }))
                    .then(refreshTable, errorHandling.report);
            }
        }

        function contextMenuGridItem(item, ev) {
            if (!ev || (ev.type !== "touchstart" && ev.type !== "touchend" && ev.which !== 3) || item.criticallyErrored || item.isInternal) {
                return;
            }
            ev.stopPropagation();
            ev.preventDefault();

            var filter = vm.checkboxes.getCheckedItems();
            //If we right clicked on a not selected item we want to use that item instead
            if ((filter.length > 1 && !_.some(filter, function (val) { return val === item.id; })) || filter.length <= 1) {
                vm.checkboxes.reset();
                vm.checkboxes.checkCheckbox(ev, item);
                filter = [item.sourceOwner + "|" + item.id];
            }

            $scope.dropdownEvent = $.extend(true, {}, ev);
            $scope.dropdownOptions = [
                { key: "deleteItem", click: vm.deleteItems, params: { card: [item.id], event: vm.dropdownEvent }, translateKey: "DELETE", show: !item.isInternal }
            ];

            var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
            var element = $("#context-menu-area");
            if (element) {
                var elementCompiled = $compile(elementToCompile)($scope);
                element.append(elementCompiled);
            }
        }
    }

})();
