/*
 * USE EXAMPLE:
 *    --- FIRST ITEM IN LIST ---
 *      <st-side-menu-list-item type="avatar" location="index.home" tooltip="Home" avatar-name="$parent.ctrl.avatarName" avatar-url="$parent.ctrl.avatarUrl"></st-side-menu-list-item>
 * 
 *    --- BASIC LIST ITEM ---
 *    <st-side-menu-list-item location="index.configuration" item-icon="settings" tooltip="Configuration"></st-side-menu-list-item>
 *    
 *    --- LIST ITEM W/ BADGE COUNTER ---
 *    <st-side-menu-list-item location="index.communications" item-icon="mail" tooltip="Communications" counter-value="BINDING"></st-side-menu-list-item>
 * 
 *    --- LIST ITEM W/ SUB-MENU ---
 *    <st-side-menu-list-item type="menu" item-icon="house" menu-class="standard" tooltip="Help">
 *        <md-menu-item>
 *           <md-button>{{::'ABOUT'|translate}}</md-button>
 *        </md-menu-item>
 *        <md-menu-item>
 *            <md-button>{{::'HELP'|translate}}</md-button>
 *        </md-menu-item>
 *    </st-side-menu-list-item>
 * 
 *    --- FIRST ITEM IN LIST W/ SUB-MENU ---
 *    <st-side-menu-list-item type="avatarmenu" menu-class="standard" tooltip="Home" avatar-name="$parent.ctrl.avatarName" avatar-url="$parent.ctrl.avatarUrl">
 *       <md-menu-item>
 *           <md-button>{{::'ABOUT'|translate}}</md-button>
 *       </md-menu-item>
 *       <md-menu-item>
 *           <md-button>{{::'HELP'|translate}}</md-button>
 *       </md-menu-item>
 *    </st-side-menu-list-item>
 * 
 *    --- DIRECTIVE RETURNS ---
 *    restrict: 'E',
 *    template: function(),
 *    transclude: true,
 *    scope: --- EXPOSED ATTRIBUTES ---
 *    controller: function()
 * 
 *    --- EXPOSED ATTRIBUTES ---
 *    location: '@', --> This is the index.location from config.js file state machine.
 *    icon: '@', --> This is the link font-icon, if left blank list item will not show.
 *    tooltip: '@', --> This is the md-tool-tip text: this will take in a regular string or a {{::'VALUE' | translate}} value. The reason being... BMS does not use translations and other products do.
 *    type: '@', --> There are three types of list items: 'avatar' (avatar image list item) |  'avatarmenu' (avatar image list item w/ sub-menu) | no-type (regular list item) | 'menu' (list item with sub-menu).
 *    menuClass: '@' --> This is exposed for MD-MENU, since there are different type of sub-menus they provide. If this is default view, we can hide this attribute.
 *    chatStatus: '=', --> Special use for SmarterMail.   Set to binding to use for chat status indicator
 *    counterValue: '=', --> This if the message counter binding for list items - NOT INCLUDING FIRST LIST ITEM - if this attribute is set, then the badge counter will display.
 *    avatarUrl: '=', --> This is for the users avatar image
 *    avatarName: '=', --> This is for a user without an avatar, so they use a monogram with the first letter of the avatarName
 */

(function () {
	angular
		.module('smartertools')
		.directive('stSideMenuListItem', stSideMenuListItem);

	function stSideMenuListItem($log, $state) {
		return {
			restrict: 'EA',
			transclude: true,
			scope: {
				location: '@',
				icon: '@',
				tooltip: '@',
				type: '@',
				menuClass: '@',
				chatStatus: '=',
				counterValue: '=',
				avatarUrl: '=',
				avatarName: '=',
				test: '@'
			},
			link: function (scope, element, attrs) {
				// Set item class
				scope.itemClass = "";
				if (attrs.type == "avatar" || attrs.type == "avatarmenu")
					scope.itemClass = "menu-item-top";
				else if (attrs.type == "menu")
					scope.itemClass = "menu-item";
				else
					scope.itemClass = "menu-item";
				if (attrs.itemClass && attrs.itemClass != scope.itemClass)
					scope.itemClass += " " + attrs.itemClass;

				// If there is a way to manually update ui-sref-active, replace this with that method of doing it
				// Otherwise, this makes sure that on load the current nav option is marked active
				scope.active = scope.location === $state.current.name;
			},
			template: function (element, attrs) {
				var type = attrs.type;
				switch (type) {
					case 'avatar':
						var badgeHtml = attrs.counterValue !== undefined
							? '<span class="badge menu-badge-wrapper over-avatar" ng-show="counterValue && counterValue <= 99">{{counterValue}}</span>' +
							'<span class="badge menu-badge-wrapper over-avatar" ng-show="counterValue && counterValue > 99">99+</span>'
							: "";
						var chatStatusHtml = attrs.chatStatus !== undefined
							? '<div ng-show="chatStatus === \'available\' || chatStatus === \'away\' || chatStatus === \'dnd\'" class="chat-status-icon overlay status-{{chatStatus}}"></div>'
							: "";
						var htmlText = '<li type="' + type + '" ui-sref-active="active" class="{{::itemClass}}" ng-class="::{\'active\': active}" ng-cloak>' +
							'<a ui-sref="{{::location}}">' +
							'<md-tooltip md-autohide md-direction="{{tooltipPositon}}"><span translate="{{::tooltip}}"></span></md-tooltip>' +
							'<st-avatar-icon element-id="menu-pic-' + Math.floor(Math.random() * (100)) + '" class="img-circle user-picture" name="avatarName" image-url="avatarUrl" width="41" height="41">' +
							badgeHtml +
							chatStatusHtml +
							'</st-avatar-icon>' +
							'<span class="inline-menu-text" translate="{{::tooltip}}"></span>' +
							'</a>' +
							'</li>';
						return htmlText;
					default:
						var badgeHtml = attrs.counterValue !== undefined
							? '<span class="menu-badge-wrapper" ng-show="counterValue && counterValue <= 999">{{counterValue}}</span>' +
							'<span class="menu-badge-wrapper" ng-show="counterValue && counterValue > 999">999+</span>'
							: "";

						var htmlText = '<li ui-sref-active="active" class="{{::itemClass}}" ng-class="::{\'active\': active}" ng-cloak>' +
							'<a ui-sref="{{::location}}">' +
							'<md-tooltip md-autohide md-direction="{{tooltipPositon}}"><span translate="{{::tooltip}}"></span></md-tooltip>' +
							'<i class="toolsicon toolsicon-{{::icon}}"></i>' +
							badgeHtml +
							'<span class="inline-menu-text" translate="{{::tooltip}}"></span>' +
							'</a>' +
							'</li>';
						return htmlText;
					case 'materialIcon':
					    var badgeHtml = attrs.counterValue !== undefined
					        ? '<span class="menu-badge-wrapper" ng-show="counterValue && counterValue <= 999">{{counterValue}}</span>' +
					        '<span class="menu-badge-wrapper" ng-show="counterValue && counterValue > 999">999+</span>'
					        : "";

					    var htmlText = '<li ui-sref-active="active" class="{{::itemClass}}" ng-class="::{\'active\': active}" ng-cloak>' +
					        '<a ui-sref="{{::location}}">' +
					        '<md-tooltip md-autohide md-direction="{{tooltipPositon}}"><span translate="{{::tooltip}}"></span></md-tooltip>' +
					        '<i class="material-icons">{{::icon}}</i>' +
					        badgeHtml +
					        '<span class="inline-menu-text" translate="{{::tooltip}}"></span>' +
					        '</a>' +
					        '</li>';
					    return htmlText;
					case 'itemclickable':
						var badgeHtml = attrs.counterValue !== undefined
							? '<span class="menu-badge-wrapper" ng-show="counterValue && counterValue <= 999">{{counterValue}}</span>' +
							'<span class="menu-badge-wrapper" ng-show="counterValue && counterValue > 999">999+</span>'
							: "";

						var htmlText = '<li type="' + type + '" class="{{::itemClass}}" ng-cloak>' +
							'<a href="">' +
							'<md-tooltip md-autohide md-direction="{{tooltipPositon}}"><span translate="{{::tooltip}}"></span></md-tooltip>' +
							'<i class="toolsicon toolsicon-{{::icon}}"></i>' +
							badgeHtml +
							'<span class="inline-menu-text" translate="{{::tooltip}}"></span>' +
							'</a>' +
							'</li>';
						return htmlText;
					case 'menu':
						var htmlText = '<li ui-sref-active="active" class="{{::itemClass}}" ng-class="::{\'active\': active}">' +
							'<md-menu md-offset="60 7">' +
							'<a ng-click="$mdMenu.open($event)">' +
							'<md-tooltip md-autohide md-direction="{{tooltipPositon}}"><span translate="{{::tooltip}}"></span></md-tooltip>' +
							'<i class="toolsicon toolsicon-{{::icon}}"></i>' +
							'<span class="inline-menu-text" translate="{{::tooltip}}"></span>' +
							'</a>' +
							'<md-menu-content class="{{::menuClass}}" ng-transclude>' +
							'</md-menu-content>' +
							'</md-menu>' +
							'</li>';
						return htmlText;
					case 'avatarmenu':
						var badgeHtml = attrs.counterValue !== undefined
							? '<span class="badge menu-badge-wrapper over-avatar" ng-show="counterValue && counterValue <= 99">{{counterValue}}</span>' +
							'<span class="badge menu-badge-wrapper over-avatar" ng-show="counterValue && counterValue > 99">99+</span>'
							: "";
						var chatStatusHtml = attrs.chatStatus !== undefined
							? '<div ng-show="chatStatus === \'available\' || chatStatus === \'away\' || chatStatus === \'dnd\'" class="chat-status-icon overlay status-{{chatStatus}}"></div>'
							: "";
						var htmlText = '<li type="' + type + '" ui-sref-active="active" class="{{::itemClass}}" ng-class="::{\'active\': active}">' +
							'<md-menu md-offset="60 7">' +
							'<a ng-click="$mdMenu.open($event)" class="avatar-menu-link">' +
							'<md-tooltip md-autohide md-direction="{{tooltipPositon}}"><span translate="{{::tooltip}}"></span></md-tooltip>' +
							'<st-avatar-icon element-id="menu-pic-' + Math.floor(Math.random() * (100)) + '" class="img-circle user-picture" name="avatarName" image-url="avatarUrl" width="41" height="41">' +
							badgeHtml +
							chatStatusHtml +
							'</st-avatar-icon>' +
							'<span class="inline-menu-text" translate="{{::tooltip}}"></span>' +
							'</a>' +
							'<md-menu-content class="{{::menuClass}}" ng-transclude>' +
							'</md-menu-content>' +
							'</md-menu>' +
							'</li>';
						return htmlText;
				};
			},
			controller: stSideMenuListItemController
		};
	}

	function stSideMenuListItemController($scope, $timeout) {
		var vm = this;
		activate();



		///////////

		function activate() {
			updateDirection();
			$scope.$on("locale:changed", updateDirection);
		}

		function updateDirection() {
			var isRtl = $('body[dir=rtl]').length > 0;
			$scope.tooltipPositon = isRtl ? 'left' : 'right';
		}
	}
})();