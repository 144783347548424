(function () {
    "use strict";

    angular
        .module('smartermail')
        .component('topMenuBarAdmin', {
            templateUrl: "app/root/top-menu-bar.component.html",
            controller: topMenuBarController,
            transclude: false,
            bindings: {}
        });

    /* @ngInject */
    function topMenuBarController($rootScope, $scope, $log, $state, $transitions, browserNotifications, $translate, $http, $mdDialog, $window, $timeout,
        coreData, userDataService, errorHandling, AuthenticationService, notifications, coreDataMail, claimsService, treeState, popupService, preferencesStorage, themesService) {
        var vm = this;
        var initialTheme = (themesService.isThemeDark()) || false;
         var debouncedOnResize = _.throttle(onResize, 50, { leading: false }); 

        vm.hasChat = false;
        vm.chatStatusTypes = [];
        vm.menuManage = { text: 'MANAGE', translated: $translate.instant('MANAGE'), sref: 'index.sysadmin-manage.dashboard', parent_sref: 'index.sysadmin-manage' };
        vm.menuReports = { text: 'REPORTS', translated: $translate.instant('REPORTS'), sref: 'index.reports.dashboard-server', parent_sref: 'index.reports' };
        vm.menuSettings = { text: 'SETTINGS', translated: $translate.instant('SETTINGS'), sref: 'index.sysadmin-settings.system-administrators', dividerBefore: true, parent_sref: 'index.sysadmin-settings' };
        vm.menuNormal = {
            expanded: [
                vm.menuManage,
                vm.menuReports,
                vm.menuSettings
            ],
            dropdown: [
            ],
        }
        vm.menuNarrowDisplays = {
            expanded: [],
            dropdown: [
                vm.menuManage,
                vm.menuReports,
                vm.menuSettings
            ],
        }
        vm.menu = vm.menuNormal;
        vm.dropdownTitle = $translate.instant("MORE");
        vm.isDropdownMenuHilighted = false;

        vm.rightItems = [
            {
                text: 'NOTIFICATIONS',
                icon: 'bell', counter: 0,
                click: function () { window.open((stSiteRoot || '/') + "interface/root#/popout/notifications", "notifications", 'resizable=1, ' + popupService.dimensions.notification); }
            },
            {
                text: initialTheme ? 'SWITCH_TO_LIGHT_THEME' : 'SWITCH_TO_DARK_THEME',
                icon: initialTheme ? 'dark_mode' : 'light_mode_filled',
                click: function () { toggleTheme(this); }
            }
        ];

        vm.avatarClick = function () { }
        vm.avatarGuid = (new Date()).getTime();
        vm.avatarName = coreData.user.displayName;
        vm.avatarUrl = coreData.user.pictureUrl;
        vm.currentItem = null;
        vm.edition = 1;
        vm.customHelpInfo = null;
        vm.userNotificationCountIndicator = 0;
        vm.unreadMessages = 0;
        vm.menuVisible = false;
        vm.username = claimsService.getDisplayName();
        vm.windowWidth = 0;
        vm.treeState = treeState;
        vm.isReady = false;

        // Functions
        vm.toggleTree = toggleTree;
        vm.openHelp = openHelp;
        vm.openCustomHelp = openCustomHelp;
        vm.openAbout = openAbout;
        vm.logout = logout;
        vm.navTo = navTo;

        activate();

        /////////////////

        function activate() {
            vm.windowWidth = $(window).width();
            onWindowWidthChanged(true);

            $scope.$on('user-settings:changed', function () {
                vm.avatarName = coreData.user.displayName;
                vm.avatarUrl = coreData.user.pictureUrl;
            });

            window.addEventListener('resize', debouncedOnResize);
            $scope.$on("$destroy", function () {
                window.removeEventListener('resize', debouncedOnResize);
            });

            $transitions.onStart({}, stateChanged);
            $transitions.onSuccess({}, stateSuccess);
            stateChanged();
            doCheckItemActive($state.current.name);

            userDataService
                .init()
                .then(function () {
                    vm.avatarName = coreData.user.displayName;
                    vm.avatarUrl = coreData.user.pictureUrl;

                    vm.customHelpInfo = coreData.customHelpInfo;
                    if (vm.customHelpInfo && (!vm.customHelpInfo.url || vm.customHelpInfo.url == 'http://' || vm.customHelpInfo.url == 'https://'))
                        vm.customHelpInfo = null;

                    // In case items are not in the DOM yet, wait to do initial resizing until after they show
                    const initialResizeInterval = setInterval(initialResizeCheck, 25);
                    function initialResizeCheck() {
                        var testHtml = $('#hamburger-toggle');
                        if (testHtml && testHtml.length > 0 && testHtml.width()) {
                            onResize();
                            clearInterval(initialResizeInterval);
            doCheckItemActive($state.current.name);
                        }
                    }

                }, function () { });

            refreshNotifications();
            hookEvents();
        }

        function onResize(event) {
            vm.windowWidth = $(window).width();
            onWindowWidthChanged();
        }

        function onWindowWidthChanged(force) {
            const topBar = document.querySelector('.root-heading');
            const barWidth = topBar.getBoundingClientRect().width;
            if (!barWidth)
                return;

            // Find width of all items that stay visible
            var fixedItemsWidth = 0;
            var fixedItems = [];
            const fixedItemSelectors = [
                '#hamburger-toggle',
                '#add-button',
                '.right-item',
                '#right-most-menu',
            ];
            topBar
                .querySelectorAll(fixedItemSelectors.join(', '))
                .forEach((item) => {
                    // We don't exclude hidden items so that things don't shift if they suddenly appear
                    fixedItems.push({ id: item.id, classList: item.classList.value, width: item.getBoundingClientRect().width })
                    fixedItemsWidth += item.getBoundingClientRect().width;
                });

            // Calculate the size of each default item, and use it to figure out if we should shrink the menu
            var remainingWidth = barWidth - fixedItemsWidth;
            vm.menuNormal.expanded.forEach(item => {
                const width = testWidth($translate.instant(item.text), item === vm.menuNormal.expanded[0]);
                remainingWidth -= width;
            });

            const sizeToReserveForAppMenu = vm.menuNormal.dropdown.length > 0 ? 300 : 0;
            const newMenu = remainingWidth < sizeToReserveForAppMenu ? vm.menuNarrowDisplays : vm.menuNormal;
            if (vm.menu !== newMenu || !vm.isReady) {
                $scope.$applyAsync(function () {
                    vm.menu = newMenu;
                    vm.isReady = true;
                    recalulateDropdownMenuClassesInsideScope();
                });
            }

            function testWidth(name, first) {
                const div = document.createElement("DIV");
                div.style.visibility = 'hidden';
                div.classList.add('left-item');
                div.classList.toggle('left-most', first);

                const title = document.createElement("DIV");
                title.classList.add('section-title');
                title.innerText = name;
                div.appendChild(title);

                topBar.appendChild(div);
                const itemWidth = div.getBoundingClientRect().width;
                topBar.removeChild(div);
                return itemWidth;
            }
        }

        function recalulateDropdownMenuClassesInsideScope() {
            var newIsDropdownMenuHilighted = false;
            var newDropdownTitle = $translate.instant("MORE");
            vm.menu.dropdown.forEach((item) => {
                if (item.isActive) {
                    newIsDropdownMenuHilighted = true;
                    newDropdownTitle = item.translated;
                }
            });
            vm.isDropdownMenuHilighted = newIsDropdownMenuHilighted;
            vm.dropdownTitle = newDropdownTitle;
        }

        function hookEvents() {
            $scope.$on('signalR.mailHub.client.notificationChanged', onNotificationChanged);
            $scope.$on('signalR.mailHub.client.eventNotification', onEventNotification);
            $scope.$on("treeState:stateChange", onTreeStateChanged);
            $rootScope.$on("loadCustomHelp", refreshCustomHelp);
            $rootScope.$on("themeService:changed", onThemeUpdated);
        }

        function onThemeUpdated() {
            $scope.$applyAsync(function () {
                vm.rightItems[1].icon = themesService.isThemeDark() ? 'dark_mode' : 'light_mode_filled';
                vm.rightItems[1].text = themesService.isThemeDark() ? 'SWITCH_TO_LIGHT_THEME' : 'SWITCH_TO_DARK_THEME';

            });
        }

        function toggleTheme(sender) {
            themesService.togglePrimaryTheme();
        }

        function onTreeStateChanged(event, stateData) {
        }

        function onNotificationChanged(event, data) {
            if (data.userCount !== null && data.userCount !== undefined) {
                $scope.userNotificationCount = data.userCount;
                vm.userNotificationCount = data.userCount;
            }
            if (data.domainCount !== null && data.domainCount !== undefined) {
                $scope.domainNotificationCount = data.domainCount;
                vm.domainNotificationCount = data.domainCount;
            }
            if (data.systemCount !== null && data.systemCount !== undefined) {
                $scope.systemNotificationCount = data.systemCount;
                vm.systemNotificationCount = data.systemCount;
            }
            vm.userNotificationCountIndicator = vm.userNotificationCount + vm.domainNotificationCount + vm.systemNotificationCount;
            vm.rightItems[0].counter = vm.userNotificationCountIndicator > 99 ? "99+" : vm.userNotificationCountIndicator;
            $scope.$evalAsync();
        }

        function onEventNotification(event, data) {
            var options = {
                body: $(data.Text).text(),
                icon: (stSiteRoot || '/') + 'interface/img/notifications/alert.png',
                timeout: 5000,
                tag: data.Guid
            };
            options.notifyClick = function (e) {
                window.open((stSiteRoot || '/') + "interface/root#/popout/notifications", "notifications", 'resizable=1, ' + popupService.dimensions.notification);
            }
            browserNotifications.show(data.subject, options);
        }

        function stateChanged(trans) {
            var id = trans ? trans.$to().name : $state.current.name;
            angular.forEach([...vm.menu.expanded, ...vm.menu.dropdown], function (item) {
                if (item.sref == id)
                    vm.currentItem = item;
            });
            treeState.hideTreeOverlay();
        }

        function stateSuccess(trans) {
            var id = trans ? trans.$to().name : $state.current.name;
            doCheckItemActive(id);
        }

        function doCheckItemActive(id) {
            var allMenuItems = [...vm.menu.expanded, ...vm.menu.dropdown];

            for (var i = 0; i < allMenuItems.length; ++i) {
                var item = allMenuItems[i];
                item.isActive = false;
                var itemSref = item.parent_sref ? item.parent_sref : item.sref;
                if (itemSref === id || id.indexOf(itemSref) !== -1) {
                   item.isActive = true;
                   $scope.$applyAsync();
                }
            }

            $scope.$applyAsync(recalulateDropdownMenuClassesInsideScope)
        }

        function toggleTree() {
            treeState.toggleTree(true);
            var ham = document.getElementById('hamburger-toggle');
            if (ham)
                ham.blur();
        }

        function logout(event) {
            preferencesStorage.setSortingFilteringParam("system", "doLogout", true);
            AuthenticationService.Logout("top menu bar admin (logout)");
        }

        function openHelp(pagesrc) {
            let page = pagesrc;
            if (!page && $rootScope.$state.current.name && $rootScope.$state.current.name.startsWith("index.reports."))
                page = $rootScope.$state.current.helpid;
            if (!page)
                page = $rootScope.$state.current.name;

            var version = stProductVersion;
            const url = "https://help.smartertools.com/smartermail/current/?page=" + encodeURIComponent(page) + "&v=" + version;
            console.log('NAVIGATING TO HELP PAGE: ' + url);
            window.open(url, "help");
        }

        function openCustomHelp() {
            window.open(vm.customHelpInfo.url, "customHelp");
        }

        function openAbout(ev) {
            $mdDialog.show({
                controller: "aboutSmartermailController",
                controllerAs: "ctrl",
                templateUrl: "app/root/modals/about.dlg.html",
                targetEvent: ev
            })
                .then(function (success) { }, function (cancel) { });
        }

        function refreshNotifications() {
            notifications.getNotificationCount()
                .then(function (data) {
                    $scope.userNotificationCount = data.userCount;
                    vm.userNotificationCount = data.userCount;
                    $scope.domainNotificationCount = data.domainCount;
                    vm.domainNotificationCount = data.domainCount;
                    $scope.systemNotificationCount = data.systemCount;
                    vm.systemNotificationCount = data.systemCount;
                    vm.userNotificationCountIndicator = vm.userNotificationCount + vm.domainNotificationCount + vm.systemNotificationCount;
                    vm.rightItems[0].counter = vm.userNotificationCountIndicator;
                });
        }

        function navTo(sref) {
            $state.go(sref);
            vm.menuVisible = false;
            if (treeState.isExpanded === true && vm.windowWidth < 737) {
                toggleTree();
            }
        }

        function refreshCustomHelp() {
            if (coreData.customHelpInfo && coreData.customHelpInfo.text !== "" && coreData.customHelpInfo.url !== "") {
                vm.customHelpInfo = coreData.customHelpInfo;
            } else {
                vm.customHelpInfo = null;
            }
        }
    }

})();
