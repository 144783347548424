(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("editAutoDiscoverSettingsController", editAutoDiscoverSettingsController);

	function editAutoDiscoverSettingsController($scope, $mdDialog, type, domain, hostname, settings) {
		$scope.displayTitle = type.toUpperCase();
		if ($scope.displayTitle === 'DAV') $scope.displayTitle = 'WEBDAV';

		$scope.title = type.toUpperCase();

		$scope.domain = domain;
		$scope.hostname = hostname;
		$scope.settings = settings;
		$scope.settings.securityLevel = settings.securityLevel >= 1 
											? 1 : settings.securityLevel
		$scope.fakeValue = true;
		$scope.previousSecurityLevel = $scope.settings.securityLevel;
		$scope.portSwitcher = {
			POP: [ 110, 995 ],
			IMAP: [ 143, 993 ],
			SMTP: [ 25, 465, 465 ],
		};
		
		$scope.encryptionChanged = function () {
			if ($scope.portSwitcher[$scope.title][$scope.previousSecurityLevel] == $scope.settings.port)
				$scope.settings.port = $scope.portSwitcher[$scope.title][$scope.settings.securityLevel] 

			$scope.previousSecurityLevel = $scope.settings.securityLevel;
		};

		$scope.close = function () {
			$mdDialog.hide(settings);
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};

	}

})();
