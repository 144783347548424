/**
 * pageTitle - Directive for set Page title - meta title
 */
(function () {

	angular
		.module("smartermail")
		.directive("pageTitle", pageTitle);

	/* @ngInject */
	function pageTitle($rootScope, $transitions) {
		return {
			link: function (scope, element) {
				var listener = function (event) {
					$rootScope.pageTitle = $rootScope.pageTitle ? $rootScope.pageTitle : "";
					setPageTitle($rootScope.pageTitle);
				};

				var countChanged = function () {
					setPageTitle($rootScope.pageTitle);
				};

				var setPageTitle = function (val) {
					var text = val;
					if ($rootScope.unreadEmailCount && $rootScope.unreadEmailCount > 0) {
						text = "(" + $rootScope.unreadEmailCount + ") " + text;
					}
					element.text(text);
				};

				$transitions.onStart({}, listener);
				$rootScope.$on("unreadMailChanged", countChanged);
				$rootScope.$on("pageTitleUpdated", function (ev, title) { $rootScope.pageTitle = title; setPageTitle($rootScope.pageTitle) });
			}
		}
	}
})();
