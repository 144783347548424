(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("mailSigningViewController", mailSigningViewController);

	function mailSigningViewController($http, $mdDialog, $translate, errorHandling, successHandling, settings, mode, preventDelete) {
		var vm = this;
		
		// Properties
		vm.preventDelete = preventDelete;
		vm.makeActiveWhen = 0;

		// Functions
		vm.close = close;
		vm.copyName = copyName;
		vm.copyValue = copyValue;
		vm.delete = deleteRollover;
		vm.onKeySizeChanged = onKeySizeChanged;
		vm.save = save;
		vm.validate = validate;

		activate();

		/////

		function activate() {
				switch (mode.toLowerCase()) {
					case "newrollover":
						vm.isNew = true;
						vm.isRollover = true;
						vm.keySize = settings.keySize || 2048;
						generateRollover();
						break;
					case "viewrollover":
						vm.isNew = false;
						vm.isRollover = true;
						vm.status = settings.status == 0 ? $translate.instant("PENDING") : $translate.instant("FAILED");
						setKeyProperties(settings);
						break;
					default:
						vm.isNew = false;
						vm.isRollover = false;
						vm.status = $translate.instant(settings.enabled ? "ACTIVE" : "DISABLED");
						vm.forced = settings.forced;
						if (settings.forced)
							vm.status += " " + $translate.instant("DNS_VERIFICATION_SKIPPED")
						setKeyProperties(settings);
						break;
				}
		}

		function close() {
			if (vm.isNew) {
				$http.post("~/api/v1/settings/domain/dkim-delete-rollover")
					.then(function () { }, errorHandling.report);
			}

			$mdDialog.cancel();
		}

		function copyName() {
			$("#txtRecordName").select().trigger("focus");
			document.execCommand("copy");
			successHandling.report("COPIED_TO_CLIPBOARD");
		}

		function copyValue() {
			$("#txtRecordValue").select().trigger("focus");
			document.execCommand("copy");
			successHandling.report("COPIED_TO_CLIPBOARD");
		}

		function deleteRollover() {
			$mdDialog.hide({delete: true});
		}

		function generateRollover() {
			// TODO Generate new rollover settings
			$http.post(`~/api/v1/settings/domain/dkim-create-rollover/${vm.keySize}`)
				.then(
					function (success) {
						setKeyProperties(success.data);
					},
					errorHandling.report)
		}

		function onKeySizeChanged() {
			if (!vm.isNew)
				return;
			generateRollover();
		}

		function save() {
			var settings = {
				keySize: vm.keySize,
				selector: vm.selector,
				publicKey: vm.publicKey
			};
			//TODO Initiate a DNS check for the pending rollover. There shouldn't need to be a 
			$mdDialog.hide({ settings: settings, force: vm.makeActiveWhen == 1 });
		}

		function setKeyProperties(props) {
			vm.keySize = props.keySize;
			vm.selector = props.selector;
			vm.txtRecordName = `${props.selector}._domainKey`;
			vm.publicKey = props.publicKey;
			vm.txtRecordValue = `v=DKIM1; k=rsa; h=sha256; p=${props.publicKey}`;
		}

		function validate() {
			$mdDialog.hide({validate: true, force: vm.makeActiveWhen == 1 });
		}
	}
})();