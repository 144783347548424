(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("mailSigningData", mailSigningData);

	/* @ngInject */
	function mailSigningData($http, $q, $timeout) {
		var vm = this;

		// Functions
		vm.getSettings = getSettings;
		vm.saveSettings = saveSettings;
		vm.enableDkim = enableDkim;
		vm.disableDkim = disableDkim;

		activate();

		///////////////////////////

		function activate() {
		}

		function getSettings() {
			var deferred = $q.defer();

			$http.get("~/api/v1/settings/domain/domain")
				.then(function (result) {
					var dk = result.data.domainSettings.domainKeysSettings;
					var settings = {
						canonicalizationBody: dk.dkimCanonicalizationAlgorithmBody,
						canonicalizationHeader: dk.dkimCanonicalizationAlgorithmHeader,
						fieldOption: dk.dkimHeaderFieldOption,
						fields: dk.dkimHeaderFields || "",
						publicKey: dk.publicKey,
						selector: dk.selector,
						keySize: dk.keySize,
						maxMessageSign: dk.maxMessageSign,
						pending: dk.pending,
						isActive: dk.isActive,
						forced: dk.forced
					};
					deferred.resolve(settings);
				}, deferred.reject);

			return deferred.promise;
		}

		function saveSettings(settings) {
			// Calling this function resets DkimToDisabled if the key size was modified
			return $http.post("~/api/v1/settings/domain/dkim-settings", JSON.stringify(settings));
		}

		function enableDkim() {
			var deferred = $q.defer();

			$q
				.all([
					$http.post("~/api/v1/settings/domain/dkim-enable"),
					minTimePromise()
				])
				.then(function (results) {
					deferred.resolve(results[0]);
				}, function (results) {
					deferred.reject(results[0]);
				});

			return deferred.promise;
		}

		function minTimePromise() {
			var deferred = $q.defer();
			$timeout(function () { deferred.resolve(); }, 1000);
			return deferred.promise;
		}

		function disableDkim() {
			return $http.post("~/api/v1/settings/domain/dkim-disable");
		}
	}

})();
