(function () {

    angular
        .module("smartertools")
        .filter('bytes', bytesFilter)
        .filter('bytesCondensed', bytesCondensedFilter);

    function bytesFilter() {
        return function (bytes, precision, textOnZero) {
            if (isNaN(parseFloat(bytes)) || !isFinite(bytes) || bytes == 0) return textOnZero || '0 KB';
            if (typeof precision === 'undefined') precision = 1;
            var units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                number = Math.floor(Math.log(bytes) / Math.log(1024));
            if (bytes < 1)
                return textOnZero;
            if (number === 0)
                return precision > 1 ? (bytes / 1024).toFixed(precision) + ' ' + units[1] : "1 KB";
            else
                return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number];
        };
    }
    function bytesCondensedFilter() {
        return function(bytes, maxDigits, textOnZero) {
            if (isNaN(parseFloat(bytes)) || !isFinite(bytes) || bytes == 0) return textOnZero || '0 KB';
            if (typeof maxDigits === 'undefined' || maxDigits < 3) maxDigits = 3;
            var units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                number = Math.floor(Math.log(bytes) / Math.log(1024));
            if (bytes < 1)
                return textOnZero;
            if (number === 0)
                return (bytes / 1024).toFixed(maxDigits) + ' ' + units[1];
            else {
                const value = (bytes / Math.pow(1024, Math.floor(number)));
                const precision = (value % 1 === 0) ? 0
                    : value >= 100
                    ? maxDigits - 3
                    : value >= 10
                    ? maxDigits - 2
                    : value >= 1
                    ? maxDigits - 1
                    : maxDigits;

                return value.toFixed(precision) + ' ' + units[number];
            }
        }
    }
})();