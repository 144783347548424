(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("userSyncDeviceController", userSyncDeviceController);

	function userSyncDeviceController($scope, $mdDialog, $filter, friendlyName, description, type, ip, rssLink, deviceId, userAgent, lastSync, remoteWipeAllowed, hostname, protocolVersion) {
		$scope.friendlyName = friendlyName;
		$scope.description = description;
		$scope.ip = ip;
		$scope.type = type;
		$scope.rssLink = rssLink;
		$scope.deviceId = deviceId;
		$scope.userAgent = userAgent;
		$scope.lastSync = lastSync ? lastSync.toDate() : undefined;
		$scope.isValid = true;
		$scope.isNameValid = true;
		$scope.isDescValid = true;
		$scope.ignoreWatch = true;
		$scope.lastSyncText = $filter('date')($scope.lastSync, 'medium');
		$scope.remoteWipeAllowed = remoteWipeAllowed;
		$scope.hostname = hostname;
		$scope.protocolVersion = protocolVersion;

		// Functions
		$scope.save = save;
		$scope.remoteWipe = remoteWipe;
		$scope.delete = deleteThis;
		$scope.cancel = cancel;

		activate();

		///////////////////////

		function activate() {
			$scope.typeStr = $filter("translate")(type);

			if ($scope.type === 'ACTIVESYNC') {
				$scope.$watch('friendlyName', function () {
					if (!$scope.ignoreWatch) {
						if ($scope.friendlyName === '') {
							$scope.isNameValid = false;
						} else {
							$scope.isNameValid = true;
							$scope.isValid = true;
						}
					} else {
						$scope.ignoreWatch = false;
					}
				});
			} else if ($scope.type === 'ADD_TO_OUTLOOK' || $scope.type === 'STSSYNC') {
				$scope.$watch('description', function () {
					if (!$scope.ignoreWatch) {
						if ($scope.description === '') {
							$scope.isDescValid = false;
						} else {
							$scope.isDescValid = true;
							$scope.isValid = true;
						}
					} else {
						$scope.ignoreWatch = false;
					}
				});
			}
		}

		function save() {
			if ($scope.friendlyName === undefined && $scope.description === undefined) {
				$mdDialog.cancel();
				return;
			}

			if (($scope.type === 'ACTIVESYNC' && $scope.friendlyName) || $scope.description) {
				$mdDialog.hide({ friendlyName: $scope.friendlyName, description: $scope.description });
				return;
			} 

			if ($scope.friendlyName !== undefined && $scope.type === 'ACTIVESYNC') {
				$scope.isNameValid = false;
				$scope.isValid = false;
			} else if ($scope.description !== undefined && ($scope.type === 'ADD_TO_OUTLOOK' || $scope.type === 'STSSYNC')) {
				$scope.isDescValid = false;
				$scope.isValid = false;
			}
			else
				$mdDialog.cancel();
		}

		function remoteWipe() { $mdDialog.hide({ remoteWipe: true }); }

		function deleteThis() { $mdDialog.hide({ deleteItem: true }); }

		function cancel() { $mdDialog.cancel(); }
	}

})();