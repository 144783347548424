"use strict";

angular.module("smartertools").factory("spinnerFactory", function ($interval) {
	return function (delay) {
		var interval = undefined
		var showSpinner = false;
		var counter = 0;

		if (delay == undefined || delay == null || delay < 0)
			delay = 500;

		return {
			show: function (_delay) {
				if (counter === 0)
					interval = $interval(function () { showSpinner = true; }, _delay || delay, 1);

				counter++;
			},
            showInstant: function () {
                if (counter === 0)
                    interval = $interval(function () { showSpinner = true; }, 1, 1);

                counter++;
            },
			hide: function () {
				if (counter > 0)
					counter--;

				if (counter === 0) {
					$interval.cancel(interval);
					interval = undefined;
					showSpinner = false;
				}
			},
			isShown: function () {
				return showSpinner;
			},
			reset: function () {
				counter = 0;
				this.hide();
			}
		}
	}
});