(function () {

	"use strict";

	angular.module("smartertools").directive("stTimelineNavigator", ["$timeout",
		function ($timeout) {
			return {
				restrict: "E",
				replace: true,
				transclude: true,
				link: function (scope, element, attrs) {
					// Scroll timeline when button is clicked
					scope.scrollTimeline = function (scrollDown) {
						scope.$broadcast('scrollTimeline', { scrollDown: scrollDown });
						cancelAutoScroll();
					}

					// Trigger automatic scrolling when button is held
					var autoScrollInterval;
					var btnScrollPressed;
					$('#btnScrollUp').mousedown(function () {
						cancelAutoScroll();
						$timeout(
							function () {
								if (Date.now() - btnScrollPressed > 249)
									autoScrollInterval = setInterval(function () { scope.$broadcast('scrollTimeline', { scrollDown: false }); }, 125);
							},
							250);
					});
					$('#btnScrollUp').mouseup(function () {
						cancelAutoScroll();
					});
					$('#btnScrollUp').mouseout(function () {
						cancelAutoScroll();
					});
					$('#btnScrollDown').mousedown(function () {
						cancelAutoScroll();
						$timeout(
							function () {
								if (Date.now() - btnScrollPressed > 249)
									autoScrollInterval = setInterval(function () { scope.$broadcast('scrollTimeline', { scrollDown: true }); }, 125);
							},
						250);
					});
					$('#btnScrollDown').mouseup(function () {
						cancelAutoScroll();
					});
					$('#btnScrollDown').mouseout(function () {
						cancelAutoScroll();
					});
					function cancelAutoScroll() {
						btnScrollPressed = Date.now();
						if (autoScrollInterval) clearInterval(autoScrollInterval);
					}
					// Scroll with keyboard
					$(document).keydown(function (e) {
						switch (e.which) {
							case 33: // page up
								scope.$broadcast('scrollTimeline', { scrollDown: false });
								break;

							case 34: // page down
								scope.$broadcast('scrollTimeline', { scrollDown: true });
								break;

							case 38: // up
								scope.$broadcast('nudgeTimeline', { scrollDown: false });
								break;

							case 40: // down
								scope.$broadcast('nudgeTimeline', { scrollDown: true });
								break;

							default: return;
						}
						e.preventDefault();
					});
				},
				template: "<span><button class='btn btn-primary' ng-click='scrollTimeline(false)' id='btnScrollUp'><span class='fa fa-chevron-up'></span></button>\
							<button class='btn btn-primary' ng-click='scrollTimeline(true)' id='btnScrollDown'><span class='fa fa-chevron-down'></span></button></span>"
			};
		}
	]);
})();