/*
 * USE EXAMPLES FOR REMAINING DIRECTIVES - Based on Required Template.html page
 * <st-mobile-menu ng-controller="mainMenuController">
 *      LIST ITEM - USE Example provided in st-mobile-menu-list-item.directive.js
 * </st-mobile-menu>
*/

(function () {
	'use strict';

	angular
		.module('smartertools')
		.directive('stMobileMenu', function () {
			return {
				restrict: "E",
				transclude: true,
				template: '<ul id="st-mobile-menu-list" ng-transclude ng-cloak></ul>',
				controller: stMobileMenuController
			};
		});

	function stMobileMenuController($rootScope, $scope, $timeout, $log) {

	}
})();