(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("domainEditAUPHtmlController", domainEditAUPHtmlController);

	function domainEditAUPHtmlController($scope, $mdDialog, $timeout, body) {
		$scope.body = body;
		$scope.editorScope = {};

		$scope.save = function () {
			const bodyText = $scope.editorScope.getHtml;

			$timeout(function () {
				$mdDialog.hide({
					body: bodyText,
					update: true
				});
			});
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}

})();
