(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("googleExportController", googleExportController);

	function googleExportController($mdDialog, $translate, fileName, mimeType) {
		const exportMap = {
			"application/vnd.google-apps.document": [
				{ display: "MS_WORD", extension: ".docx", type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" },
				{ display: "OPENDOCUMENT", extension: ".odt", type: "application/vnd.oasis.opendocument.text" },
				{ display: "RICH_TEXT", extension: ".rtf", type: "application/rtf" },
				{ display: "PDF", extension: ".pdf", type: "application/pdf" },
				{ display: "PLAIN_TEXT", extension: ".txt", type: "text/plain" },
				{ display: "HTML", extension: ".zip", type: "application/zip" },
				{ display: "EPUB", extension: ".epub", type: "application/epub-zip" }
			],
			"application/vnd.google-apps.spreadsheet": [
				{ display: "MS_EXCEL", extension: ".xlsx", type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
				{ display: "OPENDOCUMENT", extension: ".ods", type: "application/vnd.oasis.opendocument.spreadsheet" },
				{ display: "PDF", extension: ".pdf", type: "application/pdf" },
				{ display: "HTML", extension: ".zip", type: "application/zip" },
				{ display: "CSV", limitText: "FIRST_SHEET_ONLY", extension: ".csv", type: "text/csv" },
				{ display: "TAB_SEPARATED_VALUES", limitText: "FIRST_SHEET_ONLY", extension: ".tsv", type: "text/tab-separated-values" },
			],
			"application/vnd.google-apps.presentation": [
				{ display: "MS_POWERPOINT", extension: ".pptx", type: "application/vnd.openxmlformats-officedocument.presentationml.presentation" },
				{ display: "OPENDOCUMENT", extension: ".odp", type: "application/vnd.oasis.opendocument.presentation" },
				{ display: "PDF", extension: ".pdf", type: "application/pdf" },
				{ display: "PLAIN_TEXT", extension: ".txt", type: "text/plain" },
				{ display: "JPEG", limitText: "FIRST_SLIDE_ONLY", extension: ".jpg", type: "image/jpeg" },
				{ display: "PNG", limitText: "FIRST_SLIDE_ONLY", extension: ".png", type: "image/png" },
				{ display: "SVG", limitText: "FIRST_SLIDE_ONLY", extension: ".svg", type: "image/svg+xml" },
			],
			"application/vnd.google-apps.drawing": [
				{ display: "PDF", extension: ".pdf", type: "application/pdf" },
				{ display: "JPEG", extension: ".jpg", type: "image/jpeg" },
				{ display: "PNG", extension: ".png", type: "image/png" },
				{ display: "SVG", extension: ".svg", type: "image/svg+xml" },
			],
		};

		var vm = this;

		vm.exportOptions = exportMap[mimeType];
		vm.fileName = fileName;
		vm.mimeType = mimeType;

		vm.cancel = cancel;
		vm.export = exportFile;
		vm.getOptionDisplay = getOptionDisplay;

		// Functions

		function cancel() {
			$mdDialog.cancel();
		}

		function exportFile(form) {
			if (form.$invalid || !vm.exportAs)
				return;

			$mdDialog.hide({ mime: vm.exportAs.type, extension: vm.exportAs.extension });
		}

		function getOptionDisplay(option) {
			var displayText = $translate.instant(option.display);
			if (option.limitText)
				displayText += ` (${$translate.instant(option.limitText)})`;
			displayText += ` (${option.extension})`;
			return displayText;
		}
	}
})();