(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("bypassTrustedSenderDialogController", bypassTrustedSenderDialogController);

	function bypassTrustedSenderDialogController($rootScope, $scope, $mdDialog, emailValidationService, bypassedSenders) {
		$scope.textValue = "";
		$scope.bypassedTrustedSenders = bypassedSenders ?? [];
		$scope.bypassedTrustedSenders.sort();
		$scope.textValue = $scope.bypassedTrustedSenders.join("\n");
		$scope.invalidEmails = [];

		$scope.save = function () {
			var arr = $scope.textValue.split(/\r?\n/);
			angular.forEach(arr, function (text) {
				text.replace('\r', '');
				text.replace('\n', '');
			});
			$mdDialog.hide(arr);
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};

		$scope.getInvalidEmails = function (userForm) {
			$scope.invalidEmails = [];
			$scope.textValue = userForm.addresses.$viewValue;

			var emails = $scope.textValue.split("\n");

			for (var i = 0; i < emails.length; i++) {
				if (!emails[i] && i == emails.length - 1) continue;
				if (!emailValidationService.isValidEmail(emails[i]))
					$scope.invalidEmails.push({ invalidEmailIndex: i, emailToFix: emails[i] });
			}
		}

		$scope.fixInvalidEmail = function (userForm, email) {
			var emails = $scope.textValue.split("\n");

			emails[email.invalidEmailIndex] = email.emailToFix;

			$scope.textValue = emails.join("\n");
			userForm.addresses.$viewValue = $scope.textValue;
			userForm.addresses.$modelValue = $scope.textValue;
			userForm.addresses.$commitViewValue();
			userForm.addresses.$rollbackViewValue();

			$scope.getInvalidEmails(userForm);
		}

		$scope.removeInvalidEmail = function (userForm, email) {
			var emails = $scope.textValue.split("\n");

			emails.splice(email.invalidEmailIndex, 1);

			$scope.textValue = emails.join("\n");
			userForm.addresses.$viewValue = $scope.textValue;
			userForm.addresses.$modelValue = $scope.textValue;
			userForm.addresses.$commitViewValue();
			userForm.addresses.$rollbackViewValue();

			$scope.getInvalidEmails(userForm);
		}
	}

})();
