(function () {
	"use strict";

	angular
		.module("smartermail")
		.factory("authInterceptor", authInterceptor);

	function authInterceptor($rootScope, $location, $injector, $q, $filter, $timeout, $window, $state, authStorage, claimsService, toaster) {
		var refreshTokenDefer = null;
		var waitingPromises = [];
		var API = stSiteRoot + "api/v1";
		var isUnloading = false;
		var updatingTokenTimeout = null;

		$(window).on("beforeunload", function () {
			isUnloading = true;
		});

		$rootScope.$on("destroy", function () {
			$(window).unbind("offline");
			$(window).off("beforeunload");
		});

		return {
			request: request,
			response: response,
			responseError: responseError
		}

		function request(config) {
			var publicPages = ["/login", "/interface/app/sections/setup-wizard/setup-wizard.html", "/disconnected"];
			var isRestrictedPage = publicPages.indexOf(config.url) === -1 && config.url.indexOf("/translations/") === -1 && config.url.indexOf("/Interface/output/") === -1;
			if (!isRestrictedPage) {
				config.headers.Authorization = `Bearer ${authStorage.getToken()}`;
				return $q.when(config);
			}

			if (!navigator.onLine) {
				if (window.location.hostname !== "localhost" &&
					window.location.hostname !== "127.0.0.1" &&
					!window.location.hostname.startsWith("10.") &&
					!window.location.hostname.startsWith("192.168.")) {
					console.log("Not connected to the internet, performing logout...");
					$location.path("/disconnected");
					return $q.reject(config);
				}
			}

			var secondaryAuthDomain = $rootScope.secondaryImpersonationDomain;
			if (secondaryAuthDomain && $state && $state.$current && $state.$current.name && $state.$current.name.toLowerCase().startsWith("index.sysadmin-manage.dashboard.domain-specific"))
                config.headers["X-SmarterMailDomain"] = secondaryAuthDomain;


			// Check to see if we are trying to reset the user's password
			var searchObject = $location.search();
			var passwordResetId = searchObject["id"];
			if (passwordResetId != undefined) {
				authStorage.savePasswordResetId(passwordResetId);
				$location.search("id", null);
				claimsService.deleteClaims();
				authStorage.deleteAllExceptPasswordResetInfo("interceptor.service request");
			}

			var token = authStorage.getToken(claimsService.isSysAdmin());
			if (!token) {
				return $q.when(config);
			} else {
				if (config.url !== "app/authentication/login.html" && config.url.indexOf(API) > -1 && config.url.indexOf("refresh-token") === -1) {
					var now = new Date().getTime();
					var accessTokenExpiration = authStorage.getAccessTokenExpiration()
					var expireConverted = new Date(accessTokenExpiration);
					var accessTokenExpirationBuffer = 60000;
					var expires = expireConverted.getTime() - accessTokenExpirationBuffer;
					if (now > expires && config.url.indexOf("auth/logout-user") === -1) {
						// refresh needed
						var defer = $q.defer();
						waitingPromises.push(function (token) {
							config.headers.Authorization = `Bearer ${token}`;
							defer.resolve(config);
						});

						// This is being called
						if (!refreshTokenDefer) {
							if (authStorage.isTokenCurrentlyUpdating()) {
								if (!updatingTokenTimeout) {
									updatingTokenTimeout = $timeout(someTimeout, 20);
								}

								function someTimeout() {
									if (authStorage.isTokenCurrentlyUpdating()) {
										updatingTokenTimeout = $timeout(someTimeout, 20);
									} else {
										updatingTokenTimeout = undefined;
										refreshTokenDefer = null;
										var resolvedToken = authStorage.getToken();
										waitingPromises.forEach(function (readyToResolve) {
											readyToResolve(resolvedToken);
										});
									}
									return defer.promise;
								}
							} else {
								authStorage.updatingToken(true);

								var refreshToken = authStorage.getRefreshToken();
								console.debug("Refreshing token", waitingPromises.length);
								var http = $injector.get("$http");

								var clientId = authStorage.getClientId();
								refreshTokenDefer =
									http
									.post(stSiteRoot + "api/v1/auth/refresh-token", { token: refreshToken, iswebmailrefresh: true, clientId: clientId })
										.then(
											function () {
												refreshTokenDefer = null;
												console.debug("Token refreshed.", waitingPromises.length);
												waitingPromises.forEach(function (readyToResolve) {
													readyToResolve(authStorage.getToken());
												});
											}, function () {
												authStorage.updatingToken(false);
												refreshTokenDefer = null;
												waitingPromises = [];
												defer.reject(config);
											});
							}
						}
						return defer.promise;
					} else {
						// no refresh needed
						config.headers.Authorization = "Bearer " + token;
						return $q.when(config);
					}
				} else {
					config.headers.Authorization = "Bearer " + authStorage.getToken(claimsService.isSysAdmin());
					return $q.when(config);
				}
			}
		}

		function response(res) {
			if (res.config.url.indexOf(API) > -1) {
				if (res.data.accessToken) {
					claimsService.setClaims(res.data.accessToken);
					if (res.data.refreshToken) {
						authStorage.saveToken(res.data, false, "intercepter.service.response()");
					}
				} else if (res.data.impersonateAccessToken && res.data.impersonateRefreshToken) {
					authStorage.saveImpersonateToken(res.data, true);
				} 
			}
			return res;
		}

		function responseError(rejection) {
			var forwardToLoginPage = false;
			if (window.location.href.indexOf("popout/email") != -1 ||
				window.location.href.indexOf("popout/contact") != -1 ||
				window.location.href.indexOf("popout/appointment") != -1 ||
				window.location.href.indexOf("popout/task") != -1 ||
				window.location.href.indexOf("popout/note") != -1) {
				forwardToLoginPage = false;
				return $q.reject(rejection);
			} else {
				switch (rejection.status) {
					case -1: //If the user has lost internet connection.
						if (rejection.config && rejection.config.timeout && rejection.config.timeout.isResolved) {
							return $q.reject(rejection);
						}
						if (!isUnloading) {
							$rootScope.$emit("Interceptor:ServiceDown", rejection.data ? rejection.data.message : "NO_INTERNET");
							forwardToLoginPage = true;
						} else {
							return $q.reject(rejection);
						}
						break;
					case 401:
						if (rejection.config.url.indexOf(API) > -1) {
							forwardToLoginPage = true;
							authStorage.updatingToken(false);
						}
						break;
					case 403:
						forwardToLoginPage = true;
						break;
					case 400:
						var remotingError1 = "No connection could be made because the target machine actively refused it";
						var remotingError2 = "An existing connection was forcibly closed by the remote host";
						if (rejection.data.__proto__ === ArrayBuffer.prototype) {
							var enc = new TextDecoder();
							rejection.data = JSON.parse(enc.decode(rejection.data));
						}
						if (rejection.data.message.indexOf(remotingError1) > -1 ||
							rejection.data.message.indexOf(remotingError2) > -1) {
							$rootScope.$emit("Interceptor:ServiceDown", rejection.data.message);
							forwardToLoginPage = true;
						} else {
							return $q.reject(rejection);
						}
						break;
					case 500:
						var wcfServerDown = "EndpointNotFoundException";
						var ServerDown2 = "cannot be used for communication because it is in the Faulted state"
						var errorHasOccured = "An error has occurred";
						if (rejection.data.message && (rejection.data.message.indexOf(wcfServerDown) > -1 ||
							rejection.data.message.indexOf(ServerDown2) > -1)) {
							$rootScope.$emit("Interceptor:ServiceDown", rejection.data.message);
							forwardToLoginPage = true;
						} else if (rejection.data.message && rejection.data.message.indexOf(errorHasOccured) > -1) {
							rejection.data.message = $filter("translate")("ERROR_HAS_OCCURRED");
							return $q.reject(rejection);
						} else {
							return $q.reject(rejection);
						}
						break;
					case 503:
						$rootScope.$emit("Interceptor:ServiceDown", rejection.data.message);
						forwardToLoginPage = true;
						break;
					default:
						return $q.reject(rejection);
				}
			}

			if (forwardToLoginPage == true && !window.redirectingtoExternalSite) {
				var authService = $injector.get("AuthenticationService");
				var refreshBrowser = false;
				if (rejection.data && rejection.data.username) {
					refreshBrowser = authService.doLogout("interceptor (responseError)", true, rejection.data.username);
				}
				else {
					refreshBrowser = authService.doLogout("interceptor (responseError)", true);
				}
				var configUrl = rejection.config.url.indexOf("auth/authenticate-user");
				var onLoginPage = $window.location.hash.startsWith("#/login");
				if (refreshBrowser && configUrl == -1 && !onLoginPage) {
					//$window.location.reload();
					$window.location.href = stSiteRoot + "interface/root";
					$rootScope.$destroy();
					return $q.when();
				}

				return $q.reject(rejection);
			}
		}

	}
})();
