(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("renameNoteFolderController", renameNoteFolderController);

    function renameNoteFolderController($scope, $mdDialog, $filter, $http, source, errorHandling) {
        var vm = this;
        vm.name = source.displayName ? $filter("folderTranslate")(source.displayName, vm.owner) : source.name;
        vm.id = source.id || source.itemID;

        // Functions
        vm.save = save;
        vm.cancel = cancel;

        activate();

        //////////////////

        function activate() {
        }

        function cancel() {
            $mdDialog.cancel();
        }

        async function save(ev, noteFolderForm) {
            if (vm.name === "")
                return;
            if (noteFolderForm.$invalid)
                return;

            try {
                var folder = { folder: vm.name, uid: vm.id };
                await $http.post('~/api/v1/notes/sources/edit', folder);
                $mdDialog.hide();
            }
            catch (err) {
                errorHandling.report(err);
            }
        }
    }
})();
