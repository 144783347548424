(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("userTimeService", userTimeService);

	// Initialized by CoreDataSettings
	function userTimeService($q, $translate, userDataService,
		coreDataSettings) {
		var vm = this;
		vm.initialized = false;
		vm.userTimeZone;
		vm.availableTimeZones = [];

		vm.init = init;
		vm.convertLocalToUserTime = convertLocalToUserTimeDeferred;
		vm.convertLocalToUserTimeMoment = convertLocalToUserTimeMoment;
		vm.convertLocalToGivenTime = convertLocalToGivenTime;
		vm.convertLocalToUserTimeBasic = convertLocalToUserTime;

		let initDefer = null;

		function init() {
			if (initDefer)
				return initDefer.promise;

			initDefer = $q.defer();

			// User data service needs to be ready before we try to figure out what the active time zone is
			userDataService.init().then(continueInit, continueInit);

			function continueInit() {
				loadTimeZones()
					.then(function () {
						vm.userTimeZone = $.grep(vm.availableTimeZones, function (tz) { return tz.index === coreDataSettings.userSettings.timeZoneIndex; })[0];
						if (userDataService.user.isSysAdmin) {
							vm.userTimeZone = $.grep(vm.availableTimeZones, function (tz) { return tz.index === userDataService.admin.systemTimeZoneIndex; })[0];
						}
						if (!vm.userTimeZone) {
							var currentOffset = moment().utcOffset();
							vm.userTimeZone = $.grep(vm.availableTimeZones, function (tz) { return tz.currentMinuteOffset === currentOffset; })[0];
						}
						vm.initialized = true;

						initDefer.resolve();
					}, function (failure) {
						errorHandling.report(failure);

						initDefer.reject();
					})
					.finally(function () { initDefer = null; });
			}

			return initDefer.promise;
		};

		function loadTimeZones() {
			var defer = $q.defer();
			coreDataSettings.settingsData.availiableTimeZones
				.then(function (success) {
					vm.availableTimeZones = success;
					for (var i = 0; i < vm.availableTimeZones.length; ++i) {
						vm.availableTimeZones[i].translated = $translate.instant(vm.availableTimeZones[i].displayName);
					}
					defer.resolve();
				}, function (failure) {
					errorHandling.report(failure);
					defer.reject();
				});
			return defer.promise;
		}

		function convertLocalToUserTimeMoment(time) {
			return moment(convertLocalToUserTime(time));
		}

		function convertLocalToUserTimeDeferred(time) {
			if (vm.initialized)
				return $.when(convertLocalToUserTime(time));

			var defer = $q.defer();
			init()
				.then(function () {
					defer.resolve(convertLocalToUserTime(time));
				},
				function () {
					defer.reject();
				});

			return defer.promise;
		}

		function convertLocalToUserTime(time) {
			var momentTime = moment(time);
			var jsDate = momentTime.toDate();
			var utc = jsDate.getTime() + (jsDate.getTimezoneOffset() * 60000);
			var offset = moment.tz(momentTime, vm.userTimeZone.location).utcOffset();
			return new Date(utc + (offset * 60000));
		}

		function convertLocalToGivenTime(momentTime, isAllDay) {
			var jsDate = momentTime.toDate();
			var utc = jsDate.getTime() + (jsDate.getTimezoneOffset() * 60000);
			var offset = momentTime.utcOffset();
			if (isAllDay)
				offset *= -1;
			return new Date(utc + (offset * 60000));
		}
		vm.convertUserTimeToLocal = (userTime) => {
			const momentTime = moment.tz(userTime, vm.userTimeZone.location);
			const jsDate = momentTime.toDate();
			const utc = jsDate.getTime() - (momentTime.utcOffset() * 60000);
			return new Date(utc - (new Date().getTimezoneOffset() * 60000));
		}
	}
})();
