(function () {
	"use strict";

	angular
		.module("smartertools")
		.directive("validateUsernameNoDomain", validateUsernameNoDomain);

	function validateUsernameNoDomain() {
		return {
			require: "ngModel",
			link: function (scope, element, attrs, ctrl) {
				// NOTE: If this validator changes also update validate-alias-name.directive.js
				ctrl.$validators.validateUsernameNoDomain = function (modelValue, viewValue) {
					viewValue = viewValue ? viewValue.trim() : viewValue;
					if (!viewValue)
						return true;
					if (viewValue.indexOf("@") > -1)
						return false;
					return true;
				};
			}
		};
	}
})();