(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("contactsOutlookDialogController", contactsOutlookDialogController);

	function contactsOutlookDialogController($scope, $mdDialog, $filter, coreDataContacts) {
		$scope.outlookVersionOptions = [];
		$scope.contacts = coreDataContacts.getSources().filter(folder => !folder.isDomainResource);
		$scope.currentContact = $scope.contacts[0];

		// Functions
		$scope.done = done;
		$scope.cancel = cancel;

		activate();

		/////////////////

		function activate() {
			var outlookOptions = [];
			outlookOptions.push({ version: "3.0", name: $filter("translate")("OUTLOOK_2007_OR_LATER") });
			outlookOptions.push({ version: "1.0", name: $filter("translate")("OUTLOOK_2003") });
			$scope.outlookVersionOptions = outlookOptions;
			$scope.currentVersion = $scope.outlookVersionOptions[0];
		}

		function getMetaData() {
			return {
				"outlookVersion": $scope.currentVersion.version,
				"contacts": $scope.currentContact,
				"displayName": $scope.displayName
			};
		}

		function done() {
			var result = getMetaData();
			$mdDialog.hide(result);
		};

		function cancel() {
			$mdDialog.cancel();
		};
	};
})();