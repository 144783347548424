(function () {
    "use strict";

    angular
        .module('smartermail')
        .component('messageViewArchive', {
            templateUrl: "app/email/archive/view-archive.component.html",
            controller: messageViewArchive,
        });

    /* @ngInject */
    function messageViewArchive($rootScope, $scope, $stateParams, $timeout, $element, $mdDialog, $translate, emailFunctions, emailArchiveFunctions, emailNavigation,
        spinnerFactory, errorHandling, successHandling, userTimeService, meetingInfoData) {

        var vm = this;
        var popoutPacket = emailNavigation.parsePopoutPacket($stateParams.packet);
        vm.loading = true;
        vm.message = null;
        vm.isQuarantineMessage = !!popoutPacket.quarantine;
        vm.iFrameLoaded = false;
        vm.messageDoesNotExist = false;
        vm.mode = popoutPacket.mode || 'html';
        vm.addressShowLimit = 2;
        vm.showAllToAddresses = false;
        vm.showAllCcAddresses = false;
        vm.showAllBccAddresses = false;

        // Functions
        vm.closeWindow = closeWindow;
        vm.onDownloadRaw = onDownloadRaw;
        vm.viewAsClicked = viewAsClicked;
        vm.showAttachmentsModal = showAttachmentsModal;
        vm.copyContentToClipboard = copyContentToClipboard;
        vm.copyArchiveMessageToMailbox = copyArchiveMessageToMailbox;
        vm.toggleShowMoreBcc = () => vm.showAllBccAddresses = !vm.showAllBccAddresses;
        vm.toggleShowMoreCc = () => vm.showAllCcAddresses = !vm.showAllCcAddresses;
        vm.toggleShowMoreTo = () => vm.showAllToAddresses = !vm.showAllToAddresses;
        vm.needsComma = needsComma;

        /////////////////

        vm.$onInit = function () {
            $(document).on("keydown", ListenPrintHotkey);
            $element.addClass('message-view-archive');
            userTimeService.init().then(function() {
                init();
            });
        };

        vm.$onDestroy = function () {
            $(document).off("keydown", ListenPrintHotkey);
        };

        async function init() {
            const spinner = $rootScope.spinner || new spinnerFactory();

            try {
                spinner.show();
                vm.loading = true;

                const loadedMessage = await emailArchiveFunctions.loadMessage(popoutPacket.searchGuid, popoutPacket.uid);
                const noTextContent = '['+$translate.instant('MESSAGE_HAS_NO_TEXT_CONTENT')+']';
                const noHtmlContent = '[' + $translate.instant('MESSAGE_HAS_NO_HTML_CONTENT') + ']';

                vm.messageDoesNotExist = !loadedMessage;

                if (loadedMessage) {
                    vm.message = loadedMessage;

                    vm.message.raw = loadedMessage.raw;
                    let originalPlainText = loadedMessage.messagePlainText;
                    vm.message.messagePlainText = loadedMessage.messagePlainText || noTextContent;
                    if (loadedMessage.messageHTML) {
                        vm.message.messageHTML = loadedMessage.messageHTML;
                    } else {
                        const regexPlainLines = /\r\n|\n/gm;
                        const plainConverted = $("<div>").text(originalPlainText || noHtmlContent).html();
                        vm.message.messageHTML = plainConverted.replace(regexPlainLines, `<br>`);
                    }

                    vm.message.date = new Date(loadedMessage.date);

                    // TODO: Filter Trackers and these items
                    // vm.activeTrackersCount = XXX
                    vm.hadUnsafeCode = loadedMessage.hadUnsafeCode;
                    vm.htmlIsStillUnsafe = loadedMessage.htmlIsStillUnsafe;
                    vm.imagesBlocked = loadedMessage.imagesBlocked;
                    vm.cleansedFromAddress = loadedMessage.cleansedFromAddress;

                    //Convert meeting invite reminder time
                    if (vm.message.meetingInfo) {
	                    meetingInfoData.loadMeetingInfo(vm.message.meetingInfo);
	                    vm.message.meetingInfo.prop = meetingInfoData.prop;
	                    vm.message.messagePlainText = vm.message.meetingInfo.raw;
	                    vm.message.raw = vm.message.meetingInfo.raw;
                    }

					if (vm.message && vm.message.attachments.length > 1) {
						let totalSize = 0;
						for (let i = 0; i < vm.message.attachments.length; i++)
							totalSize += vm.message.attachments[i].size;
						vm.message.attachments.push({
							allAttachments: true,
							filename: $translate.instant("FILENAME_ALL_ATTACHMENTS"),
							size: totalSize,
							link: vm.message.allAttachmentsLink
						});
					}

					if (vm.message && vm.message.attachments.length > 1) {
						let totalSize = 0;
						for (let i = 0; i < vm.message.attachments.length; i++)
							totalSize += vm.message.attachments[i].size;
						vm.message.attachments.push({
							allAttachments: true,
							filename: $translate.instant("FILENAME_ALL_ATTACHMENTS"),
							size: totalSize,
							link: vm.message.allAttachmentsLink
						});
					}

                    userTimeService.convertLocalToUserTime(vm.message.date)
                        .then(function (success) {
                            if (success.getSeconds() >= 30) {
                                success.setMinutes(success.getMinutes() + 1);
                            }
                            success.setSeconds(0);
                            vm.message.userDate = success;
                            vm.message.userDataMoment = moment(success).calendar();
                        }, function () { });

                    loadIframe(vm.message, vm.mode);
                }
            } catch (err) {
                vm.messageDoesNotExist = true;
                errorHandling.report();
            } finally {
                vm.loading = false;
                spinner.hide();
            }

            $scope.$applyAsync(function () { });
        }

        function closeWindow() {
            window.close();
        }

        function ListenPrintHotkey(event) {
            if (event.keyCode == 80 && (event.metaKey || event.ctrlKey)) { //ctrl + p
                event.preventDefault();
                vm.message.mode = vm.mode;
                emailFunctions.showPrintPopup(vm.message);
            }
        }

        function needsComma(index, totalLength) {
            // Trying to do this inline on the HTML causes one of the GULP utilities to choke on the less then operator.
            return index < (totalLength - 1);
        }

        async function onDownloadRaw() {
            try {
                await emailArchiveFunctions.downloadEmlFile(popoutPacket.searchGuid, popoutPacket.uid);
            } catch (err) {
                errorHandling.report(err);
            }
        }

        async function viewAsClicked(mode) {
            try {
                vm.mode = mode;

                // If they're requesting raw and we don't have it, load it
                if (mode === 'raw' && !vm.message.raw) {
                    vm.message.raw = await emailArchiveFunctions.loadRawContent(popoutPacket.searchGuid, popoutPacket.uid);
                }
	            loadIframe(vm.message, vm.mode);
            } catch (err) {
                errorHandling.report(err);
            }
        }

        function loadIframe(contents, mode) {
            // TODO: Ban trackers
            //if (vm.message && vm.message.foundTrackers && messageData.message.foundTrackers.length > 0) {
            //    for (var i = 0; i < messageData.message.foundTrackers.length; i++) {
            //        var ft = messageData.message.foundTrackers[i];
            //        contents.messageHTML = emailTracker.replaceTracker(contents.messageHTML, ft.id);
            //    }
            //}

            var iframe = document.getElementById('archiveViewFrame');
            if (iframe && iframe.contentWindow && iframe.contentWindow.setHTML) {
                contents.translations = contents.translations || {};
                contents.translations.MESSAGE_HAS_NO_TEXT_CONTENT = $translate.instant('MESSAGE_HAS_NO_TEXT_CONTENT');
                contents.translations.MESSAGE_HAS_NO_HTML_CONTENT = $translate.instant('MESSAGE_HAS_NO_HTML_CONTENT');
                contents.translations.MESSAGE_ENCRYPTED_CANNOT_VIEW = $translate.instant('MESSAGE_ENCRYPTED_CANNOT_VIEW');
                contents.translations.MESSAGE_SIGNED = $translate.instant('MESSAGE_SIGNED');
                iframe.contentWindow.setHTML(contents, mode);
                $scope.$applyAsync(() => vm.iFrameLoaded = true);
            } else {
                $timeout(function () { loadIframe(contents, mode); }, 200);
            }
        }

        async function showAttachmentsModal() {
            try {
                await $mdDialog.show({
                    locals: { message: vm.message },
                    controller: "emailAttachmentsController",
                    templateUrl: 'app/email/modals/attachments.dlg.html',
                    clickOutsideToClose: true
                });
            } catch (e) {
                // We can ignore when the user closes the modal
            }
        }

        async function copyContentToClipboard() {
            try {
                let text = "";
                switch (vm.mode) {
                    case 'text': text = vm.message.messagePlainText; break;
                    case 'raw': text = vm.message.raw; break;
                    default: throw 'View type cannot be copied to clipboard';
                }

                navigator.clipboard.writeText(text);
                successHandling.report("COPIED_TO_CLIPBOARD");
            } catch (err) {
                errorHandling.report(err);
            }
        }

        async function copyArchiveMessageToMailbox(ev) {
            try {
                const wasCopied = await emailArchiveFunctions.copyToMailbox(popoutPacket.searchGuid, popoutPacket.uid, ev);
                if (wasCopied)
                    successHandling.report();
            } catch (err) {
                errorHandling.report(err);
            }
        }

    }

})();
