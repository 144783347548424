(function () {
	"use strict";

	angular
		.module("smartertools")
		.controller("ipRestrictionModalController", ipRestrictionModalController);

	function ipRestrictionModalController($scope, $mdDialog, info) {
		$scope.isValid = true;
		$scope.isNew = info === null;
		$scope.info = $.extend({}, info || {});
		$scope.description = $scope.info.description || "";
		$scope.address = $scope.info.address || "";

	    // Functions
		$scope.save = save;
		$scope.delete = deleteThis;
		$scope.cancel = cancel;

		activate();

	    /////////////////////

		function activate() {
		}
		
		function save() {
			if ($scope.ipRestrictionForm.$valid) {
				var info = {
					description: $scope.description,
					address: $scope.address,
				};
				$mdDialog.hide({
					info: info
				});
			} else {
				$scope.isValid = false;
			}
		};

		function deleteThis() {
			$mdDialog.hide({
				info: null
			});
		};

		function cancel() {
			$mdDialog.cancel();
		};
	}
})();