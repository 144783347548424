(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminImportSecuritySettingsController", sysAdminImportSecuritySettingsController);

	function sysAdminImportSecuritySettingsController($scope, $mdDialog, $filter, errorHandling, successHandling, titleText) {
	    
		var _this = this;
        $scope.titleText = titleText;

		this.fileCount = 0;
		$scope.uploaderInit = function (publicFunctions) {
		    $scope.uploaderFunctions = publicFunctions;
		}


		$scope.save = function () {
			if (!($scope.isValid = _this.fileCount > 0))
				return;

			$scope.uploaderFunctions.startUpload();
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};

		$scope.importFile = function (file, data) {
			successHandling.report();
			$mdDialog.hide({
			    //spamSettings: data.spamSettings,
			    //globalMailSettings: data.globalMailSettings
			});
		};

		$scope.uploadFailed = function () {
			errorHandling.report("ERROR_UPLOADING");
		    $scope.step = 1;
		    $scope.uploaderFunctions.reset();
		}

		$scope.addedFile = function () {
			$scope.isValid = true;
			_this.fileCount++;
			$scope.$applyAsync();
		};

		$scope.removedFile = function () {
			_this.fileCount--;
			if (_this.fileCount < 0)
				_this.fileCount = 0;

			if (_this.fileCount === 0)
				$scope.isValid = false;

			$scope.$applyAsync();
		};

		/// Validation
		$scope.isValid = false;
	}

})();
