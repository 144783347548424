/*
 * Use Examples:
 * Day Only: <st-date-picker id="datePicker" st-model="birthdate" day-only="true" st-placeholder="BIRTHDAY" st-disabled="ctrl.isDisabled(contactInfo)"></st-date-picker>
 * Time Only: <st-date-picker id="timePickerStart" st-model="startTime" time-only="true" st-required="true"></st-date-picker>
 */

(function () {
	"use strict";

	angular.module("smartertools").directive("stDatePicker", ["$compile", "$rootScope", "browserSupport",
		function ($compile, $rootScope) {
			return {
				restrict: "E",
				scope: {
					stModel: "=",
					stMinDate: "=",
					stMaxDate: "=",
					dayOnly: "=",
					timeOnly: "=",
					stDisabled: "=",
					stPlaceholder: "@",
					stPlaceholderTime: "@",
					stOpenOnClick: "=",
					stRequired: "=",
					stDateFormat: "=",
					stTimeFormat: "=",
					allowEmpty: "=?",
					debounceInterval: "=?"
				},
				link: function (scope, element, attr) {
					scope.stPlaceholderTime = (scope.stPlaceholderTime === undefined || scope.stPlaceholderTime === null)
						? scope.stPlaceholder
						: scope.stPlaceholderTime;

					if (!scope.allowEmpty)
						scope.allowEmpty = false;
					if (!scope.debounceInterval)
						scope.debounceInterval = 500;
					var localDateFormat = " ";
					var localTimeFormat = " ";
					if ((!$rootScope.browserCaps || !$rootScope.browserCaps.supportsDateInput) && moment) {
						localDateFormat = " mdp-format=L";
						localTimeFormat = " mdp-format=LT";
					}

					scope.tempModel = null;
					var edgeVersion = -1;
					if (!$rootScope.browserCaps || !$rootScope.browserCaps.edgeVersion) {
						var indexOfEdge = navigator.userAgent.indexOf("Edge");
						if (indexOfEdge !== -1) {
							var tempString = navigator.userAgent.substring(indexOfEdge, indexOfEdge + 8);
							if (tempString.indexOf("Edge/") !== -1) {
								tempString = tempString.replace(".", "");
								var split = tempString.split("/");
								edgeVersion = Number(split[1]);
							}
						}
					} else {
						edgeVersion = $rootScope.browserCaps.edgeVersion;
					}

					var minDate = scope.stMinDate ? ' mdp-min-date="$parent.stMinDate" ' : "";
					var maxDate = scope.stMaxDate ? ' mdp-max-date="$parent.stMaxDate" ' : "";
					var model = edgeVersion >= 16 ? "$parent.tempModal" : "$parent.stModel";
					var openOnClick = scope.stOpenOnClick ? " mdp-open-on-click " : "";
					var dateFormat = scope.stDateFormat ? " mdp-format=" + scope.stDateFormat + " " : localDateFormat;
					var timeFormat = scope.stTimeFormat ? " mdp-format=" + scope.stTimeFormat + " " : localTimeFormat;
					var required = scope.stRequired ? " required " : "";
					var dateInputDisabled = localDateFormat !== " " ? " input-disabled=true" : "";
					var timeInputDisabled = localTimeFormat !== " " ? " input-disabled=true" : "";

					var elementToBeAdded = angular.element(
						'<div class="st-datetime-picker">\
                            <mdp-date-picker ng-model-options="{ updateOn: \'default blur\', debounce: { \'blur\': 0, \'default\': '+ scope.debounceInterval +' } }" ng-if="!timeOnly"' + minDate + maxDate +
								'mdp-placeholder="{{$parent.stPlaceholder | translate}}" mdp-disabled="$parent.stDisabled" ng-model="' + model + '" ng-class="{\'date-day-only\': $parent.dayOnly}" ' +
								openOnClick + dateFormat + required + dateInputDisabled + ' allow-empty="' + scope.allowEmpty + '"' + '>\
							</mdp-date-picker>\
							<mdp-time-picker ng-model-options="{ updateOn: \'default blur\', debounce: { \'blur\': 0, \'default\': '+ scope.debounceInterval +' } }" ng-if="!dayOnly"\
								mdp-placeholder="{{$parent.stPlaceholderTime | translate}}" mdp-disabled="$parent.stDisabled" ng-model="' + model + '"' +
								timeFormat + required + timeInputDisabled + ' allow-empty="' + scope.allowEmpty + '"' + ">\
							</mdp-time-picker>\
                        </div>"
					);
					var elementCompiled = $compile(elementToBeAdded)(scope);
					element.append(elementCompiled);

					if (edgeVersion >= 16) {
						setTimeout(function () {
							scope.tempModel = scope.stModel;
							scope.$applyAsync();
						}, 1500);

						scope.$watch("tempModel", function () {
							scope.stModel = scope.tempModel;
						});

						scope.$on("DateModelReset", function () {
							scope.tempModel = scope.stModel;
							scope.$applyAsync();
						});
					}
				}
			}
		}
	]);
})();


















































/*
* Change - moved the t-rex to here
*
                                                  ____
       ___                                      .-~. /_"-._
      `-._~-.                                  / /_ "~o\  :Y
          \  \                                / : \~x.  ` ')
           ]  Y                              /  |  Y< ~-.__j
          /   !                        _.--~T : l  l<  /.-~
         /   /                 ____.--~ .   ` l /~\ \<|Y
        /   /             .-~~"        /| .    ',-~\ \L|
       /   /             /     .^   \ Y~Y \.^>/l_   "--'
      /   Y           .-"(  .  l__  j_j l_/ /~_.-~    .
     Y    l          /    \  )    ~~~." / `/"~ / \.__/l_
     |     \     _.-"      ~-{__     l  :  l._Z~-.___.--~
     |      ~---~           /   ~~"---\_  ' __[>
     l  .                _.^   ___     _>-y~
      \  \     .      .-~   .-~   ~>--"  /
       \  ~---"            /     ./  _.-'
        "-.,_____.,_  _.--~\     _.-~
                    ~~     (   _}       -Row
                           `. ~(
                             )  \
                            /,`--'~\--'~\
                  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
                             ->T-Rex<-
*/