(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sharedAddSubscriberController", sharedAddSubscriberController);

	function sharedAddSubscriberController($scope, $mdDialog, $q, $http, $state, emailValidationService,
		coreDataDomainSettings, id, email, newItem, successHandling, errorHandling) {
		var vm = this;
		vm.mailingListId = $state.params.id;

		$scope.isDomain = $state.params.type === 'domain';
		$scope.newItem = newItem;
		$scope.subscriber = {};
		$scope.mailingLists = [];
		$scope.selectedTab = 0;
		$scope.subscriberId = id;
		$scope.subscriberEmail = email;

		// Functions
		$scope.save = save;
		$scope.cancel = cancel;
		$scope.resetBounces = resetBounces;
		$scope.openMessage = openMessage;
		$scope.getInvalidEmails = getInvalidEmails;
		$scope.fixInvalidEmail = fixInvalidEmail;
		$scope.removeInvalidEmail = removeInvalidEmail;

		activate();

		///////////////////////

		function activate() {
			loadSubscriber();
		}

		function save() {
			$scope.subscriber.subscribedLists = [];
			angular.forEach($scope.subscriber.subscribedListObject, function (value, key) { if (value) $scope.subscriber.subscribedLists.push(parseInt(key)); });

			$scope.subscriber.subscribedDigests = [];
			angular.forEach($scope.subscriber.subscribedDigestObject, function (value, key) { if (value) $scope.subscriber.subscribedDigests.push(parseInt(key)); });

			if ($scope.subscriber.fieldValues) {
				angular.forEach($scope.subscriber.fieldValues, function (field) {
					if (!field.value || field.value.length === 0)
						field.value = field.defaultValue;
				});
			}

			var val = $scope.emailAddresses.split('\n');
			val = $.grep(val, function (email) { return email && email.length > 0 });
			$mdDialog.hide({ emailAddresses: val, subscriber: $scope.subscriber });
		}

		function cancel() {
			$mdDialog.cancel();
		}

		function resetBounces(form) {
			$http.post("~/api/v1/settings/domain/subscriber-clear-bounce-count", { input: $scope.subscriberEmail })
				.then(function () {
					form.$setDirty();
					successHandling.report("ACTION_SUCCEEDED")
					$scope.subscriber.bounces.length = 0;
					$scope.subscriber.bounceCount = 0;
				}, errorHandling.report);
		}

		function openMessage(miniCard) {
			//$localStorage.mailPopout = { id: miniCard.messageUID, folder: 'Previously+Sent+Messages', owner: miniCard.mailListName.toLowerCase() + "@" + coreDataDomainSettings.domain.toLowerCase() };
			//window.open(stSiteRoot + 'interface/root#/popout/email/' + miniCard.messageUID + "?folder=Previously+Sent+Messages&owner=" + miniCard.mailListName.toLowerCase() + "@" + coreDataDomainSettings.domain.toLowerCase(),
			//	"emailID" + miniCard.messageUID,
			//	'resizable=1, ' + popupService.dimensions.email);
		}

		function loadSubscriber() {
			var defer = $q.defer();

			var promises = [];

			promises.push($http.get('~/api/v1/settings/domain/mailing-lists/list'));
			promises.push(coreDataDomainSettings.settingsData.customFields);

			$q.all(promises)
				.then(function (success) {
					$scope.mailingLists = success[0].data.items || [];
					$scope.mailingLists.sort(function(listA, listB) {
						return listA.listAddress.localeCompare(listB.listAddress);
					});
					if (newItem.mailingListID) {
						angular.forEach($scope.mailingLists, function (list) {
							if (list.id === newItem.mailingListID)
								list.selected = true;
						});
					}

					defer.resolve();
				}, function () {
					defer.reject();
				});
		}

		function getInvalidEmails(userForm) {
			$scope.invalidEmails = [];
			$scope.emailAddresses = userForm.emailAddresses.$viewValue;

			var emails = $scope.emailAddresses ? $scope.emailAddresses.split("\n") : [];
			for (var i = 0; i < emails.length; i++) {
				if (!emails[i] && i == emails.length - 1) continue;
				if (!emailValidationService.isValidEmail(emails[i]))
					$scope.invalidEmails.push({ invalidEmailIndex: i, emailToFix: emails[i] });
			}
		}

		function fixInvalidEmail(userForm, email) {
			var emails = $scope.emailAddresses.split("\n");

			emails[email.invalidEmailIndex] = email.emailToFix;

			$scope.emailAddresses = emails.join("\n");
			userForm.emailAddresses.$viewValue = $scope.emailAddresses;
			userForm.emailAddresses.$modelValue = $scope.emailAddresses;
			userForm.emailAddresses.$commitViewValue();
			userForm.emailAddresses.$rollbackViewValue();

			getInvalidEmails(userForm);
		}

		function removeInvalidEmail(userForm, email) {
			var emails = $scope.emailAddresses.split("\n");

			emails.splice(email.invalidEmailIndex, 1);

			$scope.emailAddresses = emails.join("\n");
			userForm.emailAddresses.$viewValue = $scope.emailAddresses;
			userForm.emailAddresses.$modelValue = $scope.emailAddresses;
			userForm.emailAddresses.$commitViewValue();
			userForm.emailAddresses.$rollbackViewValue();

			getInvalidEmails(userForm);
		}
	}

})();
