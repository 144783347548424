(function () {
	'use strict';

	angular
		.module('smartermail')
        .controller('popoutController', popoutController);

	function popoutController($rootScope, $scope, localeInfoService, $translate) {
		var vm = this;
		vm.isInit = false;
		var noTooltip = false;

		activate();

		function activate() {
			localeInfoService.onReady().then(function () { //Wait for a guaranteed translation before letting anything else load.
				vm.isInit = true;
			}, function () { //If the translation id was not found
				vm.isInit = true;
				});
			if ($rootScope.browserCaps && $rootScope.browserCaps.mouseExists === false) {
				noTooltip = true;
				$('body').addClass("no-tooltip");
			}

			$rootScope.commonTableCounts = [25, 50, 100, 200];
			checkEdgeVersion();
			checkFirefoxVersion();
		};

		//Edge/16.16299
		function checkEdgeVersion() {
			var edgeVersion = -1;
			var indexOfEdge = navigator.userAgent.indexOf("Edge");
			if (indexOfEdge !== -1) {
				var tempString = navigator.userAgent.substring(indexOfEdge, indexOfEdge + 8);
				if (tempString.indexOf("Edge/") !== -1) {
					tempString = tempString.replace(".", "");
					var split = tempString.split("/");
					edgeVersion = Number(split[1]);
				}
			}
			if (!$rootScope.browserCaps) {
				$rootScope.browserCaps = {};
			}
			$rootScope.browserCaps.edgeVersion = edgeVersion;
		};

		//Firefox/40.0
		function checkFirefoxVersion() {
			var ffVersion = -1;
			var indexOfFF = navigator.userAgent.indexOf("Firefox");
			if (indexOfFF !== -1) {
				var tempString = navigator.userAgent.substring(indexOfFF, indexOfFF + 11);
				if (tempString.indexOf("Firefox/") !== -1) {
					tempString = tempString.replace(".", "");
					var split = tempString.split("/");
					ffVersion = Number(split[1]);
				}
			}
			if (!$rootScope.browserCaps) {
				$rootScope.browserCaps = {};
			}
			$rootScope.browserCaps.ffVersion = ffVersion;
		}

		$scope.$watch('browserCaps.mouseExists', function (newValue, oldValue) {
			if (newValue === false && noTooltip === false) {
				noTooltip = true;
				$('body').addClass("no-tooltip");
			} else if (noTooltip === true) {
				noTooltip = false;
				$('body').removeClass("no-tooltip");
			}
		});
	}

})();