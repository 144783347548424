(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminDevMenuController", sysAdminDevMenuController);

	function sysAdminDevMenuController($scope, $mdDialog, $http, toaster) {

		$scope.updateInformation = updateInformation;
		$scope.openProfiler = openProfiler;
		$scope.openMapiCap = openMapiCap;
		$scope.runCommand = runCommand;
		$scope.restore = restore;

		updateInformation();

		$scope.cancel = function () {
			$mdDialog.cancel();
		};

		function updateInformation() {
			$http.get("~/api/v1/debug/dm-status")
				.then(function (result) {
				}, function () { });
		}

		function runCommand(cmd) {
            $http.get("~/api/v1/debug/dm-cmd/"+cmd)
				.then(function (result) {
					toaster.pop("success", "CMD RESULT", result.data.result.message);
                }, function () { });
		}

		function openProfiler() {
			$mdDialog.show({
				controller: "sysAdminEditProfilingController",
				controllerAs: "ctrl",
				templateUrl: "app/sysadmin/settings/troubleshooting/devmenu/profiler.dlg.html"
			});
		}
		function openMapiCap() {
			$mdDialog.show({
				controller: "sysAdminEditMapiCapController",
				controllerAs: "ctrl",
				templateUrl: "app/sysadmin/settings/troubleshooting/devmenu/mapicap.dlg.html"
			});
		}

		function restore(ev, type) {
			$mdDialog
				.show({
					controller: "editDomainRestoreController",
					controllerAs: "ctrl",
					locals: { options: { type: type } },
					templateUrl: "app/sysadmin/manage/domains/modals/restore.dlg.html",
					targetEvent: ev,
					clickOutsideToClose: false
				})
				.then(function () {

				}, function () { });
		}
		

	}
})();
