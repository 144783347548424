(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("webSocketService", webSocketService);

	function webSocketService($rootScope, $q) {
		const service = {};
		let ws = null;
		let deferred = $q.defer();
		service.connect = function(url) {
			ws = new WebSocket(url);

			ws.onopen = function() {
				console.log('WebSocket connection opened');
				deferred.resolve();
			};

			ws.onmessage = function(message) {
				$rootScope.$broadcast('ws:message', message);
			};

			ws.onclose = function() {
				console.log('WebSocket connection closed');
			};

			ws.onerror = function(error) {
				console.log('WebSocket error', error);
				deferred.reject(error);
			};

			return deferred.promise;
		};

		service.send = function(data) {
			ws.send(JSON.stringify(data));
		};

		return service;
	}
})();