(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("reportsSystemController", reportsSystemController);

	function reportsSystemController($filter, coreData, coreDataSettings, $scope, errorHandling) {

		var _this = this;
		this.isInitialized = false;

		coreData.init()
		.then(function () {
			coreDataSettings.changePageReset();
			_this.isInitialized = true;
		}, errorHandling.report);

        $scope.navigate = function (type, report) {
            window.location.href = "root#/reports/" + type + '/' + report;
        }
	}

})();
