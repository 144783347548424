/*USE EXAMPLE --- This will Allow you to use ng-dblClick and a click action on the same element
* 		<tr ng-repeat="item in upCallsCtrl.upCallsData | limitTo: upCallsCtrl.totalUpProtPerPage : (upCallsCtrl.upProtPage-1)*upCallsCtrl.totalUpProtPerPage"
			ng-class="{'itemSelected': isRowSelected(item)}"

		-->	ng-dblclick="setClickedRow($event, item);"

		-->	st-click="upCallsCtrl.showUpCallsDetailsModal();"

			class="tr-clickable">
			<td class="th-td-medium">{{item.dateDueUtc|friendlyDateNoTime:true}}</td>
		</tr>
 */

angular.module('smartertools')
	.directive('stClick', ['$parse', function ($parse) {
		return {
			restrict: 'A',
			link: function (scope, element, attr) {
				var fn = $parse(attr['stClick']);
				var delay = 300, clicks = 0, timer = null;
				element.on('click', function (event) {
					clicks++;  //count clicks
					if (clicks === 1) {
						timer = setTimeout(function () {
							scope.$apply(function () {
								fn(scope, { $event: event });
							});
							clicks = 0;             //after action performed, reset counter
						}, delay);
					} else {
						clearTimeout(timer);    //prevent single-click action
						clicks = 0;             //after action performed, reset counter
					}
				});
			}
		};
	}]);