(function () {
    'use strict';

    angular
        .module('smartermail')
        .controller('aliasSummaryController', aliasSummaryController);

    function aliasSummaryController($scope, $mdDialog, name, targets, includeAllDomainUsers, userNames, isChatAliasList) {
        $scope.name = name;
        $scope.targets = targets;
        $scope.userNames = userNames;
        $scope.isAlias = true;
        $scope.isChatAliasList = isChatAliasList || false;
        if ($scope.isChatAliasList) {
            $scope.isAlias = false;
        }
        
        if (targets != null && userNames != null) {
            $scope.aliasTargets = {};

            for (var i = 0; i < targets.length; i++) {
                $scope.aliasTargets[targets[i]] = determineUsername(targets[i]);
            }
        }

        function determineUsername(targetEmail) {
            const user = userNames.find(user => user.target === targetEmail);
            if (user && user.username && user.username.length > 0 && user.username !== targetEmail) {
                return user.username;
            } else {
                return targetEmail.split('@')[0];
            }
        }
        
        $scope.includeAllDomainUsers = includeAllDomainUsers;

        $scope.cancel = function () {
            $mdDialog.cancel();
        };
    };
})();