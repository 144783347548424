(function () {
	'use strict';

	angular
		.module('smartermail')
		.factory('chatProviderFactory', chatProviderFactory);

	chatProviderFactory.$inject = ['$rootScope', 'userDataService', 'simpleXmppService', 'rocketChatService', 'popupService', '$state', 'xmppService', 'coreLicensing', 'claimsService'];

	function chatProviderFactory($rootScope, userDataService, simpleXmppService, rocketChatService, popupService, $state, xmppService, coreLicensing, claimsService) {
		let xmppSetStatusTask = undefined;
		const isChatWindow = $state.current.name === "popout.chat";
		const providers = {
			0: "SMARTERMAIL",
			1: "ROCKETCHAT"
		};
		const isChatEnabled = () => {
			return userDataService.user && userDataService.user.settings && userDataService.user.settings.features &&
				userDataService.user.settings.features.enableChat &&
				coreLicensing.hasEnterpriseFunctionality &&
				!claimsService.impersonating();
		}
		const setXmppStatusInterval = (status) => {
			if (xmppSetStatusTask) clearInterval(xmppSetStatusTask);
			xmppSetStatusTask = setInterval(function () {
				if (simpleXmppService.connected) {
					clearInterval(xmppSetStatusTask);
					simpleXmppService.setStatus(status);
				}
			}, 300);
		}


		try {
			const service = {
				get enabled() {
					return isChatEnabled();
				},
				get chatStatusTypes() {
					if (!userDataService.user || !userDataService.user.settings || !userDataService.user.settings.userMailSettings) return {};
					return userDataService.user.settings.userMailSettings.chatProviderType === 1
						? rocketChatService.states
						: simpleXmppService.states;
				},
				get connected() {
					if (!isChatEnabled()) return false;
					return userDataService.user.settings.userMailSettings.chatProviderType === 1 ? rocketChatService.service.connected : simpleXmppService.parameters.connected;
				},
				get provider() {
					if (!isChatEnabled()) return "NONE";
					return providers[userDataService.user.settings.userMailSettings.chatProviderType];
				},
				get status() {
					if (!isChatEnabled()) return "none";
					return userDataService.user.settings.userMailSettings.chatProviderType === 1 ? rocketChatService.status : simpleXmppService.status;
				},
				set status(status) {
					switch (service.provider) {
						case "SMARTERMAIL":
							if (simpleXmppService.status === "connecting") {
								setXmppStatusInterval(status);
							} else {
								simpleXmppService.setStatus(status);
							}
							break;
						case "ROCKETCHAT":
							rocketChatService.setStatus(status);
							break;
					}
				}
			};

			service.setStatus = (status) => {
				switch (service.provider) {
					case "SMARTERMAIL":
						simpleXmppService.setStatus(status);
						break;
					case "ROCKETCHAT":
						rocketChatService.setStatus(status);
						break;
				}
			};

			service.getUserStatus = (username) => {
				let status = null;
				switch (service.provider) {
					case "SMARTERMAIL":
						status = isChatWindow ? xmppService.getUserStatus(username) : simpleXmppService.getUserStatus(username);
						break;
					case "ROCKETCHAT":
						status = rocketChatService.getUserStatus(username);
						break;
				}
				return status;
			};

			service.connect = async (reconnect) => {
				await userDataService.init();
				if (!isChatEnabled())
					return;
				switch (service.provider) {
					case "SMARTERMAIL":
						if (reconnect) {
							simpleXmppService.close();
						}
						if (rocketChatService.service.connected) {
							rocketChatService.close();
						}
						simpleXmppService.init();
						break;
					case "ROCKETCHAT":
						if (simpleXmppService.connected) {
							simpleXmppService.close();
						}
						if (reconnect) {
							rocketChatService.close();
						}
						await rocketChatService.init();
						break;
					default:
						if (simpleXmppService.connected) {
							simpleXmppService.close();
						}
						if (rocketChatService.service.connected) {
							rocketChatService.close();
						}
						break;
				}
			};

			service.close = async () => {
				if (rocketChatService.service.connected) {
					rocketChatService.close();
				}
				if (simpleXmppService.parameters.connected) {
					simpleXmppService.close();
				}
			};

			service.generateMenuItems = (event) => {
				switch (service.provider) {
					case "SMARTERMAIL":
						return simpleXmppService.getMenuItems(event);
					case "ROCKETCHAT":
						return rocketChatService.getUnreadChatLinks(event);
				}
				return null;
			};

			service.openMenuLink = (link) => {
				switch (service.provider) {
					case "SMARTERMAIL":
						if (typeof link === "function") {
							link();
						} else {

							simpleXmppService.popout({jid: link});
						}
						break;
					case "ROCKETCHAT":
						if (typeof link === "function") {
							link();
						} else {
							window.open(link, "rocket.Chat", "resizable=1, " + popupService.dimensions.rocketChat);
						}

						break;
				}
			};

			service.openLink = (event, email) => {
				switch (service.provider) {
					case "SMARTERMAIL":
						if (simpleXmppService.status === "offline") {
							simpleXmppService.setStatus("online");
						}
						if (email) {
							simpleXmppService.popout({jid: email});
						} else {
							simpleXmppService.popout("recent");
						}
						break;
					case "ROCKETCHAT":
						rocketChatService.openLink(email);
						break;
				}
			};
			$rootScope.$on('signalR.chat.chatProviderChanged', async (data) => {
				await service.close();
				console.log("chatProviderFactory - onUserSettingsChanged to: ", data);
				userDataService.reset();
				await userDataService.init();
				if (isChatEnabled()) {
					await service.connect();
				}

			});
			return service;
		} catch (e) {
			console.error(e);
			return {
				connected: false
			};
		}
	}
})();