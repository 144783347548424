(function () {
    "use strict";

    angular
		.module("smartermail")
        .service("preferencesStorage", preferencesStorage);

	function preferencesStorage($rootScope, $timeout, claimsService) {
        // If changing the structure of a store, consider changing the version
        // This will delete that store and allow it to be rebuilt with the new structure
        var sourceVisibilitiesVersion = 2;
        var treeExpansionsVersion = 2;
        var sortingFilteringVersion = 2;
        var sessionSortingFilteringVersion = 2;
		var contactStatusesVersion = 2;
		var emailAddress = stringToHashStr(claimsService.getEmailAddress());
		var service = {};

		service.cleanForLogout = cleanForLogout;

        service.getSourceVisibility = getSourceVisibility;
        service.setSourceVisibility = setSourceVisibility;
        service.removeSourceVisibility = removeSourceVisibility;
		service.cleanSourceVisibilities = cleanSourceVisibilities;

        service.getTreeExpansion = getTreeExpansion;
		service.setTreeExpansion = setTreeExpansion;

        service.getSortingFiltering = getSortingFiltering;
        service.getSortingFilteringParam = getSortingFilteringParam;
        service.setSortingFiltering = setSortingFiltering;
		service.setSortingFilteringParam = setSortingFilteringParam;

        service.getSessionSortingFiltering = getSessionSortingFiltering;
		service.getSessionSortingFilteringParam = getSessionSortingFilteringParam;
		service.setSessionSortingFiltering = setSessionSortingFiltering;
        service.setSessionSortingFilteringParam = setSessionSortingFilteringParam;

		service.setLocalParam = setLocalParam;
		service.getLocalParam = getLocalParam;

		service.getContactStatuses = getContactStatuses;
		service.getContactStatusesParam = getContactStatusesParam;
		service.setContactStatusesParam = setContactStatusesParam;

		cleanVersions();

		$rootScope.$on('signalR.mailHub.client.galUpdate', function (event, data) {
			setSortingFilteringParam("chat", "galUpdate", data);
		});

		window.addEventListener("beforeunload", function () {
			delete localStorage.contactStatuses;
			delete localStorage.deletedUids;
		});

		return service;

		//// Parsing
		function parseSessionStorage(item) {
			var sessionItem = sessionStorage[item];
			if (sessionItem)	// Maybe add try catch if errors become a problem
				sessionItem = JSON.parse(sessionItem);
			return sessionItem;
		}

		function parseLocalStorage(item) {
			var localItem = localStorage[item];
			if (localItem)	// Maybe add try catch if errors become a problem
				localItem = JSON.parse(localItem);
			return localItem;
		}

		//// Versioning
		function cleanVersions() {
			//// Code to fix screw ups is below, can probably remove after a good amount of time

			// Delete storage saved by $localStorage and $sessionStorage if it exists
			if (localStorage["ngStorage-sortingFiltering"])
				delete localStorage["ngStorage-sortingFiltering"];

			if (localStorage["ngStorage-treeExpansion"])
				delete localStorage["ngStorage-treeExpansion"];

			if (sessionStorage["ngStorage-sourceVisibilities"])
				delete sessionStorage["ngStorage-sourceVisibilities"];

			if (sessionStorage["ngStorage-sortingFiltering"])
				delete sessionStorage["ngStorage-sortingFiltering"];

			// Delete popup open if it isn't an object because I screwed up when first making local params and didn't make it a dictionary of emails
			var popupOpen = parseLocalStorage("popupOpen");
			if (popupOpen === true || popupOpen === false)
				delete localStorage.popupOpen;

			//// Main code is below

			// Source Visibilities
			var sourceVisibilities = checkSourceVisibilitiesExists();
			if (sourceVisibilities && sourceVisibilities.version != sourceVisibilitiesVersion)
				delete localStorage.sourceVisibilities;

			// Tree Expansion
			var treeExpansion = checkTreeExpansionsExist();
			if (treeExpansion && treeExpansion.version != treeExpansionsVersion)
				delete localStorage.treeExpansion;

			// Sorting Filtering
			var sortingFiltering = checkSortingFilteringExists();
			if (sortingFiltering && sortingFiltering.version != sortingFilteringVersion)
                delete localStorage.sortingFiltering;

            // Session storage isn't emptied if the service is updated and the user doesn't close their page, so we have a version
			var sessionSortingFiltering = checkSessionSortingFilteringExists();
			if (sessionSortingFiltering && sessionSortingFiltering.version != sessionSortingFilteringVersion)
				delete sessionStorage.sortingFiltering;

			// Contact Statuses
			var contactStatuses = checkContactStatusesExists();
			if (contactStatuses && contactStatuses.version != contactStatusesVersion)
				delete localStorage.contactStatuses;
			else {
				var checkTime = moment().subtract(1, 'hour').valueOf();
				var emailsToDelete = [];
				for (var email in contactStatuses) {
					if (!contactStatuses[email].lastAccess) continue;
					if (contactStatuses[email].lastAccess < checkTime)
						emailsToDelete.push(email);
				}

				for (var i = 0; i < emailsToDelete.length; i++) {
					delete contactStatuses[emailsToDelete[i]];
				}

				for (var email in contactStatuses) {
					if (!contactStatuses[email].registered) continue;
					var timesToDelete = [];
					for (var time in contactStatuses[email].registered) {
						if (time < checkTime)
							timesToDelete.push(time);
					}

					for (var i = 0; i < timesToDelete.length; i++) {
						delete contactStatuses[email].registered[timesToDelete[i]];
					}
				}

				localStorage.contactStatuses = JSON.stringify(contactStatuses);
			}
        }

		function cleanForLogout() {
			delete localStorage.contactStatuses;
			delete localStorage.deletedUids;
			var sortingFiltering = checkSortingFilteringExists("chat");
			delete sortingFiltering[emailAddress].chat.unreadCounts
			localStorage.sortingFiltering = JSON.stringify(sortingFiltering);
		}

        //// Source Visibility
        function setSourceVisibility(section, source) {
			var sourceVisibilities = checkSourceVisibilitiesExists(section);
			sourceVisibilities[emailAddress][section][getSourceID(section, source)] = source.enabled === undefined ? source.isVisible : source.enabled;
			localStorage.sourceVisibilities = JSON.stringify(sourceVisibilities);
        }

        function removeSourceVisibility(section, source) {
			var sourceVisibilities = checkSourceVisibilitiesExists(section);
			delete sourceVisibilities[emailAddress][section][getSourceID(section, source)];
			localStorage.sourceVisibilities = JSON.stringify(sourceVisibilities);
		}

		function getSourceVisibility(section, source, defaultValue) {
			var sourceVisibilities = checkSourceVisibilitiesExists(section);
			if (sourceVisibilities[emailAddress][section][getSourceID(section, source)] === undefined) {
                if (defaultValue === undefined || defaultValue === null)
                    defaultValue = true;
                else
                    defaultValue = !!defaultValue;
				sourceVisibilities[emailAddress][section][getSourceID(section, source)] = defaultValue;
				localStorage.sourceVisibilities = JSON.stringify(sourceVisibilities);
			}
			return sourceVisibilities[emailAddress][section][getSourceID(section, source)];
        }

		function checkSourceVisibilitiesExists(section) {
			if (!emailAddress || emailAddress === "0") emailAddress = stringToHashStr(claimsService.getEmailAddress());
			var sourceVisibilities = parseLocalStorage("sourceVisibilities");
			if (!sourceVisibilities) sourceVisibilities = { version: sourceVisibilitiesVersion };
			if (!sourceVisibilities[emailAddress]) sourceVisibilities[emailAddress] = {};
			if (!sourceVisibilities[emailAddress][section]) sourceVisibilities[emailAddress][section] = {};
			return sourceVisibilities;
        }

        function cleanSourceVisibilities(section, sources) {
			var sourceVisibilities = checkSourceVisibilitiesExists(section);

            var keysToDelete = [];
			for (var key in sourceVisibilities[emailAddress][section]) {
                var temp = $.grep(sources, function (source) { return getSourceID(section, source) === key });
                if (temp.length === 0)
                    keysToDelete.push(key);
            }

            for (var i = 0; i < keysToDelete.length; i++) {
				delete sourceVisibilities[emailAddress][section][keysToDelete[i]];
			}

			sessionStorage.sourceVisibilities = JSON.stringify(sourceVisibilities);
        }

		function getSourceID(section, source) {
			switch (section) {
                case "calendars":
                case "calendartask":
                case "resource":
                    return source.id + source.owner;
                case "contacts":
                    return source.itemID + source.ownerUsername;
                case "tasks":
                    return source.id + source.owner;
				case "notes":
                    return source.itemID + source.ownerUsername;
            }
        }

        //// Tree expansion
        function setTreeExpansion(section, expanded) {
            var treeExpansion = checkTreeExpansionsExist();
            treeExpansion[emailAddress][section] = expanded;
			localStorage.treeExpansion = JSON.stringify(treeExpansion);
		}

        function getTreeExpansion(section) {
            var treeExpansion = checkTreeExpansionsExist();
			if (treeExpansion[emailAddress][section] == undefined) {
				treeExpansion[emailAddress][section] = true;
				localStorage.treeExpansion = JSON.stringify(treeExpansion);
			}
			return $localStorage.treeExpansion[emailAddress][section];
        }

		function checkTreeExpansionsExist() {
			if (!emailAddress || emailAddress === "0") emailAddress = stringToHashStr(claimsService.getEmailAddress());
			var treeExpansion = parseLocalStorage("treeExpansion");
            if (!treeExpansion) treeExpansion = { version: treeExpansionsVersion };
			if (!treeExpansion[emailAddress]) treeExpansion[emailAddress] = {};
			return treeExpansion;
        }

		//// Sorting/Filtering
		function setSortingFiltering(section, parameters) {
			var sortingFiltering = checkSortingFilteringExists();
			sortingFiltering[emailAddress][section] = parameters;
			localStorage.sortingFiltering = JSON.stringify(sortingFiltering);
		}

		function setSortingFilteringParam(section, parameter, value) {
			var sortingFiltering = checkSortingFilteringExists(section);
			sortingFiltering[emailAddress][section][parameter] = value;
			localStorage.sortingFiltering = JSON.stringify(sortingFiltering);
		}

		function getSortingFiltering(section) {
			var sortingFiltering = checkSortingFilteringExists();
			return sortingFiltering[emailAddress][section];
		}

		function getSortingFilteringParam(section, parameter) {
			var sortingFiltering = checkSortingFilteringExists(section);
			return sortingFiltering[emailAddress][section][parameter];
		}

		function checkSortingFilteringExists(section) {
			if (!emailAddress || emailAddress === "0") emailAddress = stringToHashStr(claimsService.getEmailAddress());
			var sortingFiltering = parseLocalStorage("sortingFiltering");
			if (!sortingFiltering) sortingFiltering = { version: sortingFilteringVersion };
			if (!sortingFiltering[emailAddress]) sortingFiltering[emailAddress] = {};
			if (section && !sortingFiltering[emailAddress][section]) sortingFiltering[emailAddress][section] = {};
			return sortingFiltering;
		}

		//// Session Sorting/Filtering
		function setSessionSortingFiltering(section, parameters) {
			var sessionSortingFiltering = checkSessionSortingFilteringExists();
			sessionSortingFiltering[emailAddress][section] = parameters;
			sessionStorage.sortingFiltering = JSON.stringify(sessionSortingFiltering);
		}

		function setSessionSortingFilteringParam(section, parameter, value) {
			var sessionSortingFiltering = checkSessionSortingFilteringExists(section);
			sessionSortingFiltering[emailAddress][section][parameter] = value;
			sessionStorage.sortingFiltering = JSON.stringify(sessionSortingFiltering);
		}

		function getSessionSortingFiltering(section) {
			var sessionSortingFiltering = checkSessionSortingFilteringExists();
			return sessionSortingFiltering[emailAddress][section];
		}

		function getSessionSortingFilteringParam(section, parameter) {
			var sessionSortingFiltering = checkSessionSortingFilteringExists(section);
			return sessionSortingFiltering[emailAddress][section][parameter];
		}

		function checkSessionSortingFilteringExists(section) {
			if (!emailAddress || emailAddress === "0") emailAddress = stringToHashStr(claimsService.getEmailAddress());
			var sessionSortingFiltering = parseSessionStorage("sortingFiltering");
			if (!sessionSortingFiltering) sessionSortingFiltering = { version: sessionSortingFilteringVersion };
			if (!sessionSortingFiltering[emailAddress]) sessionSortingFiltering[emailAddress] = {};
			if (section && !sessionSortingFiltering[emailAddress][section]) sessionSortingFiltering[emailAddress][section] = {};
			return sessionSortingFiltering;
		}

		//// Single Params
		function setLocalParam(parameter, value) {
			if (!emailAddress || emailAddress === "0")
				emailAddress = stringToHashStr(claimsService.getEmailAddress());
			if (!emailAddress || emailAddress === "0")
				return;
			var localParam = checkLocalParamExists(parameter);
			localParam[emailAddress] = value;
			localStorage[parameter] = JSON.stringify(localParam);
		}

		function getLocalParam(parameter) {
			return checkLocalParamExists(parameter)[emailAddress];
		}

		function checkLocalParamExists(parameter) {
			if (!emailAddress || emailAddress === "0") emailAddress = stringToHashStr(claimsService.getEmailAddress());
			var localParam = parseLocalStorage(parameter);
			if (!localParam) localParam = {};
			return localParam;
		}

		//// Contact Statuses
		function setContactStatusesParam(parameter, value) {
			var contactStatuses = checkContactStatusesExists();
			contactStatuses[emailAddress][parameter] = value;
			contactStatuses[emailAddress].lastAccess = moment().valueOf();
			localStorage.contactStatuses = JSON.stringify(contactStatuses);
		}

		function getContactStatuses() {
			var contactStatuses = checkContactStatusesExists();
			return contactStatuses[emailAddress];
		}

		function getContactStatusesParam(parameter) {
			var contactStatuses = checkContactStatusesExists();
			return contactStatuses[emailAddress][parameter];
		}

		function checkContactStatusesExists() {
			if (!emailAddress || emailAddress === "0") emailAddress = stringToHashStr(claimsService.getEmailAddress());
			var contactStatuses = parseLocalStorage("contactStatuses");
			if (!contactStatuses) contactStatuses = { version: contactStatusesVersion };
			if (!contactStatuses[emailAddress]) contactStatuses[emailAddress] = {};
			return contactStatuses;
		}

		//// Helper Functions
		function stringToHash(str) {
			var hash = 0, i, chr;
			if (str === undefined || str === null || str.length === 0) return hash;
			for (i = 0; i < str.length; i++) {
				chr = str.charCodeAt(i);
				hash = ((hash << 5) - hash) + chr;
				hash |= 0; // Convert to 32bit integer
			}
			return hash;
		}

		function stringToHashStr(str) {
			return stringToHash(str).toString(16);
		}
    }

})();