(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("emailTasksController", emailTasksController);
    function emailTasksController($scope, $mdDialog, $http, $rootScope, errorHandling, message) {
        $scope.controller = this;
        $scope.tasks = message.tasks || [];

        $scope.emailTaskClicked = function (task) {
            try {
                task.sourcePermission = 8;
                $rootScope.$broadcast('startEditTask', task);
            } catch (err) {
                errorHandling.report(err);
            }
        };

        $scope.close = function () {
            $mdDialog.cancel();
        };
    }
})();