(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("emailRecipientsController", emailRecipientsController);

	function emailRecipientsController($mdDialog, $http, $translate, sources, type, coreDataCalendar) {

		var vm = this;

		vm.sources = sources;
		vm.selectedSource = vm.sources[0];
		vm.filteredContacts = [];
		vm.type = type;

		$.each(vm.sources,
			function(i, v) {
				v.displayName = $translate.instant(v.displayName);
			});
	 
		vm.sourceChanged = sourceChanged;
		vm.selectAll = selectAll;
		vm.save = save;
		vm.cancel = cancel;

		sourceChanged();

		function sourceChanged() {
			var url;

			if (vm.sources.length === 0)
				return;
			
			if (vm.selectedSource.itemID === "category")
				url = "~/api/v1/contacts/address-book/" + vm.sources[0].ownerUsername + "/all";
			else if (vm.selectedSource.ownerUsername === "")
				url = "~/api/v1/contacts/domain/";
			else
				url = "~/api/v1/contacts/address-book/" + vm.selectedSource.ownerUsername + "/" + vm.selectedSource.itemID;

			vm.filteredContacts = [];
			if (vm.selectedSource.itemID === "conferencerooms") {
				const allConferenceRooms = $.grep(coreDataCalendar.getResources(), function (conferenceRoom) { return conferenceRoom.sharedResourceType === 2; });
				for (let i = 0; i < allConferenceRooms.length; ++i) {
					vm.filteredContacts.push({
						selected: false,
						displayAs: allConferenceRooms[i].name,
						emailAddressList: [allConferenceRooms[i].emailAlias + "@" + vm.selectedSource.domain],
						id: allConferenceRooms[i].id,
						isGroup: false,
						sourceAccount: "",
						sharedResourceType: allConferenceRooms[i].sharedResourceType
					});
                }
			} else if (vm.selectedSource.itemID === "equipment") {
				const allEquipment = $.grep(coreDataCalendar.getResources(), function (equipment) { return equipment.sharedResourceType === 3; });
				for (let i = 0; i < allEquipment.length; ++i) {
					vm.filteredContacts.push({
						selected: false,
						displayAs: allEquipment[i].name,
						emailAddressList: [allEquipment[i].emailAlias + "@" + vm.selectedSource.domain],
						id: allEquipment[i].id,
						isGroup: false,
						sourceAccount: "",
						sharedResourceType: allEquipment[i].sharedResourceType
					});
				}
			} else {
				$http
					.get(url)
					.then(function (success) {
						angular.forEach(success.data.contacts, function (detail) {
							if ((!detail.emailAddressList || detail.emailAddressList.length === 0) &&
								(!detail.groupedContacts || detail.groupedContacts.length === 0)) {
								return;
							}

							if (vm.selectedSource.itemID === "category" && (!detail.categories || $.grep(detail.categories, function (cat) { return cat.name === vm.selectedSource.displayName }).length === 0))
								return;

							const sourceAcount = detail.sourceOwner ? detail.sourceOwner.split("@")[0] : "";
							const isGroup = detail.groupedContacts && detail.groupedContacts.length > 0;
							if (isGroup) {
								vm.filteredContacts.push({
									selected: false,
									displayAs: detail.displayAs,
									id: detail.id,
									isGroup: isGroup,
									sourceAccount: sourceAcount
								});
							} else {
								for (let i = 0; i < detail.emailAddressList.length; ++i) {
									const email = detail.emailAddressList[i];
									vm.filteredContacts.push({
										selected: false,
										displayAs: detail.displayAs,
										emailAddressList: [email],
										id: detail.id,
										isGroup: isGroup,
										sourceAccount: sourceAcount
									});
								}
							}
						});

						vm.filteredContacts.sort(function (a, b) {
							var nameA = a.displayAs.toLowerCase(), nameB = b.displayAs.toLowerCase();
							if (nameA < nameB)
								return -1;
							if (nameA > nameB)
								return 1;
							return 0;
						});
					}, function () { });
			}
		}

		function selectAll() {
			var allCheck = $.grep(vm.filteredContacts, function (contact) { return contact.selected; });
			if (allCheck.length === vm.filteredContacts.length) {
				for (var i = 0; i < vm.filteredContacts.length; i++) {
					vm.filteredContacts[i].selected = false;
				}
			} else {
				for (var i = 0; i < vm.filteredContacts.length; i++) {
					vm.filteredContacts[i].selected = true;
				}
			}
		}

		function save() {
			$mdDialog.hide({ selectedContacts: $.grep(vm.filteredContacts, function (contact) { return contact.selected; }) });
		};

		function cancel() {
			$mdDialog.cancel();
		};
	}
})();
