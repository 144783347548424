(function () {
	"use strict";

	angular
		.module("smartertools")
		.directive("stDragLeave", ["$rootScope", "$parse", dragLeaveDirective])
		.directive("stDragOver", ["$rootScope", "$parse", dragOverDirective])
		.directive("stDragStart", ["$rootScope", "$parse", dragStartDirective])
		.directive("stDrop", ["$rootScope", "$parse", dropDirective]);

	function dragLeaveDirective($rootScope, $parse) {
		return {
			restrict: "A",
			compile: function ($element, attr) {
				var func = $parse(attr["stDragLeave"], null, true);

				return function (scope, element) {
					element.on("dragleave", function (event) {
						var callback = function () { func(scope, { event: event.originalEvent }) };
						scope.$apply(callback);
					});
				};
			}
		};
	}
	
	function dragOverDirective($rootScope, $parse) {
		return {
			restrict: "A",
			compile: function ($element, attr) {
				var func = $parse(attr["stDragOver"], null, true);

				return function (scope, element) {
					element.on("dragover", function (event) {
						var callback = function () { func(scope, { event: event.originalEvent }) };
						scope.$apply(callback);
					});
				};
			}
		};
	}

	function dragStartDirective($rootScope, $parse) {
		return {
			restrict: "A",
			compile: function ($element, attr) {
				var func = $parse(attr["stDragStart"], null, true);

				return function (scope, element) {
					element.on("dragstart", function (event) {
						var callback = function () { func(scope, { event: event.originalEvent }) };
						scope.$apply(callback);
					});
				};
			}
		};
	}

	function dropDirective($rootScope, $parse) {
		return {
			restrict: "A",
			compile: function ($element, attr) {
				var func = $parse(attr["stDrop"], null, true);

				return function (scope, element) {
					element.on("drop", function (event) {
						var callback = function () { func(scope, { event: event.originalEvent }) };
						scope.$apply(callback);
					});
				};
			}
		};
	}
})();