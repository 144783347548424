(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("reportDetailsController", reportDetailsController);

    function reportDetailsController($rootScope, $scope, $http, $filter, $state, $stateParams, $timeout,
        coreData, userDataService, coreDataDomainSettings, NgTableParams, $location, tableColumnSwitching, errorHandling, ngTableEventsChannel, userTimeService, $q, localeInfoService, toaster, $translate) {
        var lastReportParameters = null;
        var debouncedCreateLineOrBarChart = _.debounce(createLineOrBarChart, 100);
        var debouncedCreatePieChart = _.debounce(createPieChart, 100);
        var minDate = (new Date("2002"));


        // Functions
        $scope.changeReportChart = changeReportChart;
        $scope.changeReportMode = changeReportMode;
        $scope.changeReportRows = changeReportRows;
        $scope.changeReportUsers = changeReportUsers;
        $scope.chartReadyHandler = function (chartWrapper) { };
        $scope.dateRangeChanged = dateRangeChanged;
        $scope.exportReport = exportReport;
        $scope.getRowValue = getRowValue;
        $scope.navigateBack = navigateBack;
        $scope.tableReadyHandler = function (chartWrapper) { };
        activate();

        /////////////////////////////////////////////////

        function activate() {
            let stateType = $stateParams.type;
            let stateReport = $stateParams.report;
            $rootScope.$state.current.helpid = `index.reports.${stateType}.${stateReport}`;

            $scope.chartType = "LineChart";

            var colorsLighter = ["#4f97d6", "#fba951", "#4D990F", "#9B3737", "#D3B937", "#B183F7", "#4993A0", "#1F6B1F", "#413E7C", "#6B351F"];
            $scope.colors = colorsLighter;

            $scope.columnSwitching = tableColumnSwitching.getFirstSwitcher();
            $scope.defaultUserTemplate = { key: -1, userName: "ALL_USERS" };
            $scope.isDomainDiskUsage = false;
            $scope.showChart = true;
            $scope.showModeSwitch = true;
            $scope.showDetailSelectors = true;
            $scope.sourceName = $stateParams.source;
            if ($scope.sourceName)
                $scope.sourceNameFormatted = "(" + $scope.sourceName + ")";
            $scope.theChart = {};
            $scope.theTable = {};
            $scope.reportMaxDate = new Date('3000-1-1');
            $scope.reportMinDate = new Date('2000-1-1');

            $scope.updatingFromServer = false;

            $scope.userOrDomain = "USERS";
            if ($stateParams.report.toUpperCase() == "DISK_USAGE") {
                $scope.showDetailSelectors = false;
                if ($stateParams.type.toUpperCase() == "DOMAIN")
                    $scope.isDomainDiskUsage = true;
            }

            $scope.reportStepOptions = [
                { text: "HOURLY", key: 1 },
                { text: "DAILY", key: 4 },
                { text: "WEEKLY", key: 5 },
                { text: "MONTHLY", key: 6 },
                { text: "QUARTERLY", key: 7 }
            ];

            $scope.reportRowOptions = [
                { key: 0, text: $filter("translate")("NUM_ROWS", { num: 10 }), value: 10 },
                { key: 1, text: $filter("translate")("NUM_ROWS", { num: 50 }), value: 50 },
                { key: 2, text: $filter("translate")("NUM_ROWS", { num: 100 }), value: 100 },
                { key: 3, text: $filter("translate")("UNLIMITED"), value: Number.MAX_SAFE_INTEGER }
            ];

            $scope.userSearch = '';
            $scope.clearSearchTerm = function () { $scope.userSearch = ''; };
            $timeout(function () {
                $('#userSearch').on('keydown', function (ev) { ev.stopPropagation(); });
            });

            $scope.returnTarget = "/reports";
            if (coreData.user.isSysAdmin && !$scope.sourceName) {
                $scope.returnTarget = "/sysadmin/reports";
                $scope.userOrDomain = "DOMAINS";
            }

            if ($stateParams.report.toUpperCase() != "DISK_USAGE") {
                ngTableEventsChannel.onPagesChanged(function () {
                    setTimeout(debouncedCreateLineOrBarChart);
                }, $scope);
                ngTableEventsChannel.onAfterDataSorted(function () {
                    setTimeout(debouncedCreateLineOrBarChart);
                }, $scope);
            }

            if ($location.search().returnpath)
                $scope.returnTarget = $location.search().returnpath.split('~2F').join('/');

            $scope.$watch('columnSwitching.currentColumn', function (newvalue) { AddColorsToHeaders(false, true); });
            $(window).resize(onWindowResize);
            $(window).on('resizeEnd', onWindowResizeEnd);

            $q.all([
                coreData.init(),
                userTimeService.init()
            ])
                .then(function () {
                    $rootScope.spinner.show();
                    onInitCompleted();
                }, function (failure) {
                    $rootScope.spinner.hide();
                    errorHandling.report(failure);
                });

            var onInitCompleted = function () {
                $scope.type = $stateParams.type;
                $scope.reportData = {
                    mode: { key: 0, text: "TREND" },
                    step: { key: 4, text: "DAILY" },
                    duration: {
                        startDate: getTodayAsUsersTimeZone().subtract(6, 'd').toDate(),
                        endDate: getTodayAsUsersTimeZone().toDate(),
                        text: ""
                    },
                    user: $scope.defaultUserTemplate,
                    rows: {
                        key: 3,
                        text: "UNLIMITED",
                        value: Number.MAX_SAFE_INTEGER
                    }
                };
                $scope.$watch('reportData.duration.startDate', onStartDateChanged);
                $scope.$watch('reportData.duration.endDate', onEndDateChanged);
                $scope.$watch('reportData.step', onReportDataStepChanged);
                $scope.$watch('reportData.mode.key', onReportDataModeKeyChanged);
                $scope.$watch('reportData.user', onReportDataUserChanged);
                $scope.reportData.step = $scope.reportStepOptions[1];
                $scope.reportData.rows = $scope.reportRowOptions[3];

                var name = $stateParams.report.toUpperCase();
                var nameDetails = null;
                $scope.reportName = name;
                $scope.reportNameDetails = nameDetails;

                $scope.userList = [];
                if ($scope.type != 'user' && !($scope.type && $scope.type.startsWith('system_'))) {
                    coreDataDomainSettings.init().then(function () {
                        coreDataDomainSettings.settingsData.userList.then(function (response) {
                            $scope.userList = response;
                        });
                    });
                }

                getDataAndGenerateReport();
                $timeout(function () { updateDateDisplay(); });
            };
        }

        function getTodayAsUsersTimeZone() {
            return userTimeService.convertLocalToUserTimeMoment(Date.now()).startOf('day');
            //			//var date = moment().utc().add(userTimeService.userTimeZone.currentMinuteOffset, "m").format('YYYY-MM-DD');
            //			//return moment.tz(date, userTimeService.userTimeZone.location);
            ////			return moment(date);
            //			var date = moment().utc().add(userTimeService.userTimeZone.currentMinuteOffset, "m").clone().startOf("day");
            //			return date;
        }


        function updateStartDate(date) {
            if (!date || isNaN(date)) {
                $scope.reportData.duration.startDate = getTodayAsUsersTimeZone().toDate();
                $scope.reportMaxDate.setYear($scope.reportData.duration.startDate.getFullYear() + 5);
                return;
            }

            if (date > $scope.reportData.duration.endDate)
                updateEndDate(date);


            $scope.reportData.duration.startDate = date;
            updateDateDisplay();
            $scope.reportMaxDate.setYear($scope.reportData.duration.startDate.getFullYear() + 5);
        };

        function updateEndDate(date) {
            if (!date || isNaN(date)) {
                $scope.reportData.duration.endDate = $scope.reportData.duration.startDate;
                $scope.reportMinDate.setYear($scope.reportData.duration.endDate.getFullYear() - 5);
                return;
            }

            if (date < $scope.reportData.duration.startDate)
                date = $scope.reportData.duration.startDate;

            $scope.reportData.duration.endDate = date;
            updateDateDisplay();
            $scope.reportMinDate.setYear($scope.reportData.duration.endDate.getFullYear() - 5);
        };

        function StringToNumberData(str) {
            var mult = 1;
            str = str + "";
            if (str.indexOf("KB") != -1)
                mult = 1024;
            else if (str.indexOf("MB") != -1)
                mult = 1024 * 1024;
            else if (str.indexOf("GB") != -1)
                mult = 1024 * 1024 * 1024;
            else if (str.indexOf("TB") != -1)
                mult = 1024 * 1024 * 1024 * 1024;
            else if (str.indexOf("PB") != -1)
                mult = 1024 * 1024 * 1024 * 1024 * 1024;
            return Number(str.replace(/[^\d.-]/g, '')) * mult;
        }

        function AddColorsToHeaders(trigger, colSwitch) {
            if (window.location.hash.includes("disk_usage"))
                return;

            if (!trigger) {
                setTimeout(function () { AddColorsToHeaders(true, colSwitch); }, 20);
                return;
            }

            var colorId = -1;
            var added = false;

            if (colSwitch && $scope.columnSwitching)
                colorId = $scope.columnSwitching.currentColumn - 1;

            if ($scope.c3ChartOptions) {
                $(".table > thead > tr").children("th.header").each(function () {
                    if (colorId > -1 && $(this).find("div > div.circle").length == 0) {
                        var tableHeadingObject = $(this).find("div > span");
                        var columnNames = Object.keys($scope.c3ChartOptions.data.names);
                        for (var i = 0; i < columnNames.length; i++) {
                            var item = $scope.c3ChartOptions.data.names[columnNames[i]];
                            if (item && item == tableHeadingObject.text()) {
                                tableHeadingObject.css("color", $scope.colors[colorId]);
                                colorId++;
                                break;
                            }
                        }
                        added = true;
                    }

                    if (colorId == -1)
                        colorId++;
                });
            }
        }

        function onStartDateChanged(newvalue) {
            if (!$scope.updatingFromServer)
                updateStartDate($scope.reportData.duration.startDate);
            $scope.updatingFromServer = false;
        }

        function onEndDateChanged(newvalue) {
            if (!$scope.updatingFromServer)
                updateEndDate($scope.reportData.duration.endDate);
            $scope.updatingFromServer = false;
        }

        function dateRangeChanged() {
            if (!$scope.updatingFromServer) getDataAndGenerateReport();
        };

        function updateDateDisplay() {
            switch ($scope.reportData.step.key) {
                case 6:
                case 7:
                //$scope.reportData.duration.text = moment($scope.reportData.duration.startDate).format("MMMM, YYYY") + " - " + moment($scope.reportData.duration.endDate).format("MMMM, YYYY"); //.format("M.D.YYYY")
                //break;
                default:
                case 1:
                case 5:
                case 4:
                    $scope.reportData.duration.text = $filter('date')($scope.reportData.duration.startDate, 'shortDate') + " - " + $filter('date')($scope.reportData.duration.endDate, 'shortDate');
                    break;
            }
            $scope.dateRangeChanged();
        };

        function navigateBack() {
            window.location.hash = $scope.returnTarget;
        };

        function onReportDataStepChanged(newvalue, oldvalue) {
            if (!newvalue || !oldvalue || newvalue == oldvalue)
                return;

            if (newvalue.key == 6) { // Monthly
                $scope.reportData.duration.endDate = moment($scope.reportData.duration.endDate).endOf('month').toDate();
                $scope.reportData.duration.startDate = moment($scope.reportData.duration.startDate).startOf("month").toDate();

            } else if (newvalue.key == 7) { // Quarterly
                $scope.reportData.duration.endDate = moment($scope.reportData.duration.endDate).endOf('quarter').toDate();
                $scope.reportData.duration.startDate = moment($scope.reportData.duration.startDate).startOf("quarter").toDate();
            }

            if (moment($scope.reportData.duration.endDate) > moment())
                $scope.reportData.duration.endDate = moment().toDate();

            getDataAndGenerateReport();
            updateDateDisplay();
        }

        function changeReportMode(modeKey) {
            switch (modeKey) {
                case 1:
                    $scope.reportData.mode.text = "USERS";
                    $scope.chartType = "ColumnChart";
                    $("#btnChartTypeBar").find('i').addClass('selected');
                    $("#btnChartTypeLine").find('i').removeClass('selected');
                    break;
                default:
                case 0:
                    //$scope.reportData.mode.key = 0;
                    $scope.reportData.mode.text = "TREND";
                    $scope.reportData.rows.key = 3;
                    $scope.reportData.rows.text = "UNLIMITED";
                    $scope.reportData.rows.value = Number.MAX_SAFE_INTEGER;
                    $scope.chartType = "LineChart";
                    $("#btnChartTypeBar").find('i').removeClass('selected');
                    $("#btnChartTypeLine").find('i').addClass('selected');
                    $("[item-group-id = 'reportUsers']").find('i').removeClass('selected');
                    $("#reportUserItem").find('i').addClass('selected');

                    $scope.reportData.user = $scope.defaultUserTemplate;
                    break;
            }
            getDataAndGenerateReport();
        };

        function onReportDataModeKeyChanged(newvalue, oldvalue) {
            if (newvalue != undefined && oldvalue != undefined && newvalue != oldvalue) {
                $scope.changeReportMode(newvalue);
            }
        }

        function changeReportChart(chartKey) {
            switch (chartKey) {
                case 1:
                    if ($scope.chartType == "ColumnChart")
                        return;
                    $scope.chartType = "ColumnChart";
                    break;
                default:
                case 0:
                    if ($scope.chartType == "LineChart")
                        return;
                    $scope.chartType = "LineChart";
                    break;
            }

            debouncedCreateLineOrBarChart();
        };

        function changeReportUsers(user) {
            if (user == undefined)
                $scope.reportData.user = $scope.defaultUserTemplate;
            else
                $scope.reportData.user.key = 0;
            getDataAndGenerateReport();
        };

        function onReportDataUserChanged(newvalue, oldvalue) {
            if (newvalue && oldvalue && newvalue != oldvalue)
                $scope.changeReportUsers(newvalue);
        }

        function changeReportRows(rowKey) {
            switch (rowKey) {
                case 1:
                    $scope.reportData.rows.key = 1;
                    $scope.reportData.rows.text = $filter("translate")("NUM_ROWS", { num: 50 });
                    $scope.reportData.rows.value = 50;
                    break;
                case 2:
                    $scope.reportData.rows.key = 2;
                    $scope.reportData.rows.text = $filter("translate")("NUM_ROWS", { num: 100 });
                    $scope.reportData.rows.value = 100;
                    break;
                case 3:
                    $scope.reportData.rows.key = 3;
                    $scope.reportData.rows.text = "UNLIMITED";
                    $scope.reportData.rows.value = Number.MAX_SAFE_INTEGER;
                    break;
                default:
                case 0:
                    $scope.reportData.rows.key = 0;
                    $scope.reportData.rows.text = $filter("translate")("NUM_ROWS", { num: 10 });
                    $scope.reportData.rows.value = 10;
                    break;
            }
        };

        function getRowValue(text, rowIndex) {
            var format = $scope.content.table.headers[rowIndex].format;
            switch (format) {
                case "date":
                    text = $filter('date')(text, "MM.dd.yyyy");
                    break;
                case "percent":
                    text = $filter('safeNumber')(text, 0) + "%";
                    break;
                default:
                    text = $filter('safeNumber')(text, 0);
                    break;
            }

            if (text && $scope.content.table.headers[rowIndex].suffix != undefined)
                text += $scope.content.table.headers[rowIndex].suffix;
            return text;
        };

        function onWindowResize() {
            if (this.resizeTO)
                clearTimeout(this.resizeTO);
            this.resizeTO = setTimeout(function () { $(this).trigger('resizeEnd'); }, 300);
        }

        function onWindowResizeEnd() {
            AddColorsToHeaders(false, false);
        }

        function getDataAndGenerateReport() {
            var startDateMoment = moment($scope.reportData.duration.startDate).startOf("day").add(-$scope.reportData.duration.startDate.getTimezoneOffset(), "m");
            var endDateMoment = moment($scope.reportData.duration.endDate).startOf("day").add(-$scope.reportData.duration.endDate.getTimezoneOffset(), "m");
            var parameters = {
                //startDate: $scope.reportData.duration.startDate.toISOString(),
                //endDate: $scope.reportData.duration.endDate.toISOString(),
                startDate: startDateMoment.toISOString(),
                endDate: endDateMoment.toISOString(),
                step: $scope.reportData.step.key,
                groupBy: $scope.reportData.mode.key
            };

            if ($scope.type == 'user') {
                //parameters.emailAddress = userDataService.user.emailAddress;
            }
            else if ($scope.reportData.mode.key == 1 && $scope.reportData.user.key != -1) {
                parameters.emailAddress = $scope.reportData.user.emailAddress;
            }

            if (JSON.stringify(parameters) == lastReportParameters)
                return;
            lastReportParameters = JSON.stringify(parameters);

            $rootScope.spinner.show();

            var reportUrl = "~/api/v1/report/details/" + $stateParams.type + "/" + $stateParams.report.toUpperCase() + "/" + ($stateParams.source || '');
            $http.post(reportUrl, parameters)
                .then(onReportRetrieved, onReportFailed);

            function onReportFailed(response) {
                $rootScope.spinner.hide();
                toaster.pop("error", $translate.instant("EXCEEDED_ALLOWED_RANGE"), "", 0);
            }

            function onReportRetrieved(response) {
                try {
                    $rootScope.spinner.hide();
                    $scope.content = angular.fromJson(response.data.report);
                    $scope.content.startDateUTC = response.data.startTimeUTC;


                    $scope.showModeSwitch = response.data.supportedGroupBy.length > 1;
                    switch ($scope.content.chart.chartType) {
                        case "pie":
                            debouncedCreatePieChart();
                            break;
                        default:
                            debouncedCreateLineOrBarChart();
                            break;
                    }

                    $scope.columns = [];

                    var i = 0;
                    var defaultSort = {};

                    $scope.content.table.headers.forEach(function (obj) {
                        var colorId = -1;
                        $scope.columns.push({
                            title: $filter('translate')(obj.label),
                            label: obj.label,
                            field: "f" + i,
                            sortable: "f" + i,
                            getValue: evaluatedValue,
                            valueFormatter: vFormatType(),
                            suffix: obj.suffix || "",
                            isInteger: obj.format !== "date" && obj.format !== "string" && obj.format !== "decimal" && i > 0,
                            isDecimal: obj.format === "decimal" && i > 0,
                            scale: obj.scale,
                            visible: true,
                            show: true,
                            precision: obj.precision || 1
                        });

                        if (obj.sort && (obj.sort.toLowerCase() == "desc" || obj.sort.toLowerCase() == "asc"))
                            defaultSort["f" + i] = obj.sort.toLowerCase();
                        i++;
                        colorId++;

                        function vFormatType() {
                            if (obj.filter)
                                return obj.filter;
                            if (obj.format === "date") {
                                return $scope.reportData.step.key == 1 ? "friendlyDateHourly" : "friendlyDateNoTime";
                            }
                            if (obj.format === "string")
                                return "translate";
                        }
                    });

                    $scope.tableData = [];
                    $scope.content.table.rows.forEach(function (obj) {
                        var rowObj = {};
                        var j = 0;
                        obj.forEach(function (column) {
                            if (j == 0 && $scope.columns[0].label == "DOMAIN") {
                                //column += "<div style='color:red'>!!!</div>";
                            }
                            rowObj["f" + j] = $scope.columns[j].isInteger ? parseInt(column) :
                                $scope.columns[j].isDecimal ? parseFloat(column) : $filter("translate")(column);
                            j++;
                        });
                        $scope.tableData.push(rowObj);
                    });
                    $scope.footerData = [];
                    $scope.content.table.footer.forEach(function (obj) {
                        var rowObj = {};
                        var j = 0;
                        obj.forEach(function (column) {

                            if (j == 0)
                                rowObj["f" + j] = $filter("translate")(column);
                            else
                                rowObj["f" + j] = column;

                            j++;
                        });
                        $scope.footerData.push(rowObj);
                    });
                    if (Object.keys(defaultSort).length == 0)
                        defaultSort = { "f0": "asc" };
                    $scope.tableParams = new NgTableParams(
                        { 
                            sorting: defaultSort,

                        },
                        {
                            total: $scope.content.table.rows.length,
                            dataset: $scope.tableData,
                            localStorageKey: "ngTableRows-Reports"
                        });
                    AddColorsToHeaders(false, false);

                    if ($scope.content.chart.chartType != "pie") {
                        //redraw again, have to, I dont know who is in the table until after the table is done.
                        debouncedCreateLineOrBarChart();
                    }

                    $rootScope.spinner.hide();
                }
                catch (e) { }
                finally {
                    $rootScope.spinner.hide();
                }

                function evaluatedValue($scope, row, footer) {

                    if ((!this.valueFormatter || this.valueFormatter == "") || (footer && this.valueFormatter && this.valueFormatter.toLowerCase().indexOf("date") !== -1)) {
                        if (footer && this.field == "f0")
                            return $filter("translate")(row[this.field]);
                        return $filter("safeNumber")(row[this.field]) + (row[this.field] != "" ? this.suffix : "");
                    }
                    if (row[this.field] == -2147483648 && $scope.isDomainDiskUsage) {
                        return "∞";
                    }
                    if (this.field == "f0" && this.label == "DOMAIN" && row[this.field] != "AVERAGES" && row[this.field] != "TOTALS")
                        return '<a href="#/reports/domain/' + $stateParams.report + '/' + row[this.field] + '">' + $filter(this.valueFormatter)(row[this.field], 1) + "</a>";
                    if (this.field == "f0" && this.label == "USER" && row[this.field] != "AVERAGES" && row[this.field] != "TOTALS") {
                        return '<a href="#/reports/user/' + $stateParams.report + '/' + row[this.field] + "@" + ($scope.sourceName || userDataService.user.domain) + '">' + $filter(this.valueFormatter)(row[this.field], 1) + "</a>";
                    }

                    if (this.label == "FOLDER" && this.valueFormatter == "translate") {
                        return translateFolderName(row[this.field]);
                    }
                    if (this.valueFormatter === "percent") {
                        if (row["f0"] === "AVERAGES" || row["f0"] === "TOTALS")
                            return "";
                        var scale = this.scale || 0;
                        var minVal = scale === 0 ? 1 : scale === 1 ? 0.1 : scale === 2 ? 0.01 : 1;
                        return row[this.field] === 0 ? 0 : ((row[this.field] < minVal
                            ? `< ${minVal}`
                            : $filter("safeNumber")(row[this.field].toFixed(scale))) + (row[this.field] !== "" ? this.suffix : ""));
                    }
                    if (this.valueFormatter === "date_short_na") {
                        if (new Date(row[this.field]).getFullYear() <= minDate.getFullYear())
                            return $filter("translate")("NA");
                        else
                            return $filter("date")(row[this.field], "short");
                    }
                    if (this.valueFormatter === "bytes_precision")
                        return $filter("bytes")(row[this.field], 3);

                    return $filter(this.valueFormatter)(row[this.field], this.precision);
                    //return $scope.$eval("row." +  + " | " + this.valueFormatter, {row: row})+this.suffix;
                }
            }
        };

        function dateToUTC(date) {
            return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
        }

        function dateAsUTC(date) {
            return new Date(new Date(date).toISOString());
        }

        function sortFunc(a, b) {
            var sortingArr = $scope.reportData.tableEntities;
            return sortingArr.indexOf(a[0]) - sortingArr.indexOf(b[0]);
        }

        function createLineOrBarChart() {

            $scope.reportData.tableEntities = [];
            if ($scope.reportData.mode.key == 1) {
                $(".table-container > table > tbody").children().not(".tr-bolded").find("td:first-child").each(function (index) {
                    $scope.reportData.tableEntities.push($(this).text());
                });
            }

            $scope.c3Rows = [[]];

            // Set up data Rows, header row first
            var isDateReport = $scope.content.table.headers[0].format == "date";
            $.each($scope.content.table.headers, function (i, v) {
                if (i == 0 || $scope.content.chart.data.items.includes(v.label))
                    $scope.c3Rows[0].push(v.label);
            });

            $.each($scope.content.chart.data.values, function (i, v) {
                var row2 = [];
                if (isDateReport) {
                    row2.push(new Date($scope.content.table.rows[i][0]));
                    $.each(v, function (j, w) { row2.push(StringToNumberData(w)); });
                    $scope.c3Rows.push(row2);
                } else {
                    row2.push($scope.content.table.rows[i][0]);
                    $.each(v, function (j, w) { row2.push(StringToNumberData(w)); });
                    if ($scope.reportData.tableEntities.indexOf($scope.content.table.rows[i][0]) > -1) {
                        $scope.c3Rows.push(row2);
                    }
                }
            });

            if ($scope.reportData.tableEntities.length > 0) {
                var chartHeader = $scope.c3Rows[0];
                var chartRows = $scope.c3Rows.splice(1);
                chartRows.sort(sortFunc);
                $scope.c3Rows = [chartHeader].concat(chartRows);
            }

            $scope.c3Rows = isDateReport ? $scope.c3Rows.slice(0, 181) : $scope.c3Rows.slice(0, 11);

            // New C3 based charts
            $scope.c3ChartOptions = {
                bindto: '#theChart2',
                color: { pattern: $scope.colors },
                data: {
                    x: $scope.content.table.headers[0].label,
                    type: $scope.chartType == "LineChart" ? "spline" : "bar",
                    rows: $scope.c3Rows,
                    names: {}
                },
                axis: {
                    x: { type: axisType(), tick: { format: axisType() == 'category' ? undefined : axisLabel, multiline: true, culling: { max: isDateReport && $scope.reportData.step.key == 1 ? 5 : 10 } } },
                    y: { min: 0, padding: { bottom: 0 } }
                },
                point: { show: $scope.c3Rows.length < 60 },
                spline: { interpolation: { type: "monotone" } },
                grid: { y: { show: true } },
                legend: { position: "right" }
            };

            // Display names for legends and tool tips
            $.each($scope.c3ChartOptions.data.rows[0], function (i, v) {
                $scope.c3ChartOptions.data.names[v] = $filter("translate")(v);
            });

            // If bytes, override some styles
            if ($scope.content.chart.format && $scope.content.chart.format == "bytes") {
                $scope.c3ChartOptions.axis.y.tick = {
                    format: function (d) {
                        return $scope.content.chart.precision ? $filter("bytes")(d, $scope.content.chart.precision) : d < 1024 ? $filter("number")(d, 0) : $filter("bytes")(d);
                    }
                };
            } else {
                $scope.c3ChartOptions.axis.y.tick = { format: function (d) { return $filter("number")(d, 0); } };
            }

            // Generate the chart
            $($scope.c3ChartOptions.bindto).removeClass('pie');

            // Allow for stacked bar and line graph items
            if ($scope.content.chart.stacked_items) {

                var stackedItems = $scope.content.chart.stacked_items.split(",");
                $scope.c3ChartOptions.data.groups = [stackedItems];

                if ($scope.chartType === "LineChart") {
                    var types = {};
                    stackedItems.forEach(
                        (val) => {
                            types[val] = "area-spline";
                        });
                    $scope.c3ChartOptions.data.types = types;
                }
            }
            $scope.c3Chart = c3.generate($scope.c3ChartOptions);
            AddColorsToHeaders(false, false);
        };

        function axisType() {
            return $scope.content.table.headers[0].format != "date" ? 'category' : 'timeseries';
        }

        function axisLabel(x, y) {
            if (typeof x.getMonth !== "function")
                return x;
            switch ($scope.reportData.step.key) {
                case 1: // Hour
                case 4:
                    if (x.getHours() == 0)
                        return x.toLocaleDateString(localeInfoService.language);
                    else
                        return x.toLocaleDateString(localeInfoService.language) + ' ' + x.toLocaleTimeString(localeInfoService.language, { timeStyle: 'short' });
                case 5: // Week
                    return x.toLocaleDateString(localeInfoService.language);
                case 6: // Month
                    return $filter('date')(x, 'MMMM yyyy');
                case 7: // Quarter
                    if (x.getMonth() < 3)
                        return "Q1 " + x.getFullYear();
                    if (x.getMonth() < 6)
                        return "Q2 " + x.getFullYear();
                    if (x.getMonth() < 9)
                        return "Q3 " + x.getFullYear();
                    return "Q4 " + x.getFullYear();
                default:
                    return x.toLocaleDateString(localeInfoService.language);
            }
        }

        function createPieChart() {
            var pieData = [];
            var label = null;

            // For Legend Translations add a case to the switch statement below
            $scope.showChart = false;
            $.each($scope.content.chart.data, function (i, v) {
                switch (v.type) {
                    case 'center':
                        label = v.value;
                        break;
                    default:
                        if (v.value > 0) {
                            $scope.showChart = true;
                            pieData.push([translateFolderName(v.name), v.value]);
                        }
                        break;
                }
            });

            pieData.sort(sortByValPieData);
            if (pieData.length >= 8) {
                var total = 0;
                pieData.splice(8, pieData.length - 7).forEach(function (val) { total += val[1]; });
                pieData.push([$filter("translate")("OTHER"), total]);
            }

            $scope.c3ChartOptions2 = {
                bindto: '#theChart2',
                color: { pattern: $scope.colors },
                data: { columns: pieData, type: "donut" },
                donut: { title: label },
                legend: { position: "right" }
            };
            $($scope.c3ChartOptions2.bindto).addClass('pie');
            $scope.c3Chart2 = c3.generate($scope.c3ChartOptions2);

            function sortByValPieData(b, a) {
                return a[1] < b[1] ? -1 : (a[1] > b[1] ? 1 : 0);
            }
        };

        function translateFolderName(f) {
            var reservedNames = ["INBOX", "DELETED_ITEMS", "DRAFTS", "JUNK_EMAIL", "SENT_ITEMS", "PUBLIC_CHAT_FILES", "MY_FILES", "JUNK_EMAIL", "AF_CONTACTS", "AF_APPOINTMENTS", "AF_TASKS", "AF_NOTES", "AF_MISC"];
            var englishNames = ["INBOX", "DELETED ITEMS", "DRAFTS", "JUNK EMAIL", "SENT ITEMS", "PUBLIC CHAT FILES", "MY FILES", "JUNK E-MAIL"];
            for (var i = 0; i < reservedNames.length; i++) {
                var name = reservedNames[i];
                var englishname = englishNames[i];
                if (f.toUpperCase() == name || f.toUpperCase() == englishname)
                    return $filter('translate')(name);
                if (f.toUpperCase().startsWith(name + "/"))
                    return $filter('translate')(name) + f.substring(name.length);
                if (f.toUpperCase().startsWith(englishname + "/"))
                    return $filter('translate')(name) + f.substring(englishname.length);
            }
            return f;
        }

        function exportToCSV() {
            function csvAddValue(v) { csvFile += v + ","; };

            var UTF8BOM = "\uFEFF";
            var csvFile = UTF8BOM;

            $.each($scope.content.table.headers,
                function (i, v) {
                    csvAddValue($filter('translate')(v.label));
                });

            csvFile += '\n';

            $.each($scope.content.table.rows,
                function (i, v) {
                    for (var j = 0; j < v.length; j++) {
                        if ($scope.content.table.headers[j].format == 'date') {
                            if ($scope.reportData.step.key == 1)
                                csvAddValue(moment(v[j]).format('M.D.YYYY h:mm a'));
                            else
                                csvAddValue(moment(v[j]).format('M.D.YYYY'));
                        }
                        else if ($scope.content.table.headers[j].label == 'FOLDER')
                            csvAddValue(translateFolderName(v[j]));
                        else if ($scope.content.table.headers[j].label == 'TYPE')
                            csvAddValue($filter('translate')(v[j]));
                        else
                            csvAddValue(v[j]);
                    }
                    csvFile += '\n';
                });

            $.each($scope.content.table.footer,
                function (i, v) {
                    for (var j = 0; j < v.length; j++) {
                        if (j == 0)
                            csvAddValue($filter('translate')(v[j]));
                        else
                            csvAddValue(v[j]);
                    }
                    csvFile += '\n';
                });

            var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
            var filename = $scope.type + "_" + $scope.reportName.toLowerCase() + "_" + moment().format('M-D-YYYY') + ".csv";

            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, filename);
            } else {
                var link = document.createElement("a");
                if (link.download !== undefined) { // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", filename);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        };

        function exportReport(type) {
            switch (type) {
                case 0:
                    break;
                case 1:
                    exportToCSV();
                    break;
            }
        };
    }
})();