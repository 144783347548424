(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("editRssFolderController", editRssFolderController);
    
	function editRssFolderController($scope, $mdDialog, coreDataRss, name, currentFolder, parentFolder, newFolder) {
		$scope.isNewFolder = newFolder;
		$scope.name = name;
		$scope.folders = [];
	    $scope.parentFolder = parentFolder;
		activate();

		function activate() {
		    var feedTree = coreDataRss.data.feedTree;
			for (var key in feedTree.map) {
		        if (!feedTree.map.hasOwnProperty(key))
                    continue;
			    var folder = feedTree.map[key];
                if (!folder.data.isFolder ||
                    (currentFolder && folder.data.id === currentFolder.data.id))
                    continue;
                if (!newFolder && isSubFolder(folder.data.path))
                    continue;
			    $scope.folders.push(folder);
			}
		}

        function isSubFolder(folder) {
            return currentFolder && folder.startsWith(currentFolder.data.path);
		}

		$scope.submit = function (userForm) {
			if (userForm && userForm.$invalid) {
				return;
			}
            $mdDialog.hide({ name: $scope.name, parentFolderId: $scope.parentFolder.data.id });
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}
})();
