(function () {
	"use strict";

	angular
		.module('smartertools')
		.directive('isolateClick', function () {
			return {
				link: function (scope, elem) {
					elem.on('click', function (e) {
						e.stopPropagation();
					});
				}
			};
		});

	angular
		.module("smartertools")
		.directive("stLink", stLink);

	function stLink($compile, $timeout) {
		return {
			restrict: "E",
			priority: 1,
			scope: { href: "@" },
			replace: true,
			transclude: true,
			template: '<a ng-transclude target="_blank"></a>',
			link: function (scope, elem) {
				elem.mouseup(function (e) {
					e.stopPropagation();
				});
				elem.on('click', function (e) {
					e.stopPropagation();
				});
			}
		};
	}
})();