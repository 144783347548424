(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("combinedGatewaysController", combinedGatewaysController);

    function combinedGatewaysController($rootScope, $scope, $http, $state, $filter, $q, $compile, NgTableParams, gridCheckboxes, $mdDialog, errorHandling, coreDataSysAdminCards) {
        var vm = this;
        vm.items = [];
        vm.tableParams = new NgTableParams(
            {
                sorting: { address: 'asc' },
                count: 25
            }, {
                dataset: [],
                counts: $rootScope.commonTableCounts
            });
        vm.checkboxes = gridCheckboxes.init("id");
        vm.checkboxes.table = vm.tableParams;
        vm.initialized = false;

        // Functions
        vm.newItem = newItem;
        vm.deleteItems = deleteItems;
        vm.searchItems = searchItems;
        vm.editItem = editItem;
        vm.contextMenuGridItem = contextMenuGridItem;

        activate();

		//////////////////////////

		function activate() {
			loadItems();
        }


		function loadItems() {
			$q
				.all([
					$http.get("~/api/v1/settings/sysadmin/combined-gateways"),
					$http.get("~/api/v1/settings/sysadmin/gateway-spool-counts")
				])
				.then(function (results) {
					vm.items = results[0].data.combinedGatewayConfigs || [];
					var counts = results[1].data.gatewayStats || [];

					angular.forEach(vm.items, function (gateway) {
						var temp = $.grep(counts, function (stat) { return gateway.id === stat.id; });
						if (temp.length > 0) {
							gateway.spoolCount = temp[0].spoolCount;
						} else {
							gateway.spoolCount = 0;
						}
					});

					vm.tableParams.settings({ dataset: vm.items });

					$rootScope.$broadcast("gateways:countUpdated");
					refreshTable();
					vm.initialized = true;

				}, function () { });
		}

        function searchItems() {
            vm.tableParams.filter({ $: vm.searchText });
		}

		function newItem(ev) {
            $state.go("index.sysadmin-settings.gateways.combined", { id: null, mode: null });
		}

        function editItem(card, ev) {
			if (!card) return;

			$state.go("index.sysadmin-settings.gateways.combined", { id: card.id, mode: card.mode });
        }

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === item.id; })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item.id];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: 'deleteItems', click: deleteItems, params: $scope.dropdownEvent, translateKey: 'DELETE' }
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $('#context-menu-area');
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		function deleteItems(event) {
			var cards = vm.checkboxes.getCheckedItems();
			if (!cards || !cards.length)
				return;

			var confirm = $mdDialog.confirmDeletion()
				.textContent($filter('translate')('CONFIRMATIONS_DELETE_ITEMS', { items: cards.length }))
				.targetEvent(event);
			$mdDialog.show(confirm).then(doDelete, null);

			
			function doDelete() {
				$rootScope.spinner.show();
				var items = [];
				for (var i = 0; i < cards.length; i++) {
					var idMode = cards[i].split(':')
					items.push({ id: idMode[0], mode: idMode[1] });
				}

				var params = JSON.stringify({ deletionItems: items });

				$http
					.post("~/api/v1/settings/sysadmin/combined-gateways/delete", params)
					.then(onDeleteSuccess, errorHandling.report)
					.finally($rootScope.spinner.hide);

				function onDeleteSuccess() {
					loadItems();
					$rootScope.$broadcast("gateways:countUpdated");
				}
			}
		}

		function refreshTable() {
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}
    }

})();