(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("changeSpoolPriority", changeSpoolPriority);

	function changeSpoolPriority($scope, $mdDialog, count) {
		$scope.count = count;
		$scope.prioritySelection = 101;
        $scope.priorityOptions = [
            { value: 100, translation: "LOW" },
            { value: 101, translation: "NORMAL" },
            { value: 102, translation: "HIGH" }
        ];

		$scope.accept = function () {
			$mdDialog.hide($scope.prioritySelection);
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}

})();
