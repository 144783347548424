(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("adminSettingsMarketplaceController", adminSettingsMarketplaceController);

    function adminSettingsMarketplaceController($scope, $http, $rootScope, $mdDialog, $q, errorHandling, successHandling, $translate) {
        var vm = this;

        // Variables
        vm.marketplaceItems = [
            {
                name: $translate.instant('ONLYOFFICE'),
                description: $translate.instant('ONLYOFFICE_DESCRIPTION'),
                imageUrl: "img/MarketPlace/icon_onlyoffice.webp",
                enabled: false,
                tempEnabled: false, // Temporary state
                id: "onlyOffice"
            },
            {
                name: $translate.instant('COLLABORAONLINE'),
                description: $translate.instant('COLLABORAONLINE_DESCRIPTION'),
                imageUrl: "img/MarketPlace/icon_collabora.webp",
                enabled: false,
                tempEnabled: false, // Temporary state
                id: "collabora"
            }
        ];

        // Functions
        vm.save = save;
        vm.doCancel = doCancel;
        vm.refresh = refresh;

        // Activate
        activate();

        /////////

        function activate() {
            refresh();
        }

        function refresh() {
            $rootScope.spinner.show();

            $http.get("~/api/v1/settings/sysadmin/global-mail")
                .then(onMarketplaceSettingsLoaded)
                .catch(errorHandling.report)
                .finally(() => {
                    $rootScope.spinner.hide();
                    if ($scope.pageForm) {
                        $scope.pageForm.$setPristine();
                    }
                });
        }

        function onMarketplaceSettingsLoaded(response) {
            const settings = response.data.globalMailSettings || {};
            const marketplaceSettings = settings.marketplaceSettings || {};

            // Update items based on the API response
            vm.marketplaceItems.forEach(item => {
                if (item.id === "onlyOffice") {
                    item.enabled = marketplaceSettings.onlyOfficeEnabled || false;
                } else if (item.id === "collabora") {
                    item.enabled = marketplaceSettings.collaboraEnabled || false;
                }
                item.tempEnabled = item.enabled;
            });
        }

        function save(ev, form) {
            if (form.$invalid || form.$pending) {
                errorHandling.report("CORRECT_INVALID_FIELDS");
                return;
            }

            $rootScope.spinner.show();

            vm.marketplaceItems.forEach(item => {
                item.enabled = item.tempEnabled;
            });

            saveMarketplaceSettings()
                .then(() => {
                    successHandling.report($translate.instant("SUCCESS"));
                    form.$setPristine();
                    $rootScope.$broadcast('system-settings:changed');
                })
                .catch(errorHandling.report)
                .finally(() => $rootScope.spinner.hide());
        }

        function saveMarketplaceSettings() {
            const marketplaceSettings = {
                onlyOfficeEnabled: vm.marketplaceItems.find(i => i.id === "onlyOffice").enabled,
                collaboraEnabled: vm.marketplaceItems.find(i => i.id === "collabora").enabled
            };

            const params = JSON.stringify({
                globalMailSettings: {
                    marketplaceSettings: marketplaceSettings
                }
            });

            return $http.post("~/api/v1/settings/sysadmin/global-mail", params);
        }

        function doCancel(ev, form) {
            if (!form.$dirty) return;

            $mdDialog
                .show($mdDialog.confirmRevertChanges().targetEvent(ev))
                .then(() => {
                    refresh();
                    form.$setPristine();
                });
        }
    }
})();