(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("systemSslCertificateModalController", systemSslCertificateModalController);

    function systemSslCertificateModalController($rootScope, $scope, $mdDialog, $filter, $http, $translate, $timeout, $q,
        successHandling, claimsService, errorHandling, options) {
        var vm = this;
        vm.sslCertificate = options.sslCertificate || {};

        // Functions
        vm.cancel = cancel;

        activate();

        /////////////////////

        function activate() {
        }

        function cancel() {
            $mdDialog.cancel();
        }
    }
})();
