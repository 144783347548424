(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("systemForwardingBlacklistModalController", systemForwardingBlacklistModalController);

	function systemForwardingBlacklistModalController($rootScope, $scope, $mdDialog, $filter, $http, domains, info, errorHandling) {
		domains = domains || [];
		var origDomainName = angular.copy(info.domainName || "");
		$scope.domainName = info.domainName;
		
	    // Functions
		$scope.save = save;
		$scope.cancel = cancel;
		$scope.deleteItem = deleteItem;

		activate();

	    /////////////////////

		function activate() {
		}

		function save() {
			if (!$scope.forwardingBlacklistForm.$valid) {
				$scope.isValid = false;
				return;
			}

			var index = domains.indexOf(origDomainName);
			if (index > -1)
				domains.splice(index, 1);
			domains.push($scope.domainName);

			var params = JSON.stringify({
				forwardBlacklists: domains,
				toRemove: origDomainName ? [origDomainName] : null
			});
			
			$rootScope.spinner.show();
			$http
				.post("~/api/v1/settings/sysadmin/forward-blacklist", params)
                .then(onSaveSuccess, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onSaveSuccess() {
				$mdDialog.hide({});
			}
		};
		function deleteItem() {
			$mdDialog.hide({delete: true});
		}
		function cancel() {
			$mdDialog.cancel();
		};
	}

})();
