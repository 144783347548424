(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("userSettingsGeneralAccountController", userSettingsGeneralAccountController);

    function userSettingsGeneralAccountController($rootScope, $q, $scope, $http, $timeout, $filter, $translate, $mdDialog, $state, $interval,
        coreData, coreDataSettings, coreDataMail, userTimeService, errorHandling, successHandling, userDataService, browserNotifications, localeInfoService, preferencesStorage,
        localStorageService, coreLicensing, claimsService, toaster) {

        var vm = this;
        vm.data = {};
        vm.availiableTimeZones = [];
        vm.domain = userDataService.user.domain;
        vm.username = userDataService.user.username;
        vm.composeFonts = [
            { display: 'ARIAL', setting: 'arial', fontclass: 'arial' },
            { display: 'COMIC_SANS_MS', setting: 'comic sans ms', fontclass: 'comic-sans-ms' },
            { display: 'COURIER_NEW', setting: 'courier new', fontclass: 'courier-new' },
            { display: 'GEORGIA', setting: 'georgia', fontclass: 'georgia' },
            { display: 'LUCIDA', setting: 'lucida', fontclass: 'lucida' },
            { display: 'TAHOMA', setting: 'tahoma', fontclass: 'tahoma' },
            { display: 'TIMES_NEW_ROMAN', setting: 'times new roman', fontclass: 'times-new-roman' },
            { display: 'TREBUCHET_MS', setting: 'trebuchet ms', fontclass: 'trebuchet-ms' },
            { display: 'VERDANA', setting: 'verdana', fontclass: 'verdana' }
        ];
        vm.composeFontSizes = [
            { display: 8, setting: '8px' },
            { display: 9, setting: '9px' },
            { display: 10, setting: '10px' },
            { display: 11, setting: '11px' },
            { display: 12, setting: '12px' },
            { display: 14, setting: '14px' },
            { display: 16, setting: '16px' },
            { display: 18, setting: '18px' },
            { display: 20, setting: '20px' },
            { display: 22, setting: '22px' },
            { display: 24, setting: '24px' },
            { display: 26, setting: '26px' },
            { display: 28, setting: '28px' },
            { display: 36, setting: '36px' },
            { display: 48, setting: '48px' },
            { display: 72, setting: '72px' }
        ];
        vm.userStatusOptions = [
            { value: { isEnabled: true, canReceiveMail: true }, translation: 'ENABLED' },
            { value: { isEnabled: false, canReceiveMail: true }, translation: 'DOMAIN_USERS_DISABLED_ALLOW_MAIL' },
            { value: { isEnabled: false, canReceiveMail: false }, translation: 'DOMAIN_USERS_DISABLED_DISALLOW_MAIL' },
        ];

        vm.previewPaneOptions = [
            { display: "RIGHT", setting: "right" },
            { display: "NONE", setting: "none" }
        ];
        vm.showChatProviders = false;
        vm.appPasswordTypes = ['UNUSED', 'APP_PASSWORD_PSI', 'APP_PASSWORD_EAS', 'APP_PASSWORD_MAPI_EWS', 'APP_PASSWORD_WEBDAV', 'INTERNAL', 'INTERNAL', 'SERVICES', 'WEBMAIL', 'APP_PASSWORD_MAPI', 'APP_PASSWORD_LDAP', 'SHAREPOINT', 'APP_PASSWORD_XMPP'];
        vm.timeZoneSearch = '';
        vm.textEncodeSearch = '';
        vm.passwordRetrievalEnabled = false;
        vm.canChangeGreylist = false;
        vm.canChangeGal = false;
        vm.canChangePassword = false;
        //vm.selectedLocale = localeInfoService.getValidLocale(null, null, true);
        //vm.originalLocale = vm.selectedLocale;
        //$rootScope.$on("userSettings:retrieved", function() { vm.selectedLocale = localeInfoService.getValidLocale(null, null, true); vm.originalLocale = vm.selectedLocale; });

        vm.availableLocales = [];
        vm.availableIndexers = [];
        vm.forwardListView = [{ value: '' }];
        vm.canDisableTwoFactor = false;
        vm.appPasswordsShow = {};
        vm.mailForwardingEnabled = true;
        vm.mailForwardingSource = "";
        try {
            browserNotifications.checkPermissions();
            vm.browserNotificationPermission = browserNotifications.permission;
        }
        catch (exception) {
            vm.browserNotificationPermission = 'denied';
        }

        for (var i = 0; i < angularLangNames.length; i++) {
            var obj = {
                n: angularLangNames[i].n,
                v: angularLangNames[i].v,
                engName: localeInfoService.getEnglishName(angularLangNames[i].v)
            }
            vm.availableLocales.push(obj);
        }
        vm.autoCleanFolders = null;
        vm.autoCleanInheritedRules = [];
        vm.autoCleanInheritedRuleSource = null;
        vm.calendarFolders = [];
        vm.contactsFolders = [];
        vm.notesFolders = [];
        vm.tasksFolders = [];
        vm.calendarDefault = null;
        vm.contactsDefault = null;
        vm.notesDefault = null;
        vm.tasksDefault = null;
        vm.hasDelegation = false;
        vm.webdavLink = "TESTING";
        //// Functions
        // Load
        vm.reloadState = reloadState;
        vm.setLocale = setLocale;
        vm.autoCleanNewRule = autoCleanNewRule;
        vm.autoCleanEditRule = autoCleanEditRule;
        vm.autoCleanDeleteItem = autoCleanDeleteItem;
        vm.autoCleanGetCleanBottomString = autoCleanGetCleanBottomString;
        vm.onDefaultFolderChange = onDefaultFolderChange;
        // Load
        vm.openChangePassword = openChangePassword;
        vm.save = save;

        // Modal Functions
        vm.openHelpLinkForBrowserNotifications = openHelpLinkForBrowserNotifications;
        vm.openRemoteContentExceptions = openRemoteContentExceptions;
        vm.openRemoteTrackersExceptions = openRemoteTrackersExceptions;
        vm.setupTwoFactor = setupTwoFactor;
        vm.disableTwoFactor = disableTwoFactor;
        vm.resetAppPassword = resetAppPassword;
        vm.showAppPassword = showAppPassword;
        vm.copyDavToClipboard = copyDavToClipboard;

        // Helper Functions
        vm.clearSearchTerm = function () { vm.timeZoneSearch = ''; vm.textEncodeSearch = ''; };
        vm.onEmailChanged = onEmailChanged;
        vm.onEmailBlur = onEmailBlur;
        vm.emailDelete = emailDelete;
        activate();
        const availableTwoFactorTypes = [
	        { value: 1, translation: "RECOVERY_EMAIL" },
	        { value: 2, translation: "AUTHENTICATOR_APP" }
        ];
        /////////////////////////////

        // Load
        function activate() {
			vm.impersonating = claimsService.impersonating();
            vm.defaultFolderEditsPending = false;
            $rootScope.spinner.show();

            recalculateLicense();
            coreLicensing.watchForChanges($scope, recalculateLicense);

            $timeout(function () {
                $('#timeZoneSearch').on('keydown', function (ev) { ev.stopPropagation(); });
                $('#textEncodeSearch').on('keydown', function (ev) { ev.stopPropagation(); });
            });

            var promises = [
                $http.get('~/api/v1/settings/user-mail'),
                $http.get('~/api/v1/settings/mailbox-forward-list'),
                loadTimeZones(),
                coreData.init(),
                $http.get('~/api/v1/settings/from-address-options'),
                $http.get('~/api/v1/settings/available-indexers'),
                userDataService.init(),
                $http.get('~/api/v1/folders/list-owned-folders/2'), // 8 Notes
                $http.get('~/api/v1/folders/list-owned-folders/3'), // 9 Contacts
                $http.get('~/api/v1/folders/list-owned-folders/4'), // 10 Appointments
                $http.get('~/api/v1/folders/list-owned-folders/5'), // 11 Tasks
                $http.get('~/api/v1/settings/delegation')
            ];

            if (!claimsService.impersonating()) {
                promises.push($http.post('~/api/v1/settings/app-passwords'));
            }

            promises.push(coreDataMail.getFolderList().then(function (data) { vm.autoCleanFolders = data; }));
            promises.push($http.get("~/api/v1/settings/auto-clean").then(function (data) { vm.autoCleanSettings = data.data; }));

            $q
                .all(promises)
                .then(function (datas) {
                    vm.userName = coreData.user.username;
                    vm.canChangeGreylist = coreDataSettings.userPermissions.greyListEnabled;
                    vm.canChangeGal = coreDataSettings.userPermissions.allowProfileEdits && coreDataSettings.userPermissions.allowOptOutOfGal && coreDataSettings.userPermissions.featureGal;
                    vm.canChangePassword = !coreDataSettings.userPermissions.passwordLocked && !coreDataSettings.userProperties.passwordLocked;
                    vm.passwordRetrievalEnabled = coreDataSettings.userPermissions.passwordSettingsEnablePasswordRetrieval;
                    vm.canUseTwoFactor = coreDataSettings.userPermissions.enableTwoFactor;
                    vm.forceTwoFactor = coreDataSettings.userPermissions.forceTwoFactor;
                    vm.selectedLocale = localeInfoService.getValidLocale(null, null, true);
                    vm.originalLocale = vm.selectedLocale;
                    vm.twoFactorTypes =
	                    coreDataSettings.userPermissions.twoFactorTypes.map(
		                    t => availableTwoFactorTypes.find(x => x.value === t));
                    //for (var i = 0; i < coreDataSettings.userPermissions.twoFactorTypes.length; ++i) {
                    //    switch (coreDataSettings.userPermissions.twoFactorTypes[i]) {
                    //        case 0:
                    //            vm.canDisableTwoFactor = true;
                    //            break;
                    //        case 1:
                    //            vm.twoFactorTypes.push({ value: 1, translation: "RECOVERY_EMAIL" });
                    //            break;
                    //        case 2:
                    //            vm.twoFactorTypes.push({ value: 2, translation: "AUTHENTICATOR_APP" });
                    //            break;
                    //    }
                    //}

                    onSettingsLoaded(datas[0]);
                    OnForwardListLoaded(datas[1]);
                    $rootScope.spinner.hide();
                    vm.fromAddressOptions = datas[4].data.options;

                    vm.availableIndexers = datas[5].data.indexers || ['LUCENE_BASE'];

                    vm.notesFolders = datas[7].data.folders;
                    vm.notesDefault = vm.notesFolders.filter(folder => folder.isPrimary)[0];
                    vm.contactsFolders = datas[8].data.folders;
                    vm.contactsDefault = vm.contactsFolders.filter(folder => folder.isPrimary)[0];
                    vm.calendarFolders = datas[9].data.folders;
                    vm.calendarDefault = vm.calendarFolders.filter(folder => folder.isPrimary)[0];
                    vm.tasksFolders = datas[10].data.folders;
                    vm.tasksDefault = vm.tasksFolders.filter(folder => folder.isPrimary)[0];
                    vm.hasDelegation = datas[11].data.delegateAccounts && datas[11].data.delegateAccounts.length > 0;

                    if (datas[12]) {
                        vm.appPasswords = datas[12].data.devices || [];
                        for (let i = 0; i < vm.appPasswords.length; ++i) {
                            vm.appPasswordsShow[vm.appPasswords.deviceId] = false;
                        }
                    }

                    vm.features = userDataService.user.settings.features;
                    if (vm.features)
                        vm.hasChat = vm.features.enableChat && vm.edition !== 1;

                    onAutoCleanLoaded();

                    $rootScope.$broadcast("masonry:contentsChanged");
                }, function () { })
                .finally($rootScope.spinner.hide);

            function onAutoCleanLoaded() {
                var i;
                vm.autoCleanSettings.userRules = vm.autoCleanSettings.userRules || [];
                vm.autoCleanSettings.domainRules = vm.autoCleanSettings.domainRules || [];
                vm.autoCleanSettings.systemRules = vm.autoCleanSettings.systemRules || [];

                vm.autoCleanFolders = $.grep(vm.autoCleanFolders, function (folder) { return folder.isMappedFolder || folder.isMappedSubfolder || !folder.ownerEmailAddress; }, true);
                for (i = 0; i < vm.autoCleanFolders.length; ++i) {
                    vm.autoCleanFolders[i].translatedPath = autoCleanGetFolderNameTranslation(vm.autoCleanFolders[i].name);
                }
                for (i = 0; i < vm.autoCleanSettings.userRules.length; ++i) {
                    vm.autoCleanSettings.userRules[i].translatedPath = autoCleanGetExistingFolderNameTranslation(vm.autoCleanSettings.userRules[i].folderName);
                }
                for (i = 0; i < vm.autoCleanSettings.domainRules.length; ++i) {
                    vm.autoCleanSettings.domainRules[i].translatedPath = autoCleanGetFolderNameTranslation(vm.autoCleanSettings.domainRules[i].folderName);
                }
                for (i = 0; i < vm.autoCleanSettings.systemRules.length; ++i) {
                    vm.autoCleanSettings.systemRules[i].translatedPath = autoCleanGetFolderNameTranslation(vm.autoCleanSettings.systemRules[i].folderName);
                }

                if (vm.autoCleanSettings.allowAutoCleanInbox) {
                    vm.autoCleanFolders = $.grep(vm.autoCleanFolders, function (folder) { return folder.name === "Drafts"; }, true);
                } else {
                    vm.autoCleanFolders = $.grep(vm.autoCleanFolders, function (folder) { return folder.name === "Inbox" || folder.name === "Drafts"; }, true);
                }

                if (vm.autoCleanSettings.allowDomainOverride && vm.autoCleanSettings.domainOverrideActive) {
                    vm.autoCleanInheritedRuleSource = "domain";
                    vm.autoCleanInheritedRules = vm.autoCleanSettings.domainRules;
                }
                else {
                    vm.autoCleanInheritedRuleSource = "system";
                    vm.autoCleanInheritedRules = vm.autoCleanSettings.systemRules;
                }
            }

            function onSettingsLoaded(result) {
                vm.data = result.data.userMailSettings;
                vm.userStatus = vm.userStatusOptions.find(x => x.value.isEnabled === vm.data.isEnabled &&
                    x.value.canReceiveMail === vm.data.canReceiveMail);
                vm.chatProvider = vm.data.chatProvider;
                vm.data.enableGreylisting = !result.data.userMailSettings.disableGreylisting;
                vm.mailForwardingEnabled = coreDataSettings.userPermissions.enableMailForwarding && vm.data.enableMailForwarding;
                if (!coreDataSettings.userPermissions.enableMailForwardingDomainLevel) {
                    vm.mailForwardingSource = "system";
                } else if (!vm.mailForwardingEnabled) {
                    vm.mailForwardingSource = "domain";
                }

                // Find time zone object
                var tempZone = $.grep(vm.availiableTimeZones, function (zone) { return zone.index == vm.data.timeZoneIndex });
                vm.data.timeZone = tempZone.length > 0 ? tempZone[0] : vm.availiableTimeZones[0];

                // Find font object
                var tempFont = $.grep(vm.composeFonts, function (font) { return font.setting === vm.data.composeFont; });
                vm.data.composeFontObject = tempFont.length > 0 ? tempFont[0] : vm.composeFonts[0];

                // Find font size object
                var temp = $.grep(vm.composeFontSizes, function (zone) { return zone.setting == vm.data.composeFontSize });
                vm.data.composeFontSizeObject = temp.length > 0 ? temp[0] : vm.composeFontSizes[0];

                vm.plusAddressing = vm.data.plusAddressingEnabled ? vm.data.plusAddressingFolder : 'disabled';
                vm.previewPaneOption = vm.data.previewUsePreview ? "right" : "none";

                vm.webdavLink = vm.data.webdavLink;
            }

            function OnForwardListLoaded(result) {
                vm.forwardList = $.map(result.data.mailboxForwardList.forwardList, function (address) { return { value: address }; })
                if (vm.forwardList.length == 0 || vm.forwardList[vm.forwardList.length - 1].value.length > 0)
                    vm.forwardList.push({ value: '' });
                //vm.data.forwardingAddress = result.data.mailboxForwardList.forwardList || [];
                vm.data.keepRecipients = result.data.mailboxForwardList.keepRecipients;
                vm.data.deleteForwardMessages = result.data.mailboxForwardList.deleteOnForward;
                angular.copy(vm.forwardList, vm.forwardListView);
            }

            var intervalPromise = $interval(function () {
                try {
                    browserNotifications.checkPermissions();
                    if (vm.browserNotificationPermission != browserNotifications.permission) {
                        vm.browserNotificationPermission = browserNotifications.permission;
                        $scope.$applyAsync();
                    }
                }
                catch (exception) {
                    vm.browserNotificationPermission = 'denied';
                    $interval.cancel(intervalPromise);
                }
            }, 1000, 0, false);

            $scope.$on("$destroy", function () {
                $interval.cancel(intervalPromise);
            });
        }

        function recalculateLicense() {
            vm.edition = coreLicensing.edition;
            if (vm.features)
                vm.hasChat = vm.features.enableChat && vm.edition !== 1;
        }

        function reloadState(form) {
            activate();
            form.$setPristine();
        }

        function loadTimeZones() {
            var defer = $q.defer();
            coreDataSettings.settingsData.availiableTimeZones
                .then(function (success) {
                    vm.availiableTimeZones = success;
                    for (var i = 0; i < vm.availiableTimeZones.length; ++i) {
                        vm.availiableTimeZones[i].translated = $filter('translate')(vm.availiableTimeZones[i].displayName);
                    }
                    defer.resolve();
                }, function (failure) {
                    errorHandling.report(failure);
                    defer.reject();
                });
            return defer.promise;
        }

        function setLocale(val) {
            localeInfoService.setLocale(val);
            vm.selectedLocale = val;
        }

        // Modal Functions
        function openHelpLinkForBrowserNotifications() {
            const url = "https://help.smartertools.com/smartermail/current/?page=" + encodeURIComponent('browser-notifications-disabled') + "&v=" + stProductVersion;
            console.log('NAVIGATING TO HELP PAGE: ' + url);
            window.open(url, "help");
        }

        function openChangePassword(ev) {
            $mdDialog.show({
                controller: "userEditPasswordController",
                controllerAs: "ctrl",
                templateUrl: "app/settings/shared/change-user-password.dlg.html",
                targetEvent: ev
            });
        }

        function openRemoteContentExceptions(event, form) {
            $mdDialog.show({
                locals: {
                    header: $translate.instant("REMOTE_CONTENT_EXCEPTIONS"),
                    exceptions: angular.copy(vm.data.remoteContentExceptions)
                },
                controller: "remoteContentExceptionsDialogController",
                controllerAs: "ctrl",
                templateUrl: "app/settings/user-settings/account-settings/content-exceptions.dlg.html",
                targetEvent: event
            })
                .then(function (modalSuccess) {
                    vm.data.remoteContentExceptions = modalSuccess.exceptions;
                    form.$setDirty();
                }, function () { });
        }

        function openRemoteTrackersExceptions(event, form) {
            $mdDialog.show({
                locals: {
                    header: $translate.instant("REMOTE_TRACKERS_EXCEPTIONS"),
                    exceptions: angular.copy(vm.data.remoteTrackersExceptions)
                },
                controller: "remoteContentExceptionsDialogController",
                controllerAs: "ctrl",
                templateUrl: "app/settings/user-settings/account-settings/content-exceptions.dlg.html",
                targetEvent: event
            })
                .then(function (modalSuccess) {
                    vm.data.remoteTrackersExceptions = modalSuccess.exceptions;
                    form.$setDirty();
                }, function () { });
        }

        // Save
        function save(event, form, reload) {
            if (form.$invalid) {
                errorHandling.report("CORRECT_INVALID_FIELDS");
                return;
            }

            if (reload == undefined)
                reload = (vm.selectedLocale !== vm.originalLocale && !claimsService.impersonating()) ||
                    userTimeService.userTimeZone !== vm.data.timeZone ||
                    vm.defaultFolderEditsPending;

            if (vm.defaultFolderEditsPending) {

                var confirm = $mdDialog.confirmDeletion()
                    .textContent($filter("translate")("DEFAULT_FOLDERS_EDIT_WARNING"))
                    .ok($filter("translate")("OK"))
                    .targetEvent(event);
                $mdDialog
                    .show(confirm)
                    .then(doSave,
                        function () { });
            } else {
                doSave();
            }

            function doSave() {
                if (vm.mailForwardingEnabled) {
                    $http.post('~/api/v1/settings/forwardable-address', JSON.stringify({ emailAddresses: vm.forwardList.map(x => x.value) })).then(function (res) {
                        if (!res.data.success) {
                            toaster.pop("error", $translate.instant("FORWARDS_TO_X_NOT_ALLOWED", { forwardingAddress: res.data.message }));
                            $scope.$applyAsync();
                            return;
                        }
                        doSave2();
                    },function () { });
                }
                else 
                    doSave2();
            }

            function doSave2() {
                // Prepare input parameters
                var params = {
                    userMailSettings: {
                        replyToAddress: vm.data.replyToAddress,
                        timeZoneIndex: vm.data.timeZone.index,
                        backupEmailAddress: vm.data.backupEmailAddress,
                        disableGreylisting: !vm.data.enableGreylisting,
                        userContactInfo: { hideFromLdap: vm.data.userContactInfo.hideFromLdap },
                        plusAddressingFolder: vm.plusAddressing === "disabled" ? "" : vm.plusAddressing,
                        plusAddressingEnabled: vm.plusAddressing !== "disabled",
                        deleteOption: vm.data.deleteOption,
                        markPOP3DownloadsAsRead: vm.data.markPOP3DownloadsAsRead,
                        draftAutoSave: vm.data.draftAutoSave,
                        hideMailAvatars: vm.data.hideMailAvatars,
                        composeFont: vm.data.composeFontObject.setting,
                        composeFontSize: vm.data.composeFontSizeObject.setting,
                        requestReadReceipts: vm.data.requestReadReceipts,
                        requestDeliveryReceipts: vm.data.requestDeliveryReceipts,
                        replyFromToField: vm.data.replyFromToField,
                        defaultComposeDomain: vm.data.defaultComposeDomain,
                        notifyOnCalendarReminders: vm.data.notifyOnCalendarReminders,
                        notifyOnChatMessages: vm.data.notifyOnChatMessages,
                        notifyOnNewEmails: vm.data.notifyOnNewEmails,
                        localeID: vm.selectedLocale,
                        allowRemoteContent: vm.data.allowRemoteContent,
                        remoteContentExceptions: vm.data.remoteContentExceptions,
                        remoteTrackersExceptions: vm.data.remoteTrackersExceptions,
                        previewUsePreview: vm.previewPaneOption !== "none",
                        preferredIndexer: vm.data.preferredIndexer,
                        twoFactorSettings: vm.data.twoFactorSettings,
                        //chatProvider: vm.chatProvider
                    }
                };
                coreData.user.settings.userMailSettings.backupEmail = vm.data.backupEmailAddress;
                $http.post("~/api/v1/settings/user-mail", JSON.stringify(params))
                    .then(saveAutoClean, stepError)
                    .then(saveStep2, stepError)
                    .then(saveStep3, errorHandling.report);
            }

            function saveAutoClean() {
                // Set language
                if (vm.selectedLocale !== vm.originalLocale && !claimsService.impersonating()) {
                    sessionStorage.removeItem("locale.oneTime");
                    setLocale(vm.selectedLocale);
                }
                vm.originalLocale = vm.selectedLocale;

                if (!vm.autoCleanSettings.allowUserOverride)
                    return $q.when();
                var params = {
                    userOverrideActive: vm.autoCleanSettings.userOverrideActive,
                    userRules: vm.autoCleanSettings.userRules
                };
                return $http.post("~/api/v1/settings/auto-clean", JSON.stringify(params));
            }

            function stepError(response) {
                reload = false;
                errorHandling.report(response);
            }

            function saveStep2() {
                var promises = [];
                if (vm.calendarDefault && !vm.calendarDefault.isPrimary)
                    promises.push($http.post('~/api/v1/settings/set-folder-as-primary',
                        {
                            input: vm.calendarDefault.folderGuid
                        }));
                if (vm.notesDefault && !vm.notesDefault.isPrimary)
                    promises.push($http.post('~/api/v1/settings/set-folder-as-primary',
                        {
                            input: vm.notesDefault.folderGuid
                        }));
                if (vm.tasksDefault && !vm.tasksDefault.isPrimary)
                    promises.push($http.post('~/api/v1/settings/set-folder-as-primary',
                        {
                            input: vm.tasksDefault.folderGuid
                        }));
                if (vm.contactsDefault && !vm.contactsDefault.isPrimary)
                    promises.push($http.post('~/api/v1/settings/set-folder-as-primary',
                        {
                            input: vm.contactsDefault.folderGuid
                        }));

                return $q.all(promises);
            }
            function saveStep3() {
                userTimeService.userTimeZone = vm.data.timeZone;

                var addresses = $.grep(vm.forwardList, function (address) { return address.value.length > 0; });
                var forwardParams = {
                    mailboxForwardList: {
                        forwardList: $.map(addresses, function (address) { return address.value; }),
                        deleteOnForward: vm.data.deleteForwardMessages,
                        keepRecipients: vm.data.keepRecipients
                    }
                };
                $http.post('~/api/v1/settings/mailbox-forward-list', JSON.stringify(forwardParams))
                    .then(function () { onSaveSuccess(reload, form) }, errorHandling.report);
            }
        }

        function onSaveSuccess(reload, form) {
            form.$setPristine();
            $rootScope.$broadcast("user-settings:changed");
            preferencesStorage.setSortingFilteringParam("chat", "notify", vm.data.notifyOnChatMessages);
            if (reload) {
                location.reload(false);
            }
        }

        //// Helper Functions
        function onEmailChanged(last, index, form) {
            $timeout(function () {
                var email = form['email' + index].$viewValue;

                vm.forwardList[index] = { value: email };

                if (email && email.length > 0 && last)
                    vm.forwardList.push({ value: '' });

                angular.copy(vm.forwardList, vm.forwardListView);
            });
        };

        function onEmailBlur(last, index, form) {
            var email = form['email' + index].$viewValue;

            vm.forwardList[index] = { value: email };

            if ((!email || email.length === 0) && !last)
                vm.forwardList.splice(index, 1);

            angular.copy(vm.forwardList, vm.forwardListView);
        };

        function emailDelete(index, form) {
            form.$setDirty();
            vm.forwardList.splice(index, 1);
            angular.copy(vm.forwardList, vm.forwardListView);
        }

        function setupTwoFactor(form) {
	        const setupAction = vm.impersonating ? disableTwoFactor : openTwoFactorSetup;
			checkForUnsaved(setupAction, form);
        }
        function checkForUnsaved(action, form) {
            const doAction = function()
            { 
	            if (action instanceof Function) {
		            action(form);
	            } else {
		            errorHandling.report("INVALID_ACTION");
	            }
            }
	        if (form.$dirty) {
		        const confirm = $mdDialog.confirm({
			        title: $translate.instant('UNSAVED_CHANGES'),
			        textContent: $translate.instant('TWO_FACTOR_CONFIRM_UNSAVED'),
			        ok: $translate.instant('UNDO_CHANGES'),
			        cancel: $translate.instant('CANCEL')
		        });

		        $mdDialog.show(confirm).then(function (success) {
			        vm.skipNavigateConfirm = true;
			        vm.reloadState(form);
			        doAction();
		        }, function () { });
	        } else {
		        doAction();
	        }
        }
        function openTwoFactorSetup(form) {
            $mdDialog.show({
                locals: {
                    recoveryMail: vm.data.backupEmailAddress,
                    userEmail: coreData.user.emailAddress,
                    twoFactorSetupTypes: vm.twoFactorTypes
                },
                controller: "userTwoFactorController",
                controllerAs: "ctrl",
                templateUrl: "app/settings/user-settings/account-settings/user-setup-two-factor.dlg.html"
            })
                .then(function (modalSuccess) {
                    if (modalSuccess.failure) {
                        errorHandling.report(failure);
                        return;
                    } else {
                        onSaveSuccess(true, form);
                    }
                }, function (cancel) { });
        }

        function disableTwoFactor(form) {
            var confirm = $mdDialog.confirmDeletion({
                title: $translate.instant('CONFIRMATION_REQUIRED'),
                textContent: $translate.instant(vm.impersonating? 'TWO_FACTOR_WANT_RESET' : 'TWO_FACTOR_CONFIRM_DISABLE'),
                ok: $translate.instant(vm.impersonating? 'RESET' :'DISABLE'),
                cancel: $translate.instant('CANCEL')
            });
            $mdDialog.show(confirm)
                .then(function (success) {
                    var params = {
                        userMailSettings: {
                            twoFactorSettings: { type: 0 }
                        }
                    };
                    $http.post('~/api/v1/settings/user-mail', JSON.stringify(params))
                        .then(function () {
	                        vm.data.twoFactorSettings.type = 0;
	                        vm.data.twoFactorSettings.isSetup = false;
                        }, errorHandling.report);
                });
        }

        function resetAppPassword(password) {
            var confirm = $mdDialog.confirm({
                title: $translate.instant('CONFIRMATION_REQUIRED'),
                textContent: $translate.instant('APP_PASSWORD_RESET_CONFIRM'),
                ok: $translate.instant('RESET'),
                cancel: $translate.instant('CANCEL')
            });
            $mdDialog.show(confirm)
                .then(function (success) {
                    $http.post('~/api/v1/settings/reset-app-password/' + password.guid)
                        .then(function (success) {
                            $http.post('~/api/v1/settings/app-passwords')
                                .then(function (success2) {
                                    vm.appPasswords = success2.data.devices || [];
                                },
                                    errorHandling.report);
                        }, errorHandling.report);
                });
        }

        function showAppPassword(password) {
            vm.appPasswordsShow[password] = true;
        }

        function autoCleanGetFolderNameTranslation(folderName, skipDefault) {
            switch (folderName.toLowerCase()) {
                case "deleted items": return $translate.instant("DELETED_ITEMS");
                case "inbox": return $translate.instant("INBOX");
                case "junk e-mail": return $translate.instant("JUNK_EMAIL");
                case "sent items": return $translate.instant("SENT_ITEMS");
                default:
                    if (skipDefault)
                        return folderName;
                    var split = folderName.split("/");
                    split[0] = autoCleanGetFolderNameTranslation(split[0], true);
                    return split.join("/");
            }
        }

        function autoCleanGetExistingFolderNameTranslation(folderName) {
            folderName = folderName.toLowerCase();
            for (var j = 0; j < vm.autoCleanFolders.length; ++j) {
                if (folderName === vm.autoCleanFolders[j].path.toLowerCase())
                    return vm.autoCleanFolders[j].translatedPath;
            }
        }

        function autoCleanNewRule(ev, form) {
            var defaultFolder = "Deleted Items";
            for (var i = 0; i < vm.autoCleanFolders.length; i++) {
                if (vm.autoCleanFolders[i].name.toLowerCase() === "deleted items") {
                    defaultFolder = vm.autoCleanFolders[i].name;
                    break;
                }
            }
            $mdDialog
                .show({
                    locals: {
                        folderName: defaultFolder,
                        folders: vm.autoCleanFolders,
                        processGoal: 75,
                        processThreshold: 100,
                        thresholdDays: 30,
                        type: 0,
                        enabled: true,
                        newItem: true
                    },
                    controller: "userEditAutoCleanController",
                    controllerAs: "ctrl",
                    templateUrl: "app/settings/shared/edit-auto-clean.dlg.html",
                    targetEvent: ev
                })
                .then(onNewRuleOk, function () { });

            function onNewRuleOk(modalSuccess) {
                modalSuccess.rule.processGoalBytes = modalSuccess.rule.processGoalMb * 1024 * 1024;
                modalSuccess.rule.processThresholdBytes = modalSuccess.rule.processThresholdMb * 1024 * 1024;
                modalSuccess.rule.translatedPath = autoCleanGetExistingFolderNameTranslation(modalSuccess.rule.folderName);

                vm.autoCleanSettings.userRules.push(modalSuccess.rule);
                form.$setDirty();
                $rootScope.$broadcast("masonry:contentsChanged");
            }
        }

        function autoCleanEditRule(ev, rule, form) {
            $mdDialog
                .show({
                    locals: {
                        folderName: rule.folderName,
                        folders: vm.autoCleanFolders,
                        processGoal: rule.processGoalMb,
                        processThreshold: rule.processThresholdMb,
                        thresholdDays: rule.thresholdDays,
                        type: rule.type,
                        enabled: rule.enabled,
                        newItem: false
                    },
                    controller: "userEditAutoCleanController",
                    controllerAs: "ctrl",
                    templateUrl: "app/settings/shared/edit-auto-clean.dlg.html",
                    targetEvent: ev
                })
                .then(onEditRuleOk, function () { });

            function onEditRuleOk(modalSuccess) {
                if (modalSuccess.deleteItem) {
                    autoCleanDeleteItem(rule, form);
                    return;
                }
                modalSuccess.rule.id = rule.id;
                modalSuccess.rule.processGoalBytes = modalSuccess.rule.processGoalMb * 1024 * 1024;
                modalSuccess.rule.processThresholdBytes = modalSuccess.rule.processThresholdMb * 1024 * 1024;
                modalSuccess.rule.translatedPath = autoCleanGetExistingFolderNameTranslation(modalSuccess.rule.folderName);

                autoCleanDeleteItem(rule, form);
                vm.autoCleanSettings.userRules.push(modalSuccess.rule);
                form.$setDirty();
                $rootScope.$broadcast("masonry:contentsChanged");
            }
        }

        function autoCleanDeleteItem(rule, form) {
            var index = vm.autoCleanSettings.userRules.indexOf(rule);
            if (index !== -1)
                vm.autoCleanSettings.userRules.splice(index, 1);
            form.$setDirty();
            $rootScope.$broadcast("masonry:contentsChanged");
        }

        function autoCleanGetCleanBottomString(rule) {
            switch (rule.type) {
                case 0:
                    var over = $filter("bytes")(rule.processThresholdBytes, 1);
                    var reduce = $filter("bytes")(rule.processGoalBytes, 1);
                    return $filter("translate")("AUTO_CLEAN_SUMMARY_SIZE", { over: over, reduce: reduce });
                case 1:
                    return $filter("translate")("AUTO_CLEAN_SUMMARY_TIME_IN_FOLDER", { days: rule.thresholdDays });
                case 2:
                    return $filter("translate")("AUTO_CLEAN_SUMMARY_MESSAGE_AGE", { days: rule.thresholdDays });
            }
        }

        function onDefaultFolderChange() {
            vm.defaultFolderEditsPending =
                (vm.tasksDefault && !vm.tasksDefault.isPrimary) ||
                (vm.notesDefault && !vm.notesDefault.isPrimary) ||
                (vm.tasksDefault && !vm.contactsDefault.isPrimary) ||
                (vm.calendarDefault && !vm.calendarDefault.isPrimary);
        }

        function copyDavToClipboard() {
            var textArea = document.createElement("textarea");
            textArea.value = vm.webdavLink || "";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            document.execCommand("copy");
            document.body.removeChild(textArea);
            successHandling.report("COPIED_TO_CLIPBOARD");
        }

        vm.editRocketChatToken = function(ev) {
            const onGenerateTokenSuccess = function(data) {
                vm.chatProvider.personalAccessToken = data.personalAccessToken;
                vm.chatProvider.serviceUserId = data.userId;
                vm.chatProvider.serverUrl = data.serverUrl;
            };
            $mdDialog
                .show({
                    locals: {
                        serverUrl: vm.chatProvider.serverUrl,
                        username: vm.username,
                        personalAccessToken: vm.chatProvider.personalAccessToken,
                        tokenName: "SmarterMail",
                        title: $filter("translate")("GENERATE_ACCESS_TOKEN")
                    },
                    controller: "rocketChatPersonalAccessTokenDialog",
                    controllerAs: "ctrl",
                    templateUrl: "app/settings/shared/rocket-chat-token.dlg.html",
                    targetEvent: ev
                })
                .then(onGenerateTokenSuccess, function () { });

        }
        vm.testRocketChatToken = function(ev) {
            if (vm.chatProvider.providerType !== 1) 
                return;
            $http.post("/api/v1/settings/test-rocket-chat", JSON.stringify(vm.chatProvider))
                .then(function() {
                        vm.rocketChatTestFailed = false;
                        successHandling.report();
                    },
                    function(fail) {
                        vm.rocketChatTestFailed = true;
                    });
        }
    }
})();