(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("spamAssassinSpamCtrl", spamAssassinSpamCtrl);

	function spamAssassinSpamCtrl($rootScope, $scope, $mdDialog, $http, errorHandling, cardType) {

		$scope.type = cardType;
		activate();

		/////////////////

		function activate() {
			$rootScope.spinner.show();
			$http
				.get("~/api/v1/settings/sysadmin/antispam/settings")
				.then(onLoad, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onLoad(result) {
				switch (cardType) {
					case "SPAMASSASSIN":
						$scope.item = angular.copy(result.data.settings.spamAssassin);
						break;
					case "REMOTESPAMASSASSIN":
						$scope.item = angular.copy(result.data.settings.remoteSpamAssassin);
						break;
					default:
						$mdDialog.cancel();
						break;
				}
			}
		}

		$scope.cancel = function () {
			$mdDialog.cancel();
		};

		$scope.save = function () {
			if (!$scope.spamForm.$valid)
				return;

			var params;
			switch (cardType) {
				case "SPAMASSASSIN":
					params = JSON.stringify({ spamAssassin: $scope.item });
					break;
				case "REMOTESPAMASSASSIN":
					params = JSON.stringify({ remoteSpamAssassin: $scope.item });
					break;
				default:
					return;
			}

			$rootScope.spinner.show();
			$http
				.post("~/api/v1/settings/sysadmin/antispam/settings", params)
				.then($mdDialog.hide, errorHandling.report)
				.finally($rootScope.spinner.hide);
		};
	}
})();