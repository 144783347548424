(function () {
	"use strict";

	angular
		.module("smartertools")
		.directive("validateLinkExists", validateLinkExists);

	function validateLinkExists($http, $q) {
	    return {
	        require: "ngModel",
	        link: function (scope, element, attrs, ctrl) {
				ctrl.$asyncValidators.validateLinkExists = function (modelValue, viewValue) {
					if (!viewValue) return $q.when(true);
					return $http.post('~/api/v1/rss/validate-link-exists', { input: viewValue })
	            };
	        }
	    };
	}
})();