(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("emailFunctions", emailFunctions);

    /*
     * IMPORTANT NOTE:
     * 
     * Most of the functions defined in this service do not do error checking.It is your responsibility
     * to handle exceptions and display them, usually using errorHandling.report(err).
     * 
     * Exceptions due to modal cancellations WILL be handled by this service, however. Exceptions should only
     * be thrown for exceptional events or issues, not for user cancellations.
     * 
     */

    function emailFunctions($rootScope, $http, $mdDialog, $timeout, $translate, $filter, coreDataFileStorage, coreDataSettings, popupService, successHandling,
        coreData, coreDataCategories, coreDataTasks, coreDataMail, emailNavigation) {
        var vm = this;

        // Functions
        vm.loadMessage = loadMessage;
        vm.markRead = markRead;
        vm.rejectReadReceipt = rejectReadReceipt;
        vm.downloadEmlFile = downloadEmlFile;
        vm.popupReplyWindow = popupReplyWindow;
        vm.markSpam = markSpam;
        vm.markNotSpam = markNotSpam;
        vm.blockSender = blockSender;
        vm.unblockSender = unblockSender;
        vm.trustSender = trustSender;
        vm.untrustSender = untrustSender;
        vm.popupAddTaskModal = popupAddTaskModal;
        vm.popupInviteToMeetingPopup = popupInviteToMeetingPopup;
        vm.showPrintPopup = showPrintPopup;
        vm.showPrintPopupAfterLoad = showPrintPopupAfterLoad;
        vm.loadRawContent = loadRawContent;

        ////////////////////////////////////////

        async function loadMessage(owner, folder, uid, isForward) {
            const params = JSON.stringify({
                OwnerEmailAddress: owner,
                Folder: folder,
                UID: uid
            });
            const url = isForward ? '~/api/v1/mail/message/forward' : '~/api/v1/mail/message';
            const success = await $http.post(url, params);
            return success.data.messageData || null;
        }

        async function markRead(ownerEmail, folder, uids) {
            uids = cleanUidList(uids);
            if (uids.length == 0)
                return;

            const parameters = {
                "ownerEmailAddress": ownerEmail,
                "folder": folder,
                "UID": uids,
                "markRead": true
            };
            await $http.post("~/api/v1/mail/messages-patch", parameters);
        }

        async function rejectReadReceipt(ownerEmail, folder, uid) {
            var parameters = {
                'ownerEmailAddress': ownerEmail,
                'folder': folder,
                'UID': uid
            };
            await $http.post("~/api/v1/mail/readreceipt/reject", parameters);
        }

        async function downloadEmlFile(owner, folder, uids) {
            uids = cleanUidList(uids);

            if (uids.length === 0)
                throw $translate.instant("MUST_SELECT");

            const progressTimeout = $timeout(function () {
                const progress = $mdDialog.stProgressBar()
                    .title($translate.instant("DOWNLOADING"))
                    .barText("")
                    .close($translate.instant("DOWNLOAD_IN_BACKGROUND"))
                    .showCancel(false)
                    .enableButtons(true)
                    .autoClose(true)
                    .percentProgress(-1)
                    .guid("eml-download")
                    .progressListener("eml-download.finished");
                $mdDialog.show(progress);
            }, 1000);

            try {
                const httpPath = "~/api/v1/mail/messages-export";
                const messageCountString = $translate.instant("FILENAME_MESSAGES_WITH_COUNT", { count: uids.length });
                const displayFilename = uids.length === 1
                    ? `${messageCountString}-${uids[0]}.eml`
                    : `${messageCountString}.zip`;
                const parameters = {
                    'OwnerEmailAddress': owner,
                    'Folder': folder,
                    'UID': uids,
                };

                await coreDataFileStorage.downloadFile(httpPath, displayFilename, parameters);
            } finally {
                $timeout.cancel(progressTimeout);
                $rootScope.$broadcast("eml-download.finished", { guid: "eml-download", current: -1 });
            }
        }

        /// returns the opened window, or null if an invalid uid was passed
        function popupReplyWindow(owner, folder, uid, mode) {
            if (!uid)
                return null;

            const packet = emailNavigation.makeComposePacket({
                owner: owner,
                folder: folder,
                uid: uid,
                reply: mode || 1,
            });
            const url = emailNavigation.getPopoutComposeUrl(packet);
            return window.open(url, "", 'resizable=1, ' + popupService.dimensions.email);
        }

        async function markSpam(ownerEmail, folder, uids) {
            uids = cleanUidList(uids);
            var parameters = {
                "ownerEmailAddress": ownerEmail,
                "folder": folder,
                "UID": uids,
                markSpam: true
            };
            await $http.post("~/api/v1/mail/messages-patch", parameters);
        }

        async function markNotSpam(ownerEmail, folder, uids) {
            uids = cleanUidList(uids);
            var parameters = {
                "ownerEmailAddress": ownerEmail,
                "folder": folder,
                "UID": uids,
                markNotSpam: true
            };
            await $http.post("~/api/v1/mail/messages-patch", parameters);
        }

        /// Returns true if the sender was blocked, handles notification popups on some items failing
        async function blockSender(owner, folder, addresses, showGalWarning, reportFailuresThroughModal, isSender) {
            try {
                var parameters = {
                    blockedSenders: addresses,
                    sourceFolder: folder,
                    sourceOwner: owner,
                    isSender: isSender
                };

                const response = await $http.post("~/api/v1/settings/block-senders", parameters);

                var translation = (response.data.results.length == Object.keys(addresses).length && !showGalWarning) ? "SENDERS_BLOCKED_" : "CONFIRMATIONS_BLOCKED_NOT_GAL_";

                const action = coreDataSettings.userSettings.blockedSenderAction;
                switch (action) {
                    case 1:
                        translation += folder.toLowerCase() != "junk e-mail" ? action : "0";
                        break;
                    case 2:
                        translation += folder.toLowerCase() != "deleted items" ? action : "0";
                        break;
                    default:
                        translation += action;
                        break;
                }

                if (response.data.results.length == Object.keys(addresses).length) {
                    let modalText = $translate.instant("SENDERS_BLOCKED_0");
                    if (translation !== "SENDERS_BLOCKED_0")
                        modalText += " " + $translate.instant(translation);
                    successHandling.report(modalText);
                } else {
                    let modalText = $translate.instant("CONFIRMATIONS_BLOCKED_NOT_GAL_0");
                    if (translation !== "CONFIRMATIONS_BLOCKED_NOT_GAL_0")
                        modalText += " " + $translate.instant(translation);
                    if (reportFailuresThroughModal) {
                        let confirm = $mdDialog.notify()
                            .title($translate.instant("BLOCKED_SENDERS"))
                            .textContent(modalText)
                            .ok($translate.instant("OK"));
                        $mdDialog.show(confirm);
                    } else {
                        successHandling.report(translation);
                    }
                }

                coreDataSettings.userSettings.blockedSenders = (coreDataSettings.userSettings.blockedSenders || [])
                    .concat(response.data.results);
                $rootScope.$broadcast('restricted-addresses-updated');
                return true;

            } catch (err) {
                return false;
            }
        }

        async function unblockSender(owner, folder, addresses, isSender) {
            var parameters = {
                blockedSenders: addresses,
                sourceFolder: folder,
                sourceOwner: owner,
                isSender: isSender
            };

            const response = await $http.post('~/api/v1/settings/unblock-senders', parameters);
            var translation = !isSender || folder.toLowerCase() === 'inbox'
                ? 'SENDERS_UNBLOCKED'
                : 'SENDERS_UNBLOCKED_MOVED';
            successHandling.report(translation);

            for (var i = coreDataSettings.userSettings.blockedSenders.length - 1; i >= 0; i--) {
                if (response.data.results.indexOf(coreDataSettings.userSettings.blockedSenders[i]) > -1)
                    coreDataSettings.userSettings.blockedSenders.splice(i, 1);
            }
            $rootScope.$broadcast('restricted-addresses-updated');
        }

        async function trustSender(owner, folder, uids, isSender) {
            var parameters = {
                uids: uids,
                sourceOwner: owner,
                sourceFolder: folder,
                isSender: isSender
            };

            const success = await $http.post('~/api/v1/mail/trust-senders', parameters);
            successHandling.report(!isSender || folder.toLowerCase() !== 'junk e-mail' ? 'SENDERS_TRUSTED' : 'SENDERS_TRUSTED_MOVED');

            coreDataSettings.userSettings.whitelistAddresses = (coreDataSettings.userSettings.whitelistAddresses || [])
                .concat($.map(success.data.results, function (address) { return { value: address }; }));

            $rootScope.$broadcast('restricted-addresses-updated');
        }

        async function untrustSender(owner, folder, uids, isSender) {
            var parameters = {
                uids: uids,
                sourceOwner: owner,
                sourceFolder: folder,
                isSender: isSender
            };

            const success = await $http.post('~/api/v1/mail/untrust-senders', parameters);

            if (success.data.results.length == Object.keys(uids).length) {
                successHandling.report("SENDERS_UNTRUSTED");
            } else {
                var confirm = $mdDialog.confirmDeletion()
                    .textContent($translate.instant("CONFIRMATIONS_UNTRUSTED_NOT_GAL"))
                    .ok($translate.instant('OK'));
                $mdDialog.show(confirm);
            }

            for (var i = coreDataSettings.userSettings.whitelistAddresses.length - 1; i >= 0; i--) {
                if (success.data.results.indexOf(coreDataSettings.userSettings.whitelistAddresses[i].value.toLowerCase()) > -1)
                    coreDataSettings.userSettings.whitelistAddresses.splice(i, 1);
            }

            $rootScope.$broadcast('restricted-addresses-updated');

        }

        async function popupAddTaskModal(message) {
            var newWindow;
            var start = moment();
            start.minute(5 * Math.round(start.minute() / 5));
            start.second(0);

            try {
                $rootScope.spinner.show();
                const result = await Promise.all([
                    coreDataCategories.getUsersCategories(coreData.user.username),
                    coreDataTasks.init()
                ]);

                var categories = result[0].data;
                categories = $filter("orderBy")(categories, "name");

                var newTask = {
                    id: null,
                    sourceId: null,
                    sourceName: "",
                    sourceOwner: "",
                    subject: "",
                    start: start.toDate(),
                    due: moment(start).add(1, "h").toDate(),
                    reminderIndex: 0,
                    priority: 0,
                    status: 0,
                    percentComplete: 0,
                    description: "",
                    attachedMessageId: message.messageID,
                    attachedMessage: {
                        date: message.date,
                        folder: message.folder,
                        from: message.from,
                        owner: message.ownerEmailAddress,
                        subject: message.subject,
                        to: message.to,
                        uid: message.uid
                    },
                    sourcePermission: 8
                };

                //I cant use authStorage.setPopoutData here because the window has already been opened and something about that makes it not apply.
                newWindow = window.open("", "", "resizable=1, " + popupService.dimensions.task);
                newWindow.sessionStorage.popoutData = JSON.stringify({ isTask: true, isPopout: true, isNew: true, taskInfo: newTask, categories: categories });
                if (window.location.href.indexOf('popout') > 0) {
                    newWindow.location.href = window.location.href.slice(0, window.location.href.indexOf('/popout/email')) + '/popout/task/new';
                } else {
                    newWindow.location.href = window.location.hash.replace('/email', '/popout/task/new');
                }
            } catch (err) {
                newWindow.close();
                throw err;
            } finally {
                $rootScope.spinner.hide();
            }
        }

        async function popupInviteToMeetingPopup(message) {
	        const RecipientToAttendee = function (result, recipient) {
		        if (recipient && recipient.email && 
			        recipient.email.toLowerCase() !== coreData.user.emailAddress.toLowerCase() &&
			        !result.some(att => att.email === recipient.email.toLowerCase()))
			        result.push({
				        name: recipient.name,
				        email: recipient.email.toLowerCase(),
				        status: 0,
				        nonavail: null
			        });
		        return result;
	        }
            //'#/popout/appointment/new', '_blank', 'resizable=1, ' + popupService.dimensions.calendar
            const baseUrl = window.location.href.substring(0, window.location.href.indexOf('#/'));
            let newMeetingWindow = null;
            try {
	            const recipients = [].concat(message.toAddresses, message.ccAddresses);
	            if (message.fromAddress) recipients.push(message.fromAddress);
                const attendeesList = recipients.reduce(RecipientToAttendee, []);
                
                newMeetingWindow = window.open(`${baseUrl}#/popout/appointment/new`, "_blank", "resizable=1, " + popupService.dimensions.calendar);

	            newMeetingWindow.localStorage.apptPopout = 
		            JSON.stringify({
			            data: {
				            organizerName: coreData.user.displayName,
				            organizerEmail: coreData.user.emailAddress,
				            attendees: attendeesList,
                            subject: message.subject, 
				            // description: message.messageHTML || message.messagePlainText,
							//isHtml: message.messageHTML !== null
			            }
		            });

            } catch (err) {
                if (newMeetingWindow)
					newMeetingWindow.close();
	            throw err;
            } finally {
	            $rootScope.spinner.hide();
            }

        }

        async function showPrintPopup(message) {
            try {
                $rootScope.spinner.show();

                var printData = {
                    messageData: JSON.parse(JSON.stringify(message)), // Deep copy the object
                    translations: {
                        from: $translate.instant("FROM"),
                        to: $translate.instant("TO"),
                        cc: $translate.instant("CC"),
                        bcc: $translate.instant("BCC"),
                        attachments: $translate.instant("ATTACHMENTS")
                    }
                };

                printData.messageData.date = $filter("date")(printData.messageData.date, "short");
                printData.messageData.messageHTML = printData.messageData.messageHTML || printData.messageData.messageHtml;

                if (printData.messageData.messageHTML) {
                    var html = $("<div>");
                    html.html(printData.messageData.messageHTML);
                    html.find("hr").css("break-after", "");
                    printData.messageData.messageHTML = html.html();
                    if (!printData.messageData.mode)
                        printData.messageData.mode = "html";
                }

                const printWindow = window.open(coreDataMail.baseUrl + "/print/printmessage", `emailID${message.uid}/P`, `resizable=1, ${popupService.dimensions.email}`);
                printWindow.onload = () => {
                    printWindow.loadMessage(printData);
                };
            } finally {
                $rootScope.spinner.hide();
            }
        }

        async function showPrintPopupAfterLoad(ownerEmail, folder, uid, mode, isArchiveMessage) {
            var url = isArchiveMessage ? "~/api/v1/mail/archive-message" : "~/api/v1/mail/message";
            var parameters = {
                'Folder': folder,
                'UID': uid,
                'OwnerEmailAddress': ownerEmail
            };

            var success = await $http.post(url, parameters);
            success.data.messageData.mode = mode;
            await showPrintPopup(success.data.messageData);
        }

        async function loadRawContent(ownerEmail, folder, uid, isArchiveMessage) {
            const parameters = {
                'Folder': folder,
                'UID': uid,
                'OwnerEmailAddress': ownerEmail
            };
            const success = await $http.post(isArchiveMessage ? '~/api/v1/mail/archive-message/raw' : "~/api/v1/mail/message/raw", parameters);
            return success.data;
        }

        // INTERNAL HELPERS -------------------------------------------------------

        function cleanUidList(uids) {
            if (uids === null || uids === undefined)
                return [];
            if (!Array.isArray(uids))
                uids = [uids];
            return uids;
        }
    }
})();
