(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("viewAttachmentsController", viewAttachmentsController);

	function viewAttachmentsController($scope, $mdDialog, attachmentName) {
		$scope.attachmentName = attachmentName;

		$scope.close = function () {
			$mdDialog.hide();
		};
	}
})();
