(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminSecuritySmtpBlockingController", sysAdminSecuritySmtpBlockingController);

	function sysAdminSecuritySmtpBlockingController($rootScope, $scope, $http, $filter, $mdDialog, $compile,
		NgTableParams, gridCheckboxes,  errorHandling, coreDataFileStorage, $translate) {
		var vm = this;
		vm.items = [];
		vm.tableParams = new NgTableParams(
			{
				sorting: { addressToBlock: 'asc' },
				count: 25
			}, {
				dataset: [],
				counts: $rootScope.commonTableCounts
			});
		vm.checkboxes = gridCheckboxes.init("id");
		vm.checkboxes.table = vm.tableParams;
		vm.initialized = false;

		// Functions
		vm.newItem = newItem;
		vm.deleteItems = deleteItems;
		vm.searchItems = searchItems;
		vm.editItem = editItem;
		vm.contextMenuGridItem = contextMenuGridItem;
        vm.exportSecuritySettings = exportSecuritySettings;
        vm.importSecuritySettings = importSecuritySettings;

		activate();

		//////////////////////////

		function activate() {
			loadItems();
		}

		function loadItems() {
			$http
				.get('~/api/v1/settings/sysadmin/smtp-block-rules')
				.then(function (result) {
					vm.items = result.data.blockRules || [];
					vm.tableParams.settings({ dataset: vm.items });
					refreshTable();
					vm.initialized = true;
				}, function () {});
		}

		function searchItems() {
			vm.tableParams.filter({ $: vm.searchText });
		}

		function newItem(ev) {
			$mdDialog.show({
				locals: {
					blockType: 0,
					blockedAddress: "",
					direction: 1,
					description: "",
					isNew: true
				},
				controller: "sysAdminEditSmtpBlockingController",
				controllerAs: "ctrl",
				templateUrl: "app/sysadmin/settings/security/modals/smtp-block.dlg.html",
				targetEvent: ev
			})
				.then(onModalSuccess);

			function onModalSuccess(modalSuccess) {
				var toAdd = [];
				var addresses = modalSuccess.blockedAddress.split(",");
				for (var i = 0; i < addresses.length; ++i) {
					toAdd.push({
						type: modalSuccess.blockType,
						addressToBlock: addresses[i].replace(/\s+/g, ''),
						direction: modalSuccess.direction,
						description: modalSuccess.description
					});
				}
				var params = JSON.stringify({
					toAdd: toAdd
				});
				saveBlocks(params);
			}
		}

		function editItem(card, ev) {
			$mdDialog.show({
				locals: {
					blockType: card.type,
					blockedAddress: card.addressToBlock,
					direction: card.direction,
					description: card.description,
					isNew: false
				},
				controller: "sysAdminEditSmtpBlockingController",
				controllerAs: "ctrl",
				templateUrl: "app/sysadmin/settings/security/modals/smtp-block.dlg.html",
				targetEvent: ev
			})
				.then(onModalSuccess);

			function onModalSuccess(modalSuccess) {
				var blockRules = [];
				var addresses = modalSuccess.blockedAddress.split(",");
				for (var i = 0; i < addresses.length; ++i) {
					blockRules.push({
						type: modalSuccess.blockType,
						addressToBlock: addresses[i].replace(/\s+/g, ''),
						direction: modalSuccess.direction,
						description: modalSuccess.description,
						id: card.id
					});
				}
				var params = JSON.stringify({
					blockRules: blockRules
				});
				saveBlocks(params);
			}
		}

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === item.id; })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item.id];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: 'deleteItems', click: deleteItems, params: $scope.dropdownEvent, translateKey: 'DELETE' }
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $('#context-menu-area');
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		function saveBlocks(params) {
			if (!params) return;

			$rootScope.spinner.show();
			$http
				.post('~/api/v1/settings/sysadmin/smtp-block-rules', params)
				.then(onSaveSuccess, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onSaveSuccess() {
				loadItems();
				$rootScope.$broadcast("security:countersChanged");
		  }
		}

		function deleteItems(event) {
			var addresses = vm.checkboxes.getCheckedItems();
			if (!addresses || !addresses.length)
				return;

			var confirm = $mdDialog.confirmDeletion()
				.textContent($filter('translate')('CONFIRMATIONS_DELETE_ITEMS', { items: addresses.length }))
				.targetEvent(event);
			$mdDialog
				.show(confirm)
				.then(onConfirmed);

			function onConfirmed() {
				var params = JSON.stringify({ toRemove: addresses });
				saveBlocks(params);
			}
		}

		function refreshTable() {
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}
        function exportSecuritySettings() {
            var httpPath = "~/api/v1/settings/sysadmin/export-security-settings/smtpBlockRules";
            var fileName = $filter("translate")("FILENAME_SECURITY_CONFIG") + "-" + $filter("translate")("SMTP_BLOCKS") + ".json";

            $rootScope.spinner.show();
            coreDataFileStorage
                .downloadFile(httpPath, fileName)
                .then(onExportSuccess, errorHandling.report)
                .finally($rootScope.spinner.hide);

            function onExportSuccess() { }
        }
        function importSecuritySettings(ev) {
            $mdDialog
                .show({
                    locals: {
                        titleText: $translate.instant("IMPORT") + " " +  $translate.instant("SMTP_BLOCKS")
                    },
                    controller: "sysAdminImportSecuritySettingsController",
                    controllerAs: "ctrl",
                    templateUrl: "app/sysadmin/settings/security/modals/import-security-settings.dlg.html",
                    targetEvent: ev
                })
                .then(function () {
                    loadItems();
                    $rootScope.$broadcast("security:countersChanged");
                });
        }
	}

})();
