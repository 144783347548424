(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminEditAbuseDetectionController", sysAdminEditAbuseDetectionController);

	function sysAdminEditAbuseDetectionController($scope, $mdDialog, $timeout, detectionType, action, tempBlockWatchPeriod, tempBlockConnectionThreshold, tempBlockPeriod, description) {
		var vm = this;

		$scope.detectionType = detectionType;
		$scope.action = action;
		$scope.tempBlockWatchPeriod = tempBlockWatchPeriod;
		$scope.tempBlockConnectionThreshold = tempBlockConnectionThreshold;
		$scope.description = description;
		$scope.isValid = true;

		vm.tempBlockPeriod = tempBlockPeriod;

		$scope.save = save;
		$scope.cancel = cancel;

		activate();

		function activate () {
		}

		function save (form) {
			form.$setSubmitted();
			if (!($scope.isValid = form.$valid)) {
				return;
			}
			
			$mdDialog.hide({
				detectionType: $scope.detectionType,
				action: $scope.action,
				tempBlockWatchPeriod: $scope.tempBlockWatchPeriod,
				tempBlockConnectionThreshold: $scope.tempBlockConnectionThreshold,
				tempBlockPeriod: vm.tempBlockPeriod,
				description: $scope.description,
			});
		}

		function cancel () {
			$mdDialog.cancel();
		}
	}

})();
