(function () {
	"use strict";

	angular
		.module("smartertools")
		.service("localeInfo", localeInfo);

	function localeInfo() {
		var rtlLanguages;
		var vm = this;
		vm.isRtl = isRtl;
		vm.applyBodyDirAttribute = applyBodyDirAttribute;

		activate();

		///////////////////

		function activate() {
			rtlLanguages =
			{
				"ar": 1,
				"ar-001": 1,
				"ar-AE": 1,
				"ar-BH": 1,
				"ar-DJ": 1,
				"ar-DZ": 1,
				"ar-EG": 1,
				"ar-ER": 1,
				"ar-IL": 1,
				"ar-IQ": 1,
				"ar-JO": 1,
				"ar-KM": 1,
				"ar-KW": 1,
				"ar-LB": 1,
				"ar-LY": 1,
				"ar-MA": 1,
				"ar-MR": 1,
				"ar-OM": 1,
				"ar-PS": 1,
				"ar-QA": 1,
				"ar-SA": 1,
				"ar-SD": 1,
				"ar-SO": 1,
				"ar-SS": 1,
				"ar-SY": 1,
				"ar-TD": 1,
				"ar-TN": 1,
				"ar-YE": 1,
				"dv": 1,
				"dv-MV": 1,
				"fa": 1,
				"fa-IR": 1,
				"he": 1,
				"he-IL": 1,
				"ku": 1,
				"ku-Arab": 1,
				"ku-Arab-IQ": 1,
				"nqo": 1,
				"nqo-GN": 1,
				"pa-Arab": 1,
				"pa-Arab-PK": 1,
				"prs": 1,
				"prs-AF": 1,
				"ps": 1,
				"ps-AF": 1,
				"sd": 1,
				"sd-Arab": 1,
				"sd-Arab-PK": 1,
				"syr": 1,
				"syr-SY": 1,
				"tzm-Arab": 1,
				"tzm-Arab-MA": 1,
				"ug": 1,
				"ug-CN": 1,
				"ur": 1,
				"ur-IN": 1,
				"ur-PK": 1,
				"yi": 1,
				"yi-001": 1
			}
		}

		function isRtl(locale) {
			return !!rtlLanguages[locale];
		}

		function applyBodyDirAttribute(locale) {
			var dir = isRtl(locale) ? "RTL" : "";
			$("BODY").attr("dir", dir);
		}
	}
})();