(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("adminConnectionsAllController", adminConnectionsAllController);

	function adminConnectionsAllController($scope, $filter, $mdDialog, $rootScope, $http, $localStorage, $compile,
		NgTableParams, errorHandling, successHandling, coreDataSpool, gridCheckboxes) {

		var vm = this;
		$scope.gridColumns = [
			{ sortField: 'ipAddress', translate: 'IP_ADDRESS' },
			{ sortField: 'location', translate: 'PORT' },
			{ sortField: 'loginDate', translate: 'CONNECTIONS' },
			{ sortField: 'timeTranslate', translate: 'SSL_TLS' }
		];
		vm.searchText = '';
		vm.queryUrl = "~/api/v1/settings/sysadmin/connections";
		var serviceTypesToGet = [0, 1, 2, 7, 8, 9, 10, 12];
		vm.selectedSection = $localStorage.connectionsSection ? $localStorage.connectionsSection : 'ALL_CONNECTIONS';
		vm.searchParams = { serviceTypes: serviceTypesToGet, startindex: 0, count: 0, search: '', sortType: null, ascending: false };
		vm.tableParams = new NgTableParams(
			{
				sorting: { 0: 'asc' },
				count: 25
			}, {
				getData: queryData,
				counts: $rootScope.commonTableCounts
			});
		vm.checkboxes = gridCheckboxes.init();
		vm.checkboxes.specialKey = function (item) { return item.connectedName + "|" + item.port + "|" + item.isSSL; };
		vm.checkboxes.ignoreItemDelegate = function (item) { return item.connectedName && (item.connectedName.indexOf('127.0.0.1') > -1 || item.connectedName === '::1') }; 
		vm.checkboxes.table = vm.tableParams;

		// Functions
		vm.blacklist = blacklist;
		vm.dropConnections = dropConnections;
		vm.Refresh = Refresh;
		vm.searchItems = searchItems;
		vm.contextMenuGridItem = contextMenuGridItem;
		vm.copyIp = copyToClipboard;

		activate();

		//////////////////////////////////////////////////////////////

		function activate() {
			changeCurrentSection(vm.selectedSection);
		}

		function changeCurrentSection(section) {
			vm.selectedSection = section;
			vm.queryUrl = "~/api/v1/settings/sysadmin/connections";
			switch (section) {
				default:
				case 'ALL_CONNECTIONS':
					vm.searchParams = { serviceTypes: serviceTypesToGet, startindex: 0, count: 0, search: '', sortType: null, ascending: false };
					break;
				case 'WEBMAIL':
					vm.searchParams = { serviceTypes: [8], startindex: 0, count: 0, search: '', sortType: null, ascending: false };
					break;
				case 'SMTP':
					vm.searchParams = { serviceTypes: [0], startindex: 0, count: 0, search: '', sortType: null, ascending: false };
					break;
				case 'IMAP':
					vm.searchParams = { serviceTypes: [1], startindex: 0, count: 0, search: '', sortType: null, ascending: false };
					break;
				case 'POP':
					vm.searchParams = { serviceTypes: [2], startindex: 0, count: 0, search: '', sortType: null, ascending: false };
					break;
				case 'XMPP':
					vm.searchParams = { serviceTypes: [7], startindex: 0, count: 0, search: '', sortType: null, ascending: false };
					break;
				case 'ACTIVESYNC':
					vm.searchParams = { serviceTypes: [9], startindex: 0, count: 0, search: '', sortType: null, ascending: false };
					break;
				case 'MAPI_EWS':
					vm.searchParams = { serviceTypes: [10], startindex: 0, count: 0, search: '', sortType: null, ascending: false };
					break;
				case 'WEBDAV':
					vm.searchParams = { serviceTypes: [12], startindex: 0, count: 0, search: '', sortType: null, ascending: false };
					break;
			}
			vm.checkboxes.reset();
			vm.tableParams.reload();
			$scope.$emit('reloadConnections');
		}

        function blacklist(ev) {
			var addresses = [];
			if (vm.checkboxes.items) {
				for (var key in vm.checkboxes.items) {
					addresses.push(key.split("|")[0]);
				}
			}
			if (addresses.length === 0) {
				errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED"); return;
			}
			$mdDialog
				.show({
					locals: {
						addresses: addresses.join(', '),
						smtp: vm.selectedSection == 'ALL_CONNECTIONS' ? true : (vm.selectedSection == 'SMTP' ? true : false),
						pop: vm.selectedSection == 'ALL_CONNECTIONS' ? true : (vm.selectedSection == 'POP' ? true : false),
						imap: vm.selectedSection == 'ALL_CONNECTIONS' ? true : (vm.selectedSection == 'IMAP' ? true : false),
						xmpp: vm.selectedSection == 'ALL_CONNECTIONS' ? false : (vm.selectedSection == 'XMPP' ? true : false),
						description: "",
	                    fromGrid: true,
	                    readOnly: false
					},
					controller: "sysAdminConnectionsEditBlacklistingController",
					controllerAs: "ctrl",
					templateUrl: "app/sysadmin/manage/modals/admin-blacklist.dlg.html",
					targetEvent: ev
				})
				.then(function (modalSuccess) {
					var serviceList = [];
					if (modalSuccess.smtp)
						serviceList.push(0);
					if (modalSuccess.imap)
						serviceList.push(1);
					if (modalSuccess.pop)
						serviceList.push(2);
					if (modalSuccess.xmpp)
						serviceList.push(7);

					var params = {
						serviceList: serviceList,
						dataType: 1,
						address: modalSuccess.address,
						description: modalSuccess.description
					};

					$http.post("~/api/v1/settings/sysadmin/ip-access", JSON.stringify(params))
						.then(function (success) {
							dropConnections(ev);
							coreDataSpool.isIPListValid = false;
							$scope.$emit('reloadConnections');
							vm.checkboxes.reset();
							vm.tableParams.reload();
							successHandling.report();
						}, function (failure) {
							errorHandling.report(failure.data.message);
						});
				}, function () { });
		}

		function dropConnections(ev) {
			var addresses = [];
			if (vm.checkboxes.items) {
				for (var key in vm.checkboxes.items) {
					addresses.push(key.split("|")[0]);
				}
			}
			if (addresses.length === 0) {
				errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED"); return;
			} else if (addresses.length == 1) {
				doDropConnections();
			} else {
				var confirm = $mdDialog.confirmDeletion()
					.textContent($filter('translate')('CONFIRMATIONS_MULTI_GENERIC', { count: addresses.length }))
					.ok($filter('translate')('OK'))
					.title($filter('translate')('CONFIRMATION_REQUIRED'));
				$mdDialog.show(confirm).then(function () { doDropConnections(); });
			}

			function doDropConnections() {
				var params = JSON.stringify({ dropInfo: addresses });
				$http.post('~/api/v1/settings/sysadmin/drop-ip-connections', params)
					.then(function (success) {
						$scope.$emit('reloadConnections');
						vm.checkboxes.reset();
						vm.tableParams.reload();
						successHandling.report();
					}, errorHandling.report);
			}
		}

		function Refresh() {
			$scope.$emit('reloadConnections');
			vm.checkboxes.reset();
			vm.tableParams
				.reload()
				.then(function () { }, function () {})
				.finally(function () { successHandling.report("ACTION_SUCCEEDED"); });
		}

		function searchItems() {
			vm.searchParams.search = vm.searchText;
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}

		function queryData(params) {
			vm.searchParams.startindex = (params.page() - 1) * params.count();
			vm.searchParams.count = params.count();

			vm.searchParams.sortType = null;
			for (var k in params.sorting()) {
				if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
					continue;
				vm.searchParams.sortType = k;
				vm.searchParams.ascending = params.sorting()[k] !== 'desc';
				break;
			}

			$rootScope.spinner.show();
			return $http
				.post(vm.queryUrl, JSON.stringify(vm.searchParams))
				.then(onSearchSuccess)
				.finally($rootScope.spinner.hide);

			function onSearchSuccess(result) {
				vm.searchResults = result.data.connections;
				
				for (var i = 0; i < vm.searchResults.length; ++i) {
					var v = vm.searchResults[i];
					if (v.connectedName === "127.0.0.1" && v.port === 59100) {
						v.connectedName += ' ' + $filter('translate')('INTERNAL_XMPP_BOSH_PAREN');
					}
				}
				vm.searchResultCount = result.data.count;
				params.total(vm.searchResultCount);
				return vm.searchResults;
			}
		}

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			if (vm.checkboxes.ignoreItemDelegate(item)) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === vm.checkboxes.specialKey(item) })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [vm.checkboxes.specialKey(item)];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: 'blacklist', click: blacklist, params: $scope.dropdownEvent, translateKey: 'BLACKLIST' },
				{ key: 'dropConnections', click: dropConnections, params: $scope.dropdownEvent, translateKey: 'DROP_CONNECTIONS' },
				{ divider: true },
				{ key: 'copyIp', click: copyToClipboard, params: { ev: $scope.dropdownEvent, item: item }, translateKey: "COPY_IP" }
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $('#context-menu-area');
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		function copyToClipboard(params) {
			if (!params || !params.item || !params.item.connectedName)
				return;

			var textArea = document.createElement("textarea");
			textArea.value = params.item.connectedName;
			document.body.appendChild(textArea);
			textArea.focus();
			textArea.select();
			document.execCommand("copy");
			document.body.removeChild(textArea);
			successHandling.report("COPIED_TO_CLIPBOARD");
		}
	}
})();
