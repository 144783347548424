(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sharedEditPosterController", sharedEditPosterController);

	function sharedEditPosterController($scope, $mdDialog, emailValidationService, email, subscriberType) {
		var vm = this;

		$scope.email = email;
		$scope.subscriberType = subscriberType.toUpperCase();
		$scope.isValid = true;
		$scope.isEmailValid = true;
		$scope.isNew = email === undefined;
		// Functions
		$scope.save = save;
		$scope.cancel = cancel;
		$scope.deleteItem = deleteItem;

		activate();

		/////////////////

		function activate() {
			$scope.$watch("email", function (newValue, oldValue) {
				if (newValue === oldValue)
					return;
				$scope.isEmailValid = $scope.email && $scope.email.length > 0 && emailValidationService.isValidEmail($scope.email);
				$scope.isValid = true;
			});
		}

		function save() {
			if (!($scope.isValid = emailValidationService.isValidEmail()))
				return;

			$mdDialog.hide({
				email: $scope.email
			});
		}
		function deleteItem() {
			if (!$scope.isNew)
				$mdDialog.hide({
					delete: true
				});
		}
		function cancel() {
			$mdDialog.cancel();
		}
	}

})();
