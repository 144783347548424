(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("domainManageNewDomainController", domainManageNewDomainController);

    function domainManageNewDomainController($scope, $mdDialog, $filter, $http, $rootScope, defaultPath, defaultHostname, errorHandling) {
        let pathChar = window.stOS == 'L' ? '/' : '\\';
        let maxPathLen = window.stOS == 'L' ? 4096 : 255;

        $scope.nameInput = '';
        $scope.hostnameInput = defaultHostname.replace("%domain%", "");
        $scope.defaultHostname = defaultHostname;
        $scope.currentHostname = $scope.hostnameInput;
        $scope.defaultHostnameHasVariable = $scope.defaultHostname.indexOf("%domain%") > -1;
        $scope.folderInput = defaultPath.endsWith(pathChar) ? defaultPath : defaultPath + pathChar;
        $scope.defaultPath = defaultPath;
        $scope.enabledOptions = [
            { value: true, translation: 'RUNNING' },
            { value: false, translation: 'DISABLED' }
        ];
        $scope.domainUsername = '';
        $scope.domainPassword = '';

        // Functions
        $scope.save = save;
        $scope.cancel = cancel;

        activate();

        /////////////////////

        function activate() {
            $scope.$watch('nameInput', async function (newvalue, oldvalue) {
                if (oldvalue != newvalue) {
                    if (!$scope.folderInput)
                    {
                        $scope.folderInput = defaultPath.endsWith(pathChar) ? defaultPath : defaultPath + pathChar;
                    }

                    if ($scope.hostnameInput === oldvalue) {
                        $scope.hostnameInput = newvalue;
                    } else if ($scope.hostnameInput === $scope.currentHostname && $scope.defaultHostnameHasVariable) {
                        $scope.hostnameInput = $scope.defaultHostname.replace("%domain%", newvalue || "");
                        $scope.currentHostname = $scope.hostnameInput;
                    }

					let aceFormat = newvalue;
					if (!isAscii(aceFormat)) {
						$scope.folderInputProcessing = true;
						try {
							let punycodeResult = await $http.get(`~/api/v1/info/punycode-domain/${newvalue}`);
							if (punycodeResult.data.success && punycodeResult.data.result)
								aceFormat = punycodeResult.data.result;
						}
						catch (e) { }
						$scope.folderInputProcessing = false;
					}

					var splitFolder = $scope.folderInput.split(pathChar);
					splitFolder[splitFolder.length - 1] = aceFormat;
					$scope.folderInput = splitFolder.join(pathChar);
                }
            });

            $scope.$watch('folderInput', function (newvalue, oldvalue) {
                if (oldvalue != newvalue) {
                    if ($scope.folderInput === defaultPath) {
                        $scope.domainForm.domainFolder.$setValidity('rootPath', false);
                    }
                    else {
                        $scope.domainForm.domainFolder.$setValidity('rootPath', true);
                    }
                }
            });
        }

        function save() {
            if (!$scope.domainForm.$valid || $scope.domainForm.$pending) {
                return;
            }

            if (($scope.folderInput.length + $scope.domainUsername.length) > maxPathLen-45) // 45 chars for other stuff /Users/X/Mail/XXXX
            {
                errorHandling.report($filter('translate')('WINDOWS_FILE_LENGTH_EXCEEDED'));
                return;
            }

            var params = JSON.stringify({
                domainData: {
                    name: $scope.nameInput,
                    path: $scope.folderInput,
                    hostname: $scope.hostnameInput,
                    isEnabled: true
                },
                //domainLocation: 0,
                //domainLocationAddress: '',
                //deliverLocallyForExternalDomain: false,
                adminUsername: $scope.domainUsername,
                adminPassword: $scope.domainPassword
            });

            $rootScope.spinner.show();
            $http
                .post("~/api/v1/settings/sysadmin/domain-put", params)
                .then(onDomainCreated, errorHandling.report)
                .finally($rootScope.spinner.hide);

            function onDomainCreated(result) {
                $mdDialog.hide({
                    name: result.data.domainData.name,
                    idnName: result.data.domainData.idnName
                });
            }
        }

        function cancel() {
            $mdDialog.cancel();

        }

        function isAscii(str) {
            return /^[\x20-\x7f]+$/.test(str);
        }
    }

})();
