(function () {
    "use strict";

    angular
        .module("smartermail")
		.controller("sharedMailingListMessagesController", sharedMailingListMessagesController);

	function sharedMailingListMessagesController($state, $stateParams, $rootScope, $scope, $http, $timeout, $filter, $mdDialog, $q, $log, $compile, $translate,
		NgTableParams, errorHandling, coreDataDomainSettings, coreDataSettings, userDataService, coreData, impersonationService) {
        var vm = this;
		vm.isDomain = $stateParams.type === 'domain';
        vm.mailingListId = $state.params.mailingListId;
        vm.systemMessages = [];
		vm.tableParams = new NgTableParams(
            {
                sorting: { translated: 'asc' },
                count: 100
            }, {
                dataset: [],
				counts: $rootScope.commonTableCounts
			});
		vm.initialized = false;

        // Functions
        vm.editItem = editItem;
        vm.searchItems = function () { vm.tableParams.reload(); }

        activate();

        //////////////////////////

        function activate() {
            loadItems();
            $rootScope.$on('$translateChangeSuccess', function () {
                if (!vm.systemMessages)
                    return;
                angular.forEach(vm.systemMessages, function (value) {
                    value.translated = $translate.instant('MAILING_LISTS_MESSAGE_TYPES_' + value.name.toUpperCase());
                })
			});
        }

        function loadItems() {
            $http.get('~/api/v1/settings/domain/mailing-lists/' + vm.mailingListId +'/system-messages')
                .then(function (result) {
                    vm.systemMessages = result.data.systemMessages.filter(x => x.name !== "HELPMESSAGE") || [];
                    angular.forEach(vm.systemMessages, function (value) {
                        value.translated = $translate.instant('MAILING_LISTS_MESSAGE_TYPES_' + value.name.toUpperCase());
                    })
                    vm.tableParams.settings({ dataset: vm.systemMessages });
					refreshTable();
					vm.initialized = true;
				}, function () {});
        }

        function signalCountChanged() {
            $rootScope.$broadcast("mailing-list:counts-changed");
        }

        function editItem(message, ev) {
            $mdDialog.show({
                locals: {
                    message: message,
                    listId: vm.mailingListId
                },
				controller: "sharedEditSystemMessageController",
                controllerAs: "ctrl",
				templateUrl: "app/settings/shared/mailing-lists/modals/message.dlg.html",
                targetEvent: ev
            })
                .then(function (modalSuccess) {
                   if (modalSuccess.reset) {
			            var confirm = $mdDialog.confirmDeletion()
				            .textContent($filter("translate")("CONFIRMATIONS_RESET_SYSTEM_MESSAGE"))
				            .ok($filter("translate")("RESET"))
				            .targetEvent(ev);
			            $mdDialog.show(confirm)
                            .then(function () {
			                    $http.post('~/api/v1/settings/domain/mailing-lists/' + vm.mailingListId+'/system-messages/reset', JSON.stringify([message.name]))
                                    .then(loadItems, errorHandling.report);
                            }, function () {});

                    } else {
                      var messageParams = {
                            name: message.name,
                            subject: modalSuccess.subject,
                            body: modalSuccess.body
                        };
                        $http.post('~/api/v1/settings/domain/mailing-lists/' + vm.mailingListId + '/system-message', JSON.stringify(messageParams))
                            .then(loadItems, errorHandling.report);
                    }

                });
        }

        function refreshTable() {
            vm.tableParams.reload();
        }
    }

})();