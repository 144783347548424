(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("domainSettingsUserEditDefaultsController", domainSettingsUserEditDefaultsController);

    function domainSettingsUserEditDefaultsController($rootScope, $scope, $http, $timeout, $mdDialog, $q,
        coreLicensing, coreDataSettings, coreDataDomainSettings, userDataService, coreData, errorHandling, userSettingEnums,
        claimsService, localeInfoService) {

        var vm = this;
        vm.settingCore = coreDataDomainSettings;
        vm.domain = $rootScope.secondaryImpersonationDomain || userDataService.user.domain;
        vm.timeZoneSearch = "";
        vm.textEncodeSearch = "";
        vm.isLoaded = false;
        vm.edition = 1;
        vm.canChangeGreylist = false;
        vm.availableIndexers = [];
        vm.mailboxSpaceInput = 0;
        vm.availableLocales = [];
        vm.allowedSizeChanges = false;
        vm.enableGreylisting = false;

        // Functions
        vm.clearSearchTerm = function () { vm.timeZoneSearch = ""; vm.textEncodeSearch = ""; };
        vm.openPropagateModal = openPropagateModal;
        vm.save = save;
        vm.cancel = cancel;
        vm.data = undefined;
        vm.mailForwardingChanged = mailForwardingChanged;

        activate();

        //////////////////////////////////

        function activate() {
            recalculateLicense();
            coreLicensing.watchForChanges($scope, recalculateLicense);

            for (var i = 0; i < angularLangNames.length; i++) {
                var obj = {
                    n: angularLangNames[i].n,
                    v: angularLangNames[i].v,
                    engName: localeInfoService.getEnglishName(angularLangNames[i].v)
                };
                vm.availableLocales.push(obj);
            }

            var promises = [
                $http.get("~/api/v1/settings/domain/domain"),
                userSettingEnums.init(),
                coreDataSettings.init(),
                $http.get("~/api/v1/settings/domain/idn").then(function (success) { vm.domainIdn = success.data.idnName; }, function () { }),
            ];
            $q.all(promises).then(onLoaded, errorHandling.report);

            function onLoaded(success) {
                vm.domainSettings = success[0].data.domainSettings;
                vm.showEmailForwarding = vm.domainSettings.enableMailForwarding;
                vm.canChangeGreylist = coreDataSettings.userPermissions.greyListEnabled;
                vm.isImpersonating = claimsService.impersonating() || claimsService.isSysAdmin();
                vm.authOptions = userSettingEnums.authOptions;
                vm.autoSaveOptions = userSettingEnums.autoSaveOptions;
                vm.deleteOptions = userSettingEnums.deleteOptions;
                vm.encodingOptions = userSettingEnums.encodingOptions;
                vm.fontOptions = userSettingEnums.fontOptions;
                vm.fontSizeOptions = userSettingEnums.fontSizeOptions;
                vm.forwardOptions = userSettingEnums.forwardOptions;
                vm.plusAddressOptions = userSettingEnums.plusAddressOptions;
                vm.previewPaneOptions = userSettingEnums.previewPaneOptions;
                vm.replyHeaderOptions = userSettingEnums.replyHeaderOptions;
                vm.throttleOptions = userSettingEnums.throttleOptions;
                vm.timeZoneOptions = userSettingEnums.timeZoneOptions;
                vm.userStatusOptions = userSettingEnums.userStatusOptions;
                vm.availableIndexers = userSettingEnums.availableIndexers;
                vm.allowedSizeChanges = vm.isImpersonating || vm.domainSettings && vm.domainSettings.allowUserSizeChanging;
                loadDefaults();
            }
        }

        function recalculateLicense() {
            vm.edition = coreLicensing.edition;
            vm.activeSyncLicensed = coreLicensing.hasEas;
            vm.mapiEwsLicensed = coreLicensing.hasMapiEws;
            if (!coreLicensing.hasEnterpriseFunctionality && vm.data && vm.data.services) {
                vm.data.services.xmpp = false;
            }
        }

        function loadDefaults() {
            $rootScope.spinner.show();

            const promises = [
                $http.get("~/api/v1/settings/domain/user-defaults"),
                $http.get("~/api/v1/settings/domain/domain-aliases")
            ];

            $q.all(promises)
                .then(onLoadSuccessful, errorHandling.report)
                .finally(function () {
                    vm.isLoaded = true;
                    $rootScope.spinner.hide();
                    $rootScope.$broadcast("masonry:contentsChanged");
                });

            function onLoadSuccessful(success) {
                vm.data = success[0].data.settings;
                vm.enableGreylisting = !success[0].data.settings.disableGreylisting;
                vm.mailboxSpaceInput = vm.data.maxMailboxSize / (1024 * 1024);
                vm.previewPaneOption = vm.data.previewUsePreview ? "right" : "none";
                vm.data.defaultComposeDomain = vm.data.defaultComposeDomain ? vm.data.defaultComposeDomain : "";
                vm.domainAliases = success[1].data.domainAliasData;
                recalculateLicense();
                $timeout(function () {
                    $("#timeZoneSearch").on("keydown", function (ev) { ev.stopPropagation(); });
                    $("#textEncodeSearch").on("keydown", function (ev) { ev.stopPropagation(); });
                });
            }
        };

        function save(event, form) {
            if (!form.$valid)
                return;

            vm.data.previewUsePreview = vm.previewPaneOption !== "none";
            vm.data.maxMailboxSize = vm.mailboxSpaceInput * (1024 * 1024);
            vm.data.disableGreylisting = !vm.enableGreylisting;

            $rootScope.spinner.show();
            $http
                .post('~/api/v1/settings/domain/user-defaults', JSON.stringify(vm.data))
                .then(function () { form.$setPristine(); form.$setUntouched(); })
                .catch(errorHandling.report)
                .finally($rootScope.spinner.hide);
        }

        function cancel(event, form) {
            loadDefaults();
            form.$setPristine();
        }

        function openPropagateModal(ev) {
            vm.data.previewPaneOption = vm.previewPaneOption;

            $mdDialog.show({
                locals: {
                    modUser: vm.data,
                    domain: $rootScope.secondaryImpersonationDomain || coreData.user.domain
                },
                controller: "domainUserPropagationController",
                controllerAs: "ctrl",
                templateUrl: "app/settings/domain-settings/user-defaults/propagation.dlg.html",
                targetEvent: ev
            });
        };

        function mailForwardingChanged() {
            if (vm.data.mailForwarding) {
                vm.data.keepRecipients = true;
            }
        }
    }

})();
