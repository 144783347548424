(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("restrictedSenders", restrictedSendersService);

	function restrictedSendersService($q, $http, coreDataSettings, coreDataContacts, userDataService)
	{
		var vm = this;
		vm.init = init;
		vm.isInit = false;
		vm.galContacts = [];

		vm.isBlockedSender = isBlockedSender;
		vm.isTrustedSender = isTrustedSender;
		vm.isSenderInGAL = isSenderInGAL;
		vm.convertEmailFromDomainAlias = convertEmailFromDomainAlias;

		var initDefer;
		function init() {
			if (vm.isInit) {
				return $q.when();
			}
			if (!initDefer) {
				initDefer = $q.defer();
				activate();
			}
			return initDefer.promise;
		}
		
		function activate() {
			const promises = [
				$http.get("~/api/v1/contacts/domain/"),
				$http.get("~/api/v1/settings/domain/domain-aliases"),
				coreDataSettings.init()
			];

			$q.all(promises).then(function (success) {
				var contacts = success[0].data.contacts || [];
				for (let i = 0; i < contacts.length; i++) {
					if (!contacts[i].emailAddressList || contacts[i].emailAddressList.length === 0)
						continue;

					vm.galContacts = vm.galContacts.concat($.map(contacts[i].emailAddressList, function (c) { return c.toLowerCase(); }));
				}
				
				vm.domainAliases = $.map(success[1].data.domainAliasData, function (aliasData) { return aliasData.name.toLowerCase(); }) || [];

				initDefer.resolve();
				initDefer = undefined;
				vm.isInit = true;
			}, function (failure) {
				initDefer.reject(failure);
				initDefer = undefined;
				vm.isInit = false;
			});
		}

		function convertEmailFromDomainAlias(email) {
			if (!email) return { domainAlias: false, email: "" };
			email = email.toLowerCase();

			var split = email.split('@');
			var domain = split.length > 1 ? split[1] : email;
			if (vm.domainAliases && vm.domainAliases.indexOf(domain) > -1)
				return { domainAlias: true, email: split[0] + "@" + userDataService.user.domain };

			return { domainAlias: false, email: email };
		}
		
		function isTrustedSender(email) {
			email = convertEmailFromDomainAlias(email).email;

			if (!email) {
				return $q.when({
					isTrusted: false,
					trustSource: "NONE"
				});
			}
			
			const defer = $q.defer();
			var params = JSON.stringify({ email: email });
			$http.post(`~/api/v1/settings/sender-trusted/`, params)
				.then(
					function (success) {
						if (!success.data.trustSource)
							success.data.trustSource = "NONE";
						defer.resolve(success.data);
					},
					function (err) {
						defer.reject(err);
					});

			return defer.promise;
		}

		function isSenderInGAL(email) {
			email = convertEmailFromDomainAlias(email).email;
			return vm.galContacts.indexOf(email) != -1;
		}

		function isBlockedSender(email) {
			email = convertEmailFromDomainAlias(email).email;
			return (coreDataSettings.userSettings.blockedSenders || []).indexOf(email) != -1;
		}
	}
})();
