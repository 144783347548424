(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("messageArchiveEditDialogController", messageArchiveEditDialogController);

	function messageArchiveEditDialogController($scope, $mdDialog, $filter, $translate, $http, $state, userDataService, coreDataDomainSettings, coreDataSysAdminSettings,
		searchGuid, isNew, errorHandling) {
		var vm = this;
		vm.isNew = isNew;
		vm.searchGuid = searchGuid;
		vm.to = "";
		vm.from = "";
		vm.subject = "";
		vm.startDate = moment().local().subtract(7, "d").startOf("day").toDate();
		vm.endDate = moment().local().endOf("day").toDate();
		vm.endDateMax = vm.endDate;
		vm.archivesDisabled = false;
		vm.reindexingArchive = false;
		const domainState = $state &&
			$state.$current &&
			$state.$current.name &&
			$state.$current.name.toLowerCase().startsWith("index.sysadmin-manage.dashboard.domain-specific"); 

		vm.isSysAdmin = userDataService.user.isSysAdmin && !domainState;
		vm.archiveType = vm.isSysAdmin ? "sysadmin" : "domain";
		const domainId = domainState ? $state.params.id : userDataService.user.domain;
		vm.archiveName = vm.isSysAdmin ? "all" : domainId;
	    // Functions
		vm.save = save;
		vm.cancel = cancel;
		vm.deleteItem = deleteItem;
		activate();

	    /////////////////////

		function activate() {
			function onSystemArchiveRulesLoaded(result) {
				vm.archiveitems = result.data.rules || [];
				vm.archivesDisabled = vm.archiveitems.some(opt => opt.isDefault && opt.disabled);
			}
			vm.reindexingArchive = coreDataSysAdminSettings.isMessageArchiveReindexRunning();
			if (vm.isSysAdmin) {
				$http.get("~/api/v1/settings/sysadmin/domain-archive-rules")
					.then(onSystemArchiveRulesLoaded, function () { })
					.finally(getSavedSearch);
			} else {
				coreDataDomainSettings.reset();
				coreDataDomainSettings.init()
					.then(function () { 
						vm.archivesDisabled = !coreDataDomainSettings.domainPermissions.showMessageArchiveSearch;
					}, function () { })
					.finally(getSavedSearch);
			}

		}

		function save() {
			vm.startDate.setHours("0");
			vm.startDate.setMinutes("0");
			vm.startDate.setSeconds("0");

			vm.endDate.setHours("23");
			vm.endDate.setMinutes("59");
			vm.endDate.setSeconds("59");

			var params = {
				messageArchiveName: vm.archiveName,
				startDateUtc: vm.startDate.toUTCString(),
				endDateUtc: vm.endDate.toUTCString(),
				searchDescription: vm.searchDescription,
				to: vm.to,
				from: vm.from,
				subject: vm.subject
			};
			if (!vm.isNew) params.searchGuid = vm.searchGuid;
			$http
				.post("~/api/v1/settings/" + (vm.isSysAdmin ? "sysadmin" : "domain") + "/message-archive-search", JSON.stringify(params))
				.then(onSuccess, errorHandling.report);

			function onSuccess(res) {
				$mdDialog.hide({ searchGuid: res.data.result });
			}
		};

		function cancel() {
			$mdDialog.cancel();
		};

		function deleteItem() {
			$mdDialog.hide({
				deleteItem: true
			});
		};

		function getSavedSearch() {
			if (vm.isNew)
				return;

			$http
				.get(`/api/v1/settings/domain/message-archive-search-info/${vm.searchGuid}`)
				.then(onSearchSuccess, function() {});
			function onSearchSuccess(result) {
				vm.archivedSearch = result.data;
				vm.from = vm.archivedSearch.searchArgs.from;
				vm.to = vm.archivedSearch.searchArgs.to;
				vm.startDate = vm.archivedSearch.searchArgs.startDateUtc;
				vm.endDate = vm.archivedSearch.searchArgs.endDateUtc;
				vm.subject = vm.archivedSearch.searchArgs.subject;
			}
		}
	}

})();
