(function () {
	"use strict";

	angular
		.module("smartertools")
		.directive("validateUrl", validateUrl);

	function validateUrl(emailValidationService) {
		return {
			require: "ngModel",
			link: function (scope, element, attrs, ctrl) {
				function isValidIPv4(ipv4) {
					ipv4 = ipv4.toUpperCase();
					var count = (ipv4.match(/HTTPS?:\/\//g) || []).length;
					if (count != 1)
						return false;
					ipv4 = ipv4.replace("HTTPS://", "").replace("HTTP://", "");
					var doubleParens = ipv4.split("//");
					if (doubleParens.length > 1) {
						return false;
					}
					var path = ipv4.split("/");
					if (path.length > 1) {
						ipv4 = path[0];
						var fullPath = path;
						fullPath.shift();
						fullPath = fullPath.join("/")
						if (/\s/g.test(fullPath)) {
							return false;
						}
					}
					var port = ipv4.split(":");
					if (port.length > 1) {
						if (!/^\d{1,5}$/.test(port[1])) {
							return false;
						}
						var portNum = parseInt(port[1]);
						if (portNum < 0 || portNum > 65535) {
							return false;
						}
						ipv4 = port[0];
					}
					var ips = ipv4.split('.');
					if (ips.length != 4) return false;
					for (var i = 0; i < 4; ++i) {
						if (!$.isNumeric(ips[i])) return false;
						var value = parseInt(ips[i], 10);
						if (value < 0 || value > 255) return false;
					}
					return true;
				}

				ctrl.$validators.validateUrl = function (modelValue, viewValue) {
					if (!viewValue) { return true; }
					if (scope.$eval(attrs.validateUrl) === false) { return true; }

					const hasUnicode = /[^\x00-\x7F]/;
					if (!hasUnicode.test(viewValue)) {		// All ASCII URL can use the old validation regex
						return /https?:\/\/(www\.)?([-a-zA-Z0-9@:%_\+~#=]{2,256}\.)+([a-zA-Z]{2,})\/?(?::([0-9]|[0-8][0-9]|9[0-9]|[0-8][0-9]{2}|9[0-8][0-9]|99[0-9]|[0-8][0-9]{3}|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9]|[0-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5]))?(?:\/([-a-zA-Z0-9@:%_\/\+\.~#=]*))?$/.test(viewValue) ||
							/https?:\/\/(www\.)?(localhost)\/?(?::([0-9]|[0-8][0-9]|9[0-9]|[0-8][0-9]{2}|9[0-8][0-9]|99[0-9]|[0-8][0-9]{3}|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9]|[0-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5]))?(?:\/([-a-zA-Z0-9@:%_\/\+\.~#=]*))?$/.test(viewValue) ||
							isValidIPv4(viewValue);
					}

					const httpAtStart = /^https?:\/\//i;
					if (!httpAtStart.test(viewValue))
						return false;

					var testValue = viewValue.replace(httpAtStart, "");
					var urlParts = testValue.split("/");

					var pathSegmentRegEx = /[-a-zA-Z0-9@:%_+.~#=?&]*/;
					// Check if anything after the domain is invalid
					for (var i = 1; i < urlParts.length; i++) {
						if (!pathSegmentRegEx.test(urlParts[i]))
							return false;
					}

					return emailValidationService.isValidDomain(urlParts[0]);
				};
			}
		};
	}
})();