/**
 *	PURPOSE: Returns a string in the following formats:
 *				{days}d {hours}h {minutes}m
 *				{hours}h {minutes}m {seconds}s
 *				{minutes}m {seconds}s
 *				{seconds}s
 *
 *	NOTE: the given time, started, must be in UTC time
 */
function getTimeSpanString(utcA, utcB) {
	// NOTE: times must be in UTC
	var msPerDay = 1000 * 60 * 60 * 24;
	var msPerHour = 1000 * 60 * 60;
	var msPerMinute = 1000 * 60;
	var msPerSecond = 1000;

	var duration = utcA - utcB;
	var days = Math.floor(duration / msPerDay); duration -= days * msPerDay;
	var hours = Math.floor(duration / msPerHour); duration -= hours * msPerHour;
	var minutes = Math.floor(duration / msPerMinute); duration -= minutes * msPerMinute;
	var seconds = Math.floor(duration / msPerSecond); duration -= seconds * msPerSecond;

	var result = "";
	if (days > 0)
		result = days + "d " + hours + "h " + minutes + "m";
	else if (hours > 0)
		result = hours + "h " + minutes + "m " + seconds + "s";
	else if (minutes > 0)
		result = minutes + "m " + seconds + "s";
	else
		result = seconds + "s";

	return result;
}

/* btoa and atob only properly handle ASCII strings. Need to do extra processing for non-ASCII characters. */
function utf8ToBase64(input) {
	const encoded = encodeURIComponent(input);
	const unescaped = unescape(encoded);
	return window.btoa(unescaped);
}

function base64ToUtf8(input) {
	const unescaped = window.atob(input);
	const encoded = escape(unescaped);
	return decodeURIComponent(encoded);
}