(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sharedAdminSecurityPassReqsController", sharedAdminSecurityPassReqsController);

	function sharedAdminSecurityPassReqsController($rootScope,
		$scope,
		$http,
		$filter,
		$state,
		$q,
		errorHandling,
		unsavedChangesTrackingService,
		userDataService) {
		var vm = this;
		vm.isSystemRequirements = userDataService.user.isSysAdmin && !$state.params.id;
		const apiPath = vm.isSystemRequirements ? "sysadmin" : "domain";
		vm.gridSections = [
			{ translate: "OPTIONS", length: -1 },
			{ translate: "PASSWORD_VIOLATIONS", length: '0' },
			{ translate: "EXPIRED_PASSWORDS", length: '0' },
			{ translate: "PASSWORDS_AGED", length: -1 },
		];
		vm.selectedSection = "OPTIONS";
		vm.changeSection = function(section) {
			if (unsavedChangesTrackingService.doConfirmDialog) {
				unsavedChangesTrackingService
					.doConfirmDialog()
					.then(function() {
							unsavedChangesTrackingService.doConfirmDialog = undefined;
							vm.selectedSection = section;
						},
						function() {});
			} else {
				vm.selectedSection = section;
			}
			if (vm.selectedSection !== 'PASSWORD_VIOLATIONS') {
				getCounts();
			}
		};

		activate();

		//////////////////////

		function activate() {
			$scope.$on('password-compliance-count', (e, count) => updateTabCount(e, count, 1));
			$scope.$on('password-update-count', getCounts);
			$scope.$on('password-expired-count', (e, counts) => updateTabCount(e, counts.expiredCount, 2, counts.count));
			getCounts();
		}

		function getCounts() {
			var params = JSON.stringify({
				skip: 0,
				take: 2147483647,
				search: null,
				sortField: null,
				sortDescending: false
			});

			return $q.all([
					$http.post(`~/api/v1/settings/${apiPath}/password-compliance-users-no-activedirectory`, params),
					$http.post(`~/api/v1/settings/${apiPath}/expired-password-list`, params)
				])
				.then(onSuccess, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onSuccess(result) {
				vm.gridSections[1].length = result[0].data.count;
				vm.gridSections[2].length = result[1].data.expiredCount;
			}
		}

		function updateTabCount(event, count, gridIdx, labelCount) {
			vm.gridSections[gridIdx].length = count;
			//if (labelCount) 
			//	vm.gridSections[gridIdx].label = "/ " + $filter("unicodeNumber")(labelCount);

		}
	}
})();
