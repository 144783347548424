(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("coreDataLicensing", coreDataLicensing);

	function coreDataLicensing($http, $q, $rootScope, errorHandling, claimsService) {

		var vm = this;
		var _Licensing = undefined;
		var isInitialized = false;

		// Public Variables
		vm.Data = {
			get Licensing() { return _Licensing; }
		};

		// Functions
		vm.init = init;
		vm.reset = reset;

		// Startup
		$rootScope.$on("signalR.mailHub.client.licensingChanged", function () {
			if (claimsService.isSysAdmin()) {
				reset();
				init();
			}
		});

		// Implementation

		var initDefer;
		function init() {
			if (initDefer) return initDefer.promise;
			if (isInitialized) return $q.when();

			initDefer = $q.defer();
			var promises = [];
			promises.push(loadLicensing());
			$q.all(promises)
				.then(function () {
					isInitialized = true;
					initDefer.resolve();
					initDefer = null;
				}, function (failure) {
					initDefer.reject(failure);
				})
				.finally(function () {
					initDefer = null;
				});

			return initDefer.promise;
		}

		function reset() {
			isInitialized = false;
		}

		function loadLicensing() {
			return $http
				.get('~/api/v1/licensing/info')
				.then(onLoadSuccess, errorHandling.report);

			function onLoadSuccess(success) {
				_Licensing = success.data;
			}
		}

		function onLicensingChanged(data) {
			isInitialized = false;
			init();
		}
	}

})();