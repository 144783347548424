(function () {
	"use strict";

	angular
		.module("smartertools")
		.directive("validateRetryIntervals", [validateRetryIntervals]);

	function validateRetryIntervals() {
		return {
			require: "ngModel",
			link: function (scope, element, attrs, ctrl) {
				ctrl.$validators.retryIntervals = function (modelValue, viewValue) {
					if (!viewValue)
						return false;

					var values = viewValue.split(',');
					for (var i = 0; i < values.length; ++i) {
						if (isNaN(values[i]))
							return false;

						if (values[i] < 0)
							return false;
					}

					return true;
				};
			}
		};
	}
})();