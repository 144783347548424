(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("coreDataFileStorage", coreDataFileStorage);

	function coreDataFileStorage($http, $q, $filter, $rootScope, $log, $timeout,
		preferencesStorage, authStorage) {
		var vm = this;
		var isInitialized = false;
		var isFoldersLoaded = false;
		var treeData = [];
		var treeDataMap = {};
		var allFilesData = {};
		var folderList = [];
		var _totalDiskUsage = 0;
		var rootFolderIndex = 0;

		// Public Variables
		vm.ignoreFolderChanges = {
			requested: moment(),
			ignored: moment()
		};
		vm.ignoreFileAdds = {
			requested: moment(),
			ignored: moment()
		};
		vm.ignoreFileDeletes = {
			requested: moment(),
			ignored: moment()
		};
		vm.ignoreFileChanges = {
			requested: moment(),
			ignored: moment()
		};

		vm.parameters = {
			get sortField() {
				var value = preferencesStorage.getSortingFilteringParam("fileStorage", "sortField");
				if (value === undefined) {
					value = "fileName"; preferencesStorage.setSortingFilteringParam("fileStorage", "sortField", value);
				}
				return value;
			},
			set sortField(value) { preferencesStorage.setSortingFilteringParam("fileStorage", "sortField", value); },

			get isDescending() {
				var value = preferencesStorage.getSortingFilteringParam("fileStorage", "isDescending");
				if (value === undefined) {
					value = false; preferencesStorage.setSortingFilteringParam("fileStorage", "isDescending", value);
				}
				return value;
			},
			set isDescending(value) { preferencesStorage.setSortingFilteringParam("fileStorage", "isDescending", value); },

			get sortReverse() {
				var value = preferencesStorage.getSortingFilteringParam("fileStorage", "sortReverse");
				if (value === undefined) {
					value = false;
					preferencesStorage.setSortingFilteringParam("fileStorage", "sortReverse", value);
				}
				return value;
			},
			set sortReverse(value) { preferencesStorage.setSortingFilteringParam("fileStorage", "sortReverse", value); },

			get currentTreeBranch() {
				var value = preferencesStorage.getSessionSortingFilteringParam("fileStorage", "currentTreeBranch");
				return value;
			},
			set currentTreeBranch(value) {
				var temp = $.extend(true, {}, value);
				var path = temp.data.path;
				delete temp.children;
				delete temp.classes;
				delete temp.data;
				temp.data = { path: path };
				preferencesStorage.setSessionSortingFilteringParam("fileStorage", "currentTreeBranch", temp);
			},

			get currentView() {
				var value = preferencesStorage.getSortingFilteringParam("fileStorage", "currentView");
				if (value === undefined) {
					value = "CARD_VIEW";
					preferencesStorage.setSortingFilteringParam("fileStorage", "currentView", value);
				}
				return value;
			},
			set currentView(value) {
				preferencesStorage.setSortingFilteringParam("fileStorage", "currentView", value);
			}
		};
		vm.parameters.searchText = "";
		vm.parameters.currentBranch = {};

		vm.listDataCache = {};
		vm.addFolderCallback = null;
		vm.deleteFolderCallback = null;
		vm.renameFolderCallback = null;
		vm.addFilesCallback = null;
		vm.removeFilesCallback = null;
		vm.modifyFilesCallback = null;
		vm.cacheResumable = undefined;
		vm.hasFiles = false;

		// Functions
		vm.init = init;
		vm.reset = reset;
		vm.reloadFolders = reloadFolders;
		vm.addFiles = addFiles;
		vm.addFolder = addFolder;
		vm.deleteFiles = deleteFiles;
		vm.deleteFolder = deleteFolder;
		vm.removeMeetingFolders = removeMeetingFolders;
		vm.renameMeetingFolder = renameMeetingFolder;
		vm.downloadFile = downloadFile;
		vm.editFile = editFile;
		vm.moveFiles = moveFiles;
		vm.editFolder = editFolder;
		vm.folderChange = folderChange;
		vm.getFilteredFiles = getFilteredFiles;
		vm.getFolders = function () { return treeData; };
		vm.getAllFilesFolder = function () { return allFilesData; };
		vm.getFolderList = function () { return $filter("orderBy")(folderList, "path"); };
		vm.getTotalDiskUsage = getTotalDiskUsage;
		vm.listDataProvider = listDataProvider;
		vm.modifyFiles = modifyFiles;
		vm.removeFiles = removeFiles;
		vm.uploadNewFile = uploadNewFile;

		// Startup 

		// Implementation

		var initDefer;
		function init() {
			if (initDefer) return initDefer.promise;
			if (isInitialized) return $q.when();

			initDefer = $q.defer();
			var promise = initDefer.promise;

			loadFolders()
				.then(function () {
					isInitialized = true;
					initDefer.resolve();
				}, function (failure) {
					initDefer.reject(failure);
				})
				.finally(function () {
					initDefer = null;
				});

			return promise;
		}

		function reset() {
			isInitialized = false;
			isFoldersLoaded = false;
			treeData = [];
			_totalDiskUsage = 0;
			vm.listDataCache = {};
		}

		function getFilteredFiles() {
			var filteredFiles = vm.parameters.currentBranch.files || [];
			filteredFiles = $.grep(filteredFiles, function (f) { return !!f; });
			filteredFiles = $filter("filter")(filteredFiles, searchPredicate);
			filteredFiles = $filter("orderBy")(filteredFiles, vm.parameters.sortField, vm.parameters.isDescending);
			return filteredFiles;
		}

		function listDataProvider(obj) {
			//TODO: Manage loading files dynamically instead of pulling from a full set of data
			if (vm.parameters.currentBranch == undefined || vm.parameters.currentBranch.files == undefined) {
				obj.defer.reject(false);
			}
			else {
				var subset = [];
				var j = 0;
				var files = vm.getFilteredFiles();
				for (var i = obj.start; i < (obj.start + obj.take); i++) {
					//Note: splicing will make a copy, I want a reference.
					if (files[i] == undefined) {
						break;
					}
					subset[j++] = files[i];
				}
				obj.defer.resolve(subset);
			}
			return obj.defer.promise;
		}

		function updateTotalDiskUsage() {
			_totalDiskUsage = getCumulativeFolderSize(treeData[rootFolderIndex]);
		}

		function getTotalDiskUsage() {
			updateTotalDiskUsage();
			return _totalDiskUsage;
		}

		function reloadFolders() {
			isFoldersLoaded = false;
			loadFolders();
		}

		function loadFolders() {
			var defer = $q.defer();
			if (isFoldersLoaded) {
				defer.resolve();
			} else {
				$http.get("~/api/v1/filestorage/folders")
					.then(function (success) {
						if (!success.data.folder.subFolders) {
							isFoldersLoaded = false;
							defer.resolve(success.data.message);
							return;
						}
						var rootFolder = success.data.folder;

						var selectedBranch = undefined;
						if (treeData.length > 0 && treeData[0].children.length > 0)
							selectedBranch = treeData[0].children.find(function (a) { return a.selected });


						folderList.length = 0;
						folderList.push({ name: $filter("translate")("MY_FILES"), path: rootFolder.path, size: rootFolder.size, count: $filter("bytes")(rootFolder.size, 1) });
						treeData.length = 0;

						var smarterMailFolder = {
							label: $filter('translate')("SMARTERMAIL"),
							data: {
								files: [],
								name: "SMARTERMAIL",
								serverName: "SMARTERMAIL",
								size: 0,
								path: "",
								subFolders: [],
								count: "",
								key: "",
								unselectable: true
							}
						};
						treeData.push(smarterMailFolder);

						// Add the allFilesFolder
						allFilesData = {
							label: $filter('translate')("ALL_FILES"),
							data: {
								files: getAllFiles(rootFolder),
								name: "ALL_FILES",
								serverName: "ALL_FILES",
								size: 0,
								path: "",
								subFolders: [],
								count: "",
								key: ""
							},
							children: []
						};
						smarterMailFolder.data.subFolders.push(allFilesData);

						var rootFolderData = {
							label: $filter("translate")("MY_FILES"),//rootFolder.name,
							data: {
								files: rootFolder.files || [],
								name: "ROOT_FOLDER",//rootFolder.name,
								path: rootFolder.path,
								size: rootFolder.size,
								subFolders: rootFolder.subFolders,
								count: $filter("bytes")(rootFolder.size, 1),
								key: "|" + rootFolder.path
							},
							children: loadSubFolders(rootFolder)
						};
						smarterMailFolder.data.subFolders.push(rootFolderData);

						for (var i = 0; i < rootFolder.subFolders.length; i++) {
							if (rootFolder.subFolders[i].name == "ONLINE_MEETING_FILES" ||
								rootFolder.subFolders[i].name == "PUBLIC_CHAT_FILES" ||
								rootFolder.subFolders[i].name == "ATTACHED_FILES") {
								var folderName = rootFolder.subFolders[i].name;
								var origFolderName = folderName;
								folderName = $filter("translate")(rootFolder.subFolders[i].name);
								var folder = {
									label: folderName,
									data: {
										files: rootFolder.subFolders[i].files,
										name: folderName,
										serverName: origFolderName,
										size: rootFolder.subFolders[i].size,
										path: rootFolder.subFolders[i].path,
										subFolders: rootFolder.subFolders[i].subFolders,
										count: $filter("bytes")(rootFolder.subFolders[i].size, 1),
										key: "|" + rootFolder.subFolders[i].path,
										unselectable: rootFolder.subFolders[i].name === "ONLINE_MEETING_FILES",
									},
									children: loadSubFolders(rootFolder.subFolders[i], undefined, true)
								};
								smarterMailFolder.data.subFolders.push(folder);
								treeDataMap[folder.data.key] = folder;
								rootFolder.subFolders[i].skip = true;
							}
						}

						smarterMailFolder.children = smarterMailFolder.data.subFolders;
						treeDataMap[rootFolderData.data.key] = rootFolderData;
						rootFolderIndex = 0;

						treeData.forEach(function (t) {
							t.children.forEach(function (a) {

								if (selectedBranch) {
									if (selectedBranch.data.path == a.data.path) {
										a.selected = true;
									}
								}

							});
						});

						expandBranches();
						updateTotalDiskUsage();
						isFoldersLoaded = true;
						defer.resolve();
					}, function (failure) {
						isFoldersLoaded = false;
						defer.reject(failure);
					});
			}
			return defer.promise;
		}

		function loadSubFolders(folder, originalElementName, translate) {
			var subFolders = [];
			$.each(folder.subFolders, function (index, element) {
				if (element.skip)
					return;
				var origElementName = originalElementName || element.name;
				if (translate) {
					element.name = $filter("translate")(origElementName);
				}
				var folder = {
					label: element.name,
					data: {
						files: element.files || [],
						serverName: origElementName,
						name: element.name,
						path: element.path,
						size: element.size,
						subFolders: element.subFolders,
						count: $filter("bytes")(element.size, 1),
						key: "|" + element.path,
						hide: origElementName == "ONLINE_MEETING_FILES" || origElementName == "PUBLIC_CHAT_FILES"
					},
					children: loadSubFolders(element, origElementName)
				};
				subFolders.push(folder);
				treeDataMap[folder.data.key] = folder;
				folderList.push({ name: element.name, path: element.path, size: element.size, count: $filter("bytes")(element.size, 1) });
			});
			return subFolders;
		}

		function expandBranches() {
			vm.expandedBranches = preferencesStorage.getSortingFilteringParam("fileStorage", "expandedBranches");
			if (!vm.expandedBranches) vm.expandedBranches = {};

			var keys = Object.keys(treeDataMap);
			for (var i = 0; i < keys.length; ++i) {
				treeDataMap[keys[i]].expanded = vm.expandedBranches[keys[i]];
			}
		}

		//#region Helper Functions
		function findFileById(id, branch) {
			var found = undefined;
			for (var i = 0; i < (branch.data.files || []).length; ++i) {
				var f = branch.data.files[i];
				if (f.id === id) {
					found = f;
					break;
				}
			}
			if (!found) {
				for (var i = 0; i < branch.children.length; ++i) {
					found = findFileById(id, branch.children[i]);
					if (found) { break; }
				}
			}
			return found;
		}

		function findFolder(data, folder) {
			if (data.data.path === folder) return data;
			var result = null;
			for (var index = 0; index < data.children.length; ++index) {
				result = findFolder(data.children[index], folder);
				if (result)
					return result;
			}
			return null;
		}

		//should be used in case we need to search from more than just Root Folder at the root.
		function findFolderByPath(path) {
			var found = undefined;
			for (var i = 0; i < treeData.length; ++i) {
				if (treeData[i].data.path === path) {
					found = treeData[i];
				} else {
					var f = searchFolderChildrenPath(path, treeData[i]);
					found = f ? f : found;
				}
			}
			return found;
		}

		function searchFolderChildrenPath(path, folder) {
			var found = undefined;
			for (var i = 0; i < folder.children.length; ++i) {
				if (folder.children[i].data.path === path) {
					found = folder.children[i];
				} else {
					var f = searchFolderChildrenPath(path, folder.children[i]);
					found = f ? f : found;
				}
			}
			return found;
		}

		function getAllFiles(folder) {
			var files = [];
			if (!folder.subFolders || folder.subFolders.length === 0)
				return files;
			var foldersToCheck = folder.subFolders.slice();
			addFiles(folder);
			while (foldersToCheck.length > 0) {
				addFiles(foldersToCheck[0]);
				for (var i = 0; i < foldersToCheck[0].subFolders.length; i++) {
					foldersToCheck.push(foldersToCheck[0].subFolders[i]);
				}
				foldersToCheck.splice(0, 1);
			}
			return files;

			function addFiles(subFolder) {
				if (!subFolder.files || subFolder.files.length == 0)
					return;

				for (var i = 0; i < subFolder.files.length; i++) {
					files.push(subFolder.files[i]);
				}
			}
		}
		//#endregion

		function addFolder(folder) {
			var defer = $q.defer();
			var params = JSON.stringify(folder);
			vm.ignoreFolderChanges.requested = Date.now();
			$http.post("~/api/v1/filestorage/folder-put", params)
				.then(function (success) {
					var parentFolder = folder.parentFolder;
					var newFolder = success.data.folder;

					var temp = findFolder(treeData[rootFolderIndex], parentFolder);
					if (temp) {
						if (!temp.data.subFolders.filter(x => x.path === newFolder.path).length)
							temp.data.subFolders.push(newFolder);
						if (!temp.children.filter(x => x.data.path === newFolder.path).length) {
							temp.children.push({
								label: newFolder.name,
								data: {
									files: [],
									name: newFolder.name,
									path: newFolder.path,
									size: 0,
									subFolders: [],
									count: $filter("bytes")(0, 1),
									key: "|" + newFolder.path
								},
								children: []
							});
						}
						temp.children = $filter("orderBy")(temp.children, "label");
					}
					folderList.push({ name: newFolder.name, path: newFolder.path, size: newFolder.size, count: $filter("bytes")(newFolder.size, 1) });

					defer.resolve();
				}, function (failure) {
					defer.reject(failure);
				});
			return defer.promise;
		}

		function removeMeetingFolders(publicIds) {
			angular.forEach(publicIds, function (id) {
				var path = "/86bc2a5f-89d8-4e76-a3e8-c53cbebe565d/" + id + "/";

				for (var ii = 0; ii < treeData.length; ii++) {
					purgeFolder(ii);
				}

				function purgeFolder(index) {
					var folder = treeData[index];
					for (var i = 0; i < folder.children.length; i++) {
						if (folder.children[i].data.path === "/86bc2a5f-89d8-4e76-a3e8-c53cbebe565d/") {
							folder = folder.children[i];
							break;
						}
					}

					folder.children = $.grep(folder.children, function (child) {
						if (child.data.path === path)
							return false;
						return true;
					});

					folderList = $.grep(folderList, function (folder) { return folder.path === path }, true);
				}
			});
		}

		function renameMeetingFolder(publicIdentifier, newName) {
			folderList;
			var path = "/86bc2a5f-89d8-4e76-a3e8-c53cbebe565d/" + publicIdentifier + "/";

			var folder = treeData[rootFolderIndex];
			for (var i = 0; i < folder.children.length; i++) {
				if (folder.children[i].data.path === "/86bc2a5f-89d8-4e76-a3e8-c53cbebe565d/") {
					folder = folder.children[i];
					break;
				}
			}

			for (var i = 0; i < folder.children.length; i++) {
				if (folder.children[i].data.path === path) {
					folder.children[i].data.name = newName;
					folder.children[i].label = newName;
					break;
				}
			}
		}

		function deleteFolder(folderToDelete) {
			var defer = $q.defer();
			var params = JSON.stringify(folderToDelete);

			var totalSize = 0;
			var folder = findFolder(treeData[rootFolderIndex], folderToDelete.folder);

			$http.post("~/api/v1/filestorage/delete-folder", params)
				.then(function (success) {
					updateTotalDiskUsage();
					folderList = $.grep(folderList, function (folder) { return folder.path === folderToDelete.folder }, true);
					defer.resolve();
				}, function (failure) {
					defer.reject(failure);
				});
			return defer.promise;
		}

		function editFolder(folder, oldInfo) {
			var defer = $q.defer();
			var oldInfo = oldInfo;
			var params = {
				folder: folder.path,
				newFolderName: folder.name
			}
			vm.ignoreFolderChanges.requested = Date.now();
			$http.post("~/api/v1/filestorage/folder-patch", params)
				.then(function () {
					var temp = findFolder(treeData[rootFolderIndex], folder.path);
					for (var i = 0; i < folderList.length; ++i) {
						if (folderList[i].name === oldInfo.oldName) {
							var parentPath = oldInfo.oldPath.substring(0, oldInfo.oldPath.length - oldInfo.oldName.length - 1);
							folderList[i].path = parentPath + folder.name + "/";
							folderList[i].name = folder.name;
							break;
						}
					}

					if (temp !== null) {
						var parentPath = oldInfo.oldPath.substring(0, oldInfo.oldPath.length - oldInfo.oldName.length - 1);
						var newPath = parentPath + folder.name + "/";
						temp.data.path = temp.data.path.replace(oldInfo.oldPath, newPath);
						temp.data.key = temp.data.key.replace(oldInfo.oldPath, newPath);
						temp.data.name = folder.name;
						temp.label = folder.name;

						changeChildrenPath(temp, newPath);

						var parent = findFolder(treeData[rootFolderIndex], parentPath);
						if (parent) {
							parent.children = $filter("orderBy")(parent.children, "label");
						}
					}

					function changeChildrenPath(folder, newPath) {
						for (var index = 0; index < folder.children.length; ++index) {
							folder.children[index].data.path = folder.children[index].data.path.replace(oldInfo.oldPath, newPath);
							folder.children[index].data.key = folder.children[index].data.key.replace(oldInfo.oldPath, newPath);

							changeChildrenPath(folder.children[index], newPath);
						}
					}

					defer.resolve();
				}, function (failure) {
					defer.reject(failure);
				});
			return defer.promise;
		}

		function uploadNewFile(fileInfo) {
			var defer = $q.defer();
			try {
				var folder = vm.parameters.currentBranch;
				for (var i = 0; i < fileInfo.length; ++i) {
					if (fileInfo[i].folderPath == folder.path) {
						folder.files.push(fileInfo[i]);
						folder.size += fileInfo[i].size;
						folder.count = $filter("bytes")(folder.size, 1);
					}

					if (folder.name != "ALL_FILES") {
						allFilesData.data.files.push(fileInfo[i]);
						allFilesData.data.size += fileInfo[i].size;
						allFilesData.data.count = $filter("bytes")(allFilesData.data.size, 1);
					}
				}

				updateTotalDiskUsage();

				if (vm.addFilesCallback)
					vm.addFilesCallback();

				defer.resolve();
			} catch (er) {
				defer.reject(er);
			}
			return defer.promise;
		}

		function downloadFile(httpPath, fileName, params, external) {
			var defer = $q.defer();
			if (external) {
				var link = document.createElement("a");
				link.href = httpPath.replace("~", window.location.href.substring(0, window.location.href.indexOf("/interface/root#"))) + "?token=" + authStorage.getToken();
				link.download = fileName;
				//Firefox needs the element actually on the page for .click() to work.
				document.body.appendChild(link);
				link.setAttribute("type", "hidden");
				link.click();
				link.outerHTML = ""; 
				defer.resolve();
				return defer.promise;
			}

			if (params) {
				$http.post(httpPath, params, { responseType: "arraybuffer" })
					.then(function (response) {
						var result = processFile(response.data, response.status, response.headers, fileName);
						defer.resolve();
					}, function (response) {
						const decoder = new TextDecoder();
						var messageText = decoder.decode(response.data);
						defer.reject(JSON.parse(messageText).message);
					});
			} else {
				// Use an arraybuffer
				$http.get(httpPath, { responseType: "arraybuffer" })
					.then(function (response) {
						var result = processFile(response.data, response.status, response.headers, fileName);
						defer.resolve();
					}, function (response) {
						const decoder = new TextDecoder();
						var messageText = decoder.decode(response.data);
						defer.reject(JSON.parse(messageText).message);
					});
			}
			return defer.promise;
		}

		function processFile(data, status, headers, fileName) {
			var octetStreamMime = "application/octet-stream";
			var success = false;

			// Get the headers
			headers = headers();
			headers["x-filename"] = fileName;
			// Get the filename from the x-filename header or default to "download.bin"
			var filename = headers["x-filename"];

			// Determine the content type from the header or default to "application/octet-stream"
			var contentType = headers["content-type"] || octetStreamMime;

			try {
				// Try using msSaveBlob if supported
				var blob = new Blob([data], { type: contentType });
				if (navigator.msSaveBlob)
					navigator.msSaveBlob(blob, filename);
				else {
					// Try using other saveBlob implementations, if available
					var saveBlob = navigator.webkitSaveBlob || navigator.mozSaveBlob || navigator.saveBlob;
					if (saveBlob === undefined) throw "Not supported";
					saveBlob(blob, filename);
				}
				//$log.log("saveBlob succeeded");
				success = true;
			} catch (ex) {
			}

			if (!success) {
				// Get the blob url creator
				var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
				if (urlCreator) {
					// Try to use a download link
					var link = document.createElement("a");
					if ("download" in link) {
						// Try to simulate a click
						try {
							// Prepare a blob URL
							var blob = new Blob([data], { type: contentType });
							var url = urlCreator.createObjectURL(blob);
							link.setAttribute("href", url);

							// Set the download attribute (Supported in Chrome 14+ / Firefox 20+)
							link.setAttribute("download", filename);

							// Simulate clicking the download link
							var event = document.createEvent("MouseEvents");
							event.initMouseEvent("click", true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
							link.dispatchEvent(event);
							success = true;
						} catch (ex) {
						}
					}

					if (!success) {
						// Fallback to window.location method
						try {
							// Prepare a blob URL
							// Use application/octet-stream when using window.location to force download
							var blob = new Blob([data], { type: octetStreamMime });
							var url = urlCreator.createObjectURL(blob);
							window.location = url;
							success = true;
						} catch (ex) {
						}
					}
				}
			}

			if (!success) {
				// Fallback to window.open method
				window.open(httpPath, "_blank", "");
			}
		}

		function deleteFiles(filesToDelete) {
			var defer = $q.defer();
			if (!angular.isArray(filesToDelete)) {
				defer.reject($filter("translate")("ERROR"));
			} else {
				vm.ignoreFileChanges.requested = moment();
				var fileIDs = $.map(filesToDelete, function (file) { return file.id });
				var params = JSON.stringify({ fileIDs: fileIDs });
				$http.post("~/api/v1/filestorage/delete-files", params)
					.then(function () {
						angular.forEach(filesToDelete, function (fileToDelete) {
							var folder = findFolder(treeData[rootFolderIndex], fileToDelete.folderPath);
							if (folder) {
								var file = $.grep(folder.data.files, function (f) { return !f ? false : fileToDelete.id === f.id; });
								if (file.length > 0) {
									var index = folder.data.files.indexOf(file[0]);
									if (index > -1) {
										folder.data.size -= fileToDelete.size;
										if (folder.data.size < 0) folder.data.size = 0;
										folder.data.count = $filter("bytes")(folder.data.size, 1);
										folder.data.files.splice(index, 1);
									}
								}
							}

							var file = $.grep(allFilesData.data.files, function (f) { return !f ? false : fileToDelete.id === f.id; });
							if (file.length > 0) {
								var index = allFilesData.data.files.indexOf(file[0]);
								if (index > -1) {
									allFilesData.data.files.splice(index, 1);
								}
							}
						});

						updateTotalDiskUsage();

						if (vm.removeFilesCallback)
							vm.removeFilesCallback();

						$rootScope.$broadcast("filesRefresh");
						defer.resolve();
					}, function (failure) {
						defer.reject(failure);
					});
			}
			return defer.promise;
		}

		function editFile(file) {
			var defer = $q.defer();
			if (!file.password) {
				file.password = "";
			}

			for (var i = 0; i < treeData.length; ++i) {
				var found = findFileById(file.id, treeData[i]);
				if (found) {
					found.dateAdded = file.dateAdded;
					found.id = file.id;
					found.fileName = file.newFileName;
					found.published = file.published;
					//a date less than 2 days from the current date will be interpreted as 'empty' by the api.
					found.publishedUntil = file.publishedUntil < new Date("1001") ? undefined : file.publishedUntil;
					found.password = file.password;
					found.publicDownloadLink = file.publicDownloadLink;
					break;
				}
			}

			var params = JSON.stringify(file);
			vm.ignoreFileChanges.requested = moment();
			$http.post("~/api/v1/filestorage/" + file.id + "/edit", params)
				.then(function () {
					defer.resolve();
				}, function (failure) {
					defer.reject(failure);
				});

			return defer.promise;
		}

		function moveFiles(files, folder) {
			//this is to support the drag and drop only having the folder path
			if (typeof folder === "string") {
				folder = findFolderByPath(folder).data;
			}
			var defer = $q.defer();
			if (!Array.isArray(files)) {
				files = [files];
			}
			var ids = [];
			for (var i = 0; i < files.length; ++i) {
				ids.push(files[i].id);
			}

			var params = { fileIDs: ids, newFolder: folder.path };

			$http.post("~/api/v1/filestorage/move-files", params)
				.then(function (success) {
					var oldPath = files[0].folderPath;
					var oldFolder = findFolderByPath(oldPath);
					var newFolder = findFolderByPath(folder.path);
					for (var i = 0; i < files.length; ++i) {
						files[i].folderPath = folder.path;
						var index = oldFolder.data.files.indexOf(files[i]);
						if (index > -1) {
							newFolder.data.files.push(oldFolder.data.files.splice(index, 1)[0]);
						}
					}
					defer.resolve();
				}, function (failure) {
					defer.reject(failure);
				});

			return defer.promise;
		}

		function folderChange(data) {
			if (!data) return;

			switch (data.action) {
				case 0: addFolderAction(data); break;
				case 1: removeFolderAction(data); break;
				case 2: renameFolderAction(data); break;
				default: break;
			}
		}

		function addFolderAction(data) {
			if (!data) return;
			data.parentFolder = data.parentFolder.replace("Root Folder", "") + "/";

			if (data.folder == "PUBLIC_CHAT_FILES") {
				isFoldersLoaded = false;
				loadFolders();
				return;
			}

			var params = {};
			var folder = findFolder(treeData[rootFolderIndex], data.parentFolder);
			if (folder) {
				var folderExists = false;
				for (var i = 0; i < folder.children.length; ++i) {
					if (folder.children[i].label === data.folder) {
						folderExists = true;
						break;
					}
				}

				if (folderExists) return;
			} else {
				isFoldersLoaded = false;
				loadFolders();
				return;
			}

			params = JSON.stringify({ folder: data.parentFolder + data.folder + "/" });
			$http.post("~/api/v1/filestorage/folder", params)
				.then(function (success) {
					var newFolder = success.data.folder;
					if (!folder.data.subFolders.filter(x => x.path === newFolder.path).length)
						folder.data.subFolders.push(newFolder);
					if (!folder.children.filter(x => x.data.path === newFolder.path).length) {
						folder.children.push({
							label: newFolder.name,
							data: {
								files: newFolder.files,
								name: newFolder.name,
								path: newFolder.path,
								size: newFolder.size,
								count: $filter("bytes")(0, 1),
								key: "|" + newFolder.path
							},
							children: loadSubFolders(newFolder)
						});
					}

					if (vm.addFolderCallback)
						vm.addFolderCallback(0);
				});
		}

		function removeFolderAction(data) {
			if (!data) return;
			data.parentFolder = data.parentFolder.replace("Root Folder", "") + "/";

			var parentFolder = findFolder(treeData[rootFolderIndex], data.parentFolder);
			if (!parentFolder) return;

			var folderToRemoveIndex = -1;
			var folderToRemove = null;
			for (var i = 0; i < parentFolder.children.length; ++i) {
				if (parentFolder.children[i].label === data.folder) {
					folderToRemoveIndex = i;
					folderToRemove = parentFolder.children[i];
					break;
				}
			}

			if (folderToRemoveIndex === -1) return;

			updateTotalDiskUsage();
			parentFolder.children.splice(folderToRemoveIndex, 1);

			if (vm.deleteFolderCallback)
				vm.deleteFolderCallback();
		}

		function getCumulativeFolderSize(folder, totalSize) {
			if (!folder) { return; }
			if (!totalSize) { totalSize = 0; }

			totalSize += folder.data.size;
			for (var i = 0; i < folder.children.length; ++i) {
				return getCumulativeFolderSize(folder.children[i], totalSize);
			}
			return totalSize;
		}

		function renameFolderAction(data) {
			if (!data) return;

			var parentFolder = findFolder(treeData[rootFolderIndex], data.parentFolder);
			if (!parentFolder) return;

			var oldPath = parentFolder.data.path + data.oldFolder + "/";
			var newPath = parentFolder.data.path + data.newFolder + "/";
			for (var i = 0; i < parentFolder.children.length; ++i) {
				if (parentFolder.children[i].label === data.oldFolder) {
					parentFolder.children[i].data.path = parentFolder.children[i].data.path.replace(oldPath, newPath);
					parentFolder.children[i].data.key = parentFolder.children[i].data.key.replace(oldPath, newPath);
					parentFolder.children[i].data.name = data.newFolder;
					parentFolder.children[i].label = data.newFolder;
					renameChildrenPath(parentFolder.children[i]);
					break;
				}
			}

			if (vm.renameFolderCallback)
				vm.renameFolderCallback();

			function renameChildrenPath(folder) {
				for (var index = 0; index < folder.children.length; ++index) {
					folder.children[index].data.path = folder.children[index].data.path.replace(oldPath, newPath);
					folder.children[index].data.key = folder.children[index].data.key.replace(oldPath, newPath);
					renameChildrenPath(folder.children[index]);
				}
			}
		}

		function addFiles(data) {
			if (!data) return;
			console.log("addFiles");
			var ids = $.map(data, function (datum) { return datum.id; });
			var params = JSON.stringify({ fileIDs: ids });
			$http.post("~/api/v1/filestorage/files", params)
				.then(function (success) {
					var files = success.data.files || [];

					if (vm.addFilesCallback)
						vm.addFilesCallback(files);
					angular.forEach(files, function (file) {
						const path = file.folderPath;
						let folder;
						for (let i = 0; i < treeData.length; i++) {
							folder = findFolder(treeData[i], path);
							if (folder)
								break;
						}

						if (folder) {
							folder.data.files = folder.data.files || [];
							var exist = $.grep(folder.data.files, function (f) { return !f ? false : f.id === file.id; });
							if (exist.length === 0) {
								folder.data.files.push(file);
								folder.data.size += file.size;
								folder.data.count = $filter("bytes")(folder.data.size, 1);
							}
							allFilesData.data.files = allFilesData.data.files || [];
							exist = $.grep(allFilesData.data.files, function (f) { return !f ? false : f.id === file.id; });
							if (exist.length === 0) {
								allFilesData.data.files.push(file);
								allFilesData.data.size += file.size;
								allFilesData.data.count = $filter("bytes")(allFilesData.data.size, 1);
							}
						}
					});

					updateTotalDiskUsage();

				});
		}

		function removeFiles(data) {
			if (!data) return;

			angular.forEach(data, function (datum) {
				const id = datum.id;
				if (!id) return;

				for (let i = 0; i < treeData.length; i++) {
					if (tryRemoveFileRecursively(treeData[i], id))
						break;
				}
			});

			updateTotalDiskUsage();

			if (vm.removeFilesCallback)
				vm.removeFilesCallback();

			function tryRemoveFileRecursively(folder, fileId) {
				if (!folder) return false;

				if (folder.data.files) {
					for (let i = 0; i < folder.data.files.length; i++) {
						const file = folder.data.files[i];
						if (file.id.replace(/-/g, '') === fileId) {
							folder.data.files.splice(i, 1);
							folder.data.size -= file.size;
							folder.data.count = $filter("bytes")(folder.data.size, 1);
							
							// Removal is not sucessful until the file is removed from its parent folder, not just the ALL_FILES folder.
							return folder.data.name !== "ALL_FILES";
						}
					}
				}

				for (let i = 0; i < folder.children.length; i++) {
					if (tryRemoveFileRecursively(folder.children[i], fileId))
						return true;
				}

				return false;
			}
		}

		function modifyFiles(data) {
			if (!data) return;

			var ids = $.map(data, function (datum) { return datum.id; });
			var params = JSON.stringify({ fileIDs: ids });
			$http.post("~/api/v1/filestorage/files", params)
				.then(function (success) {
					var files = success.data.files || [];
					angular.forEach(files, function (file) {
						modifyFileRecursively(treeData[rootFolderIndex], file);
					});
				});

			if (vm.modifyFilesCallback)
				vm.modifyFilesCallback();
		}

		function modifyFileRecursively(folder, file) {
			if (!folder) return;
			if (!file) return;

			for (var i = 0; i < folder.data.files.length; ++i) {
				var f = folder.data.files[i];
				if (f.id === file.id) {
					f.dateAdded = file.dateAdded;
					f.fileName = file.fileName;
					f.folderPath = file.folderPath;
					f.id = file.id;
					f.password = file.password;
					f.previewImage = file.previewImage;
					f.publicDownloadLink = file.publicDownloadLink;
					f.published = file.published;
					f.size = file.size;
					f.type = file.type;
					return folder;
				}
			}

			var result = null;
			for (var i = 0; i < folder.children.length; ++i) {
				result = modifyFileRecursively(folder.children[i], file);
				if (result)
					return result;
			}
			return null;
		}

		function searchPredicate(file) {
			var search = vm.parameters.searchText || "";
			return (file.fileName.toLowerCase().indexOf(search.toLowerCase()) > -1);
		}
	}
})();