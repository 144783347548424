(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("renameCalendarFolderController", renameCalendarFolderController);

    function renameCalendarFolderController($scope, $mdDialog, $http, $translate,
        source, coreDataSettings, userDataService, coreData, errorHandling) {

        var vm = this;
        vm.name = source ? (source.untranslatedName ? $translate.instant(source.untranslatedName) : source.name) : "";
        vm.color = source ? source.color : coreData.nextCalendarColor();
        vm.isOwner = source ? (source.owner && source.owner.toLowerCase() === userDataService.user.username.toLowerCase() && !source.isDomainResource) : true;
        vm.isPrimary = source ? source.isPrimary : false;
        vm.originalName = angular.copy(vm.name);
        vm.isCalendar = source ? source.isCalendar : true;
        vm.isResource = source ? source.isResource : false;
        //vm.webcalLink = source ? source.webcalLink : null;
        //vm.allowWebcalSubscription = source ? source.allowWebcalSubscription : false;

        // Functions
        vm.cancel = cancel;
        vm.save = save;

        // Setup
        activate();

        //////////////////////////////////////

        function activate() {
        }

        function cancel() {
            $mdDialog.cancel();
        }

        async function save(ev, userForm) {
            if (vm.name === "")
                return;
            if (!coreDataSettings.validateHexSixColor(vm.color))
                return;
            if (userForm.$invalid)
                return;
            if (!vm.isOwner && !vm.isResource)
                return;

            try {
                var params = {
                    setting: {
                        friendlyName: vm.name !== vm.originalName ? vm.name : undefined,
                        calendarViewColor: vm.color,
                        isPrimary: vm.isPrimary,
                        id: source.id,
                        //allowWebcalSubscription: vm.allowWebcalSubscription
                    }
                };

                await $http.post("~/api/v1/calendars/calendar", JSON.stringify(params));
                $mdDialog.hide({ modified: true });
            }
            catch (failure) {
                errorHandling.report(failure);
            }
        }
    }
})();
