(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("contentFilterData", contentFilterData);

	/* @ngInject */
	function contentFilterData($rootScope, $timeout, $log, $filter, $translate, toaster, successHandling) {
		var vm = this;
		var filterToast = undefined;
		vm.runningFilters = false;

		// Functions
		vm.contentFilterProgress = contentFilterProgress;

		activate();

		///////////////////////////

		function activate() {
			refreshItems();
			$rootScope.$on("$translateChangeSuccess", refreshItems);
			$rootScope.$on("signalR.mailHub.client.contentFilterProgress", contentFilterProgress);
		}

		function refreshItems() {
			vm.matchTypes = [
				{ value: 1, label: $translate.instant("ANY_CONDITION_MUST_BE_MET") },
				{ value: 0, label: $translate.instant("ALL_CONDITIONS_MUST_BE_MET") }
			];
			vm.filterCategories = [
				{ id: 0, translate: $translate.instant("FROM_ADDRESS") },
				{ id: 1, translate: $translate.instant("CONTENT_FILTERING_CATEGORIES_CONTAINS_WORDS") },
				{ id: 2, translate: $translate.instant("TO_ADDRESS") },
				{ id: 3, translate: $translate.instant("ATTACHMENTS") },
				{ id: 4, translate: $translate.instant("OTHER") }
			];
			vm.filterTypes = [
				{ id: 0, translate: $translate.instant("CONTENT_FILTERING_TYPES_FROM_ADDRESS"), category: 0, inputType: 1, inputTranslate: $translate.instant("CONTENT_FILTERING_INPUTS_FROM_ADDRESS") },
				{ id: 1, translate: $translate.instant("CONTENT_FILTERING_TYPES_FROM_DOMAIN"), category: 0, inputType: 1, inputTranslate: $translate.instant("CONTENT_FILTERING_INPUTS_FROM_DOMAIN") },
				{ id: 2, translate: $translate.instant("CONTENT_FILTERING_TYPES_FROM_TRUSTED_SENDER"), category: 0, inputType: 0, inputTranslate: $translate.instant("CONTENT_FILTERING_TYPES_FROM_TRUSTED_SENDER") },
				{ id: 3, translate: $translate.instant("SUBJECT"), category: 1, inputType: 1, inputTranslate: $translate.instant("CONTENT_FILTERING_INPUTS_WORDS_SUBJECT") },
				{ id: 4, translate: $translate.instant("BODY"), category: 1, inputType: 1, inputTranslate: $translate.instant("CONTENT_FILTERING_INPUTS_WORDS_BODY") },
				{ id: 5, translate: $translate.instant("CONTENT_FILTERING_TYPES_WORDS_SUBJECT_BODY"), category: 1, inputType: 1, inputTranslate: $translate.instant("CONTENT_FILTERING_INPUTS_WORDS_SUBJECT_BODY") },
				{ id: 6, translate: $translate.instant("FROM_ADDRESS"), category: 1, inputType: 1, inputTranslate: $translate.instant("CONTENT_FILTERING_INPUTS_WORDS_FROM") },
				{ id: 7, translate: $translate.instant("TO_ADDRESS"), category: 1, inputType: 1, inputTranslate: $translate.instant("CONTENT_FILTERING_INPUTS_WORDS_TO") },
				{ id: 8, translate: $translate.instant("CONTENT_FILTERING_TYPES_WORDS_HEADERS"), category: 1, inputType: 1, inputTranslate: $translate.instant("CONTENT_FILTERING_INPUTS_WORDS_HEADERS") },
				{ id: 9, translate: $translate.instant("CONTENT_FILTERING_TYPES_WORDS_MESSAGE"), category: 1, inputType: 1, inputTranslate: $translate.instant("CONTENT_FILTERING_INPUTS_WORDS_MESSAGE") },
				{ id: 10, translate: $translate.instant("CONTENT_FILTERING_TYPES_TO_ADDRESS"), category: 2, inputType: 1, inputTranslate: $translate.instant("CONTENT_FILTERING_INPUTS_TO_ADDRESS") },
				{ id: 11, translate: $translate.instant("CONTENT_FILTERING_TYPES_TO_DOMAIN"), category: 2, inputType: 1, inputTranslate: $translate.instant("CONTENT_FILTERING_INPUTS_TO_DOMAIN") },
				{ id: 12, translate: $translate.instant("CONTENT_FILTERING_TYPES_TO_ME"), category: 2, inputType: 0, inputTranslate: $translate.instant("CONTENT_FILTERING_TYPES_TO_ME") },
				{ id: 13, translate: $translate.instant("CONTENT_FILTERING_TYPES_NAME_TO"), category: 2, inputType: 0, inputTranslate: $translate.instant("CONTENT_FILTERING_TYPES_NAME_TO") },
				{ id: 14, translate: $translate.instant("CONTENT_FILTERING_TYPES_NAME_NOT_TO"), category: 2, inputType: 0, inputTranslate: $translate.instant("CONTENT_FILTERING_TYPES_NAME_NOT_TO") },
				{ id: 15, translate: $translate.instant("CONTENT_FILTERING_TYPES_NAME_TO_CC"), category: 2, inputType: 0, inputTranslate: $translate.instant("CONTENT_FILTERING_TYPES_NAME_TO_CC") },
				{ id: 16, translate: $translate.instant("CONTENT_FILTERING_TYPES_ATT_HAS"), category: 3, inputType: 0, inputTranslate: $translate.instant("CONTENT_FILTERING_TYPES_ATT_HAS") },
				{ id: 17, translate: $translate.instant("CONTENT_FILTERING_TYPES_ATT_FILENAME"), category: 3, inputType: 1, inputTranslate: $translate.instant("CONTENT_FILTERING_INPUTS_ATT_FILENAME") },
				{ id: 18, translate: $translate.instant("CONTENT_FILTERING_TYPES_ATT_EXTENSION"), category: 3, inputType: 1, inputTranslate: $translate.instant("CONTENT_FILTERING_INPUTS_ATT_EXTENSION") },
				{ id: 19, translate: $translate.instant("CONTENT_FILTERING_TYPES_ATT_SIZE"), category: 3, inputType: 2, inputTranslate: $translate.instant("CONTENT_FILTERING_INPUTS_ATT_SIZE") },
				{ id: 20, translate: $translate.instant("CONTENT_FILTERING_TYPES_PRIORITY_HIGH"), category: 4, inputType: 0, inputTranslate: $translate.instant("CONTENT_FILTERING_TYPES_PRIORITY_HIGH") },
				{ id: 21, translate: $translate.instant("CONTENT_FILTERING_TYPES_PRIORITY_NORMAL"), category: 4, inputType: 0, inputTranslate: $translate.instant("CONTENT_FILTERING_TYPES_PRIORITY_NORMAL") },
				{ id: 22, translate: $translate.instant("CONTENT_FILTERING_TYPES_PRIORITY_LOW"), category: 4, inputType: 0, inputTranslate: $translate.instant("CONTENT_FILTERING_TYPES_PRIORITY_LOW") },
				{ id: 23, translate: $translate.instant("CONTENT_FILTERING_TYPES_AUTO_MESSAGE"), category: 4, inputType: 6, inputTranslate: $translate.instant("CONTENT_FILTERING_TYPES_AUTO_MESSAGE") },
				{ id: 24, translate: $translate.instant("CONTENT_FILTERING_TYPES_IS_AUTH"), category: 4, inputType: 6, inputTranslate: $translate.instant("CONTENT_FILTERING_TYPES_IS_AUTH") },
				{ id: 25, translate: $translate.instant("CONTENT_FILTERING_TYPES_SIZE_OVER"), category: 4, inputType: 2, inputTranslate: $translate.instant("CONTENT_FILTERING_INPUTS_SIZE_OVER") },
				{ id: 26, translate: $translate.instant("CONTENT_FILTERING_TYPES_SIZE_UNDER"), category: 4, inputType: 3, inputTranslate: $translate.instant("CONTENT_FILTERING_INPUTS_SIZE_UNDER") },
				{ id: 27, translate: $translate.instant("CONTENT_FILTERING_TYPES_DATE_RANGE"), category: 4, inputType: 4, inputTranslate: $translate.instant("CONTENT_FILTERING_TYPES_DATE_RANGE") },
				{ id: 28, translate: $translate.instant("CONTENT_FILTERING_TYPES_SENDER_IP"), category: 4, inputType: 1, inputTranslate: $translate.instant("CONTENT_FILTERING_INPUTS_SENDER_IP") },
				{ id: 29, translate: $translate.instant("CONTENT_FILTERING_TYPES_SPAM_WEIGHT"), category: 4, inputType: 5, inputTranslate: $translate.instant("CONTENT_FILTERING_TYPES_SPAM_WEIGHT") }
			];
			vm.filterInputTypes = [
				{ id: 0, translate: $translate.instant("NONE") },
				{ id: 1, translate: $translate.instant("MULTILINE") },
				{ id: 2, translate: $translate.instant("KB") },
				{ id: 3, translate: $translate.instant("BYTES") },
				{ id: 4, translate: $translate.instant("DATE_RANGE") },
				{ id: 5, translate: $translate.instant("SPAM") },
				{ id: 6, translate: $translate.instant("BOOL") }
			];
			vm.actionTypes = [
				{ id: 0, translate: $translate.instant("CONTENT_FILTERING_ACTIONS_NONE") },
				{ id: 1, translate: $translate.instant("DELETE_MESSAGE") },
				// OBSOLETE { id: 2, translate: $translate.instant("REROUTE_MESSAGE"), inputTranslate: $translate.instant("EMAIL_ADDRESS") },
				{ id: 3, translate: $translate.instant("BOUNCE_MESSAGE") },
				{ id: 4, translate: $translate.instant("MOVE_MESSAGE") },
				{ id: 5, translate: $translate.instant("ADD_HEADER"), inputTranslate: $translate.instant("TEXT_TO_ADD") },
				{ id: 6, translate: $translate.instant("SPAM_FILTERING_ADD_SUBJECT"), inputTranslate: $translate.instant("TEXT_TO_ADD") },
				{ id: 7, translate: $translate.instant("FORWARD_MESSAGE"), inputTranslate: $translate.instant("EMAIL_ADDRESS") },
				{ id: 8, translate: $translate.instant("MARK_AS_READ") },
				{ id: 9, translate: $translate.instant("SET_PRIORITY") },
				{ id: 10, translate: $translate.instant("FLAG_MESSAGE") }
			];
		}

		function contentFilterProgress(event, data) {
			if (data.done) {
				successHandling.report($translate.instant("ACTION_SUCCEEDED"));
				if (filterToast) {
					toaster.clear(filterToast);
				}
				filterToast = undefined;
				vm.runningFilters = false;
			} else {
				vm.runningFilters = true;
				filterToast = toaster.pop({
					type: "info",
					title: null,
					body: $translate.instant("CONTENT_FILTER_RUNNING"),
					timeout: 0,
					bodyOutputType: "trustedHtml"
				});
			}
		}
	}
})();