(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("honeyPotSpamCtrl", honeyPotSpamCtrl);

	function honeyPotSpamCtrl($rootScope, $scope, $mdDialog, $http, errorHandling) {

		activate();

		/////////////////

		function activate() {
			$rootScope.spinner.show();
			$http
				.get("~/api/v1/settings/sysadmin/antispam/settings")
				.then(onLoad, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onLoad(result) {
				$scope.item = angular.copy(result.data.settings.honeyPot);
				$scope.addresses = result.data.settings.honeyPot.honeyPotAddresses.join("\r\n");
			}
		}

		$scope.cancel = function () {
			$mdDialog.cancel();
		};

		$scope.save = function () {
			if (!$scope.spamForm.$valid)
				return;

			var arr = $scope.addresses.split(/\r?\n/);
			arr = arr.filter(function (ext) {
				return ext != "";
			});
			$scope.item.honeyPotAddresses = arr;

			$rootScope.spinner.show();
			$http
				.post("~/api/v1/settings/sysadmin/antispam/settings", JSON.stringify({ honeyPot: $scope.item }))
				.then($mdDialog.hide, errorHandling.report)
				.finally($rootScope.spinner.hide);
		};
	}
})();