(function () {
	"use strict";

	angular
		.module("smartertools")
		.config(config);

	function config($mdDialogProvider) {
		$mdDialogProvider.addPreset("confirmDeletion",
			{
				methods: ["title", "htmlContent", "textContent", "content", "ariaLabel", "ok", "cancel", "theme", "css", "noWarn", "hideCancel"],
				options: confirmDeletionOptions
			});
		$mdDialogProvider.addPreset("confirmWithOption",
			{
				methods: ["title", "htmlContent", "textContent", "content", "ariaLabel", "ok", "cancel", "theme", "css", "noWarn", "hideCancel", "optionText", "optionDefault"],
				options: confirmWithOptionOptions
			});
		$mdDialogProvider.addPreset("confirmRevertChanges",
			{
				methods: ["title", "htmlContent", "textContent", "content", "ariaLabel", "ok", "cancel", "theme", "css", "noWarn", "hideCancel"],
				options: confirmRevertChanges
			});
		
		$mdDialogProvider.addPreset("notify",
			{
				methods: ["title", "htmlContent", "textContent", "content", "ariaLabel", "ok", "theme", "css", "noWarn", "hideCancel"],
				options: notifyOptions
			});

		$mdDialogProvider.addPreset("stProgressBar",
			{
				methods: ["title", "barText", "cancel", "close", "ariaLabel", "cancel", "close", "enableButtons", "showCancel", "theme", "css", "percentProgress", "progressListener", "guid", "autoClose"],
				options: stProgressBar
			});

	}

	/* @ngInject */
	function confirmDeletionOptions($mdDialog, $mdConstant) {
		return {
			template: [
				'<md-dialog md-theme="{{ dialog.theme }}" aria-label="{{ dialog.ariaLabel || dialog.title }}" ng-class="dialog.css" class="confirm-dialog">',
				'  <md-dialog-content class="md-dialog-content" role="document" tabIndex="-1">',
				'    <h2 class="md-title">{{ dialog.title }}</h2>',
				'    <div ng-if="::dialog.mdHtmlContent" class="md-dialog-content-body" ',
				'        ng-bind-html="::dialog.mdHtmlContent"></div>',
				'    <div ng-if="::!dialog.mdHtmlContent" class="md-dialog-content-body">',
				"      <p>{{::dialog.mdTextContent}}</p>",
				"    </div>",
				"  </md-dialog-content>",
				"  <md-dialog-actions>",
				'    <md-button md-autofocus ng-click="dialog.abort()" class="md-primary md-cancel-button" ng-hide="dialog.hideCancel">',
				"      {{ dialog.cancel }}",
				"    </md-button>",
				'    <md-button ng-click="dialog.hide()" class="md-confirm-button" ng-class="{ \'md-warn\': !dialog.noWarn}" md-autofocus>',
				"      {{ dialog.ok }}",
				"    </md-button>",
				"  </md-dialog-actions>",
				"</md-dialog>"
			].join("").replace(/\s\s+/g, ""),
			controller: mdDialogCtrlController,
			controllerAs: "dialog",
			bindToController: true
		};

		/* @ngInject */
		function mdDialogCtrlController($scope, $translate) {
			this.cancel = this.cancel || $translate.instant("CANCEL");
			this.ok = this.ok || $translate.instant("DELETE");
			this.title = this.title || $translate.instant("CONFIRMATION_REQUIRED");

			this.hide = function () {
				$mdDialog.hide(true);
			};

			this.abort = function () {
				$mdDialog.cancel();
			};

			this.keypress = function ($event) {
				if ($event.keyCode === $mdConstant.KEY_CODE.ENTER) {
					$mdDialog.hide(this.result);
				}
			};
		}
	}

	/* @ngInject */
	function confirmWithOptionOptions($mdDialog, $mdConstant) {
		return {
			template: [
				'<md-dialog md-theme="{{ dialog.theme }}" aria-label="{{ dialog.ariaLabel || dialog.title }}" ng-class="dialog.css" class="confirm-dialog">',
				'  <md-dialog-content class="md-dialog-content" role="document" tabIndex="-1">',
				'    <h2 class="md-title">{{ dialog.title }}</h2>',
				'    <div ng-if="::dialog.mdHtmlContent" class="md-dialog-content-body" ',
				'        ng-bind-html="::dialog.mdHtmlContent"></div>',
				'    <div ng-if="::!dialog.mdHtmlContent" class="md-dialog-content-body">',
				"      <p>{{::dialog.mdTextContent}}</p>",
				"    </div>" +
				'    <div class="md-dialog-content-body" >',
				'		<md-switch aria-label="{{ dialog.optionText }}" ng-model="dialog.option" class="md-primary select-box">{{ dialog.optionText }}</md-switch>' +
				"    </div>" +
				"  </md-dialog-content>",
				"  <md-dialog-actions>",
				'    <md-button md-autofocus ng-click="dialog.abort()" class="md-primary md-cancel-button" ng-hide="dialog.hideCancel">',
				"      {{ dialog.cancel }}",
				"    </md-button>",
				'    <md-button ng-click="dialog.hide()" class="md-confirm-button" ng-class="{ \'md-warn\': !dialog.noWarn}" md-autofocus>',
				"      {{ dialog.ok }}",
				"    </md-button>",
				"  </md-dialog-actions>",
				"</md-dialog>"
			].join("").replace(/\s\s+/g, ""),
			controller: mdDialogCtrlController,
			controllerAs: "dialog",
			bindToController: true
		};

		/* @ngInject */
		function mdDialogCtrlController($scope, $translate) {
			this.cancel = this.cancel || $translate.instant("CANCEL");
			this.ok = this.ok || $translate.instant("DELETE");
			this.title = this.title || $translate.instant("CONFIRMATION_REQUIRED");
			this.optionText = this.optionText || $translate.instant("OPTION");
			this.option = this.optionDefault || false;

			this.hide = function () {
				$mdDialog.hide(this.option);
			};

			this.abort = function () {
				$mdDialog.cancel();
			};

			this.keypress = function ($event) {
				if ($event.keyCode === $mdConstant.KEY_CODE.ENTER) {
					$mdDialog.hide(this.option);
				}
			};
		}
	}

	/* @ngInject */
	function confirmRevertChanges($mdDialog, $mdConstant) {
		return {
			template: [
				'<md-dialog md-theme="{{ dialog.theme }}" aria-label="{{ dialog.ariaLabel || dialog.title }}" ng-class="dialog.css" class="confirm-dialog">',
				'  <md-dialog-content class="md-dialog-content" role="document" tabIndex="-1">',
				'    <h2 class="md-title">{{ dialog.title }}</h2>',
				'    <div ng-if="::dialog.mdHtmlContent" class="md-dialog-content-body" ',
				'        ng-bind-html="::dialog.mdHtmlContent"></div>',
				'    <div ng-if="::!dialog.mdHtmlContent" class="md-dialog-content-body">',
				"      <p>{{::dialog.mdTextContent}}</p>",
				"    </div>",
				"  </md-dialog-content>",
				"  <md-dialog-actions>",
				'    <md-button ng-click="dialog.abort()" class="md-primary md-cancel-button" ng-hide="dialog.hideCancel">',
				"      {{ dialog.cancel }}",
				"    </md-button>",
				'    <md-button md-autofocus ng-click="dialog.hide()" class="md-confirm-button" ng-class="{ \'md-primary\': !dialog.noWarn}">',
				"      {{ dialog.ok }}",
				"    </md-button>",
				"  </md-dialog-actions>",
				"</md-dialog>"
			].join("").replace(/\s\s+/g, ""),
			controller: mdDialogCtrlController,
			controllerAs: "dialog",
			bindToController: true
		};

		/* @ngInject */
		function mdDialogCtrlController($scope, $filter) {
			this.cancel = this.cancel || $filter("translate")("KEEP_CHANGES");
			this.ok = this.ok || $filter("translate")("UNDO_CHANGES");
			this.title = this.title || $filter("translate")("CONFIRMATION_REQUIRED");
			this.textContent = this.textContent || $filter("translate")("CONFIRMATIONS_REVERT_CHANGES_BODY");

			this.hide = function () {
				$mdDialog.hide(true);
			};

			this.abort = function () {
				$mdDialog.cancel();
			};

			this.keypress = function ($event) {
				if ($event.keyCode === $mdConstant.KEY_CODE.ENTER) {
					$mdDialog.hide(this.result);
				}
			};
		}
	}
	
	/* @ngInject */
	function notifyOptions($mdDialog, $mdConstant) {
		return {
			template: [
				"<md-dialog md-theme=\"{{ dialog.theme }}\" aria-label=\"{{ dialog.ariaLabel || dialog.title }}\" ng-class=\"dialog.css\" class=\"confirm-dialog\">",
				"  <md-dialog-content class=\"md-dialog-content\" role=\"document\" tabIndex=\"-1\">",
				"    <h2 class=\"md-title\">{{ dialog.title }}</h2>",
				"    <div ng-if=\"::dialog.mdHtmlContent\" class=\"md-dialog-content-body\" ",
				"        ng-bind-html=\"::dialog.mdHtmlContent\"></div>",
				"    <div ng-if=\"::!dialog.mdHtmlContent\" class=\"md-dialog-content-body\">",
				"      <p>{{::dialog.mdTextContent}}</p>",
				"    </div>",
				"  </md-dialog-content>",
				"  <md-dialog-actions>",
				"    <md-button ng-click=\"dialog.hide()\" class=\"md-confirm-button\" ng-class=\"{ 'md-warn': !dialog.noWarn}\" md-autofocus>",
				"      {{ dialog.ok }}",
				"    </md-button>",
				"  </md-dialog-actions>",
				"</md-dialog>"
			].join("").replace(/\s\s+/g, ""),
			controller: mdDialogCtrlController,
			controllerAs: "dialog",
			bindToController: true
		};

		/* @ngInject */
		function mdDialogCtrlController($scope, $translate) {
			this.ok = this.ok || $translate.instant("OK");
			this.title = this.title || "";

			this.hide = function () {
				$mdDialog.hide(true);
			};

			this.keypress = function ($event) {
				if ($event.keyCode === $mdConstant.KEY_CODE.ENTER) {
					$mdDialog.hide(this.result);
				}
			};
		}
	}

	/* @ngInject */
	function stProgressBar($mdDialog, $mdConstant) {
		return {
			template: [
				'<md-dialog md-theme="{{ dialog.theme }}" aria-label="{{ dialog.ariaLabel || dialog.title }}" ng-class="dialog.css" class="confirm-dialog">',
				'  <md-dialog-content class="md-dialog-content" role="document" tabIndex="-1">',
				'    <h2 class="md-title">{{ ::dialog.title }}</h2>',
				'    <div class="grid-disk-space-bar-chart">',
				'     <div class="overview-chart">',
				'       <span class="chart-search" ng-class="{indeterminate: dialog.indeterminate}" ng-style="{\'width\': (dialog.percentProgress <= 100 ? dialog.percentProgress : 100) + \'%\'}"></span>',
				"     </div>",
				'     <div class="chart-overlay" ng-show="dialog.barText">{{dialog.barText}} ({{ dialog.percentProgress | number : 0 }}%)</div>',
				"    </div>",
				"  </md-dialog-content>",
				'  <md-dialog-actions ng-show="!dialog.autoClose || dialog.showCancel || dialog.enableButtons">',
				'    <md-button md-autofocus ng-click="dialog.onClose()" class="md-primary md-cancel-button" ng-show="!dialog.autoClose || dialog.enableButtons" ng-disabled="!dialog.enableButtons">',
				"      {{ dialog.close }}",
				"    </md-button>",
				'    <md-button ng-click="dialog.onCancel()" class="md-warn md-confirm-button" ng-show="dialog.showCancel" ng-disabled="dialog.percentProgress === 100">',
				"      {{ dialog.cancel }}",
				"    </md-button>",
				"  </md-dialog-actions>",
				"</md-dialog>"
			].join("").replace(/\s\s+/g, ""),
			controller: stProgressBarController,
			controllerAs: "dialog",
			bindToController: true
		};

		/* @ngInject */
		function stProgressBarController($rootScope, $scope, $timeout, $translate, $log, errorHandling) {
			var vm = this;
			vm.onClose = onClose;
			vm.onCancel = onCancel;
			vm.close = vm.close || $translate.instant("CLOSE");
			vm.cancel = vm.cancel || $translate.instant("CANCEL");
			vm.title = vm.title || $translate.instant("PROGRESS");
			vm.percentProgress = vm.percentProgress || 0;
			vm.closeModal = false;
			vm.indeterminate = vm.percentProgress === -1;
			if (!vm.indeterminate)
				vm.closeTimeout = $timeout(vm.onClose, 25000);
			if (vm.percentProgress === 100) {
				vm.onClose();
				return;
			}
			
			Object.defineProperty(vm,
				"progressListener",
				{
					get: function() { return vm._progListener; },
					set: function(value) {
						vm._progListener = value;
						initProgressListener();
					}
				});

			if (vm.progressListener)
				initProgressListener();
			
			function initProgressListener() {
				if(debugMode)
					console.log("Started listening for " + vm.progressListener + " with GUID " + vm.guid);
				$scope.$on(vm.progressListener,
					function (ev, data) {
						if (debugMode)
							console.log("Got update for " + data.guid + " | Current: " + data.current + " | Total: " + data.total);
						if (data.guid === vm.guid) {
							$timeout.cancel(vm.closeTimeout);
							if (data.error) {
								errorHandling.report(data.error);
								vm.onClose();
							}
							if (data.action) {
								vm.barText = $translate.instant(data.action);
							}
							vm.closeTimeout = $timeout(vm.onClose, 25000);
							if (data.totalCount == -1) {
								if (data.current !== data.total && data.current !== -1) {
									vm.percentProgress = Math.floor((data.current / data.total) * 100);
								} else if (data.current === data.total || data.current === -1) {
									vm.percentProgress = 100;
								}
							} else {
								var totalProcessed = data.current + data.processed;
								if (totalProcessed !== data.totalCount && data.current !== -1) {
									vm.percentProgress = Math.floor((totalProcessed / data.totalCount) * 100);
								} else if (data.current === -1 && data.asyncFolderCurrent == data.asyncFolderTotal) {
									vm.percentProgress = 100;
								}
							}
							vm.percentProgress = vm.percentProgress > 100 ? 100 : vm.percentProgress;
							if (data.current === -1) {
								vm.closeModal = true;
							}
						}
						if (vm.closeModal) {
							$timeout.cancel(vm.closeTimeout);
							if (vm.autoClose) {
								vm.onClose();
							} else {
								vm.enableButtons = true;
							}
						}
						$scope.$applyAsync();
					});
			}
			
			function onClose() {
				if (vm.closeTimeout) {
					$timeout.cancel(vm.closeTimeout);
				}
				vm.percentProgress = 0;
				vm.guid = "";
				$mdDialog.cancel();
			};

			function onCancel() {
				if (vm.closeTimeout) {
					$timeout.cancel(vm.closeTimeout);
				}
				vm.percentProgress = 0;
				vm.guid = "";
				$mdDialog.hide({});
			};
		};
	};

})();