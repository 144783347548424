(function () {
	"use strict";

	/*
	Sample Usage:

	HTML
	----

	<st-search-box search-text="searchText" on-search="ctrl.searchUpdate()"></st-search-box>

	- search-text binds to the variable that will store the text entered

	- on-search is the event that fires when user clicks the search or presses Enter

	- ng-disabled can also be used to conditionally disable the search area

	LESS
	----
	
	To set color for search icon, use this:

	st-search-box button:hover, st-search-box button:focus { color: #WHATEVER; }

	*/

	// ReSharper disable once UndeclaredGlobalVariableUsing
	angular
		.module("smartertools")
		.directive("stSearchBox", stSearchBox);

	function stSearchBox($filter, $translate, $timeout) {
		return {
			restrict: "EA",
			scope: {
				searchText: "=",
				alwaysShowClearIcon: "=",
				onSearch: "&",
				onClosedWhenEmpty: "&",
				ngDisabled: "="
			},
			link: function (scope, element) {
				scope.placeholder = $translate.instant("SEARCH");
				scope.keyDown = function ($event) {
					var charCode = ($event.which) ? $event.which : $event.keyCode;
					/*Escape*/
					if (charCode === 27) {
						scope.searchText = "";
						$timeout(function () {
							scope.onSearch();
							$("#searchBoxText").blur();
						});
						$event.preventDefault();
					}
					/*Enter*/
					if (charCode === 13 || charCode === 3) {
						scope.onSearch();
						$("#searchBoxText").blur();
						$event.preventDefault();
					}
					return true;
				};
				scope.clearText = function () {
					const wasAlredyEmpty = scope.searchText === '' || scope.searchText === null;
					scope.searchText = "";
					$timeout(function () {
						scope.onSearch();
						$("#searchBoxText").trigger("focus");
						if (wasAlredyEmpty)
							scope.onClosedWhenEmpty();
					});
				};
			},
			template:
				"<div class='st-search-box'>" +
					"<button class='search-mag-btn' type='submit' ng-click='onSearch()'>" +
						"<i class='toolsicon toolsicon-search'></i>" +
					"</button>" +
					"<input name='search' id='searchBoxText' placeholder='{{placeholder}}' ng-model='searchText' ng-keydown='keyDown($event)' autocomplete='off' ng-focus='onFocused()' ng-blur='onBlurred()'/>" +
					"<button class='search-close-btn' type='button' ng-click='clearText()' ng-show='searchText || alwaysShowClearIcon'>" +
						"<i class='toolsicon toolsicon-close'></i>" +
					"</button>" +
				"</div>"
		};
	}
})();