(function () {
	"use strict";

	angular
		.module("smartermail")
		.directive("rssCard", rssCardDirective);

	function rssCardDirective() {
		return {
			restrict: "EA",
			templateUrl: "app/rss-feeds/rss-card.html",
			controller: function () { }
		};
	}

})();
