(function () {
	"use strict";

	angular
		.module("smartermail")
		.directive("domainManageCard", [domainManageCard]);
	
	function domainManageCard () {
		return {
			restrict: "EA",
			templateUrl: "app/sysadmin/manage/domains/cards/domain-manage-card.html",
			controller: function () { }
		};
	}
})();