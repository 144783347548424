(function () {
	"use strict";

	angular
		.module("smartertools")
		.directive("settingBadge", settingBadge);

	function settingBadge($filter, $sce) {

		var colors = [
			"#3094d1",
			"#e23f33",
			"#34d999",
			"#3d4999",
			"#ff7c00",
			"#519651",
			"#2bc1b9",
			"#d10096",
			"#4e74b7",
			"#dd6b33",
			"#ce9e28",
			"#94b22b"
		];

		return {
			restrict: "EA",
			scope: {
			},
			template: "" +
				"<div class='st-circle-nav'>" +
				"<div class='st-circle-icon'" +
				"ng-mouseenter='active=true' ng-mouseleave='active=false'>" +
				"<i class='toolsicon toolsicon-4x toolsicon-{{::icon}}' ng-style=\"{ 'color': active ? shadeColor : color}\"></i></div>" +
				"<div class='root-settings-card-title' ng-bind-html='$sce.trustAsHtml(title);'></div></div>",
			link: function (scope, element, attrs) {
				scope.$sce = $sce;
				if (attrs.color)
					scope.color = attrs.color;
				else if (attrs.colorIndex)
					scope.color = colors[parseInt(attrs.colorIndex) % colors.length];
				if (scope.color)
					scope.shadeColor = $.shadeColor(scope.color, -0.2);
				scope.title = attrs.title;
				scope.titleKey = attrs.titleKey;
				if (!scope.title && scope.titleKey)
					scope.title = $filter('translate')(scope.titleKey);
				scope.icon = attrs.icon;
			}
		};


	}


    angular
        .module("smartertools")
        .directive("materialSettingBadge", materialSettingBadge);

    function materialSettingBadge($filter, $sce) {

        var colors = [
            "#3094d1",
            "#e23f33",
            "#34d999",
            "#3d4999",
            "#ff7c00",
            "#519651",
            "#2bc1b9",
            "#d10096",
            "#4e74b7",
            "#dd6b33",
            "#ce9e28",
            "#94b22b"
        ];

        return {
            restrict: "EA",
            scope: {
            },
            template: "" +
                "<div class='st-circle-nav'>" +
                "<div class='st-circle-icon'" +
                "ng-mouseenter='active=true' ng-mouseleave='active=false'>" +
                "<i class='material-icons' ng-style=\"{ 'color': active ? shadeColor : color}\">{{::icon}}</i></div>" +
                "<div class='root-settings-card-title' ng-bind-html='$sce.trustAsHtml(title);'></div></div>",
            link: function (scope, element, attrs) {
                scope.$sce = $sce;
                if (attrs.color)
                    scope.color = attrs.color;
                else if (attrs.colorIndex)
                    scope.color = colors[parseInt(attrs.colorIndex) % colors.length];
                if (scope.color)
                    scope.shadeColor = $.shadeColor(scope.color, -0.2);
                scope.title = attrs.title;
                scope.titleKey = attrs.titleKey;
                if (!scope.title && scope.titleKey)
                    scope.title = $filter('translate')(scope.titleKey);
                scope.icon = attrs.icon;
            }
        };


    }

})();
