(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("claimsService", claimsService);

    function claimsService($window, $state) {
        var vm = this;

        // Functions
        vm.isClaimCurrent = function () { return "1" == getClaims()["claimVersion"]; }
        vm.canImpersonate = function () { return isTrue(getClaims()["CanImpersonate"]); };
        vm.canViewPasswords = function () { return isTrue(getClaims()["CanViewPasswords"]); };
        vm.deleteClaims = function () { delete currentStorage().claims; };
        vm.getDisplayName = function () { return getClaims()["name"]; };
        vm.getDomain = getDomain;
        vm.getEmailAddress = function () { return getClaims()["nameid"]; };
        vm.getRootEmail = function () { return getClaims()["rootemail"]; };
        vm.getUsername = function () { return getClaims()["username"]; };
        vm.impersonating = impersonating;
        vm.getImpersonatorUsername = function () { return getClaims()["ImpersonatingAdmin"] };
        vm.getImpersonatorLocale = function () { return getClaims()["ImpersonatingAdminLocale"] };
        vm.isDomainAdmin = function () { return findRole("DomainAdmin"); };
        vm.isPrimarySysAdmin = function () { return findRole("PrimarySysAdmin"); };
        vm.isSysAdmin = function () { return findRole("SysAdmin"); };
        vm.setClaims = setClaims;
        vm.setDisplayName = setDisplayName;
        vm.decodeToken = function (token) { return decodeToken(token); }
        vm.redirectToDefaultPageForUser = redirectToDefaultPageForUser;
        vm.hasDomain = function() {
            return getDomain().length > 1 || $state.params["id"];
        }
        vm.isEmClientAdmin = function() { return isTrue(getClaims()["eMClientAdmin"]) };
        vm.checkAuthFromState = function(auth) {
            if (auth) {
                if (window.name === "ImpersonatedWindow" && vm.isSysAdmin() && !impersonating())
                    return true;
                return auth.claims.every(claim => getClaims()[claim]) &&
                    auth.roles.every(role => findRole(role)) &&
                    (!auth.hasDomain || vm.hasDomain());

            } else {
                // no auth required
                return true;
            }
        }
        vm.getDefaultRouteForUser = function() {
            if (vm.isSysAdmin())
                return "index.sysadmin-manage.dashboard";
            else if (vm.getUsername())
                return "index.email";
            else
                return "login";

        }
        vm.navigateOnLoginSuccess = function (callbackFunction) {
            const autologinRedirect = localStorage.getItem("smAutoLoginTokenRedirect");
            const isSystemAdmin = vm.isSysAdmin();
            const isDomainAdmin = vm.isDomainAdmin();
            localStorage.removeItem("smAutoLoginTokenRedirect");
            if (!autologinRedirect) {
                $state.go(vm.getDefaultRouteForUser());
            } else if (autologinRedirect.startsWith("/sysadmin")) {
                if (isSystemAdmin) {
                    window.location.hash = autologinRedirect;
                } else {
                    $state.go(vm.getDefaultRouteForUser());
                }
            } else if (autologinRedirect.startsWith("/settings/domain")) {
                if (isDomainAdmin) {
                    window.location.hash = autologinRedirect;
                } else {
                    $state.go(vm.getDefaultRouteForUser());
                }
            } else {
                if (!isSystemAdmin && !autologinRedirect.startsWith("/popout")) {
                    window.location.hash = autologinRedirect;
                } else {
                    $state.go(vm.getDefaultRouteForUser());
                }
            }
            if (callbackFunction != undefined)
                callbackFunction(true);

        }
        activate();

        //-----------------------------------------------

        function activate() {
            if (localStorage && localStorage.claims)
                delete localStorage.claims;
        }

        function getDomain() {
            var email = vm.getEmailAddress() || "";
            var emailParts = email.split("@");
            return (emailParts.length == 2) ? emailParts[1] : "";
        }

        function redirectToDefaultPageForUser() {
            if (vm.isSysAdmin())
                $state.go("index.sysadmin-manage.dashboard");
            else if (vm.getUsername())
                $state.go("index.email");
            else
                $window.location.href = stSiteRoot + "interface/root";
        }

        function impersonating() {
            //I am checking sessionstorage for an impersonating claim first since if you are impersonating you will have
            // a session storage claim saying you are. This is to avoid some complications with remember me and impersonating.

            var session = sessionStorage.getItem("claims");
            if (session)
                return isTrue(JSON.parse(session)["Impersonating"]);

            var local = localStorage.getItem("claims");
            if (local)
                return isTrue(JSON.parse(local)["Impersonating"]);

            return false;
        }

        function setClaims(token, isImp) {
            var storageToUse = sessionStorage;
            if (!isImp)
                storageToUse = currentStorage();
            var claims = decodeToken(token);
            storageToUse.setItem("claims", JSON.stringify(claims));
        }

        function setDisplayName(value) {
            // We shouldn't try to set values in the claims as they'll be overwritten when the next claim comes in
            var encodedVlaue = encodeUtf8(value);
            var c = getClaims();
            c.name = encodedVlaue;
            currentStorage().setItem("claims", JSON.stringify(c));
        }

        function decodeToken(token) {
            var parts = token.split('.');
            if (parts.length !== 3)
                return;
            var decoded = urlBase64Decode2(parts[1]);
            return angular.fromJson(decoded);
        }

        function urlBase64Decode2(str) {
            var output = str.replace(/-/g, '+').replace(/_/g, '/');
            var mod = output.length % 4;
            switch (mod) {
                case 0:
                    break;
                case 1:
                    output += '===';
                    break;
                case 2:
                    output += '==';
                    break;
                case 3:
                    output += '=';
                    break;
            }
            return $window.decodeURIComponent(base64ToUtf8(output));
        }

        function encodeUtf8(value) {
            return unescape(encodeURIComponent(value));
        }

        function decodeUtf8(value) {
            return decodeURIComponent(escape(value));
        }

        function isTrue(s) { return "true" === (s + "").toLowerCase(); }

        function findRole(roleCheck) {
            const roles = getClaims()["role"] || [];
            for (let i = 0; i < roles.length; i++) {
                if (roles[i] === roleCheck)
                    return true;
            }
            return false;
        }

        function getClaims() {
            const claims = currentStorage().getItem("claims");
            const claimSet = claims ? JSON.parse(claims) : [];
            if (claims && claimSet && claimSet.length > 0 && !claimSet["claimVersion"]) {
                // If the claim set is an old version (in this case non-existent) we need to re-login
                vm.deleteClaims();
                delete currentStorage().token;
                return [];
            }
            return claimSet;
        }

        function currentStorage() {
            return sessionStorage;
        }
    }

})();