(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("renameAddressBookController", renameAddressBookController);

    function renameAddressBookController($scope, $mdDialog, $filter, $translate, $http,
        source, userDataService, errorHandling) {

        var vm = this;
        vm.owner = source ? (source.ownerUsername ? source.ownerUsername : userDataService.user.domain) : userDataService.user.username;
        vm.isOwner = vm.owner.toLowerCase() === userDataService.user.username.toLowerCase();
        vm.name = source
            ? (source.displayName ? $filter("folderTranslate")(source.displayName, vm.owner) : source.name)
            : "";
        vm.isGal = source ? source.itemID === "gal" : false;
        vm.id = source ? (source.id || source.itemID) : null;

        // Functions
        vm.cancel = cancel;
        vm.save = save;

        // Setup
        activate();

        /////////////////////////////////

        function activate() {
            if (vm.id === 'gal')
                vm.id = null;
        }

        function cancel() {
            $mdDialog.cancel();
        }

        async function save() {
            if (vm.name === "")
                return;

            var folder = {
                folder: vm.name,
                uid: vm.id
            };

            if (vm.isOwner) {
                try {
                    await $http.post('~/api/v1/contacts/address-book/edit', folder);
                    $mdDialog.hide();
                } catch (failure) {
                    errorHandling.report(failure);
                }
            }
            // Shares should use edit-shared-folder.dlg.html
        }
    }
})();
