(function () {
    "use strict";

    angular
		.module("smartermail")
		.directive("authenticatedIpsType", ["$translate", "$filter", authenticatedIpsType]);

	function authenticatedIpsType($translate, $filter) {
		return function (scope, element, attrs) {
			switch (scope.item.type) {
				case 1:
					scope.item.type = $filter("htmlUnescape")($translate.instant("MAPI_EWS"));
					break;
				case 2:
					scope.item.type = $filter("htmlUnescape")($translate.instant("ACTIVESYNC"));
					break;
				case 3:
					scope.item.type = $filter("htmlUnescape")($translate.instant("IMAP"));
					break;
				case 4:
					scope.item.type = $filter("htmlUnescape")($translate.instant("POP"));
					break;
				case 5:
					scope.item.type = $filter("htmlUnescape")($translate.instant("WEBDAV"));
					break;
				case 6:
					scope.item.type = $filter("htmlUnescape")($translate.instant("WEBMAIL"));
					break;
				case 7:
					scope.item.type = $filter("htmlUnescape")($translate.instant("SHAREPOINT"));
					break;
				case 8:
					scope.item.type = $filter("htmlUnescape")($translate.instant("MAPI_EWS"));
					break;
				case 9:
					scope.item.type = $filter("htmlUnescape")($translate.instant("SMTP"));
					break;
				case 13:
					scope.item.type = $filter("htmlUnescape")($translate.instant("XMPP"));
			}
        };
    }
})();