(function () {
	"use strict";

	angular
		.module("smartertools")
		.service("browserSupport", browserSupport);

	function browserSupport($rootScope) {
		var vm = this;
		var hooked = false;
		$rootScope.browserCaps = $rootScope.browserCaps || {};
		$rootScope.browserCaps.mouseExists = true;
		$rootScope.browserCaps.supportsDateInput = false;
		$rootScope.browserCaps.webRtcSupport = false;

		// Functions
		activate();

		////////////////////

		function activate() {
			window.addEventListener('touchstart', onTouchStart, true);
			window.addEventListener('touchend', onTouchStart, true);
			window.addEventListener('touchcancel', onTouchStart, true);
			window.addEventListener('touchmove', onTouchStart, true);
			$rootScope.$on("$destroy", function () { detach(); });
			hooked = true;
			checkBrowserSupport();
		}

		function checkBrowserSupport() {
			$rootScope.browserCaps.supportsDateInput = checkDateInputSupport();
			$rootScope.browserCaps.webRtcSupport = checkWebRtcSupported();
		}

		function checkDateInputSupport() {
			var input = document.createElement('input');
			input.setAttribute('type', 'date');
			var invalidDate = 'invalid';
			input.setAttribute('value', invalidDate);
			return (input.value !== invalidDate);
		}

		function checkWebRtcSupported() {
			var isWebRTCSupported = false;
			var checks = ['RTCPeerConnection', 'webkitRTCPeerConnection', 'mozRTCPeerConnection', 'RTCIceGatherer'];
			for (var i = 0; i < checks.length; ++i) {
				if (isWebRTCSupported) { break; }
				if (checks[i] in window) {
					isWebRTCSupported = true;
				}
			};
			isWebRTCSupported = isWebRTCSupported && navigator.mediaDevices != undefined;
			return isWebRTCSupported;
		}

		function onTouchStart() {
			$rootScope.browserCaps.mouseExists = false;
			detach();
		}

		function detach() {
			if (hooked) {
				window.removeEventListener('touchstart', onTouchStart, true);
				window.removeEventListener('touchend', onTouchStart, true);
				window.removeEventListener('touchcancel', onTouchStart, true);
				window.removeEventListener('touchmove', onTouchStart, true);
				hooked = false;
			}
		}
	}

})();


