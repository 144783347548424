(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("editSpamFilterActionDialogController", editSpamFilterActionDialogController);

	function editSpamFilterActionDialogController($mdDialog, title, actionType, argument, weight, hideMoveToJunk) {
		var vm = this;
		vm.title = title;
		vm.actionType = actionType;
		vm.argument = argument;
		vm.weight = weight;
		vm.showWeight = weight != undefined;
		vm.hideMoveToJunk = hideMoveToJunk;

		switch (vm.actionType) {
			case "Delete":
			case 1:
				vm.actionType = 1;
				break;
			case "MoveToFolder":
			case 4:
				vm.actionType = hideMoveToJunk
					? 1
					: 4;
				break;
			case "PrefixSubject":
			case 6:
				vm.actionType = 6;
				break;
			default:
				vm.actionType = 0;
		}

		vm.save = function (invalid) {
			if (invalid) {
				return;
			}

            vm.actionName = "";
            if (vm.actionType === 0)
				vm.actionName = "NoAction";
            if (vm.actionType === 1)
                vm.actionName = "Delete";
            if (vm.actionType === 4)
				vm.actionName = hideMoveToJunk ? "Delete" : "MoveToFolder";
            if (vm.actionType === 6)
                vm.actionName = "PrefixSubject";

			$mdDialog.hide({
				actionType: vm.actionType,
				argument: vm.argument,
                weight: vm.weight,
                actionTypeName: vm.actionName
			});
		};

		vm.cancel = function () {
			$mdDialog.cancel();
		};
	}
})();