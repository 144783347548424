(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("coreDataSysAdminCards", coreDataSysAdminCards);

	function coreDataSysAdminCards($rootScope, $http, $q, $filter, emailValidationService) {
		var vm = this;
		vm.cards = [];
		vm.filteredCards = [];
		vm.selectedCards = [];
		vm.newItemDelegate = undefined;
		vm.editItemDelegate = undefined;
		vm.deleteItemsDelegate = undefined;
		vm.searchItemsDelegate = undefined;
		vm.selectAllDelegate = undefined;
		vm.searchEnabled = true;
		vm.searchText = "";
		vm.searchPredicate = undefined;
		vm.selectMode = false;
		vm.sortField = "";
		vm.isDescending = false;
		vm.totalCount = vm.cards.length;
		vm.listDataCache = {};
		vm.listController = {};

		// Functions
		vm.changePageReset = changePageReset;
		vm.listDataProvider = listDataProvider;
		vm.getFilteredCards = getFilteredCards;
		vm.updateFilteredCards = updateFilteredCards;
		vm.onSortingChanged = onSortingChanged;
		vm.onSortOrderChanged = onSortOrderChanged;
		vm.setSelectMode = setSelectMode;
		vm.isCardSelected = isCardSelected;
		vm.selectAll = selectAll;
		vm.deselectAll = deselectAll;
		vm.newItem = newItem;
		vm.editItem = editItem;
		vm.deleteItems = deleteItems;
		vm.searchItems = searchItems;
		vm.selectItem = selectItem;
		vm.getIndexOf = getIndexOf;
		vm.getSelectedIndexOf = getSelectedIndexOf;
		vm.parseUserTimeSpan = parseUserTimeSpan;
		vm.validateEmailAddress = validateEmailAddress;

		activate();

		/////////////////////

		function activate() {
		}

		function changePageReset() {
			vm.cards.length = 0;
			vm.filteredCards.length = 0;
			vm.selectedCards.length = 0;
			vm.newItemDelegate = undefined;
			vm.editItemDelegate = undefined;
			vm.deleteItemsDelegate = undefined;
			vm.searchItemsDelegate = undefined;
			vm.selectAllDelegate = undefined;
			vm.searchEnabled = true;
			vm.searchText = "";
			vm.searchPredicate = { $: vm.searchText };
			vm.selectMode = false;
			vm.sortField = "";
			vm.isDescending = false;
		}

		function listDataProvider(obj) {
			var subset = [];
			var j = 0;
			var c = vm.getFilteredCards();
			for (var i = obj.start; i < (obj.start + obj.take) ; ++i) {
				if (c[i] == undefined)
					break;

				subset[j++] = c[i];
			}
			obj.defer.resolve(subset);
			return obj.defer.promise;
		}

		function getFilteredCards() {
			if (vm.searchEnabled && vm.searchPredicate)
				vm.filteredCards = $filter("filter")(vm.cards, vm.searchPredicate);
			else
				vm.filteredCards = vm.cards;
			vm.filteredCards = $filter("orderBy")(vm.filteredCards, vm.sortField, vm.isDescending);
			return vm.filteredCards;
		}

		function updateFilteredCards() {
			vm.totalCount = vm.cards.length;
			vm.filteredCards = vm.getFilteredCards();
			if (vm.listController.reset && vm.listController.updateDisplayList) {
				vm.listController.reset();
				vm.listController.updateDisplayList();
			}
		}

		function onSortingChanged(sortBy) {
			vm.sortField = sortBy;
			vm.updateFilteredCards();
		}

		function onSortOrderChanged(isDescending) {
			vm.isDescending = isDescending;
			vm.updateFilteredCards();
		}

		function setSelectMode(enable) {
			vm.selectedCards.length = 0;
			$("md-card").removeAttr("style");
			vm.selectMode = enable;
		}

		function isCardSelected(selectedCard) {
			var index = vm.selectedCards.indexOf(selectedCard);
			return index > -1;
		}

		function selectAll() {
			if (vm.selectAllDelegate) {
				vm.selectAllDelegate();
			} else {
				vm.selectedCards.length = 0;
				angular.forEach(vm.filteredCards, function (c) {
					vm.selectedCards.push(c);
				});
			}
		}

		function deselectAll() {
			vm.selectedCards.length = 0;
		}

		function newItem(ev) {
			if (vm.newItemDelegate)
				vm.newItemDelegate(ev);
		}

		function editItem(card, ev) {
			if (!vm.selectMode) {
				if (vm.editItemDelegate)
					vm.editItemDelegate(card, ev);
			} else {
				var index = vm.selectedCards.indexOf(card);
				if (index > -1)
					vm.selectedCards.splice(index, 1);
				else
					vm.selectedCards.push(card);
			}
		}

		function deleteItems(cards, ev) {
			if (vm.deleteItemsDelegate)
				vm.deleteItemsDelegate(cards, ev);
		}

		function searchItems() {
			if (vm.searchItemsDelegate)
				vm.searchItemsDelegate();
		}

		function selectItem(selectedCard, ev) {
			if (vm.selectItemDelegate)
				vm.selectItemDelegate(selectedCard, ev);
		}

		function getIndexOf(card) {
			if (vm.getIndexOfDelegate)
				return vm.getIndexOfDelegate(card);
		}

		function getSelectedIndexOf(card) {
			if (vm.getSelectedIndexOfDelegate)
				return vm.getSelectedIndexOfDelegate(card);
		}

		function parseUserTimeSpan(time) {
			var valid = /\d/.test(time);
			if (!valid) {
				return '';
			}
			var parsed = moment(time, ['h:m a', 'H:m']);
			if (parsed.isValid()) {
				return parsed;
			} else {
				return '';
			}
		}

		function validateEmailAddress(emailAddress) {
			return emailValidationService.isValidEmail(emailAddress);
		}
	}

})();
