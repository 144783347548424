(function () {
	'use strict';

	angular
		.module('smartermail')
		.controller('ImpersonatingController', ImpersonatingController);

	function ImpersonatingController($scope, $mdDialog, $translate, claimsService) {
		var vm = this;
		vm.impersonating = claimsService.impersonating();
		vm.canImpersonate = claimsService.canImpersonate();

		vm.impersonatingEmail = getImpersonatingEmail();

		if (vm.impersonating && !window.name && !window.opener)
			window.close();

		function getImpersonatingEmail() {
			return claimsService.getEmailAddress();
		}

		$scope.onClick = function (ev) {
			if (vm.canImpersonate && !vm.impersonating)
				impersonate(ev);
			else if (vm.impersonating)
				stopImpersonating(ev);
		};

		function impersonate(ev) {
			$mdDialog
				.show({
                    locals: { domain: null, email: null },
                    controller: "sysAdminImpersonateUserController",
					controllerAs: "ctrl",
					templateUrl: "app/root/modals/impersonate-user.dlg.html",
					targetEvent: ev
				});
		}
		
		function stopImpersonating(ev) {
            $mdDialog
                .show({
					controller: "sysAdminImpersonateUserControllerChange",
                    controllerAs: "ctrl",
                    templateUrl: "app/root/modals/impersonate-user-change.dlg.html",
                    targetEvent: ev
                });
		}
	}

})();
