(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("systemSslUploadCertModalController", systemSslUploadCertModalController);

    function systemSslUploadCertModalController($rootScope, $scope, $mdDialog, $filter, $http, $translate, $timeout, $q,
        successHandling, claimsService, errorHandling) {

        var vm = this;
        vm.page = 'start';
        vm.sslCertificate = {
            cert_setup_type: 1
        };
        vm.uploadedCertificate = undefined;

        // Functions
        vm.step1_next = step1_next;
        vm.upload_cert = upload_cert;
        vm.cancel = cancel;
        vm.back = back;

        activate();

        /////////////////////

        function activate() {
            // Setup events that have to happen after DOM elements are configured
            $timeout(setupSslCertificateEvents, 0);
        }

        function setupSslCertificateEvents() {
            document.getElementById('sslSetupUploadFile')
                .addEventListener('change', () => {
                    $scope.$applyAsync(() => {
                        let files = document.getElementById('sslSetupUploadFile').files;
                        vm.uploadedCertificate = !!(files && files.length);
                    });
                });
        }

        async function step1_next() {
            vm.validatingCertificate = true;
            let certOk = await validate_cert();
            if (certOk)
                $scope.$applyAsync(() => vm.page = 'upload-cert');
        }

        async function validate_cert() {
            try {
                $rootScope.spinner.show();
                vm.uploadedFile = await read_uploaded_file();
                var result = await $http.post("~/api/v1/settings/sysadmin/ssl-cert-validate", JSON.stringify(vm.uploadedFile));
                if (result.data && !result.data.success)
                    throw result;
                vm.validateResult = result.data;
                return true;
            } catch (err) {
                errorHandling.report(err)
                return false;
            } finally {
                $rootScope.spinner.hide();
            }
        }

        async function read_uploaded_file() {
            let defer = $q.defer();
            const reader = new FileReader();

            let file = document.getElementById('sslSetupUploadFile').files[0];
            if (file) {
                reader.addEventListener("load", onFileLoaded);
                reader.readAsDataURL(file);
            }

            async function onFileLoaded() {
                try {
                    let uploadedFile = {
                        filename: file.name,
                        password: vm.sslPassword || '',
                        length: file.size,
                        data: reader.result.split(',')[1]
                    };
                    defer.resolve(uploadedFile);
                } catch (err) {
                    defer.reject(err);
                }
            }

            return defer.promise;
        }

        async function upload_cert() {
            try {
                $rootScope.spinner.show();

                var result = await $http.post("~/api/v1/settings/sysadmin/ssl-cert-upload", JSON.stringify(vm.uploadedFile));
                if (result.data && !result.data.success)
                    throw result;
                else {
                    successHandling.report('ACTION_SUCCEEDED');
                    $mdDialog.hide();
                }

            } catch (err) {
                errorHandling.report(err)
            } finally {
                $rootScope.spinner.hide();
            }
        }

        function back() {
            vm.page = 'start';
        }

        function cancel() {
            $mdDialog.cancel();
        }
    }
})();
