(function () {

    angular
        .module("smartertools")
        .filter('secondsToHms', secondsToHms);

    function secondsToHms() {
        return function (seconds) {
            return moment.duration(seconds, 'seconds').format("hh:mm:ss");
        }
    }

})();

