(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sharedAdminSecurityPassExpiredController", sharedAdminSecurityPassAgedController);

	function sharedAdminSecurityPassAgedController($rootScope, $scope, $http, $filter, $mdDialog, $translate, $compile, $state, userDataService,
		errorHandling, successHandling, NgTableParams, gridCheckboxes, coreDataFileStorage) {
		var vm = this;
		vm.isSystemRequirements = userDataService.user.isSysAdmin && !$state.params.id;
		const apiPath = vm.isSystemRequirements ? "sysadmin" : "domain";

		vm.searchText = null;
		vm.searchParams = { skip: 0, take: 0, search: null, sortField: null, sortDescending: false };
		vm.tableParams = new NgTableParams(
			{
				sorting: { username: 'desc' },
				count: 25
			}, {
			getData: queryData,
			counts: $rootScope.commonTableCounts
		});

		vm.checkboxes = gridCheckboxes.init("id");
		vm.checkboxes.ignoreItemDelegate = function (item) { return item.default; }
		vm.checkboxes.table = vm.tableParams;
		vm.initialized = false;
		vm.searchText = "";

		vm.searchItems = function () {
			vm.searchParams.search = vm.searchText;
			refreshTable();
		}
		vm.exportCsv = exportCsv;
		vm.sendEmail = sendEmail;
		vm.contextMenuGridItem = contextMenuGridItem;

		activate();

		//////////////////////

		function activate() {
			moment.relativeTimeThreshold('d', 180);  // show in days until 6 months
			moment.relativeTimeThreshold('M', 24);   // show in months until 2 years
			moment.relativeTimeRounding(Math.floor); // always round down

		}

		function queryData(params) {
			vm.searchParams.skip = (params.page() - 1) * params.count();
			vm.searchParams.take = params.count();

			vm.searchParams.sortField = null;
			for (var k in params.sorting()) {
				if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
					continue;
				vm.searchParams.sortField = k;
				vm.searchParams.sortDescending = params.sorting()[k] === 'desc';
				break;
			}

			$rootScope.spinner.show();
			return $http
				.post(`~/api/v1/settings/${apiPath}/expired-password-list`, JSON.stringify(vm.searchParams))
				.then(onSuccess, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onSuccess(result) {
				vm.searchResults = result.data.agedPasswordList;
				vm.searchResultCount = result.data.count;
				params.total(vm.searchResultCount);
				if (!vm.searchText) {
					$scope.$emit('password-expired-count', { expiredCount: result.data.expiredCount, count: result.data.count });
				}
				for (let i = 0; i < vm.searchResults.length; ++i) {
					vm.searchResults[i].id = vm.searchResults[i].username + '|' + vm.searchResults[i].domain;
				}
				return vm.searchResults;
			}
		}

		function exportCsv(ev) {
			var httpPath = `~/api/v1/settings/${apiPath}/export-expired-password-list`;
			httpPath += (vm.searchText ? ('/' + vm.searchText) : '');
			var params = JSON.stringify({});
			var fileName = "expired.csv";

			$rootScope.spinner.show();
			coreDataFileStorage
				.downloadFile(httpPath, fileName, params)
				.then(function () { }, errorHandling.report)
				.finally($rootScope.spinner.hide);
		}

		function sendEmail(ev) {
			const domainName = userDataService.user.isSysAdmin ? $state.params.id :
				userDataService.user.domain;
			const rows = vm.checkboxes.getCheckedItems();
			if (!rows) return;
			var emails = [];
			for (let i = 0; i < rows.length; ++i) {
				let s = rows[i].split('|');
				emails.push(s[0] + '@' + domainName);
			}

			$mdDialog
				.show({
					locals: {
						from: "System Administrator",
						subject: $translate.instant("PASSWORD_RESET_PASSWORD_WARNING_MESSAGE"),
						emailText: "",
						isSysAdmin: userDataService.user.isSysAdmin
					},
					controller: "passwordComplianceSendEmailController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/shared/password-requirements/send-email.dlg.html",
					targetEvent: ev
				})
				.then(onSuccess);
			function onSuccess(dialog) {
				if (!dialog || !dialog.emailText) return;
				if (userDataService.user.isSysAdmin) {
					var params = {
						recipientType: 'email',
						from: dialog.from,
						toHeader: $translate.instant("USER"),
						emailAddresses: emails,
						subject: dialog.subject,
						body: dialog.emailText,
						html: true
					};
					$rootScope.spinner.show();
					$http
						.post("~/api/v1/settings/sysadmin/send-email", JSON.stringify(params))
						.then(function () { successHandling.report(); }, errorHandling.report)
						.finally($rootScope.spinner.hide);
				} else {

					$rootScope.spinner.show();
					$http.post("~/api/v1/mail/message-put", JSON.stringify({
						actions: {},
						folder: "drafts",
						to: "",
						ownerEmailAddress: userDataService.user.emailAddress,
						bcc: emails.join(";"),
						subject: dialog.subject,
						date: new Date(Date.now()),
						from: userDataService.user.emailAddress,
						replyTo: userDataService.user.emailAddress,
						messageHTML: dialog.emailText,
						attachmentGuid: ""
					}))
						.then(function () { successHandling.report(); }, errorHandling.report)
						.finally($rootScope.spinner.hide);
				}
			}
		}

		function refreshTable() {
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === item.id; })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item.id];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: 'sendEmail', click: sendEmail, params: $scope.dropdownEvent, translateKey: 'SEND_EMAIL' }
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $('#context-menu-area');
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}
	}
})();