(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("taskController", taskController);

    function taskController($rootScope, $scope, $http, $mdDialog, $compile, $state,
        $timeout, $filter, $q, $translate, coreData, coreDataTasks, coreDataSettings, apiCategories,
        coreLicensing, claimsService, preferencesStorage, errorHandling, treeState, userDataService,
        NgTableParams, gridCheckboxes, tableColumnSwitching, popupService, taskActions, coreDataCalendar,
        toaster, coreDataFileStorage) {

        var reloadDefer = null;
        const statusStrings = [
            $translate.instant("STATUS_NOT_STARTED"), // = 0 or unknown
            $translate.instant("COMPLETED"),  // = 1
            $translate.instant("STATUS_IN_PROGRESS"),  // = 2
            $translate.instant("STATUS_CANCELED")  // = 3
        ];

        // Variable Declaration / instantiation
        var vm = this;
        $scope.isInitialized = false;
        $scope.treeExpanded = treeState.isExpanded;
        $scope.currentView = "CARD_VIEW";
        $scope.selectedCards = [];
        $scope.selectMode = false;
        $scope.anySourcesSelected = true;
        $scope.activeSearch = false;

        // Data list
        $scope.tasks = [];
        $scope.totalCount = $scope.tasks.length;
        $scope.listDataCache = coreDataTasks.listDataCache;
        $scope.listDataProvider = coreDataTasks.listDataProvider;
        $scope.listController = {};

        vm.searchParams = { skip: 0, take: 0, search: null, sortField: null, sortDescending: false };
        vm.tableParams = new NgTableParams({});
        vm.checkboxes = gridCheckboxes.init();
        vm.checkboxes.table = vm.tableParams;
        vm.checkboxes.specialKey = function (item) {
            if (item.sourceOwner)
                return item.sourceOwner + "|" + item.id;
            else
                return "|" + item.id;
        };
        vm.isVisibleRenameFolder = false;
        vm.isVisibleDeleteFolder = false;
        vm.isVisibleShareFolder = false;
        vm.isVisibleFolderProperties = false;
        $scope.hasCategoryFilter = coreDataTasks.parameters.hasCategoryFilter;
        vm.filterType = coreDataTasks.parameters.filterType;
        vm.showManageShares = coreDataSettings.userDomainSettings.enableSharing;

        // Functions
        vm.searchUpdate = searchUpdate;
        vm.onCategoryFilterChanged = onCategoryFilterChanged;
        vm.onSortingChanged = onSortingChanged;
        vm.onSortOrderChanged = onSortOrderChanged;
        vm.onFilterChanged = onFilterChanged;
        vm.selectBtnPressed = selectBtnPressed;
        vm.selectAll = selectAll;
        vm.deselectAll = deselectAll;
        vm.deleteTasksInGrid = deleteTasksInGrid;
        vm.importIcsFile = importIcsFile;
        vm.exportTasks = exportTasks;
        vm.exportTasksFromSelection = exportTasksFromSelection;
        vm.exportIcsFile = exportIcsFile;
        vm.onViewChanged = onViewChanged;
        $scope.branchMouseUp = branchMouseUp;
        $scope.contextMenuGridItem = contextMenuGridItem;
        $scope.isSourceSelected = isSourceSelected;
        $scope.isCardSelected = isCardSelected;
        $scope.newTask = newTask;
        $scope.onMouseUp = onMouseUp;
        $scope.editItemDropdown = editItemDropdown;
        $scope.editItem = editItem;
        $scope.deleteTasksDropdown = deleteTasksDropdown;
        $scope.deleteTasksWithConfirm = deleteTasksWithConfirm;
        $scope.deleteTasks = deleteTasks;
        vm.addToOutlook = addToOutlook;
        $scope.statusToString = statusToString;
        $scope.statusChanged = statusChanged;
        $scope.percentChanged = percentChanged;
        $scope.openManageCategoriesModal = openManageCategoriesModal;
        $scope.sourcesTreeController = {};
        $scope.sourcesTree = coreDataTasks.getSourcesTree();
        $scope.onBranchSelect = onBranchSelect;
        $scope.isRecurring = isRecurring;
        $scope.showActionsMenu = showActionsMenu;
        $scope.translateCategory = translateCategory;
        vm.onContextNewFolder = onContextNewFolder;
        vm.onContextRenameFolder = onContextRenameFolder;
        vm.onContextDeleteFolder = onContextDeleteFolder;
        vm.onContextShareFolder = onContextShareFolder;
        vm.goToManageShares = goToManageShares;
        vm.onContextSharedFolderProperties = onContextSharedFolderProperties;

        activate();

        //////////////////

        async function activate() {
            $scope.$on("treeState:stateChange", onTreeStateChanged);
            $scope.$on("signalR.mailHub.client.sharesChanged", onSharesChanged);
            $scope.$on("signalR.mailHub.client.folderChange", onSharesChanged);
            $scope.$on("newTaskItem", onNewTaskItem);
            $scope.$on("tasksRefresh", onTasksRefresh);
            $scope.$on("$destroy", onDestroy);
            $(window).on("resize.doResize", windowResize);

            try {
                $rootScope.spinner.show();
                await coreData.init();
                await Promise.all([
                    coreDataTasks.init(),
                    coreDataTasks.ensureSourcesLoadedPromise(),
                    coreDataTasks.ensureTasksLoadedPromise(),

                ]);
                await init();
            } catch (err) {
                errorHandling.report(err);
            } finally {
                $rootScope.spinner.hide();
            }

            if (!claimsService.impersonating() && coreDataSettings.userSettings.seenWhatsNew) {
                var keyExist = ("tasks" in coreDataSettings.userSettings.seenWhatsNew);
                if (keyExist) {
                    var versionOverride = localStorage.getItem("FeatureVersionOverride");
                    var shouldShow = versionOverride === null ? stProductVersion.split('.')[2] > coreDataSettings.userSettings.seenWhatsNew["tasks"] : true;
                    if (shouldShow) {
                        var route = `~/api/v1/settings/new-features/Tasks${versionOverride === null ? "" : "/" + versionOverride}`;
                        $http.get(route).then(onFeaturesLoaded, function () { });
                    }
                } else {
                    $http.get('~/api/v1/settings/new-features/Tasks').then(onFeaturesLoaded, function () { });
                }
            }
        }

        function onDestroy() {
            $(window).off("resize.doResize", windowResize);
        }

        async function init() {
            recalculateLicense();
            coreLicensing.watchForChanges($scope, recalculateLicense);

            checkForAvailableMappings(true);

            // Sorting, filtering, searching
            vm.searchText = coreDataTasks.parameters.searchText;
            vm.sortField = coreDataTasks.parameters.sortField;
            vm.sortReverse = coreDataTasks.parameters.sortReverse;
            recalculateSorting();
            
            vm.tableParams = new NgTableParams(
                {
                    sorting: { due: "desc" },
                    count: 25
                },
                {
                    getData: queryData,
                    counts: $rootScope.commonTableCounts
                }
            );
            vm.checkboxes.table = vm.tableParams;

            $scope.currentView = coreDataTasks.parameters.currentView;

            await loadTree(coreDataTasks.reloadOnEnter);

            initSortChecks();
            coreDataTasks.parameters.isDescending = vm.isDescending;
            $scope.anySourcesSelected = $scope.isSourceSelected();
            if (window.innerWidth <= 736 && $scope.currentView !== "CARD_VIEW") {
                onViewChanged("CARD_VIEW");
            }
            else
                searchUpdate();
        }

        function windowResize() {
            if (window.innerWidth <= 736 && $scope.currentView !== "CARD_VIEW") {
                onViewChanged("CARD_VIEW");
            }
        }

        function onFeaturesLoaded(result) {
            var newItems = result.data.newFeatures;
            if (newItems.length > 0) {
                $rootScope.$broadcast("user-settings:changed");
                if (newItems.length > 4 && window.innerWidth > 736) {
                    $mdDialog.show({
                        locals: { items: newItems },
                        controller: "whatsNewDialogController",
                        controllerAs: "ctrl",
                        templateUrl: "~/interface/app/shared/modals/whats-new-double.dlg.html",
                        clickOutsideToClose: false
                    }).then(function () { }, function () { });
                }
                else {
                    $mdDialog.show({
                        locals: { items: newItems },
                        controller: "whatsNewDialogController",
                        controllerAs: "ctrl",
                        templateUrl: "~/interface/app/shared/modals/whats-new-narrow.dlg.html",
                        clickOutsideToClose: false
                    }).then(function () { }, function () { });
                }
            }
        }

        async function loadTree(forceReload) {
            coreDataTasks.reloadOnEnter = false;

            try {
                $rootScope.spinner.show();

                var promises = [
                    coreDataTasks.loadSourcesTree($scope.sourcesTreeController, forceReload),
                ];
                await Promise.all(promises);

                $scope.currentView = coreDataTasks.parameters.currentView;
                $scope.anySourcesSelected = $scope.isSourceSelected();
                //$scope.isInitialized = true;
            }
            finally {
                $rootScope.spinner.hide();
            }
        }

        function onViewChanged(newView) {
            // Empty the table's data so the table isn't visible when switching views
            vm.tableParams.settings({ dataset: [] });
            $scope.currentView = coreDataTasks.parameters.currentView = newView || "CARD_VIEW";
            $scope.selectMode = false;
            deselectAll();
            refresh(true);
            vm.searchUpdate();
        }

        function onTreeStateChanged(event, data) {
            $scope.treeExpanded = data.expanded;
            $timeout(function () { $(window).trigger("resize"); }, 250);
        }

        function recalculateLicense() {
            $scope.edition = coreLicensing.edition;
        }

        function searchItems() {
            vm.searchParams.search = vm.searchText;
            refresh();
        }

        function refresh(skipReload) {
            $scope.hasCategoryFilter = coreDataTasks.parameters.hasCategoryFilter;
            vm.checkboxes.reset();
            if (!skipReload)
                vm.tableParams.reload();
        }

        async function queryData(params) {
            if (tableColumnSwitching.getFirstSwitcher())
                tableColumnSwitching.getFirstSwitcher().allowCheckHide = $scope.currentView === "GRID_VIEW";

            if ($scope.currentView !== "GRID_VIEW")
                return $q.when();

            vm.searchParams.skip = (params.page() - 1) * params.count();
            vm.searchParams.take = params.count();

            vm.searchParams.sortField = null;
            for (var k in params.sorting()) {
                if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
                    continue;
                vm.searchParams.sortField = k;
                vm.searchParams.sortDescending = params.sorting()[k] === "desc";
                break;
            }

            var sources = coreDataTasks.getSources().filter(source => source.isVisible === true && source.permission > 2);
            if (sources.length === 0) {
                vm.searchResults = [];
                vm.searchResultCount = 0;
                params.total(vm.searchResultCount);

                return $q.when(vm.searchResults);
            }

            var mapped = $.map(sources, function (s) {
                return {
                    owner: s.owner,
                    id: s.id
                };
            });

            vm.searchParams.filterFlags = vm.filterType;

            var hasCategoryFilters = coreDataTasks.parameters.hasCategoryFilter;
            var catFilters = coreDataTasks.parameters.categoryFilters;
            vm.searchParams.categories = hasCategoryFilters ? $.map($.grep(catFilters, function (cat) { return cat.selected && !cat.noCategory; }), function (cat) { return cat.name; }) : undefined;
            vm.searchParams.showNonCategorized = !hasCategoryFilters || catFilters.some(cat => cat.noCategory && cat.selected);

            try {
                $rootScope.spinner.show();
                const result = await $http.post("~/api/v1/tasks/tasks-all", JSON.stringify({ sources: mapped, searchParams: vm.searchParams }));
                for (var i = 0; i < result.data.results.length; ++i) {
                    let task = result.data.results[i];

                    if (task.useDateTime === false) {
                        task.due = $filter("translate")("NA");
                        task.start = $filter("translate")("NA");
                    } else {
                        if (task.due === "0001-01-01T00:00:00")
                            task.due = $filter("translate")("NA");
                        else
                            task.due = coreDataTasks.convertLocalToTargetTz(task.due, task.userTimeZone);
                        if (task.start === "0001-01-01T00:00:00")
                            task.start = $filter("translate")("NA");
                        else
                            task.start = coreDataTasks.convertLocalToTargetTz(task.start, task.userTimeZone);
                    }

                    if (task.sourceOwner && task.sourceOwner !== coreData.user.username)
                        task.sourceName = task.sourceOwner + " - " + task.sourceName;
                    else if (task.sourceName.toLowerCase() === "my tasks")
                        task.sourceName = "MY_TASKS";

                    if (task.sourceOwner)
                        apiCategories.addRgbColorsToCategories(task.sourceOwner, task.categories);
                    else
                        task.categories = [];
                    task.categoriesString = apiCategories.generateNameString(task.categories);
                }
                vm.searchResults = result.data.results;
                vm.searchResultCount = result.data.totalCount;
                params.total(vm.searchResultCount);
                return vm.searchResults;
            } catch (err) {
                console.warn(err)
                errorHandling.report(err);
            } finally {
                $rootScope.spinner.hide();
            }
        }

        function isSourceSelected() {
            $scope.anySourcesSelected = coreDataTasks.getSources().some(source => source.isVisible);
            return $scope.anySourcesSelected;
        };

        // Sorting, filtering, and searching
        function searchUpdate() {
            if (!coreDataTasks.areTasksLoaded()) {
                // coreDataTasks.resetTasks();
                $timeout(function () { searchUpdate(); }, 25);
                return;
            }

            $scope.isInitialized = true;
            $scope.hasCategoryFilter = coreDataTasks.parameters.hasCategoryFilter;

            coreDataTasks.parameters.searchText = vm.searchText;
            $scope.activeSearch = vm.searchText !== "";
            $scope.tasks = coreDataTasks.getFilteredTasks();
            $scope.totalCount = $scope.tasks.length;

            $scope.listController.onResize();
            $scope.listController
                .updateDisplayList().then(function() {
                    searchItems();
                });
        }

        function onCategoryFilterChanged(categoryFilter) {
            searchUpdate();
        }

        function onSortingChanged(value) {
            vm.sortField = value;
            recalculateSorting();
            vm.searchUpdate();
        }

        function onSortOrderChanged(value) {
            vm.sortReverse = value;
            recalculateSorting();
            vm.searchUpdate();
        }

        function recalculateSorting() {
            switch (vm.sortField) {
                case "priority":
                    vm.isDescending = !vm.sortReverse;
                    break;
                default:
                    vm.isDescending = vm.sortReverse;
                    break;
            }

            coreDataTasks.parameters.sortField = vm.sortField;
            coreDataTasks.parameters.sortReverse = vm.sortReverse;
            coreDataTasks.parameters.isDescending = vm.isDescending;
        }



        function onFilterChanged(status) {
            switch (status) {
                case 0:
                    vm.filterType = undefined;
                    coreDataTasks.parameters.filterType = undefined;
                    break;
                case 1:
                    if (vm.filterType === undefined) {
                        vm.filterType = {};
                        vm.filterType[1] = true;
                    } else if (vm.filterType[1] === true) {
                        vm.filterType[1] = false;
                    } else {
                        vm.filterType[1] = true;
                    }
                    break;
                case 2:
                    if (vm.filterType === undefined) {
                        vm.filterType = {};
                        vm.filterType[2] = true;
                    } else if (vm.filterType[2] === true) {
                        vm.filterType[2] = false;
                    } else {
                        vm.filterType[2] = true;
                    }
                    break;
                case 3:
                    if (vm.filterType === undefined) {
                        vm.filterType = {};
                        vm.filterType[3] = true;
                    } else if (vm.filterType[3] === true) {
                        vm.filterType[3] = false;
                    } else {
                        vm.filterType[3] = true;
                    }
                    break;
                case 4:
                    if (vm.filterType === undefined) {
                        vm.filterType = {};
                        vm.filterType[4] = true;
                    } else if (vm.filterType[4] === true) {
                        vm.filterType[4] = false;
                    } else {
                        vm.filterType[4] = true;
                    }
                    break;
                case 5:
                    if (vm.filterType === undefined) {
                        vm.filterType = {};
                        vm.filterType[5] = true;
                    } else if (vm.filterType[5] === true) {
                        vm.filterType[5] = false;
                    } else {
                        vm.filterType[5] = true;
                    }
                    break;
            }

            var changeToUndefined = true;
            for (let key in vm.filterType) {
                if (vm.filterType[key])
                    changeToUndefined = false;
            }
            if (changeToUndefined)
                vm.filterType = undefined;
            coreDataTasks.parameters.filterType = vm.filterType;
            vm.searchUpdate();
        }

        function initSortChecks() {
            $timeout(function () {
                var id;
                switch (vm.sortField) {
                    default:
                    case "subject": id = "#chk0"; break;
                    case "due": id = "#chk1"; break;
                    case "percentComplete": id = "#chk2"; break;
                    case "priority": id = "#chk3"; break;
                }

                $(id).addClass("selected");
                if (vm.isDescending)
                    $("#chkDescend").addClass("selected");
                else
                    $("#chkAscend").addClass("selected");

                angular.forEach(coreDataTasks.getSources(), function (source) {
                    if (source.isVisible) {
                        id = "#src" + source.id;
                        $(id).addClass("selected");
                    }
                });
            });
        }

        // Select button
        function selectBtnPressed() {
            $scope.selectedCards.length = 0;
            $scope.selectMode = !$scope.selectMode;
        }

        function isCardSelected(selectedCard) {
            var index = $scope.selectedCards.indexOf(selectedCard.id);
            return index > -1;
        }

        // Select All/Deselect All
        function deselectAll() {
            $scope.selectedCards.length = 0;
        }

        function selectAll() {
            $scope.selectedCards.length = 0;
            $.each($scope.tasks, function (index, value) {
                $scope.selectedCards.push(value.id);
            });
        }

        // Modifying Tasks
        function newTask(ev) {
            taskActions.showNewTaskPopup();
        }

        function onNewTaskItem(tasks) {
            $scope.tasks = coreDataTasks.getFilteredTasks();
            $timeout(function () { $(window).trigger("resize"); }, 250);
        }

        // Edit / Select

        function showActionsMenu(contact, event, $mdOpenMenu) {
            event.stopPropagation();
            event.preventDefault();
            $scope.dropdownEvent = $.extend(true, {}, event);

            var newOptions = getDropdownMenuOptions(contact);
            $scope.$applyAsync(function () {
                $scope.dropdownOptions = newOptions;
                $timeout(function () { $mdOpenMenu(event); }, 0);
            });
        }

        function getDropdownMenuOptions(selectedCard) {
            var menu = [];
            menu.push({ key: "deleteItem", translateKey: "DELETE", click: deleteTasksDropdown, params: { card: [selectedCard], event: $scope.dropdownEvent } });
            menu.push({ key: "exportItem", translateKey: "EXPORT_ICS_FILE", click: exportTasksDropdown, params: { card: [selectedCard], event: $scope.dropdownEvent } });
            return menu;
        }

        function onMouseUp(selectedCard, ev, caret) {
            if ($scope.selectMode)
                return;

            // Handle right click
            if (ev.which === 3 || (caret && ev.which === 1)) {
                ev.stopPropagation();
                ev.preventDefault();
                $scope.dropdownEvent = $.extend(true, {}, ev);
                $scope.dropdownOptions = getDropdownMenuOptions(selectedCard);

                var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" ' + (caret ? 'menu-like="::true" menu-class="::\'more-menu\'" direction="\'left\'" vert-offset="-20"' : "") + "></st-context-menu>";
                var element = $("#context-menu-area");
                if (element) {
                    var elementCompiled = $compile(elementToCompile)($scope);
                    element.append(elementCompiled);
                }
            }
        }

        function editItemDropdown(params) {
            $scope.editItem(params.card, params.event);
        }

        function editItem(selectedCard, ev) {
            if (!$scope.selectMode) {
                if (selectedCard.permission < 8 && selectedCard.isPrivate) {
                    showPrivateTaskModal(selectedCard, ev);
                    return;
                }

                taskActions.showEditTaskPopup(selectedCard.id, selectedCard.sourceId, selectedCard.sourceOwner);
            } else {
                var index = $scope.selectedCards.indexOf(selectedCard.id);
                if (index > -1)
                    $scope.selectedCards.splice(index, 1);
                else
                    $scope.selectedCards.push(selectedCard.id);
            }
        }

        function showPrivateTaskModal(task, ev) {
            if (!task)
                return;

            const notify = $mdDialog.notify()
                .title($translate.instant("PRIVATE_TASK"))
                .textContent($translate.instant("CALENDAR_ITEM_WITH_AVAILABILITY_ONLY_PERMISSIONS"))
                .ok($translate.instant("OK"));
            $mdDialog.show(notify);
        }

        function contextMenuGridItem(item, ev) {
            if (!ev || (ev.type !== "touchstart" && ev.type !== "touchend" && ev.which !== 3) || item.criticallyErrored) {
                return;
            }
            ev.stopPropagation();
            ev.preventDefault();
            var tasks = vm.checkboxes.getCheckedItems();
            //If we right clicked on a not selected item we want to use that item instead
            if ((tasks.length > 1 && !_.some(tasks, function (val) { return val.split("|")[1] === item.id; })) || tasks.length <= 1) {
                vm.checkboxes.reset();
                vm.checkboxes.checkCheckbox(ev, item);
                tasks = [item.sourceOwner + "|" + item.id];
            }
            $scope.dropdownEvent = $.extend(true, {}, ev);
            $scope.dropdownOptions = [
                { key: "deleteTasksInGrid", click: deleteTasksInGrid, params: tasks, translateKey: "DELETE" },
                { key: "exportTasks", click: exportTasks, params: tasks, translateKey: "EXPORT_ICS_FILE" }
            ];

            var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
            var element = $("#context-menu-area");
            if (element) {
                var elementCompiled = $compile(elementToCompile)($scope);
                element.append(elementCompiled);
            }
        }

        // Export / Import
        function importIcsFile() {
            $mdDialog.show({
                locals: {},
                controller: 'taskImportDialogController',
                controllerAs: 'ctrl',
                templateUrl: 'app/tasks/modals/task-import.dlg.html',
                clickOutsideToClose: false
            });
        }

        function exportTasksDropdown(params) {
            var inputParamsStrings = [];
            for (var i = 0; i < params.card.length; ++i) {
                var card = params.card[i];
                inputParamsStrings.push(card.sourceOwner + "|" + card.sourceId + "|" + card.id);
            }
            vm.exportIcsFile(inputParamsStrings);
        }

        function exportTasks(vals, ev) {
            let inputParamsStrings = [];
            for (var i = 0; i < vals.length; ++i) {
                var owner = vals[i].split("|")[0];
                if (owner == "")
                    owner = null;
                var id = vals[i].split("|")[1];
                var card = $.grep($scope.tasks, function (t) {
                    return t.sourceOwner === owner && t.id === id;
                })[0];
                if (card)
                    inputParamsStrings.push(card.sourceOwner + "|" + card.sourceId + "|" + card.id);
            }
            vm.exportIcsFile(inputParamsStrings);
        }
        
        function exportTasksFromSelection(tasks) {
            if (!tasks)
                tasks = $scope.tasks;
            if (!tasks[0].id) {
                if (tasks[0].contains("|"))
                    tasks = $.map(tasks, function (t) { return t.split("|")[1]; });
                tasks = $.grep($scope.tasks, function (t) { return tasks.includes(t.id); });
            }

            tasks = tasks[0].id
                ? tasks
                : $.grep($scope.tasks, function (t) { return tasks.includes(t.id); });
                
            var inputParamStrings = [];
            for (var i = 0; i < tasks.length; ++i) {
                inputParamStrings.push(tasks[i].sourceOwner + "|" + tasks[i].sourceId + "|" + tasks[i].id);
            }
            vm.exportIcsFile(inputParamStrings);
        }

        function exportIcsFile(tasks) {
            const params = JSON.stringify(tasks);
            const httpPath = "~/api/v1/tasks/export/download";
            const filename = $filter("translate")("TASK") + ".ics";

            $rootScope.spinner.show();
            toaster.pop("warning", $translate.instant("WARN_ATTACH_NOT_EXPORTED"));
            coreDataFileStorage.downloadFile(httpPath, filename, params)
                .then(function () { }, errorHandling.report)
                .finally($rootScope.spinner.hide);
            return;
        }

        // Delete
        function deleteTasksDropdown(params) {
            $scope.deleteTasksWithConfirm(params.card, params.event);
        }

        function deleteTasksInGrid(vals, ev) {
            var cards = [];
            for (var i = 0; i < vals.length; ++i) {
                var owner = vals[i].split("|")[0];
                if (owner == "")
                    owner = null;
                var id = vals[i].split("|")[1];
                var card = $.grep($scope.tasks, function (t) {
                    return t.sourceOwner === owner && t.id === id;
                })[0];
                if (card)
                    cards.push(card);
            }
            $scope.deleteTasksWithConfirm(cards, ev);
        }

        function deleteTasksWithConfirm(selectedCards, ev) {
            if (selectedCards.length > 0) {

                var confirm = $mdDialog.confirmDeletion()
                    .textContent($filter("translate")("CONFIRMATIONS_DELETE_ITEMS", { items: selectedCards.length }))
                    .targetEvent(ev);
                $mdDialog.show(confirm)
                    .then(function () { $scope.deleteTasks(selectedCards, ev); }, null);
            } else {
                errorHandling.report("ERROR_NO_CARDS");
                $rootScope.spinner.hide();
            }
        }

        function deleteTasks(selectedCards, ev) {
            $scope.editingItem = false;

            try {
                $rootScope.spinner.show();
                coreDataTasks.removeTasks(selectedCards)
                    .then(function () {
                        $scope.tasks = coreDataTasks.getFilteredTasks();
                        $scope.selectedCards.length = 0;
                        if ($scope.tasks.length === 0)
                            $scope.selectMode = false;
                    }, function (failure) {
                        var message = failure.message;
                        if (message == undefined)
                            message = failure.status + " " + failure.statusText;
                        errorHandling.report(message);
                    })
                    .finally($rootScope.spinner.hide);
            }
            catch (err) {
                errorHandling.report(err);
                $rootScope.spinner.hide();
            }
        }

        // Modals -- Add to Outlook
        async function addToOutlook() {
            taskActions.addToOutlook();
        }

        // Miscellaneous functions
        function generateReminderOptions() {
            var reminderOptions = [], id;

            // None
            reminderOptions.push({ id: 0, name: $filter("translate")("NONE") });

            // Minutes
            id = 1;
            for (var numMinutes = 0; numMinutes <= 15; numMinutes += 5)
                reminderOptions.push({ id: id++, name: $filter("translate")("REMINDER_MINUTES", { numMinutes: numMinutes }) });
            reminderOptions.push({ id: 5, name: $filter("translate")("REMINDER_MINUTES", { numMinutes: 30 }) });

            // Hours
            id = 6;
            for (var numHours = 1; numHours <= 12; ++numHours)
                reminderOptions.push({ id: id++, name: $filter("translate")("REMINDER_HOURS", { numHours: numHours }) });
            reminderOptions.push({ id: 18, name: $filter("translate")("REMINDER_HOURS", { numHours: 24 }) });

            // Days
            id = 19;
            for (var numDays = 2; numDays <= 4; ++numDays)
                reminderOptions.push({ id: id++, name: $filter("translate")("REMINDER_DAYS", { numDays: numDays }) });

            // Weeks
            reminderOptions.push({ id: 22, name: $filter("translate")("REMINDER_WEEKS", { numWeeks: 1 }) });
            reminderOptions.push({ id: 23, name: $filter("translate")("REMINDER_WEEKS", { numWeeks: 2 }) });

            return reminderOptions;
        }

        function statusToString(status) {
            if (status < 0 || status >= statusStrings.length)
                return statusStrings[0];
            return statusStrings[status];
        }

        function onTasksRefresh(e, args) {
            $timeout(function () {
                vm.searchUpdate();
                $scope.selectedCards.length = 0;
                coreDataTasks
                    .resetTasks()
                    .then(reloadSources)
                    .finally(function () { $(window).trigger("resize"); });
            }, 1);
        }

        function statusChanged(status) {
            if (status === 1)
                $scope.taskInfo.percentComplete = 100;
        }

        function percentChanged(percent) {
            if (percent === 100)
                $scope.taskInfo.status = 1;
        }

        function checkForAvailableMappings(reset) {
            if (reset) coreDataSettings.resetResources();
            coreDataSettings.settingsData.mappedResources
                .then(function (success) {
                    // Remove all non-task or mapped resources
                    $.grep(success || [], function (resource) {
                        return resource.shareType === 4 && !resource.mapped;
                    });
                });
        }

        function reloadSources() {
            if (reloadDefer) return reloadDefer.promise;

            reloadDefer = $q.defer();
            coreDataCalendar.resetTasksTree();
            coreDataCalendar.resetSources();
            coreDataTasks.resetSources();
            coreDataTasks
                .loadSourcesTree($scope.sourcesTreeController, true)
                .then(function () {
                    coreDataTasks
                        .resetTasks()
                        .then(function () {
                            $scope.sources = coreDataTasks.getSources();
                            vm.searchUpdate();
                            reloadDefer.resolve();
                            reloadDefer = null
                        }, function () {
                            reloadDefer.reject();
                            reloadDefer = null;
                        });
                }, function (failure) {
                    errorHandling.report(failure);
                    reloadDefer = null;
                });

            return reloadDefer.promise;
        }

        // SignalR
        function onSharesChanged(e, args) {
            if (args && args.shareType && args.shareType !== "tasks") return;
            checkForAvailableMappings(true);
            reloadSources();
        }

        async function openManageCategoriesModal(ev) {
            try {
                await $mdDialog.show({
                    controller: "manageCategoriesDialogController",
                    controllerAs: "manageCategoriesCtrl",
                    templateUrl: "app/shared/modals/manage.categories.dlg.html",
                    targetEvent: ev
                });
            } catch (err) {
                // error indicates modal was closed and it can be ignored
            }
        }

        // Left Tree ----------------------------------------------------------------------

        async function onBranchSelect(branch, treeType, eye) {
            for (let i = 0; i < $scope.sourcesTree.data.length; i++) {
                let item = $scope.sourcesTree.data[i];
                item.selected = false;
            }
            vm.selectedBranch = branch;
            branch.selected = true;

            let oldVisible = branch.data.isVisible;
            if (eye)
                branch.data.isVisible = !branch.data.isVisible;
            else
                branch.data.isVisible = true;
            branch.data.source.isVisible = branch.data.isVisible;

            if (oldVisible != branch.data.isVisible)
                await updateSourcesFiltering(branch);

            vm.isVisibleRenameFolder = visibilityCheckRenameFolder(branch);
            vm.isVisibleDeleteFolder = visibilityCheckDeleteFolder(branch);
            vm.isVisibleShareFolder = visibilityCheckShareFolder(branch);
            vm.isVisibleFolderProperties = visibilityCheckFolderProperties(branch);
        }

        async function updateSourcesFiltering(branch) {
            try {
                $rootScope.spinner.show();
                //branch.data.isVisible = !branch.data.isVisible;

                $scope.isInitialized = false;
                await coreDataTasks.updateSourceVisibility(branch.data.source);
                $scope.anySourcesSelected = $scope.isSourceSelected();
                vm.searchUpdate();
            } catch (err) {
                errorHandling.report(err);
            } finally {
                $rootScope.spinner.hide();
            }
        }

        function branchMouseUp(branch, event) {
            event.stopPropagation();
            event.preventDefault();
            event.cancelbubble = true;
            if (!event || event.which !== 3)
                return;

            if (branch.data.unclickable) return;

            $scope.dropdownEvent = $.extend(true, {}, event);
            $scope.dropdownOptions = generateFolderMenuItems(branch, event);

            // Make sure at least one element is visible
            let hasAny = false;
            for (let i = 0; i < $scope.dropdownOptions.length; i++)
                if ($scope.dropdownOptions[i].show || $scope.dropdownOptions[i].show === undefined)
                    hasAny = true;
            if (!hasAny)
                return;

            var elementToCompile = `<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="['dropdown-no-scroll']" menu-like="true" menu-class="'abn-tree-row'"></st-context-menu>`;
            var element = $("#context-menu-area");
            if (element) {
                var elementCompiled = $compile(elementToCompile)($scope);
                element.append(elementCompiled);
            }
        }

        function generateFolderMenuItems(branch, event) {
            return [
                { key: 'newFolder', click: onContextNewFolder, params: { event: event, branch: branch }, translateKey: 'NEW_FOLDER' },
                { key: 'renameFolder', click: onContextRenameFolder, params: { event: event, branch: branch }, translateKey: 'EDIT_FOLDER', show: visibilityCheckRenameFolder(branch) },
                { key: 'deleteFolder', click: onContextDeleteFolder, params: { branch: branch, event: event }, translateKey: 'DELETE_FOLDER', show: visibilityCheckDeleteFolder(branch) },
                { key: 'shareFolder', click: onContextShareFolder, params: { branch: branch, event: event }, translateKey: 'SHARE_FOLDER', show: visibilityCheckShareFolder(branch) },
                { key: 'sharedFolderProperties', click: onContextSharedFolderProperties, params: { branch: branch, event: event }, translateKey: "PROPERTIES", show: visibilityCheckFolderProperties(branch) },
            ];
        }

        function visibilityCheckRenameFolder(branch) {
            return branch && branch.data.source.permission >= 2 && !branch.data.isSharedByOther && !branch.data.isDomainResource;
        }
        function visibilityCheckDeleteFolder(branch) {
            return branch && branch.data.source.permission >= 4 && !branch.data.isSharedByOther && !branch.data.isDomainResource && !branch.data.isPrimary;
        }
        function visibilityCheckShareFolder(branch) {
            return branch && branch.data.source.permission >= 4 && !branch.data.isSharedByOther && !branch.data.isDomainResource && coreDataSettings.userDomainSettings.enableSharing;
        }
        function visibilityCheckFolderProperties(branch) {
            return branch && branch.data.isSharedByOther;
        }

        async function onContextNewFolder(params) {
            let success = await taskActions.showCreateFolderModal(params.event);
            if (success) {
                await reloadSources();
                $(window).trigger("resize");
            }
        }

        async function onContextRenameFolder(params) {
            let success = await taskActions.showRenameFolderModal(params.event, params.branch.data.source);
            if (success) {
                await reloadSources();
                $(window).trigger("resize");
            }
        }

        async function onContextDeleteFolder(params) {
            let success = await taskActions.showDeleteFolderModal(params.event, params.branch.data.source);
            if (success) {
                await reloadSources();
                $(window).trigger("resize");
            }
        }

        async function onContextShareFolder(params) {
            let source = params.branch.data.source;
            let success = await taskActions.showShareFolderModal(params.event, source);
            if (success) {
                await reloadSources();
                $(window).trigger("resize");
            }
        }

        function goToManageShares() {
            $state.go("index.settings.user-sharing");
        }

        async function onContextSharedFolderProperties(params) {
            let source = params.branch.data.source;
            let success = await taskActions.showSharedFolderPropertiesModal(params.event, source);
            if (success) {
                await reloadSources();
                $(window).trigger("resize");
            }
        }

        // Filtering Functions

        function isRecurring(item) {
            return item.recurrence && item.recurrence.type !== 0;
        }

        function translateCategory(catName) {
            const translated = $translate.instant(catName);
            return $("<div>").html(translated).text(); // Translate HTML encodes the string, so we need to undo that
        }
    }
})();