(function () {
	"use strict";

	angular
		.module("smartermail")
        .controller("extensionBlacklistDialogController", extensionBlacklistDialogController);

    function extensionBlacklistDialogController($rootScope, $scope, $mdDialog, extensions, title, isReadOnly) {
		$scope.textValue = "";
		$scope.extensions = extensions || [];
		$scope.title = title || "EXTENSION_BLACKLIST";
        $scope.extensions.sort();
        $scope.textValue = $scope.extensions.join("\r\n");
        $scope.isReadOnly = isReadOnly;
		
		$scope.save = function () {
			var arr = $scope.textValue.split(/\r?\n/);
			arr = arr.filter(function (ext) {
				return ext != "";
			});
			$mdDialog.hide({extensions:arr});
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}

})();
