/*
 * Modified version of https://github.com/nickperkinslondon/angular-bootstrap-nav-tree
 * Use Examples:
 * <abn-tree tree-data="shortTree.data" on-drag-drop="onDragDrop" pre-branch-select="onBranchSelect" tree-type="mail" tree-control="shortTreeController" icon-leaf="" icon-expand="toolsicon toolsicon-arrow_drop_down_emphasis expand" icon-collapse="toolsicon toolsicon-arrow_drop_down_emphasis" expand-level="0" on-select="onBranchChanged(branch)"></abn-tree>
 * <abn-tree tree-data="rssCtrl.tree.data" on-select="onBranchSelect(branch)" tree-control="rssCtrl.treeController" icon-leaf="" icon-expand="toolsicon toolsicon-arrow_drop_down_emphasis expand" icon-collapse="toolsicon toolsicon-arrow_drop_down_emphasis"></abn-tree>
 * Example of tree data at bottom of file.
 */

(function () {
    angular.module('angularBootstrapNavTree', [])
        .directive('abnTree', ['$rootScope', '$timeout', abnTree]);

    function abnTree($rootScope, $timeout) {
        return {
            restrict: 'E',
            template: `
				<ul class="nav nav-list nav-pills nav-stacked abn-tree" ng-disabled="disableTree">
				<li st-drag-over="onRowDragOver(event)" 
					st-drag-leave="onRowDragLeave(event)" 
					st-drop="onDrop(event)"  
					ng-repeat="row in tree_rows | filter:{visible:true} track by row.branch.uid" 
					key="{{row.key}}" 
					ng-class="'level-' + {{ row.level }} + (row.branch.selected ? ' active':'') + ' ' +row.classes.join(' ')" 
					class="abn-tree-row" 
					ng-hide="row.branch.data.hide"> 
				<a tabindex='0' 
					ng-click="(disableTree || row.branch.data.unclickable) ? null : branchClicked(row, undefined, $event)" 
					ng-mouseup="triggerBranchMouseUp(row.branch, undefined, $event)" 
					st-enter-key="( disableTree || row.branch.data.unclickable) ? null : branchClicked(row, undefined, $event)" 
					ng-class="{
						'unclickable': disableTree || row.branch.data.unclickable,
						'not-visible': row.branch.data.showEye && !row.branch.data.isVisible
					}" 
					oncontextmenu="return false;" md-prevent-menu-close
					ng-disable="disableTree" > 
					<span ng-if="row.branch.data.busy ">
						<i ng-class="[busyIcon, {'tree-icon': useIcons}]"> </i>
					</span>
					<span ng-if="row.branch.data.criticallyErrored ">
						<i class="toolsicon toolsicon-warning_fill red"> </i>
					</span>
					<span ng-if="!row.branch.data.busy && !row.branch.data.criticallyErrored">
						<i isolate-click ng-class="[row.tree_icon, {'tree-icon': useIcons}]" 
						   ng-show="(row.tree_icon || row.level > 1) && !row.branch.data.hideCaret" 
						   ng-click="$event.stopPropagation(); $event.preventDefault(); branchExpand(row, $event);" 
						   class="indented"
						   md-prevent-menu-close ng-disable="disableTree || row.branch.data.busy || row.branch.data.criticallyErrored"> </i> 
					</span>
                    <div ng-class="{'underline-target': !disableTree && !row.branch.data.disableUnderline}" 
						 ng-click="!disableTree && row.branch.data.showEye ? branchClicked(row, undefined, $event) : null">
					    <st-tree-checkbox isolate-click 
						    ng-click="branchClicked(row, true, $event)" 
						    ng-show="row.branch.data.showEye"
						    class='st-dropdown-eye tree-count'
                            is-checked="!disableTree && row.branch.data.isVisible"
                            fill-color="row.branch.data.color"
					        is-disabled="disableTree"">
                        </st-tree-checkbox> 
						<span class="tree-label" ng-bind-html="row.label"></span> 
                    </div>
                    <i ng-show="row.infoIcon" class="toolsicon {{ row.infoIcon }}"></i>
					<span class='counter tree-count' ng-show="row.count && row.count !== '-' && showCounts == true">{{ row.count }}</span> 
				</a>
				</li>
				</ul>`,
            replace: true,
            link: linkFunction,
            scope: {
                branchMouseUp: '=',
                initialSelection: '@',
                onBranchExpand: '=',
                onBranchOpen: '=',
                onDragDrop: '=',
                onSelect: '&',
                preBranchSelect: '=', //this fires when a user clicks on a branch themselves before the branch is selected.
                showCounts: '=',
                treeControl: '=',
                treeData: '=',
                treeType: '@',
                useIcons: '=',
                disableTree: '<'
            },
        };

        function linkFunction(scope, element, attrs) {
            let treeState = undefined;
            let expand_level;
            let tree;
            let selected_branch = null;

            // Scope variables
            scope.tree_rows = [];
            
            // Function Map
            scope.onRowDragOver = onRowDragOver;
            scope.onRowDragLeave = onRowDragLeave;
            scope.onDrop = onDrop;
            scope.branchExpand = branchExpand;
            scope.triggerBranchMouseUp = triggerBranchMouseUp;
            scope.branchClicked = branchClicked;
            activate();

            //-------------------------------

            function activate() {
                if ($('body').injector().has('treeState')) {
                    treeState = $('body').injector().get('treeState');
                }

                if (attrs.iconExpand == null) {
                    attrs.iconExpand = 'icon-plus glyphicon glyphicon-plus fa fa-plus';
                }
                if (attrs.iconCollapse == null) {
                    attrs.iconCollapse = 'icon-minus glyphicon glyphicon-minus fa fa-minus';
                }
                if (attrs.iconLeaf == null) {
                    attrs.iconLeaf = 'icon-file glyphicon glyphicon-file fa fa-file';
                }
                if (attrs.expandLevel == null) {
                    attrs.expandLevel = '3';
                }

                scope.busyIcon = attrs.busyIcon || "toolsicon toolsicon-progress_activity toolsicon-spin"
                scope.erroredIcon = attrs.erroredIcon || "toolsicon toolsicon-warning_fill red";
                expand_level = parseInt(attrs.expandLevel, 10);

                // Pull treeData from root level if it didn't get passed in scope vars
                if (!scope.treeData) {
                    return;
                }
                if (scope.treeData.length == null) {
                    if (!treeData.label)
                        return;
                    scope.treeData = [treeData];
                }

                scope.$watch('treeData', on_treeData_change, true);

                // Select initial branch
                if (attrs.initialSelection != null) {
                    for_each_branch(scope.treeData, function (b) {
                        if (b.label === attrs.initialSelection) {
                            $timeout(() => select_branch(b));
                            return;
                        }
                    });
                }

                for_each_branch(scope.treeData, function (b, level) {
                    b.level = level;
                    b.expanded = b.level < expand_level;
                });

                if (scope.treeControl != null && typeof scope.treeControl === 'object') {
                    tree = scope.treeControl;
                    initTreeFunctions();
                }
            }
            
            function select_branch(branch) {
                if (!branch) {
                    if (selected_branch != null)
                        selected_branch.selected = false;
                    else
                        tree.deselect_all_branches();
                    selected_branch = null;
                    return;
                }
                if (branch !== selected_branch) {
                    if (selected_branch != null)
                        selected_branch.selected = false;
                    else
                        tree.deselect_all_branches();
                    branch.selected = true;
                    selected_branch = branch;
                    expand_all_parents(scope.treeData, branch);
                    if (typeof branch.onSelect === 'function') {
                        return $timeout(() => branch.onSelect(branch, scope.treeType));
                    } else if (typeof scope.onSelect === 'function') {
                        return $timeout(() => scope.onSelect({ branch: branch }, scope.treeType));
                    }
                }
            }

            function branchClicked(row, eye, ev) {
                if (scope.disableTree)
                    return;
                if (!ev || ev.which === 3)
                    return;
                if (row.branch.data.unselectable) {
                    branchExpand(row, ev);
                    return;
                }
                if (treeState && $rootScope.windowWidth && $rootScope.windowWidth <= 737 && !eye && ev.target.classList.value.indexOf(attrs.iconExpand) === -1 && ev.target.classList.value.indexOf(attrs.iconCollapse) === -1) {
                    treeState.setTree(false);
                    $rootScope.$broadcast('treeChanged');
                }
                if (row.branch !== selected_branch) {
					if (typeof scope.preBranchSelect === 'function') {
						if (scope.preBranchSelect(row.branch, scope.treeType, eye) === false)
							return;
					}
                    if (row.branch.data.showEye)
                        return;
                    return select_branch(row.branch);
                }
            }

            function on_treeData_change() {
                scope.tree_rows = [];

                // Ensure that each branch has an ID
                for_each_branch(scope.treeData, (branch) => {
                    branch.uid = branch.uid || "" + Math.random()
                });

                // Ensure each child branch has the UID of its parent set
                for_each_branch(scope.treeData, (branch) => {
                    if (!Array.isArray(branch.children))
                        return;
                    let _ref = branch.children;
                    let _len = _ref.length;
                    for (let _i = 0; _i < _len; _i++) {
                        let child = _ref[_i];
                        child.parent_uid = branch.uid;
                    }
                });

                // Make sure all branch children are the proper object types
                for_each_branch(scope.treeData, (branch) => {
                    let oldChildren = branch.children || [];
                    let newChildren = [];
                    let _len = oldChildren.length;
                    for (let _i = 0; _i < _len; _i++) {
                        let child = oldChildren[_i];
                        let fixedChild = typeof child === 'string' ? { label: child, children: [] } : child;
                        newChildren.push(fixedChild);
                    }
                    branch.children = newChildren;
                });

                let _ref = scope.treeData;
                let _len = _ref.length;
                for (let _i = 0; _i < _len; _i++) {
                    let root_branch = _ref[_i];
                    add_branch_to_list(1, root_branch, true);
                }

                function add_branch_to_list(level, branch, visible) {

                    branch.expanded = branch.expanded || false;
                    branch.classes = branch.classes || [];

                    let tree_icon = attrs.iconLeaf;
                    if (branch.noLeaf || (branch.children && branch.children.length))
                        tree_icon = branch.expanded ? attrs.iconCollapse : attrs.iconExpand;
                    else if (branch.classes.indexOf("leaf") < 0) {
                        branch.classes.push("leaf");
                    }

                    scope.tree_rows.push({
                        level: level,
                        branch: branch,
                        label: branch.label,
                        count: (branch.data && branch.data.count) ? branch.data.count : "",
                        key: branch.data.key,
                        classes: branch.classes,
                        infoIcon: branch.infoIcon,
                        tree_icon: tree_icon,
                        visible: visible,
                        showSharedIcon: (branch.data && branch.data.isShared) ? true : false,
                        disposableAddress: (branch.data && branch.data.isDisposable && !branch.data.isShared) ? true : false,
                    });

                    if (branch.children != null) {
                        let _ref = branch.children;
                        let _len = _ref.length;
                        for (let _i = 0; _i < _len; _i++) {
                            let child = _ref[_i];
                            let child_visible = visible && branch.expanded;
                            add_branch_to_list(level + 1, child, child_visible);
                        }
                    }
                }
            }

            function initTreeFunctions() {
                tree.expand_all = function () {
                    return for_each_branch(scope.treeData, (b) => b.expanded = true);
                };
                tree.collapse_all = function () {
                    return for_each_branch(scope.treeData, (b) => b.expanded = false);
                };
                tree.get_first_branch = function () {
                    return scope.treeData && scope.treeData.length && scope.treeData[0];
                };
                tree.select_first_branch = function () {
                    return tree.select_branch(tree.get_first_branch());
                };
                tree.get_selected_branch = function () {
                    if (selected_branch) {
                        return selected_branch;
                    }
                    for_each_branch(scope.treeData, function (b) {
                        if (b.selected) {
                            return selected_branch;
                        }
                    });
                    return undefined;
                };
                tree.get_parent_branch = (b) => get_parent(scope.treeData, b);
                tree.select_branch = (b) => {
                    select_branch(b);
                    return b;
                };
                tree.select_branch_id = (key) => {
                    for_each_branch(scope.treeData, (b) => {
                        if (b.data.key === key)
                            select_branch(b);
                    });
                };
                tree.get_children = (b) => b.children;
                tree.select_parent_branch = function (b) {
                    b = b || tree.get_selected_branch();
                    if (!b)
                        return;
                    let p = tree.get_parent_branch(b);
                    if (p) {
                        tree.select_branch(p);
                        return p;
                    }
                };
                tree.add_branch = function (parent, new_branch) {
                    if (parent != null) {
                        parent.children.push(new_branch);
                        parent.expanded = true;
                    } else {
                        scope.treeData.push(new_branch);
                    }
                    return new_branch;
                };
                tree.remove_branch = function (b) {
                    let parent = scope.treeControl.get_parent_branch(b);
                    if (parent) {
                        parent.children.splice(parent.children.indexOf(b), 1);
                    } else {
                        scope.treeData.splice(scope.treeData.indexOf(b), 1);
                    }
                };
                tree.add_root_branch = function (new_branch) {
                    tree.add_branch(null, new_branch);
                    return new_branch;
                };
                tree.expand_branch = function (b) {
                    b = b || tree.get_selected_branch();
                    if (!b) return;
                    b.expanded = true;
                    return b;
                };
                tree.expand_all_parents = (b) => expand_all_parents(scope.treeData, b);
                tree.collapse_branch = function (b) {
                    b = b || selected_branch;
                    if (!b) return;
                    b.expanded = false;
                    return b;
                };
                tree.get_siblings = function (b) {
                    b = b || selected_branch;
                    if (!b) return;
                    let p = tree.get_parent_branch(b);
                    return p ? p.children : scope.treeData;
                };
                tree.get_next_sibling = function (b) {
                    b = b || selected_branch;
                    if (!b) return;
                    let siblings = tree.get_siblings(b);
                    let n = siblings.length;
                    let i = siblings.indexOf(b);
                    if (i < n) {
                        return siblings[i + 1];
                    }
                };
                tree.get_prev_sibling = function (b) {
                    b = b || selected_branch;
                    if (!b) return;
                    let siblings = tree.get_siblings(b);
                    let i = siblings.indexOf(b);
                    if (i > 0) {
                        return siblings[i - 1];
                    }
                };
                tree.select_next_sibling = function (b) {
                    b = b || selected_branch;
                    if (!b) return;
                    let next = tree.get_next_sibling(b);
                    if (next) {
                        return tree.select_branch(next);
                    }
                };
                tree.select_prev_sibling = function (b) {
                    b = b || selected_branch;
                    if (!b) return;
                    let prev = tree.get_prev_sibling(b);
                    if (prev) {
                        return tree.select_branch(prev);
                    }
                };
                tree.get_first_child = function (b) {
                    b = b || selected_branch;
                    if (!b) return;
                    return b.children && b.children.length && b.children[0];
                };
                tree.get_closest_ancestor_next_sibling = function (b) {
                    let next = tree.get_next_sibling(b);
                    if (next) return next;
                    let parent = tree.get_parent_branch(b);
                    return tree.get_closest_ancestor_next_sibling(parent);
                };
                tree.get_next_branch = function (b) {
                    b = b || selected_branch;
                    if (!b) return;
                    let next = tree.get_first_child(b);
                    if (next)
                        return next;
                    return tree.get_closest_ancestor_next_sibling(b);
                };
                tree.select_next_branch = function (b) {
                    b = b || selected_branch;
                    if (!b) return;
                    let next = tree.get_next_branch(b);
                    if (next) {
                        tree.select_branch(next);
                        return next;
                    }
                };
                tree.last_descendant = function (b) {
                    if (b == null) { /* We don't do anything here for some reason */ }
                    let n = b.children.length;
                    if (n === 0) {
                        return b;
                    } else {
                        let last_child = b.children[n - 1];
                        return tree.last_descendant(last_child);
                    }
                };
                tree.get_prev_branch = function (b) {
                    b = b || selected_branch;
                    if (!b) return;
                    let prev_sibling = tree.get_prev_sibling(b);
                    return prev_sibling
                        ? tree.last_descendant(prev_sibling)
                        : tree.get_parent_branch(b);
                };
                tree.deselect_all_branches = function () {
                    for_each_branch(scope.treeData, (b) => b.selected = false);
                    selected_branch = null;
                };
                tree.select_prev_branch = function (b) {
                    b = b || selected_branch;
                    if (!b) return;
                    let prev = tree.get_prev_branch(b);
                    if (prev) {
                        tree.select_branch(prev);
                        return prev;
                    }
                };
            }

            function onRowDragOver(event) {
                if (!scope.onDragDrop)
                    return false;
                $(event.currentTarget).find('a').addClass('drop-highlight');
                event.preventDefault();
            }

            function onRowDragLeave(event) {
                $(event.currentTarget).find('a').removeClass('drop-highlight');
            }

            function onDrop(event) {
                $(event.currentTarget).find('a').removeClass('drop-highlight');
                if (scope.onDragDrop != undefined)
                    scope.onDragDrop(event);
                event.preventDefault();
                event.stopPropagation();
                return false;
            }

            function branchExpand(row, event) {
                event.stopPropagation();
                event.preventDefault();
                row.branch.expanded = !row.branch.expanded;
                if (scope.onBranchOpen != undefined)
                    scope.onBranchOpen(row.branch, event);
            }

            function triggerBranchMouseUp(branch, eye, event) {
                if (event && event.which === 3 && typeof scope.branchMouseUp === 'function')
                    scope.branchMouseUp(branch, event);
            }

            function for_each_branch(treeData, action) {
                let _ref = treeData;
                let _len = _ref.length;
                let _results = [];
                for (let _i = 0; _i < _len; _i++) {
                    let root_branch = _ref[_i];
                    _results.push(do_f(root_branch, 1));
                }
                return _results;

                function do_f(branch, level) {
                    action(branch, level);
                    if (!branch.children)
                        return;

                    let _ref = branch.children;
                    let _results = [];
                    let _len = _ref.length;
                    for (let _i = 0; _i < _len; _i++) {
                        let child = _ref[_i];
                        _results.push(do_f(child, level + 1));
                    }
                    return _results;
                }
            }

            function for_all_ancestors(treeData, child, action) {
                let parent = get_parent(treeData, child);
                if (parent != null) {
                    action(parent);
                    return for_all_ancestors(treeData, parent, action);
                }
            }

            function get_parent(treeData, child) {
                let parent = undefined;
                if (child.parent_uid) {
                    for_each_branch(treeData, function (b) {
                        if (b.uid === child.parent_uid) {
                            return parent = b;
                        }
                    });
                }
                return parent;
            }

            function expand_all_parents(treeData, child) {
                return for_all_ancestors(treeData, child, (b) => b.expanded = true);
            }
        }
    }
}).call(this);
